import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { timestampForDate } from '../../../../../functions/src/utils/dates';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { AlertInputComponent } from '../../../components/alert-input/alert-input.component';
import { ForwardNotesComponent } from '../../../components/forward-notes/forward-notes.component';
import { UserDocumentsComponent } from '../../../components/user-documents/user-documents.component';
import { AuthService } from '../../../core';
import { ChatAttributedInterface } from '../../models/ChatAttributedEntity';
import { ChatContactEntity } from '../../models/ChatContactEntity';
import { ChatMessageEntity } from '../../models/ChatMessageEntity';
import { ChatAttributedService } from '../../services/chat-attributed.service';
import { ChatService } from '../../services/chat.service';


@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent implements OnInit {
  @Input() contact: ChatContactEntity;
  messages: ChatMessageEntity[] = [];
  isLoadingMessages = false;
  messageSubscription: Subscription;
  loggedUserSubscription: Subscription;
  coords: { x: number; y: number };
  isMenuContactOpen: boolean;
  messageSelected: ChatMessageEntity;
  showPopupContact: boolean;
  loggedUser: any;
  phoneValue: string;
  hasTextBeenLoaded: any;
  menuOpenStates: { [key: string]: boolean } = {};
  menuOpenStatesImage: { [key: string]: boolean } = {};
  getAtriubutedListSubescription: Subscription;
  atributedList: ChatAttributedInterface[] = [];

  currentPage = 1;
  itemsPerPage = 20;
  getPlugConfigSubscription: Subscription;

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private chatAttributedService: ChatAttributedService,
    private dialog: MatDialog
  ) {
    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser) => {
      this.loggedUser = {
        uid: loggedUser.uid,
        name: loggedUser.name || 'Unknown',
        email: loggedUser.email,
        accessLevel: loggedUser.accessLevel
      };
    });

    this.getPlugConfigSubscription = this.chatService.getPlugConfig().subscribe((data: any) => {
      this.phoneValue = data?.phone;
      this.itemsPerPage = data?.maxMessage || 20;
    });

  }

  ngOnInit(): void {

    this.getMessagesByContact(this.contact.whatsapp, this.currentPage, this.itemsPerPage);
    this.getAtributesList();

  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if (this.getAtriubutedListSubescription) {
      this.getAtriubutedListSubescription.unsubscribe();
    }
    if (this.getPlugConfigSubscription) {
      this.getPlugConfigSubscription.unsubscribe();
    }

  }

  private getAtributesList(): void {
    if (this.getAtriubutedListSubescription) {
      this.getAtriubutedListSubescription.unsubscribe();
    }

    this.getAtriubutedListSubescription = this.chatAttributedService.getAtributedList(this.contact.uid).subscribe(res => {
      this.atributedList = res.length > 0 ? res : [];
    });
  }

  getAttributedList(messageBefore: ChatMessageEntity, messageAfter: ChatMessageEntity = null): ChatAttributedInterface[] {
    let att = null;

    if (this.atributedList?.length === 0) {
      return null;
    }

    const dateAfter = messageAfter?.sendDate ? timestampForDate(messageAfter.sendDate).toISOString() : null;
    const dateBefore = messageBefore?.sendDate ? timestampForDate(messageBefore.sendDate).toISOString() : null;

    att = this.atributedList
      .filter(a => {
        let attCreatedAt: string;

        // Verificar e converter createdAt
        if (typeof a.createdAt === 'string') {
          attCreatedAt = new Date(a.createdAt).toISOString();
        } else if (a.createdAt instanceof Date) {
          attCreatedAt = a.createdAt.toISOString();
        } else if (typeof a.createdAt.seconds === 'number' && typeof a.createdAt.nanoseconds === 'number') {
          attCreatedAt = new Date(a.createdAt.seconds * 1000 + a.createdAt.nanoseconds / 1000000).toISOString();
        } else {
          console.error("Invalid createdAt value:", a.createdAt);
          return false;
        }

        if (!attCreatedAt) return false;
        if (!dateAfter) return attCreatedAt > dateBefore;
        if (dateAfter && dateBefore) return attCreatedAt < dateAfter && attCreatedAt > dateBefore;
      })
      .sort((a, b) => {
        const dateA = typeof a.createdAt === 'string' ? new Date(a.createdAt) :
          a.createdAt instanceof Date ? a.createdAt :
            new Date(a.createdAt.seconds * 1000 + a.createdAt.nanoseconds / 1000000);
        const dateB = typeof b.createdAt === 'string' ? new Date(b.createdAt) :
          b.createdAt instanceof Date ? b.createdAt :
            new Date(b.createdAt.seconds * 1000 + b.createdAt.nanoseconds / 1000000);

        return dateA.toISOString() > dateB.toISOString() ? 1 : -1;
      }) || null;

    return att;
  }



  private getMessagesByContact(contact: string, page: number, itemsPerPage: number): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }

    this.isLoadingMessages = true;
    this.messageSubscription = this.chatService.getCdcMessages(contact, page, itemsPerPage).subscribe({
      next: (result) => {


        if (result && Array.isArray(result)) {
          // Adicione as novas mensagens à lista existente
          this.messages = result.sort((a, b) => timestampForDate(a.sendDate).toISOString() > timestampForDate(b.sendDate).toISOString() ? 1 : -1);
        } else {
          // Se não houver mensagens, não altere a lista existente
        }
        console.log('Received messages:', this.messages.length);

        this.isLoadingMessages = false;
      },
      error: (error) => {
        console.error('Error fetching messages:', error);
        this.isLoadingMessages = false;
      },
      complete: () => {
        this.isLoadingMessages = false;
      },
    });
  }

  loadMoreMessages(): void {
    this.getMessagesByContact(this.contact.whatsapp, this.currentPage, this.messages.length + this.itemsPerPage);
  }


  openMenuContactSelected(event: MouseEvent, message: ChatMessageEntity): void {
    this.closeMenuContactSelected();
    event.preventDefault();
    const originalX = (event.currentTarget as HTMLElement).getBoundingClientRect().left;
    this.coords = { x: event.clientX - (originalX > 1300 ? 300 : 0), y: event.clientY };
    this.isMenuContactOpen = true;
    this.messageSelected = message;
  }

  closeMenuContactSelected(): void {
    this.isMenuContactOpen = false;
    this.messageSelected = null;
    this.showPopupContact = false;
  }

  getMessageCardStyle(message: any) {
    if (message.messageType === "WHATSAPP" && message.sender.type !== 'admin') {
      if (message.deleteMessage === true) {
        return 'message-deleted-card-whatsapp-client'
      } else {
        return 'message-card-whatsapp-client';
      }
    }
    if (message.messageType === "WHATSAPP" && message.sender.type === 'admin') {
      if (message.deleteMessage === true) {
        return 'message-deleted-card-whatsapp'
      } else {
        return 'message-card-whatsapp';
      }
    }
    if (message.messageType === 'CHAT-SCE' && message.sender.type !== 'admin') {
      if (message.deleteMessage === true) {
        return 'message-deleted-card-internal-client';
      } else {
        return 'message-card-internal-client';
      }
    }
    if (message.messageType === 'CHAT-SCE' && message.sender.type === 'admin') {
      if (message.deleteMessage === true) {
        return 'message-deleted-card-internal';
      } else {
        return 'message-card-internal';
      }

    }
    if (message.messageType === 'NOTES' && message.sender.type === 'admin') {
      return 'message-card-note';
    }
  }
  getAttachmentCardStyle(message: any): "document-wrapper-whatsapp-client" | "document-wrapper-whatsapp-admin " | "document-wrapper-sce-client " | "document-wrapper-sce-admin " {
    if (message.messageType === 'WHATSAPP' && message.sender.type !== 'admin') {
      return 'document-wrapper-whatsapp-client';
    }
    if (message.messageType === 'WHATSAPP' && message.sender.type === 'admin') {
      return 'document-wrapper-whatsapp-admin ';
    }
    if (message.messageType === 'CHAT-SCE' && message.sender.type !== 'admin') {
      return 'document-wrapper-sce-client ';
    }
    if (message.messageType === 'CHAT-SCE' && message.sender.type === 'admin') {
      return 'document-wrapper-sce-admin ';
    }
  }


  formatMessage(text: string): string {
    const parts = text.split(/(<br>)/);

    const formattedParts = parts.map((part) => {
      if (part === '<br>') {
        return '<br>';
      }

      const words = part.split(/\s+/);

      const formattedWords = words.map((word) => {
        const isUrl = this.checkIfTextIsUrl(word);

        if (isUrl) {
          const prefixedUrl = word.startsWith('http') ? word : 'http://' + word;
          return `<b><a href="${ prefixedUrl }" target="_blank">${ word }</a></b>`;
        }

        return word;
      });

      return formattedWords.join(' ');
    });

    return formattedParts.join('');
  }

  checkIfTextIsUrl(str: string): boolean {
    if (str.includes('://')) {
      try {
        const url = new URL(str);
        return ['http:', 'https:'].includes(url.protocol);
      } catch (error) {
        return false;
      }
    }
    else {
      const domainRegex = /^(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
      return domainRegex.test(str);
    }
  }


  handleOptionClick(option: any, typeMessage: string): void {
    const data = {
      customerId: this.contact.uid,
      contactId: this.contact.whatsapp,
      sender: {
        uid: this.contact.uid,
        name: this.getContactName(this.contact),
        phone: this.contact.whatsapp,
      },
      recipient: {
        uid: this.loggedUser.uid,
        name: this.loggedUser.name,
        phone: this.phoneValue,
      },
      sendingType: 'API Client',
      messageType: typeMessage,
      sendDate: new Date(),
      text: 'Você selecionou a opção de ' + option.title + '. Em breve o time responsável irá entrar em contato para auxiliá-lo!',
    };
    this.chatService.sendTextMessage(data);
  }

  clearTexts() {
    throw new Error('Method not implemented.');
  }

  toggleMenu(firestoreId: string): void {
    this.menuOpenStates[firestoreId] = !this.menuOpenStates[firestoreId];
  }

  toggleMenuImage(firestoreId: string): void {
    this.menuOpenStatesImage[firestoreId] = !this.menuOpenStatesImage[firestoreId];
  }

  openAnexarDialog(): void {
    console.log({ uid: this.contact.uid || null })
    const dialogRef = this.dialog.open(UserDocumentsComponent, {
      width: '600px', // Defina a largura do diálogo conforme sua necessidade
      data: { uid: this.contact.uid },
    });
  }

  openEditDialog(message: ChatMessageEntity): void {

    this.menuOpenStates[message.firestoreId] = false;
    this.menuOpenStatesImage[message.firestoreId] = false;
    const dialogRef = this.dialog.open(AlertInputComponent, {
      width: '600px',
      data: {
        alertTitle: 'Deseja editar a mensagem?',
        showInput: true,
        inputLabel: 'Novo conteúdo:',
        message
      },
    });

    dialogRef.afterClosed().toPromise().then(result => {
      if (result !== undefined) {
        const novoConteudo = result.inputValue;

        if (novoConteudo !== undefined && novoConteudo.trim() !== '') {
          console.log(novoConteudo)
          this.chatService.updateMessage(message, novoConteudo)
            .then(response => {
              console.log('Mensagem editada com sucesso:', response);
            })
            .catch(error => {
              console.error('Erro ao editar mensagem:', error);
            });
        }
      }
    });
  }

  openDeleteDialog(message: ChatMessageEntity): void {

    this.menuOpenStates[message.firestoreId] = false;
    this.menuOpenStatesImage[message.firestoreId] = false;
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '600px',
      data: {
        alertTitle: 'Deseja deletar a mensagem?',
        alertDescription: `Deseja realmente deletar a mensagem?`,
      },
    });

    console.log(message.contactId, message.firestoreId)

    dialogRef.afterClosed().toPromise().then(result => {
      if (result === true) {
        this.chatService.deleteMessage(message)
          .then(response => {
            console.log('Mensagem deletada com sucesso:', response);
          })
          .catch(error => {
            console.error('Erro ao deletar mensagem:', error);
          });
      }
    });
  }

  getContactName(contact: any): string {
    return contact.name ? contact.name : contact.contactName;
  }


  formatDate(date: any): string {
    if (date) {
      return new Date(timestampForDate(date)).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    } else {
      return '';
    }
  }

  formatWhatsapp(whatsapp: string): string {
    const number = whatsapp.replace(/\D/g, '');
    const formattedNumber = number.startsWith('55') ? number.slice(2) : number;
    const digits = formattedNumber.length === 11 ? 5 : 4;
    return formattedNumber.replace(new RegExp(`^(\\d{2})(\\d{${ digits }})(\\d{4})$`), '($1) $2-$3');
  }

  downloadImage(message: any): void {
    const imageUrl = message.image;
    const imageName = message.imageCaption;

    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = imageName;
    link.target = '_blank';
    link.click();
  }

  downloadDocuments(message: any): void {
    const documentUrl = message.document;
    const documentName = message.documentFileName;

    const link = document.createElement('a');
    link.href = documentUrl;
    link.download = documentName;
    link.target = '_blank';
    link.click();
  }

  blockRightClickMouse(event: MouseEvent): void {
    event.preventDefault();
    this.closeMenuContactSelected();
  }

  forwardNote(message): void {
    this.dialog.open(ForwardNotesComponent, {
      width: '70%',
      data: {
        customer: this.contact.uid,
        customerData: this.contact,
        noteText: message,
        noteFrom: 'USER',
      },
    });
  }

  openReplyMessageDialog(message: ChatMessageEntity): void {
    
    this.menuOpenStates[message.firestoreId] = false;
    this.menuOpenStatesImage[message.firestoreId] = false;
    const dialogRef = this.dialog.open(AlertInputComponent, {
      width: '600px',
      data: {
        alertTitle: 'Deseja responder a mensagem?',
        showInput: true,
        inputLabel: 'Novo conteúdo:',
        message: ''
      },
    });

    dialogRef.afterClosed().toPromise().then(result => {
      if (result !== undefined) {
        const novoConteudo = result.inputValue;
        console.log('novoConteudo', novoConteudo)
        if (novoConteudo !== undefined && novoConteudo.trim() !== '') {
          const data = {
            customerId: this.contact.uid,
            contactId: this.contact.whatsapp,
            sender: {
              uid: this.loggedUser.uid,
              name: this.loggedUser.name,
              phone: this.phoneValue,
              type: "admin"
            },
            recipient: {
              uid: this.contact.uid,
              name: this.getContactName(this.contact),
              phone: this.contact.whatsapp,
              type: "customer"
            },
            sendingType: "API Client",
            messageType: "WHATSAPP",
            replyMessage: {
              whatsappId: message.whatsappId,
              nameSender: message.sender.name,
              sendDate: message.sendDate,
              text: message.text,
              audio: message.audio,
              image: message.image,
              imageCaption: message.imageCaption,
              document: message.document,
              documentFileName: message.documentFileName,
              mimeType: message.mimeType
            },
            text: novoConteudo.replace(/\n/g, '<br>'),
          };

          this.chatService.updateReplyMessage(data)
            .then(response => {
              console.log('Mensagem editada com sucesso:', response);
            })
            .catch(error => {
              console.error('Erro ao editar mensagem:', error);
            });
        }
      }
    });
  }

  getStyleReply(type?: string): string {
    let style = 'reply-div';
    if (type && type !== '') style += '-' + type;
    return style;
  }
}
