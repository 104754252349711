<form class="user-security-content" [formGroup]="passwordFormGroup">
  <h3>Senha</h3>
  <label>Senha atual</label>
  <input class="input" type="password" placeholder="Digite sua senha atual" formControlName="oldPasswordCtrl" />
  <div *ngIf="passwordFormGroup.get('oldPasswordCtrl').invalid && (passwordFormGroup.get('oldPasswordCtrl').dirty || passwordFormGroup.get('oldPasswordCtrl').touched)">
    <div class="error" *ngIf="passwordFormGroup.get('oldPasswordCtrl').errors.required">O preenchimento do campo é obrigatório.</div>
  </div>
  <label>Nova senha</label>
  <input class="input" type="password" placeholder="Digite sua nova senha" formControlName="newPasswordCtrl" />
  <div *ngIf="passwordFormGroup.get('newPasswordCtrl').invalid && (passwordFormGroup.get('newPasswordCtrl').dirty || passwordFormGroup.get('newPasswordCtrl').touched)">
    <div class="error" *ngIf="passwordFormGroup.get('newPasswordCtrl').errors.required">O preenchimento do campo é obrigatório.</div>
  </div>
  <label>Confirmar nova senha</label>
  <input class="input" type="password" placeholder="Confirme sua nova senha" formControlName="confirmNewPasswordCtrl" />
  <div *ngIf="passwordFormGroup.hasError('passwordsNotMatched') && (passwordFormGroup.get('confirmNewPasswordCtrl').dirty || passwordFormGroup.get('confirmNewPasswordCtrl').touched)">
    <div class="error">As senhas não coincidem.</div>
  </div>
  <div class="btn-wrapper">
    <button class="button-goback" (click)="navigateToFirstLayer()">Voltar</button>
    <button class="button-save" (click)="changePassword()" [disabled]="passwordFormGroup.invalid">Salvar</button>
  </div>
  <mat-spinner *ngIf="isProcessingPassword" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
</form>
