<div class="content">
  <div class="d-flex">
    <div style="flex: 1">
     
    </div>
    <button mat-button class="d-flex align-items-center flex-row" (click)="logout()">
      <mat-icon fontSet="fas" fontIcon="fa-sign-out-alt" class="colored-icon mr-2"></mat-icon> Sair
    </button>
  </div>

  <section>
    <div>
      <span class="step-label">ETAPA 2 de 3: Sobre você</span>
      <h1>Confirme seu email</h1>
      <p>
        Enviamos um email de confirmação para <span style="font-weight: 600">{{ customer?.email }}</span
        >. Verifique-o para continuar. Se não encontrá-lo, procure também em sua caixa de spam.
      </p>
    </div>

    <div class="d-flex flex-column flex-lg-row">
      <div class="my-2 text-center">
        <a mat-stroked-button class="action-button text-black" href="https://mail.google.com" target="_blank">
          <img src="assets/images/icons/logo-gmail.svg" alt="gmail" class="mr-2" /> Abrir Gmail
        </a>
      </div>
      <div class="m-2 text-center">
        <a mat-stroked-button class="action-button text-black" href="https://outlook.live.com/" target="_blank">
          <img src="assets/images/icons/logo-outlook.svg" alt="outlook" class="mr-2" /> Abrir Outlook
        </a>
      </div>
      <div class="my-2 text-center">
        <a mat-stroked-button class="action-button text-black" href="https://mail.yahoo.com/" target="_blank">
          <img src="assets/images/icons/logo-yahoo.svg" alt="yahoo" class="mr-2" /> Abrir Yahoo
        </a>
      </div>
    </div>

    <!-- <div class="colored-box">
      <h1 class="header">Crédito pré-aprovado!</h1>
      <p class="m-0">
        Identificamos que você possui um limite de crédito de <span class="colored-600">{{ preApprovedLimit }}</span>*.
        Finalize seu cadastro para ter acesso a todas as oportunidades que temos pra te oferecer.
      </p>
    </div> -->

    <div class="d-flex align-items-center flex-column flex-lg-row">
      <p class="m-0">Não recebeu o e-mail de confirmação?</p>
      <div class="mt-2 mt-lg-0">
        <button
          mat-stroked-button
          class="action-button text-black autosized px-3 ml-3 d-inline-block"
          type="button"
          (click)="resendConfirmationEmail()"
          *ngIf="!isProcessing"
        >
          Reenviar e-mail de confirmação
        </button>
        <mat-spinner *ngIf="isProcessing" [diameter]="45" color="primary" class="ml-4"></mat-spinner>
      </div>
    </div>
  </section>
</div>
