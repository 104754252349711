import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from 'functions/src/models/Customer';
import { Document as DocumentMongoDB } from 'functions/src/models/documents/UserDocument';
import { Subscription } from 'rxjs';
import { Opportunity } from '../../../../../functions/src/models/opportunity/Opportunity';
import { timestampForDate } from '../../../../../functions/src/utils/dates';
import { OpportunityManagementService } from '../../../admin/services/opportunity-management.service';
import { ChatService } from '../../../chat/services/chat.service';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { CreditRecurrenceComponent } from '../../../components/credit-recurrence/credit-recurrence.component';
import { LogService } from '../../../components/logger/log.service';
import { AuthService } from '../../../core/auth/auth.service';
import { OpenFinanceService } from '../../../core/services/open-finance.service';
import { ContractService } from '../../services/contract.service';
import { CustomerService } from '../../services/customer.service';
import { SidebarStateService } from '../../services/sidebar-state.service';
import { UserDocumentService } from '../../services/user-document.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

  userSubscription: Subscription;
  customer: Customer;
  customerPendingDocuments: DocumentMongoDB[];
  pendingDocumentsLength: number;
  showMissingSignatureCard: boolean;
  showOpenFinanceBtn = false;
  phoneValue: string;
  hasOppsByRecurrence = false;
  customerUid: string;
  hasAccountant = false;
  isEditingAccountantData = false;
  accountantDataFormGroup: FormGroup;
  isCustomerNoTouch: boolean;
  enableLoading = true;
  isSaving = false;
  opportunities: Opportunity[];
  isBabyCustomer: boolean;

  constructor(
    public authService: AuthService,
    private customerService: CustomerService,
    private logger: LogService,
    private chatService: ChatService,
    private userDocumentService: UserDocumentService,
    private contractService: ContractService,
    private dialog: MatDialog,
    private oppManagementService: OpportunityManagementService,
    private openFinanceService: OpenFinanceService,
    private activatedRoute: ActivatedRoute,
    private sidebarStateService: SidebarStateService,
    private router: Router
  ) {
    this.logger.controllerName = this.constructor.name;
    const routeSnapshot = this.activatedRoute.snapshot;
    const routeData = routeSnapshot.data;
    this.sidebarStateService.updateCurrentSection(routeData);

    this.chatService.getPlugConfig().subscribe((data: any) => {
      this.phoneValue = data?.phone;
    });
  }


  async ngOnInit(): Promise<void> {

    // await this.handleOpenFinanceData();

    this.accountantDataFormGroup = new FormGroup({
      allowAccountantContact: new FormControl('', []),
      accountantEmail: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
      accountantPhone: new FormControl({ value: '', disabled: true }, [Validators.minLength(14)]),
    });
    this.userSubscription = this.customerService.customer.subscribe(async (customer) => {
      this.customer = customer;
      this.customerUid = this.customer?.uid;
      this.isCustomerNoTouch = this.customerService.checkCustomerNoTouch(this.customer);
      this.isBabyCustomer = this.customerService.checkCustomerBabyLayout(this.customer);

      if (this.customer?.hasOpenFinance) {
        this.showOpenFinanceBtn = true;
      }
      if (customer?.uid) {
        const uid = this.customer.uid;
        this.customerPendingDocuments = await this.userDocumentService.getDocumentsPending(uid);
        this.pendingDocumentsLength = this.customerPendingDocuments.length;
        this.customerPendingDocuments.forEach((doc) => {
          if (doc.mnemonic === 'carta_de_autorizacao_scr') {
            this.showMissingSignatureCard = true;

          }
        })

        this.hasAccountant = !!this.customer.accountantData?.email;
        if (this.hasAccountant) {
          this.accountantDataFormGroup.get('allowAccountantContact').setValue(true);
          this.accountantDataFormGroup.get('accountantEmail').setValue(this.customer.accountantData.email);
          this.accountantDataFormGroup.get('accountantPhone').setValue(this.customer.accountantData.phone || '');
        }

        this.enableLoading = false;
      }
      this.getOppotunities();
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreditRecurrenceComponent, {
      width: '250px',
      data: {
        customer: this.customer
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  navigateToSerasa() {
    this.router.navigate(['/minha-conta'], { queryParams: { tab: 'serasa' } });
  }

  validateSendEmailContract(): boolean {
    const guaranteesIds = this.customer.guarantees.map((guarantee) => guarantee.id);
    const revenueGreaterThan240000 = this.customer.revenue > 240000;
    const companyAgeGreaterThan2 = this.customer.companyInformation?.age > 2;

    const hasVehicleOrProperty = guaranteesIds.some((id) => id.includes('veiculos') || id.includes('imovel'));
    const hasDesiredGuarantee = guaranteesIds.some((id) => id.includes('avalista') || id.includes('boleto'));

    return (revenueGreaterThan240000 && (hasVehicleOrProperty || (hasDesiredGuarantee && companyAgeGreaterThan2)));
  }

  saveAccountantData(): void {
    this.isSaving = true;
    const accountantData = this.accountantDataFormGroup.get('allowAccountantContact').value
      ? {
        email: this.accountantDataFormGroup.get('accountantEmail').value,
        phone: this.accountantDataFormGroup.get('accountantPhone').value,
      }
      : null;

    this.customerService
      .updateCustomerOnFirestore(this.customer.uid, { accountantData })
      .then(() => {
        if (this.validateSendEmailContract() && accountantData.email) {
          this.contractService.sendEmailToAccountant(
            accountantData.email.toLowerCase(),
            this.customer.companyName?.toUpperCase(),
            this.customer.email.toLowerCase(),
          );
        }

        this.isSaving = false;
        this.isEditingAccountantData = false;

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Dados do contador salvos',
            alertDescription:
              'Recebemos os dados do seu contador. Agora podemos entrar em contato assim que for necessário acrescentar ou atualizar algum documento.',
            isOnlyConfirm: true,
          },
        });
      })
      .catch((err) => {
        this.isSaving = false;

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro',
            alertDescription: 'Ocorreu um erro ao salvar os dados do contador. Tente novamente.',
            isOnlyConfirm: true,
          },
        });

        console.error('Error saving accountant', err);

        this.logger.fatal(
          'Problemas ao atualizar os dados de contador do usuario',
          this.customer.email,
          this.accountantDataFormGroup.get('accountantEmail').value,
          err
        );
      });
  }

  onChangeAccountantContactAuthorization(): void {
    const valueSet = !this.accountantDataFormGroup.get('allowAccountantContact').value;
    this.accountantDataFormGroup.get('allowAccountantContact').setValue(valueSet);
    if (valueSet) {
      this.accountantDataFormGroup.get('accountantEmail').enable({ onlySelf: true });
      this.accountantDataFormGroup.get('accountantEmail').setErrors({ required: true });
      this.accountantDataFormGroup.get('accountantEmail').markAsUntouched();

      this.accountantDataFormGroup.get('accountantPhone').enable({ onlySelf: true });
      this.accountantDataFormGroup.get('accountantPhone').setErrors({ required: true });
      this.accountantDataFormGroup.get('accountantPhone').markAsUntouched();
    } else {
      this.accountantDataFormGroup.get('accountantEmail').setValue('');
      this.accountantDataFormGroup.get('accountantEmail').disable({ onlySelf: true });
      this.accountantDataFormGroup.get('accountantEmail').setErrors([]);

      this.accountantDataFormGroup.get('accountantPhone').setValue('');
      this.accountantDataFormGroup.get('accountantPhone').disable({ onlySelf: true });
      this.accountantDataFormGroup.get('accountantPhone').setErrors([]);
    }
  }

  formatPhone(phone: string): (RegExp | string)[] {
    if (phone.length < 15) {
      return ['(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      return ['(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
  }

  cancelEditing(): void {
    this.isEditingAccountantData = false;
    this.accountantDataFormGroup.get('allowAccountantContact').setValue(true);
    this.accountantDataFormGroup.get('accountantEmail').setValue(this.customer.accountantData.email);
    this.accountantDataFormGroup.get('accountantPhone').setValue(this.customer.accountantData.phone || '');
  }

  removesFilesToSignFromList(documentsList: DocumentMongoDB[]): DocumentMongoDB[] {
    const documents = documentsList?.filter((document) => {
      if (document?.documentAction === 'sign') {
        return (
          !!document.fileInfo?.find((info) => !!info?.path) &&
          document.qtySubmittedFiles >= document.qtyExpectedFiles &&
          document.situation !== 'Pendente'
        );
      } else {
        return true;
      }
    });

    return documents;
  }

  getOppotunities(): void {
    this.oppManagementService.getOpportunities(this.customerUid).then((opps) => {
      this.opportunities = opps;
      const [opportunity] = opps.sort((a, b) => a.closingDate < b.closingDate ? 1 : -1);
      const allOppsClosed = !opps?.find(opp => ![100, 0].includes(opp.stageOpportunity.defaultprobability));
      if (opportunity && allOppsClosed) {
        this.onHasOppsByRecurrence(opportunity);
      }
    });
  }

  onHasOppsByRecurrence(opp: Opportunity): void {
    const lastRecurrenceDate = this.customer.lastRecurrenceDate ? timestampForDate(this.customer.lastRecurrenceDate) : null;
    const closingDate = new Date(opp.closingDate);
    const dateOpp = !lastRecurrenceDate ? closingDate : (closingDate > lastRecurrenceDate ? closingDate : lastRecurrenceDate);
    const diffInMs = Math.abs(new Date().getTime() - dateOpp.getTime());
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    this.hasOppsByRecurrence = diffInDays >= 90;
  }

  redirectToOpenFinance(): void {
    this.router.navigate(['/open-finance-page']);
  }

  formatWhatsapp(phoneStr: string) {
    return phoneStr.replace(/[^0-9]/g, '');
  }
  
  async onClickAddGuarantee(guarantee: string) {
    const data = {
      customerId: this.customer.uid,
      contactId: this.formatWhatsapp(this.customer.whatsapp),
      sender: {
        uid: this.customer.uid,
        name: this.customer.name,
        phone: this.formatWhatsapp(this.customer.whatsapp),
        type: 'customer'
      },
      recipient: {
        uid: this.customer.attributedTo || '',
        name: 'Capital Empreendedor',
        phone: this.phoneValue,
        type: 'admin'
      },
      sendingType: 'API Client by client',
      messageType: 'Chat-SCE',
      text: `Quero adicionar um ${ guarantee } como garantia`,
    };
    console.log(data)
    await this.chatService.sendTextMessage(data, this.customer)
    try {

      console.log(data)
      this.router.navigate(['/mensagens']);
    }
    catch (error) {
      console.log('erro ao adicionar garantia')
    }
  }

}
