import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../components/shared.module';
import { AsideChatFaqComponent } from './components/aside-chat-faq/aside-chat-faq.component';
import { BndesCardComponent } from './components/bndes-card/bndes-card.component';
import { CarrosselComponent } from './components/carrossel/carrossel.component';
import { CustomerCreditDialogComponent } from './components/customer-credit-dialog/customer-credit-dialog.component';
import { CustomerOnboardingDialogComponent } from './components/customer-onboarding/customer-onboarding.component';
import { DeleteAccountComponent } from './components/delete-account/delete-account.component';
import { DescriptionOfferDialogComponent } from './components/description-offer-dialog/description-offer-dialog.component';
import { OpenFinanceDialogComponent } from './components/open-finance-dialog/open-finance-dialog.component';
import { UserAccountDataComponent } from './components/user-account-data/user-account-data.component';
import { UserCompanyDataComponent } from './components/user-company-data/user-company-data.component';
import { UserSecurityFirstLayerComponent } from './components/user-security-first-layer/user-security-first-layer.component';
import { UserSecurityComponent } from './components/user-security/user-security.component';
import { AgentComponent } from './containers/agent/agent.component';
import { CreateAccountComponent } from './containers/create-account/create-account.component';
import { CustomEmailActionsComponent } from './containers/custom-email-actions/custom-email-actions.component';
import { CustomerChangePasswordComponent } from './containers/customer-change-password/customer-change-password.component';
import { CustomerSettingsComponent } from './containers/customer-settings/customer-settings.component';
import { DuvidasFrequentesComponent } from './containers/duvidas-frequentes/duvidas-frequentes.component';
import { EmailInformationComponent } from './containers/email-information/email-information.component';
import { HomeComponent } from './containers/home/home.component';
import { UserLoginComponent } from './containers/login/login.component';
import { MainComponent } from './containers/main/main.component';
import { PrivacyPolicyComponent } from './containers/privacy-policy/privacy-policy.component';
import { TermsComponent } from './containers/terms/terms.component';
import { UserContractComponent } from './containers/user-contract/user-contract.component';
import { UserCreditLinesComponent } from './containers/user-credit-lines/user-credit-lines.component';
import { UserCupomComponent } from './containers/user-cupom/user-cupom.component';
import { UserDocumentComponent } from './containers/user-document/user-document.component';
import { UserNotesContainerComponent } from './containers/user-notes-container/user-notes-container.component';
import { CustomerRoutes } from './customer-routing.module';
import { OpenFinanceInfoPageComponent } from './open-finance-info-page/open-finance-info-page.component';


@NgModule({
  imports: [CommonModule, SharedModule, RouterModule.forChild(CustomerRoutes)],
  declarations: [
    MainComponent,
    CreateAccountComponent,
    UserLoginComponent,
    CustomEmailActionsComponent,
    UserDocumentComponent,
    DuvidasFrequentesComponent,
    UserContractComponent,
    AgentComponent,
    EmailInformationComponent,
    TermsComponent,
    UserNotesContainerComponent,
    CustomerOnboardingDialogComponent,
    CustomerChangePasswordComponent,
    PrivacyPolicyComponent,
    HomeComponent,
    CustomerCreditDialogComponent,
    UserCreditLinesComponent,
    UserCupomComponent,
    BndesCardComponent,
    UserAccountDataComponent,
    CustomerSettingsComponent,
    UserCompanyDataComponent,
    UserSecurityComponent,
    UserSecurityFirstLayerComponent,
    DeleteAccountComponent,
    DescriptionOfferDialogComponent,
    OpenFinanceDialogComponent,
    OpenFinanceInfoPageComponent,
    CarrosselComponent,
    AsideChatFaqComponent
  ],
})
export class CustomerModule {}
