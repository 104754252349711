import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Agent } from 'functions/src/models/Agent';
import { Banker } from 'functions/src/models/Banker';
import { User } from 'functions/src/models/User';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { Subscription } from 'rxjs';
import { UserManagementService } from 'src/app/admin/services/user-management.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { TemplateSignupService as ImageService } from 'src/app/layouts/templates/template-signup/template-signup.service';

import { Customer } from '../../../../functions/src/models/Customer';
import { CustomerService } from '../../customer/services/customer.service';
import { AgentService } from './../../agents/services/agent.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
 
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  @Input() banker: Banker = null;
  @Input() agent: Agent = null;
  readonly MASKS = MASKS;

  formatPhone = UtilHandler.formatPhone;

  authSubscription: Subscription;
  userDataSubscription: Subscription;

  passwordFormGroup: FormGroup;
  emailFormGroup: FormGroup;
  personalInformationFormGroup: FormGroup;
  exclusedAccountFormGroup: FormGroup;

  user: User;
customer: Customer;
customerUID: string;
  isLoading = true;
  isProcessingPassword = false;
  isProcessingEmail = false;
  isProcessingExclused = false;
  resetEmailWasSent = false;
  isUpdateError = false;

  readonly reasonOptions = {
    agentsAndBanker: [
      {
        id: 'Sem motivo',
        value: 'Sem motivo',
      },
      {
        id: 'Mudança de área/função',
        value: 'Mudança de área/função',
      },
      {
        id: 'Insatisfeito com os resultados',
        value: 'Insatisfeito com os resultados',
      },
    ],
    customers: [
      {
        id: 'Sem motivo',
        value: 'Sem motivo',
      },
      {
        id: 'Não concorda com a taxa',
        value: 'Não concorda com a taxa',
      },
      {
        id: 'Queria apenas uma simulação',
        value: 'Queria apenas uma simulação',
      },
      {
        id: 'Achava que a plataforma fazia uma simulação instantanea',
        value: 'Achava que a plataforma fazia uma simulação instantanea',
      },
    ],
  };

  constructor(
    private authService: AuthService,
    private agentService: AgentService,
    private dialog: MatDialog,
    private imageService: ImageService,
    private formBuilder: FormBuilder,
    private router: Router,
    private ums: UserManagementService,
    private customerService: CustomerService,
  ) {
    this.imageService.setImagePath('assets/images/signup/illustration1.svg');
  }

  ngOnInit(): void {
    this.createFormGroup();
    this.authSubscription = this.authService.user.subscribe((user) => {
      if (user) {
        this.user = user;

        if (this.user.isAgent) {
          this.userData('agent');
        } else {
          this.isLoading = false;
        }
      }
    });
    this.authSubscription.unsubscribe();

    this.userDataSubscription = this.customerService.customer.subscribe((customer) => {
      this.customer = customer


      if (this.customer?.uid) {
      
      }

    });
  }

  ngOnDestroy(): void {
    //
  }

  userData(userType: string): void {
    if (userType === 'agent') {
      if (this.agent) {
        this.personalInformationFormGroup.get('nameCtrl').setValue(this.agent.name);
        this.personalInformationFormGroup.get('emailCtrl').setValue(this.agent.email);
        this.personalInformationFormGroup.get('phoneCtrl').setValue(this.agent.phone);
        this.personalInformationFormGroup.get('CNPJCtrl').setValue(this.agent.cnpj);
        this.personalInformationFormGroup.get('linkedinCtrl').setValue(this.agent.linkedin || '');
      }

      this.isLoading = false;
    }
  }

  createFormGroup(): void {
    this.passwordFormGroup = this.formBuilder.group(
      {
        oldPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
        newPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmNewPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
      },
      { validators: this.passwordMatchValidator }
    );

    this.emailFormGroup = this.formBuilder.group(
      {
        oldEmailCtrl: new FormControl('', [Validators.required, Validators.email]),
        newEmailCtrl: new FormControl('', [Validators.required, Validators.email]),
        confirmNewEmailCtrl: new FormControl('', [Validators.required, Validators.email]),
        passwordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
      },
      { validators: [this.emailMatchValidator, this.newEmailFormatValidator] }
    );

    this.personalInformationFormGroup = this.formBuilder.group({
      nameCtrl: new FormControl('', Validators.required),
      emailCtrl: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
      phoneCtrl: new FormControl('', [Validators.required, NgBrazilValidators.telefone]),
      CNPJCtrl: new FormControl('', [NgBrazilValidators.cnpj]),
      linkedinCtrl: new FormControl('', Validators.required),
      passwordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });

    this.exclusedAccountFormGroup = this.formBuilder.group({
      oldPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
      motivationCtrl: new FormControl('', [Validators.required]),
    });
  }

  async editUser(): Promise<void> {
    this.isLoading = true;

    const cnpj = this.personalInformationFormGroup.get('CNPJCtrl').value;
    let isValidCNPJ = true;
    // CNPJ has changed
    if (cnpj && this.agent.cnpj !== cnpj) {
      try {
        await this.agentService.checkIfCNPJExists(cnpj);
      } catch (err) {
        console.error(err);
        isValidCNPJ = false;
        if (err === 'existent-cnpj') {
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'CNPJ em uso',
              alertDescription: `O CNPJ ${cnpj} já está em uso. Favor entrar em contato através do e-mail <strong>ops@capitalempreendedor.com.br</strong> informando o ocorrido.`,
              isOnlyConfirm: true,
            },
          });
        } else {
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro ao checar CNPJ',
              alertDescription: 'Ocorreu um erro ao verificar o CNPJ informado. Tente novamente mais tarde.',
              isOnlyConfirm: true,
            },
          });
        }
        this.isLoading = false;
      }
    }

    if (this.personalInformationFormGroup.valid && isValidCNPJ) {
      this.authService
        .loginWithEmailAndPassword(
          this.personalInformationFormGroup.get('emailCtrl').value,
          this.personalInformationFormGroup.get('passwordCtrl').value
        )
        .then(() => {
          console.log('Signed in');
          if (this.hasChangedFieldValues()) {
            console.log('Changed only data');

            this.changeFields()
              .then(() => {
                console.log('Data successfully saved');

                this.personalInformationFormGroup.get('passwordCtrl').reset();
                this.personalInformationFormGroup.get('passwordCtrl').setErrors(null);
                this.personalInformationFormGroup.get('passwordCtrl').markAsUntouched();

                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Dados alterados',
                    alertDescription: 'Seus dados foram alterados com sucesso.',
                    isOnlyConfirm: true,
                  },
                });

                this.isLoading = false;
              })
              .catch((err) => {
                console.error('Error changing data', err);

                this.personalInformationFormGroup.get('passwordCtrl').reset();
                this.personalInformationFormGroup.get('passwordCtrl').setErrors(null);
                this.personalInformationFormGroup.get('passwordCtrl').markAsUntouched();

                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao salvar dados',
                    alertDescription: 'Ocorreu um erro ao salvar seus dados. Tente novamente mais tarde.',
                    isOnlyConfirm: true,
                  },
                });

                this.isLoading = false;
              });
          }
        })
        .catch((err) => {
          console.error('Erro ao reautenticar.', err);

          this.personalInformationFormGroup.get('passwordCtrl').reset();
          this.personalInformationFormGroup.get('passwordCtrl').setErrors(null);
          this.personalInformationFormGroup.get('passwordCtrl').markAsUntouched();

          if ((err.code = 'auth/wrong-password')) {
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Senha incorreta',
                alertDescription: 'A senha atual informada é incorreta. Verifique e tente novamente.',
                isOnlyConfirm: true,
              },
            });
          } else {
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao autenticar',
                alertDescription: 'Ocorreu um erro ao autenticar. Tente novamente mais tarde.',
                isOnlyConfirm: true,
              },
            });
          }

          this.isLoading = false;
        });
    }
  }

  hasChangedFieldValues(): boolean {
    if (
      this.agent.name !== this.personalInformationFormGroup.get('nameCtrl').value.trim().toUpperCase() ||
      this.agent.phone !== this.personalInformationFormGroup.get('phoneCtrl').value ||
      this.agent.cnpj !== this.personalInformationFormGroup.get('CNPJCtrl').value ||
      this.agent.linkedin !== this.personalInformationFormGroup.get('linkedinCtrl').value
    ) {
      return true;
    }
    return false;
  }

  changeFields(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.agent) {
        this.agentService
          .updateAgentData(this.agent.uid, {
            name: this.personalInformationFormGroup.get('nameCtrl').value.trim().toUpperCase(),
            displayName: this.personalInformationFormGroup.get('nameCtrl').value.trim().toUpperCase(),
            phone: this.personalInformationFormGroup.get('phoneCtrl').value,
            cnpj: this.personalInformationFormGroup.get('CNPJCtrl').value || '',
            linkedin: this.personalInformationFormGroup.get('linkedinCtrl').value || '',
          })
          .then((res) => resolve(res))
          .catch((err) => {
            if (err === 'salesforce-error') {
              this.isUpdateError = true;
            } else {
              this.isUpdateError = false;
            }
            reject(err);
          });
      }
    });
  }

  shouldButtonDisable(): boolean {
    return !(
      (this.hasChangedFieldValues() || this.isUpdateError) &&
      this.personalInformationFormGroup.get('passwordCtrl').value
    );
  }

  confirmOldEmail(controlName: string): void {
    console.log(this.emailFormGroup.controls[controlName].value);
    const error = this.emailFormGroup.controls[controlName].value === this.user.email ? null : { mismatch: true };
    this.emailFormGroup.get(controlName).setErrors(error);
  }

  emailMatchValidator(formGroup: FormGroup): void {
    const error =
      formGroup.get('newEmailCtrl').value === formGroup.get('confirmNewEmailCtrl').value ? null : { mismatch: true };
    formGroup.get('confirmNewEmailCtrl').setErrors(error);
  }

  newEmailFormatValidator(formGroup: FormGroup): void {
    const error =
      formGroup.get('newEmailCtrl')?.value !== '' && formGroup.get('newEmailCtrl')?.value.match(UtilHandler.emailRegex)
        ? null
        : { format: true };
    formGroup.get('newEmailCtrl')?.setErrors(error);
  }

  passwordMatchValidator(formGroup: FormGroup): void {
    const error =
      formGroup.get('newPasswordCtrl').value === formGroup.get('confirmNewPasswordCtrl').value
        ? null
        : { mismatch: true };
    formGroup.get('confirmNewPasswordCtrl').setErrors(error);
  }

  changePassword(): void {
    this.isProcessingPassword = true;

    const newPassword = this.passwordFormGroup.get('newPasswordCtrl').value;
    const oldPassword = this.passwordFormGroup.get('oldPasswordCtrl').value;

    this.authService
      .loginWithEmailAndPassword(this.user.email, oldPassword)
      .then(() => {
        this.authService
          .changePassword(this.user.uid, newPassword)
          .then(() => {
            this.user.changedPassword = true;
            return this.authService.updateUser(this.user);
          })
          .then(() => {
            this.isProcessingPassword = false;
            const msgSub = this.showMessage('Senha Atualizada', 'Sua senha foi atualizada com sucesso.')
              .afterClosed()
              .subscribe(() => {
                msgSub.unsubscribe();
              });
            this.ngOnInit();
          })
          .catch((err) => {
            console.error(err);
            this.isProcessingPassword = false;
            this.showMessage(
              'Erro ao Atualizar Senha',
              'Não foi possível atualizar sua senha, tente novamente mais tarde.'
            );
          });
      })
      .catch(() => {
        this.showMessage('Senha Incorreta', 'Verifique sua senha e tente novamente');
        this.isProcessingPassword = false;
      });
  }
  logout(): void {
    this.authService.logout().then(() => {
      this.router.navigate(['/entrar']);
    });
  }
  changeEmail(): void {
    this.isProcessingEmail = true;

    try {
      if (this.emailFormGroup.valid) {
        const password = this.emailFormGroup.get('passwordCtrl').value;
        const oldEmail = this.emailFormGroup.get('oldEmailCtrl').value;

        this.authService
          .loginWithEmailAndPassword(oldEmail, password)
          .then(() => {
            this.dialog
              .open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Confirmar alteração de email',
                  alertDescription: 'Tem certeza que deseja alterar o email?',
                  isOnlyConfirm: false,
                },
              })
              .afterClosed()
              .subscribe(async (res) => {
                if (res) {
                  const newEmail = this.emailFormGroup.get('newEmailCtrl').value;
                  const emailUsed = await this.authService.checkIfUserEmailExists(newEmail?.toLowerCase());

                  // IF email not exist
                  if (!emailUsed) {
                    this.authService
                      .changeEmail(newEmail)
                      .then(() => {
                        this.user.changedPassword = true;
                        return this.authService.updateUser(this.user);
                      })
                      .then(() => {
                        this.isProcessingPassword = false;
                        const msgSub = this.showMessage('Email Atualizado', 'Seu email foi atualizado com sucesso.')
                          .afterClosed()
                          .subscribe(() => {
                            msgSub.unsubscribe();
                          });
                        this.emailFormGroup.get('passwordCtrl').reset();
                        this.emailFormGroup.get('passwordCtrl')?.setErrors(null);
                        this.emailFormGroup.get('passwordCtrl').markAsUntouched();

                        this.emailFormGroup.get('oldEmailCtrl')?.setValue('');
                        this.emailFormGroup.get('oldEmailCtrl')?.setErrors(null);
                        this.emailFormGroup.get('oldEmailCtrl')?.markAsUntouched();

                        this.emailFormGroup.get('confirmNewEmailCtrl')?.setValue('');
                        this.emailFormGroup.get('confirmNewEmailCtrl')?.setErrors(null);
                        this.emailFormGroup.get('confirmNewEmailCtrl')?.markAsUntouched();

                        this.emailFormGroup.get('newEmailCtrl')?.setValue('');
                        this.emailFormGroup.get('newEmailCtrl')?.setErrors(null);
                        this.emailFormGroup.get('newEmailCtrl')?.markAsUntouched();
                      })
                      .catch((err) => {
                        console.error(err);
                        this.isProcessingPassword = false;
                        this.showMessage(
                          'Erro ao Atualizar Email',
                          'Não foi possível atualizar seu email, tente novamente mais tarde.'
                        );
                      })
                      .finally(() => {
                        this.isProcessingEmail = false;
                      });
                  } else {
                    this.showMessage(
                      'E-mail já cadastrado',
                      'O e-mail informado já possui uma conta. Tente outro email.'
                    );
                  }
                }
              });
          })
          .catch(() => {
            this.showMessage('Senha Incorreta', 'Verifique sua senha e tente novamente');
            this.isProcessingPassword = false;
          });
      }
    } catch (err) {
      console.error('Erro ao reautenticar.', err, err.code === 'auth/wrong-password');

      this.emailFormGroup.get('passwordCtrl').reset();
      this.emailFormGroup.get('passwordCtrl').setErrors(null);
      this.emailFormGroup.get('passwordCtrl').markAsUntouched();

      if (err.code === 'auth/wrong-password') {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Senha incorreta',
            alertDescription: 'A senha atual informada é incorreta. Verifique e tente novamente.',
            isOnlyConfirm: true,
          },
        });
      } else {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao autenticar',
            alertDescription: 'Ocorreu um erro ao autenticar. Tente novamente mais tarde.',
            isOnlyConfirm: true,
          },
        });
      }
    } finally {
      this.isProcessingEmail = false;
    }
  }

  exclusedAccount(): void {
    const { motivationCtrl: motivation, oldPasswordCtrl: oldPassword } = this.exclusedAccountFormGroup.getRawValue();
    const { uid, mainRole, email } = this.user;
    const deletedBy = 'user';

    this.authService
      .loginWithEmailAndPassword(email, oldPassword)
      .then(() => {
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Excluir Conta',
              alertDescription: 'Deseja enviado o seu pedido de excluir sua conta para nossa equipe?',
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.isProcessingExclused = true;
              if (uid) {
                this.ums
                  .deleteUser(uid, mainRole, motivation.id, deletedBy)
                  .then(() => {
                    this.showMessage(
                      'Usuário Deletado',
                      'Foi enviado o seu pedido de excluir sua conta para nossa equipe.'
                    );
                  })
                  .catch((err) => {
                    this.showMessage(
                      'Erro ao Deletar',
                      'Erro ao enviado o seu pedido de excluir sua conta para nossa equipe.'
                    );
                    console.error('Erro ao Deletar o usuário: ', err);
                  })
                  .finally(() => {
                    this.isProcessingExclused = false;
                  });
              } else {
                this.showMessage(
                  'Erro ao Deletar',
                  'Erro ao enviado o seu pedido de excluir sua conta para nossa equipe.'
                );
                console.error('Erro ao Deletar o usuário - O usuário não tem uid');
              }
            }
          });
      })
      .catch(() => {
        this.showMessage('Senha Incorreta', 'Verifique sua senha e tente novamente');
        this.isProcessingExclused = false;
      });
  }

  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }
}
