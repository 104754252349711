import { FormGroup } from '@angular/forms';

export class PartnershipsEntity {
  banner?: string; // URL do banner
  isConfirmationEmail: boolean; // Indica se é necessário confirmar o email
  isPassword: boolean; // Indica se é necessário uma senha
  isTerm: boolean; // Indica se são necessários termos de serviço
  logo?: string; // URL do logo
  onboardingTexts?: {
    subtitle?: string; // Subtítulo
    title?: string; // Título 
  }; // Mapa de textos para o onboarding
  partner: string; // Parceiro
  tenantId?: string;
  corDeFundo?: string;
  corDaFonte?: string;
  fontTitleSize?: string;
  fontSubTitleSize?: string;
  order?: string;

  constructor() {
    this.partner = '';
    this.tenantId = '';
    this.onboardingTexts = {
      title: '',
      subtitle: '',
    };
    this.isTerm = true;
    this.isPassword = true;
    this.isConfirmationEmail = true;
    this.banner = '';
    this.logo = '';
    this.corDaFonte = '';
    this.corDeFundo = '';
    this.fontSubTitleSize = '';
    this.fontTitleSize = '';
    this.order = '';
  }
  static generatePartnerByForm(createFormGroup: FormGroup): PartnershipsEntity {
    const formRaw = createFormGroup.getRawValue()
    const partnerData = new PartnershipsEntity();

    partnerData.partner = formRaw.nameCtrl.toUpperCase();
    partnerData.tenantId = formRaw.tenantIdCtrl;
    partnerData.onboardingTexts = {
      title: formRaw.tituloCtrl,
      subtitle: formRaw.subtituloCtrl,
    };
    partnerData.isTerm = formRaw.isTermCtrl;
    partnerData.isPassword = formRaw.isPasswordCtrl;
    partnerData.isConfirmationEmail = formRaw.isConfirmationEmailCtrl;
    partnerData.banner = formRaw.bannerCtrl;
    partnerData.logo = formRaw.logoCtrl;
    partnerData.corDaFonte = formRaw.corDaFonteCtrl || null;
    partnerData.corDeFundo = formRaw.corDeFundoCtrl || null;
    partnerData.fontSubTitleSize = formRaw.fontSubTitleSizeCtrl;
    partnerData.fontTitleSize = formRaw.fontTitleSizeCtrl;
    partnerData.order = formRaw.orderCtrl;


    return partnerData;
  }

}
