<div *ngIf="!isCreateEdit">
  <mat-card>
    <mat-card-content>
      <div class="d-flex align-items-start">
        <div style="flex: 1">
          <mat-card-title>Tipos de Documentos</mat-card-title>
          <mat-card-subtitle> Consulte e altere os dados associados as intituições financeiras. </mat-card-subtitle>
        </div>
        <div>
          <button mat-raised-button *ngIf="loggedAdmin?.accessLevel === 'master' || loggedAdmin?.accessLevel === 'admin'" class="btn" color="primary" type="button" (click)="onAdicionarNovo()">
            <mat-icon>add</mat-icon>
            &nbsp;Novo
          </button>
        </div>
      </div>

      <div style="margin-top: 15px">
        <mat-form-field>
          <input matInput (input)="applyFilter($event.target.value)" placeholder="Filtro" />
        </mat-form-field>
      </div>
      <div>
        <table
          mat-table
          multiTemplateDataRows
          [dataSource]="dataSourceTipoDocumentos"
          matSort
          #matSortTipoDocumentos="matSort"
        >
          <ng-container matColumnDef="nome">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Nome</th>
            <td mat-cell *matCellDef="let tp">{{ tp.nome }}</td>
          </ng-container>
          <ng-container matColumnDef="mnemonico">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Mnemonico</th>
            <td
              mat-cell
              *matCellDef="let tp"
              class="mnemonico"
              matTooltip="{{ tp.mnemonico }}"
              matTooltipClass="mnemonico-tooltip"
              aria-label="Button that shows a red tooltip"
            >
              {{ tp.mnemonico.length > 40 ? tp.mnemonico.slice(0, 40) + '...' : tp.mnemonico }}
            </td>
          </ng-container>
          <ng-container matColumnDef="ativo">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Ativo</th>
            <td mat-cell *matCellDef="let tp">{{ tp.ativo.name }}</td>
          </ng-container>
          <ng-container matColumnDef="acao">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let tp" (click)="$event.stopPropagation()">
              <button mat-flat-button *ngIf="loggedAdmin?.accessLevel === 'master' || loggedAdmin?.accessLevel === 'admin'" (click)="onEditar(tp)" matTooltip="Editar">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let tp" [attr.colspan]="displayedColumns.length">
              <div
                class="example-element-detail"
                [@detailExpand]="expandedElementId === tp.id && tp.ativo ? 'expanded' : 'collapsed'"
                [style.height]="expandedElementId === tp.id ? 'unset' : '0 !important'"
              >
                <div class="py-3">
                  <p><b>Descrição:</b></p>
                  <div style="white-space: pre-wrap">{{ tp.description || '-' }}</div>
                </div>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let tp; columns: displayedColumns"
            class="example-element-row taskboard-task"
            [class.example-expanded-row]="expandedElementId === tp.id"
            (click)="expandedElement = expandedElement === tp ? null : tp; onClickExpanded()"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="isCreateEdit">
  <mat-card>
    <mat-card-content>
      <div class="d-flex flex-row">
        <div style="padding-right: 10px">
          <button mat-mini-fab color="primary" type="button" (click)="onVoltar()" matTooltip="Voltar a listagem">
            <mat-icon>chevron_left</mat-icon>
          </button>
        </div>
        <div>
          <mat-card-title>
            Tipos de Documentos <span style="font-weight: 500">{{ ' - ' + tituloTipoDocumento }}</span>
          </mat-card-title>
          <mat-card-subtitle>
            Consulte, adicione e altere os tipos de documentos que as instituições financeiras podem requisitar.
          </mat-card-subtitle>
        </div>
      </div>
      <form [formGroup]="createEditFormGroup" (submit)="onSaveForm()">
        <mat-form-field style="width: 100%">
          <input type="hidden" formControlName="idCtrl" name="id" value="" />
          <input
            matInput
            placeholder="Nome"
            formControlName="nomeCtrl"
            required
            name="nome"
            (input)="slugifName($event.target.value)"
          />
          <mat-error *ngIf="createEditFormGroup.controls['nomeCtrl'].hasError('required')">Informe um nome.</mat-error>
        </mat-form-field>
        <mat-form-field style="width: 100%">
          <input
            matInput
            placeholder="Mnemonico (preenchimento automático)"
            formControlName="mnemonicoCtrl"
            required
            name="mnemonico"
          />
        </mat-form-field>
        <mat-form-field style="width: 100%">
          <textarea
            matInput
            placeholder="Descrição"
            formControlName="descricaoCtrl"
            required
            name="nome"
            matTextareaAutosize
            matAutosizeMinRows="2"
          ></textarea>
          <mat-error *ngIf="createEditFormGroup.controls['descricaoCtrl'].hasError('required')"
            >Informe uma descrição.</mat-error
          >
        </mat-form-field>

        <!----Fields for Guarantees--->
        <div class="col-12 d-flex p-0 m-0">
          <div class="flex-fill {{ hasTypeOfAnticipation ? 'mr-4' : 'mr-0' }}">
            <app-form-field-select
              fieldName="Garantias"
              controlName="guaranteesCtrl"
              (selectionChange)="onChangeGuarantees($event)"
              [formGroup]="createEditFormGroup"
              [options]="config?.guarantees"
              [compareWith]="true"
              [multiple]="true"
              [required]="false"
            ></app-form-field-select>
          </div>

          <app-form-field-select
            class="flex-fill"
            *ngIf="hasTypeOfAnticipation"
            fieldName="Antecipação de Recebíveis"
            controlName="typeOfAnticipationCtrl"
            [formGroup]="createEditFormGroup"
            [options]="selectTypeOfAnticipationOperator"
            [multiple]="true"
            [required]="hasTypeOfAnticipation"
          ></app-form-field-select>
        </div>

        <div class="d-flex flex-column">
          <div class="example-radio-button d-flex flex-column">
            <div class="d-flex align-content-start flex-wrap">
              <mat-form-field>
                <mat-label style="font-weight: 400">Ativo? </mat-label>
                <mat-select
                  placeholder="Ativo?"
                  formControlName="ativoCtrl"
                  name="ativo"
                  required
                  [compareWith]="compareObjects"
                >
                  <mat-option *ngFor="let ativoCtrl of opcaoSimNao" [value]="ativoCtrl">
                    {{ ativoCtrl.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    createEditFormGroup.controls['ativoCtrl'].hasError('required') &&
                    createEditFormGroup.controls['ativoCtrl'].touched
                  "
                >
                  Informe se esse tipo de documento está ativo.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="example-radio-button example-radio-group">
            <div class="d-flex flex-column flex-md-row">
              <mat-form-field>
                <mat-label style="font-weight: 400">Exigido automaticamente? </mat-label>
                <mat-select
                  placeholder="Exigido automaticamente?"
                  formControlName="automaticoCtrl"
                  name="automatico"
                  required
                  [compareWith]="compareObjects"
                >
                  <mat-option *ngFor="let automaticoCtrl of opcaoSimNao" [value]="automaticoCtrl">
                    {{ automaticoCtrl.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    createEditFormGroup.controls['automaticoCtrl'].hasError('required') &&
                    createEditFormGroup.controls['automaticoCtrl'].touched
                  "
                >
                  Informe se esse tipo de documento será exibido automaticamente.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="example-radio-button example-radio-group">
            <div class="d-flex flex-column flex-md-row">
              <mat-form-field>
                <mat-label style="font-weight: 400">Visivel para banker?? </mat-label>
                <mat-select
                  placeholder="Visivel para banker?"
                  formControlName="visibleToBankerCtrl"
                  name="visibleToBanker"
                  required
                  [compareWith]="compareObjects"
                >
                  <mat-option *ngFor="let visibleToBankerCtrl of opcaoSimNao" [value]="visibleToBankerCtrl">
                    {{ visibleToBankerCtrl.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    createEditFormGroup.controls['visibleToBankerCtrl'].hasError('required') &&
                    createEditFormGroup.controls['visibleToBankerCtrl'].touched
                  "
                >
                  Informe se esse tipo de documento será exibido visivel para o banker.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="example-radio-button example-radio-group">
            <div class="d-flex flex-column flex-md-row">
              <mat-form-field>
                <mat-label>Tem Validade? </mat-label>
                <mat-select
                  placeholder="Tem validade?"
                  formControlName="validadeCtrl"
                  name="validade"
                  required
                  [compareWith]="compareObjects"
                  (selectionChange)="onChangeTemValidade($event)"
                >
                  <mat-option *ngFor="let validadeCtrl of opcaoSimNao" [value]="validadeCtrl">
                    {{ validadeCtrl.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    createEditFormGroup.controls['validadeCtrl'].hasError('required') &&
                    createEditFormGroup.controls['validadeCtrl'].touched
                  "
                >
                  Informe se esse tipo de documeto tem validade.
                </mat-error>
              </mat-form-field>
              <mat-form-field *ngIf="hasValidade" style="padding-left: 20px">
                <input
                  matInput
                  type="number"
                  placeholder="Qtd. dias"
                  formControlName="qtdDiasCtrl"
                  required
                  name="qtdDias"
                />
                <mat-error *ngIf="createEditFormGroup.controls['qtdDiasCtrl'].hasError('required')">
                  Informe a quantidade de dias de validade do documento.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="example-radio-button example-radio-group">
            <div class="d-flex flex-column flex-md-row">
              <mat-form-field>
                <mat-label style="font-weight: 400">Tem ano de exercício? </mat-label>
                <mat-select
                  placeholder="Tem ano de exercício?"
                  formControlName="anoExercicioCtrl"
                  name="anoExercicio"
                  required
                  [compareWith]="compareObjects"
                  (selectionChange)="onChangeTemAnoExercicio($event)"
                >
                  <mat-option *ngFor="let anoExercicioCtrl of opcaoSimNao" [value]="anoExercicioCtrl">
                    {{ anoExercicioCtrl.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    createEditFormGroup.controls['anoExercicioCtrl'].hasError('required') &&
                    createEditFormGroup.controls['anoExercicioCtrl'].touched
                  "
                >
                  Informe se esse tipo de documento tem ano de exercício.
                </mat-error>
              </mat-form-field>
              <mat-form-field
                *ngIf="createEditFormGroup.controls['anoExercicioCtrl'].value.id === 'sim'"
                style="padding-left: 20px"
              >
                <input
                  matInput
                  type="number"
                  placeholder="Qtd. anos exercício"
                  formControlName="anoExercicioQtdCtrl"
                  required
                  name="anoExercicioQtd"
                  min="1"
                  max="999999"
                />
                <mat-error *ngIf="createEditFormGroup.controls['anoExercicioQtdCtrl'].hasError('required')">
                  Informe a quantidade de anos de exercício.
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="example-radio-button example-radio-group">
          <div class="d-flex flex-column flex-md-row">
          <mat-form-field  *ngIf="createEditFormGroup.controls['anoExercicioCtrl'].value.id === 'sim'"
          style="padding-left: 0px">
            <mat-label style="font-weight: 400">Ano de Exercício Corrente </mat-label>
            <mat-select
              placeholder="Tem Ano de Exercício Corrente?"
              formControlName="anoExercicioCorrenteCtrl"
              name="anoExercicioCorrente"
              required
              [compareWith]="compareObjects"
             
            >
              <mat-option *ngFor="let anoExercicioCorrenteCtrl of opcaoSimNao" [value]="anoExercicioCorrenteCtrl">
                {{ anoExercicioCorrenteCtrl.name }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                createEditFormGroup.controls['anoExercicioCorrenteCtrl'].hasError('required') &&
                createEditFormGroup.controls['anoExercicioCorrenteCtrl'].touched
              "
            >
              Informe se esse tipo de documento tem ano de Exercício Corrente.
            </mat-error>
          </mat-form-field>
        </div>
        </div>
          <div class="example-radio-button d-flex flex-column">
            <div class="d-flex align-content-start flex-wrap">
              <mat-form-field>
                <mat-label style="font-weight: 400">Permite upload de múltiplos arquivo? </mat-label>
                <mat-select
                  placeholder="Permite upload de múltiplos arquivo?"
                  formControlName="multipleDocumentsCtrl"
                  name="multipleDocuments"
                  required
                  [compareWith]="compareObjects"
                >
                  <mat-option *ngFor="let multipleDocumentsCtrl of opcaoSimNao" [value]="multipleDocumentsCtrl">
                    {{ multipleDocumentsCtrl.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    createEditFormGroup.controls['multipleDocumentsCtrl'].hasError('required') &&
                    createEditFormGroup.controls['multipleDocumentsCtrl'].touched
                  "
                >
                  Informe se esse tipo de documento permito múltiplas submissões.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="example-radio-button example-radio-group">
            <div class="d-flex flex-column flex-md-row">
              <mat-form-field style="padding-right: 10px">
                <input
                  matInput
                  type="number"
                  placeholder="Mínimo de documentos"
                  formControlName="minimumDocumentsCtrl"
                  required
                  name="minimumDocuments"
                />
                <mat-error *ngIf="createEditFormGroup.controls['minimumDocumentsCtrl'].hasError('required')">
                  Informe a quantidade mínima de documentos submetidos.
                </mat-error>
              </mat-form-field>
              <mat-form-field style="padding-left: 10px">
                <input
                  matInput
                  type="number"
                  placeholder="Máximo de documentos"
                  formControlName="maximumDocumentsCtrl"
                  required
                  name="maximumDocuments"
                />
                <mat-error *ngIf="createEditFormGroup.controls['maximumDocumentsCtrl'].hasError('required')">
                  Informe a quantidade máxima de documentos submetidos.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="example-radio-button example-radio-group">
            <div class="d-flex flex-column flex-md-row">
              <mat-form-field>
                <mat-label style="font-weight: 400">Ação do documento </mat-label>
                <mat-select
                  placeholder="Ação do documento"
                  formControlName="documentActionCtrl"
                  name="documentAction"
                  required
                  [compareWith]="compareObjects"
                >
                  <mat-option *ngFor="let documentAction of documentActionList" [value]="documentAction">
                    {{ documentAction.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    createEditFormGroup.controls['multipleDocumentsCtrl'].hasError('required') &&
                    createEditFormGroup.controls['multipleDocumentsCtrl'].touched
                  "
                >
                  Informe se esse tipo de documento permito múltiplas submissões.
                </mat-error>
                <mat-error
                  *ngIf="
                    createEditFormGroup.controls['documentActionCtrl'].hasError('required') &&
                    createEditFormGroup.controls['documentActionCtrl'].touched
                  "
                >
                  Informe a ação a ser realizada no documento.</mat-error
                >
              </mat-form-field>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px; margin-bottom: 20px">
          <mat-label style="font-weight: 400">Instruções para usuário </mat-label>
          <quill-editor
            #editor
            [modules]="modules"
            [formControl]="createEditFormGroup.controls['instrucoesCtrl']"
          ></quill-editor>
        </div>
        <div class="text-right">
          <button mat-stroked-button type="button" (click)="onVoltar()">
            <mat-icon>chevron_left</mat-icon>
            &nbsp;Voltar</button
          >&nbsp;
          <button mat-raised-button color="primary" type="submit" [disabled]="!createEditFormGroup.valid">
            <mat-icon>check</mat-icon>
            &nbsp;Salvar
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
