import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { StatsService } from '../../services/stats.service';

@Component({
  selector: 'app-agent-reports',
  templateUrl: './agent-reports.component.html',
  styleUrls: ['./agent-reports.component.scss'],
})
export class AgentReportsComponent implements OnInit {
  agents = undefined;

  // filter
  selectedAgentStatus = new FormControl([]);
  sourceCtrl: FormControl;

  selectedStatus = [];
  readonly agentStatusList = [
    { label: 'Aguardando confirmação', name: 'email-pending' },
    { label: 'Aguardando aprovação', name: 'waiting-approval' },
    { label: 'Aprovado', name: 'approved' },
    { label: 'Recusado', name: 'rejected' },
    { label: 'Suspenso', name: 'suspended' },
  ];
  // end filter

  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  isLoadingAgents = false;

  selectedFields;
  fieldsList = [];
  length = 0;
  pageSize = 100;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  currentPage = 0;

  currentFilterValue = '';
  filterValue = '';

  // Sorting
  sortField = 'lastInteraction';
  sortDirection: MatSortable['start'] = 'desc';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selectedType: FormControl;
  readonly typeFile = [
    { name: 'CSV', type: 'text/csv' },
    { name: 'XLSX', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' },
    { name: 'XLS', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' },
  ];

  selectedReport;
  readonly reportList = [
    { label: 'Todos os assessores', name: 'all-agents' },
    { label: 'Assessores ativos', name: 'active-agents' },
    { label: 'Assessores e seus leads', name: 'agents-and-leads' },
  ];

  constructor(private statsService: StatsService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.sourceCtrl = new FormControl();
    this.selectedReport = new FormControl();
    this.selectedFields = new FormControl();
    this.selectedType = new FormControl({ value: this.typeFile[0], disabled: false });
    this.dataSource = new MatTableDataSource([]);
  }

  getActiveAgents(): void {
    this.isLoadingAgents = true;
    this.statsService
      .getActiveAgents({
        status: this.selectedAgentStatus.value,
        source: this.sourceCtrl.value || null,
      })
      .then((result) => {
        this.agents = result.map((agent) => ({
          ...agent,
          createdAt: agent.createdAt ? new Date(agent.createdAt as any) : null,
          lastStatusUpdate: agent.lastStatusUpdate ? new Date(agent.lastStatusUpdate) : null,
        }));

        this.fieldsList = [
          { name: 'name', label: 'Nome' },
          { name: 'email', label: 'E-mail' },
          { name: 'status', label: 'Status' },
          { name: 'phone', label: 'Telefone' },
          { name: 'cnpj', label: 'CNPJ' },
          { name: 'createdAt', label: 'Data Criação' },
          { name: 'lastStatusUpdate', label: 'Última atualização de status' },
          { name: 'lastStatusEvent', label: 'Última ação de status' },
          { name: 'leadQuantity', label: 'Quantidade de Leads' },
          { name: 'createdLeadQuantity', label: 'Quantidade de Leads Criados' },
          { name: 'uid', label: 'UID' },
          { name: 'source', label: 'Origem' },
        ];
        this.agents = _.sortBy(this.agents, [this.sortField]);

        if (this.sortDirection === 'desc') {
          _.reverse(this.agents);
        }

        this.displayedColumns = this.fieldsList.map((f) => f.name);

        this.dataSource = new MatTableDataSource(this.agents);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.selectedFields.setValue(this.displayedColumns);

        this.isLoadingAgents = false;
      })
      .catch((err) => {
        console.error('Error getting active agents', err);
        this.agents = null;

        this.dataSource = new MatTableDataSource([]);

        this.isLoadingAgents = false;

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '700px',
          data: {
            alertTitle: 'Erro ao obter assessores',
            alertDescription: `Houve um problema ao obter os dados dos assessores.`,
            isOnlyConfirm: true,
          },
        });
      });
  }

  getAllAgents(): void {
    this.isLoadingAgents = true;

    this.statsService
      .getAllAgents({
        status: this.selectedAgentStatus.value,
        source: this.sourceCtrl.value || null,
      })
      .then((result: any) => {
        this.agents = result.map((a) => ({
          ...a,
          createdAt: a.createdAt ? new Date(a.createdAt) : null,
          lastStatusUpdate: a.lastStatusUpdate ? new Date(a.lastStatusUpdate) : null,
        }));

        this.fieldsList = [
          { name: 'name', label: 'Nome' },
          { name: 'email', label: 'E-mail' },
          { name: 'status', label: 'Status' },
          { name: 'phone', label: 'Telefone' },
          { name: 'cnpj', label: 'CNPJ' },
          { name: 'createdAt', label: 'Data Criação' },
          { name: 'lastStatusUpdate', label: 'Última atualização de status' },
          { name: 'lastStatusEvent', label: 'Última ação de status' },
          { name: 'createdLeadQuantity', label: 'Quantidade de Leads Criados' },
          { name: 'uid', label: 'UID' },
          { name: 'source', label: 'Origem' },
        ];
        this.agents = _.sortBy(this.agents, [this.sortField]);

        if (this.sortDirection === 'desc') {
          _.reverse(this.agents);
        }

        this.displayedColumns = this.fieldsList.map((f) => f.name);

        this.dataSource = new MatTableDataSource(this.agents);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.selectedFields.setValue(this.displayedColumns);

        this.isLoadingAgents = false;
      })
      .catch((err) => {
        console.error('Error getting all agents', err);
        this.agents = null;

        this.dataSource = new MatTableDataSource([]);

        this.isLoadingAgents = false;

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '700px',
          data: {
            alertTitle: 'Erro ao obter assessores',
            alertDescription: `Houve um problema ao obter os dados dos assessores.`,
            isOnlyConfirm: true,
          },
        });
      });
  }

  getAgentsAndLeads(): void {
    this.isLoadingAgents = true;
    this.statsService
      .getAgentsAndLeads({
        status: this.selectedAgentStatus.value,
        source: this.sourceCtrl.value || null,
      })
      .then((result) => {
        this.agents = [];
        result.forEach((agent) => {
          agent.leads?.forEach((lead) =>
            this.agents.push({
              uid: agent.uid,
              name: agent.name,
              status: agent.status,
              email: agent.email,
              createdAt: agent.createdAt ? new Date(agent.createdAt as any) : null,
              leadName: lead.name?.toUpperCase(),
              leadEmail: lead.email,
              signedTerm: lead.createdAt ? new Date(lead.createdAt).toLocaleString() : null,
            })
          );
        });

        this.fieldsList = [
          { name: 'name', label: 'Nome do Assessor' },
          { name: 'email', label: 'E-mail do Assessor' },
          { name: 'status', label: 'Status' },
          { name: 'leadName', label: 'Nome do Lead' },
          { name: 'leadEmail', label: 'E-mail do Lead' },
          { name: 'signedTerm', label: 'Aceite do termo' },
          { name: 'uid', label: 'UID' },
        ];
        this.agents = _.sortBy(this.agents, [this.sortField]);

        if (this.sortDirection === 'desc') {
          _.reverse(this.agents);
        }

        this.displayedColumns = this.fieldsList.map((f) => f.name);

        this.dataSource = new MatTableDataSource(this.agents);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.selectedFields.setValue(this.displayedColumns);

        this.isLoadingAgents = false;
      })
      .catch((err) => {
        console.error('Error getting agents and leads', err);
        this.agents = null;

        this.dataSource = new MatTableDataSource([]);

        this.isLoadingAgents = false;

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '700px',
          data: {
            alertTitle: 'Erro ao obter assessores e leads',
            alertDescription: `Houve um problema ao obter os dados dos assessores e seus leads.`,
            isOnlyConfirm: true,
          },
        });
      });
  }

  getData(): void {
    this.selectedStatus = this.selectedAgentStatus.value;

    switch (this.selectedReport.value) {
      case 'all-agents':
        this.getAllAgents();
        break;
      case 'active-agents':
        this.getActiveAgents();
        break;
      case 'agents-and-leads':
        this.getAgentsAndLeads();
        break;
    }
  }

  exportList(): void {
    if (this.selectedFields?.value?.length) {
      const data = this.agents.map((a) => _.pick(a, this.selectedFields.value));
      const type = this.selectedType?.value.type;
      const extension = this.selectedType?.value.name.toLowerCase();
      const blob = new Blob(
        [
          this.selectedType?.value.name === 'XLSX'
            ? this.statsService.generateXLSX(data)
            : this.selectedType?.value.name === 'XLS'
            ? this.statsService.generateXLS(data)
            : this.statsService.generateCSV(data),
        ],
        { type }
      );

      const date = new Date().toLocaleDateString();
      saveAs(blob, `${this.selectedReport.value}-${date}.${extension}`.toLowerCase().replace(/\s/g, '-'));
    }
  }
  compareSelectedType(o1: any, o2: any): boolean {
    return o1.name === o2.name;
  }
}
