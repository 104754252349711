<div class="container">
  <mat-card>
    <mat-card-content class="div-shadow p-4 py-5 p-md-5">
      <h1 class="mb-4">Meu Assessor</h1>

      <div class="text-center my-3" *ngIf="isLoading">
        <mat-spinner [diameter]="60" color="accent" class="mx-auto"></mat-spinner>
      </div>

      <div *ngIf="!isLoading && !hasError && agent === undefined">
        <p>Você não possui um assessor de crédito vinculado à sua conta.</p>
      </div>

      <div *ngIf="!isLoading && !hasError && agent === null">
        <p>
          O assessor de crédito vinculado à sua conta é inválido. Para informar o problema e/ou saber mais, envie um
          e-mail para <strong>ops@capitalempreendedor.com.br</strong>
        </p>
      </div>

      <div *ngIf="!isLoading && !hasError && agent">
        <section>
          <p><strong>Nome:</strong> {{ agent.name || '-' }}</p>
          <p><strong>E-mail:</strong> {{ agent.email || '-' }}</p>
          <p><strong>Telefone:</strong> {{ agent.phone || '-' }}</p>
        </section>

        <div class="agent-info">
          <div>
            <mat-checkbox [(ngModel)]="canVisualizeDocs" color="primary">
              <span class="pre-wrap">Autorizo meu assessor a visualizar meus documentos</span>
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox [(ngModel)]="canSendDocs" color="primary" class="mb-2">
              <span class="pre-wrap">Autorizo meu assessor a enviar documentos por mim</span>
            </mat-checkbox>
          </div>

          <div class="text-center mt-3 mb-2">
            <button mat-raised-button color="primary" (click)="saveAgentPermissions()" style="color: white">
              <mat-icon>save</mat-icon> Salvar
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="!isLoading && hasError">
        <p>Não foi possível obter os dados do seu assessor de crédito.</p>
        <p class="text-center">
          <button mat-raised-button color="primary" (click)="getAgentData()">Tentar novamente</button>
        </p>
      </div>
    </mat-card-content>
  </mat-card>
</div>
