<div class="user-credit-lines-container">
  <div class="inner-container">
    <div class="content-wrapper">
      <div class="main-content">
        <div class="user-credit-lines-content">
          <div class="div-flex">
            <div class="ofertas-container-green">
              <h3>Crédito Solicitado: {{ customer?.creditValueStr?.slice(0, -3) }}</h3>
            </div>

            <div class="ofertas-container-flex" (click)="openWhatsApp()">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2FGroup%2037322.png?alt=media&token=eef9aa82-5cbe-402a-84ba-b25d489f8e66"
                alt="">
              <h3>Whatsapp <br>
                AI Credit Agent</h3>
            </div>


          </div>

          <div class="garantias-container">
            <h3>Suas Garantias</h3>
            <p id="garantias-text"></p>
            <br>
            <p>Com mais Garantias você recebe + Ofertas!</p>
            <button class="button-oferta" routerLink="/mensagens">
              Adicione mais garantias
              <img class="img-arrow" src="../../../assets/images/icons/Arrow Right.png" alt="">
            </button>
          </div>
          <div class="garantias-container" *ngIf="customer?.scoreSerasa > 0">
            <app-score-gauge-mini [customer]="customer"></app-score-gauge-mini>
          </div>
        </div>
      </div>

      <div *ngIf="!enableLoading">
        <div class=" d-flex content">
          <h1 class="mb-4">Ofertas de crédito</h1>
        </div>
        <mat-tab-group>
          <mat-tab label="Linhas atuais">


            <mat-spinner class="mx-auto my-2" color="primary" *ngIf="isLoading" diameter="60"></mat-spinner>



            <div class="opportunity-cards">
              <div class="row">
                <div class="col-12 col-md-4" *ngFor="let operacao of opportunities">
                  <div class="custom-card" [class]="getCardBorderColorClass(operacao)"
                    *ngIf="operacao?.stageOpportunity?.defaultprobability != 0">
                    <div class="card-header">
                      <ng-container *ngIf="operationToCustomerVisibleCondition(operacao)">
                        <img src="{{ operacao?.logoPath }}" style="height: 100px;">
                      </ng-container>
                      <div class="d-flex align-items-center"
                        style=" justify-content: space-between; color: #00C46C; height: 30px; margin: 10px;">
                        <ng-container *ngIf="iconCheck(operacao)">
                          <img src="../../../assets/images/icons/check.png" alt="" style="margin-left: auto;">
                        </ng-container>
                        <ng-container *ngIf="iconX(operacao)">
                          <img src="../../../assets/images/icons/x.png" alt="" style="margin-left: auto;">
                        </ng-container>
                        <ng-container *ngIf="!iconCheck(operacao) && !iconX(operacao) ">
                        </ng-container>
                      </div>

                      <ng-container *ngIf="operacao.institutionName === 'Pronampe - Capital Empreendedor'"
                        style="margin-top: 15px;">
                        <p>Prazo: Até 48 meses</p>
                        <p>Taxa: Selic +6% a.a</p>
                        <p>Limite: Até R$150.000</p>
                      </ng-container>

                      <ng-container *ngIf="operacao.institutionName === 'FGI PEAC - Capital Empreendedor'"
                        style="margin-top: 15px;">
                        <p>Prazo: Até 60 meses</p>
                        <p>Taxa: 1,59% ao mês Pré-fixado*</p>
                        <p>Limite: Até R$5.000.000</p>
                      </ng-container>
                      <ng-container *ngIf="operacao.institutionName === 'FGI PEAC - Capital Empreendedor'">
                        <p style="font-size: 10px;">*Sujeito ao Score Crédito</p>
                      </ng-container>

                      <ng-container *ngIf="operacao.institutionName === 'Stone'" style="margin-top: 15px;">
                        <p>Taxas: 0,79% no débito e 2,99% no crédito à vista</p>
                        <p>Maquininha grátis</p>
                      </ng-container>
                      <ng-container *ngIf="operacao.institutionName === 'Stone'">
                        <p style="font-size: 10px;">*Válida para estabelecimentos com faturamento a partir R$30mil/mês
                        </p>
                      </ng-container>


                      <ng-container
                        *ngIf="operacao.institutionName === 'Cartão de crédito Capital Empreendedor' || operacao.institutionName === 'Cartão de crédito Cora' "
                        style="margin-top: 15px;">
                        <p>Taxa: Zero</p>
                        <p>Limite: Inicial de até R$ 10.000*</p>
                      </ng-container>
                      <ng-container
                        *ngIf="operacao.institutionName === 'Cartão de crédito Capital Empreendedor' || operacao.institutionName === 'Cartão de crédito Cora' ">
                        <p style="font-size: 10px;">*Limite sujeito à Operação de Crédito.</p>
                      </ng-container>


                    </div>
                    <ng-container
                      *ngIf="(operacao.institutionName === 'Cartão de crédito Capital Empreendedor' && operacao.stageOpportunity.defaultprobability === 40 ) || (operacao.institutionName === 'Cartão de crédito Capital Empreendedor' && operacao.stageOpportunity.defaultprobability === 60 )
                      || (operacao.institutionName === 'Cartão de crédito Cora' && operacao.stageOpportunity.defaultprobability === 40 ) || (operacao.institutionName === 'Cartão de crédito Cora' && operacao.stageOpportunity.defaultprobability === 60 )">
                      <div class="card-content" *ngIf="!isExpired(operacao)">
                        <button *ngIf="showDetailsButton(operacao)" mat-raised-button type="button"
                          class="button-detalhe" (click)="onOpenProposalDialog(operacao)">
                          Solicitar Cartão</button>
                      </div>
                    </ng-container>

                    <ng-container
                      *ngIf="(operacao.institutionName === 'Stone' && operacao.stageOpportunity.defaultprobability === 40 ) || (operacao.institutionName === 'Stone' && operacao.stageOpportunity.defaultprobability === 60 )">
                      <div class="card-content" *ngIf="!isExpired(operacao)">
                        <button *ngIf="showDetailsButton(operacao)" mat-raised-button type="button"
                          class="button-detalhe" (click)="onOpenProposalDialog(operacao)">
                          Solicitar Maquininha</button>
                      </div>
                    </ng-container>

                    <ng-container
                      *ngIf="(operacao.institutionName === 'Pronampe - Capital Empreendedor' && operacao.stageOpportunity.defaultprobability != 40 && operacao.stageOpportunity.defaultprobability != 60 || operacao.institutionName != 'Pronampe - Capital Empreendedor') && (operacao.institutionName === 'FGI PEAC - Capital Empreendedor' && operacao.stageOpportunity.defaultprobability != 40 && operacao.stageOpportunity.defaultprobability != 60 || operacao.institutionName != 'FGI PEAC - Capital Empreendedor') && 
                    (operacao.institutionName === 'Cartão de crédito Capital Empreendedor' && operacao.stageOpportunity.defaultprobability != 40 && operacao.stageOpportunity.defaultprobability != 60 || operacao.institutionName != 'Cartão de crédito Capital Empreendedor') &&
                    (operacao.institutionName === 'Cartão de crédito Cora' && operacao.stageOpportunity.defaultprobability != 40 && operacao.stageOpportunity.defaultprobability != 60 || operacao.institutionName != 'Cartão de crédito Cora')">
                      <div class="card-content" *ngIf="!isExpired(operacao)">
                        <h4 [class.waiting-for-response]="isWaitingForCustomerResponse(operacao)"
                          *ngIf="operacao.stageSituation != 'Perfil não enquadrado'">{{
                          operacao?.stageSituation }}</h4>
                        <button *ngIf="showDetailsButton(operacao)" mat-raised-button type="button"
                          class="button-detalhe" (click)="onOpenProposalDialog(operacao)">
                          Ver detalhes</button>
                      </div>
                    </ng-container>


                    <ng-container
                      *ngIf="operacao.institutionName === 'Pronampe - Capital Empreendedor' && operacao.stageOpportunity.defaultprobability === 40 || operacao.institutionName === 'Pronampe - Capital Empreendedor' && operacao.stageOpportunity.defaultprobability === 60 || operacao.institutionName === 'FGI PEAC - Capital Empreendedor' && operacao.stageOpportunity.defaultprobability === 40 || operacao.institutionName === 'FGI PEAC - Capital Empreendedor' && operacao.stageOpportunity.defaultprobability === 60 ">
                      <button *ngIf="showDetailsButton(operacao)" mat-raised-button type="button" class="button-detalhe"
                        (click)="documentsDialog(operacao)">
                        Carregar Documentos</button>
                    </ng-container>

                    <div *ngIf="isExpired(operacao)" class="card-content">

                      <div>
                        <h4 style="color: #F93232;">Proposta expirada</h4>
                      </div>

                      <div>
                        <button *ngIf="showDetailsButton(operacao)" mat-raised-button type="button"
                          class="button-detalhe" (click)="onOpenProposalDialog(operacao)">
                          Ver detalhes</button>
                      </div>

                      <ng-container>

                      </ng-container>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngFor="let group of opportunityHistoryGroups">
            <ng-template mat-tab-label>
              {{ group.label }}
            </ng-template>

            <table mat-table [dataSource]="group.dataSource" class="table-generic-styles">

              <ng-container matColumnDef="img">
                <th mat-header-cell *matHeaderCellDef>#</th>
                <td mat-cell *matCellDef="let history">
                  <div *ngIf="getLogoPath(history)">
                    <img [src]="getLogoPath(history)" width="50px" />
                  </div>
                  <div *ngIf="!getLogoPath(history)">
                    <mat-icon>account_balance</mat-icon>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="institutionName">
                <th mat-header-cell *matHeaderCellDef>Instituição</th>
                <td mat-cell *matCellDef="let history">{{ history.institutionName }}</td>
              </ng-container>

              <ng-container matColumnDef="deadlineForMonth">
                <th mat-header-cell *matHeaderCellDef>Prazo em meses</th>
                <td mat-cell *matCellDef="let history">{{ history.deadlineForMonth }}</td>
              </ng-container>

              <ng-container matColumnDef="limitOperation">
                <th mat-header-cell *matHeaderCellDef>Valor do Crédito</th>
                <td mat-cell *matCellDef="let history">{{ history.limitOperation | currency: 'BRL':true:'1.2-2' }}</td>
              </ng-container>

              <ng-container matColumnDef="rate">
                <th mat-header-cell *matHeaderCellDef>Taxa</th>
                <td mat-cell *matCellDef="let history">{{ history.rate }} %</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="opportunityHistoryColumns"></tr>

              <tr mat-row *matRowDef="let row; columns: opportunityHistoryColumns"></tr>
            </table>
          </mat-tab>
        </mat-tab-group>
        <div class="cta-documents" *ngIf="!isCustomerNoTouch">
          <p>Termine de enviar seus documentos para receber mais propostas!</p>
          <button><a href="/documentacao">Enviar documentos</a></button>
        </div>
      </div>
    </div>
  </div>
</div>