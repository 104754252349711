import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RevenueRangeService {
  private minRevenue: number;
  private maxRevenue: number;

  setMinRevenue(value: number): void {
    this.minRevenue = value;
  }

  getMinRevenue(): number {
    return this.minRevenue;
  }

  setMaxRevenue(value: number): void {
    this.maxRevenue = value;
  }

  getMaxRevenue(): number {
    return this.maxRevenue;
  }

}
