<div *ngIf="attributedList.length > 0">
<div class="attributedToInfo" *ngFor="let attributed of attributedList">
  <p *ngIf="attributed.action === 'attributed'">
    ━━━━━━ {{ attributed.atribuitedBy.name }} atribuiu o usuário para {{ attributed.atribuitedTo?.name }} em: {{ attributed.createdAt |
    date:'medium' }} ━━━━━━
  </p>
  <p  *ngIf="attributed.action === 'desattributed'">
    ━━━━━━ Usuário {{ attributed.desatribuitedTo?.name }} desatribuído em: {{ attributed.createdAt | date:'medium' }} ━━━━━━
  </p>
</div>
</div>
