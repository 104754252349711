<div class="container">
  <mat-card *ngIf="loading || !agent">
    <mat-card-content>
      <section class="my-6 text-center">
        <mat-spinner [diameter]="60" color="accent" class="d-inline-block mx-3"></mat-spinner>
      </section>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!loading && agent && !agent.emailVerified">
    <mat-card-content>
      <mat-card-title> Confirme seu e-mail </mat-card-title>
      <section class="mt-4">
        <p>
          Obrigado por se cadastrar como assessor de crédito na Capital Empreendedor! Estamos ansiosos para tê-lo como
          nosso parceiro.
        </p>
        <p>Para continuarmos basta seguir alguns passos:</p>
        <ol>
          <li>
            Você receberá um e-mail com o assunto "<strong>Confirmação de e-mail para Capital Empreendedor</strong>". Ao
            abrir o e-mail, clique no link contindo na mensagem para confirmarmos seu e-mail;
          </li>
          <li>
            Ao clicar nesse link, você já pode acessar nossa plataforma de assessores, convidar novos leads e visualizar
            os leads já cadastrados.
          </li>
        </ol>
        <p>
          Caso não tenha recebido o e-mail, verifique sua caixa de spam e caso não encontre-o, clique no botão "<strong
            >Reenviar e-mail de confirmação</strong
          >" para enviá-lo novamente.
        </p>
        <br />
        <div class="text-center">
          <button mat-raised-button color="primary" [disabled]="processing" (click)="sendEmailVerification()">
            <span *ngIf="!processing">Reenviar e-mail de confirmação</span>
            <mat-spinner *ngIf="processing" [diameter]="20" color="accent" class="d-inline-block m-x-3"></mat-spinner>
          </button>
        </div>
      </section>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!loading && agent && agent.emailVerified && !agent.isApprovedByAdmin && agent.isActive">
    <mat-card-content>
      <mat-card-title> Aguardando aprovação </mat-card-title>
      <section class="mt-4">
        <p>
          Seu e-mail foi confirmado e seu cadastro está sendo avaliado pela nossa equipe. Em breve você receberá um
          e-mail informando a situação da sua conta.
        </p>
        <p>
          Caso tenha alguma dúvida, entre em contato através do e-mail <strong>ops@capitalempreendedor.com.br</strong>.
        </p>
      </section>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!loading && agent && agent.emailVerified && !agent.isApprovedByAdmin && !agent.isActive">
    <mat-card-content>
      <mat-card-title> Ops... </mat-card-title>
      <section class="mt-4">
        Infelizmente seu cadastro foi negado pela nossa equipe. Caso tenha alguma dúvida, entre em contato através do
        e-mail <strong>ops@capitalempreendedor.com.br</strong>.
      </section>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!loading && agent && agent.emailVerified && agent.isApprovedByAdmin && !agent.isActive">
    <mat-card-content>
      <mat-card-title> Ops... </mat-card-title>
      <section class="mt-4">
        Infelizmente seu cadastro foi suspenso pela nossa equipe. Caso tenha alguma dúvida, entre em contato através do
        e-mail <strong>ops@capitalempreendedor.com.br</strong>.
      </section>
    </mat-card-content>
  </mat-card>

  <div *ngIf="!loading && agent && agent.emailVerified && agent.isApprovedByAdmin && agent.isActive">
    <app-user-settings [agent]="agent"></app-user-settings>
  </div>
</div>
