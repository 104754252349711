<router-outlet #o="outlet" *ngIf="!isLoading"></router-outlet>
<div class="d-flex align-items-center justify-content-center" style="height: 100%" *ngIf="isLoading">
  <div>
    <mat-spinner [diameter]="120"></mat-spinner>
  </div>
</div>
<!--Cookie confirmation-->
<div
  *ngIf="user && !user.isAdmin && !user.isAnonymous && !user.userConsent?.consentGiven && user?.userConsent?.showUserConsent"
  class="vw-100">
  <app-cookie-confirmation [user]="user"></app-cookie-confirmation>
</div>