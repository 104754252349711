import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { OppFilter } from '../../../../functions/src/models/common/OppFilter';
import { RevenueRangeService } from '../../bankers/services/revenue-range.service';
import { SliderRangeRevenueComponent } from '../slider-range-revenue/slider-range-revenue.component';

@Component({
  selector: 'app-filter-oportunity',
  templateUrl: './filter-oportunity.component.html',
  styleUrls: ['./filter-oportunity.component.css']
})
export class FilterOportunityComponent {
  @Input() isLoadingResults = false;
  @Input() minRevenue = 1;
  @Input() maxRevenue = 10000000;
  @Output() applyFilter: EventEmitter<Partial<OppFilter>> = new EventEmitter<Partial<OppFilter>>();
  @ViewChild('intervaloTempoMenu') intervaloTempoMenu: MatMenuTrigger;
  @ViewChild('triggerButton') triggerButtonRef: ElementRef;


  // Filters
  filterOptions = [
    { id: 'companyName', name: 'Razão Social' },
    { id: 'cnpj', name: 'CNPJ' },
    { id: 'status', name: 'STATUS' },
  ];

  status = [
    { id: 0, label: 'Negado pela instituição/Recusado pelo cliente' },
    { id: 10, label: 'Aguardando documentação do cliente' },
    { id: 15, label: 'Aguardando documentação do cliente' },
    { id: 20, label: 'aguardando autorização SCR' },
    { id: 30, label: 'Aguardando proposta da instituição' },
    { id: 40, label: 'Proposta enviada, aguardando resposta do cliente' },
    { id: 60, label: 'Proposta aceita, aguardando documentação adicional' },
    { id: 80, label: 'Em análise final de crédito' },
    { id: 90, label: 'Contratação/Abertura de conta' },
    { id: 100, label: 'Crédito contratado' },
  ];

  selectedFilter = 'companyName';
  filterValue: string | number = '';
  currentPage: number;
  dataInicioValue: Date;
  dataFimValue: Date;

  constructor(private dialog: MatDialog, private revenueService: RevenueRangeService) {
  }

  getLabel(): string {
    return this.filterOptions.find(i => i.id === this.selectedFilter).name;
  }

  onApplyFilter(): void {
    const filter: Partial<OppFilter> = {};
    filter.filterField = this.selectedFilter;
    filter.filterValue = this.filterValue;
    filter.startDate = this.dataInicioValue?.toISOString() || null;
    filter.endDate = this.dataFimValue?.toISOString() || null;
    filter.minRevenue = this.minRevenue || null;
    filter.maxRevenue = this.maxRevenue || null;
    // this.intervaloTempoMenu?.closeMenu(); // Fechar o menu de intervalo de tempo
    // this.faixaValorMenu?.closeMenu(); // Fechar o menu de faixa de valor
    this.applyFilter.emit(filter);
  }

  onOpenDatepicker(datepicker: MatDatepicker<any>): void {
    datepicker.open();
  }

  onClearFilter(): void {
    this.filterValue = '';
    this.currentPage = 0;
    this.onApplyFilter();
  }

  onChangeSelectedFilter(value: string): void {
    this.selectedFilter = value;
  }

  onChangeFilterValue(value: string): void {
    this.filterValue = value;
  }

  openSliderRangerRvenue(): void {
    const rect = this.triggerButtonRef.nativeElement.getBoundingClientRect();
    const width = 415;
    const dialogSubscription = this.dialog.open(SliderRangeRevenueComponent, {
      width: `${ width }px`,
      height: '240px',
      data: {
        minRevenue: this.minRevenue,
        maxRevenue: this.maxRevenue,
      },
      position: { top: `${ rect.bottom }px`, left: `${ rect.left - width / 2 }px` }

    }).afterClosed().subscribe(() => {
      this.minRevenue = this.revenueService.getMinRevenue();
      this.maxRevenue = this.revenueService.getMaxRevenue();
      this.onApplyFilter();
      dialogSubscription.unsubscribe();
    });
  }
}
// border-radius: 5px; background-color: #F4F4F4;
