<div class="score-gauge-container">
  <div class="score-gauge-header">
    <h3>Sua pontuação de crédito</h3>
    <!-- <button *ngIf="closedCard" (click)="onExpandCard()" class="header-button open-card-button">Saiba mais</button>
    <button *ngIf="!closedCard" (click)="onExpandCard()" class="header-button close-card-button">Recuar planilha</button> -->
  </div>
  <div class="serasa-indicator-container">
    <svg width="300" height="160" viewBox="0 0 200 120">
      <path d="M 10 100 A 90 90 0 0 1 85 20" stroke="#ff6364" stroke-width="10" fill="none"/>
      <path d="M 85 20 A 90 90 0 0 1 105 20" stroke="#ff883a" stroke-width="10" fill="none"/>
      <path d="M 105 20 A 90 90 0 0 1 145 35" stroke="#fdd42d" stroke-width="10" fill="none"/>
      <path d="M 145 35 A 90 90 0 0 1 165 55" stroke="#0192f5" stroke-width="10" fill="none"/>
      <path d="M 165 55 A 90 90 0 0 1 180 100" stroke="#3fd796" stroke-width="10" fill="none"/>

      <circle [attr.cx]="circlePosition.x" [attr.cy]="circlePosition.y" r="8" fill="grey"/>

      <text x="3" y="120" font-size="20" fill="#242424" font-weight="600">H</text>
      <text x="175" y="120" font-size="20" fill="#242424" font-weight="600">A</text>

      <text x="98" y="80" font-size="32" fill="#242424" font-weight="600" text-anchor="middle">{{getClassficationScoreSerasa().classification}}</text>
      <text x="96" y="100" font-size="14" fill="#242424" font-weight="400" text-anchor="middle">{{this.customer?.scoreSerasa}}</text>
    </svg>
  </div>
  <!-- <div class="serasa-gauge-table" *ngIf="!closedCard"> -->
  <div class="serasa-gauge-table">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="scoreAverage">
        <th mat-header-cell *matHeaderCellDef>Pontuação Serasa</th>
        <td mat-cell *matCellDef="let item"> {{item.scoreAverage}} </td>
      </ng-container>
      <ng-container matColumnDef="classification">
        <th mat-header-cell *matHeaderCellDef>Classificação Banco Central</th>
        <td mat-cell *matCellDef="let item"> {{item.classification}} </td>
      </ng-container>
      <ng-container matColumnDef="scoreDescription">
        <th mat-header-cell *matHeaderCellDef>Descrição do Score</th>
        <td mat-cell *matCellDef="let item"> {{item.scoreDescription}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <button (click)="goToChat()" class="go-to-chat-button">Como funciona o Score de Crédito?</button>
</div>

