<div class="container">
  <mat-card *ngIf="isLoading">
    <mat-card-content>
      <mat-card-title>Cadastrar Assessor de Crédito</mat-card-title>
      <section class="my-5">
        <mat-spinner [diameter]="60" color="primary" class="mx-auto"></mat-spinner>
      </section>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!isLoading">
    <mat-card-content>
      <mat-card-title> Cadastrar Assessor de Crédito </mat-card-title>
      <section class="mt-4 agent-profile-form">
        <form [formGroup]="createAgentFormGroup">
          <div class="fields">
            <div class="field">
              <mat-form-field color="primary" class="w-100">
                <input matInput type="email" placeholder="E-mail" formControlName="emailCtrl" required />
                <mat-error *ngIf="createAgentFormGroup.controls['emailCtrl'].hasError('required')">
                  Informe o e-mail do assessor.
                </mat-error>
                <mat-error *ngIf="createAgentFormGroup.controls['emailCtrl'].hasError('email')">
                  O e-mail deve ser válido.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary" class="w-100">
                <input matInput placeholder="Nome completo" formControlName="nameCtrl" required name="fullname" />
                <mat-error *ngIf="createAgentFormGroup.controls['nameCtrl'].hasError('required')">
                  Informe o nome completo do assessor.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary" class="w-100">
                <input
                  matInput
                  placeholder="Telefone"
                  formControlName="phoneCtrl"
                  name="phone"
                  phone
                  #phone
                  [textMask]="{ mask: formatPhone, guide: false }"
                  required
                />
                <mat-error *ngIf="createAgentFormGroup.controls['phoneCtrl'].hasError('required')">
                  Informe um telefone do assessor para contato.
                </mat-error>
                <mat-error *ngIf="createAgentFormGroup.controls['phoneCtrl'].hasError('telefone')">
                  Telefone inválido.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field color="primary">
                <mat-label>CNPJ</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="CNPJ"
                  formControlName="CNPJCtrl"
                  name="CNPJ"
                  [textMask]="{ mask: MASKS.cnpj.textMask }"
                  required
                />
                <mat-error *ngIf="createAgentFormGroup.controls['CNPJCtrl'].hasError('cnpj')"> CNPJ inválido</mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field color="primary">
                <mat-label> Linkedin </mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Linkedin"
                  formControlName="linkedinCtrl"
                  name="linkedin"
                  required
                />
                <mat-error *ngIf="createAgentFormGroup.controls['linkedinCtrl'].hasError('linkedin')">
                  Informe o Linkedin do assessor
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field d-flex align-items-center">
              <mat-form-field color="primary" class="w-100 mr-3">
                <input
                  matInput
                  [type]="showPassword ? 'text' : 'password'"
                  placeholder="Senha"
                  formControlName="passwordCtrl"
                  required
                />
                <button
                  mat-button
                  type="button"
                  matSuffix
                  mat-icon-button
                  aria-label="Toggle password"
                  (click)="showPassword = !showPassword"
                >
                  <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                </button>
                <mat-error *ngIf="createAgentFormGroup.controls['passwordCtrl'].hasError('required')">
                  Informe uma senha de acesso.
                </mat-error>
                <mat-error *ngIf="createAgentFormGroup.controls['passwordCtrl'].hasError('minlength')">
                  A senha deve ter 6 caracteres ou mais.
                </mat-error>
              </mat-form-field>
              <div>
                <button
                  mat-raised-button
                  type="button"
                  color="accent"
                  [disabled]="isProcessing"
                  (click)="generatePassword()"
                >
                  Gerar
                </button>
              </div>
            </div>
            <div class="my-3 text-right tip">* Campos obrigatórios</div>
            <div class="mt-3">
              <button
                mat-raised-button
                *ngIf="!isProcessing"
                color="primary"
                class="btn-block btn-lg"
                type="submit"
                [disabled]="createAgentFormGroup.invalid"
                (click)="createAgent()"
              >
                Cadastrar assessor
              </button>
              <mat-spinner [diameter]="40" color="primary" *ngIf="isProcessing" class="mx-auto"></mat-spinner>
            </div>
          </div>
        </form>
      </section>
    </mat-card-content>
  </mat-card>
</div>
