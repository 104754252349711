import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_CHECKBOX_CLICK_ACTION } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import { BigDataCorpService } from '../services/bigdatacorp.service';

@Component({
  selector: 'app-bigdatacorp',
  templateUrl: './bigdatacorp.component.html',
  styleUrls: ['./bigdatacorp.component.scss'],
  providers: [
    {
      provide: MAT_CHECKBOX_CLICK_ACTION,
      useValue: 'noop'
    },
  ],
})
export class BigdatacorpComponent implements OnInit {
  readonly MASKS = MASKS;

  cnpj: FormControl;
  searchedCNPJ = '';

  isLoading = false;
  result = {};

  leafKeys = [];
  nodeKeys = [];

  readonly Object = Object;

  datasets = [
    { name: 'Informações Básicas', value: 'companies/basic_data', checked: false },
    { name: 'Protestos da empresa', value: 'companies/ondemand_query_pesquisaprotesto', checked: false },
    { name: 'Dívidas com o governo', value: 'companies/ondemand_pgfn', checked: false },
    { name: 'Processos da empresa', value: 'companies/processes', checked: false },
    { name: 'Processos dos sócios', value: 'companies/owners_lawsuits', checked: false },
    { name: 'Sócios', value: 'companies/relationships', checked: false },
    { name: 'Protestos dos sócios', value: 'peoplev2/ondemand_query_pesquisaprotesto', checked: false },
  ];

  constructor(
    private bdcService: BigDataCorpService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.cnpj = new FormControl('', [Validators.required, NgBrazilValidators.cnpj]);
  }

  checkOne(dataset) {
    if (dataset.value === 'peoplev2/ondemand_query_pesquisaprotesto' && dataset.checked === false) {
      _.find(this.datasets, ['value', 'companies/relationships']).checked = true;
    } else if (dataset.value === 'companies/relationships' && dataset.checked === true) {
      _.find(this.datasets, ['value', 'peoplev2/ondemand_query_pesquisaprotesto']).checked = false;
    }

    dataset.checked = !dataset.checked;
  }

  checkAll() {
    this.datasets.forEach(d => { d.checked = true; });
  }

  hasSelectedDatasets(): boolean {
    return this.datasets.filter(d => d.checked).length > 0;
  }

  uncheckAll() {
    this.datasets.forEach(d => { d.checked = false; });
  }

  search() {
    this.isLoading = true;
    this.cnpj.disable();

    const data = {
      datasets: this.datasets.filter(d => d.checked).map(d => d.value),
      cnpj: this.cnpj.value,

    };

    this.searchedCNPJ = this.cnpj.value;
    this.bdcService.getGenralForTools(data).then((result: any) => {
      this.result = result;
      this.cnpj.enable();
      this.isLoading = false;
      const keys = Object?.keys(this.result) ?? null;
      if (!!keys) {
        this.leafKeys = _.filter(
          keys,
          (k) =>
            !!this.result[k] &&
            (typeof this.result[k] === 'number' || typeof this.result[k] === 'string' || typeof this.result[k] === 'boolean')
        );
        this.nodeKeys = _.filter(
          keys,
          (k) => {
            return !!this.result[k] && (Array.isArray(this.result[k]) || typeof this.result[k] === 'object');
          }
    );
  }
    }).catch(err => {
      console.error(err);

      this.dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Erro ao obter dados',
          alertDescription: `Ocorreu um erro ao obter e processar os dados do BigDataCorp. Tente novamente mais tarde.`,
          isOnlyConfirm: true
        }
      });

      this.cnpj.enable();
      this.isLoading = false;
    });
  }

  downloadResult() {
    const blob = new Blob([JSON.stringify(this.result, null, 2)], { type: 'text' });
    saveAs(blob, `bigdatacorp-${ this.searchedCNPJ.replace(/\D/g, '-') }.json`);
  }
}
