import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { OpportunityHundredInterface } from 'functions/src/models/opportunity/OpportunityHundred';
import {
  OpportunityStageSituation,
  OpportunityStageSituationCustomer,
} from 'functions/src/models/opportunity/OpportunityStages';
import { Observable, of } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Customer } from '../../../../functions/src/models/Customer';
import { Opportunity } from '../../../../functions/src/models/opportunity/Opportunity';
import { OpportunityStage } from '../../../../functions/src/models/opportunity/OpportunityStage';
import { ParamsUpdateOpportunityByIF } from '../../../../functions/src/models/opportunity/ParamsUpdateOpportunityByIF';
import { environment } from '../../../environments/environment';
import { NotesService } from './notes.service';

@Injectable({
  providedIn: 'root',
})
export class OpportunityManagementService {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private angularFirestore: AngularFirestore, private adminNotesService: NotesService, private http: HttpClient, private authService: AuthService, private firestore: AngularFirestore,) { }

  getOppStageFromNumber(stage: OpportunityStageSituation = 10): OpportunityStage {
    const sortOrderMap = {
      10: 1,
      15: 1,
      20: 2,
      30: 2,
      40: 3,
      60: 4,
      70: 4,
      80: 5,
      90: 5,
      0: 6,
      100: 7,
    };
    return {
      id: OpportunityStageSituationCustomer[stage] as string,
      defaultprobability: stage,
      defaultprobabilitystr: `${ stage }%`,
      name: OpportunityStageSituationCustomer[stage],
      sortorder: sortOrderMap[stage],
    };
  }

  // pegas as oportunidades por stage
  getAllOppStages(): OpportunityStage[] {
    return [10, 15, 20, 30, 40, 60, 70, 80, 90, 0, 100].map(this.getOppStageFromNumber);
  }

  getGuarantees(): Observable<any> {
    return this.firestore.collection('configuracao').doc('guarantees').valueChanges();
  }

  getIntegrationConfig(): Observable<any> {
    return this.firestore
      .collection('configuracao')
      .doc('automatic-integrations')
      .valueChanges()
      .pipe(
        map((data) => {
          console.log('Configurações de integrações automáticas:', data); // Adicione este log
          if (!data) {
            throw new Error('Configurações de integrações automáticas não encontradas.');
          }
          return data;
        }),
        catchError((error) => {
          console.error('Erro ao obter configurações de integrações:', error);
          return of(null); // Retorne null ou um objeto padrão
        })
      );
  }
  
  isIntegrationEnabled(institutionKey: string): Observable<boolean> {
    return this.getIntegrationConfig().pipe(
      map(config => {
        console.log(`Verificando integração para a chave: ${institutionKey}`); // Log para verificação
        const isEnabled = config ? config[institutionKey] : undefined; // Verifica se existe e pega o valor
        console.log(`Valor de integração para ${institutionKey}:`, isEnabled); // Log do valor obtido
        return isEnabled !== true; // Retorna true se não for explicitamente true
      }),
      catchError((error) => {
        console.error(`Erro ao verificar a configuração de integração para a instituição ${institutionKey}:`, error);
        return of(false); // Retorna false em caso de erro
      })
    );
  }
  
  
  

  getLines(): Observable<any> {
    return this.firestore.collection('configuracao').doc('lines').valueChanges();
  }

  // sends system msg (SCE chat), send email to admins and changes opp stage 40 -> 60
  async proceedCustomerOpp(customer: Customer, opp: Opportunity): Promise<void> {
    try {
      this.http
        .post(
          `${ environment.functionsUrl }/opportunity/proceed-customer-opp`,
          {
            customerUid: customer.uid,
            financialInstitution: opp.institutionKey,
          },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('success - proceed customer opp');
        })
        .catch((err) => {
          console.error(err);
        });
      this.adminNotesService.sendAutomaticMsgCustomerChat(customer, 'customer-proceed-opp', 'USER', {
        institution: opp.institutionName,
      });
    } catch (err) {
      console.error(
        `Não foi possível prosseguir com a oportunidade de crédito, por favor, entre em contato com os administridores do sistema`
      );
    }
  }

  deleteOpp(opportunity: Opportunity): Promise<void> {
    return this.angularFirestore.collection('opportunityhistory', ref =>
      ref.where('key', '==', opportunity.key)
    ).get().toPromise().then(querySnapshot => {
      const batch = this.angularFirestore.firestore.batch();
      querySnapshot.forEach(doc => {
        batch.delete(doc.ref);
      });
      return batch.commit();
    });
  }


  async rejectCustomerOpp(customer: Customer, opp: Opportunity, reasonText?: string): Promise<void> {
    try {
      this.http
        .post(
          `${ environment.functionsUrl }/opportunity/reject-customer-opp`,
          {
            customerUid: customer.uid,
            financialInstitution: opp.institutionKey,
            rejectReason: opp.motivationOfCancellation,
          },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('success - reject customer opp');
        })
        .catch((err) => {
          console.error(err);
        });
      this.adminNotesService.sendAutomaticMsgCustomerChat(customer, 'customer-reject-opp', 'USER', {
        institution: opp.institutionName,
        reason: reasonText,
      });
    } catch (err) {
      console.error(
        `Não foi possível rejeitar a oportunidade de crédito, por favor, entre em contato com os administridores do sistema`
      );
    }
  }
  getOpportunity(uid: string, institutionKey: string): Promise<Opportunity> {
    let params = new HttpParams();
    params = params.append('institutionKey', institutionKey);

    return new Promise((resolve, reject) => {
      this.http
        .get<Opportunity>(`${ environment.functionsUrl }/opportunity/${ uid }`, {
          params,
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res) => resolve(res))
        .catch((error) => {
          console.error('Error Ocurred - Get Opportunities: ', error);
          reject(error);
        });
    });
  }

  getOpportunities(customerUid: string): Promise<Opportunity[]> {
    let params = new HttpParams();
    params = params.append('uid', customerUid);

    return new Promise((resolve, reject) => {
      this.http
        .get(`${ environment.functionsUrl }/opportunity`, {
          params,
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res) => {
          console.log('get Opportunities.');
          resolve(res as Opportunity[]);
        })
        .catch((err) => {
          console.error('Error get Opportunities.', err);
          reject(err);
        });
    });
  }


  getOpportunitiesHundred(customerUid: string): Promise<OpportunityHundredInterface[]> {

    let params = new HttpParams();
    params = params.append('uid', customerUid);
    params = params.append('opportunitiesSteps', '100');

    return new Promise((resolve, reject) => {
      this.http
        .get(`${ environment.functionsUrl }/opportunity`, {
          params,
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res) => {
          console.log('Get Opportunities. - 100');
          resolve(res as OpportunityHundredInterface[]);
        })
        .catch((err) => {
          console.error('Error get Opportunities.', err);
          reject(err);
        });
    });
  }

  createOpportunities(uid: string, opportunities?: any[], type = 'manual', createdBy?: { uid: string; name: string }): Promise<Opportunity[]> {
    return new Promise((resolve, reject) => {
      if (!opportunities?.length && type === 'manual') {
        console.error('Error Create Opportunities - not have Opportunities selected');
        reject();
      } else {
        this.http
          .post(
            `${ environment.functionsUrl }/opportunity`,
            { uid, opportunities, type, createdBy },
            {
              responseType: 'json',
              headers: this.authService.getHeader(),
            }
          )
          .toPromise()
          .then((res) => {
            console.log('Create Opportunity.');
            resolve(res as Opportunity[]);
          })
          .catch((err) => {
            console.error('Error Create Opportunity.', err);
            reject(err);
          });
      }
    });
  }

  updateOpportunity(uid: string, opportunity: Opportunity, createdBy?: { uid: string; name: string }): Promise<Opportunity> {
    return new Promise((resolve, reject) => {
      this.http
        .patch(`${ environment.functionsUrl }/opportunity/${ uid }`, { opportunity, createdBy }, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res) => {
          console.log('Update Opportunity.');
          resolve(res as Opportunity);
        })
        .catch((err) => {
          console.error('Error Update Opportunity.', err);
          reject(err);
        });
    });
  }

  updateOpportunityHistory(uid: string, opportunity: Opportunity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection('opportunityhistory', (ref) =>
          ref.where('uid', '==', uid)
            .where('key', '==', opportunity.key))
        .get()
        .toPromise()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const docRef = querySnapshot.docs[0].ref;
            const date = new Date()
            console.log('Document found, updating:', docRef.id);
            const updateData = {
              deadlineForMonth: opportunity.deadlineForMonth || null,
              rate: opportunity.rate || null,
              description: opportunity.description || " ",
              limitOperation: opportunity.limitOperation || null,
              usedWarranty: opportunity.usedWarranty || null,
              usedLine: opportunity.usedLine || null,
              lastUpdate: date
            };
            console.log('Update data:', updateData);

            return docRef.update(updateData).then(() => {
              return docRef.get(); // Obter o documento atualizado
            });
          } else {
            throw new Error('No document found with the provided uid and key.');
          }
        })
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            console.log('Document after update:', docSnapshot.data());
          } else {
            console.log('Document not found after update.');
          }
          resolve();
        })
        .catch((err) => {
          console.error('Error Update Opportunity.', err);
          reject(err);
        });
    });
  }






  updateOpportunitiesByIF(institutionKey: string, params: ParamsUpdateOpportunityByIF): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .patch(`${ environment.functionsUrl }/opportunity/update-by-if/${ institutionKey }`, params, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then(() => {
          console.log('Update Opportunity by IF.');
          resolve();
        })
        .catch((err) => {
          console.error('Error Update Opportunity By IF.', err);
          reject(err);
        });
    });
  }
  closeOpportunities(customerUid: string, stage: number, motivation: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .patch(
          `${ environment.functionsUrl }/opportunity/close-all/${ customerUid }`,
          { stage, motivation },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('closed Opportunities.');
          resolve();
        })
        .catch((err) => {
          console.error('Error closed Opportunities.', err);
          reject(err);
        });
    });
  }
}
