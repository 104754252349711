import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { User } from 'functions/src/models/User';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment as env } from '../environments/environment';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { AuthService } from './core/auth/auth.service';
import { UtilHandler } from './core/handler/util.handler';
import { initialState, SettingsState } from './core/settings/settings.reducer';

@Component({
  selector: 'anms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  userSubscription: Subscription;
  loggedUserSubscription: Subscription;
  sessionTokenSubscription: Subscription;
  routerEventSubscription: Subscription;
  user: User;
  @HostBinding('class') componentCssClass;

  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  loginAction = null;

  isLoading = true;

  browser: string;

  navigation = [
    { link: 'about', label: 'About' },
    { link: 'features', label: 'Features' },
    { link: 'examples', label: 'Examples' },
  ];
  navigationSideMenu = [...this.navigation, { link: 'settings', label: 'Settings' }];

  constructor(
    public authService: AuthService,
    private dialog: MatDialog,
    public overlayContainer: OverlayContainer,
    private router: Router,
    private titleService: Title
  ) {
    this.sessionTokenSubscription = this.authService.idTokenUser.subscribe((userToken) => {
      if (userToken) {
        this.authService.userSessionToken = userToken;
        this.isLoading = false;
      } else {
        this.authService.loginWithAnonymousAccount().then(() => {
          this.isLoading = false;
        });
      }
    });

    this.browser = UtilHandler.getBrowserName();
    console.log(this.browser);
    if (this.browser === 'ie') {
      this.dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Navegador não suportado',
          alertDescription:
            'Este navegador não é suportado e eventuais problemas podem ocorrer durante a sua utilização. ' +
            'Recomendamos a utilização dos navegadores <strong>Google Chrome</strong> ou <strong>Mozilla Firefox</strong>.',
          isOnlyconfirm: true,
        },
      });
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigate([event.url.replace('/#', '')]);
        }
      }
    });
    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser: User) => {
      this.user = loggedUser;

    });

    this.subscribeToSettings();
    this.subscribeToRouterEvents();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.routerEventSubscription) {
      this.routerEventSubscription.unsubscribe();
    }
    if (this.sessionTokenSubscription) {
      this.sessionTokenSubscription.unsubscribe();
    }
  }

  private subscribeToSettings(): void {
    this.setTheme(initialState);
  }

  private setTheme(settings: SettingsState): void {
    const effectiveTheme = settings.theme.toLowerCase();
    this.componentCssClass = effectiveTheme;
    const classList = this.overlayContainer.getContainerElement().classList;
    const toRemove = Array.from(classList).filter((item: string) => item.includes('-theme'));
    if (toRemove.length) {
      classList.remove(...toRemove);
    }
    classList.add(effectiveTheme);
  }

  private subscribeToRouterEvents(): void {
    this.routerEventSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd || event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event) => {
        if (event instanceof ActivationEnd) {
          this.setPageTitle(event);
        }
      });
  }

  private setPageTitle(event: ActivationEnd): void {
    let lastChild = event.snapshot;

    while (lastChild.children.length) {
      lastChild = lastChild.children[0];
    }

    const { title } = lastChild.data;

    this.titleService.setTitle(title ? `${title} - ${env.appName}` : env.appName);
  }
}
