<mat-card-title class="my-4">Mensagens IF</mat-card-title>
<div class="ifs-locality-form">
  <h4 style="font-weight: 500">Nova Mensagem IF:</h4>
  <hr class="my-2" />

  <div class="fields">
    <div class="field my-3">
      <p style="font-size: 0.9em">
        <strong>Descrição: </strong>Mensagem da IF seria as mensagens disparadas quando a oportunidade(linha de crédito)
        da instituição atingir certos parâmetros. Essa mensagem é enviada no chat do cliente e no e-mail dele.
      </p>
      <p style="font-size: 0.9em">
        <strong>Funcionalidade: </strong> As mensagens da IF são disparadas quando a Instituição for ativa e for
        cumprido os critérios, que são baseados nos campos que serão preenchidos abaixo. Elas são Enviadas as 18 horas.
      </p>
      <ul>
        <li>
          <strong>Nome : </strong> Seria o nome dado a essa mensagem. Serve mais para visualização não é usado como
          parâmetro.
        </li>
        <li><strong>Fase : </strong> Seria em que fase a oportunidade do cliente se encontra.</li>
        <li>
          <strong>Intervalo: </strong> Seria quantos dias uma oportunidade esta numa faze especifica.
          <br />
          <strong>exemplo: </strong>
          <ul>
            <li>d-0 = {{ calculeDateAsDay(0) }}</li>
            <li>d-1 = {{ calculeDateAsDay(1) }}</li>
            <li>d-5 = {{ calculeDateAsDay(5) }}</li>
            <li>d-10 = {{ calculeDateAsDay(10) }}</li>
          </ul>
        </li>
        <li>
          <strong>Mensagem: </strong> Seria a mensagem a ser enviada para o cliente. É possível utilizar valores
          personalizados usando os campos padrões ou campos personalizados.
          <br />
          <strong>exemplo: </strong>
          <ul>
            <li>{{ '{' }}cliente{{ '}' }} => sera substituido pelo nome do cliente</li>
          </ul>
          <strong>obs. </strong> Pode ser apontado outros campos relacionados com o cliente mas deve ser alinhar qual é
          o nome do campo no banco de dados.
          <br />
        </li>
      </ul>
      <br />
    </div>

    <div class="field">
      <!---Campo de fase --->
      <div>
        <mat-form-field color="primary">
          <input
            matInput
            [formControl]="createMessageFormGroup.controls['messageNameCtrl']"
            placeholder="Nome"
            required
          />
          <!-- <mat-error *ngIf="createMessageFormGroup.controls['ifsLocalityNameCtrl'].hasError('required')">
            Digite um nome para a localidade
          </mat-error> -->
        </mat-form-field>
        <mat-form-field color="primary">
          <mat-label>Fase </mat-label>
          <mat-select
            placeholder="40"
            [formControl]="createMessageFormGroup.controls['oppPhaseCtrl']"
            name="oppPhase"
            required
            [compareWith]="compareObjectsValue"
          >
            <mat-option
              *ngFor="let faseOportunidade of opportunityStepsOptions"
              [value]="faseOportunidade.defaultprobability"
            >
              {{ faseOportunidade.defaultprobabilitystr + ' - ' + faseOportunidade.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!---Campo de intervalo --->
      <mat-form-field color="primary">
        <mat-label>Intervalo (em dias)</mat-label>
        <mat-chip-list #chipList aria-label="Interval days selection" required>
          <mat-chip
            *ngFor="let n of intervalInDaysList"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeIntervalDay(n)"
          >
            {{ n }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            type="number"
            min="1"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="addIntervalDay($event)"
            required
          />
        </mat-chip-list>
        <!-- <mat-error *ngIf="localityFormGroup.controls['ifsLocalityNameCtrl'].hasError('required')">
        Digite um nome para a localidade
        </mat-error> -->
      </mat-form-field>

      <!------Campo de Message------>
      <mat-form-field>
        <mat-label>Mensagem</mat-label>
        <textarea
          matInput
          name="automaticMessageContent"
          [formControl]="createMessageFormGroup.controls['automaticMessageContentCtrl']"
          placeholder="Message"
          name="automaticMessageContent"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="8"
          required
        ></textarea>
      </mat-form-field>

      <!------------>
      <!-- <mat-slide-toggle
        color="primary"
        name="automaticMessage"
        [formControl]="createMessageFormGroup.controls['automaticMessageCtl']"
    >
        Mensagens apos preposta
    </mat-slide-toggle> -->
    </div>

    <div class="text-right my-3">
      <button
        mat-raised-button
        class="btn"
        color="accent"
        type="button"
        (click)="onAddNewMessage()"
        [disabled]="isMessageInvalid()"
      >
        <mat-icon>add</mat-icon> &nbsp;Adicionar
      </button>
    </div>
  </div>
</div>

<hr class="my-4" />

<div class="d-flex justify-content-between my-3">
  <div>
    <mat-card-title>Mensagens</mat-card-title>
    <mat-card-subtitle>Mensagens adicionadas</mat-card-subtitle>
  </div>
</div>

<div class="mt-4">
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel *ngFor="let message of automaticMessage; let i = index">
      <mat-expansion-panel-header>
        {{ message.messageName }}
      </mat-expansion-panel-header>

      <div>
        <div class="d-flex justify-content-end">
          <div>
            <button
              mat-stroked-button
              *ngIf="['master','admin'].includes(loggedUser?.accessLevel)"
              color="basic"
              type="button"
              (click)="updateOrAddAutomaticMsgToInst(message)"
              [disabled]="isEditMessageInvalid(message)"
            >
              <mat-icon>save</mat-icon>
            </button>
          </div>

          <div>
            <button
              mat-stroked-button
              *ngIf="['master','admin'].includes(loggedUser?.accessLevel)"
              color="warn"
              type="button"
              (click)="removeAutomaticMessage(message)"
              [disabled]=""
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>

        <mat-form-field *ngIf="message.remindPercentage" color="primary">
          <mat-label>Fase</mat-label>
          <mat-select [(ngModel)]="message.remindPercentage" name="Fase" [compareWith]="compareObjectsValue" [disabled]="!['master','admin'].includes(this.loggedUser?.accessLevel)">
            <mat-option
              *ngFor="let faseOportunidade of opportunityStepsOptions"
              [value]="faseOportunidade.defaultprobability"
            >
              {{ faseOportunidade.defaultprobabilitystr + ' - ' + faseOportunidade.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field color="primary">
          <mat-label>Intervalo (em dias)</mat-label>
          <mat-chip-list #chipList aria-label="Interval days selection" required>
            <mat-chip
              *ngFor="let interval of message.intervalInDays"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="removeIntervalDay(interval, i)"
            >
              {{ interval }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="addIntervalDay($event, i)"
              [readonly]="!['master','admin'].includes(this.loggedUser?.accessLevel)"
              type="number"
              min="1"
            />
          </mat-chip-list>
        </mat-form-field>

        <div class="d-flex justify-content-between">
          <mat-form-field color="primary">
            <mat-label>Mensagem</mat-label>
            <textarea
              matInput
              [(ngModel)]="message.templateMessage"
              name="automaticMessageContent"
              placeholder="Message"
              [value]="message.templateMessage"
              placeholder="Message"
              name="automaticMessageContent"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="8"
              [readonly]="!['master','admin'].includes(this.loggedUser?.accessLevel)"
              required
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
