import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { firestore } from 'firebase';
import { Agent, AgentEvent, AgentLog } from 'functions/src/models/Agent';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AgentService implements OnDestroy {
  userSubscription: Subscription;
  agentSubscription: Subscription;

  agent = new BehaviorSubject<Agent>(null);

  constructor(
    private angularFirestore: AngularFirestore,
    private authService: AuthService,
    private http: HttpClient,
    private router: Router
  ) {
    this.userSubscription = authService.user.subscribe((user) => {
      if (user && !user.isAnonymous && user.isAgent) {
        this.agentSubscription = this.angularFirestore
          .doc(`agents/${user.uid}`)
          .valueChanges()
          .subscribe((agent: Agent) => {
            this.agent.next({
              ...agent,
              emailVerified: user.emailVerified,
            });
          });

        this.updateAgentOnFirestore(user.uid, {
          emailVerified: user.emailVerified,
          displayName: user.displayName,
        });
      } else {
        this.agent.next(null);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  async checkIfCNPJExists(CNPJ: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!CNPJ) {
        return resolve();
      }

      this.angularFirestore
        .collection<Agent>(`agents`, (ref) => ref.where('cnpj', '==', CNPJ.trim()))
        .get()
        .toPromise()
        .then((snapshot) => {
          if (!snapshot.empty) {
            return reject('existent-cnpj');
          }
          return resolve();
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  async createAgent(agentData): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (agentData.cnpj) {
        try {
          await this.checkIfCNPJExists(agentData.cnpj);
        } catch (err) {
          if (err === 'existent-cnpj') {
            return resolve('existent-cnpj');
          } else {
            return reject(err);
          }
        }
      }
      const agent = {
        ...agentData,
        isActive: true,
        isApprovedByAdmin: false,
        notifications: {
          statusChanged: true,
          leadCreate: true,
          leadUpdate: true,
          leadAcceptedOffer: true,
        },
        status: 'waiting-approval',
      };

      this.http
        .post(
          `${environment.functionsUrl}/agent/create`,
          { agent },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          return resolve('success');
        })
        .catch((err) => {
          if (err.error && err.error.isExistentAuth) {
            return resolve('email-in-use');
          } else {
            console.error('Problemas ao tentar criar um novo usuário.', err);
            return reject(err);
          }
        });
    });
  }

  async getAgentByEmail(email: string): Promise<Agent> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection<Agent>(`agents`, (ref) => ref.where('email', '==', email.trim().toLowerCase()))
        .get()
        .toPromise()
        .then((snapshot) => {
          if (!snapshot.empty) {
            resolve(snapshot.docs[0].data());
          }
          return resolve(null);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  async getAgentByUID(uid: string): Promise<Agent> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc<Agent>(`agents/${uid}`)
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.exists) {
            return resolve(doc.data());
          }
          return resolve(null);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  public getAgentConfig(): Observable<any> {
    return this.angularFirestore.doc(`configuracao/agents`).valueChanges();
  }

  async redirectAccordingStatus(shouldSendToLogin = false, current = '', next = '/agents/leads'): Promise<void> {
    const statusSubs = this.agent.pipe(skip(1)).subscribe((agent) => {
      let redirection = next;

      if (agent) {
        if (current.indexOf('signup') < 0) {
          if (!agent || agent.isAnonymous) {
            redirection = `${shouldSendToLogin ? '/entrar' : '/agents/signup'}`;
          } else if (!agent.emailVerified || !agent.isApprovedByAdmin || !agent.isActive) {
            redirection = '/agents/profile';
          }
        }

        if (redirection !== current) {
          console.log(`Redirecting from ${current} to ${redirection}`);
          this.router.navigate([redirection]);
        }

        if (statusSubs) {
          statusSubs.unsubscribe();
        }
      }
    });
  }

  registerAgentLog(agent: string, event: AgentEvent, user?: string): void {
    const log: AgentLog = {
      date: firestore.Timestamp.now(),
      event: event,
    };

    if (user) {
      log.user = user;
    }

    const agentRef: AngularFirestoreDocument<AgentLog> = this.angularFirestore
      .doc(`agents/${agent}`)
      .collection('logs')
      .doc(log.date.toDate().toISOString());
    agentRef
      .set(log, { merge: true })
      .then(() => {
        console.log('Added AgentLog');
      })
      .catch((e) => {
        console.error('Error on adding AgentLog', e);
      });
  }

  updateAgentData(uid: string, data: Agent): Promise<void> {
    return new Promise((resolve, reject) => {
      if (uid) {
        const agentRef: AngularFirestoreDocument<Agent> = this.angularFirestore.doc(`agents/${uid}`);

        agentRef
          .set(data, { merge: true })
          .then(() => {
            console.log('Agent data updated on database');

            this.registerAgentLog(uid, AgentEvent.updated);

            return resolve();
          })
          .catch((err) => {
            console.error('Error updating agent data', err);
            reject(err);
          });
      }
    });
  }

  updateAgentOnFirestore(uid: string, data: Agent = {}): Promise<void> {
    return new Promise((resolve, reject) => {
      if (uid) {
        const agentRef: AngularFirestoreDocument<Agent> = this.angularFirestore.doc(`agents/${uid}`);

        agentRef
          .set(
            {
              ...data,
              lastInteraction: firestore.Timestamp.now(),
            },
            { merge: true }
          )
          .then(() => {
            console.log('Agent data updated on database');
            resolve();
          })
          .catch((err) => {
            console.error('Error getting agent data', err);
            reject(err);
          });
      } else {
        reject(new Error('Missing agent uid'));
      }
    });
  }
}
