import { Component, Input, OnInit } from '@angular/core';
import { Customer } from '../../../../../functions/src/models/Customer';

@Component({
  selector: 'app-user-account-data',
  templateUrl: './user-account-data.component.html',
  styleUrls: ['./user-account-data.component.scss']
})
export class UserAccountDataComponent implements OnInit {

  @Input() customer: Customer;
 
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }
  

  ngOnInit(): void {
  
  console.log(this.customer);
  }


}
