<mat-dialog-content class="document-upload-modal">
  <div class="close-icon">
    <i class="material-icons" [mat-dialog-close]="undefined">close</i>
  </div>
  <div class="content d-flex flex-column align-items-center">
    <h1>Declaração de Faturamento</h1>
    <p>Carregue a Declaração de faturamento dos últimos 24 meses, assinada por você e pelo seu contador!</p>
    <button mat-raised-button class="upload-button" (click)="uploadDocument()">Carregar Declaração</button>
    <p>Preencha o e-mail do seu contador para ajudar no processo</p>
    <mat-form-field appearance="outline" class="email-input">
      <mat-label>Email do contador</mat-label>
      <input matInput type="email" placeholder="Digite o email" [(ngModel)]="contadorEmail" />
    </mat-form-field>
    <div style="align-items: center; margin-top: 70px;">
      <button mat-raised-button class="upload-button" (click)="continue()">continuar</button>
    </div>
  </div>
</mat-dialog-content>