import { Injectable } from '@angular/core';
import { ChatMenuItem } from '../models/chat-menuItem';

@Injectable({
  providedIn: 'root'
})
export class ChatMenuService {
  menuItem: ChatMenuItem;

  getMenuItem(): ChatMenuItem {
    return this.menuItem;
  }

  setMenuItem(menuitem: ChatMenuItem): void {
    this.menuItem = menuitem;
  }

}
