import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { firestore } from 'firebase';
import { AgentNote, BankerNote, Note, UserNote, UserNotification } from 'functions/src/models/Notes';
import { User } from 'functions/src/models/User';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { AgentsAdminService } from 'src/app/admin/services/agents-admin.service';
import { NotesService } from 'src/app/admin/services/notes.service';
import { OpportunityManagementService } from 'src/app/admin/services/opportunity-management.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forward-notes',
  templateUrl: './forward-notes.component.html',
  styleUrls: ['./forward-notes.component.scss'],
})

export class ForwardNotesComponent implements OnInit, OnDestroy {
  authSubscription: Subscription;
  agentSubscription: Subscription;

  agentData: UserNotification['recipients'][0] = {
    Name: '',
    Email: '',
    type: 'AGENT',
  };

  noteText: string;
  forwardTo: 'BANKER' | 'AGENT' | 'USER';
  forwardToBankInstitution: {
    systemName: string;
    name: string;
  };
  customer: string;
  customerData: User;
  isLoading = false;
  noteFrom: 'BANKER' | 'AGENT' | 'USER';
  createdBy = {
    uid: '',
    name: '',
    email: '',
  };

  bankersInstitutions: {
    systemName: string;
    name: string;
  }[];


  constructor(
    private dialog: MatDialog,
    private notesService: NotesService,
    private authService: AuthService,
    private agentService: AgentsAdminService,
    private oppService: OpportunityManagementService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.customer = data.customer;
    this.customerData = data.customerData;
    this.noteText = data.noteText;
    this.noteFrom = data.noteFrom;
  }

  async ngOnInit(): Promise<void> {

    let bankersInstitutionsNoOrder = [];
    await this.oppService.getOpportunities(this.customerData.uid)
    .then((opportunities) => {
       bankersInstitutionsNoOrder = opportunities
        .filter((opp) => opp.stageOpportunity.defaultprobability > 30)
        .map((opp) => ({
          systemName: opp.institutionKey,
          name: opp.institutionName,
        }));
    })
    .catch((error) => {
      console.error('Erro ao obter as oportunidades:', error);
    });

    this.bankersInstitutions = _.orderBy(bankersInstitutionsNoOrder, ['name'], 'asc');

    this.authSubscription = this.authService.user.subscribe((loggedUser) => {
      this.createdBy.email = loggedUser.email;
      this.createdBy.name = loggedUser.name;
      this.createdBy.uid = loggedUser.uid;
    });

    this.agentSubscription = this.agentService.getAgentData(this.customerData.agent).subscribe((result) => {
      this.agentData.Name = result.name;
      this.agentData.Email = result.email;
    });
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
  }


  closeSuccess(): void {
    this.dialog.closeAll();
    this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: 'Mensagem Encaminhada',
        alertDescription: 'A mensagem foi encaminhada com sucesso.',
        isOnlyConfirm: true,
      },
    });
  }

  dialogError(err): void {
    console.error(err);
    this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: 'Erro ao salvar',
        alertDescription: 'Não foi possível encaminhar a mensagem. Tente novamente mais tarde.',
        isOnlyConfirm: true,
      },
    });
  }

  createNote(): void {
    const note: Note = {
      createdAt: firestore.Timestamp.now(),
      createdBy: this.createdBy,
      message: this.noteText,
    };

    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Enviar Mensagem',
          alertDescription: 'Deseja realmente enviar essa mensagem?',
        },
      })
      .afterClosed()
      .subscribe(() => {
        switch (this.forwardTo) {
          case 'USER': {
            const userNote: UserNote = {
              ...note,
              type: 'ADMIN',
              agent: this.customerData.agent || null,
            };
            const notificationData: UserNotification = {
              type: 'ADMIN',
              name: this.createdBy.name,
              companyName: this.customerData?.companyName || null,
              content: this.noteText,
              recipients: [
                {
                  Name: this.customerData.name,
                  Email: this.customerData.email,
                  type: 'USER',
                },
              ],
              link: {
                toAdmin: `${environment.baseURL}/admin/clientes/detalhes/${this.customer}`,
                toAgent: `${environment.baseURL}/agents/lead/${this.customer}`,
                toUser: `${environment.baseURL}/mensagens`,
              },
            };

            if (this.customerData.agent) {
              notificationData.recipients.push(this.agentData);
            }

            this.notesService
              .createUserNote(this.customer, userNote, notificationData)
              .then(() => {
                this.closeSuccess();
              })
              .catch((err) => {
                this.dialogError(err);
              })
              .finally(() => {
                confirmSubscription.unsubscribe();
              });
            break;
          }
          case 'AGENT': {
            const agentNote: AgentNote = {
              ...note,
              type: 'ADMIN',
              agent: this.customerData.agent || null,
            };
            this.notesService
              .createAgentNote(this.customer, agentNote, { companyName: this.customerData.companyName })
              .then(() => {
                this.closeSuccess();
              })
              .catch((err) => {
                this.dialogError(err);
              })
              .finally(() => {
                confirmSubscription.unsubscribe();
              });
            break;
          }
          case 'BANKER': {
            const bankerNote: BankerNote = {
              ...note,
              type: 'ADMIN',
            };
            this.notesService
              .createBankerNote(this.customer, this.forwardToBankInstitution.systemName, bankerNote, {
                address: this.customerData.address,
                companyName: this.customerData.companyName,
                institution: this.forwardToBankInstitution.name,
              })
              .then(() => {
                this.closeSuccess();
              })
              .catch((err) => {
                this.dialogError(err);
              })
              .finally(() => {
                confirmSubscription.unsubscribe();
              });

            break;
          }
        }
      });
  }
}
