<div *ngIf="!isCreateEdit && loggedUser">
  <mat-card>
    <mat-card-content>
      <div class="d-flex align-items-start">
        <div style="flex: 1">
          <mat-card-title>Cadastro de Instituições Financeiras</mat-card-title>
          <mat-card-subtitle>
            Consulte, cadastre e altere os dados das intituições financeiras no sistema.
          </mat-card-subtitle>
        </div>
        <div *ngIf="!isCreateEdit">
          <button mat-raised-button class="btn" color="primary" type="button" (click)="onAdicionarNovo()">
            <mat-icon>add</mat-icon> &nbsp;Nova instituição
          </button>
        </div>
      </div>

      <div *ngIf="!isCreateEdit" class="mb-4">
        <!---<mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtro"
            [(ngModel)]="matInputInstFinanc">
        </mat-form-field>--->

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field color="primary">
              <input matInput [formControl]="ifsName" placeholder="Nome" />
            </mat-form-field>
          </div>

          <div class="col-12 col-md-3">
            <mat-form-field color="primary">
              <mat-label>No touch</mat-label>
              <mat-select [formControl]="ifsNoTouch">
                <mat-option value="sim">Sim</mat-option>
                <mat-option value="nao">Não</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-3">
            <mat-form-field color="primary">
              <mat-label>Ativo</mat-label>
              <mat-select [formControl]="ifsAtivo">
                <mat-option></mat-option>
                <mat-option value="sim">Sim</mat-option>
                <mat-option value="nao">Não</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 d-flex align-items-center" *ngIf="garantias">
            <!-- filter by guarantees -->
            <mat-form-field color="primary" style="flex: 1; max-width: 180px" class="mr-2"
              matTooltip="Escolha o operador para as garantias">
              <mat-label>Filtro</mat-label>
              <mat-select [formControl]="ifsGuaranteesOperator">
                <mat-option></mat-option>
                <mat-option value="exactly">É exatamente</mat-option>
                <mat-option value="yes-all">Possui todos</mat-option>
                <mat-option value="yes-any">Possui algum</mat-option>
                <mat-option value="not-all">Não possui nenhum</mat-option>
                <mat-option value="not-any">Não possui algum</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field color="primary" style="flex: 1" matTooltip="Selecionar garantias">
              <mat-label>Garantias</mat-label>
              <!-- filtro -->
              <mat-select multiple style="flex: 1" [formControl]="ifsSelectedGuarantees"
                [disabled]="!ifsGuaranteesOperator.value" (selectionChange)="selectedGuaranteesChange($event)">
                <mat-option *ngFor="let garantia of garantias" [value]="garantia?.id">{{ garantia?.nome }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="isTypeOfAnticipationEnabled" color="primary" class="ml-2 flex-fill"
              matTooltip="Selecione o(s) tipo(s) de antecipação(ões)">
              <mat-label>Tipo de Antecipação de Recebíveis</mat-label>
              <mat-select multiple style="flex: 1" [formControl]="ifsSelectedAnticipations">
                <mat-option *ngFor="let anticipation of typeOfAnticipationList" [value]="anticipation?.id">{{
                  anticipation.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 d-flex align-items-center" *ngIf="modalidades">
            <mat-form-field color="primary" style="flex: 1; max-width: 180px" class="mr-2"
              matTooltip="Escolha o operador para as modalidades">
              <mat-label>Filtro</mat-label>
              <mat-select [formControl]="ifsModalityOperator">
                <mat-option></mat-option>
                <mat-option value="exactly">É exatamente</mat-option>
                <mat-option value="yes-all">Possui todos</mat-option>
                <mat-option value="yes-any">Possui algum</mat-option>
                <mat-option value="not-all">Não possui nenhum</mat-option>
                <mat-option value="not-any">Não possui algum</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field color="primary" style="flex: 1" matTooltip="Selecionar modalidades">
              <mat-label>Modalidades</mat-label>
              <mat-select multiple style="flex: 1" [formControl]="ifsSelectedModality"
                [disabled]="!ifsModalityOperator.value">
                <mat-option *ngFor="let modalidade of modalidades" [value]="modalidade?.id">{{
                  modalidade?.nome
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 d-flex align-items-center" *ngIf="taxRegimes">
            <mat-form-field color="primary" style="flex: 1; max-width: 180px" class="mr-2"
              matTooltip="Escolha o operador para os regimes tributários">
              <mat-label>Filtro</mat-label>
              <mat-select [formControl]="ifsTaxRegimeOperator">
                <mat-option></mat-option>
                <mat-option value="exactly">É exatamente</mat-option>
                <mat-option value="yes-all">Possui todos</mat-option>
                <mat-option value="yes-any">Possui algum</mat-option>
                <mat-option value="not-all">Não possui nenhum</mat-option>
                <mat-option value="not-any">Não possui algum</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field color="primary" style="flex: 1" matTooltip="Selecionar os Regimes Tributários">
              <mat-label>Regime Tributário</mat-label>
              <mat-select multiple style="flex: 1" [formControl]="ifsSelectedTaxRegime"
                [disabled]="!ifsTaxRegimeOperator.value">
                <mat-option *ngFor="let t of taxRegimes" [value]="t?.id">{{ t?.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field color="primary">
              <mat-label>Faturamento mínimo</mat-label>
              <input matInput type="number" [formControl]="ifsMin" [min]="0" [max]="faturamentoMaximo()" />
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field color="primary">
              <mat-label>Faturamento máximo</mat-label>
              <input matInput type="number" [formControl]="ifsMax" [min]="faturamentoMinimo()" />
            </mat-form-field>
          </div>

          <div class="col-12 text-center">
            <button mat-stroked-button color="accent" type="button" style="width: 100%; max-width: 200px" class="mx-1"
              (click)="clearFields()">
              Limpar
            </button>
            <button mat-raised-button color="primary" type="button" style="width: 100%; max-width: 200px" class="mx-1"
              (click)="getData()">
              Filtrar
            </button>
          </div>
        </div>
      </div>

      <div class="table-container">
        <table mat-table [dataSource]="dataSourceInstFinanc" matSort #matSortInstFinanc="matSort" multiTemplateDataRows>
          <ng-container matColumnDef="imagem">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let instFinanc">
              <div *ngIf="instFinanc.arquivoInfo">
                <img src="{{ instFinanc.arquivoInfo.path }}" width="30px" />
              </div>
              <div *ngIf="!instFinanc.arquivoInfo">
                <mat-icon color="primary">account_balance</mat-icon>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
            <td mat-cell *matCellDef="let instFinanc">{{ instFinanc.nome }}</td>
          </ng-container>
          <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
            <td mat-cell *matCellDef="let instFinanc">{{ instFinanc.tipo.nome }}</td>
          </ng-container>
          <ng-container matColumnDef="faturamentoMinimo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Faturamento Mínimo</th>
            <td mat-cell *matCellDef="let instFinanc">
              {{ instFinanc.faturamentoMinimo ? (instFinanc.faturamentoMinimo | currency: 'BR').slice(0, -3) : '' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="faturamentoMaximo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Faturamento Máximo</th>
            <td mat-cell *matCellDef="let instFinanc">
              {{ instFinanc.faturamentoMaximo ? (instFinanc.faturamentoMaximo | currency: 'BR').slice(0, -3) : '' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="ativo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ativo</th>
            <td mat-cell *matCellDef="let instFinanc">{{ instFinanc.ativo.name }}</td>
          </ng-container>
          <ng-container matColumnDef="acao">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let instFinanc" (click)="$event.stopPropagation()">
              <button mat-flat-button (click)="onEditar(instFinanc)">
                <mat-icon color="primary">edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let instFinanc" [attr.colspan]="columnsInstFinanc.length">
              <div [@detailExpand]="expandedElementId === instFinanc.id && instFinanc.ativo ? 'expanded' : 'collapsed'"
                [style.height]="expandedElementId === instFinanc.id ? 'unset' : '0 !important'">
                <div class="py-3">
                  <p><b>Descrição:</b></p>
                  <div style="white-space: pre-wrap">{{ instFinanc.description || '-' }}</div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsInstFinanc"></tr>
          <tr mat-row *matRowDef="let instFinanc; columns: columnsInstFinanc" class="example-element-row taskboard-task"
            [class.example-expanded-row]="expandedElementId === instFinanc.id"
            (click)="expandedElement = expandedElement === instFinanc ? null : instFinanc; onClickExpanded()"
            matTooltip="Clique para visualizar a descrição"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
      </div>
      <mat-paginator #paginatorInstFinanc [pageSizeOptions]="[25, 50, 75, 100]"></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="isCreateEdit && loggedUser">
  <mat-card>
    <mat-card-content>
      <div class="d-flex flex-row">
        <div style="padding-right: 10px">
          <button mat-mini-fab color="primary" type="button" (click)="onVoltar()" matTooltip="Voltar a listagem">
            <mat-icon>chevron_left</mat-icon>
          </button>
        </div>
        <div>
          <mat-card-title> Cadastro de Instituições Financeiras {{ tituloFormInstituicao }} </mat-card-title>
          <mat-card-subtitle>
            Consulte, cadastre e altere os dados das intituições financeiras no sistema.
          </mat-card-subtitle>
        </div>
      </div>
      <mat-tab-group>
        <mat-tab label="Informações Gerais" style="overflow: hidden !important">
          <div style="margin: 10px">
            <form [formGroup]="createEditFormGroup">
              <div class="d-flex">
                <div class="flex-grow-1" style="padding-right: 10px">
                  <mat-form-field style="width: 100%">
                    <input type="hidden" formControlName="idCtrl" name="id" value="" />
                    <input matInput placeholder="Nome" formControlName="nomeCtrl" required name="nome"
                      (input)="slugifName($event.target.value)" />
                    <mat-error *ngIf="createEditFormGroup.controls['nomeCtrl'].hasError('required')">
                      Informe o nome da instituição bancária.</mat-error>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>Nome no sistema</mat-label>
                    <input matInput placeholder="Nome no sistema" formControlName="nomeNoSistemaCtrl" required
                      name="nomeNoSistema" />
                  </mat-form-field>
                </div>
                <div class="d-flex align-items-center">
                  <mat-slide-toggle name="naoIncluirAvaliacao" formControlName="naoIncluirAvaliacaoCtrl">
                    Não incluir na avaliação
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <mat-form-field style="padding-right: 8px">
                  <mat-label>Tipo</mat-label>
                  <mat-select placeholder="Tipo" formControlName="tipoCtrl" name="tipo" required
                    [compareWith]="compareObjectsMn">
                    <mat-option *ngFor="let tipoCtrl of tipoInstituicaoFinanceira" [value]="tipoCtrl">
                      {{ tipoCtrl.nome }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="createEditFormGroup.controls['tipoCtrl'].hasError('required')">
                    Informe o tipo de instituição bancária.</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Estágio Inicial das Oportunidades</mat-label>
                  <mat-select formControlName="defaultInitialStageCtrl" name="defaultInitialStage" required
                    [compareWith]="compareStages">
                    <mat-option *ngFor="let stageValue of oppStagesValues" [value]="stageValue">
                      {{ stageValue }}% - {{ oppStage[stageValue] }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="createEditFormGroup.controls['defaultInitialStageCtrl'].hasError('required')">
                    Informe o estágio inicial padrão.</mat-error>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field>
                  <mat-label>Fluxo das oportunidades</mat-label>
                  <mat-select formControlName="opportunityStreamCtrl" name="opportunityStream"
                  [compareWith]="compareObjects">
                    <mat-option *ngFor="let oppStreamOption of opportunityStreamOptions" [value]="oppStreamOption">
                      {{ oppStreamOption.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="createEditFormGroup.controls['opportunityStreamCtrl'].hasError('required')">
                    Informe o fluxo das oportunidades.</mat-error>
                  </mat-form-field>
                  <button class="opp-stream-button" [disabled]="!createEditFormGroup.controls['opportunityStreamCtrl']?.value" (click)="openOppStreamDialog()">Configurar</button>
              </div>
              <div class="d-flex justify-content-between">
                <mat-form-field>
                  <textarea matInput matTextareaAutosize matAutosizeMinRows="3"
                    placeholder="Obervações sobre as Instituições" formControlName="descriptionCtrl"
                    name="description"></textarea>
                </mat-form-field>
              </div>
              <mat-form-field class="flex-fill">
                <input matInput type="text" placeholder="Descrição pública (visível para o cliente)"
                  formControlName="publicDescriptionCtrl" name="publicDescription" />
              </mat-form-field>

              <div class="d-flex justify-content-between">
                <mat-form-field class="pr-2">
                  <mat-label>Faturamento Mínimo (R$)</mat-label>
                  <input matInput type="text" placeholder="Faturamento Mínimo (R$)"
                    formControlName="faturamentoMinimoStrCtrl" required name="faturamentoMinimoStr"
                    [textMask]="{ mask: currencyMask }" />
                  <span matSuffix *ngIf="createEditFormGroup.controls['faturamentoMinimoStrCtrl'].value"></span>
                  <mat-error *ngIf="createEditFormGroup.controls['faturamentoMinimoStrCtrl'].hasError('required')">
                    Informe um faturamento mínimo aceito pela instituição bancária.</mat-error>
                </mat-form-field>

                <mat-form-field class="pl-2">
                  <mat-label>Faturamento Máximo (R$)</mat-label>
                  <input matInput type="text" placeholder="Faturamento Máximo (R$)"
                    formControlName="faturamentoMaximoStrCtrl" required name="faturamentoMaximoStr"
                    [textMask]="{ mask: currencyMask }" />
                  <span matSuffix *ngIf="createEditFormGroup.controls['faturamentoMaximoStrCtrl'].value"></span>
                  <mat-error *ngIf="createEditFormGroup.controls['faturamentoMaximoStrCtrl'].hasError('required')">
                    Informe um faturamento máximo aceito pela instituição bancária.</mat-error>
                  <mat-error *ngIf="createEditFormGroup.controls['faturamentoMaximoStrCtrl'].hasError('mismatch')">
                    Informe um valor de faturamento máximo maior que o valor de faturamento mínimo.</mat-error>
                </mat-form-field>
              </div>

              <div class="d-flex justify-content-between">
                <mat-form-field class="pr-2">
                  <mat-label>Score Mínimo</mat-label>
                  <input matInput type="text" name="scoreMinStr" placeholder="Score Mínimo"
                    formControlName="scoreMinStrCtrl" [textMask]="{ mask: scoreMask }" />
                </mat-form-field>

                <mat-form-field class="pl-2">
                  <mat-label>Score Máximo</mat-label>
                  <input matInput type="text" name="scoreMaxStr" placeholder="Score Máximo"
                    formControlName="scoreMaxStrCtrl" [textMask]="{ mask: scoreMask }" />
                  <mat-error *ngIf="createEditFormGroup.controls['scoreMaxStrCtrl'].hasError('mismatch')">
                    Informe um valor de score máximo maior que o valor de score mínimo.
                  </mat-error>
                </mat-form-field>
              </div>

              <mat-form-field style="width: 100%">
                <mat-label>Endividamento > 25%</mat-label>
                <mat-select placeholder="Endividamento > 25%" formControlName="endividamento25pCtrl"
                  name="endividamento25p" required [compareWith]="compareObjects">
                  <mat-option *ngFor="let endividamento25pCtrl of opcaoSimNao" [value]="endividamento25pCtrl">
                    {{ endividamento25pCtrl.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="createEditFormGroup.controls['endividamento25pCtrl'].hasError('required')">
                  Informe se a instituição bancária aceita clientes com endividamento maior que 25%.</mat-error>
              </mat-form-field>
              <div class="d-flex p-0 m-0">
                <mat-form-field class="flex-fill mr-2">
                  <mat-label>Tempo mínimo de CNPJ (anos)</mat-label>
                  <input matInput type="number" name="minCompanyAge" placeholder="Tempo mínimo de CNPJ (anos)"
                    formControlName="minCompanyAgeCtrl" />
                </mat-form-field>
                <mat-form-field class="flex-fill ml-2">
                  <mat-label>Tempo mínimo de CNPJ (meses)</mat-label>
                  <input matInput type="number" name="minCompanyMonth" placeholder="Tempo mínimo de CNPJ (meses)"
                    formControlName="minCompanyMonthCtrl" />
                </mat-form-field>
              </div>

              <mat-form-field style="width: 100%">
                <mat-label>Recuperação Judicial</mat-label>
                <mat-select placeholder="Recuperação Judicial" formControlName="recuperacaoJudicialCtrl"
                  name="recuperacaoJudicial" [compareWith]="compareObjects">
                  <mat-option *ngFor="let recuperacaoJudicialCtrl of opcaoSimNao" [value]="recuperacaoJudicialCtrl">
                    {{ recuperacaoJudicialCtrl.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%">
                <mat-label>Setores</mat-label>
                <mat-select placeholder="Setores" formControlName="setoresCtrl" name="setor" multiple
                  [compareWith]="compareObjectsMn">
                  <mat-option *ngFor="let setor of setores" [value]="setor">
                    {{ setor.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="d-flex align-items-center">
                <mat-form-field style="flex: 1">
                  <!-- casdastro -->
                  <mat-label>Garantias</mat-label>
                  <mat-select style="flex: 1" placeholder="Garantias" formControlName="garantiasCtrl" name="garantia"
                    multiple required [compareWith]="compareObjectsMn"
                    (selectionChange)="selectedGuaranteesAnticipationChange($event)">
                    <mat-option *ngFor="let garantia of garantias" [value]="garantia">
                      {{ garantia.nome }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="isTypeOfAnticipationEnabled" color="primary" class="ml-2 flex-fill"
                  matTooltip="Selecione o(s) tipo(s) de antecipação(ões)">
                  <mat-label>Tipo de Antecipação de Recebíveis</mat-label>
                  <mat-select style="flex: 1" multiple formControlName="typeOfAnticipationCtrl"
                    [compareWith]="compareObjects" name="anticipation" [required]="isTypeOfAnticipationEnabled"
                    (selectionChange)="selecteChangeAntecipation($event)">
                    <mat-option *ngFor="let anticipation of typeOfAnticipationList" [value]="anticipation">{{
                      anticipation.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="istypeOfAnticipationCard" color="primary" class="ml-2 flex-fill"
                  matTooltip="Selecione o(s) tipo(s) de creditCard(s)">
                  <mat-label>Maquinha de Cartão</mat-label>
                  <mat-select style="flex: 1" formControlName="creditCardCtrl" [compareWith]="compareObjects"
                    name="creditCard" multiple [required]="istypeOfAnticipationCard">
                    <mat-option *ngFor="let creditCard of creditCard" [value]="creditCard">
                      {{ creditCard.nome }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <mat-form-field style="width: 100%">
                <mat-label>Linhas/Produtos</mat-label>
                <mat-select placeholder="Linhas/Produtos" formControlName="linhasCtrl" name="linha" multiple
                  [compareWith]="compareObjectsMn">
                  <mat-option *ngFor="let linha of linhas" [value]="linha">
                    {{ linha.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%">
                <mat-label>Valor da Operação de Crédito (R$)</mat-label>
                <input matInput type="text" placeholder="(R$)" formControlName="valorOperacaoStrCtrl"
                  name="valorOperacaoStr" [textMask]="{ mask: currencyMask }" />
                <span matSuffix *ngIf="createEditFormGroup.controls['valorOperacaoStrCtrl'].value"></span>
              </mat-form-field>
              <mat-form-field style="width: 100%">
                <mat-label>Modalidade</mat-label>
                <mat-select placeholder="Modalidade" formControlName="modalidadeCtrl" name="modalidade" multiple
                  [compareWith]="compareObjectsMn">
                  <mat-option *ngFor="let modalidade of modalidades" [value]="modalidade">
                    {{ modalidade.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="taxRegimes" style="width: 100%">
                <mat-label>Regime Tributário</mat-label>
                <mat-select placeholder="Regimes Tributários" formControlName="taxRegimeCtrl" name="taxRegime" multiple
                  [compareWith]="compareTaxRegimes">
                  <mat-option *ngFor="let t of taxRegimes" [value]="t">
                    {{ t.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%">
                <mat-label>Prazo máximo (ex.: 12 meses)</mat-label>
                <input matInput placeholder="Prazo máximo (ex.: 12 meses)" formControlName="prazoCtrl" required
                  name="prazo" />
                <mat-error *ngIf="createEditFormGroup.controls['prazoCtrl'].hasError('required')">
                  Informe o prazo de financiamento que será exibido na proposta.</mat-error>
              </mat-form-field>

              <div class="d-flex justify-content-between">
                <mat-form-field class="pr-2">
                  <mat-label>Valor Mínimo de Empréstimo (R$)</mat-label>
                  <input matInput type="text" placeholder="Valor Mínimo de Empréstimo (R$)"
                    formControlName="capitalMinimoStrCtrl" name="capitalMinimoStr"
                    [textMask]="{ mask: currencyMask }" />
                  <span matSuffix *ngIf="createEditFormGroup.controls['capitalMinimoStrCtrl'].value"></span>
                  <mat-error *ngIf="createEditFormGroup.controls['capitalMinimoStrCtrl'].hasError('mismatch')">
                    Informe um valor mínimo de empréstimo menor que o valor máximo de empréstimo.</mat-error>
                </mat-form-field>

                <mat-form-field class="pl-2">
                  <mat-label>Valor Máximo de Empréstimo (R$)</mat-label>
                  <input matInput type="text" placeholder="Valor Máximo de Empréstimo (R$)"
                    formControlName="capitalMaximoStrCtrl" name="capitalMaximoStr"
                    [textMask]="{ mask: currencyMask }" />
                  <span matSuffix *ngIf="createEditFormGroup.controls['capitalMaximoStrCtrl'].value"></span>
                  <mat-error *ngIf="createEditFormGroup.controls['capitalMaximoStrCtrl'].hasError('mismatch')">
                    Informe um valor máximo de empréstimo maior que o valor mínimo de empréstimo.</mat-error>
                </mat-form-field>
              </div>

              <div class="d-flex justify-content-between">
                <mat-form-field class="pr-2">
                  <mat-label>Pefin</mat-label>
                  <mat-select placeholder="Pefin" formControlName="pefinCtrl" name="pefin" required
                    [compareWith]="compareObjects">
                    <mat-option *ngFor="let pefinCtrl of opcaoSimNao" [value]="pefinCtrl">
                      {{ pefinCtrl.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="createEditFormGroup.controls['pefinCtrl'].hasError('required')">
                    Informe se a instituição bancária aceita clientes com pefin.</mat-error>
                </mat-form-field>

                <mat-form-field class="pl-2">
                  <mat-label>Refin</mat-label>
                  <mat-select placeholder="Refin" formControlName="refinCtrl" name="refin" required
                    [compareWith]="compareObjects">
                    <mat-option *ngFor="let refinCtrl of opcaoSimNao" [value]="refinCtrl">
                      {{ refinCtrl.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="createEditFormGroup.controls['refinCtrl'].hasError('required')">
                    Informe se a instituição bancária aceita clientes com refin.</mat-error>
                </mat-form-field>
              </div>
              <div class="d-flex justify-content-between">
                <mat-form-field class="pr-2">
                  <mat-label>Cheque Sem Fundo</mat-label>
                  <mat-select placeholder="Cheque Sem Fundo" formControlName="chequeSemFundoCtrl" name="chequeSemFundo"
                    [compareWith]="compareObjects">
                    <mat-option *ngFor="let chequeSemFundoCtrl of opcaoSimNao" [value]="chequeSemFundoCtrl">
                      {{ chequeSemFundoCtrl.name }}
                    </mat-option>
                  </mat-select>
                  <!----
                  <mat-error *ngIf="createEditFormGroup.controls['chequeSemFundoCtrl'].hasError('required')">
                    Informe se a instituição bancária aceita clientes com cheque sem fundo.</mat-error
                  >---->
                </mat-form-field>

                <mat-form-field class="pl-2">
                  <mat-label>Fraude</mat-label>
                  <mat-select placeholder="Fraude" formControlName="fraudeCtrl" name="fraude"
                    [compareWith]="compareObjects">
                    <mat-option *ngFor="let fraudeCtrl of opcaoSimNao" [value]="fraudeCtrl">
                      {{ fraudeCtrl.name }}
                    </mat-option>
                  </mat-select>
                  <!----
                  <mat-error *ngIf="createEditFormGroup.controls['refinCtrl'].hasError('required')">
                    Informe se a instituição bancária aceita clientes com fraude.</mat-error
                  >--->
                </mat-form-field>
              </div>

              <div class="d-flex justify-content-between m-0">
                <mat-form-field class="flex-fill mr-2">
                  <mat-label>Tem carta BACEN automática?</mat-label>
                  <mat-select placeholder="Tem carta BACEN automática?" formControlName="automaticBacenCtrl"
                    name="automaticBacen" required [compareWith]="compareObjects">
                    <mat-option *ngFor="let automaticBacenCtrl of opcaoSimNao" [value]="automaticBacenCtrl">
                      {{ automaticBacenCtrl.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="createEditFormGroup.controls['automaticBacenCtrl'].hasError('required')">
                    Informe se a instituição bancária tem carta BACEN automática.
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="flex-fill ml-2">
                  <mat-label>Estágio de Abertura da carta Bacen</mat-label>
                  <mat-select formControlName="defaultBacenStageCtrl" name="defaultBacenStage" required
                    [compareWith]="compareStages"
                    [disabled]="createEditFormGroup.controls['automaticBacenCtrl'].value.id !== 'sim'">
                    <mat-option *ngFor="let stageValue of oppStagesValues" [value]="stageValue">
                      {{ stageValue }}% - {{ oppStage[stageValue] }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="createEditFormGroup.controls['defaultBacenStageCtrl'].hasError('required')">
                    Informe o estágio de Abertura da carta Bacen.</mat-error>
                </mat-form-field>
              </div>

              <div class="d-flex justify-content-between m-0">
                <mat-form-field class="flex-fill mr-2">
                  <mat-label>Chave do modelo Clicksign</mat-label>
                  <input matInput type="text" placeholder="Chave do modelo Clicksign" name="clicksignTemplateKey"
                    formControlName="clicksignTemplateKeyCtrl" />
                  <mat-error *ngIf="createEditFormGroup.controls['clicksignTemplateKeyCtrl'].hasError('required')">
                    Informe a chave do modelo no Clicksign.
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="flex-fill ml-2">
                  <mat-label>Tipo de Documento vinculado</mat-label>
                  <mat-select placeholder="Tipo de Documento vinculado" formControlName="documentTypeBacenCtrl"
                    name="documentTypeBacen" [compareWith]="compareObjects">
                    <mat-option *ngFor="let documentTypeBacenCtrl of associatedDocumentsOptions"
                      [value]="documentTypeBacenCtrl">
                      {{ documentTypeBacenCtrl.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="createEditFormGroup.controls['documentTypeBacenCtrl'].hasError('required')">
                    Informe o tipo de documento vinculado.
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="flex-fill ml-2">
                  <mat-label>Próxima fase após comite</mat-label>

                  <mat-select formControlName="committeeStageCtrl" name="committeeStage" required
                  [compareWith]="compareStages">
                  <mat-option *ngFor="let stageValue of oppStagesValues" [value]="stageValue">
                    {{ stageValue }}% - {{ oppStage[stageValue] }}
                  </mat-option>
                </mat-select>

                  <mat-error *ngIf="createEditFormGroup.controls['committeeStageCtrl'].hasError('required')">
                    Informe o estágio de Abertura da carta Bacen.</mat-error>
                </mat-form-field>

              </div>
              <mat-form-field class="col-12 p-0">
                <mat-label>Ativo</mat-label>
                <mat-select placeholder="Ativo" formControlName="ativoCtrl" name="ativo" required
                  [compareWith]="compareObjects">
                  <mat-option *ngFor="let ativoCtrl of opcaoSimNao" [value]="ativoCtrl">
                    {{ ativoCtrl.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="createEditFormGroup.controls['ativoCtrl'].hasError('required')">
                  Informe se a instituição bancária está ativa.</mat-error>
              </mat-form-field>
              <mat-form-field class="col-12 p-0">
                <mat-label>no Touch</mat-label>
                <mat-select placeholder="noTouch" formControlName="noTouchCtrl" name="noTouch" required
                  [compareWith]="compareObjects">
                  <mat-option *ngFor="let noTouchCtrl of opcaoSimNao" [value]="noTouchCtrl">
                    {{ noTouchCtrl.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="createEditFormGroup.controls['ativoCtrl'].hasError('required')">
                  Informe se a instituição bancária é no touch.</mat-error>
              </mat-form-field>
              <div class="text-right">
                <button mat-stroked-button type="button" color="accent" (click)="onVoltar()">
                  <mat-icon>chevron_left</mat-icon>
                  &nbsp;Voltar</button>&nbsp;
                <button mat-raised-button color="primary" (click)="onSaveForm()" [disabled]="!createEditFormGroup.valid">
                  <mat-icon>check</mat-icon>
                  &nbsp;Salvar
                </button>
              </div>
            </form>
            <div class="text-center mt-4 mb-3">
              <button (click)="openPopup()" mat-raised-button color="primary">Adicionar contato</button>
              <app-contact-form-component *ngIf="showPopup" (closePopup)="closePopup()"></app-contact-form-component>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Documentos Associados">
          <br />
          <h3>Mudança de fase após aprovação dos documentos automáticos</h3>
          <form *ngIf="FiDocuments" class="d-flex col-12">
            <h4 class="col-3 initial-stage-title">Fase para cadastro das IFs</h4>
            <mat-select class="col-6 automaticIntegrationIF-select" style="margin: auto 0px;"
              [(ngModel)]="FiDocuments.stageAproved" name="stageAproved" [compareWith]="compareOppStage">
              <mat-option *ngFor="let stageValue of opportunityStepsOptions"
                [value]="stageValue">{{stageValue.defaultprobability}}% -
                {{stageValue.name}}</mat-option>
            </mat-select>
            <button mat-raised-button color="accent" class="col-3" (click)="onSaveStagesDocument()"
              style=" margin:auto; max-height: 40px;max-width: 150px;" [disabled]="runSaveInstitutionDocuments">
              <span>Salvar</span>
            </button>

          </form>

          <div style="margin: 2px">
            <mat-accordion class="example-headers-align">
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>Novos Documentos</mat-panel-title>
                  <mat-panel-description>
                    <mat-label>Associe novos documentos a instituição financeira</mat-label>
                    <mat-icon>add_circle</mat-icon>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                  <div class="d-flex flex-column">
                    <mat-selection-list #documentosAtivosSelecionados>
                      <mat-list-option *ngFor="let documento of documentosAtivos" [value]="documento">
                        {{ documento.nome }}
                      </mat-list-option>
                    </mat-selection-list>
                  </div>
                  <br />
                  <div class="d-flex justify-content-end">
                    <button mat-raised-button class="btn" color="accent" type="button" (click)="onAddNewDoc()"
                      [disabled]="documentosAtivosSelecionados.selectedOptions.selected.length === 0 && (isEdit && !['master','admin'].includes(loggedUser.accessLevel))">
                      <mat-icon>add</mat-icon>
                      &nbsp;Adicionar
                    </button>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <br />
            <h3>Lista de documentos associados</h3>
            <mat-accordion class="example-headers-align">
              <mat-expansion-panel hideToggle *ngFor="let item of FiDocuments?.documentsData; let idx = index">
                <mat-expansion-panel-header class="expansion-panel-header" [collapsedHeight]="'auto'"
                  [expandedHeight]="'auto'">
                  <mat-panel-title class="expansion-panel-title">{{ item.nome }}</mat-panel-title>
                  <mat-panel-description class="expansion-panel-description">
                    <mat-slide-toggle class="example-margin" (click)="$event.stopPropagation()"
                      (change)="toggleDocumentAutomaticProp(item)" [(ngModel)]="item.automatic">
                      Automático
                    </mat-slide-toggle>
                    <mat-label>&nbsp;</mat-label>
                    <button mat-icon-button type="button" color="warn" matTooltip="Remover documento"
                      (click)="$event.stopPropagation(); onRemoverDocumento(item.instituicaoFinanceiraId, idx)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="descricao">
                  <div class="d-flex justify-content-between flex-row">
                    <h5>Descrição</h5>
                  </div>
                  <div class="d-flex justify-content-between flex-column flex-md-row">
                    <mat-label>{{ item.descricao }}</mat-label>
                  </div>
                </div>
                <br />
                <div class="instrucoes">
                  <h4>Instruções</h4>
                  <div innerHtml="{{ item.instrucoes }}" style="padding-top: 5px"></div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>

        <mat-tab label="Remuneração">
          <div style="margin: 2px">
            <form [formGroup]="createEditFormGroup" (submit)="onSaveForm()">
              <mat-accordion class="example-headers-align">
                <div class="d-flex flex-column col-12">
                  <mat-slide-toggle class="mb-16" color="primary" name="openNoTerm"
                  formControlName="openNoTermCtrl" 
                  (change)="onSaveForm()"
                  >Abrir para cliente sem termo
                </mat-slide-toggle>
                  <mat-slide-toggle  style="margin-top: 10px;" color="primary" name="cobrarRemuneracaoVariavel"
                    formControlName="remuneracaoVariavelCtrl" (change)="onSaveForm()"
              
                  >Cobrar TAC do cliente
                  </mat-slide-toggle>
                </div>
              </mat-accordion>
              <br>
              <app-remuneracao-utm *ngIf="isEdit" [loggedUser]="loggedUser"
                [instituicaoFinanceira]="instituicaoFinanceira"></app-remuneracao-utm>
            </form>
          </div>
        </mat-tab>

        <mat-tab label="Mensagens" *ngIf="isEdit">
          <app-template-messages-institution [isEdit]="isEdit" [institution]="instituicaoFinanceira"
            [loggedUser]="loggedUser"></app-template-messages-institution>
        </mat-tab>

        <mat-tab *ngIf="isEdit" label="Regionalização">
          <div>
            <mat-card-title class="my-4">Regionalização</mat-card-title>

            <div class="ifs-locality-form">
              <h4 style="font-weight: 500">Nova Localidade:</h4>
              <hr class="my-2" />

              <div class="fields">
                <div class="field my-3">
                  <p style="font-size: 0.9em">
                    <strong>OBS: </strong> Se, para uma dada localidade, o CEP for combinado com outro critério, o
                    cliente deve atender tanto ao CEP quanto ao outro critério para que a localidade seja válida.
                  </p>
                </div>

                <div class="field">
                  <mat-form-field color="primary">
                    <input matInput [formControl]="localityFormGroup.controls['ifsLocalityNameCtrl']" placeholder="Nome"
                      required />
                    <mat-error *ngIf="localityFormGroup.controls['ifsLocalityNameCtrl'].hasError('required')">
                      Digite um nome para a localidade
                    </mat-error>
                  </mat-form-field>

                  <div *ngIf="criteria">
                    <mat-form-field color="primary">
                      <mat-label>Critério</mat-label>
                      <mat-select [formControl]="localityFormGroup.controls['ifsLocalityTypeCtrl']">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let c of criteria" [value]="c.id">{{ c.name }}</mat-option>
                      </mat-select>
                      <!-- <mat-error *ngIf="localityFormGroup.controls['ifsLocalityTypeCtrl'].hasError('required')">
                        Informe o critério dessa localidade.
                      </mat-error> -->
                    </mat-form-field>
                  </div>

                  <div *ngIf="localityFormGroup.controls['ifsLocalityTypeCtrl']?.value">
                    <mat-form-field *ngIf="localityFormGroup.controls['ifsLocalityTypeCtrl'].value === 'region'"
                      color="primary">
                      <mat-label>Região</mat-label>
                      <mat-select [formControl]="localityFormGroup.controls['ifsLocalityRegionsCtrl']">
                        <mat-option *ngFor="let r of regions" [value]="r">{{ r?.name }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="localityFormGroup.controls['ifsLocalityRegionsCtrl'].hasError('required')">
                        Informe a região.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field
                      *ngIf="localityFormGroup.controls['ifsLocalityTypeCtrl'].value === 'state' && states"
                      color="primary">
                      <mat-label>Estado</mat-label>
                      <mat-select [formControl]="localityFormGroup.controls['ifsLocalityStateCtrl']">
                        <mat-option *ngFor="let state of states" [value]="state" (click)="getCitiesforUF(state?.id)">{{
                          state?.name }} ({{ state?.abdr }})</mat-option>
                      </mat-select>
                      <mat-error *ngIf="localityFormGroup.controls['ifsLocalityStateCtrl'].hasError('required')">
                        Informe o estado.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field
                      *ngIf="localityFormGroup.controls['ifsLocalityTypeCtrl'].value === 'state' && cities"
                      color="primary">
                      <mat-label>Cidade</mat-label>
                      <mat-select [formControl]="localityFormGroup.controls['ifsLocalityCityCtrl']"
                        [disabled]="!localityFormGroup.controls['ifsLocalityStateCtrl']?.value">
                        <mat-option *ngFor="let c of cities" [value]="c">{{ c.name }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="localityFormGroup.controls['ifsLocalityCityCtrl'].hasError('required')">
                        Informe a cidade.
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="d-flex">
                    <mat-form-field color="primary" style="flex: 2; margin-right: 1em">
                      <input matInput [formControl]="localityFormGroup.controls['ifsLocalityZipCodeCtrl']"
                        placeholder="CEP" #postalCode="matInput" name="postalCode"
                        [textMask]="{ mask: MASKS.cep.textMask, guide: false }" />
                      <mat-error *ngIf="localityFormGroup.controls['ifsLocalityZipCodeCtrl']?.hasError('required')">
                        O CEP é obrigatório.
                      </mat-error>
                      <mat-error *ngIf="
                          !localityFormGroup.controls['ifsLocalityZipCodeCtrl']?.hasError('required') &&
                          localityFormGroup.controls['ifsLocalityZipCodeCtrl']?.hasError('minlength')
                        ">
                        Informe um CEP válido.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field style="flex: 1" color="primary">
                      <input matInput [formControl]="localityFormGroup.controls['ifsLocalityZipCodeQTDNumberCtrl']"
                        placeholder="Quantos dígitos?" type="number" max="8" maxlength="1" />
                      <mat-error
                        *ngIf="localityFormGroup.controls['ifsLocalityZipCodeQTDNumberCtrl'].hasError('required')">
                        Informe a quantidade de dígitos.
                      </mat-error>
                      <mat-error *ngIf="localityFormGroup.controls['ifsLocalityZipCodeQTDNumberCtrl'].errors?.min">
                        O número de dígitos mínimo é
                        {{ localityFormGroup.controls['ifsLocalityZipCodeQTDNumberCtrl'].errors?.min?.min }}.
                      </mat-error>
                      <mat-error *ngIf="localityFormGroup.controls['ifsLocalityZipCodeQTDNumberCtrl'].errors?.max">
                        O número de dígitos máximo é
                        {{ localityFormGroup.controls['ifsLocalityZipCodeQTDNumberCtrl'].errors?.max?.max }}.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="text-right my-3">
                  <button mat-raised-button class="btn" color="accent" type="button"
                    [disabled]="!['master','admin'].includes(loggedUser.accessLevel) || localityFormGroup.invalid"
                    (click)="onAddNewLocality()">
                    <mat-icon>add</mat-icon> &nbsp;Adicionar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr class="my-4" />

          <div class="d-flex justify-content-between my-3">
            <div>
              <mat-card-title>Localidades</mat-card-title>
              <mat-card-subtitle>O cliente deve pertencer a pelo menos uma das localidades abaixo</mat-card-subtitle>
            </div>
            <mat-slide-toggle [(ngModel)]="isRegionalized" color="primary"
              [disabled]="regionalization?.length === 0">Regionalização {{ isRegionalized ? 'ativada' : 'desativada'
              }}</mat-slide-toggle>
          </div>

          <div class="mt-4">
            <mat-accordion class="example-headers-align">
              <mat-expansion-panel *ngFor="let r of regionalization; let i = index">
                <mat-expansion-panel-header>
                  {{ r.name }}
                </mat-expansion-panel-header>

                <div>
                  <div class="d-flex justify-content-end">
                    <button mat-stroked-button color="warn" type="button" (click)="removeLocality(r)"
                      [disabled]="!['master','admin'].includes(loggedUser.accessLevel)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>

                  <mat-form-field *ngIf="r?.region" color="primary">
                    <mat-label>Região</mat-label>
                    <mat-select [(ngModel)]="r.region" name="Região" [compareWith]="compareName">
                      <mat-option *ngFor="let region of regions" [value]="region" [disabled]="isLocalityInvalid()">
                        {{ region.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field *ngIf="r.state" color="primary">
                    <mat-label>Estado</mat-label>

                    <mat-select [(ngModel)]="r.state" name="Estado" [compareWith]="compareName">
                      <mat-option *ngFor="let state of states" (click)="getCitiesListforUF(r?.state?.id, i)"
                        [value]="state" [disabled]="isLocalityInvalid()">{{ state.name }} ({{ state.abdr }})
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field *ngIf="r?.city" color="primary">
                    <mat-label>Cidade</mat-label>

                    <mat-select [(ngModel)]="r.city" name="Cidade" [compareWith]="compareName">
                      <mat-option></mat-option>
                      <mat-option *ngFor="let city of citiesList[i]" [value]="city" [disabled]="isLocalityInvalid()">
                        {{ city.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <div class="d-flex justify-content-between">
                    <mat-form-field *ngIf="r?.zipCode" color="primary">
                      <input matInput [(ngModel)]="r.zipCode" name="zipCode" placeholder="CEP"
                        [textMask]="{ mask: MASKS.cep.textMask, guide: false }" [value]="r?.zipCode"
                        [disabled]="isLocalityInvalid()" />
                      <mat-error *ngIf="r.zipCode === '' || r.zipCode === null"> Informe o CEP. </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-12 col-md-3" *ngIf="r?.zipCodeDigitQuantity" color="primary">
                      <input matInput type="number" max="8" maxlength="1" [(ngModel)]="r.zipCodeDigitQuantity"
                        name="zipCode" placeholder="Quantidade de Digitos" [value]="r?.zipCodeDigitQuantity"
                        [disabled]="isLocalityInvalid()" />
                      <mat-error *ngIf="r.zipCodeDigitQuantity === null">
                        Informe a quantidade de degitos do CEP.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <!--div *ngIf="regionalization && isEdit" class="d-flex justify-content-center" style="margin-top: 40px">
            <button mat-raised-button class="btn" color="accent" type="button" (click)="saveLocality()">
              <mat-icon>save</mat-icon> &nbsp;Salvar Regionalização
            </button>
          </div!-->
        </mat-tab>

        <mat-tab *ngIf="isEdit" label="CNAEs">
          <div>
            <mat-card-title class="my-4">CNAEs referentes à Instituição</mat-card-title>
            <mat-card>
              <strong class="CN" aria-label="Select an option">Selecione:</strong>
              <mat-radio-group class="example-radio-group" aria-label="Select an option"
                *ngFor="let cnaeMatch of selectTypecnaeMatchOptions" [(ngModel)]="selectedOption">
                <mat-radio-button class="example-radio-button" (change)="changeCnae($event)" [value]="cnaeMatch">
                  {{ cnaeMatch.name }}</mat-radio-button>
              </mat-radio-group>
            </mat-card>

            <div class="ifs-locality-form">
              <h4 style="font-weight: 500">Adicionar Novo CNAE</h4>
              <hr class="my-2" />

              <div class="fields">
                <div class="field my-3" style="font-size: 0.8em">
                  <p>
                    <strong>Observação: </strong> Os CNAEs seguem uma hierarquia,
                    cada uma herdando os dígitos da anterior. A ordem dessa hierarquia e a quantidade de dígitos são:
                  </p>
                  <ul>
                    <li>Divisão [12]</li>
                    <li>Grupo [123]</li>
                    <li>Classe [12345]</li>
                    <li>Subclasse [1234567]</li>
                  </ul>
                </div>

                <form [formGroup]="CNAEFormGroup" class="field">
                  <mat-form-field class="col-12 col-lg-3" style="flex: 1" color="primary">
                    <mat-label>Tipo do CNAE</mat-label>
                    <mat-select formControlName="typeCtrl"
                      (ngModelChange)="onChangeCnaeValue($event, CNAEFormGroup.get('cnaeCtrl')?.value)">
                      <mat-option *ngFor="let cnaeType of cnaeTypes" [value]="cnaeType">
                        {{ cnaeType.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-12 col-lg-9" style="flex: 1" color="primary">
                    <mat-label>CNAE</mat-label>
                    <input type="text" placeholder="Digite o CNAE" matInput formControlName="cnaeCtrl" name="cnae"
                      [matAutocomplete]="auto" [maxlength]="CNAEFormGroup.get('typeCtrl')?.value?.digitsNumber"
                      [disabled]="!CNAEFormGroup.get('typeCtrl').value"
                      [value]="formatCnae(CNAEFormGroup.get('cnaeCtrl')?.value)"
                      (ngModelChange)="onChangeCnaeValue(CNAEFormGroup.get('typeCtrl')?.value, $event)" />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let option of optionsCnae" [value]="option"
                        [matTooltip]="option?.id + ' - ' + option?.type?.name + ' - ' + option?.description">
                        {{ option?.id }} - {{ option?.type?.name }} - {{ option?.description }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="CNAEFormGroup.get('cnaeCtrl').hasError('required')" ;>
                      Digite um CNAE.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-12" style="flex: 1" color="primary">
                    <mat-label>Descrição</mat-label>
                    <input type="text" readonly placeholder="Dercrição do CNAE" matInput
                      formControlName="descriptionCtrl" />
                  </mat-form-field>
                </form>

                <div class="text-right my-3">
                  <button mat-raised-button class="btn" color="accent" type="button" (click)="addNewCnae()"
                    [disabled]="!['master','admin'].includes(loggedUser.accessLevel) || isCnaeInvalid(CNAEFormGroup)">
                    <mat-icon>add</mat-icon> &nbsp;Adicionar
                  </button>
                </div>
              </div>
            </div>

            <div class="ifs-locality-form">
              <h4 style="font-weight: 500">Adicionar CNAEs em Lote</h4>
              <hr class="my-2" />
              <div class="field my-3" style="font-size: 0.8em">
                <p>
                  <strong>Descrição: </strong> Essa ação pega uma lista de CNAEs de um arquivo Excel e adiciona de uma
                  vez na tabela de CNAEs da instituição financeira.
                </p>
                <p>
                  <strong>Passo a Passo: </strong>
                </p>
                <ul>
                  <li>Crie uma tabela em Excel com os CNAEs desejados</li>
                  <li>Copie somente os campos de codigo e descrição</li>
                  <li>Cole no campo de testo abaixo</li>
                  <li>Depois é só clicar em adicionar e salvar se tudo estiver correto</li>
                </ul>
                <p>
                  <strong>Observação: </strong> Essa ação não verifica se o CNAE é existente ou não ela só adiciona.
                </p>
              </div>

              <mat-form-field>
                <mat-label>Adcionar CNAEs</mat-label>
                <textarea matInput [(ngModel)]="cnaeLote" placeholder="Adcionar CNAEs" name="cnaeLote"
                  cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
                  cdkAutosizeMaxRows="8"></textarea>
              </mat-form-field>
              <div class="text-right my-3">
                <button mat-raised-button class="btn" color="accent" type="button" (click)="addCNAEsInLote()"
                  [disabled]="!cnaeLote || cnaeLote === ''">
                  <mat-icon>add</mat-icon> &nbsp;Adicionar
                </button>
              </div>
            </div>
          </div>
          <hr class="my-4" />
          <div class="d-flex justify-content-between my-3">
            <div>
              <mat-card-title>CNAEs</mat-card-title>
            </div>
          </div>
          <div class="table-container" *ngIf="dataSourceCnae && dataCnaeTable.length > 0">
            <table mat-table [dataSource]="dataSourceCnae" matSort #matSortCnae="matSort" multiTemplateDataRows>
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Número</th>
                <td mat-cell *matCellDef="let cnae">{{ cnae?.id || '-' }}</td>
              </ng-container>
              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
                <td mat-cell *matCellDef="let cnae">{{ cnae?.type?.name || '-' }}</td>
              </ng-container>
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Descrição</th>
                <td mat-cell *matCellDef="let cnae">{{ cnae?.description || '-' }}</td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ações</th>
                <td mat-cell *matCellDef="let cnae">
                  <div class="d-flex justify-content-end">
                    <button mat-stroked-button color="warn" type="button" (click)="removeCnae(cnae)" [disabled]="">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="columnsCnae"></tr>
              <tr mat-row *matRowDef="let cnae; columns: columnsCnae"></tr>
            </table>
          </div>
          <mat-paginator #paginatorCnae [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>

          <div *ngIf="regionalization && isEdit && ['master','admin'].includes(loggedUser.accessLevel)"
            class="d-flex justify-content-center" style="margin-top: 40px">
            <button mat-raised-button class="btn" color="accent" type="button" (click)="saveCnae()">
              <mat-icon>save</mat-icon> &nbsp;Salvar Alterações
            </button>
          </div>
        </mat-tab>
        <!--- Ações em lote --->
        <mat-tab *ngIf="isEdit" label="Ações em lote">
          <mat-card-title class="my-4">Ações em lote</mat-card-title>
          <app-action-in-lote *ngIf="instituicaoFinanceira" [institution]="instituicaoFinanceira"
            [hasPermission]="hasPermission"></app-action-in-lote>
        </mat-tab>

        <mat-tab label="Imagem" *ngIf="isEdit && ['master','admin'].includes(loggedUser?.accessLevel)">
          <div style="margin: 2px">
            <h4>Logotipo da instituicao financeira</h4>
            <form [formGroup]="fileInfoFormGroup" (submit)="onSalvarArquivo()">
              <file-upload></file-upload>
              <div style="padding-top: 40px" class="d-flex justify-content-end flex-column flex-md-row">
                <button mat-raised-button color="accent" class="btn" type="submit" style="margin: 5px"
                  [disabled]="fileInfoFormGroup.invalid">
                  <mat-icon>save</mat-icon>
                  &nbsp;Salvar Imagem
                </button>
              </div>
            </form>
          </div>
        </mat-tab>

        <mat-tab label="API">
          <mat-card>
            <mat-card-content>
              <h4>API disponível: {{apiActiveConfig ? 'Sim' : 'Não'}}</h4>
            </mat-card-content>
          </mat-card>
          <mat-card class="automatic-api-container">
            <mat-card-content>
              <h4>Rodar API automaticamente?</h4>
              <selection class="example-section" *ngIf="integrate">
                <mat-slide-toggle class="example-margin" color="primary" [(ngModel)]="integrate.isActive"
                  name="integrateIsActive" (change)="toggleAutomaticApi()"
                  [disabled]="!apiActiveConfig || !['master','admin'].includes(loggedUser.accessLevel)">
                  Automático
                </mat-slide-toggle>
              </selection>
              <div class="conditions-api-container">
                <h4 class="conditions-title">Condições</h4>
                <div class="toggle-wrapper" *ngIf="integrate">
                  <mat-slide-toggle [(ngModel)]="integrate.documentsAssociatedApproved" (change)="onToggleApi()"
                    color="primary">Documentos associados aprovados</mat-slide-toggle>
                  <mat-slide-toggle [(ngModel)]="integrate.signed_SCR" (change)="onToggleApi()" color="primary">Carta
                    SCR
                    assinada</mat-slide-toggle>
                </div>
                <h4 class="initial-stage-title">Estágio inicial para rodar a API:</h4>
                <mat-select class="automaticIntegrationIF-select" *ngIf="integrate" [(ngModel)]="integrate.initialStep"
                  (ngModelChange)="onToggleApi()" name="integrateInitialStage" [compareWith]="compareStages">
                  <mat-option *ngFor="let stageValue of opportunityStepsOptions"
                    [value]="stageValue.defaultprobability">{{stageValue.defaultprobability}}</mat-option>
                </mat-select>
              </div>
            </mat-card-content>
          </mat-card>

        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
