import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OpenFinanceDialogComponent } from '../components/open-finance-dialog/open-finance-dialog.component';

@Component({
  selector: 'app-open-finance-info-page',
  templateUrl: './open-finance-info-page.component.html',
  styleUrls: ['./open-finance-info-page.component.scss']
})
export class OpenFinanceInfoPageComponent  {



  constructor(private dialog: MatDialog) { }

  isOpen = false;


  closeModal() {
    this.isOpen = false;
  }


  openStepModal() {
    this.dialog.open(OpenFinanceDialogComponent, {
      maxWidth: '600px'
      
    });
  }

}
