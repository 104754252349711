import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { firestore } from 'firebase';
import { Agent } from 'functions/src/models/Agent';
import { Customer } from 'functions/src/models/Customer';
import { User } from 'functions/src/models/User';
import { Subscription } from 'rxjs';
import { AgentLeadsService } from 'src/app/agents/services/agent-leads.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { AgentsAdminService } from '../../services/agents-admin.service';

@Component({
  selector: 'app-agent-view',
  templateUrl: './agent-view.component.html',
  styleUrls: ['./agent-view.component.scss'],
})
export class AgentViewComponent implements OnInit, OnDestroy {
  agentSubscription: Subscription;
  agent: Agent;
  agentCode: string;
  agentLink = '';
  isLoading = true;
  hasError = false;

  // Admin control
  loggedAdmin: User;
  loggedAdminSubscription: Subscription;

  // Leads
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  leads: Customer[] = [];
  isLoadingResults = true;
  displayedColumns: string[] = ['name', 'email', 'createdAt', 'status', 'actions'];
  dataSource: MatTableDataSource<Customer>;

  // filter
  selectedFilter = 'name';
  currentFilterValue = '';
  filterValue = '';

  // Pagination
  length = 0;
  pageSize = 100;
  pageSizeOptions: number[] = [10, 25, 40, 100];
  currentPage = 0;

  // Sorting
  sortField = 'createdAt';
  sortDirection = 'desc';

  constructor(
    private route: ActivatedRoute,
    private admin: AgentsAdminService,
    private dialog: MatDialog,
    private authService: AuthService,
    private agentLeadsService: AgentLeadsService
  ) {}

  ngOnInit(): void {
    this.agentCode = this.route.snapshot.paramMap.get('id');

    this.agentSubscription = this.admin.getAgentData(this.agentCode).subscribe((agent) => {
      if (agent) {
        this.agent = agent;
        this.agentLink = `${environment.baseURL}/cadastrar/${this.agentCode}`;
        this.isLoading = false;
        this.applyActionToTable();
        this.hasError = false;
      } else {
        this.hasError = true;
      }
      this.isLoading = false;
    });

    this.loggedAdminSubscription = this.authService.user.subscribe((user) => {
      this.loggedAdmin = user;
    });
  }

  ngOnDestroy(): void {
    if (this.loggedAdminSubscription) {
      this.loggedAdminSubscription.unsubscribe();
    }
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
  }

  enableAgent(uid: string, email: string): void {
    this.isLoading = true;

    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '700px',
        data: {
          alertTitle: 'Habilitar assessor de crédito',
          alertDescription: `Deseja realmente <strong>habilitar</strong> o cadastro do assessor ${email}?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // Faz a ação somente se respondeu SIM.
          this.admin
            .enableAgent(uid, email, this.loggedAdmin.email)
            .then(() => {
              console.log(`Agent ${uid} enabled`);
              this.isLoading = false;
            })
            .catch((err) => {
              console.error(err);
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      });
  }

  disableAgent(uid: string, email: string): void {
    this.isLoading = true;

    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '700px',
        data: {
          alertTitle: 'Suspender assessor de crédito',
          alertDescription: `Deseja realmente <strong>suspender</strong> o cadastro do assessor ${email}?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // Faz a ação somente se respondeu SIM.
          this.admin
            .disableAgent(uid, email, this.loggedAdmin.email)
            .then(() => {
              console.log(`Agent ${uid} disabled`);
              this.isLoading = false;
            })
            .catch((err) => {
              console.error(err);
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      });
  }

  approveAgent(uid: string, email: string): void {
    this.isLoading = true;

    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '700px',
        data: {
          alertTitle: 'Aprovar assessor de crédito',
          alertDescription: `Deseja realmente <strong>aprovar</strong> o cadastro do assessor ${email}?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // Faz a ação somente se respondeu SIM.
          this.admin
            .approveAgent(uid, email, this.loggedAdmin.email)
            .then(() => {
              console.log(`Agent ${uid} approved`);
              this.isLoading = false;
            })
            .catch((err) => {
              console.error(err);
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      });
  }

  rejectAgent(uid: string, email: string): void {
    this.isLoading = true;

    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '700px',
        data: {
          alertTitle: 'Recusar assessor de crédito',
          alertDescription: `Deseja realmente <strong>recusar</strong> o cadastro do assessor ${email}?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // Faz a ação somente se respondeu SIM.
          this.admin
            .rejectAgent(uid, email, this.loggedAdmin.email)
            .then(() => {
              console.log(`Agent ${uid} rejected`);
              this.isLoading = false;
            })
            .catch((err) => {
              console.error(err);
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      });
  }

  applyActionToTable(): void {
    this.currentFilterValue = this.filterValue;
    this.isLoadingResults = true;

    this.agentLeadsService
      .searchLeads({
        agent: this.agentCode,
        filterField: this.selectedFilter,
        filterValue: this.currentFilterValue,
        orderField: this.sortField,
        orderDirection: this.sortDirection,
        pageSize: this.pageSize,
        page: this.currentPage,
      })
      .then((response) => {
        this.leads = response;
        this.length = response.length;

        this.dataSource = new MatTableDataSource(this.leads);

        this.isLoadingResults = false;
      })
      .catch((err) => {
        console.error('Error getting leads of agent.', err);
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '700px',
            data: {
              alertTitle: 'Erro ao buscar leads',
              alertDescription: `Ocorreu um erro ao buscar os leads do assessor de crédito. Por favor, tente novamente mais tarde.`,
              isOnlyConfirm: true,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.isLoadingResults = false;
          });
      });
  }

  setPageSizeOptions(setPageSizeOptionsInput: string): void {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
    }
  }

  paginatorEvent(event: PageEvent): void {
    if (event.pageSize !== this.pageSize) {
      this.pageSize = event.pageSize;
      this.currentPage = 0;
    } else {
      this.currentPage = event.pageIndex;
    }
    this.applyActionToTable();
  }

  sortData(event: Sort): void {
    this.sortDirection = event.direction || 'asc';
    this.sortField = event.active;
    this.currentPage = 0;
    this.applyActionToTable();
  }
}
