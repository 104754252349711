import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { QuillEditorComponent } from 'ngx-quill';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Customer } from '../../../../../functions/src/models/Customer';
import { User } from '../../../../../functions/src/models/User';
import { UserNotificationEntity } from '../../../../../functions/src/models/UserNotificationEntity';
import { timestampForDate } from '../../../../../functions/src/utils/dates';
import { parseCnpj } from '../../../../../functions/src/utils/formatters';
import { CadastrosGeraisService } from '../../../admin/cadastros-gerais/cadastros-gerais.service';
import { UserManagementService } from '../../../admin/services/user-management.service';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { ForwardNotesComponent } from '../../../components/forward-notes/forward-notes.component';
import { InfoCompanyDialogComponent } from '../../../components/info-company-dialog/info-company-dialog.component';
import { UserDocumentsComponent } from '../../../components/user-documents/user-documents.component';
import { AuthService } from '../../../core/auth/auth.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { SelectWhatsappTemplateComponent } from '../../components/select-whatsapp-template/select-whatsapp-template.component';
import { ChatAttributedEntity } from '../../models/ChatAttributedEntity';
import { ChatContactEntity } from '../../models/ChatContactEntity';
import { ContactReceived, ContactsCustomer } from '../../models/contact';
import { ChatAttributedService } from '../../services/chat-attributed.service';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {
  @ViewChild('messagesContent') messagesContent!: ElementRef;

  contacts: ChatContactEntity[];
  customer: Customer;
  contactSelected: ChatContactEntity;

  customerDetailsPath: any;
  messageSelected: ContactReceived;

  company: ContactsCustomer;
  selectedValue: string;
  users: User[] = [];
  selectedUser: User;
  selectedContact: any;
  loggedUser: User;
  loggedUserSubscription: Subscription;
  textMessageWhatsApp = '';
  textMessageChatSce = '';
  textMessageNotes = '';
  phoneValue: string;
  message = '';
  selectedFile: File | null = null;
  fileData: string | null = null;
  fileType: string | null = null;
  docExtension: string;
  showTemplateOptions = false;
  templateMessageSubscription: Subscription;
  messageTemplates: any[];

  menuOpenStates: { [key: string]: boolean } = {};
  menuOpenStatesImage: { [key: string]: boolean } = {};

  isDisableTabChatSce = true;
  isMenuContactOpen = false;
  coords: { x: number; y: number } = { x: 0, y: 0 };
  showPopupContact = false;

  hasTextBeenLoaded = true;

  textMessageChtSce: string; // Propriedade que armazena o texto digitado
  messagesFormat = [
    { name: 'textMessageChatSce', formattedText: '', showTemplateOptions: false },
    { name: 'textMessageWhatsApp', formattedText: '', showTemplateOptions: false },
    { name: 'textMessageNotes', formattedText: '', showTemplateOptions: false }
  ];

  modules = {};

  private toolbar = [
    ['bold', 'italic', 'strike'],

  ];

  @ViewChild('fileInput') fileInputRef!: ElementRef;
  @ViewChild('fileInput2') fileInput2Ref!: ElementRef;
  @ViewChild('textareaNotes') textareaNotes: ElementRef;
  @ViewChild('textareaChatSCE') textareaChatSCE: ElementRef;
  @ViewChild('audioElement') audioElement: ElementRef;
  @ViewChild('editor', { static: false }) editor: QuillEditorComponent;

  sendingAttachment: boolean;

  audioPlaying = false;
  currentTime = 0;
  duration = 0;
  showAudioPlayer: boolean;
  activeChatbot= false

  whatsAppDate: string;
  whatsAppValue: boolean;
  cnpj: any;
  paramsRouteSubscription: Subscription;
  getContactsByCustomerSubscription: Subscription;
  getUserWithAdminRoleSubscription: Subscription;
  sendMessageLoading: boolean;
  adminAttributed: string;

  formattedText: string;
  elementRef: ElementRef;
  flexDirection = 'column-reverse';

  lastInteraction: Date;
  isBlocked = false;

  isModelSent = false;

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private router: Router,
    private userService: UserManagementService,
    private cadastrosGeraisService: CadastrosGeraisService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private ums: UserManagementService,
    private chatAtributtedService: ChatAttributedService
  ) {
    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser) => {
      this.loggedUser = {
        uid: loggedUser.uid,
        name: loggedUser.name || 'Unknown',
        email: loggedUser.email,
        accessLevel: loggedUser.accessLevel
      };
    });
    // todo promise
    this.chatService.getPlugConfig().subscribe((data: any) => {
      this.phoneValue = data?.phone;
    });

    this.modules = {
      toolbar: this.toolbar,
    };
  }

  ngOnInit(): void {
    if (this.paramsRouteSubscription) {
      this.paramsRouteSubscription.unsubscribe();
    }
    this.paramsRouteSubscription = this.activatedRoute.params.subscribe(params => {
      this.cnpj = parseCnpj(params['id']);
      this.initializeComponent();
    });
    this.getMessageFromCustomerPendency();

    if (this.customer?.attributedTo){
      this.userService.getUserByUid(this.customer.attributedTo).subscribe((data: User) => {
        this.adminAttributed = data.displayName ? data.displayName : data.name;
      })
    }

  }

  ngOnDestroy(): void {
    if (this.templateMessageSubscription) {
      this.templateMessageSubscription.unsubscribe();
    }
    if (this.paramsRouteSubscription) {
      this.paramsRouteSubscription.unsubscribe();
    }
    if (this.getContactsByCustomerSubscription) {
      this.getContactsByCustomerSubscription.unsubscribe();
    }
  }

  onLoadMoreMessages(scrollPosition: number) {
    const messagesContent = document.getElementById('messagesContent');
    if (messagesContent) {
      messagesContent.scrollTop = scrollPosition;
    }
  }

  initializeComponent(): void {
    this.chatService.flexDirection$.subscribe(direction => {
      this.flexDirection = direction;
    });
    if (this.getContactsByCustomerSubscription) {
      this.getContactsByCustomerSubscription.unsubscribe();
    }
    this.getContactsByCustomerSubscription = this.chatService.obseveContactsByCustomer(this.cnpj).pipe(first()).subscribe({
      next: (contacts) => {
        this.contacts = contacts.sort((a, b) => !a.isContactMain && b.isContactMain ? 1 : -1);
        this.contactSelected = this.contacts?.find(c => c?.isContactMain) || this.contacts[0];
        this.selectedContact = this.contacts?.find(c => c?.isContactMain) || this.contacts[0];
      },
    });

    this.customerService.getCustomerByCNPJ(this.cnpj)
      .then(res => {
        this.customer = res;
        this.lastInteraction = res.lastContact ? res.lastContact.toDate() : null;
        console.log('this.lastInteraction',this.lastInteraction)
        this.checkLastInteraction();
      });

    this.getTemplateMessages();
    this.getUsersColaborators()
  }

  private getUsersColaborators(): void {
    this.getUserWithAdminRoleSubscription = this.chatService.getUsersWithAdminRole().subscribe(users => {
      this.users = users
    });
  }
  toggleAudio(): void {
    const audio = this.audioElement.nativeElement;

    if (this.audioPlaying) {
      audio.pause();
    } else {
      audio.play();
    }

    this.audioPlaying = !this.audioPlaying;
  }

  checkLastInteraction() {
    if (this.lastInteraction) {
      const now = new Date();
      const diffInHours = (now.getTime() - this.lastInteraction.getTime()) / (1000 * 3600); // Diferença em horas
      
      this.isBlocked = diffInHours > 24; // Libera o botão após 24h
      if (diffInHours > 24) {
        this.isModelSent = false; // Libera o botão e muda o texto
      }
    }
  }


  checkingAdmin(){
    if(this.customer && this.customer.attributedTo){
      if(this.customer.attributedTo === this.loggedUser.uid || this.loggedUser.accessLevel === 'master'){
        return true
      }else{
        return false
      }
    }
    if(this.customer && !this.customer.attributedTo){
      return true
    }
  }

  updateTime(event: Event): void {
    const audio = event.target as HTMLAudioElement;
    this.currentTime = audio.currentTime;
    this.duration = audio.duration;
  }

  formatTime(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${ minutes }:${ seconds < 10 ? '0' : '' }${ seconds }`;
  }

  seekTimeAudio(event: MouseEvent): void {
    const audio = this.audioElement.nativeElement;
    const progressBar = event.currentTarget as HTMLDivElement;
    const rect = progressBar.getBoundingClientRect();
    const totalWidth = rect.width;
    const clickX = event.clientX - rect.left;
    const seekTime = (clickX / totalWidth) * this.duration;

    audio.currentTime = seekTime;
  }

  openFileInput(value: string): void {
    if (value === 'whatsapp') {
      this.fileInput2Ref.nativeElement.click();
      return;
    }
    this.fileInputRef.nativeElement.click();
  }


  toggleMenu(firestoreId: string): void {
    this.menuOpenStates[firestoreId] = !this.menuOpenStates[firestoreId];
  }

  toggleMenuImage(firestoreId: string): void {
    this.menuOpenStatesImage[firestoreId] = !this.menuOpenStatesImage[firestoreId];
  }

  openAnexarDialog(): void {
    const dialogRef = this.dialog.open(UserDocumentsComponent, {
      width: '600px', // Defina a largura do diálogo conforme sua necessidade
      data: { uid: this.company.uid },
    });
  }

  onFileSelected(event: Event, messageType: string): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFile = fileInput.files[0];
      const docName = fileInput.files[0].name || "document";

      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.fileData = e.target.result;
        if (this.fileType === 'image/jpeg' || this.fileType === 'image/jpng' || this.fileType === 'image/png') {
          console.log('onFileSelected', messageType);
          await this.sendImageMessage(messageType, this.fileData);
        }
        if (this.fileType === 'application/pdf' || this.fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || this.fileType === 'application/msword' || this.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          if (this.fileType === 'application/pdf') {
            this.docExtension = 'pdf';
          }
          if (this.fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            this.docExtension = 'docx';
          }
          if (this.fileType === 'application/msword') {
            this.docExtension = 'doc';
          }
          if (this.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            this.docExtension = 'xlsx';
          }
          await this.sendDocumentMessage(messageType, this.fileData, this.docExtension, docName.replace(`.${ this.docExtension }`, ''));
        }
      };
      reader.readAsDataURL(this.selectedFile);

      this.fileType = this.selectedFile.type;
    }
  }


  handleOptionClick(option: any, typeMessage: string): void {
    const data = {
      customerId: this.selectedContact.uid,
      contactId: this.selectedContact.whatsapp,
      sender: {
        uid: this.selectedContact.uid,
        name: this.getContactName(this.selectedContact),
        phone: this.selectedContact.whatsapp,
      },
      recipient: {
        uid: this.loggedUser.uid,
        name: this.loggedUser.name,
        phone: this.phoneValue,
      },
      sendingType: 'API Client',
      messageType: typeMessage,
      sendDate: new Date(),
      text: 'Você selecionou a opção de ' + option.title + '. Em breve o time responsável irá entrar em contato para auxiliá-lo!',
    };
    this.chatService.sendTextMessage(data, this.customer);
  }

  onTabSelectionChange(event: MatTabChangeEvent): void {
    const selectedTabIndex = event.index;
    if (this.contacts && this.contacts[selectedTabIndex]) {
      this.selectedContact = this.contacts[selectedTabIndex];
    }
  }

  onUserSelectionChange(user: User): Promise<void> {
    const promises: Promise<void>[] = [];
    const atributedBy = {
      uid: this.loggedUser.uid,
      name: this.loggedUser.name,
      email: this.loggedUser.email,
    }
    const options = { atribuitedTo: null, desatribuitedTo: null };
    if (user) {
      // atributed
      const attributedToNotification = (user?.uid && user.uid !== this.loggedUser.uid)
      promises.push(this.chatService.customerAttributedTo(this.customer.uid, user.uid, user?.displayName || user?.name, attributedToNotification));
      options.atribuitedTo = {
        uid: user.uid,
        name: user?.displayName || user?.name,
        email: user.email,
      }
    } else {
      // desatributed
      promises.push(this.chatService.customerDesattibuted(this.customer.uid));
      options.desatribuitedTo = {
        uid: this.customer.attributedTo,
        name: this.customer.attributedToName,
      }
    }

    const attributedData = ChatAttributedEntity.generateChatAttributed(atributedBy, options);
    promises.push(this.chatAtributtedService.addAttributedTo(this.customer.uid, attributedData)
      .then(() => {
        return;
      }));

    return Promise.all(promises)
      .then(() => console.log('Atribuição salva com sucesso!'))
      .catch(error => console.error('Erro ao salvar atribuição:', error));

  }

  getContactName(contact: any): string {
    return contact.name ? contact.name : contact.contactName;
  }

  getMessageCardStyle(message: any): "message-card-whatsapp-client" | "message-card-whatsapp" | "message-card-internal-client" | "message-card-internal" | "message-card-note" {
    if (message.messageType === 'WHATSAPP' && message.sender.type !== 'admin') {
      return 'message-card-whatsapp-client';
    }
    if (message.messageType === 'WHATSAPP' && message.sender.type === 'admin') {
      return 'message-card-whatsapp';
    }
    if (message.messageType === 'CHAT-SCE' && message.sender.type !== 'admin') {
      return 'message-card-internal-client';
    }
    if (message.messageType === 'CHAT-SCE' && message.sender.type === 'admin') {
      return 'message-card-internal';
    }
    if (message.messageType === 'NOTES' && message.sender.type === 'admin') {
      return 'message-card-note';
    }
  }

  getAttachmentCardStyle(message: any): "document-wrapper-whatsapp-client" | "document-wrapper-whatsapp-admin " | "document-wrapper-sce-client " | "document-wrapper-sce-admin " {
    if (message.messageType === 'WHATSAPP' && message.sender.type !== 'admin') {
      return 'document-wrapper-whatsapp-client';
    }
    if (message.messageType === 'WHATSAPP' && message.sender.type === 'admin') {
      return 'document-wrapper-whatsapp-admin ';
    }
    if (message.messageType === 'CHAT-SCE' && message.sender.type !== 'admin') {
      return 'document-wrapper-sce-client ';
    }
    if (message.messageType === 'CHAT-SCE' && message.sender.type === 'admin') {
      return 'document-wrapper-sce-admin ';
    }
  }

  async sendTextMessage(messageType: string): Promise<void> {
    this.sendMessageLoading = true;

    // Verificar se o envio de mensagens WHATSAPP está bloqueado
  if (messageType === 'WHATSAPP' && this.selectedContact.isMessageBlocked) {
    console.log('Envio via WhatsApp bloqueado. A última interação foi há mais de 24 horas.');
    this.sendMessageLoading = false;
    return; 
  }

    if (messageType === 'WHATSAPP') {
      this.message = this.textMessageWhatsApp;
    } else if (messageType === 'Chat-SCE') {
      this.message = this.textMessageChatSce;
    } else if (messageType === 'NOTES') {
      this.message = this.textMessageNotes;
    }

    // Substitui quebras de linha por <br>
    let textMessage = this.message.replace(/\n/g, '<br>');

    if (messageType !== 'WHATSAPP') {
      textMessage = this.formatMessage(textMessage);
    }
    // else {
    //   textMessage = textMessage
    //     .replace(/<\/?span[^>]*>/g, '') // Remove tags <span>
    //     .replace(/<\/?br[^>]*>/g, '') // Remove tags <br>
    //     .replace(/ style="[^"]*"/g, '')
    //     .replace(/&nbsp;/g, '') // Remove &nbsp;
    // }

    const data = {
      customerId: this.customer.uid,
      contactId: this.selectedContact.whatsapp,
      sender: {
        uid: this.loggedUser.uid,
        name: this.loggedUser.name,
        phone: this.phoneValue,
        type: 'admin'
      },
      recipient: {
        uid: this.selectedContact.uid,
        name: this.getContactName(this.selectedContact),
        phone: this.selectedContact.whatsapp,
        type: 'customer'
      },
      sendingType: 'API Client',
      messageType: messageType,

      text: textMessage,
      readDate: null,
    };

    this.chatService.sendTextMessage(data, this.customer)
      .finally(() => {
        this.clearTexts()
        this.sendMessageLoading = false;
      })
    messageType !== 'NOTES' ? Promise.all(this.contacts.map(async contact => await this.chatService.updateCdcMessageReadDate(contact.whatsapp))) : Promise.resolve(),
      this.chatService.updateAtributedNotificationOfCustomer(this.customer.uid, false)

    this.hasTextBeenLoaded = true;

    if (!this.customer?.attributedTo && this.loggedUser.accessLevel !== 'master') {
      this.onUserSelectionChange(this.loggedUser)
        .then(() => {
          console.log(`Atribuição automática para o colaborador ${ this.loggedUser.name }!`);
          this.selectedUser = this.loggedUser;
        })
        .catch(error => console.error('Erro ao salvar atribuição:', error));
    }

    const sendNotificationPromise = UserNotificationEntity.needNotificationCustomer(data) ? this.chatService.notifyNewMessageToCustomer(this.customer, data.sender?.name, data.text) : null;
    await sendNotificationPromise;
  }

  async clearTexts(): Promise<void> {
    this.textMessageWhatsApp = '';
    this.textMessageChatSce = '';
    this.textMessageNotes = '';
    this.message = '';

    this.textareaNotes.nativeElement.style.height = 'auto';
    this.textareaChatSCE.nativeElement.style.height = 'auto';
    // this.editor.quillEditor.scrollingContainer.style.height = 'auto';
  }

  userDetail(): void {
    const url = '/admin/clientes/detalhes/' + this.customer.uid;
    window.open(url, '_blank');
  }

  async getInfoCompany(contact: ContactsCustomer): Promise<void> {

    const customer: Customer = await new Promise((resolve, reject) => {
      this.chatService.getCustomerByUid(contact.uid)
        .subscribe(
          (result: Customer) => resolve(result),
          error => reject(error)
        );
    });

    if (customer) {
      this.dialog.open(InfoCompanyDialogComponent, {
        minWidth: '650px',
        maxWidth: '800px',
        data: {
          alertTitle: "Dados do pedido",
          dataSource: customer,
          guarantees: customer.guarantees
        }
      })
    }
  }

  sendImageMessage(messageType: string, base64: string): void {
    if (messageType === 'WHATSAPP') {
      this.message = this.textMessageWhatsApp;
    } else if (messageType === 'Chat-SCE') {
      this.message = this.textMessageChatSce;
    } else if (messageType === 'NOTES') {
      this.message = this.textMessageNotes;
    }

    this.sendingAttachment = true;

    const data = {
      readDate: messageType !== 'NOTES' ? new Date() : null,
      customerId: this.selectedContact.uid,
      contactId: this.selectedContact.whatsapp,
      sender: {
        uid: this.loggedUser.uid,
        name: this.loggedUser.name,
        phone: this.phoneValue,
        type: 'admin',
      },
      recipient: {
        uid: this.selectedContact.uid,
        name: this.getContactName(this.selectedContact),
        phone: this.selectedContact.whatsapp,
        type: 'customer'
      },
      sendingType: 'API Client',
      messageType: messageType,
      imageCaption: ' ',
      image: base64,
    };
    this.chatService.sendImageMessage(data, this.customer)
      .catch((error) => {
        console.error("Erro ao enviar mensagem de imagem:", error);
      })
      .finally(() => {
        this.sendingAttachment = false;
      });
  }

  sendDocumentMessage(messageType: string, base64: string, docExtension: string, docName: string): void {
    if (messageType === 'WHATSAPP') {
      this.message = this.textMessageWhatsApp;
    } else if (messageType === 'Chat-SCE') {
      this.message = this.textMessageChatSce;
    } else if (messageType === 'NOTES') {
      this.message = this.textMessageNotes;
    }
    this.sendingAttachment = true;

    const data = {
      readDate: messageType !== 'NOTES' ? new Date() : null,
      customerId: this.selectedContact.uid,
      contactId: this.selectedContact.whatsapp,
      sender: {
        uid: this.loggedUser.uid,
        name: this.loggedUser.name,
        phone: this.phoneValue,
        type: 'admin',
      },
      recipient: {
        uid: this.selectedContact.uid,
        name: this.getContactName(this.selectedContact),
        phone: this.selectedContact.whatsapp,
        type: 'customer'
      },
      sendingType: 'API Client',
      messageType: messageType,
      documentFileName: docName,
      document: base64,
      docExtension: docExtension
    };
    this.chatService.sendDocumentMessage(data, this.customer)
      .catch((error) => {
        console.error("Erro ao enviar mensagem de imagem:", error);
      })
      .finally(() => {
        this.sendingAttachment = false;
      });
  }


  onToggleChange() {
    this.ums.updateCustomer(this.customer);
  }


  getTemplateMessages(): void {
    if (this.templateMessageSubscription) {
      this.templateMessageSubscription.unsubscribe();
    }

    this.templateMessageSubscription = this.cadastrosGeraisService.getTemplateMessageAtivas().subscribe((templates) => {
      this.messageTemplates = templates;
    });
  }




  formatText(): void {

    // Remover tags HTML
    let plainText = this.removeHTMLTags(this.textMessageWhatsApp);

    // Substituir negrito (<strong>, <b>) por Markdown (*)
    plainText = plainText.replace(/<(strong|b)>(.*?)<\/(strong|b)>/g, '*$2*');

    // Substituir itálico (<em>, <i>) por Markdown (_)
    plainText = plainText.replace(/<(em|i)>(.*?)<\/(em|i)>/g, '_$2_');

    this.formattedText = plainText;

  }

  removeHTMLTags(html: string): string {
    // Remover todas as tags HTML
    return html.replace(/<[^>]*>/g, '');
  }

  checkForSlash() {
    if (this.textMessageChatSce.includes('/')) {
      this.showTemplateOptions = true;
    } else if (this.textMessageWhatsApp.includes('/')) {
      this.showTemplateOptions = true;
    } else if (this.textMessageNotes.includes('/')) {
      this.showTemplateOptions = true;
    } else {
      this.showTemplateOptions = false;
    }
  }


  selectTemplateNotes(template: any): void {
    this.textMessageNotes = template.description;
    this.showTemplateOptions = false;
    this.setHeidhtTestEditor(this.textareaNotes.nativeElement);

  }
  selectTemplateChatSce(template: any): void {
    this.textMessageChatSce = template.description;
    this.showTemplateOptions = false;
    this.setHeidhtTestEditor(this.textareaChatSCE.nativeElement, this.textareaChatSCE.nativeElement.scrollingContainer);
  }

  //quando voltar o editor volta o metodo
  // selectTemplateWhatsApp(template: any): void {
  //   const textoFormatado = this.formatTextForQuill(template.description);
  //   this.editor.quillEditor.root.innerHTML = textoFormatado;
  //   this.showTemplateOptions = false;
  //   this.setHeidhtTestEditor(this.editor.quillEditor.scrollingContainer); // Ajusta a altura do editor após alterar o conteúdo
  // }

  selectTemplateWhatsApp(template: any) {
    this.textMessageWhatsApp = template.description;
    this.showTemplateOptions = false;
  }

  formatTextForQuill(texto: string): string {
    return texto
      .replace(/\n/g, "<br>")
      .split('<br>')
      .map(i => i === '' ? '<br>' : '<p>' + i + '</p>')
      .join('');
  }

  onInput(event: any): void {
    const scrollingContainer = event.target.value === '' ? 40 : event.target.scrollHeight
    this.setHeidhtTestEditor(event.target, scrollingContainer);
  }

  setHeidhtTestEditor(textarea, scrollHeight?: number): void {
    textarea.style.height = 'auto';
    textarea.style.height = `${ scrollHeight || textarea.scrollHeight }px`;
  }

  async onKeyDown(event: any, messageType: string): Promise<void> {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      await this.sendTextMessage(messageType);
      const textarea = event.target;
      textarea.setSelectionRange(0, 0);
      textarea.style.height = 'auto';
    }
  }

  formatDate(date: any): string {
    if (date) {
      return new Date(timestampForDate(date)).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    } else {
      return '';
    }
  }

  formatWhatsapp(whatsapp: string): string {
    const number = whatsapp.replace(/\D/g, '');
    const formattedNumber = number.startsWith('55') ? number.slice(2) : number;
    const digits = formattedNumber.length === 11 ? 5 : 4;
    return formattedNumber.replace(new RegExp(`^(\\d{2})(\\d{${ digits }})(\\d{4})$`), '($1) $2-$3');
  }


  async getWhatsAppContact(contact: any): Promise<void> {
    if (contact) {
      if (contact?.whatsAppContact) {
        this.whatsAppDate = this.formatDate(contact.whatsAppContact.date);
        this.whatsAppValue = contact.whatsAppContact.value;
      }
    }
  }

  openMenuContactSelected(event: MouseEvent, message: ContactReceived): void {
    this.closeMenuContactSelected();
    event.preventDefault();
    const originalX = (event.currentTarget as HTMLElement).getBoundingClientRect().left;
    this.coords = { x: event.clientX - (originalX > 1300 ? 300 : 0), y: event.clientY };
    this.isMenuContactOpen = true;
    this.messageSelected = message;
  }

  closeMenuContactSelected(): void {
    this.isMenuContactOpen = false;
    this.messageSelected = null;
    this.showPopupContact = false;
  }

  blockRightClickMouse(event: MouseEvent): void {
    event.preventDefault();
    this.closeMenuContactSelected();
  }

  async addContact(message: any): Promise<void> {
    const number = message.contactReceived?.phone.replace(/\D/g, '');
    const formattedNumber = number.startsWith('55') ? number.slice(2) : number;
    this.closeMenuContactSelected();
    let registerInserted = false;

    await this.chatService.getContactById(formattedNumber).subscribe(
      (contact: any) => {
        if (contact && Object.keys(contact).length > 0 && !registerInserted) {
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Contato existente',
              alertDescription: `Contato ${ message.contactReceived?.displayName } já adicionado para este cliente.`,
              isOnlyConfirm: true
            },
          });
          this.showPopupContact = false;
        } else {

          this.messageSelected = {
            displayName: message.contactReceived?.displayName,
            phone: formattedNumber,
            photo: message.contactReceived?.photo,
            description: `Contato do(a) ${ this.company.companyName }`,
            cnpj: this.company.cnpj,
          };
          registerInserted = true;
          this.showPopupContact = true;
        }
      },
      (error) => {
        console.error('Erro ao obter contato:', error);
      }
    );
  }

  closePopupContact(): void {
    this.showPopupContact = false;
  }

  forwardNote(message): void {
    this.dialog.open(ForwardNotesComponent, {
      width: '70%',
      data: {
        customer: this.company.uid,
        customerData: this.company,
        noteText: message,
        noteFrom: 'USER',
      },
    });
  }

  formatMessage(text: string): string {

    const txt = text.replace(/\*(.*?)\*/g, '<b>$1</b>')
    text.replace(/_(.*?)_/g, '<i>$1</i>');

    const parts = txt.split(/(<br>)/);


    const formattedParts = parts.map((part) => {
      if (part === '<br>') {
        return '<br>';
      }

      const words = part.split(/\s+/);

      const formattedWords = words.map((word) => {
        const isUrl = this.checkIfTextIsUrl(word);

        if (isUrl) {
          const prefixedUrl = word.startsWith('http') ? word : 'http://' + word;
          return `<b><a href="${ prefixedUrl }" target="_blank">${ word }</a></b>`;
        }

        return word;
      });

      return formattedWords.join(' ');
    });

    return formattedParts.join('');
  }

  checkIfTextIsUrl(str: string): boolean {
    if (str.includes('://')) {
      try {
        const url = new URL(str);
        return ['http:', 'https:'].includes(url.protocol);
      } catch (error) {
        return false;
      }
    }
    else {
      const domainRegex = /^(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
      return domainRegex.test(str);
    }
  }


  compareSelectedUser(userA, userB): boolean {
    return userA?.uid === userB?.uid;
  }

  getMessageFromCustomerPendency(){
    this.activatedRoute.queryParams.subscribe(params => {
      this.textMessageChatSce = params['message'] || '';
    });
  }


    openSelectWhatsappTemplate(): void {
      const dialogRef = this.dialog.open(SelectWhatsappTemplateComponent, {
        width: '1134px',
        height: '545px',
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.isModelSent = true; 
        }
      });
    }


}
