import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BasicRegionalization } from 'functions/src/models/Banker';
import { InstituicaoFinanceira } from 'functions/src/models/model-interface';
import { User } from 'functions/src/models/User';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { CepError, Endereco } from 'src/app/core/models/model-interfaces';
import { ViacepService } from 'src/app/core/services/viacep.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { BankersAdminService } from '../../services/bankers-admin.service';

@Component({
  selector: 'app-banker-create',
  templateUrl: './banker-create.component.html',
  styleUrls: ['./banker-create.component.scss'],
})
export class BankerCreateComponent implements OnInit, OnDestroy {
  isLoading = true;
  isProcessing = false;
  showPassword = false;

  readonly MASKS = MASKS;

  readonly numberMask = createNumberMask({
    decimalLimit: 2,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: true,
    integerLimit: 15,
    prefix: 'R$ ',
    suffix: '',
  });

  createBankerFormGroup: FormGroup;

  formatPhone = UtilHandler.formatPhone;

  // Admin control
  loggedAdmin: User;
  loggedAdminSubscription: Subscription;

  institutions: InstituicaoFinanceira[];
  lastPostalCode = '';

  // regionalization
  isRegionalized = false;
  newlocality = false;
  regionalization: BasicRegionalization[] = [];
  states: Array<any> = [];
  cities: Array<any> = [];
  regions: Array<any> = [];
  citiesList: Array<any> = [];
  createEditFormGroupLocality: FormGroup;
  readonly criteria = [
    { id: 'region', name: 'Região' },
    { id: 'state', name: 'Estado / Cidade' },
    // { id: 'zipcode', name: 'CEP' }
  ];

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private admin: BankersAdminService,
    private authService: AuthService,
    private viacep: ViacepService
  ) {}

  ngOnInit(): void {
    this.createBankerFormGroup = this.formBuilder.group({
      nameCtrl: new FormControl('', Validators.required),
      emailCtrl: new FormControl('', [Validators.required, Validators.email]),
      whatsappCtrl: new FormControl('', [Validators.required, NgBrazilValidators.telefone]),
      phoneCtrl: new FormControl('', [NgBrazilValidators.telefone]),
      institutionCtrl: new FormControl('', [Validators.required]),
      postalCodeCtrl: new FormControl('', [Validators.required, NgBrazilValidators.cep]),
      streetCtrl: new FormControl({ value: '', disabled: true }, [Validators.required]),
      numberCtrl: new FormControl('', [Validators.required]),
      extraInfoCtrl: new FormControl('', []),
      neighborhoodCtrl: new FormControl({ value: '', disabled: true }, [Validators.required]),
      cityCtrl: new FormControl({ value: '', disabled: true }, [Validators.required]),
      stateCtrl: new FormControl({ value: '', disabled: true }, [Validators.required]),
      minRevenueCtrl: new FormControl('', []),
      maxRevenueCtrl: new FormControl('', []),
      passwordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });

    // locality
    this.createEditFormGroupLocality = this.formBuilder.group({
      ifsLocalityNameCtrl: new FormControl('', Validators.required),
      ifsLocalityTypeCtrl: new FormControl('' /* Validators.required */),
      ifsLocalityStateCtrl: new FormControl('' /* Validators.required */),
      ifsLocalityRegionsCtrl: new FormControl('' /* Validators.required */),
      ifsLocalityCityCtrl: new FormControl('' /* Validators.required */),
      ifsLocalityZipCodeCtrl: new FormControl('', [/* Validators.required, */ Validators.minLength(10)]),
      ifsLocalityZipCodeQTDNumberCtrl: new FormControl('', [
        /* Validators.required,  */ Validators.min(1),
        Validators.max(8),
      ]),
    });

    this.getInstitutions();

    this.loggedAdminSubscription = this.authService.user.subscribe((user) => {
      this.loggedAdmin = user;
      this.isLoading = false;
    });
    // regionalization
    this.admin
      .getStates()
      .toPromise()
      .then((states) => {
        states.map((state) => {
          this.states.push({ id: state.id, name: state.nome, abdr: state.sigla });
        });
      })
      .catch((err) => {
        console.error('Erro ao buscar os Estados: ', err);
      });

    this.admin
      .getRegions()
      .toPromise()
      .then((regions) => {
        // regions igual a todas as regiões
        regions.forEach((region) => {
          // region igual a cada região
          this.admin
            .getUfsRegions(region.id)
            .toPromise()
            .then((statesOfRegion) => {
              // statesOfRegion igual a todos os estados que sao de uma determinada região
              const statesArray = [];

              statesOfRegion.map((state) => statesArray.push({ name: state.nome, abdr: state.sigla }));
              this.regions.push({
                id: region.id,
                name: region.nome,
                states: statesArray,
              });
            })
            .catch((err) => {
              console.error('Erro ao buscar os Estados de cada Região: ', err);
            });
        });
      })
      .catch((err) => {
        console.error('Erro ao buscar as Rgiões: ', err);
      });
  }

  ngOnDestroy(): void {
    if (this.loggedAdminSubscription) {
      this.loggedAdminSubscription.unsubscribe();
    }
  }

  // regionalization
  getCitiesforUF(id: number): void {
    this.cities = [];
    this.admin
      .getCities(id)
      .toPromise()
      .then((cities) => {
        cities.map((city) => this.cities.push({ name: city.nome }));
      })
      .catch((err) => {
        console.error('Erro ao buscar as Cidades : ', err);
      });
  }

  getCitiesListforUF(id: number, index: number): void {
    this.citiesList[index] = [];
    this.admin
      .getCities(id)
      .toPromise()
      .then((c) => {
        c.map((city) => this.citiesList[index].push({ name: city.nome }));
      })
      .catch((err) => {
        console.error('Erro ao buscar as Cidades : ', err);
      });
  }

  compareName(option, value): boolean {
    return option.name === value.name;
  }

  citiesListFunction(reg: BasicRegionalization[]): void {
    this.citiesList = [];
    if (reg.length > 0) {
      reg.forEach((r) => {
        if (r.state) {
          const c = [];

          this.admin
            .getCities(r?.state?.id)
            .toPromise()
            .then((cities) => {
              cities.map((city) => {
                c.push({ name: city.nome });
              });
            })
            .catch((err) => {
              console.error('Erro ao buscar as Cidades : ', err);
            });
          this.citiesList.push(c);
        } else {
          this.citiesList.push(null);
        }
      });
    }
  }

  isLocalityInvalid(): boolean {
    const type = this.createEditFormGroupLocality.get('ifsLocalityTypeCtrl').value || 'zipcode';

    if (
      !this.createEditFormGroupLocality.get('ifsLocalityNameCtrl').value ||
      this.createEditFormGroupLocality.get('ifsLocalityNameCtrl').invalid
    ) {
      return true;
    } else {
      if (type === 'region') {
        return !this.createEditFormGroupLocality.get('ifsLocalityRegionsCtrl').value;
      }

      if (type === 'state') {
        return !this.createEditFormGroupLocality.get('ifsLocalityStateCtrl').value;
      }

      if (type === 'zipcode') {
        return (
          !this.createEditFormGroupLocality.get('ifsLocalityZipCodeCtrl').value ||
          !this.createEditFormGroupLocality.get('ifsLocalityZipCodeQTDNumberCtrl').value
        );
      } else {
        return (
          (!this.createEditFormGroupLocality.get('ifsLocalityZipCodeCtrl').value &&
            this.createEditFormGroupLocality.get('ifsLocalityZipCodeQTDNumberCtrl').value) ||
          (this.createEditFormGroupLocality.get('ifsLocalityZipCodeCtrl').value &&
            !this.createEditFormGroupLocality.get('ifsLocalityZipCodeQTDNumberCtrl').value)
        );
      }
    }
  }

  removeLocality(l): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Remover localidade',
          alertDescription: `Deseja realmente remover essa localidade? `,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.regionalization.splice(this.regionalization.indexOf(l), 1);
          this.citiesList.splice(this.citiesList.indexOf(l), 1);
        }
      });
  }

  onAddNewLocality(): void {
    const type = this.createEditFormGroupLocality.get('ifsLocalityTypeCtrl').value || 'zipcode';

    this.regionalization.push({
      type,
      name: this.createEditFormGroupLocality.get('ifsLocalityNameCtrl')?.value || null,
      region: type === 'region' ? this.createEditFormGroupLocality.get('ifsLocalityRegionsCtrl')?.value || null : null,
      state: type === 'state' ? this.createEditFormGroupLocality.get('ifsLocalityStateCtrl')?.value || null : null,
      city: type === 'state' ? this.createEditFormGroupLocality.get('ifsLocalityCityCtrl')?.value || null : null,
      zipCode: this.createEditFormGroupLocality.get('ifsLocalityZipCodeCtrl')?.value || '',
      zipCodeDigitQuantity: this.createEditFormGroupLocality.get('ifsLocalityZipCodeQTDNumberCtrl')?.value || 0,
    });

    const stateID = this.createEditFormGroupLocality.get('ifsLocalityStateCtrl')?.value?.id || null;
    if (stateID) {
      this.admin
        .getCities(stateID)
        .toPromise()
        .then((cities) => {
          this.citiesList.push(cities.map((city) => ({ name: city.nome })));
        });
    } else {
      this.citiesList.push(null);
    }

    this.createEditFormGroupLocality.get('ifsTypelocalityCtrl')?.reset('');
    this.createEditFormGroupLocality.get('ifsTypelocalityCtrl')?.setErrors(null);
    this.createEditFormGroupLocality.get('ifsTypelocalityCtrl')?.markAsUntouched();

    this.createEditFormGroupLocality.get('ifsLocalityNameCtrl')?.reset('');
    this.createEditFormGroupLocality.get('ifsLocalityNameCtrl')?.setErrors(null);
    this.createEditFormGroupLocality.get('ifsLocalityNameCtrl')?.markAsUntouched();

    this.createEditFormGroupLocality.get('ifsLocalityStateCtrl')?.reset('');
    this.createEditFormGroupLocality.get('ifsLocalityStateCtrl')?.setErrors(null);
    this.createEditFormGroupLocality.get('ifsLocalityStateCtrl')?.markAsUntouched();

    this.createEditFormGroupLocality.get('ifsLocalityStateCtrl')?.reset('');
    this.createEditFormGroupLocality.get('ifsLocalityStateCtrl')?.setErrors(null);
    this.createEditFormGroupLocality.get('ifsLocalityStateCtrl')?.markAsUntouched();

    this.createEditFormGroupLocality.get('ifsLocalityRegionsCtrl')?.reset('');
    this.createEditFormGroupLocality.get('ifsLocalityRegionsCtrl')?.setErrors(null);
    this.createEditFormGroupLocality.get('ifsLocalityRegionsCtrl')?.markAsUntouched();

    this.createEditFormGroupLocality.get('ifsLocalityCityCtrl')?.reset('');
    this.createEditFormGroupLocality.get('ifsLocalityCityCtrl')?.setErrors(null);
    this.createEditFormGroupLocality.get('ifsLocalityCityCtrl')?.markAsUntouched();

    this.createEditFormGroupLocality.get('ifsLocalityZipCodeCtrl')?.reset('');
    this.createEditFormGroupLocality.get('ifsLocalityZipCodeCtrl')?.setErrors(null);
    this.createEditFormGroupLocality.get('ifsLocalityZipCodeCtrl')?.markAsUntouched();

    this.createEditFormGroupLocality.get('ifsLocalityZipCodeQTDNumberCtrl')?.reset('');
    this.createEditFormGroupLocality.get('ifsLocalityZipCodeQTDNumberCtrl')?.setErrors(null);
    this.createEditFormGroupLocality.get('ifsLocalityZipCodeQTDNumberCtrl')?.markAsUntouched();
  }

  async createBanker(): Promise<void> {
    this.isProcessing = true;

    if (this.createBankerFormGroup.valid) {
      console.log('Changed data');
      const regionalization = this.regionalization.slice(0) || [];

      this.admin
        .createBanker(
          {
            email: this.createBankerFormGroup.get('emailCtrl').value?.trim().toLowerCase(),
            name: this.createBankerFormGroup
              .get('nameCtrl')
              .value?.trim()
              .replace(/(\s{2,})/g, ' ')
              .toUpperCase(),
            whatsapp: this.createBankerFormGroup.get('whatsappCtrl').value?.trim() || '',
            phone: this.createBankerFormGroup.get('phoneCtrl').value?.trim() || '',
            institution: { name: this.createBankerFormGroup.get('institutionCtrl').value?.trim() },
            address: {
              postalCode: this.createBankerFormGroup.get('postalCodeCtrl').value?.trim() || '',
              street: this.createBankerFormGroup.get('streetCtrl').value?.trim() || '',
              number: this.createBankerFormGroup.get('numberCtrl').value,
              extraInfo: this.createBankerFormGroup.get('extraInfoCtrl').value?.trim() || '',
              neighborhood: this.createBankerFormGroup.get('neighborhoodCtrl').value?.trim() || '',
              city: this.createBankerFormGroup.get('cityCtrl').value?.trim() || '',
              state: this.createBankerFormGroup.get('stateCtrl').value?.trim() || '',
            },
            revenueRange: {
              minRevenue: this.createBankerFormGroup.get('minRevenueCtrl').value
                ? parseFloat(
                    this.createBankerFormGroup
                      .get('minRevenueCtrl')
                      .value.replace(/[^\,\d]/g, '')
                      .replace(/\,/g, '.')
                  )
                : null,
              minRevenueStr: this.createBankerFormGroup.get('minRevenueCtrl').value || null,
              maxRevenue: this.createBankerFormGroup.get('maxRevenueCtrl').value
                ? parseFloat(
                    this.createBankerFormGroup
                      .get('maxRevenueCtrl')
                      .value.replace(/[^\,\d]/g, '')
                      .replace(/\,/g, '.')
                  )
                : null,
              maxRevenueStr: this.createBankerFormGroup.get('maxRevenueCtrl').value || null,
            },
            isRegionalized: this.isRegionalized,
            regionalization,
          },
          this.createBankerFormGroup.get('passwordCtrl').value,
          this.loggedAdmin.email
        )
        .then((res) => {
          console.log('Banker created');
          if (res === 'email-in-use') {
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'E-mail em uso',
                alertDescription: `O e-mail ${this.createBankerFormGroup.get('emailCtrl').value} já está em uso.`,
                isOnlyConfirm: true,
              },
            });
          } else if (res === 'error-sending-email') {
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao enviar e-mail',
                alertDescription: `O operador de crédito ${
                  this.createBankerFormGroup.get('emailCtrl').value
                } foi criado, mas ocorreu um erro ao enviar os dados de acesso por e-mail.`,
                isOnlyConfirm: true,
              },
            });
          } else {
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Operador criado',
                alertDescription:
                  'O operador de crédito foi criado com sucesso. Um e-mail com os dados de acesso foi enviado.',
                isOnlyConfirm: true,
              },
            });

            this.resetFields();
          }

          this.isProcessing = false;
        })
        .catch((err) => {
          console.error('Error creating banker', err);

          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro ao criar operador',
              alertDescription: 'Ocorreu um erro ao criar o operador de crédito. Tente novamente mais tarde.',
              isOnlyConfirm: true,
            },
          });

          this.isProcessing = false;
        });
    }
  }

  generatePassword(): void {
    this.createBankerFormGroup.get('passwordCtrl').setValue(this.admin.generatePassword(16));
    this.showPassword = true;
  }

  getInstitutions(): void {
    this.admin
      .getInstitutions()
      .then((institutions) => {
        this.institutions = institutions;
      })
      .catch((err) => {
        console.error('error getting institutions', err);

        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro ao obter instituições',
              alertDescription: 'Ocorreu um erro ao buscar as instituições financeiras cadastradas. Tentar novamente?',
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.getInstitutions();
            }
          });
      });
  }

  getAddressFromPostalCode(): void {
    const postalCode = this.createBankerFormGroup.get('postalCodeCtrl').value.replace('.', '').replace('-', '');

    if (!!postalCode && postalCode.length === 8) {
      this.lastPostalCode = postalCode;

      this.viacep
        .buscarPorCep(postalCode)
        .then((endereco: Endereco) => {
          if (endereco.cep === undefined) {
            this.createBankerFormGroup.get('postalCodeCtrl').setErrors({ incorrect: true });
            this.createBankerFormGroup.get('streetCtrl').setValue('');
            this.createBankerFormGroup.get('numberCtrl').setValue('');
            this.createBankerFormGroup.get('neighborhoodCtrl').setValue('');
            this.createBankerFormGroup.get('cityCtrl').setValue('');
            this.createBankerFormGroup.get('stateCtrl').setValue('');
          } else {
            if (endereco.logradouro) {
              this.createBankerFormGroup.get('streetCtrl').setValue(endereco.logradouro);
              this.createBankerFormGroup.get('streetCtrl').disable({ onlySelf: true });
            } else {
              this.createBankerFormGroup.get('streetCtrl').setValue('');
              this.createBankerFormGroup.get('streetCtrl').enable({ onlySelf: true });
            }
            if (endereco.bairro) {
              this.createBankerFormGroup.get('neighborhoodCtrl').setValue(endereco.bairro);
              this.createBankerFormGroup.get('neighborhoodCtrl').disable({ onlySelf: true });
            } else {
              this.createBankerFormGroup.get('neighborhoodCtrl').setValue('');
              this.createBankerFormGroup.get('neighborhoodCtrl').enable({ onlySelf: true });
            }
            this.createBankerFormGroup.get('cityCtrl').setValue(endereco.localidade);
            this.createBankerFormGroup.get('stateCtrl').setValue(endereco.uf);
          }
        })
        .catch((error: CepError) => {
          this.createBankerFormGroup.get('postalCodeCtrl').setErrors({ incorrect: true });
          console.error('Error getting address info', error.descricao);
        });
    }
  }

  resetFields(): void {
    this.createBankerFormGroup.get('emailCtrl').reset();
    this.createBankerFormGroup.get('emailCtrl').setErrors(null);
    this.createBankerFormGroup.get('emailCtrl').markAsUntouched();

    this.createBankerFormGroup.get('nameCtrl').reset();
    this.createBankerFormGroup.get('nameCtrl').setErrors(null);
    this.createBankerFormGroup.get('nameCtrl').markAsUntouched();

    this.createBankerFormGroup.get('whatsappCtrl').reset();
    this.createBankerFormGroup.get('whatsappCtrl').setErrors(null);
    this.createBankerFormGroup.get('whatsappCtrl').markAsUntouched();

    this.createBankerFormGroup.get('phoneCtrl').reset();
    this.createBankerFormGroup.get('phoneCtrl').setErrors(null);
    this.createBankerFormGroup.get('phoneCtrl').markAsUntouched();

    this.createBankerFormGroup.get('institutionCtrl').reset();
    this.createBankerFormGroup.get('institutionCtrl').setErrors(null);
    this.createBankerFormGroup.get('institutionCtrl').markAsUntouched();

    this.createBankerFormGroup.get('postalCodeCtrl').reset();
    this.createBankerFormGroup.get('postalCodeCtrl').setErrors(null);
    this.createBankerFormGroup.get('postalCodeCtrl').markAsUntouched();

    this.createBankerFormGroup.get('streetCtrl').reset();
    this.createBankerFormGroup.get('streetCtrl').setErrors(null);
    this.createBankerFormGroup.get('streetCtrl').markAsUntouched();
    this.createBankerFormGroup.get('streetCtrl').disable({ onlySelf: true });

    this.createBankerFormGroup.get('numberCtrl').reset();
    this.createBankerFormGroup.get('numberCtrl').setErrors(null);
    this.createBankerFormGroup.get('numberCtrl').markAsUntouched();

    this.createBankerFormGroup.get('extraInfoCtrl').reset();
    this.createBankerFormGroup.get('extraInfoCtrl').setErrors(null);
    this.createBankerFormGroup.get('extraInfoCtrl').markAsUntouched();

    this.createBankerFormGroup.get('neighborhoodCtrl').reset();
    this.createBankerFormGroup.get('neighborhoodCtrl').setErrors(null);
    this.createBankerFormGroup.get('neighborhoodCtrl').markAsUntouched();
    this.createBankerFormGroup.get('neighborhoodCtrl').disable({ onlySelf: true });

    this.createBankerFormGroup.get('cityCtrl').reset();
    this.createBankerFormGroup.get('cityCtrl').setErrors(null);
    this.createBankerFormGroup.get('cityCtrl').markAsUntouched();

    this.createBankerFormGroup.get('stateCtrl').reset();
    this.createBankerFormGroup.get('stateCtrl').setErrors(null);
    this.createBankerFormGroup.get('stateCtrl').markAsUntouched();

    this.createBankerFormGroup.get('minRevenueCtrl').reset();
    this.createBankerFormGroup.get('minRevenueCtrl').setErrors(null);
    this.createBankerFormGroup.get('minRevenueCtrl').markAsUntouched();

    this.createBankerFormGroup.get('maxRevenueCtrl').reset();
    this.createBankerFormGroup.get('maxRevenueCtrl').setErrors(null);
    this.createBankerFormGroup.get('maxRevenueCtrl').markAsUntouched();

    this.createBankerFormGroup.get('passwordCtrl').reset();
    this.createBankerFormGroup.get('passwordCtrl').setErrors(null);
    this.createBankerFormGroup.get('passwordCtrl').markAsUntouched();

    this.isRegionalized = false;
  }
}
