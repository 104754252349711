<mat-dialog-content #dialogContent class="offer-dialog-container">

  <h2>Detalhes da oferta</h2>
  <div class="dialog-main-content">
    <p *ngIf="institutionData?.instructions?.firstInstruction"><strong>Passo 1: </strong>{{ institutionData?.instructions?.firstInstruction}}</p>
    <p *ngIf="institutionData?.instructions?.secondInstruction"><strong>Passo 2: </strong>{{ institutionData?.instructions?.secondInstruction}}</p>
    <p *ngIf="institutionData?.instructions?.thirdInstruction"><strong>Passo 3: </strong>{{ institutionData?.instructions?.thirdInstruction}}</p>
    <p *ngIf="institutionData?.instructions?.fourthInstruction"><strong>Passo 4: </strong>{{ institutionData?.instructions?.fourthInstruction}}</p>
    <p *ngIf="institutionData?.instructions?.fifthInstruction"><strong>Passo 5: </strong>{{ institutionData?.instructions?.fifthInstruction}}</p>
    <p><span class="highlight">Observação importante:</span> Esta instituição financeira prefere fazer contato direto com o cliente, mas <strong>nosso time da Capital Empreendedor seguirá acompanhando sua operação</strong> e, em caso de dificuldades ou dúvidas, encorajamos fortemente que <strong>entre em contato conosco</strong> para que possamos auxiliá-lo da melhor forma! <strong>Estamos sempre presentes para ajudá-lo a conseguir o melhor crédito para sua empresa!</strong></p>
  </div>
  <div class="button-container">
    <button (click)="redirectToPortal()" [mat-dialog-close]="true">{{institutionData?.buttonText || "Acessar portal"}}</button>
  </div>
</mat-dialog-content>
