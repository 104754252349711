import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Agent } from 'functions/src/models/Agent';
import { User } from 'functions/src/models/User';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { Subscription } from 'rxjs';
import { AgentService } from 'src/app/agents/services/agent.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { AgentsAdminService } from '../../services/agents-admin.service';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-agent-edit',
  templateUrl: './agent-edit.component.html',
  styleUrls: ['./agent-edit.component.scss'],
})
export class AgentEditComponent implements OnInit, OnDestroy {
  agent: Agent;
  agentSubscription: Subscription;
  isLoading = true;
  isProcessing = false;
  isSalesforceError = false;
  agentCode: string;
  hasError = false;

  readonly MASKS = MASKS;

  editAgentFormGroup: FormGroup;

  formatPhone = UtilHandler.formatPhone;

  // Admin control
  loggedAdmin: User;
  loggedAdminSubscription: Subscription;

  constructor(
    private agentService: AgentService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private admin: AgentsAdminService,
    private authService: AuthService,
    private ums: UserManagementService
  ) {}

  ngOnInit(): void {
    this.agentCode = this.route.snapshot.paramMap.get('id');

    this.editAgentFormGroup = this.formBuilder.group({
      nameCtrl: new FormControl('', Validators.required),
      emailCtrl: new FormControl('', [Validators.required, Validators.email]),
      phoneCtrl: new FormControl('', [Validators.required, NgBrazilValidators.telefone]),
      CNPJCtrl: new FormControl('', [NgBrazilValidators.cnpj]),
      linkedinCtrl: new FormControl('', Validators.required),
      origemCtrl: new FormControl('', Validators.required),
    });

    this.agentSubscription = this.admin.getAgentData(this.agentCode).subscribe((agent) => {
      if (agent) {
        this.agent = agent;
        this.editAgentFormGroup.get('nameCtrl').setValue(agent.name);
        this.editAgentFormGroup.get('emailCtrl').setValue(agent.email);
        this.editAgentFormGroup.get('phoneCtrl').setValue(agent.phone);
        this.editAgentFormGroup.get('CNPJCtrl').setValue(agent.cnpj);
        this.editAgentFormGroup.get('linkedinCtrl').setValue(agent.linkedin);
        this.editAgentFormGroup.get('origemCtrl').setValue(agent.source || 'nao_registrado');

        this.hasError = false;
      } else {
        this.hasError = true;
      }
      this.isLoading = false;
    });

    this.loggedAdminSubscription = this.authService.user.subscribe((user) => {
      this.loggedAdmin = user;
      if (this.loggedAdmin?.accessLevel !== 'master' && this.loggedAdmin?.accessLevel !== 'admin') {
        this.editAgentFormGroup.disable();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.loggedAdminSubscription) {
      this.loggedAdminSubscription.unsubscribe();
    }
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
  }

  hasChangedFieldValues(): boolean {
    if (
      this.agent.name !== this.editAgentFormGroup.get('nameCtrl').value.trim().toUpperCase() ||
      this.agent.phone !== this.editAgentFormGroup.get('phoneCtrl').value ||
      this.agent.cnpj !== this.editAgentFormGroup.get('CNPJCtrl').value ||
      this.agent.linkedin !== this.editAgentFormGroup.get('linkedinCtrl').value ||
      this.agent.email !== this.editAgentFormGroup.get('emailCtrl').value ||
      this.agent.source !== this.editAgentFormGroup.get('origemCtrl').value
    ) {
      return true;
    }
    return false;
  }

  shouldButtonDisable(): boolean {
    return !(this.hasChangedFieldValues() || this.isSalesforceError);
  }

  changeFields(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.admin
        .updateAgentData(
          this.agent.uid,
          {
            // email: this.editAgentFormGroup.get('emailCtrl').value,
            name: this.editAgentFormGroup.get('nameCtrl').value.trim().toUpperCase(),
            phone: this.editAgentFormGroup.get('phoneCtrl').value,
            cnpj: this.editAgentFormGroup.get('CNPJCtrl').value || '',
            linkedin: this.editAgentFormGroup.get('linkedinCtrl').value || '',
            source: this.editAgentFormGroup.get('origemCtrl').value || '',
          },
          this.loggedAdmin.email
        )
        .then((res) => resolve(res))
        .catch((err) => {
          if (err === 'salesforce-error') {
            this.isSalesforceError = true;
          } else {
            this.isSalesforceError = false;
          }
          reject(err);
        });
    });
  }

  async editAgent(): Promise<void> {
    this.isProcessing = true;

    const cnpj = this.editAgentFormGroup.get('CNPJCtrl').value;
    const newEmail = this.editAgentFormGroup.get('emailCtrl').value;
    let isValidCNPJ = true;
    // CNPJ has changed
    if (cnpj && this.agent.cnpj !== cnpj) {
      try {
        await this.agentService.checkIfCNPJExists(cnpj);
      } catch (err) {
        console.error(err);
        isValidCNPJ = false;
        if (err === 'existent-cnpj') {
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'CNPJ em uso',
              alertDescription: `O CNPJ ${cnpj} já está em uso.`,
              isOnlyConfirm: true,
            },
          });
        } else {
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro ao checar CNPJ',
              alertDescription: 'Ocorreu um erro ao verificar o CNPJ informado. Tente novamente mais tarde.',
              isOnlyConfirm: true,
            },
          });
        }
        this.isProcessing = false;
      }
    }

    if (this.editAgentFormGroup.valid && isValidCNPJ) {
      console.log('Changed data');

      this.changeFields()
        .then(async () => {
          console.log('Data successfully saved');
          if (newEmail !== this.agent.email) {
            try {
              const emailUsed = await this.authService.checkIfUserEmailExists(newEmail);
              if (!emailUsed) {
                this.ums
                  .changeUserEmail(
                    this.agent.uid,
                    this.editAgentFormGroup.get('emailCtrl').value,
                    this.agent.email,
                    this.loggedAdmin.email,
                    'agents'
                  )
                  .then(() => {
                    this.dialog
                      .open(AlertDialogComponent, {
                        maxWidth: '600px',
                        data: {
                          alertTitle: 'Dados alterados',
                          alertDescription: 'Os dados do assessor de crédito foram alterados com sucesso.',
                          isOnlyConfirm: true,
                        },
                      })
                      .afterClosed()
                      .subscribe(() => {
                        this.isProcessing = false;
                      });
                  })
                  .catch((err) => {
                    this.isProcessing = false;
                    this.editAgentFormGroup.get('emailCtrl').setValue(this.agent.email);
                    console.error('Erro ao tentar alterar o email:', err);
                  });
              } else {
                this.dialog
                  .open(AlertDialogComponent, {
                    maxWidth: '600px',
                    data: {
                      alertTitle: 'Email em uso',
                      alertDescription: `O email ${newEmail} já está em uso.`,
                      isOnlyConfirm: true,
                    },
                  })
                  .afterClosed()
                  .subscribe(() => {
                    this.isProcessing = false;
                  });
              }
            } catch (error) {
              console.error(error);
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao checar Email',
                  alertDescription:
                    'Ocorreu um erro ao verificar se o Email informado existe. Tente novamente mais tarde.',
                  isOnlyConfirm: true,
                },
              });
            }
          } else {
            this.dialog
              .open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Dados alterados',
                  alertDescription: 'Os dados do assessor de crédito foram alterados com sucesso.',
                  isOnlyConfirm: true,
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.isProcessing = false;
              });
          }
        })
        .catch((err) => {
          console.error('Error changing data', err);

          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro ao salvar dados',
              alertDescription:
                'Ocorreu um erro ao salvar os dados do assessor de crédito. Tente novamente mais tarde.',
              isOnlyConfirm: true,
            },
          });
          this.isProcessing = false;
        });
    }
  }
}
