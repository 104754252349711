import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogService } from 'src/app/components/logger/log.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { DocControleVencimento, Document, UserDocuments } from '../../../../functions/src/models/UserDocument';
import {
  Document as DocumentMongoDB
} from '../../../../functions/src/models/documents/UserDocument';
import { TipoDocumento } from './../../../../functions/src/models/model-interface';

@Injectable({
  providedIn: 'root',
})
export class UserDocumentService {
  tiposDocumentosCollection: AngularFirestoreCollection<Document>;

  userDocumentsCollection: AngularFirestoreCollection<UserDocuments>;
  userDocuments: Observable<UserDocuments[]>;
  userDocumentDoc: AngularFirestoreDocument<UserDocuments>;

  constructor(
    private angularFirestore: AngularFirestore,
    private storage: AngularFireStorage,
    private logger: LogService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.logger.controllerName = this.constructor.name;
  }

  ////// MONGODB////////
  async getDocuments(filter: { uid?: string; uids?: string[]; situations?: string[] }): Promise<DocumentMongoDB[]> {
    return new Promise((resolve, reject) => {
      let params = new HttpParams();
      params = params.append('filter', JSON.stringify(filter));

      this.http
        .get(`${environment.functionsUrl}/document/?${params}`, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res) => {
          console.log('Get customer documents');
          resolve(res as DocumentMongoDB[]);
        })
        .catch((err) => {
          console.error('Error getting customer documents', err);
          reject(err);
        });
    });
  }

  getDocumentsPending(uid: string): Promise<DocumentMongoDB[]> {
    return this.getDocuments({ uid, situations: ['Pendente', 'Enviado Parcialmente'] });
  }

  postDocuments(documents: TipoDocumento[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.functionsUrl}/document/`,
          { documents },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Created Documents - Success.');
          resolve();
        })
        .catch((err) => {
          console.error('Error Created Documents - ', err);
          reject(err);
        });
    });
  }

  async patchDocument(document: DocumentMongoDB): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .patch(
          `${environment.functionsUrl}/document/${document.uid}`,
          { document },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Patch document');
          resolve();
        })
        .catch((err) => {
          console.error('Error patching document', err);
          reject(err);
        });
    });
  }


  async deleteDocument(uid: string, typeId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(`${environment.functionsUrl}/document/${uid}/${typeId}`, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then(() => {
          console.log('Usuario Deletado com Sucesso.');
          resolve();
        })
        .catch((err) => {
          console.error('Erro ao tentar Deletar o Usuario.', err);
          reject(err);
        });
    });
  }
  /////////////////////

  getTiposDocumentos(userDocuments: UserDocuments) {
    this.tiposDocumentosCollection = this.angularFirestore.collection('tipo-documento', (x) =>
      x.where('ativo.id', '==', 'sim').where('automatico.id', '==', 'sim').orderBy('nome', 'asc')
    );
    const tipoDocumento: Observable<Document[]> = this.tiposDocumentosCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as Document;
          data.id = a.payload.doc.id;
          data.situacao = 'Pendente';
          data.qtdArquivosEsperados = 1;
          data.qtdArquviosSubmetidos = 0;
          if (data.anoExercicio.id === 'sim') {
            data.qtdArquivosEsperados = data.anoExercicioQtd;
          }
          if (userDocuments && userDocuments.documentos) {
            const userData = userDocuments.documentos.find((f) => f.mnemonico === data.mnemonico);
            if (userData) {
              data.situacao = userData.situacao;
              data.qtdArquviosSubmetidos = userData.qtdArquviosSubmetidos;
              data.arquivosInfo = userData.arquivosInfo;
              data.dataAprovacao = userData.dataAprovacao;
              data.motivoReprovacao = userData.motivoReprovacao;
              data.controleVencimentoId = userData.controleVencimentoId;
            }
          }
          return data;
        });
      })
    );
    return tipoDocumento;
  }

  async setDocument(document: Document): Promise<void> {
    try {
      const promises: Promise<any>[] = [];

      promises.push(
        this.angularFirestore
          .doc(`customers/${document.uid}`)
          .collection('documents')
          .doc(document.id)
          .set(document, { merge: true })
      );

      await Promise.all(promises);
    } catch (error) {
      this.logger.fatal('Problemas na alteração dos documentos do usuário. ' + document.uid, error);
    }

    return this.angularFirestore
      .doc(`customers/${document.uid}`)
      .collection('documents')
      .doc(document.id)
      .set(document, { merge: true });
  }

  async setDocuments(documents: Document[], uid: string): Promise<void> {
    const userDocumentsRef = this.angularFirestore.doc(`customers/${uid}`).collection('documents');

    try {
      await Promise.all(documents.map(async (d) => await userDocumentsRef.doc(d.id).set(d, { merge: true })));
      this.logger.info('Documentos do usuário alterado com sucesso.', uid);
    } catch (error) {
      this.logger.fatal('Problemas na alteração dos documentos do usuário. ' + uid, error);
    }
  }

  async deleteDocumentFirebase(uid: string, id: string): Promise<void> {
    await this.angularFirestore.doc(`customers/${uid}`).collection('documents').doc(id).delete();
  }

  addControleVencimento(dadosControleVencimento: DocControleVencimento): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getControleVencimento(dadosControleVencimento.uid, dadosControleVencimento.tipoDocumento).then(
        (dadosExistentes) => {
          if (dadosExistentes.length > 0) {
            dadosExistentes.forEach((item, index) => {
              this.deleteControleVencimento(item['id'])
                .then(() => {
                  this.logger.info('item de controle de vencimento de documentos excluído com sucesso');
                })
                .catch((e) => {
                  this.logger.fatal('Problemas ao excluir um item de controle de vencimento de documentos');
                });
            });
          }
          const collectionRef = this.angularFirestore.collection('user-doc-controle-vencimento');
          collectionRef
            .add(dadosControleVencimento)
            .then((retorno) => {
              this.logger.info('Inclusão no controle de vencimento incluído com sucesso.');
              resolve(retorno.id);
            })
            .catch((e) => {
              this.logger.fatal('Houve um problema na inclusão no controle de vencimento de documentos.', e);
              reject('error');
            });
        }
      );
    }).catch((e) => {
      this.logger.fatal(
        'Problemas ao buscar os controle de vencimento de um usuário',
        dadosControleVencimento.uid,
        dadosControleVencimento.tipoDocumento,
        e
      );
    });
  }

  deleteControleVencimento(docId: string) {
    return this.angularFirestore.collection('user-doc-controle-vencimento').doc(docId).delete();
  }

  setDocumentoReprovado(dadosTipoDocumento: DocumentMongoDB) {
    const ref = this.angularFirestore.collection('user-documents-disapproved');
    ref
      .doc()
      .set(dadosTipoDocumento, { merge: true })
      .then(() => {
        this.logger.info('Documento reprovado incluído com sucesso');
      })
      .catch((e) => {
        this.logger.error('Houve um problema na inclusão de um documento reprovado.', e);
      });
  }

  calculateDocumentSituation(document: DocumentMongoDB): string {
    const allDocsReady = document.financialYears?.every((exerciseYear) => {
      const submittedFilesSameYear = document.fileInfo.filter((docInfo) => docInfo.financialYear === exerciseYear)
        .length;
      return submittedFilesSameYear >= (document.minimumDocuments || 1);
    });
    if (document.fileInfo.length === 0) {
      return 'Pendente';
    } else if (document.financialYear.id === 'nao' && document.fileInfo.length >= (document.minimumDocuments ?? 1)) {
      return 'Envio Completo';
    } else if (allDocsReady) {
      return 'Envio Completo';
    } else {
      return 'Enviado Parcialmente';
    }
  }

  getControleVencimento(uid: string, tipoDocumento: string): Promise<any> {
    const collectionRef = this.angularFirestore.collection<DocControleVencimento>('user-doc-controle-vencimento', (x) =>
      x.where('uid', '==', uid).where('tipoDocumento', '==', tipoDocumento)
    );
    const dadosControleVencimento = collectionRef.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as DocControleVencimento;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return new Promise((resolve, reject) => {
      dadosControleVencimento.subscribe((dadosExistentes) => {
        if (dadosExistentes) {
          resolve(dadosExistentes);
        } else {
          resolve(null);
        }
      });
    }).catch((e) => {
      this.logger.log('Problemas ao buscar os controle de vencimento de um usuário', uid, tipoDocumento, e);
    });
  }

  // getStatusTutuDigital(idProposta: string) {
  //   return this.http.post(
  //     `${environment.functionsUrl}/tutudigitalapi/get-proposta-por-codigo/${idProposta}`,
  //     { responseType: 'text', headers: this.authService.getHeader() }).toPromise();
  // }

  getDetalhesBanco(idBanco: string) {
    return this.angularFirestore.doc(`instituicoes-financeiras/${idBanco}`).get();
  }

  downloadUrlAsPromise(url: string): Promise<any> {
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function (evt) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(new Error('Ajax error for ' + url + ': ' + xhr.status));
          }
        }
      };
      xhr.send();
    });
  }

  downloadFile(url: string): Promise<any> {
    return this.http.get(url, { responseType: 'blob' }).toPromise();
  }

  async documentsNoNeedsChange(uid: string, typeId: string): Promise<any> {
    return await this.http
      .post(
        `${ environment.functionsUrl }/usermanagement/documents-no-needs-change`,
        {  uid, typeId },
        { responseType: 'text', headers: this.authService.getHeader() }
      )
      .toPromise();
  }

}
