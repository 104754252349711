/* eslint-disable @typescript-eslint/no-var-requires */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { firestore } from 'firebase';
import { timestampForDate } from 'functions/src/utils/dates';
import _moment from 'moment';
import { Subscription } from 'rxjs';
import { LogService } from 'src/app/components/logger/log.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { createNumberMask } from 'text-mask-addons';
import { User } from '../../../../functions/src/models/User';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import { CadastrosGerais, CadastrosGeraisCupom, CadastrosGeraisItem, CadastrosTemplatesMessage, GeneralConfigsModel, ItemRegimeTributario } from './cadastros-gerais';
import { CadastrosGeraisService } from './cadastros-gerais.service';

@Component({
  selector: 'app-cadastros-gerais',
  templateUrl: './cadastros-gerais.component.html',
  styleUrls: ['./cadastros-gerais.component.scss'],
})

export class CadastrosGeraisComponent implements OnInit, OnDestroy {
  loggedAdminSubscription: Subscription;
  loggedAdmin: User;

  constructor(public _data: CadastrosGeraisService, private formBuilder: FormBuilder, private dialog: MatDialog, private logger: LogService, private authService: AuthService) {
    this.logger.controllerName = this.constructor.name;
    this.dataSource = new MatTableDataSource(this.dataCadastrosGeraisItem);
    this.showDetails = false;
    this.isCreateEdit = false;
  }
  displayedColumns: string[] = ['nome', 'mnemonico', 'ativo', 'acao'];
  cadastrosGerais: CadastrosGerais[] = [
    { nome: 'Tipos Instituições Financeiras', mnemonico: 'tipo-instituicao-financeira' },
    { nome: 'Setor', mnemonico: 'setor' },
    { nome: 'Garantias', mnemonico: 'garantias' },
    { nome: 'Linhas / Produtos', mnemonico: 'linhas' },
    { nome: 'Modalidades', mnemonico: 'modalidades' },
    { nome: 'Grupo Instituição Financeira', mnemonico: 'instituicao-financeira-grupo' },
    { nome: 'Cupom', mnemonico: 'cupom' },
    { nome: 'Configurações Gerais', mnemonico: 'generalconfigs' },
    { nome: 'Regimes Tributários', mnemonico: 'regimes-tributarios' },
    { nome: 'Motivos de Recusa do Admin', mnemonico: 'motivos-de-recusa' },
    { nome: 'Motivos de Recusa para Clientes', mnemonico: 'motivos-de-recusa-cliente' },
    { nome: 'Motivos de Recusa para IF', mnemonico: 'motivos-de-recusa-IF' },
    { nome: 'Máquinas de Cartão', mnemonico: 'maquinas-de-cartao' },
    { nome: 'Templates de Mensagens', mnemonico: 'templates-de-mensagens'},
    { nome: 'Grupo de Emails', mnemonico: 'grupo-de-emails' }
  ];
  cadastrosGeraisItem: any;

  valueType: GeneralConfigsModel['valueType'] = [
    { id: 'number', name: 'Numérico' },
    { id: 'string', name: 'Texto' },
    { id: 'date', name: 'Data e Hora' }
  ];

  createEditFormGroup: FormGroup;
  filterFormGroup: FormGroup;

  isCreateEdit: boolean;
  showDetails: boolean;
  tituloCadastroGerais: string;
  dataCadastrosGeraisItem: CadastrosGeraisItem[] | ItemRegimeTributario[];
  dataSource;
  tipoSelecionado;
  isEdit = false;
  isRegimeTributario: boolean;
  isEmailGroup: boolean;

  nomeValue: string;
  codigoValue: string;
  responsavelValue: string;
  tipoDescValue: string;
  valorDescontoValue: string;
  ativoValue: boolean;
  dataInicioValue: firebase.firestore.Timestamp;
  descricaoValue: string;

  numberMask = createNumberMask({
    decimalLimit: 2,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: false,
    integerLimit: 15,
    prefix: 'R$ ',
    suffix: '',
  });

  minDate = new Date();
  getCadastrosGeraisSubscription: Subscription;

  protected toDate(d: Date | string): Date {
    return (typeof d === 'string') ? new Date(d) : d;
  }

  // filtro do calendario do cupom
  public dateFilter = (d: Date): boolean => {
    const value = this.createEditFormGroup.value;
    return (d >= this.toDate(value.dataInicioCtrl));
  }

  ngOnInit(): void {
    console.log(this.constructor.name);

    this.loggedAdminSubscription = this.authService.user.subscribe((user) => {
      this.loggedAdmin = user;
    });

  }

  ngOnDestroy(): void {
    if (this.getCadastrosGeraisSubscription) {
      this.getCadastrosGeraisSubscription.unsubscribe();
    }
    if (this.loggedAdminSubscription) {
      this.loggedAdminSubscription.unsubscribe();
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  onClick(item) {
    this.showDetails = false;
    const prevItem = -1;
    const nextItem = 1;
    const nullItem = 0;

    this.tipoSelecionado = item.mnemonico;
    this.tituloCadastroGerais = item.nome;

    // break main logic to inser separeted component template
    if (this.tipoSelecionado === 'grupo-de-emails') {
      this.isEmailGroup = true;
      this.showDetails = false;
      return;
    }
    if (this.tipoSelecionado === 'regimes-tributarios') {
      this.displayedColumns.splice(1, 0, 'limite');
    } else {
      this.displayedColumns = this.displayedColumns.filter((column) => column !== 'limite');
    }

    if (this.tipoSelecionado === 'generalconfigs') {
      this.displayedColumns.splice(2, 0, 'valueType');
    } else {
      this.displayedColumns = this.displayedColumns.filter((column) => column !== 'valueType');
    }

    this.getCadastrosGeraisSubscription = this._data.getCadastrosGerais(this.tipoSelecionado).subscribe((result) => {
      this.dataCadastrosGeraisItem = result;
      this.dataCadastrosGeraisItem.sort(function (itemOne, itemTwo) {
        return itemTwo.position > itemOne.position
          ? prevItem
          : itemTwo.position < itemOne.position
            ? nextItem
            : nullItem;
      });
      this.showDetails = true;
    });
    this.onLoadForm();
  }

  onLoadForm() {
    this.isRegimeTributario = this.tipoSelecionado === 'regimes-tributarios';

    if (this.isRegimeTributario) {
      this.createEditFormGroup = new FormGroup({
        nomeCtrl: new FormControl('', Validators.required),
        mnemonicoCtrl: new FormControl({ value: '', disabled: this.isEdit }, Validators.required),
        ativoCtrl: new FormControl('', Validators.required),
        idCtrl: new FormControl('', []),
        limiteCtrl: new FormControl('', []),
      });
    } else {
      this.createEditFormGroup = new FormGroup({
        nomeCtrl: new FormControl('', Validators.required),
        mnemonicoCtrl: new FormControl({ value: '', disabled: this.isEdit }, Validators.required),
        ativoCtrl: new FormControl('', Validators.required),
        idCtrl: new FormControl('', []),
        codigoCtrl: new FormControl,
        responsavelCtrl: new FormControl,
        dataFimCtrl: new FormControl,
        dataInicioCtrl: new FormControl,
        descricaoCtrl: new FormControl,
        tipoDescCtrl: new FormControl,
        valorDescontoCtrl: new FormControl,
        positionCtrl: new FormControl(this.dataCadastrosGeraisItem?.length + 1, []),

        // cadastros gerais
        descriptionCtrl: new FormControl,
        createdAtCtrl: new FormControl({ disabled: this.isEdit }),
        lastUpdateCtrl: new FormControl,
        valueCtrl: new FormControl,
        valueTypeCtrl: new FormControl(),
      });
    }
  }

  onAdicionarNovo() {
    this.isEdit = false;
    this.isCreateEdit = true;
    this.onLoadForm();
  }

  onEditar(cg) {
    this.isEdit = true;
    this.isCreateEdit = true;
    this.onLoadForm();
    this.createEditFormGroup.get('idCtrl').setValue(cg.id);
    this.createEditFormGroup.get('nomeCtrl').setValue(cg.nome);
    this.createEditFormGroup.get('mnemonicoCtrl').setValue(cg.mnemonico);
    this.createEditFormGroup.get('ativoCtrl').setValue(cg.ativo);
    if (this.isRegimeTributario) {
      this.createEditFormGroup.get('limiteCtrl').setValue(cg.limite);
    }
  }


  onEditGeneralConfigs(cg) {
    this.isEdit = true;
    this.isCreateEdit = true;
    this.onLoadForm();
    this.createEditFormGroup.get('nomeCtrl').setValue(cg.nome);
    this.createEditFormGroup.get('mnemonicoCtrl').setValue(cg.mnemonico);
    this.createEditFormGroup.get('ativoCtrl').setValue(cg.ativo);
    this.createEditFormGroup.get('descriptionCtrl').setValue(cg.description);
    this.createEditFormGroup.get('createdAtCtrl').setValue(cg.createdAt);
    this.createEditFormGroup.get('valueCtrl').setValue(cg.value);
    this.createEditFormGroup.get('valueTypeCtrl').setValue(cg.valueType);

  }

  onEditarCupom(cg) {
    this.onLoadForm();
    this.createEditFormGroup.get('codigoCtrl').setValue(cg.id);
    this.createEditFormGroup.get('nomeCtrl').setValue(cg.nome);
    this.createEditFormGroup.get('mnemonicoCtrl').setValue(cg.mnemonico);
    this.createEditFormGroup.get('ativoCtrl').setValue(cg.ativo);
    this.createEditFormGroup.get('codigoCtrl').setValue(cg.codigo);
    this.createEditFormGroup.get('responsavelCtrl').setValue(cg.responsavel);
    this.createEditFormGroup.get('dataFimCtrl').setValue(timestampForDate(cg.dataFim));
    this.createEditFormGroup.get('dataInicioCtrl').setValue(timestampForDate(cg.dataInicio));
    this.createEditFormGroup.get('descricaoCtrl').setValue(cg.descricao);
    this.createEditFormGroup.get('tipoDescCtrl').setValue(cg.tipoDesconto);
    this.createEditFormGroup.get('valorDescontoCtrl').setValue(cg.valorDesconto);

    this.nomeValue = cg.nome;
    this.codigoValue = cg.codigo;
    this.responsavelValue = cg.responsavel;
    this.tipoDescValue = cg.tipoDesconto;
    this.valorDescontoValue = cg.valorDesconto;
    this.ativoValue = cg.ativo;
    this.dataInicioValue = cg.dataInicio;
    this.descricaoValue = cg.descricao;

    this.createEditFormGroup.get('nomeCtrl').disable();
    this.createEditFormGroup.get('codigoCtrl').disable();
    this.createEditFormGroup.get('responsavelCtrl').disable();
    this.createEditFormGroup.get('tipoDescCtrl').disable();
    this.createEditFormGroup.get('valorDescontoCtrl').disable();
    this.createEditFormGroup.get('ativoCtrl').disable();
    this.createEditFormGroup.get('dataInicioCtrl').disable();
    this.createEditFormGroup.get('descricaoCtrl').disable();

    this.isEdit = true;
    this.isCreateEdit = true;
  }

  onEditMessage(cg): void {
    this.isEdit = true;
    this.isCreateEdit = true;
    this.onLoadForm();
    this.createEditFormGroup.get('nomeCtrl').setValue(cg.nome);
    this.createEditFormGroup.get('mnemonicoCtrl').setValue(cg.mnemonico);
    this.createEditFormGroup.get('ativoCtrl').setValue(cg.ativo);
    this.createEditFormGroup.get('descriptionCtrl').setValue(cg.description);
  }

  onSaveForm() {
    let cadastrosGeraisItem: CadastrosGeraisItem | ItemRegimeTributario = {
      nome: this.createEditFormGroup.value.nomeCtrl,
      mnemonico: this.createEditFormGroup.controls.mnemonicoCtrl.value,
      ativo: this.createEditFormGroup.value.ativoCtrl,
      position: this.createEditFormGroup.value.positionCtrl,
    };

    if (this.isRegimeTributario) {
      cadastrosGeraisItem = {
        nome: this.createEditFormGroup.value.nomeCtrl,
        mnemonico: this.createEditFormGroup.controls.mnemonicoCtrl.value,
        ativo: this.createEditFormGroup.value.ativoCtrl,
        limite: this.createEditFormGroup.value.limiteCtrl || null,
        limiteNum:
          parseFloat(
            this.createEditFormGroup.value.limiteCtrl
              .trim()
              .replace(/[^\,\d]/g, '')
              .replace(/\,/g, '.')
          ) || null,
      };
    }

    if (this.createEditFormGroup.value.idCtrl === '') {
      this._data.addCadastrosGerais(cadastrosGeraisItem);
    } else {
      cadastrosGeraisItem.id = this.createEditFormGroup.value.idCtrl;
      this._data.updateCadastrosGerais(this.tipoSelecionado, cadastrosGeraisItem);
    }
    this.isCreateEdit = false;
  }

  onSaveGeneralConfigs() {
    const date = new Date();
    const cadastrosGeraisItem: GeneralConfigsModel = {
      nome: this.createEditFormGroup.value.nomeCtrl,
      mnemonico: this.createEditFormGroup.controls.mnemonicoCtrl.value,
      ativo: this.createEditFormGroup.value.ativoCtrl,
      description: this.createEditFormGroup.value.descriptionCtrl,
      valueType: this.createEditFormGroup.value.valueTypeCtrl,
      lastUpdate: firestore.Timestamp.fromDate(
        _moment(date).toDate()
      ),
      value: this.createEditFormGroup.value.valueCtrl,
      id: this.createEditFormGroup.value.idCtrl,
    };
    if (!this.isEdit) {
      cadastrosGeraisItem.createdAt = firestore.Timestamp.fromDate(
        _moment(date).toDate()
      );
    }
    if (this.createEditFormGroup.value.idCtrl === this.createEditFormGroup.controls.mnemonicoCtrl.value) {
      this._data.addCadastrosGerais(cadastrosGeraisItem);
    } else {
      cadastrosGeraisItem.id = this.createEditFormGroup.controls.mnemonicoCtrl.value;
      this._data.updateCadastrosGerais(this.tipoSelecionado, cadastrosGeraisItem);
    }
    this.isCreateEdit = false;

  }

  compareObjects(o1: any, o2: any): boolean {
    return o1?.name === o2?.name && o1?.id === o2?.id;
  }

  onSaveCupom() {
    if (!this.isEdit) {
      this.onCreateCupom();
    } else {
      const cadastrosGeraisItem: CadastrosGeraisCupom = {
        id: this.createEditFormGroup.value.codigoCtrl || this.codigoValue,
        nome: this.createEditFormGroup.value.nomeCtrl || this.nomeValue,
        mnemonico: this.createEditFormGroup.controls.mnemonicoCtrl.value,
        ativo: this.createEditFormGroup.value.ativoCtrl || this.ativoValue,
        responsavel: this.createEditFormGroup.value.responsavelCtrl || this.responsavelValue,
        codigo: this.createEditFormGroup.value.codigoCtrl || this.codigoValue,
        dataInicio: firestore.Timestamp.fromDate(
          _moment(this.createEditFormGroup.value.dataInicioCtrl || this.dataInicioValue).toDate()
        ),
        dataFim: firestore.Timestamp.fromDate(
          _moment(this.createEditFormGroup.value.dataFimCtrl).toDate()
        ),
        descricao: this.createEditFormGroup.value.descricaoCtrl || this.descricaoValue,
        tipoDesconto: this.createEditFormGroup.value.tipoDescCtrl || this.tipoDescValue,
        valorDesconto: this.createEditFormGroup.value.valorDescontoCtrl || this.valorDescontoValue,
      };
      
      this._data.updateCadastrosGeraisCupom(cadastrosGeraisItem)
      .then(() => {
        this.isCreateEdit = false;
        this.isEdit = false;
      })
      .catch((error) => {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro',
            alertDescription: error.message,
            isOnlyConfirm: true,
          },
        });
      });
    }
  
  }

  onCreateCupom() {
    const cadastrosGeraisItem: CadastrosGeraisCupom = {
      nome: this.createEditFormGroup.value.nomeCtrl,
      mnemonico: this.createEditFormGroup.controls.mnemonicoCtrl.value,
      ativo: this.createEditFormGroup.value.ativoCtrl,
      responsavel: this.createEditFormGroup.value.responsavelCtrl,
      codigo: this.createEditFormGroup.value.codigoCtrl,
      dataInicio: firestore.Timestamp.fromDate(
        _moment(this.createEditFormGroup.value.dataInicioCtrl).toDate()
      ),
      dataFim: firestore.Timestamp.fromDate(
        _moment(this.createEditFormGroup.value.dataFimCtrl).toDate()
      ),
      descricao: this.createEditFormGroup.value.descricaoCtrl,
      tipoDesconto: this.createEditFormGroup.value.tipoDescCtrl,
      valorDesconto: this.createEditFormGroup.value.valorDescontoCtrl,
    };

    if (this.createEditFormGroup.value.idCtrl === this.createEditFormGroup.value.codigoCtrl) {
      this._data.addCadastrosGerais(cadastrosGeraisItem);
    } else {
      cadastrosGeraisItem.id = this.createEditFormGroup.value.codigoCtrl;
      this._data.updateCadastrosGerais(this.tipoSelecionado, cadastrosGeraisItem);
    }
    this.isCreateEdit = false;
  }

  onSaveTemplateMessage(): void {

    const cadastrosGeraisItem: CadastrosTemplatesMessage = {
      nome: this.createEditFormGroup.value.nomeCtrl,
      mnemonico: this.createEditFormGroup.controls.mnemonicoCtrl.value,
      description: this.createEditFormGroup.value.descriptionCtrl,
      ativo: this.createEditFormGroup.value.ativoCtrl,

    };
    if (this.createEditFormGroup.value.idCtrl === this.createEditFormGroup.controls.mnemonicoCtrl.value) {
      this._data.addCadastrosGerais(cadastrosGeraisItem);
    } else {
      cadastrosGeraisItem.id = this.createEditFormGroup.controls.mnemonicoCtrl.value;
      this._data.updateCadastrosGerais(this.tipoSelecionado, cadastrosGeraisItem);
    }
    this.isCreateEdit = false;
  }

  onVoltar() {
    this.isCreateEdit = false;
  }

  deleteCupons(cg) {
    this._data.deleteCupom(cg.codigo);
  }

  deleteMessage(cg) {
    this._data.deleteMessage(cg.id);
  }

  slugifName(name) {
    if (!this.isEdit) {
      const slugify = require('@sindresorhus/slugify');
      const result = slugify(name, { separator: '_' });

      this.createEditFormGroup.get('mnemonicoCtrl').setValue(result);
    }
  }
}


