<mat-accordion *ngIf="openFinanceData && openFinanceData.length > 0">
  <mat-expansion-panel *ngFor="let item of openFinanceData" class="exp-panel">
    <mat-expansion-panel-header>
      Empréstimo <!-- Mostra as chaves do objeto como título do menu -->
    </mat-expansion-panel-header>
    <div *ngFor="let key of getObjectKeys(item)">
      {{ key }}: 
      <span *ngIf="isObjectOrArray(item[key])">
        <pre>{{ item[key] | json }}</pre>
      </span>
      <span *ngIf="!isObjectOrArray(item[key])">{{ item[key] }}</span>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<p *ngIf="!openFinanceData || openFinanceData.length === 0">Nenhum dado encontrado.</p>
