import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogService } from 'src/app/components/logger/log.service';
import { Document } from '../../../../functions/src/models/UserDocument';

@Injectable({
  providedIn: 'root',
})
export class TipoDocumentoService {
  tiposDocumentosItemCollection: AngularFirestoreCollection<Document>;
  tipoDocumento: Observable<Document[]>;
  tipoDocumentoDoc: AngularFirestoreDocument<Document>;

  constructor(private angularFirestore: AngularFirestore, private logger: LogService) {
    this.logger.controllerName = this.constructor.name;
    this.tiposDocumentosItemCollection = this.angularFirestore.collection('tipo-documento', (x) =>
      x.orderBy('nome', 'asc')
    );
  }

  getTiposDocumentos() {
    this.tipoDocumento = this.tiposDocumentosItemCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as Document;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.tipoDocumento;
  }

  addTipoDocumento(dadosTipoDocumento: Document): void {
    dadosTipoDocumento = {
      ...dadosTipoDocumento,
      id: this.angularFirestore.createId(),
    };

    this.angularFirestore
      .doc(`tipo-documento/${dadosTipoDocumento.id}`)
      .set(dadosTipoDocumento, { merge: true })
      .then(() => {
        this.logger.info('document type added.', dadosTipoDocumento.mnemonico);
      })
      .catch(() => {
        this.logger.error('Error: could not add document type', dadosTipoDocumento.mnemonico);
      });
  }

  updateTipoDocumento(dadosTipoDocumento: Document) {
    this.tipoDocumentoDoc = this.angularFirestore.doc(`tipo-documento/${dadosTipoDocumento.id}`);
    this.tipoDocumentoDoc
      .set(dadosTipoDocumento, { merge: true })
      .then((s) => {
        this.logger.info('tipo de documento alterado com sucesso.', dadosTipoDocumento.mnemonico);
      })
      .catch((e) => {
        this.logger.error('Problemas na alteração de tipo de documento. ' + dadosTipoDocumento.mnemonico, e);
      });
  }
}
