import { Customer } from 'functions/src/models/Customer';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { CustomerService } from '../../../customer/services/customer.service';
import { TemplateBndesSignupService } from '../../../layouts/templates/template-bndes/template-bndes.service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit, OnDestroy {
  customer: Customer = null;
  proposalId: string;
  isProcessing = true;
  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private templateService: TemplateBndesSignupService
  ) {
    this.templateService.setImagePath('assets/images/bndes-signup.png');
  }

  async ngOnInit(): Promise<void> {
    this.proposalId = this.activatedRoute.snapshot.params.key ?? null;
    if (!this.proposalId) {
      this.showMessage(
        'Oferta não especificada',
        'Nenhum ID de oferta foi especificado. O navegador será redirecionado para a tela de cadastro da Capital Empreendedor.',
        'cadastrar'
      );
    } else {
      try {
        const { customer, cnpj } = await this.customerService.getBndesProposalByID(this.proposalId);
        const cnpjUsed = await this.customerService.checkIfCNPJExistsAsCustomer(cnpj);

        if (cnpjUsed) {
          this.showMessage(
            'Cliente já cadastrado',
            'Já foi efetuado o cadastro de uma proposta com esse CNPJ. Você será redirecionado a tela de login',
            'entrar'
          );
        }

        if (!customer) {
          this.showMessage(
            'Erro: Cliente não encontrado',
            'Nenhum cliente com o ID especificado foi encontrado. O navegador será redirecionado para a tela de cadastro da Capital Empreendedor.',
            'cadastrar'
          );
        } else {
          this.customer = customer;
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  ngOnDestroy(): void {
    //
  }

  showMessage(alertTitle: string, alertDescription: string, redirectTo?: string): void {
    const subscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        closeOnNavigation: false,
        disableClose: false,
        hasBackdrop: true,
        data: {
          alertTitle,
          alertDescription,
          isOnlyConfirm: true,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!!result) {
          if (subscription) {
            subscription.unsubscribe();
          }
          if (!!redirectTo) {
            this.router.navigate([redirectTo]);
          }
        }
      });
  }
}
