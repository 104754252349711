<div class="content">
  <div class="d-flex">
    <div style="flex: 1">
      <img alt="logo-capital-empreendedor" src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fnewlogo.svg?alt=media&token=670d09c9-9b73-453f-9598-cce88c9486e6" class="logo" />
    </div>
    <a mat-button class="d-flex align-items-center flex-row" [routerLink]="['/entrar']">
      <mat-icon fontSet="fas" fontIcon="fa-sign-in-alt" class="colored-icon mr-2"></mat-icon> Entrar
    </a>
  </div>
  <div *ngIf="customer">
    <app-form-create-account
      [proposalId]="proposalId"
      [customer]="customer"
      [bndesSource]="'OAuth - BNDES'"
    ></app-form-create-account>
  </div>
</div>
