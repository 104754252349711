<div class="main-container">
  <div class="toolbar">
    <mat-toolbar style="background-color: #0A452B;">
      <button class="toggler" mat-icon-button (click)="this.toggle()" *ngIf="shouldSidenavBeVisible()">
        <mat-icon>menu</mat-icon>
      </button>
      <a routerLink="/" class="branding d-md-inline" style="color: #fff">
        <img [src]="logoFull" />
      </a>
      <mat-chip-list aria-label="Environment" class="d-inline-block spacer" *ngIf="!isProd">
        <mat-chip class="ml-4">
          <span>{{ envLabel }}</span>
        </mat-chip>
      </mat-chip-list>
      <div class="d-inline-block spacer" *ngIf="isProd"></div>
      <div>
        <button class="custom-button" href="https://www.capitalempreendedor.com.br/faq">
          <img src="../../../assets/images/icons/question-circle-solid.png" alt="">
          <a style="color: #FFFFFF;" href="https://www.capitalempreendedor.com.br/faq" target="_blank"> Dúvidas</a>
        </button>
      </div>

      <app-header></app-header>
    </mat-toolbar>
  </div>
  <div class="wrapper">
    <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
      <mat-sidenav class="snav" #snav *ngIf="shouldSidenavBeVisible()" [opened]="toggled" [disableClose]="true"
        style="min-width: 80px; background-color: #fafafa" [mode]="mobileQuery.matches ? 'over' : 'push'">
        <mat-nav-list [class.expanded-sidenav]="toggled && expanded">
          <a class="menu-item" [ngClass]="selectedItem === 'Crédito' ? 'menu-item selected' : 'menu-item'" mat-list-item
            routerLink="/credito" (click)="selected('linha-de-credito')">
            <i class="icon dollar">
              <ng-container *ngIf="selectedItem === 'Crédito'; else normalIcon">
                <!-- Ícone branco quando selecionado -->
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Ffavicon.png?alt=media&token=639a1fc8-498a-43a6-8455-50e5d3ff54b8"
                  alt="Ícone Crédito" width="30" height="30" />
              </ng-container>
              <ng-template #normalIcon>
                <!-- Ícone cinza quando não selecionado -->
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Ffaviconcinza.png?alt=media&token=c7840f6d-825a-42cd-8634-2b9b123c2dec"
                  alt="Ícone Crédito Cinza" width="30" height="30" />
              </ng-template>
            </i>
            <span class="menu-label" *ngIf="expanded">Crédito</span>
          </a>






          <a class="menu-item" [ngClass]="selectedItem === 'Documentação' ? 'menu-item selected' : 'menu-item'"
            mat-list-item routerLink="/documentacao" (click)="selected('documentacao')"
            *ngIf="!isCustomerNoTouch && !isBabyCustomer">
            <i class="icon file">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z"
                  stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14 2V8H20" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 13H8" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 17H8" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10 9H9H8" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </i>
            <span class="menu-label" *ngIf="expanded">Meus documentos</span>
          </a>
          <a class="menu-item" [ngClass]="selectedItem === 'Mensagens' ? 'menu-item selected' : 'menu-item'"
            mat-list-item routerLink="/mensagens" (click)="selected('mensagens')">
            <i class="circle icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path [attr.stroke]="selectedItem === 'Mensagens' ? '#FFFFFF' : '#747474'" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"
                  d="M12 2C6.48 2 2 6.48 2 12c0 1.94.56 3.74 1.52 5.26L2 22l4.94-1.34C8.26 21.44 10.07 22 12 22c5.52 0 10-4.48 10-10S17.52 2 12 2Z" />
                <path [attr.fill]="selectedItem === 'Mensagens' ? '#FFFFFF' : '#747474'"
                  d="M17.27 15.69c-.22.63-1.11 1.17-1.54 1.25-.4.07-.9.1-1.46-.09-.34-.11-.78-.25-1.35-.5-2.38-1.03-3.94-3.43-4.06-3.6-.12-.17-.97-1.3-.97-2.48 0-1.18.61-1.76.83-1.99.22-.23.49-.3.65-.3s.32.01.46.01c.15 0 .34-.05.53.4.2.45.67 1.57.73 1.68.06.11.1.24.02.38-.08.14-.12.22-.23.34-.12.13-.24.29-.34.39-.12.12-.25.25-.11.49.15.23.67 1.11 1.43 1.8 1 .9 1.84 1.18 2.09 1.3.25.11.39.09.54-.05.16-.15.63-.73.79-.98.15-.26.33-.21.56-.13.24.08 1.52.72 1.78.85.26.13.44.19.51.29.07.1.07.58-.15 1.21Z" />
              </svg>
            </i>
            <span class="menu-label" *ngIf="expanded">Chat</span>

            <div *ngIf="!expanded">
              <span class="notification-today" *ngIf="unreadMessagesCount > 0">
                {{unreadMessagesCount}}
              </span>
            </div>

            <div *ngIf="expanded">
              <span class="notification-today-expanded" *ngIf="unreadMessagesCount > 0">
                {{unreadMessagesCount}}
              </span>
            </div>




          </a>

          <a class="menu-item" [ngClass]="selectedItem === 'Explorar' ? 'menu-item selected' : 'menu-item'"
            mat-list-item routerLink="/home" (click)="selected('pagina-inicial')">
            <i class="icon file">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <!-- Círculo -->
                <circle cx="12" cy="12" r="10" stroke="#747474" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <!-- Símbolo de soma -->
                <path d="M12 8V16" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 12H16" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </i>
            <span class="menu-label" *ngIf="expanded">Explorar</span>
          </a>
          <a class="menu-item" style="background-color: #008348; border-radius: 8px;" mat-list-item
            *ngIf="hasOppsByRecurrence" (click)="openDialog()">
            <mat-icon style="color: #fff">attach_money</mat-icon>
            <span class="menu-label" style="color: #fff" *ngIf="expanded">Peça crédito novamente!</span>
          </a>

        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content class="sidenav-content"
        [class.expanded-sidenav-content]="shouldSidenavBeVisible() && toggled && expanded"
        [class.toggled-only-sidenav-content]="shouldSidenavBeVisible() && toggled && !expanded"
        (click)="toggled = mobileQuery.matches && toggled ? false : toggled">
        <div class="content">
          <router-outlet #o="outlet"></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
    <div class="footer">
      <div class="row">
        <div class="col-12">
          <div class="flex" style="display: flex; flex-direction: row; justify-content: space-between;">
            <ng-container></ng-container>

            <div class="col signature">
              <strong>v.{{ version }}</strong> - &#169; <span class="year">{{ year }}</span> - Capital Empreendedor -
              CNPJ: 21.261.858/0001-80
            </div>

            <ng-container> <img width="160px" src="../../../../assets/images/Parceiro BNDES.svg" alt=""> </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>