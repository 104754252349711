<div class="container">
  <mat-card *ngIf="isLoadingCustomer">
    <mat-card-content>
      <section class="my-6 text-center">
        <mat-spinner [diameter]="60" color="accent" class="d-inline-block mx-3"></mat-spinner>
      </section>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="hasCustomerError">
    <mat-card-content>
      <mat-card-title class="d-flex align-items-center">
        <a mat-mini-fab color="accent" routerLink="/agents/leads" matTooltip="Voltar à listagem" class="mr-3">
          <mat-icon>chevron_left</mat-icon>
        </a>
        <span>Lead: Erro</span>
      </mat-card-title>
      <section class="my-6">Ocorreu um erro ao obter os dados da lead. Por favor, tente novamente mais tarde.</section>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!isLoadingCustomer && !hasCustomerError && customer">
    <mat-card-content>
      <mat-card-title class="d-flex align-items-center">
        <a mat-mini-fab color="accent" routerLink="/agents/leads" matTooltip="Voltar à listagem" class="mr-3">
          <mat-icon>chevron_left</mat-icon>
        </a>
        <span>Lead: {{ customer.email }}</span>
      </mat-card-title>

      <section class="lead-info my-4">
        <h3 style="font-weight: bold">Dados do cliente</h3>
        <hr style="background-color: #9e9e9e" class="mb-3" />
        <div class="row">
          <div class="col-12 col-lg-6 overflow-auto">
            <p><strong>Razão Social:</strong> {{ customer?.companyName || '-' | uppercase }}</p>
            <p><strong>CNPJ:</strong> {{ customer?.cnpj || '-' }}</p>
            <!-- <p>
              <strong>Tempo de atividade:</strong> {{ customer.activityTime?.name || '-' }}
            </p> -->
            <p><strong>Valor solicitado:</strong> {{ customer?.creditValueStr || '-' }}</p>
            <p><strong>Faturamento anual:</strong> {{ customer.revenueStr || '-' }}</p>
            <p><strong>Dívida atual informada:</strong> {{ customer?.debtStr || '-' }}</p>
            <p><strong>Garantias:</strong>{{ guarantees?.length > 0 ? '' : '-' }}</p>
            <ul *ngIf="guarantees.length">
              <li *ngFor="let guarantee of guarantees">{{ guarantee }}</li>
            </ul>
            <p><strong>Recebíveis:</strong>{{ customer?.typeOfAnticipation?.length > 0 ? '' : '-' }}</p>
            <ul *ngIf="customer?.typeOfAnticipation?.length > 0">
              <li *ngFor="let typeOfAnticipation of customer?.typeOfAnticipation">{{ typeOfAnticipation.value }}</li>
            </ul>
            <p></p>
            <p><strong>Valor total das garantias:</strong> {{ customer?.guaranteesValueStr || '-' }}</p>
            <p><strong>Nome do responsável:</strong> {{ customer?.name || '-' | uppercase }}</p>
            <p><strong>CPF do responsável:</strong> {{ customer?.cpf || '-' }}</p>
          </div>

          <div class="col-12 col-lg-6">
            <p><strong>E-mail:</strong> {{ customer?.email || '-' }}</p>
            <p><strong>WhatsApp:</strong> {{ customer?.whatsapp || '-' }}</p>
            <p><strong>E-mail do contador:</strong> {{ customer?.accountantData?.email || '-' }}</p>
            <p><strong>Telefone do contador:</strong> {{ customer?.accountantData?.phone || '-' }}</p>
            <!-- <p>
              <strong>Telefone:</strong> {{ customer?.phone || '-' }}
            </p> -->
            <p><strong>Lead criado em:</strong> {{ customer?.createdAt?.toDate() | date }}</p>
            <p><strong>CEP:</strong> {{ customer?.address?.zipCode || '-' }}</p>
            <p>
              <strong>Endereço:</strong> {{ customer?.address?.street || '-' }}, {{ customer?.address?.number || '-' }}
            </p>
            <p><strong>Complemento:</strong> {{ customer?.address?.extraInfo || '-' }}</p>
            <p><strong>Bairro:</strong> {{ customer?.address?.neighborhood || '-' }}</p>
            <p><strong>Cidade:</strong> {{ customer?.address?.city || '-' }}</p>
            <p><strong>Estado:</strong> {{ customer?.address?.state || '-' }}</p>
            <p><strong>Origem:</strong> {{ customer?.source || '-' }}</p>
          </div>
        </div>
        <div class="col-12 text-center d-flex my-2">
          <div class="d-flex align-items-center justify-content-center flex-column" style="flex: 1">
            Status:
            <span style="font-weight: 500; font-size: 1.3em; margin: 0.5em">{{
              leadService.getLeadStatus(customer.agreedTerms, opportunities)
            }}</span>
          </div>
        </div>
      </section>

      <section class="lead-info my-4">
        <h3 style="font-weight: bold">Mensagens</h3>
        <hr style="background-color: #9e9e9e" class="mb-3" />
        <app-user-notes [type]="'AGENT'" [customer]="uid" [createdBy]="createdBy" [showHr]="true"></app-user-notes>
      </section>

      <section class="lead-info my-4" *ngIf="customer.agreedTerms && opportunities?.length > 0">
        <h3 style="font-weight: bold">Oportunidades</h3>
        <hr style="background-color: #9e9e9e" class="mb-3" />
        <div style="margin: 2px">
          <table
            mat-table
            multiTemplateDataRows
            [dataSource]="creditDataSource"
            class="mat-elevation-z8"
            style="border-collapse: separate; width: 100%"
          >
            <ng-container matColumnDef="logoPath">
              <th mat-header-cell *matHeaderCellDef>#</th>
              <td mat-cell *matCellDef="let opportunity">
                <div *ngIf="opportunity.logoPath && opportunity.lineActive">
                  <img src="{{ opportunity.logoPath }}" width="30px" />
                </div>
                <div *ngIf="!opportunity.logoPath">
                  <mat-icon>account_balance</mat-icon>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="institutionName">
              <th mat-header-cell *matHeaderCellDef>Operação</th>
              <td
                mat-cell
                *matCellDef="let opportunity"
                [class.situacao-ativada]="isOpportunityActive(opportunity)"
                [class.active-op]="isOpportunityActive(opportunity)"
              >
                {{
                  isOpportunityActive(opportunity)
                    ? opportunity.institutionName
                    : 'Instituição ' + (this.opportunities.indexOf(opportunity) + 1)
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="deadlineForMonth">
              <th mat-header-cell *matHeaderCellDef>Prazo</th>
              <td mat-cell *matCellDef="let opportunity" [class.situacao-ativada]="isOpportunityActive(opportunity)">
                {{ isOpportunityActive(opportunity) ? deadLineStr(opportunity) : '-' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="rate">
              <th mat-header-cell *matHeaderCellDef>Taxa</th>
              <td mat-cell *matCellDef="let opportunity" [class.situacao-ativada]="isOpportunityActive(opportunity)">
                {{ opportunity.rate ? opportunity.rate + '%' : '-' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="limitOperation">
              <th mat-header-cell *matHeaderCellDef>Limite Operação</th>
              <td mat-cell *matCellDef="let opportunity" [class.situacao-ativada]="isOpportunityActive(opportunity)">
                {{ opportunity?.limitOperation | currency: 'R$' || '-' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Situação</th>
              <td mat-cell *matCellDef="let opportunity" [class.situacao-ativada]="isOpportunityActive(opportunity)">
                {{ leadService.getOpportunityStatus(opportunity) }}
              </td>
            </ng-container>
            <!------
            <ng-container matColumnDef="decisionCustomer" >
              <th mat-header-cell *matHeaderCellDef  style="margin: auto;text-align: center;" > Decisão do Cliente</th>
              <td mat-cell *matCellDef="let opportunity">
                <div *ngIf="opportunity.instituicaoFinanceiraId && opportunity.lineActive">
                  <div *ngIf="opportunity.instituicaoFinanceiraId && opportunity.lineActive">
                    <p *ngIf="opportunity?.decisionCustomer === null " style="margin: auto;text-align: center;">Pendente</p>
                    <p *ngIf="opportunity?.decisionCustomer !== null " style="margin: auto;text-align: center;">{{(opportunity?.decisionCustomer === true)? 'Aceito': 'Rejeitado'}}</p>
                  </div>
                </div>
              </td>
            </ng-container>
            ---->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let opportunity" [attr.colspan]="creditColumns.length">
                <div
                  class="example-element-detail"
                  [@detailExpand]="
                    expandedElementId === opportunity.institutionId && isOpportunityActive(opportunity)
                      ? 'expanded'
                      : 'collapsed'
                  "
                  [style.height]="expandedElementId === opportunity.institutionId ? 'unset' : '0 !important'"
                >
                  <div class="p-3">
                    <!-- <div *ngIf="opportunity.rate">
                      <mat-label><b>Taxa:</b> {{opportunity.rate}} %</mat-label>
                    </div>
                    <div *ngIf="opportunity.deadlineForMonth">
                      <mat-label><b>Prazo:</b> {{opportunity.deadlineForMonth}}</mat-label>
                    </div>
                    <div *ngIf="opportunity.limiteopportunity">
                      <mat-label><b>Limite Operação:</b> {{opportunity.limitOperation}}</mat-label>
                    </div> -->
                    <div>
                      <mat-label><b>Descrição:</b> {{ opportunity.description || '-' }}</mat-label>
                    </div>
                    <div *ngIf="opportunity.lastUpdate && !opportunity.lineClosed">
                      <mat-label
                        ><b>Última alteração:</b>
                        {{
                          opportunity.lastUpdate ? (opportunity.lastUpdate | date: 'dd/LL/yyyy HH:mm') : '-'
                        }}</mat-label
                      >
                    </div>
                    <div *ngIf="opportunity.closingDate && opportunity.lineClosed">
                      <mat-label
                        ><b>Data fechamento:</b>
                        {{
                          opportunity.closingDate ? (opportunity.closingDate | date: 'dd/LL/yyyy HH:mm') : '-'
                        }}</mat-label
                      >
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="creditColumns"></tr>
            <tr
              mat-row
              *matRowDef="let opportunity; columns: creditColumns"
              class="example-element-row taskboard-task"
              [class.example-expanded-row]="expandedElementId === opportunity.institutionId"
              [class.task-status-danger]="opportunity.pendingForUser && opportunity.lineActive"
              (click)="expandedElement = expandedElement === opportunity ? null : opportunity; onClickExpanded()"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
        </div>
      </section>

      <section
        *ngIf="customer.agreedTerms && customer?.agentPermissions?.canVisualizeDocs === false"
        class="lead-info my-4"
      >
        <h3 style="font-weight: bold">Documentação</h3>
        <hr style="background-color: #9e9e9e" class="mb-3" />
        <p class="text-center">Você não tem permissão para visualizar e enviar os documentos deste cliente.</p>
      </section>
      <section
        *ngIf="customer.agreedTerms && customer?.agentPermissions?.canVisualizeDocs !== false"
        class="lead-info my-4"
      >
        <h3 style="font-weight: bold">
          Documentação
          <hr />
        </h3>
        <hr style="background-color: #9e9e9e" class="mb-3" />
        <div *ngIf="!isUpload">
          <mat-accordion class="example-headers-align">
            <mat-expansion-panel *ngFor="let item of documents">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ item.name }}
                </mat-panel-title>
                <mat-panel-description>
                  {{ item.situation }}
                  <mat-icon *ngIf="item.pendingIssueForUser" class="pendencia-element">*</mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="description">
                <h5>Descrição</h5>
                <div class="d-flex justify-content-between flex-column flex-md-row">
                  <mat-label>{{ item.description }}</mat-label>
                </div>
              </div>
              <div class="instrucoes">
                <h4>Instruções</h4>
                <div [innerHtml]="item.instructions" style="padding-top: 5px"></div>
              </div>
              <div class="detalhes">
                <h5>Detalhes</h5>
                <div class="d-flex flex-column">
                  <div
                    *ngIf="item.situation === 'Aprovado' && item.approvalOrDisapprovalDate"
                    style="padding-bottom: 10px"
                  >
                    <mat-label>
                      Aprovado em {{ item.approvalOrDisapprovalDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                    </mat-label>
                  </div>
                  <div
                    *ngIf="item.situation === 'Reprovado' && item.approvalOrDisapprovalDate"
                    style="padding-bottom: 10px"
                  >
                    <mat-label>
                      Reprovado em {{ item.approvalOrDisapprovalDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                    </mat-label>
                    <br />
                    <mat-label *ngIf="item.disapprovalReason"> Motivo: {{ item.disapprovalReason }} </mat-label>
                  </div>
                </div>
                <div>
                  <mat-label
                    >Aguardando
                    {{
                      item.qtyExpectedFiles - item.qtySubmittedFiles < 0
                        ? 0
                        : item.qtyExpectedFiles - item.qtySubmittedFiles
                    }}
                    de
                    {{ item.qtyExpectedFiles }}
                    {{ item.qtyExpectedFiles > 1 ? 'arquivos' : 'arquivo' }}</mat-label
                  >
                  <br />
                  <mat-label
                    >Limite de arquivos: {{ item.maximumDocuments || item.minimumDocuments || 1 }}
                    {{ item.qtyFinancialYear === 0 ? '' : '(por ano)' }}</mat-label
                  >
                </div>
                <div
                  class="d-flex flex-column flex-md-row"
                  *ngIf="item.expirationDate.id === 'sim'"
                  style="padding-top: 10px"
                >
                  <mat-label> Validade: {{ item.qtyDays }} dias</mat-label>
                </div>
                <div
                  class="d-flex align-items-center flex-row"
                  *ngIf="item.financialYear.id === 'sim'"
                  style="padding-top: 5px"
                >
                  <div *ngIf="item.financialYears.length > 0" style="padding-top: 10px">
                    <mat-label> Anos de exercício:</mat-label>
                    <mat-accordion>
                      <mat-expansion-panel class="mt-2" *ngFor="let year of item.financialYears">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            {{ year }}
                          </mat-panel-title>
                          <mat-panel-description>
                            {{ getDocumentStatus(item, year) }}
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="mt-2">
                          <p class="mb-2">
                            Anexe o {{ item.name }} de {{ year }} abaixo e informe a data de emissão do documento.
                          </p>
                          <div *ngIf="item.fileInfo.length > 0">
                            <div class="attached-file m-2 mt-4 mb-3" *ngIf="item?.fileInfo">
                              <div *ngFor="let file of item.fileInfo; let i = index">
                                <p class="file" *ngIf="file?.financialYear === year">
                                  <a class="attached-file-link cursor-style" href="{{ file.path }}" target="_blank">
                                    {{ file.fileName ? file.fileName : item.name }}
                                  </a>
                                  <mat-icon
                                    inline="true"
                                    class="ml-2 icon"
                                    (click)="onRemoveFileOfDocument(i, item, file)"
                                    *ngIf="customer?.agentPermissions?.canSendDocs !== false"
                                    >clear</mat-icon
                                  >
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="attach-file">
                            <span
                              matTooltip="Limite de arquivos submetidos atingido."
                              *ngIf="customer?.agentPermissions?.canSendDocs !== false"
                              [matTooltipDisabled]="canUpload"
                            >
                              <p class="mb-2" *ngIf="!(item?.fileInfo.length > 0)">Nenhum documento foi anexado.</p>
                              <mat-icon class="mr-2">attach_file</mat-icon>
                              <button
                                mat-raised-button
                                color="primary"
                                (click)="onSendNewFile(item, year)"
                                [disabled]="
                                  item.situation === 'Aprovado' || getDocumentStatus(item, year) === 'Enviado'
                                "
                              >
                                <mat-label>Enviar arquivo</mat-label>
                              </button>
                            </span>
                            <p class="text-center" *ngIf="customer?.agentPermissions?.canSendDocs === false">
                              Você não tem permissão para enviar documentos deste cliente.
                            </p>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
                <div *ngIf="item.financialYear.id === 'nao'">
                  <div>
                    <p class="mb-2">Anexe o {{ item.nome }} abaixo e informe a data de emissão do documento.</p>

                    <div class="attached-file mt-4 mb-3" *ngIf="item?.fileInfo">
                      <p class="file" *ngFor="let file of item.fileInfo; let i = index">
                        <a class="attached-file-link cursor-style" [href]="file.path" target="_blank">
                          {{ file.fileName ? file.fileName : item.nome }}
                        </a>
                        <mat-icon
                          class="ml-3 icon"
                          inline="true"
                          (click)="onRemoveFileOfDocument(null, item, file)"
                          *ngIf="customer?.agentPermissions?.canSendDocs !== false"
                          >clear</mat-icon
                        >
                      </p>
                    </div>
                    <div class="attach-file">
                      <span
                        matTooltip="Limite de arquivos submetidos atingido."
                        *ngIf="customer?.agentPermissions?.canSendDocs !== false"
                        [matTooltipDisabled]="canUpload"
                      >
                        <p class="mb-2" *ngIf="!item?.fileInfo">Nenhum documento foi anexado.</p>
                        <mat-icon class="mr-2">attach_file</mat-icon>
                        <button
                          mat-raised-button
                          color="primary"
                          (click)="onSendNewFile(item)"
                          [disabled]="item.situation === 'Aprovado' || getDocumentStatus(item) === 'Enviado'"
                        >
                          <mat-label>Enviar arquivo</mat-label>
                        </button>
                      </span>
                    </div>
                    <p class="text-center" *ngIf="customer?.agentPermissions?.canSendDocs === false">
                      Você não tem permissão para enviar documentos deste cliente.
                    </p>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div *ngIf="isUpload">
          <h4>Documento: {{ tipoDocumentoSelecionado.nome }}</h4>
          <form [formGroup]="fileInfoFormGroup" (submit)="onSalvarArquivo()">
            <file-upload></file-upload>
            <div style="padding-top: 40px" class="d-flex flex-column">
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="dp"
                  placeholder="Data de emissão"
                  name="dataEmissao"
                  formControlName="dataEmissaoCtrl"
                  required
                  (focus)="dp.open()"
                />
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
                <mat-error *ngIf="fileInfoFormGroup.controls['dataEmissaoCtrl'].hasError('required')">
                  Informe a data de emissão do documento.</mat-error
                >
              </mat-form-field>
              <mat-form-field *ngIf="tipoDocumentoSelecionado.financialYear.id === 'sim'">
                <mat-select
                  placeholder="Ano de Exercício"
                  formControlName="anoExercicioCtrl"
                  name="anoExercicio"
                  required
                >
                  <mat-option
                    *ngFor="let anoExercicioCtrl of tipoDocumentoSelecionado.anosExercicios"
                    [value]="anoExercicioCtrl"
                  >
                    {{ anoExercicioCtrl }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="fileInfoFormGroup.controls['anoExercicioCtrl'].hasError('required')">
                  Informe um ano de execício do documento.</mat-error
                >
              </mat-form-field>
            </div>
            <div style="padding-top: 40px" class="d-flex justify-content-between flex-column flex-md-row">
              <button mat-raised-button type="button" (click)="onVoltarParaLista(item)" style="margin: 5px">
                Voltar
              </button>
              <button
                mat-raised-button
                color="primary"
                type="submit"
                style="margin: 5px"
                [disabled]="!fileInfoFormGroup.valid"
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      </section>

      <section class="lead-info my-4">
        <div class="d-flex flex-column flex-sm-row">
          <h3 style="font-weight: bold; flex: 1">Registros de acompanhamento</h3>
          <button mat-raised-button color="primary" (click)="isCreateMode = true">
            <mat-icon>post_add</mat-icon> Novo Registro
          </button>
        </div>
        <hr style="background-color: #9e9e9e" class="mb-3" />

        <div *ngIf="isCreateMode">
          <mat-card class="p-3">
            <mat-card-content>
              <h3 style="font-weight: 500">Novo Registro</h3>

              <mat-form-field class="w-100" color="accent">
                <textarea
                  matInput
                  placeholder="Descrição"
                  mat-autosize
                  matAutosizeMinRows="5"
                  [(ngModel)]="newNote.message"
                  required
                ></textarea>
              </mat-form-field>

              <div class="text-right">
                <button mat-raised-button type="button" (click)="cancelNote()" class="mx-2">
                  <mat-icon>clear</mat-icon> Cancelar
                </button>
                <button
                  mat-raised-button
                  color="accent"
                  type="button"
                  [disabled]="!newNote.message"
                  (click)="createNote()"
                  class="mx-2"
                >
                  <mat-icon>save</mat-icon> Salvar
                </button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="text-center" *ngIf="isLoadingNotes">
          <mat-spinner diameter="60" class="mx-auto"></mat-spinner>
        </div>

        <div *ngIf="!isLoadingNotes">
          <p class="p-4 m-3 text-center" *ngIf="this.notes?.length === 0">Ainda não há nenhum registro.</p>

          <p class="mb-2 mt-4" style="font-weight: 500" *ngIf="this.notes?.length > 0">Últimos registros:</p>

          <mat-card
            *ngFor="let note of notes; let i = index"
            class="my-2 note"
            [class.admin-note]="note.type === 'ADMIN'"
          >
            <mat-card-content class="p-3">
              <div style="white-space: pre-line">
                {{ note.message }}
              </div>
              <div *ngIf="note.attachments?.length">
                <a style="color: blue" href="{{ attachment.contentUrl }}" *ngFor="let attachment of note.attachments">{{
                  attachment.fileName
                }}</a>
              </div>
              <mat-card-footer class="text-right p-3">
                <span class="date-label"
                  >{{ note.createdBy?.name }} - {{ note.createdAt?.toDate() | date: 'dd/MM/yyyy HH:mm:ss' }}
                  {{ note.type === 'ADMIN' ? '- (Admin)' : '' }}</span
                >
              </mat-card-footer>
            </mat-card-content>
          </mat-card>

          <div class="text-center mt-3">
            <button mat-raised-button color="accent" type="button" (click)="viewMoreNotes()">
              Ver mais <mat-icon>open_in_new</mat-icon>
            </button>
          </div>
        </div>
      </section>

      <section class="mt-4 lead-info">
        <h3 style="font-weight: bold" class="d-flex align-items-center">
          Dados extras sobre o cliente
          <mat-chip-list class="ml-3">
            <mat-chip color="primary" selected>Beta</mat-chip>
          </mat-chip-list>
        </h3>

        <hr style="background-color: #9e9e9e" class="mb-3" />

        <div class="message mb-4">
          <h5>Atenção!</h5>
          <span class="message-text">
            <p>
              Os dados abaixo foram obtidos de terceiros e são uma consulta adicional, podendo variar seu formato e/ou
              estrutura de cliente para cliente. Além disso, os dados abaixo podem estar incompletos e/ou
              desatualizados.
            </p>
            <p>
              Caso não haja nenhuma informação disponível e você deseja saber mais sobre este cliente, favor solicitar
              ao seu gerente de relacionamento.
            </p>

            <p class="mt-2">
              Caso tenha qualquer dúvida sobre a organização e significado dos dados apresentados, consulte o
              <strong>Documento de Referência sobre Dados Adicionais</strong> clicando no botão abaixo.
            </p>
            <p class="text-center m-0">
              <a
                href="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/manuais%2Freferencia-dados-adicionais.pdf?alt=media"
                target="_blank"
                color="accent"
                class="my-2"
                mat-raised-button
              >
                <div class="d-flex align-items-center">
                  <div style="flex: 1; white-space: normal">Acessar Documento de Referência sobre Dados Adicionais</div>
                  <mat-icon class="ml-2">open_in_new</mat-icon>
                </div>
              </a>
            </p>
          </span>
        </div>

        <div class="row">
          <div class="col-12" *ngIf="!bigdatacorpData || Object.keys(bigdatacorpData).length <= 0">
            <p class="text-center p-4">Nenhum dado encontrado.</p>
          </div>

          <div class="col-12" *ngIf="bigdatacorpData && Object.keys(bigdatacorpData).length">
            <app-tree [tree]="bigdatacorpData"></app-tree>
          </div>
        </div>
      </section>
    </mat-card-content>
  </mat-card>
</div>
