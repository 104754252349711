import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Customer } from '../../../../../functions/src/models/Customer';
import { OpenFinanceService } from '../../../core/services/open-finance.service';
import { CustomerService } from '../../services/customer.service';
@Component({
  selector: 'app-open-finance-dialog',
  templateUrl: './open-finance-dialog.component.html',
  styleUrls: ['./open-finance-dialog.component.scss']
})
export class OpenFinanceDialogComponent implements OnInit {

  @ViewChild('accessToken') accessTokenElm: ElementRef;




  isOpen = false;
  accessToken: string;
  urlFunctions: string;
  customerUid: string;
  customer: Customer;

  userSubscription: Subscription;

  constructor(private openFinanceService: OpenFinanceService, private customerService: CustomerService, public dialogRef: MatDialogRef<OpenFinanceDialogComponent>) { }

  async ngOnInit(): Promise<void> {

    await this.handleOpenFinanceData();
    this.userSubscription = this.customerService.customer.subscribe(async (customer) => {
      this.customer = customer;
      this.customerUid = this.customer?.uid;
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }


  async handleOpenFinanceData(): Promise<void> {
    try {
      await this.openFinanceService.generateApiKey();
      await this.openFinanceService.generateAccessToken();
      await this.openFinanceService.getCredentials().subscribe((data: any) => {

        const openFinanceBtn = document.getElementById("open-finance-btn");

        if (openFinanceBtn) {
          openFinanceBtn.setAttribute("data-access-token", data?.accessToken);
          openFinanceBtn.setAttribute("data-customer-uid", this.customerUid);
          openFinanceBtn.setAttribute("data-url-functions", data?.urlFunctions);
        }

      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  openModal() {
    this.isOpen = true;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
