import { Component, OnInit, OnDestroy } from '@angular/core';

import { AuthService } from 'src/app/core/auth/auth.service';
import { LogService } from 'src/app/components/logger/log.service';
import { Router } from '@angular/router';

import { firestore } from 'firebase';

@Component({
  selector: 'anms-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnDestroy, OnInit {
  constructor(private authService: AuthService, private router: Router, private logger: LogService) {
    this.logger.controllerName = this.constructor.name;
  }

  ngOnInit() {
    const authSubscription = this.authService.user.subscribe((user) => {
      if (user && !user.isAnonymous) {
        authSubscription.unsubscribe();
        firestore()
          .doc(`agents/${user.uid}`)
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.router.navigate(['/agents']);
            } else {
              this.router.navigate(['/user-profile']);
            }
          });
      }
    });
  }

  ngOnDestroy(): void {}
}
