import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      this.authService.user.pipe(
        map((user) => {
          if (!!user && !user.isAnonymous && !!user.uid) {
            resolve(true);
          } else {
            console.log('AuthGuard: Redirecting to Login');
            this.router.navigate(['/entrar'], { queryParams: { redirect: state.url } });
            resolve(false);
          }
        })
      );
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuardCustomer implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      this.authService.user
        .pipe(take(1))
        .toPromise()
        .then(async (user) => {
          if (!!user && !user.isAnonymous && !!user.uid) {
            if (user.isCustomer) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            console.log('AuthGuard: Redirecting to Login');
            this.router.navigate(['/entrar'], { queryParams: { redirect: state.url } });
            resolve(false);
          }
        });
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuardAdmin implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const requiredRoles: string[] = next.data.requiredRoles ?? [];
    return new Promise((resolve, reject) => {
      this.authService.user
        .pipe(take(1))
        .toPromise()
        .then(async (user) => {
          if (!!user && !user.isAnonymous && !!user.uid) {
            if (!user.isAdmin) {
              resolve(false);
            } else if (requiredRoles.length > 0 && !requiredRoles.includes(user.accessLevel)) {
              resolve(false);
            } else {
              resolve(true);
            }
          } else {
            console.log('AuthGuard: Redirecting to Login');
            this.router.navigate(['/entrar'], { queryParams: { redirect: state.url } });
            resolve(false);
          }
        });
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuardAgents implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.user
        .pipe(take(1))
        .toPromise()
        .then(async (user) => {
          if (!!user && !user.isAnonymous && !!user.uid) {
            if (user.isAgent) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            console.log('AuthGuard: Redirecting to Login');
            this.router.navigate(['/entrar'], { queryParams: { redirect: state.url } });
            resolve(false);
          }
        });
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuardBankers implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.user
        .pipe(take(1))
        .toPromise()
        .then(async (user) => {
          if (!!user && !user.isAnonymous && !!user.uid) {
            if (user.isBanker) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            console.log('AuthGuard: Redirecting to Login');
            this.router.navigate(['/entrar'], { queryParams: { redirect: state.url } });
            resolve(false);
          }
        });
    });
  }
}
