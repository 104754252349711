import { firestore } from 'firebase';
import { UserManagementService } from 'src/app/admin/services/user-management.service';
import { Component, OnInit, Input } from '@angular/core';
import { User, UserConsent } from 'functions/src/models/User';

@Component({
  selector: 'app-cookie-confirmation',
  templateUrl: './cookie-confirmation.component.html',
  styleUrls: ['./cookie-confirmation.component.scss'],
})
export class CookieConfirmationComponent implements OnInit {
  @Input() user: User;

  readonly privacyPolicyUrl =
    'https://f.hubspotusercontent10.net/hubfs/8256969/Institucional/Pol%C3%ADtica%20de%20Privacidade%20-%20Capital%20Empreendedor.pdf';
  readonly cookiesPolicyUrl =
    'https://f.hubspotusercontent10.net/hubfs/8256969/Institucional/Pol%C3%ADtica%20de%20Cookies%20-%20Capital%20Empreendedor.pdf';

  constructor(private ums: UserManagementService) {}

  ngOnInit(): void {}

  signCookies(agreedCookie: boolean): void {
    const cookiePolicy: UserConsent['cookiePolicy'] = {
      agreedCookie,
      agreedCookiesDate: agreedCookie ? firestore.Timestamp.now().toMillis() : null,
      cancelCookiesDate: !agreedCookie ? firestore.Timestamp.now().toMillis() : null,
    };
    this.ums
      .signOrCancelCookiePolicy(this.user.uid, cookiePolicy, this.user.mainRole)
      .then(() => {
        console.log('Sucess in change Cookie Policy');
      })
      .catch((err) => {
        console.error('Error in change Cookie Policy');
      });
  }
}
