import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { PartnershipsEntity } from '../entities/partnerships.entity';

@Injectable({
    providedIn: 'root'
})
export class PartnershipsService {
    partner?: PartnershipsEntity;
    cnpj?: string;
    constructor(private angularFirestore: AngularFirestore) { }


    async getPartner(id: string): Promise<void> {
        console.log('PartnerId -', id);
        return this.angularFirestore.collection('partnerships').doc(id).get().toPromise()
            .then(res => {
                this.partner = res.data() as PartnershipsEntity;
            }).catch(error => console.error('Partner Not Fount - ',error));

    }


    getAllPartners(): Observable<PartnershipsEntity[]> {
        const partnershipsCollection = this.angularFirestore.collection<PartnershipsEntity>('partnerships');
       
        return partnershipsCollection.valueChanges();
      }

    getOnboardData(): {
        cnpj: string | undefined;
        tenantId: string | undefined;
    } {
        return {
            cnpj: this.cnpj,
            tenantId: this.partner?.tenantId
        };

    }

    createNewPartnerOnFirestore(data: PartnershipsEntity): Promise<any> {
      return new Promise((resolve, reject) => {
        const partnerId = data.partner; 

        if (!partnerId) {
          reject(new Error('O campo partner deve ser fornecido para definir o ID.'));
          return;
        }
        
        const partnerRef = this.angularFirestore.collection('partnerships').doc(partnerId);
        const partnerData = {
          banner: data.banner,
          isConfirmationEmail: data.isConfirmationEmail,
          isPassword: data.isPassword,
          logo: data.logo,
          onboardingTexts: data.onboardingTexts,
          partner: data.partner,
          tenantId: data.tenantId,
          corDeFundo: data.corDeFundo,
          corDaFonte: data.corDaFonte,
          fontTitleSize: data.fontTitleSize || null,
          fontSubTitleSize: data.fontSubTitleSize || null,
          order: data.order || null,
        };
        partnerRef.set(partnerData)
          .then(() => {
            console.log('Novo parceiro criado com sucesso, ID:', partnerId);
            resolve(partnerId);
          })
          .catch((error) => {
            console.error('Erro ao criar novo parceiro:', error);
            reject(error);
          });
      });
    }
    
}
