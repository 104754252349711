export const breakpoints = {
  // screen breakpoints - size in pixels
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const sidenavAllowedURLs = [
  // URLs where customer sidebar should appear
  '/credito',
  '/documentacao',
  '/documentacao?tab=credit',
  '/documentacao?tab=documents',
  '/documentacao/?tab=credit',
  '/documentacao/?tab=documents',
  '/meu-termo',
  '/meu-assessor',
  '/minha-conta',
  '/mensagens',
  '/mensagens?filter=credito',
  '/home',

  '/open-finance-page',
  '/user-agent',
  '/user-credit',
  '/user-document',
  '/messages',
  '/user-credit-lines',
  '/user-document?tab=credit',
  '/user-document?tab=documents',
  '/user-document/?tab=credit',
  '/user-document/?tab=documents',
];

export const agentsSidenavAllowedURLs = [
  // URLs where agent sidebar should appear
  '/agents/dashboard',
  '/agents/invite',
  '/agents/leads',
  '/agents/lead',
  '/agents/profile',
  '/agents/pending-documents',
  '/agents/create-customer',
];

export const bankersSidenavAllowedURLs = [
  // URLs where banker sidebar should appear
  '/bankers/dashboard',
  '/bankers/opportunities',
  '/bankers/opportunity',
  '/bankers/profile',
  '/bankers/rejected',
  '/bankers/concluded',
];

export const environmentLabels = {
  PROD: 'Production',
  'PROD:LOCAL': 'Production - Local',
  'DEV:REMOTE': 'Staging',
  'DEV:LOCAL': 'Development',
};
