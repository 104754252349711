<mat-accordion>
  <mat-expansion-panel class="options-panel">
    <mat-expansion-panel-header>
      <mat-panel-title class="options-panel-title w-100"> Histórico de linhas </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="d-flex justify-content-between">
      <button *ngFor="let item of groupHistory" [class]="item.selected ? 'option-history bnt-selected':'option-history'"
        (click)="onSelectGroup(item.id)">{{item.label}}</button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
