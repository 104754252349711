<div class="content">
  <a routerLink="/login">
    <img alt="logo-capital-empreendedor" src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fnewlogo.svg?alt=media&token=670d09c9-9b73-453f-9598-cce88c9486e6" style="width: 100%; max-width: 210px" />
  </a>

  <!-- E-mail Validation -->
  <div *ngIf="isEmailValidation">
    <div>
      <h1>Validando e-mail</h1>
      <p>O e-mail da sua conta esté sendo validado e você será redirecionado automaticamente ao final do processo.</p>
    </div>
    <div class="form">
      <mat-spinner [diameter]="100" color="primary" class="mx-auto"></mat-spinner>
    </div>
  </div>

  <!-- Reset Password -->
  <div *ngIf="isResetPassword">
    <div>
      <h1>Redefina sua senha</h1>
      <p>O e-mail da sua conta esté sendo validado e você será redirecionado automaticamente ao final do processo.</p>
    </div>
    <form [formGroup]="resetPasswordForm">
      <div class="form">
        <div class="mb-2 d-flex align-items-center">
          <mat-icon fontSet="fas" fontIcon="fa-envelope" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <input matInput type="email" placeholder="Seu e-mail" formControlName="emailCtrl" name="email" required />
            <mat-error *ngIf="resetPasswordForm.controls['emailCtrl'].hasError('required')">
              Informe seu e-mail.
            </mat-error>
            <mat-error *ngIf="resetPasswordForm.controls['emailCtrl'].hasError('email')">
              O e-mail deve ser válido.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mb-2 d-flex align-items-center">
          <mat-icon fontSet="fas" fontIcon="fa-key" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <input
              matInput
              type="password"
              placeholder="Digite sua nova senha"
              formControlName="newPasswordCtrl"
              required
              name="newPassword"
            />
            <mat-error *ngIf="resetPasswordForm.controls['newPasswordCtrl'].hasError('required')">
              Informe uma nova senha de acesso.</mat-error
            >
            <mat-error *ngIf="resetPasswordForm.controls['newPasswordCtrl'].hasError('minlength')">
              A nova senha deve ter 6 caracteres ou mais.</mat-error
            >
          </mat-form-field>
        </div>

        <div class="mb-2 d-flex align-items-center">
          <mat-icon fontSet="fas" fontIcon="fa-key" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <input
              matInput
              type="password"
              placeholder="Confirme sua nova senha"
              formControlName="confirmNewPasswordCtrl"
              required
              name="confirmNewPassword"
            />
            <mat-error *ngIf="resetPasswordForm.controls['confirmNewPasswordCtrl'].hasError('required')">
              Confirme a nova senha criada.
            </mat-error>
            <mat-error *ngIf="resetPasswordForm.controls['confirmNewPasswordCtrl'].hasError('mismatch')">
              Confirmação da nova senha não confere.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mt-3 mb-4">
          <button
            mat-raised-button
            color="primary"
            class="action-button"
            type="submit"
            [class.disabled]="resetPasswordForm.invalid"
            *ngIf="!isProcessing"
            [disabled]="resetPasswordForm.invalid"
            (click)="saveNewPassword()"
          >
            Salvar
          </button>
          <div class="text-center">
            <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- Cancel Terms - TO DO: Refactor -->
  <div *ngIf="isCancelTermsOfService">
    <div>
      <h1>Cancelamento do Termo de Serviço</h1>
      <!-- <p>O e-mail da sua conta esté sendo validado e você será redirecionado automaticamente ao final do processo.</p> -->
    </div>

    <div class="mt-5">
      <mat-spinner *ngIf="isProcessing" [diameter]="100" color="primary"></mat-spinner>
    </div>

    <div class="form" *ngIf="!isProcessing">
      <div *ngIf="!permiteCancelamento">
        <p class="mb-3">Não será possível fazer o cancelamento do Termo de Serviço para esse código.</p>
        <p class="mb-3">Possíveis razões:</p>
        <ul>
          <li>O código de cancelamento informado é inválido ou já foi utilizado.</li>
          <li>Passou o prazo de 24 horas do aceite do Termo de Serviço.</li>
          <li>O usuário logado é diferente do usuário a quem pertence esse Termo de Serviço.</li>
        </ul>
      </div>

      <div *ngIf="permiteCancelamento">
        <p>
          <mat-label
            >Nome representante:
            <span *ngIf="usuarioLogado.nome" style="font-weight: 500">{{ usuarioLogado.nome }}</span>
            <span *ngIf="!usuarioLogado.nome"> - </span>
          </mat-label>
        </p>
        <p>
          <mat-label
            >Data e hora do aceite:
            <span style="font-weight: 500" *ngIf="usuarioLogado.datetimeAceite">
              {{ usuarioLogado.datetimeAceite.toDate() | date: 'dd/MM/yyyy HH:mm:ss' }}</span
            >
            <span *ngIf="!usuarioLogado.datetimeAceite"> - </span>
          </mat-label>
        </p>
        <form [formGroup]="cancelTermsForm" (submit)="onSaveCancelTerms()">
          <mat-form-field style="width: 100%">
            <mat-label>Deseja realmente cancelar o termo de serviço?</mat-label>
            <mat-select
              placeholder="Deseja realmente cancelar o termo de serviço?"
              formControlName="cancelCtrl"
              name="cancelaTermos"
              required
              [compareWith]="compareObjects"
              (selectionChange)="onChangeOpcaoTermos($event)"
            >
              <mat-option *ngFor="let cancelaTermosCtrl of opcaoSimNao" [value]="cancelaTermosCtrl">
                {{ cancelaTermosCtrl.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="cancelTermsForm.controls['cancelaTermosCtrl'].hasError('required')">
              Informe se deseja realmente cancelar o termo de serviço anteriormente aceito.
            </mat-error>
          </mat-form-field>
          <mat-form-field style="width: 100%" *ngIf="isCancelTerms">
            <textarea
              matInput
              placeholder="Motivo cancelamento"
              formControlName="cancelReasonCtrl"
              name="motivoCancelamento"
              matTextareaAutosize
              matAutosizeMinRows="1"
              matAutosizeMaxRows="5"
              required
            ></textarea>
            <mat-error *ngIf="cancelTermsForm.controls['motivoCancelamentoCtrl'].hasError('required')">
              Informe o motivo do cancelamento do termo de serviço.
            </mat-error>
          </mat-form-field>
          <div class="text-right">
            <button mat-raised-button class="btn" color="warn" type="button" (click)="onVoltarMinhaConta()">
              Voltar
            </button>
            &nbsp;<button
              mat-raised-button
              class="btn"
              color="accent"
              type="submit"
              [disabled]="!cancelTermsForm.valid"
              *ngIf="!isProcessing"
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- <div *ngIf="isCreateNewUser">
    <div>
      <h1>Cadastrar Dados de acesso</h1>
    </div>
    <form [formGroup]="createNewUserForm">
      <div class="form">
        <div class="mb-2 d-flex align-items-center">
          <mat-form-field color="primary">
            <input matInput type="email" placeholder="Seu e-mail" formControlName="userEmailCtrl" name="userEmail"
              required readonly>
            <mat-error *ngIf="createNewUserForm.controls['userEmailCtrl'].hasError('required')">
              Informe seu e-mail.</mat-error>
            <mat-error *ngIf="createNewUserForm.controls['userEmailCtrl'].hasError('email')">
              O e-mail deve ser válido.</mat-error>
          </mat-form-field>
        </div>

        <div class="mb-2 d-flex align-items-center">
          <mat-form-field color="primary">
            <input matInput type="password" placeholder="Digite uma senha" formControlName="passwordCtrl"
              required name="password">
            <mat-error *ngIf="createNewUserForm.controls['passwordCtrl'].hasError('required')">
              Informe uma senha de acesso.</mat-error>
            <mat-error *ngIf="createNewUserForm.controls['passwordCtrl'].hasError('minlength')">
              A senha deve ter 6 ou mais caracteres.</mat-error>
          </mat-form-field>
        </div>

        <div class="mb-2 d-flex align-items-center">
          <mat-form-field color="primary">
            <input matInput type="password" placeholder="Confirme a senha" formControlName="confirmPasswordCtrl"
              required name="confirmPassword">
            <mat-error *ngIf="createNewUserForm.controls['confirmPasswordCtrl'].hasError('required')">
              Confirme a senha criada.
            </mat-error>
            <mat-error *ngIf="createNewUserForm.controls['confirmPasswordCtrl'].hasError('mismatch')">
              Confirmação da senha não confere.
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <button mat-raised-button color="primary" class="action-button" type="submit" [class.disabled]="createNewUserForm.invalid"
            *ngIf="!isProcessing" [disabled]="createNewUserForm.invalid" (click)="createNewUser()">Salvar</button>
          <div class="text-center">
            <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
          </div>
        </div>
      </div>
    </form>
  </div> -->
</div>
