import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Banker } from 'functions/src/models/Banker';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { BankerFilter } from '../../../../../functions/src/models/common/BankerFilter';
import { OppFilter } from '../../../../../functions/src/models/common/OppFilter';
import { BankerService } from '../../services/banker.service';
import { OpportunityService } from '../../services/opportunity.service';
import { BankerNote } from './../../../../../functions/src/models/Notes';

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss'],
})
export class BankerOpportunitiesComponent implements OnInit, OnDestroy {
  opportunities: any[] = [];

  banker: Banker;
  bankerSubscription: Subscription;
  institutionName: string;
  // Control
  isLoadingResults = true;

  displayedColumns: string[] = [
    'companyName',
    'cnpj',
    'createdAt',
    'creditValue',
    'revenue',
    // 'score',
    'status',
    // 'actions',
  ];
  dataSource: MatTableDataSource<any>;

  selectedFilter = 'companyName';
  currentFilterValue = '';
  filterValue = '';

  // Pagination
  pageLength: PageEvent['length'] = 0;
  pageSize: PageEvent['pageSize'] = 25;
  pageSizeOptions: number[] = [10, 25, 40, 100];
  currentPage = 0;

  // Sorting
  sortField = 'lastInteraction';
  sortDirection: MatSortable['start'] = 'desc';


  minRevenue: number;
  maxRevenue: number;

  private paginator: MatPaginator;
  @ViewChild('matSort', { static: true }) matSort: MatSort;
  @ViewChild('paginator', { static: false }) set matPaginator(mp1: MatPaginator) {
    this.paginator = mp1;
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private bankerService: BankerService,
    private dialog: MatDialog,
    private opportunityService: OpportunityService
  ) {
    this.dataSource = new MatTableDataSource([]);

  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.isLoadingResults = true;
    this.bankerService.redirectAccordingStatus('/bankers/opportunities', '/bankers/opportunities');

    this.bankerSubscription = this.bankerService.banker.subscribe((banker) => {
      this.banker = banker;
      this.minRevenue = this.banker.revenueRange?.minRevenue || null;
      this.maxRevenue = this.banker.revenueRange?.maxRevenue || null;

      if (this.banker?.uid) {
        if (this.banker.institution?.name) {
          this.bankerService
            .getCreditInstitutionName(this.banker.institution?.name)
            .then((name) => {
              this.institutionName = name;
              this.isLoadingResults = true;

              const bankerFilter: Partial<BankerFilter> = {};
              bankerFilter.bank = this.banker.institution.name;
              bankerFilter.banker = this.banker.uid;
              bankerFilter.filterField = this.selectedFilter;
              bankerFilter.filterValue = this.currentFilterValue;
              bankerFilter.isRegionalized = this.banker.isRegionalized || false;
              bankerFilter.minRevenue = this.banker?.revenueRange?.minRevenue || null;
              bankerFilter.maxRevenue = this.banker?.revenueRange?.maxRevenue || null;
              bankerFilter.typeSearch = 'actived';

              this.applyFilter(bankerFilter);
            })
            .catch((err) => {
              console.error('Error getting institution name', err);
              this.institutionName = undefined;
            });
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.bankerSubscription) {
      this.bankerSubscription.unsubscribe();
    }
  }

  async createNote(op: any): Promise<void> {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Enviar mensagem',
          alertDescription: 'Deseja realmente enviar essa mensagem?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const note: BankerNote = {
            message: op.newMessage,
            type: 'BANKER',
            banker: this.banker.uid,
            createdBy: {
              name: this.banker.name,
              email: this.banker.email,
            },
          };

          this.opportunityService
            .createNote(op.uid, this.banker.institution?.name, note, {
              companyName: op.companyName,
              institution: this.institutionName,
            })
            .then(() => {
              // clear newNote
              op.newMessage = '';

              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Mensagem enviada',
                  alertDescription: 'A mensagem foi enviada com sucesso.',
                  isOnlyConfirm: true,
                },
              });
            })
            .catch((err) => {
              console.error('Error creating note', err);

              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao enviar',
                  alertDescription: 'Não foi possível enviar a mensagem. Tente novamente mais tarde.',
                  isOnlyConfirm: true,
                },
              });
            });
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  applyFilter(value: Partial<OppFilter>): void {
    const bankerFilter: Partial<BankerFilter> = value;
    bankerFilter.bank = this.banker.institution.name;
    bankerFilter.banker = this.banker.uid;
    bankerFilter.isRegionalized = this.banker.isRegionalized || false;
    bankerFilter.typeSearch = 'actived';

    this.isLoadingResults = true;

    this.opportunityService
      .searchOpportunities(value)
      .then((results) => {
        this.opportunities = results.map((op) => ({
          ...op,
          newMessage: '',
        }));

        this.pageLength = results.length; // Atualize o comprimento total da página
        this.dataSource.data = this.opportunities;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.matSort;
        this.isLoadingResults = false;
      })
      .catch((err) => {
        console.error('Error getting opportunities of banker.', err);
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '700px',
            data: {
              alertTitle: 'Erro ao buscar oportunidades',
              alertDescription: `Ocorreu um erro ao buscar suas oportunidades. Por favor, tente novamente mais tarde.`,
              isOnlyConfirm: true,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.isLoadingResults = false;
          });
      });
  }

  sortData(event: Sort): void {
    this.sortDirection = event.direction || 'asc';
    this.sortField = event.active;
    this.currentPage = 0;
    this.dataSource.data = _.orderBy(this.opportunities, [this.sortField], [this.sortDirection]);
  }
}
