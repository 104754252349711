import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import 'hammerjs';
import { ChartistModule } from 'ng-chartist';
import { ChartsModule } from 'ng2-charts';
import { ChatTemplateComponent } from '../chat/chat-template.component';
import { AssignedComponent } from '../chat/components/assigned/assigned.component';
import { AudioPlayerComponent } from '../chat/components/audio-player/audio-player.component';
import { ChatAttributedItemComponent } from '../chat/components/chat-attributed-item/chat-attributed-item.component';
import { ChatCustomerAttributedComponent } from '../chat/components/chat-customer-attributed/chat-customer-attributed.component';
import { ChatMenuComponent } from '../chat/components/chat-menu/chat-menu.component';
import { ContactItemComponent } from '../chat/components/contact-item/contact-item.component';
import { MessageListComponent } from '../chat/components/message-list/message-list.component';
import { ContainerAssignedToMeComponent } from '../chat/containers/container-assigned-to-me/container-assigned-to-me.component';
import { ContainerColaboratorComponent } from '../chat/containers/container-colaborator/container-colaborator.component';
import { ContainerContactsComponent } from '../chat/containers/container-contacts/container-contacts.component';
import { ContainerNoAssignedComponent } from '../chat/containers/container-no-assigned/container-no-assigned.component';
import { MessagesComponent } from '../chat/containers/messages/messages.component';
import { CreditRecurrenceComponent } from '../components/credit-recurrence/credit-recurrence.component';
import { SharedModule } from '../components/shared.module';
import { AdminRoutes } from './admin-routing.module';
import { BigdatacorpComponent } from './bigdatacorp/bigdatacorp.component';
import { CadastrosGeraisComponent } from './cadastros-gerais/cadastros-gerais.component';
import { EmailGroupComponent } from './cadastros-gerais/components/email-group/email-group.component';
import { AgentNotesComponent } from './components/agent-notes/agent-notes.component';
import { BankerNotesComponent } from './components/banker-notes/banker-notes.component';
import { ContactFormComponentComponent } from './components/contact-form-component/contact-form-component.component';
import { CustomerAttributedComponent } from './components/customer-attributed/customer-attributed.component';
import { CustomerInfoCardComponent } from './components/customer-info-card/customer-info-card.component';
import { CustomerScreeningModalComponent } from './components/customer-screening-modal/customer-screening-modal.component';
import { MenuItems } from './components/menu-items/menu-items';
import { OpportunityStreamDialogComponent } from './components/opportunity-stream-dialog/opportunity-stream-dialog.component';
import { RecurrenceAdminDialogComponent } from './components/recurrence-admin/recurrence-admin-dialog.component';
import { AccountantReportsComponent } from './containers/accountant-reports/accountant-reports.component';
import { AdminCreateCustomerComponent } from './containers/admin-create-customer/admin-create-customer.component';
import { AgentCreateComponent } from './containers/agent-create/agent-create.component';
import { AgentEditComponent } from './containers/agent-edit/agent-edit.component';
import { AgentReportsComponent } from './containers/agent-reports/agent-reports.component';
import { AgentViewComponent } from './containers/agent-view/agent-view.component';
import { AgentsComponent } from './containers/agents/agents.component';
import { BackupsComponent } from './containers/backups/backups.component';
import { BankerCreateComponent } from './containers/banker-create/banker-create.component';
import { BankerEditComponent } from './containers/banker-edit/banker-edit.component';
import { BankerViewComponent } from './containers/banker-view/banker-view.component';
import { BankersListComponent } from './containers/bankers-list/bankers-list.component';
import { BankersReportsComponent } from './containers/bankers-reports/bankers-reports.component';
import { CustomerOpportunityComponent } from './containers/customer-opportunity/customer-opportunity.component';
import { CustomerReportsComponent } from './containers/customers-reports/customer-reports.component';
import { PartnerBannerFileUploadComponent } from './containers/partner-banner-file-upload/partner-banner-file-upload.component';
import { PartnerEditComponent } from './containers/partner-edit/partner-edit.component';
import { PartnerLogoFileUploadComponent } from './containers/partner-logo-file-upload/partner-logo-file-upload.component';
import { PartnershipsRegisterComponent } from './containers/partnerships-register/partnerships-register.component';
import { PartnershipsViewComponent } from './containers/partnerships-view/partnerships-view.component';
import { SettingsComponent } from './containers/settings/settings.component';
import { CustomerContactComponent } from './customer-contact/customer-contact.component';
import { CustomerScreeningComponent } from './customer-screening/customer-screening.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GrupoInstituicaoFinanceiraComponent } from './grupo-instituicao-financeira/grupo-instituicao-financeira.component';
import { InstituicaoFinanceiraComponent } from './instituicao-financeira/instituicao-financeira.component';
import { OpenFinanceAdminComponent } from './open-finance-admin/open-finance-admin.component';
import { OppListComponent } from './opp-list/opp-list.component';
import { RemuneracaoUtmComponent } from './remuneracao-utm/remuneracao-utm.component';
import { TipoDocumentoComponent } from './tipo-documento/tipo-documento.component';
import { UserChargeComponent } from './user-charge/user-charge.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserListComponent } from './user-list/user-list.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ChartistModule,
    ChartsModule,
    RouterModule.forChild(AdminRoutes),
  ],
  declarations: [
    DashboardComponent,
    CadastrosGeraisComponent,
    InstituicaoFinanceiraComponent,
    TipoDocumentoComponent,
    GrupoInstituicaoFinanceiraComponent,
    CustomerReportsComponent,
    UserListComponent,
    UserDetailComponent,
    AgentsComponent,
    AgentViewComponent,
    AgentCreateComponent,
    AgentEditComponent,
    BankersListComponent,
    BankerCreateComponent,
    BankerEditComponent,
    BankerViewComponent,
    AgentReportsComponent,
    AccountantReportsComponent,
    AgentNotesComponent,
    BankerNotesComponent,
    BankersReportsComponent,
    AdminCreateCustomerComponent,
    BackupsComponent,
    SettingsComponent,
    CustomerOpportunityComponent,
    BigdatacorpComponent,
    UserChargeComponent,
    OppListComponent,
    EmailGroupComponent,
    CreditRecurrenceComponent,
    CustomerContactComponent,
    RemuneracaoUtmComponent,
    CustomerScreeningComponent,
    CustomerScreeningModalComponent,
    ContactFormComponentComponent,
    ChatTemplateComponent,
    MessagesComponent,
    AssignedComponent,
    ContainerAssignedToMeComponent,
    ContainerNoAssignedComponent,
    ContainerContactsComponent,
    ContainerColaboratorComponent,
    ChatMenuComponent,
    AudioPlayerComponent,
    PartnershipsRegisterComponent,
    PartnershipsViewComponent,
    PartnerLogoFileUploadComponent,
    PartnerBannerFileUploadComponent,
    PartnerEditComponent,
    ContactItemComponent,
    MessageListComponent,
    ChatAttributedItemComponent,
    ChatCustomerAttributedComponent,
    CustomerAttributedComponent,
    CustomerInfoCardComponent,
    OpenFinanceAdminComponent,
    OpportunityStreamDialogComponent,
    RecurrenceAdminDialogComponent
  ],
  providers: [
    MenuItems,
    DatePipe
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // }
  ],
})
export class AdminModule { }
