<div class="flex-container">
  <div class="d-flex align-items-start mb-2">
    <h1 class="flex-grow-1">O que é o Score?</h1>
    <button mat-icon-button (click)="dialogRef.close()">
      <mat-icon style="color: rgb(59, 59, 59)">close</mat-icon>
    </button>
  </div>
  <div>
    <p>
      Score é uma “pontuação” de 0 a 1000, reflexo dos dados do comportamento consumidor de cada empresa e sua
      capacidade de pagamento. É uma das métricas utilizadas pelas instituições financeiras para analisar pedidos de
      crédito.
    </p>
    <p>Saiba mais em nosso blog:</p>
    <ul>
      <li>
        <a
          href="https://www.capitalempreendedor.com.br/blog/pontuacao-do-score-de-credito"
          target="_blank"
          class="link"
        >
          Como funciona o score
        </a>
      </li>
      <li>
        <a
          href="https://www.capitalempreendedor.com.br/blog/como-consultar-o-score-de-credito-da-minha-empresa"
          target="_blank"
          class="link"
        >
          Como consultar seu score
        </a>
      </li>
    </ul>
  </div>
</div>
