<div class="chat-container" *ngIf="customer && banker">
  <div class="div-chat div-note-top">
    <h4>Chat com a Capital</h4>
  </div>
  
  <div class="div-chat div-note-records" id="div-scroll" *ngIf="notes">
    <mat-card *ngFor="let note of notes; let i = index" class="note" [class.admin-note]="note.type === 'ADMIN'"
      [class.banker-note]="note.type === 'BANKER'">
       <button *ngIf="note.type === 'BANKER'" mat-icon-button [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu" style="margin-left: 70%;">
          <mat-icon>more_horiz</mat-icon>
        </button>
      <mat-card-content>
        <div class="sender-label" *ngIf="note.type === 'ADMIN'">
          <span>{{ note.createdBy.name }}:</span>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item class="mat-menu" (click)="deleteNote(note)">
            <mat-icon>delete_outline</mat-icon>
            <span>Deletar mensagem</span>
          </button>
        </mat-menu>
        <div style="white-space: pre-line">
          {{ note.message }}
        </div>
        <div *ngIf="note.attachments?.length">
          <a style="color: blue" href="{{ attachment.contentUrl }}" *ngFor="let attachment of note.attachments">{{
            attachment.fileName
            }}</a>
        </div>
        <mat-card-footer class="text-right">
          <span class="date-label">{{ note.createdAt?.toDate() | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
        </mat-card-footer>
      </mat-card-content>
    </mat-card>
    <div class="text-center" *ngIf="isLoadingNotes">
      <mat-spinner diameter="60" class="mx-auto"></mat-spinner>
    </div>
  </div>
  
  <div class="div-chat div-note-blank"></div>
  <div class="input-wrapper">
    <div class="" id="new-message-area">
      <textarea placeholder="Nova mensagem" ngDefaultControl [(ngModel)]="message" rows="1">
      </textarea>
    </div>
    <div class="" id="send-button">
      <button matInput id="send-message-button" *ngIf="!isLoadingNotes" [disabled]="!message" (click)="createNote()">
        <mat-icon style="color: #ffffff">send</mat-icon>
      </button>
    </div>
  </div>
  </div>