<div class="container-fluid">
  <mat-card>
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <span style="flex: 1">Backups</span>
      </mat-card-title>

      <div class="row">
        <div class="col-12 mt-4">
          <h4>Novo backup</h4>

          <div>
            <form [formGroup]="createBackupFormGroup" (submit)="onSave()">
              <mat-form-field style="width: 100%">
                <mat-label>Coleções</mat-label>
                <mat-select
                  placeholder="Coleções"
                  formControlName="collectionsCtrl"
                  name="collections"
                  color="accent"
                  multiple
                  required
                >
                  <mat-option *ngFor="let collection of collectionsOptions" [value]="collection">
                    {{ collection }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="text-right">
                <button mat-raised-button color="primary" type="submit" *ngIf="!isProcessing">
                  <mat-icon>check</mat-icon>
                  &nbsp;Iniciar backup
                </button>
                <button mat-button type="button" readonly *ngIf="isProcessing">
                  <mat-spinner [diameter]="40" color="primary"></mat-spinner>
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="col-12 mt-5">
          <div class="d-flex mb-3">
            <h4 style="flex: 1">Backups realizados</h4>
            <div>
              <button mat-stroked-button (click)="loadBackups()"><mat-icon>refresh</mat-icon> Atualizar</button>
            </div>
          </div>

          <div>
            <div class="w-100 p-5" *ngIf="isLoadingBackups">
              <mat-spinner *ngIf="isLoadingBackups" [diameter]="80" class="mx-auto" color="primary"></mat-spinner>
            </div>

            <div *ngIf="!isLoadingBackups && backupsList?.length === 0">
              <p class="text-center">Nenhum backup encontrado.</p>
            </div>

            <div class="mat-elevation-z3" *ngIf="!isLoadingBackups && backupsList?.length > 0">
              <div class="row" style="padding: 12px 24px; font-weight: bold">
                <span class="col-7">Data e hora de início</span>
                <span class="col-5 row">
                  <span class="col-6">Tipo</span>
                  <span class="col-6">Status</span>
                </span>
              </div>
              <mat-accordion multi *ngFor="let backup of backupsList">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="col-7">
                      <mat-icon
                        style="font-size: 16px !important; display: flex; align-items: center; margin-right: 12px"
                        [style.color]="backup.statusIcon?.color"
                      >
                        {{ backup.statusIcon?.name }}
                      </mat-icon>
                      {{ backup.triggeredAt }}
                    </mat-panel-title>
                    <mat-panel-description class="col-5">
                      <span class="col-6">
                        {{ backup.triggerTypeLabel }}
                      </span>
                      <span class="col-6">
                        {{ backup.statusLabel }}
                      </span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <div>
                    <h5>Coleções</h5>
                    <div class="ml-4">
                      <mat-list *ngFor="let collection of backup.collectionsItems">
                        <mat-list-item>
                          <mat-icon
                            style="
                              font-size: 12px;
                              display: flex;
                              align-items: center;
                              margin-right: 8px;
                              margin-left: -16px;
                            "
                            [style.color]="collection.icon?.color"
                            >{{ collection.icon?.name }}</mat-icon
                          >
                          {{ collection.name }}
                        </mat-list-item>
                      </mat-list>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
