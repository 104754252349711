import { Routes } from '@angular/router';
import { AuthGuardBankers } from '../core/auth/auth.guard';
import { PrivacyPolicyComponent } from '../customer/containers/privacy-policy/privacy-policy.component';
import { BankerConcludedListComponent } from './containers/concluded-list/concluded-list.component';
import { BankerOpportunitiesComponent } from './containers/opportunities/opportunities.component';
import { BankerOpportunityComponent } from './containers/opportunity/opportunity.component';
import { BankerProfileComponent } from './containers/profile/profile.component';

export const BankersRoutes: Routes = [
  {
    path: 'login',
    redirectTo: '/entrar',
  },
  {
    path: 'profile',
    component: BankerProfileComponent,
    data: { title: 'Operadores - Perfil' },
    canActivate: [AuthGuardBankers],
  },
  {
    path: 'opportunities',
    component: BankerOpportunitiesComponent,
    data: { title: 'Operadores - Oportunidades em aberto' },
    canActivate: [AuthGuardBankers],
  },
  {
    path: 'concluded',
    component: BankerConcludedListComponent,
    data: { title: 'Operadores - Oportunidades ganhas' },
    canActivate: [AuthGuardBankers],
  },
  // {
  //   path: 'dashboard',
  //   component: BankerDashboardComponent,
  //   data: { title: 'Assessores - Dashboard' },
  //   canActivate: [AuthGuardBankers]
  // },
  {
    path: 'opportunity/:id',
    component: BankerOpportunityComponent,
    data: { title: 'Operadores - Oportunidade' },
    canActivate: [AuthGuardBankers],
  },
  // {
  //   path: 'rejected',
  //   component: BankerRejectedListComponent,
  //   data: { title: 'Operadores - Oportunidades rejeitadas' },
  //   canActivate: [AuthGuardBankers]
  // },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: { title: 'Aceitar Política de Privacidade' },
    canActivate: [AuthGuardBankers],
  },
  {
    path: '**',
    redirectTo: '/entrar',
  },
];
