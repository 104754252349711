<div class="container-fluid row opportunity-container" style="background-color: white; padding: 0px">
  <mat-card *ngIf="isLoadingCustomer || isLoadingContract">
    <mat-card-content>
      <section class="my-6 text-center">
        <mat-spinner [diameter]="60" color="accent" class="d-inline-block mx-3"></mat-spinner>
      </section>
    </mat-card-content>
  </mat-card>

  <div class="col-sm-8" style="background-color: white; padding: 0px">
    <mat-card *ngIf="hasCustomerError || hasContractError">
      <mat-card-content>
        <mat-card-title class="d-flex align-items-center">
          <a mat-mini-fab color="accent" [routerLink]="
              !hasCustomerError && isRejected
                ? '/bankers/rejected'
                : isConcluded
                ? '/bankers/concluded'
                : '/bankers/opportunities'
            " matTooltip="Voltar à listagem" class="mr-3">
            <mat-icon>chevron_left</mat-icon>
          </a>
          <span>Oportunidade: Erro</span>
        </mat-card-title>
        <section class="my-6">
          Ocorreu um erro ao obter os dados da oportunidade. Por favor, tente novamente mais tarde.
        </section>
      </mat-card-content>
    </mat-card>

    <mat-card
      *ngIf="!isLoadingCustomer && !isLoadingContract && !hasCustomerError && !hasContractError && customer && contract"
      style="background-color: white; padding: 4px">
      <mat-card-content class="opportunity-content">
        <mat-card-title class="d-flex align-items-center">
          <a [routerLink]="
              isRejected ? '/bankers/rejected' : isConcluded ? '/bankers/concluded' : '/bankers/opportunities'
            " matTooltip="Voltar à listagem" class="mr-5">
            <img src="../../../../assets/images/icons/arrow-left.png" style="width: 12px" />
          </a>
          <span #targetOpp style="color: #008348;">Oportunidade: {{ customer?.companyName?.toUpperCase() }}</span>
        </mat-card-title>

        <mat-card class=" mat-card-content mat-card-progress-bar container" style="margin: 10px auto"
          *ngIf="oppLastUpdate && opportunity">
          <mat-card-content style="padding: 0px;">
            <app-progress-bar [status]="opportunity.stageOpportunity.defaultprobability" [oppLastUpdate]="oppLastUpdate"
              style="padding: 0px;"></app-progress-bar>
          </mat-card-content>
        </mat-card>

        <div style="overflow: hidden">
          <mat-tab-group>
            <mat-tab id="dadosDoCliente">
              <ng-template mat-tab-label> Dados do cliente </ng-template>
              <mat-card *ngIf="!isRejected" style="background-color: rgb(255, 255, 255); padding: 4px">
                <mat-card-content class="mat-card-content">
                  <div class="container" style="margin: 0">
                    <div class="align-top">
                      <mat-card class="mat-card-client-data"
                        style="margin: 20px 0 20px 0 !important; background-color: #F4F4F4;">
                        <mat-card-content>
                          <div class="" style="margin: 20px 0 20px 0 !important">
                            <span style="color: #008348; font-size: 24px; font-weight: 600;">Dados do cliente</span>
                            <div class="row" style="max-width: auto; margin-top: 20px">

                              <div class="col-12 col-lg-4 col-xl-6">
                                <p><strong>Razão Social:</strong> {{ customer?.companyName?.toUpperCase() || '-' }}</p>
                                <p>
                                  <strong>Faturamento anual:</strong>
                                  {{ utilHandler.getFormattedPrice(customer?.revenue) || '-' }}
                                </p>
                                <p><strong>E-mail:</strong> <br><span style="font-size: small">{{ customer?.email || '-'
                                    }}</span> </p>
                                <p><strong>Dívida atual informada:</strong> {{ customer?.debtStr || '-' }}</p>
                                <p><strong>Telefone:</strong> {{ customer?.phone || '-' }}</p>
                                <p><strong>Garantias:</strong>{{ guarantees.length > 0 ? '' : '-' }}</p>
                                <ul *ngIf="guarantees.length">
                                  <li *ngFor="let guarantee of guarantees" style="font-size: small">{{ guarantee }}</li>
                                </ul>
                                <p></p>
                                <p>
                                  <strong>Recebíveis:</strong>{{ customer?.typeOfAnticipation > 0 ? '' :
                                  '-' }}
                                </p>
                                <p><strong>CPF do responsável:</strong> {{ customer?.cpf || '-' }}</p>
                                <p>
                                  <strong>Endereço:</strong> {{ customer.address?.street || '-' }},
                                  {{ customer.address?.number || '-' }}
                                </p>
                                <p><strong>Bairro:</strong> {{ customer.address?.neighborhood || '-' }}</p>
                                <p><strong>Estado:</strong> {{ customer.address?.state || '-' }}</p>

                              </div>
                              <div class="col-12 col-lg-4 col-xl-6">
                                <p><strong>CNPJ:</strong> {{ customer?.cnpj || '-' }}</p>
                                <p><strong>WhatsApp:</strong> {{ customer?.whatsapp || '-' }}</p>
                                <p><strong>Valor solicitado:</strong> {{ customer?.creditValueStr || '-' }}</p>
                                <p>
                                  <strong>Tempo de atividade:</strong>
                                  {{
                                  customer?.companyInformation?.age
                                  ? customer?.companyInformation?.age +
                                  (customer?.companyInformation?.age > 1 ? ' anos' : ' ano')
                                  : '-'
                                  }}
                                </p>
                                <p><strong>Finalidade do crédito:</strong> {{ customer?.purpose?.name || '-' }}</p>
                                <app-bndes-score *ngIf="customer"
                                  [proposalId]="customer?.proposalId || null"></app-bndes-score>
                                <p>
                                  <strong>Valor total das garantias:</strong>
                                  {{ customer?.guaranteesValueStr || '-' }}
                                </p>
                                <p><strong>Nome do responsável:</strong> {{ customer.name.toUpperCase() || '-' }}</p>
                                <p><strong>CEP:</strong> {{ customer.address?.zipCode || '-' }}</p>
                                <p><strong>Complemento:</strong> {{ customer.address?.extraInfo || '-' }}</p>
                                <p><strong>Cidade:</strong> {{ customer.address?.city || '-' }}</p>
                                <p>
                                  <strong>Oportunidade criada em:</strong>
                                  {{ customer.termsSignatureDate?.toDate().toLocaleString() || '-' }}
                                </p>
                              </div>

                              <div style="max-width: 30%; margin: 20px 20px 20px 15px !important"
                                *ngIf="status === 'Aguardando proposta da instituição'">
                                <button mat-flat-button
                                  style="background-color: #00a659; color: white; font-size: 20px; padding: 8px"
                                  (click)="selectTab('credito')">
                                  Fazer uma proposta
                                </button>
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                      <div class="bndes-card-wrapper">
                        <app-bndes-card [customer]="customer"></app-bndes-card>
                      </div>

                      <h3 style="color: #FF8641; ">Open Finance ⤵</h3>

                      <hr style="background-color: #FF8641" class="mb-3" />

                      <mat-accordion>
                        <mat-expansion-panel class="expansion-panel">
                          <mat-expansion-panel-header>
                            <mat-panel-title>Empréstimos e dívidas</mat-panel-title>
                          </mat-expansion-panel-header>

                          <app-open-finance [uid]="customer.uid"></app-open-finance>

                        </mat-expansion-panel>
                      </mat-accordion>

                      <app-serasa-card [expanded]="false" [uid]="customer.uid"></app-serasa-card>

                      <app-data-aggregator [customer]="customer"
                        (viewDataClicked)="selectTab('dadosExtras')"></app-data-aggregator>
                      <div class="col-12 text-center my-2 decline-opportunity" *ngIf="!isClosed"
                        style="overflow: hidden; font-size: 0.9em">
                        <p>Esta oportunidade não se enquadra no perfil da sua instituição?</p>
                        <button mat-flat-button style="background-color: #ec3d32; color: white; font-size: 14px"
                          (click)="rejectOpportunity()">
                          Rejeitar oportunidade
                        </button>
                      </div>
                    </div>
                    </div>
                  </mat-card-content>
                </mat-card>

                <!-- <div class="bndes-card-wrapper">
                  <app-bndes-card [customer]="customer"></app-bndes-card>
                </div>

                <div class="col-12 text-center my-2" *ngIf="!isClosed" style="overflow: hidden; font-size: 0.9em">
                  <p>Esta oportunidade não se enquadra no perfil da sua instituição?</p>
                  <button
                    mat-flat-button
                    style="background-color: #ec3d32; color: white; font-size: 14px"
                    (click)="rejectOpportunity()"
                  >
                    Rejeitar oportunidade
                  </button>
                </div> -->
            </mat-tab>
            <mat-tab id="proposta">
              <ng-template mat-tab-label> Proposta de crédito </ng-template>
              <mat-card *ngIf="!isRejected" class="mat-card-wrapper">
                <mat-card-content class="mat-card-content ">
                  <div class="container" style="margin: 0">
                    <div class="align-top">
                      <mat-card class="mat-card-client-data">
                        <mat-card-content>
                          <mat-card-header class="card-container-center">
                            <mat-card-title>Instruções!</mat-card-title>
                          </mat-card-header>
                          <span class="message-text">
                            <ul id="instructions">
                              <li>
                                Caso a sua instituição possa oferecer várias opções de prazo e/ou taxa,
                                favor informar nos campos abaixo os
                                <b style="color: #FF8641;">dados referentes à proposta de maior prazo</b>
                              </li>
                              <li>Favor informar no campo <b>Descrição</b> a(s) garantia(s) referente(s) à proposta</li>
                              <li><b style="color: #FF8641;">As demais opções de prazo ou taxa</b> podem também ser informadas no campo <b>Descrição</b></li>
                              <li>Caso tenha um <b style="color: #FF8641;">arquivo de simulação da oportunidade</b> pronta para enviar, por favor insira os dados dele
                                nos campos de <b>Detalhes da proposta</b> e anexe o arquivo no campo de <b>Submeter simulação</b></li>
                            </ul>
                          </span>
                        </mat-card-content>
                      </mat-card>

                      <div *ngIf="status !== 'Crédito contratado'">
                        <mat-card class="mat-card-client-custom">
                          <mat-card-content>
                            <mat-card-header><mat-card-title> Detalhes da proposta</mat-card-title></mat-card-header>
                            <div class="mx-auto form-proposal-details">
                              <p>

                                  <mat-label>Taxa (% a.m.)</mat-label>
                                  <input matInput type="number" placeholder="Taxa (%)" [value]="proposal.interest"
                                    name="proposalInterest" [(ngModel)]="proposal.interest" required #interest="ngModel" (input)="isSubmitButtonEnabled()"
                                    />
                                  <mat-error *ngIf="interest.errors?.required" style="padding-bottom: 8px">A taxa é
                                    obrigatória</mat-error>
                                  <mat-error *ngIf="proposal.interest < 0">A taxa é negativa</mat-error>

                              </p>

                              <p>

                                  <mat-label>Prazo (meses)</mat-label>
                                  <input matInput type="number" placeholder="Prazo (meses)" [value]="proposal.term"
                                    name="proposalTerm" [(ngModel)]="proposal.term" required
                                    style="padding-top: 8px; margin-top: 8px; padding-bottom: 8px" #term="ngModel" (input)="isSubmitButtonEnabled()" />
                                  <mat-error *ngIf="term.errors?.required">O prazo é obrigatório</mat-error>
                                  <mat-error *ngIf="proposal.term < 0">O prazo é negativo</mat-error>

                              </p>


                                  <mat-label>Limite da Operação (R$)</mat-label>
                                  <input matInput type="number" placeholder="Limite da Operação"
                                    [value]="proposal.amount" name="proposalAmount" #amount="ngModel"
                                    [(ngModel)]="proposal.amount" required
                                    style="padding-top: 8px; margin-top: 8px; padding-bottom: 8px" (input)="isSubmitButtonEnabled()" />
                                  <mat-error *ngIf="amount.errors?.required">O limite da operação é
                                    obrigatório</mat-error>
                                  <mat-error *ngIf="proposal.amount < 0">O limite da operação é negativo</mat-error>



                              <p>
                                <mat-label>Descrição</mat-label>
                                  <textarea matInput placeholder="Descrição" [(ngModel)]="proposal.description"
                                    name="proposalDescription" matTextareaAutosize matAutosizeMinRows="2"
                                    matAutosizeMaxRows="5"></textarea>

                              </p>
                              <div class="text-center" *ngIf="status !== 'Crédito contratado'">
                                <!-- <button mat-raised-button color="primary" type="button" (click)="updateProposal()"
                                  [disabled]="!canSubmitProposal()"> Salvar proposta
                                </button> -->
                              </div>
                            </div>



                            <mat-card-header class="card-container-center">
                              <mat-card-title *ngIf="!bankerContract">Submeter simulação</mat-card-title>
                              <mat-card-title *ngIf="bankerContract && !isSendingContractMode">Simulação
                                enviada</mat-card-title>
                            </mat-card-header>


                            <div class="col-12 col-lg-12 mb-2" *ngIf="!bankerContract || isSendingContractMode">
                              <!-- <file-upload [maximumDocuments]="1" [hasEmissionDate]="false"
                                (completedUpload)="updateCompletedUpload($event)"></file-upload> -->
                              <app-banker-file-upload [maximumDocuments]="1" [hasEmissionDate]="false"
                                (completedUpload)="updateCompletedUpload($event)"></app-banker-file-upload>

                              <div class="mx-auto my-3 text-center">
                                <button mat-raised-button color="warn" class="mx-1"
                                  (click)="isSendingContractMode = false" target="_blank" *ngIf="bankerContract">
                                  Voltar
                                </button>
                                <button mat-raised-button color="primary" (click)="onSubmitButton()"
                                  [disabled]="!canEnableSubmitButton"
                                  [ngClass]="{'disabled-button': !canEnableSubmitButton}">
                                  Enviar proposta
                                </button>
                              </div>
                            </div>

                          </mat-card-content>
                        </mat-card>
                        <div class="col-12 col-lg-12 mb-2" *ngIf="bankerContract && !isSendingContractMode">
                          <p class="text-center">
                            <strong>Data de submissão:</strong>
                            {{ bankerContract?.submission?.toDate().toLocaleString() || '-' }}
                          </p>

                          <div class="btn-container" style="text-align: center">
                            <a mat-raised-button class="m-1" color="accent" [href]="bankerContract.path" target="_blank"
                              style="font-size: small; height: 36px">
                              <span style="color: white"> Baixar </span>
                            </a>

                            <button mat-raised-button class="m-1" color="accent" (click)="isSendingContractMode = true"
                              target="_blank" *ngIf="status !== 'Crédito contratado'"
                              style="font-size: small; height: 36px">
                              Atualizar
                            </button>
                          </div>
                        </div>

                      </div>

                      <div *ngIf="status === 'Crédito contratado'">
                        <mat-card class="mat-card-client-data"><mat-card-content>
                            <mat-card-header><mat-card-title> Detalhes da proposta</mat-card-title></mat-card-header>

                            <div class="mx-auto form-proposal-details">
                              <p>
                                <mat-form-field color="accent" class="w-100 mb-2" appearance="outline">
                                  <mat-label>Taxa (% a.m.)</mat-label>
                                  <input disabled matInput type="number" placeholder="Taxa (%)"
                                    [value]="proposal.interest" name="proposalInterest" [(ngModel)]="proposal.interest"
                                    required #interest="ngModel"
                                    style="padding-top: 8px; padding-bottom: 8px; margin-top: 8px" />
                                  <mat-error *ngIf="interest.errors?.required" style="padding-bottom: 8px">A taxa é
                                    obrigatória</mat-error>
                                  <mat-error *ngIf="proposal.interest < 0">A taxa é negativa</mat-error>
                                </mat-form-field>
                              </p>

                              <p>
                                <mat-form-field color="accent" class="w-100 mb-2" appearance="outline">
                                  <mat-label>Prazo (meses)</mat-label>
                                  <input disabled matInput type="number" placeholder="Prazo (meses)"
                                    [value]="proposal.term" name="proposalTerm" [(ngModel)]="proposal.term" required
                                    style="padding-top: 8px; margin-top: 8px; padding-bottom: 8px" #term="ngModel" />
                                  <mat-error *ngIf="term.errors?.required">O prazo é obrigatório</mat-error>
                                  <mat-error *ngIf="proposal.term < 0">O prazo é negativo</mat-error>
                                </mat-form-field>
                              </p>

                              <p>
                                <mat-form-field color="accent" class="w-100 mb-2" appearance="outline">
                                  <mat-label>Limite da Operação (R$)</mat-label>
                                  <input disabled matInput type="number" placeholder="Limite da Operação"
                                    [value]="proposal.amount" name="proposalAmount" #amount="ngModel"
                                    [(ngModel)]="proposal.amount" required
                                    style="padding-top: 8px; margin-top: 8px; padding-bottom: 8px" />
                                  <mat-error *ngIf="amount.errors?.required">O limite da operação é
                                    obrigatório</mat-error>
                                  <mat-error *ngIf="proposal.amount < 0">O limite da operação é negativo</mat-error>
                                </mat-form-field>
                              </p>

                              <p>
                                <mat-form-field color="accent" class="w-80 mb-2" appearance="outline">
                                  <textarea disabled matInput placeholder="Descrição" [(ngModel)]="proposal.description"
                                    name="proposalDescription" matTextareaAutosize matAutosizeMinRows="2"
                                    matAutosizeMaxRows="5"></textarea>
                                </mat-form-field>
                              </p>
                            </div>
                          </mat-card-content></mat-card>

                        <mat-card class="mat-card-client-data"><mat-card-content>
                            <mat-card-header class="card-container-center">
                              <mat-card-title *ngIf="!bankerContract">Submeter simulação</mat-card-title>
                              <mat-card-title *ngIf="bankerContract && !isSendingContractMode">Simulação
                                enviada</mat-card-title>
                            </mat-card-header>

                            <div class="col-12 col-lg-12 mb-2" *ngIf="!bankerContract || isSendingContractMode">
                              <file-upload [maximumDocuments]="1" [hasEmissionDate]="false"
                                (completedUpload)="updateCompletedUpload($event)"></file-upload>

                              <div class="mx-auto my-3 text-center">
                                <button mat-raised-button color="warn" class="mx-1"
                                  (click)="isSendingContractMode = false" target="_blank" *ngIf="bankerContract">
                                  Voltar
                                </button>
                                <button mat-raised-button class="mx-1" color="primary" (click)="saveSimulation()"
                                  [disabled]="!completedUpload">
                                  Enviar simulação
                                </button>
                              </div>
                            </div>

                            <div class="col-12 col-lg-12 mb-2" *ngIf="bankerContract && !isSendingContractMode">
                              <p class="text-center">
                                <strong>Data de submissão:</strong>
                                {{ bankerContract?.submission?.toDate().toLocaleString() || '-' }}
                              </p>

                              <div class="text-center">
                                <a mat-raised-button class="d-inline-block mx-auto" color="primary"
                                  [href]="bankerContract.path" target="_blank">
                                  <span style="color: white"> Baixar simulação</span>
                                </a>
                                <button mat-raised-button class="d-block mx-auto my-3" color="accent"
                                  (click)="isSendingContractMode = true" target="_blank"
                                  *ngIf="status !== 'Crédito contratado'">
                                  Atualizar simulação
                                </button>
                              </div>
                            </div>
                          </mat-card-content></mat-card>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 class="finish-opportunity-text" style="color: #FF8641; text-align: center;">O processo desta
                      operação foi finalizado com sucesso?</h3>
                  </div>
                  <div class="mx-auto text-center conclude-opportunity" style="width: 100%;"
                    *ngIf="status !== 'Crédito contratado'">
                    <button mat-raised-button (click)="completeOpportunity()" style="font-size: 1rem">
                      Concluir Oportunidade
                    </button>
                  </div>
                </mat-card-content>
              </mat-card>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label> Documentação </ng-template>
              <div class="align-top"></div>
              <section class="align-top" *ngIf="!isRejected">
                <h2 style="color: black; margin-left: 30px; padding-top: 1px;">Status: <span
                    style="color:#008348; font-family: 'Montserrat'; font-weight: 600;">Documentação completa</span>
                </h2>
                <mat-card *ngIf="!isRejected" style="background-color: #F4F4F4; margin: 25px">


                  <mat-card-content class="documentation-card-custom">
                    <mat-card-title class="documentation-card-title">
                      <h3>Documentos</h3>
                    </mat-card-title>
                    <div *ngIf="documents?.length > 0" class="documentation-list">
                      <mat-accordion class="example-headers-align w-100">
                        <mat-expansion-panel *ngFor="let item of documents" class="document">
                          <mat-expansion-panel-header (click)="getDocumentInfo(item); verifyUploadStatus(item)">
                            <mat-panel-title class="mat-expansion-header-main">
                              {{ item.name || '-' }}
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="descricao">
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                              <mat-label class="label">{{ item.description || '-' }}</mat-label>
                            </div>
                          </div>
                          <div class="detalhes">
                            <div class="d-flex align-items-center flex-row" *ngIf="item.financialYear.id === 'sim'"
                              style="padding-top: 5px">
                              <div *ngIf="item.qtyFinancialYear > 0" class="div-nested-expansion-panel">
                                <mat-label class="label"> Anos de exercício:</mat-label>
                                <mat-accordion class="w-100">
                                  <mat-expansion-panel class="mt-2" *ngFor="let docs of selectedDocument"
                                    (click)="verifyUploadStatus(item, docs.year); getYearDocuments(docs.year)">
                                    <mat-expansion-panel-header>
                                      <mat-panel-title class="mat-expansion-header-secondary">
                                        {{ docs.year }}
                                      </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="mt-2">
                                      <div *ngIf="docs.documents?.length">
                                        <div class="attached-file" *ngIf="item?.fileInfo">
                                          <p class="file" *ngFor="let file of yearDocuments?.documents; let i = index">
                                            <a class="attached-file-link cursor-style" href="{{ file.path }}"
                                              target="_blank">
                                              {{ file.fileName ? file.fileName : item.name }}
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </mat-expansion-panel>
                                </mat-accordion>
                              </div>
                            </div>
                            <div *ngIf="item.financialYear.id === 'nao'">
                              <div>
                                <div class="attached-file" *ngIf="item?.fileInfo">
                                  <p class="file" *ngFor="let file of item.fileInfo; let i = index">
                                    <a class="attached-file-link cursor-style" [href]="file.path" target="_blank">
                                      {{ file.fileName ? file.fileName : item.name }}
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                    <div class="mx-auto my-3 text-center" *ngIf="documents?.length > 0">

                      <button style="background-color: #00C46C; border: none;" *ngIf="!isLoading" ><a (click)="downloadDocsOneByOne(documents)">Baixar documentos individualmente</a></button>
    
                      <button style="background-color: #00C46C; border: none;" *ngIf="!isLoading" ><a (click)="downloadAllDocsZip(documents)">Baixar todos os documentos em zip</a></button>
  
                      <mat-spinner class="mx-auto my-2" color="primary" *ngIf="isLoading" diameter="40"></mat-spinner>
                    </div>
                  </mat-card-content>
                </mat-card>
              </section>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label> Histórico </ng-template>
              <div class="align-top"></div>
              <div style="margin: 25px; padding-top: 10px">
                <mat-card class="gray-card ">
                  <mat-card-header>
                    <mat-card-title class="gray-card-title">Operações anteriores</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div *ngIf="previousProposal">
                      <mat-accordion *ngFor="let proposal of previousProposal">
                        <mat-expansion-panel class="expansion-panel-gray" (opened)="panelOpenState = true"
                          (closed)="panelOpenState = false">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              {{proposal.label}}
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <mat-panel-description class="expansion-panel-description">
                            <div *ngFor="let proposalOpportunity of proposal.opportunities">
                              <h3>Detalhes da proposta:</h3>
                              <p>Taxa: {{proposalOpportunity.rate}}%</p>
                              <p>Prazo: {{proposalOpportunity.deadlineForMonth}} meses</p>
                              <p>Limite da operação: R${{proposalOpportunity.limitOperation}}</p>
                              <p>Detalhes: {{proposalOpportunity.description}}</p>
                            </div>
                          </mat-panel-description>
                        </mat-expansion-panel>
                      </mat-accordion>

                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>Dados extras</ng-template>
              <mat-card class="align-top extra-data-gray-container">
                <mat-card-content>
                  <mat-card-title>
                    <h3>Dados extras sobre o cliente</h3>
                  </mat-card-title>
                  <mat-card-content>
                    <mat-accordion>
                      <mat-expansion-panel class="inside-panel-container">
                        <mat-expansion-panel-header>
                          <mat-panel-title>Open Finance</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div >
                          <mat-expansion-panel class="expansion-panel">
                            <mat-expansion-panel-header>
                              <mat-panel-title>Empréstimos e dívidas</mat-panel-title>
                            </mat-expansion-panel-header>

                            <app-open-finance [uid]="customer.uid"></app-open-finance>

                          </mat-expansion-panel>
                        </div>
                      </mat-expansion-panel>
                      <hr>
                      <mat-expansion-panel class="inside-panel-container">
                        <mat-expansion-panel-header>
                          <mat-panel-title>Dados Serasa</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div>
                          Conteúdo do expansion panel
                        </div>
                      </mat-expansion-panel>
                      <hr>
                      <mat-expansion-panel class="inside-panel-container">
                        <mat-expansion-panel-header>
                          <mat-panel-title>Agregador de dados Capital Empreendedor</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="extra-data-card">

                          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                            class="expansion-panel">
                            <mat-expansion-panel-header>
                              <mat-panel-title>Informações básicas</mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="expansion-panel-content" *ngIf="basicData">
                              <p>Razão social: <span>{{customer?.companyName}}</span></p>
                              <p>Nome fantasia: <span>{{customer?.displayName}}</span></p>
                              <p>Regime tributário: <span>{{customer?.taxRegime?.name}}</span></p>
                              <p>Natureza legal: <span>{{basicData?.LegalNature?.Activity}}</span></p>
                              <p>Número de identificação fiscal: <span>{{basicData?.TaxIdNumber}}</span></p>
                              <p>País: <span>{{basicData?.TaxIdCountry}}</span></p>
                              <p>Data de fundação: <span>{{basicData?.FoundedDate | date}}</span></p>
                              <p>Idade: <span>{{basicData?.Age}} anos</span></p>
                              <p>É sede: <span>{{basicData?.IsHeadquarter === 'true' ? 'Sim' : 'Não'}}</span></p>
                              <p>Estado da sede: <span>{{basicData?.HeadquarterState === 'true' ? 'Sim' : 'Não'}}</span>
                              </p>
                              <p>É conglomerado: <span>{{basicData?.IsConglomerate === 'true' ? 'Sim' : 'Não'}}</span>
                              </p>
                              <p>Status de identificação fiscal: <span>{{basicData?.TaxIdStatus}}</span></p>
                              <p>Origem de identificação fiscal: <span>{{basicData?.TaxIdOrigin}}</span></p>
                              <p>TaxIdStatusDate: <span>{{basicData?.TaxIdStatusDate | date}}</span></p>
                              <p>TaxIdStatusRegistrationDate: <span>{{basicData?.TaxIdStatusRegistrationDate |
                                  date}}</span>
                              </p>
                              <p>Tipo de empresa: <span>{{basicData?.CompanyType_ReceitaFederal}}</span></p>
                              <p>NameUniquenessScore: <span>{{basicData?.NameUniquenessScore}}</span></p>
                              <p>OfficialNameUniquenessScore: <span>{{basicData?.OfficialNameUniquenessScore}}</span>
                              </p>
                              <p>TradeNameUniquenessScore: <span>{{basicData?.TradeNameUniquenessScore}}</span></p>
                              <p>Dados adicionais: <span> - </span></p>

                              <mat-expansion-panel *ngIf="basicDataActivities" class="expansion-panel-sub">
                                <mat-expansion-panel-header>
                                  <mat-panel-title>Atividades</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="expansion-panel-content-sub" *ngFor="let activity of basicDataActivities">
                                  <div>
                                    <p>Atividade: {{activity?.Activity}}</p>
                                    <p>Código: {{activity?.Code}}</p>
                                    <p>Principal: {{activity?.IsMain === 'true' ? 'Sim' : 'Não'}}</p>
                                  </div>
                                </div>
                              </mat-expansion-panel>

                            </div>
                          </mat-expansion-panel>

                          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                            class="expansion-panel">
                            <mat-expansion-panel-header>
                              <mat-panel-title>Processos</mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="expansion-panel-content" *ngIf="lawsuitsData">
                              <p>Total de processos: <span>{{lawsuitsData.TotalLawsuits}}</span></p>
                              <p>Total de processos como autor: <span>{{lawsuitsData.TotalLawsuitsAsAuthor}}</span></p>
                              <p>Total de processos como réu: <span>{{lawsuitsData.TotalLawsuitsAsDefendant}}</span></p>
                              <p>Total de processos como outro: <span>{{lawsuitsData.TotalLawsuitsAsOther}}</span></p>

                              <div *ngIf="lawsuits">
                                <mat-expansion-panel *ngFor="let lawsuit of lawsuits" class="expansion-panel-sub">
                                  <mat-expansion-panel-header>
                                    <mat-panel-title>Processo</mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <div class="expansion-panel-content-sub">
                                    <div>
                                      <p>Número do processo: {{lawsuit?.Number}}</p>
                                      <p>Tipo do processo: {{lawsuit?.Type}}</p>
                                      <p>Assunto principal: {{lawsuit?.MainSubject}}</p>
                                      <p>Nome do tribunal/ Instância: {{lawsuit?.CourtName}}</p>
                                      <p>Tipo do tribunal: {{lawsuit?.CourtType}}</p>
                                      <p>Status/ Situação: {{lawsuit?.Status}}</p>
                                      <p>Estado: {{lawsuit?.State}}</p>
                                      <!--p>Tipo da parte: {{lawsuit?.}}</p-->
                                      <!--p>Polaridade da parte: {{}}</p-->
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </div>
                            </div>
                          </mat-expansion-panel>

                          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                            class="expansion-panel">
                            <mat-expansion-panel-header>
                              <mat-panel-title>Protestos</mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="expansion-panel-content" *ngIf="protests">
                              <p>Origem: <span>{{protests.Origin}}</span></p>
                              <p>Status: <span>{{protests.BaseStatus}}</span></p>
                              <p>Data da consulta: <span>{{protests.QueryDate | date}}</span></p>
                              //A PARTIR DAQUI COMENTADO
                              <div *ngIf="lawsuits">
                                <mat-expansion-panel *ngFor="let lawsuit of lawsuits" class="expansion-panel-sub">
                                  <mat-expansion-panel-header>
                                    <mat-panel-title>Processo</mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <div class="expansion-panel-content-sub">
                                    <div>
                                      <p>Número do processo: {{lawsuit?.Number}}</p>
                                      <p>Tipo do processo: {{lawsuit?.Type}}</p>
                                      <p>Assunto principal: {{lawsuit?.MainSubject}}</p>
                                      <p>Nome do tribunal/ Instância: {{lawsuit?.CourtName}}</p>
                                      <p>Tipo do tribunal: {{lawsuit?.CourtType}}</p>
                                      <p>Status/ Situação: {{lawsuit?.Status}}</p>
                                      <p>Estado: {{lawsuit?.State}}</p>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                      class="expansion-panel">
                      <mat-expansion-panel-header>
                        <mat-panel-title>Relacionamentos</mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="expansion-panel-content" *ngIf="relationships">
                        <p>Proprietários da mesma família: <span>{{ (relationships[0]?.IsFamilyCompany != null) ?
                            (relationships[0].IsFamilyCompany ? 'Sim' : 'Não') : '-' }}</span></p>
                        <p>Funcionários da mesma família: <span>{{ (relationships[0]?.IsFamilyOperated != null) ?
                            (relationships[0].IsFamilyOperated ? 'Sim' : 'Não') : '-' }}</span></p>
                        <p>Total de relacionamentos: <span>{{ relationships[0]?.TotalRelationships || '-' }}</span></p>
                        <p>Total de proprietários: <span>{{ relationships[0]?.TotalOwners || '-' }}</span></p>
                        <p>Total de empresas possuídas: <span>{{ relationships[0]?.TotalOwned || '-' }}</span></p>
                        <p>Total de funcionários: <span>{{ relationships[0]?.TotalEmployees || '-' }}</span></p>

                        <!-- Painel "Relacionamentos atuais" -->
                        <mat-expansion-panel class="expansion-panel-custom">
                          <mat-expansion-panel-header>
                            <mat-panel-title>Relacionamentos atuais</mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="expansion-panel-content-custom">
                            <!-- Segundo loop começa -->
                            <ng-container *ngFor="let relationship of relationships; let i = index">
                              <!-- Início do bloco "Relação" -->
                              <mat-expansion-panel class="expansion-panel-subs">
                                <mat-expansion-panel-header>
                                  <mat-panel-title>Relacionamentos atuais</mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="expansion-panel-content">
                                  <!-- Conteúdo do segundo loop -->
                                  <p>Nome da pessoa: <span> {{ relationship.RelatedEntityName || '-' }}</span></p>
                                  <p>Tipo de relacionamento:  <span> {{ relationship.RelationshipType || '-' }}</span></p>
                                  <p>Nome de relacionamento:  <span> {{ relationship.RelationshipName === 'OWNER' ? 'Proprietário' : (relationship.RelationshipName || '-') }}</span></p>
                                  <p>Data de início do relacionamento: <span> {{ (relationship.RelationshipStartDate | date: 'dd/MM/yyyy') || '-' }}</span></p>
                                  <p>Data de fim do relacionamento: <span> {{ (relationship.RelationshipEndDate | date: 'dd/MM/yyyy') || '-' }}</span></p>
                                  <p>Nível de relacionamento: <span> {{ (relationship.RelationshipLevel === 'Direct' ? 'Direto' : (relationship.RelationshipLevel === 'Indirect' ? 'Indireto' : relationship.RelationshipLevel)) || '-' }}</span></p>
                                  <p>País de atuação: <span> {{ (relationship.RelatedEntityTaxIdCountry === 'Brazil' ? 'Brasil' : relationship.RelatedEntityTaxIdCountry) || '-' }}</span></p>
                                  <p>Tipo de documento: <span> {{ relationship.RelatedEntityTaxIdType || '-' }}</span></p>
                                  <p>Número do documento: <span> {{ relationship.RelatedEntityTaxIdNumber || '-' }}</span></p>
                                  <p>Data do primeiro registro do relacionamento: <span> {{ (relationship.CreationDate | date: 'dd/MM/yyyy') || '-' }}</span></p>
                                  <p>Última atualização de registro: <span> {{ (relationship.LastUpdateDate | date: 'dd/MM/yyyy') || '-' }}</span></p>
                                  <p>Fonte de origem dos dados: <span> {{ relationship.RelationshipDataOrigin || '-' }}</span></p>

                                </div>
                              </mat-expansion-panel>
                            </ng-container>
                          </div>
                          </mat-expansion-panel>
                          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                            class="expansion-panel">
                            <mat-expansion-panel-header>
                              <mat-panel-title>Dívidas com o governo</mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="expansion-panel-content" *ngIf="dividas">
                              <p>Origem: <span>{{dividas.Origin}}</span></p>
                              <p>Status: <span>{{dividas.BaseStatus}}</span></p>
                              <p>Data da consulta: <span>{{dividas.QueryDate | date}}</span></p>
                              <div *ngIf="lawsuits">
                                <mat-expansion-panel *ngFor="let lawsuit of lawsuits" class="expansion-panel-sub">
                                  <mat-expansion-panel-header>
                                    <mat-panel-title>Processo</mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <div class="expansion-panel-content-sub">
                                    <div>
                                      <p>Número do processo: {{lawsuit?.Number}}</p>
                                      <p>Tipo do processo: {{lawsuit?.Type}}</p>
                                      <p>Assunto principal: {{lawsuit?.MainSubject}}</p>
                                      <p>Nome do tribunal/ Instância: {{lawsuit?.CourtName}}</p>
                                      <p>Tipo do tribunal: {{lawsuit?.CourtType}}</p>
                                      <p>Status/ Situação: {{lawsuit?.Status}}</p>
                                      <p>Estado: {{lawsuit?.State}}</p>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </div>

                      </mat-expansion-panel>
                      <mat-expansion-panel class="expansion-panel">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            Dados do Banco Central (Registrato/SCR)
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-card-bmp-moneyplus *ngIf="customer?.uid" [customer]="customer?.uid"></app-card-bmp-moneyplus>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </mat-card-content>
                  <mat-accordion>

                  </mat-accordion>
                </mat-card-content>
              </mat-card>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <app-chat-message-banker *ngIf="customer && banker" [customer]="customer" [banker]="banker"></app-chat-message-banker>

</div>
