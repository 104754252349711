
  <div class="small-select">
    <select 
      [(ngModel)]="selectedUser" 
      name="user" 
      (ngModelChange)="onUserSelectionChange($event)" 
      [compareWith]="compareSelectedUser">
      <option [ngValue]="null">Sem atribuição</option>
      <option *ngFor="let user of users" [ngValue]="user">
        {{ user?.displayName }}
      </option>
    </select>
  </div>

