import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { PartnershipsEntity } from '../../../core/entities/partnerships.entity';
import { PartnershipsService } from '../../../core/services/partnerships.service';

@Component({
  selector: 'app-partnerships-view',
  templateUrl: './partnerships-view.component.html',
  styleUrls: ['./partnerships-view.component.scss']
})
export class PartnershipsViewComponent implements OnInit {

 
  constructor(public partnerService: PartnershipsService) {
    this.dataSourcePartner = new MatTableDataSource<PartnershipsEntity>()
   }

  dataSourcePartner: MatTableDataSource<PartnershipsEntity>;
  
  partnerships: PartnershipsEntity[] = [];

  getPartnerSubscription: Subscription;
  displayedColumns: string[] = ['partner','searchIcon'];
  searchPartner: string = '';

  ngOnInit(): void {

    this.partnerService.getAllPartners().subscribe(partners => {
      this.dataSourcePartner.data = partners;
    });
  }

  onInputChange() {

    if (!this.searchPartner.trim()) {
      this.dataSourcePartner.filter = '';
     
    }
  }

  applyFilter() {
    this.dataSourcePartner.filter = this.searchPartner.trim().toLowerCase();
  
  }

  clearFilter() {
    this.searchPartner = '';
    this.dataSourcePartner.filter = ''; 

  }

  ngOnDestroy(): void {
    if (this.getPartnerSubscription) {
      this.getPartnerSubscription.unsubscribe();
    }
  }

}
