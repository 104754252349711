import { Component, OnDestroy, OnInit } from '@angular/core';
import { Banker } from 'functions/src/models/Banker';
import { MASKS } from 'ng-brazil';
import { Subscription } from 'rxjs';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { BankerService } from '../../services/banker.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class BankerProfileComponent implements OnInit, OnDestroy {
  banker: Banker;
  bankerSubscription: Subscription;
  loading = true;
  processing = false;

  readonly MASKS = MASKS;

  formatPhone = UtilHandler.formatPhone;

  constructor(private bankerService: BankerService) {}

  ngOnInit(): void {
    this.bankerSubscription = this.bankerService.banker.subscribe((banker) => {
      this.banker = banker;

      if (banker?.institution?.name) {
        this.bankerService
          .getCreditInstitutionName(banker.institution.name)
          .then((label) => {
            this.banker.institution.label = (label || '').toUpperCase();
          })
          .catch((err) => {
            console.error('Error getting institution name', err);
            this.banker.institution.label = this.banker?.institution?.name?.replace('_', ' ').toUpperCase();
          });
      }
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    if (this.bankerSubscription) {
      this.bankerSubscription.unsubscribe();
    }
  }
}
