import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Contract } from 'functions/src/models/Contract';
import { User } from 'functions/src/models/User';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CustomerService } from 'src/app/customer/services/customer.service';
import { ContractService } from './../../services/contract.service';

@Component({
  selector: 'app-user-contract',
  templateUrl: './user-contract.component.html',
  styleUrls: ['./user-contract.component.scss'],
})
export class UserContractComponent implements OnInit, OnDestroy {
  contract: Contract;
  contractSubscription: Subscription;

  authSubscription: Subscription;
  user: User;

  isLoading = true;

  @ViewChild('contractView', { static: false }) contractView: ElementRef;

  constructor(
    public authService: AuthService,
    private customerService: CustomerService,
    private contractService: ContractService
  ) {}

  ngOnInit(): void {
    this.authSubscription = this.authService.user.subscribe((user) => {
      this.user = user;

      if (this.user && !this.user.isAnonymous) {
        this.contractSubscription = this.contractService.getContract(this.user.uid).subscribe((contract) => {
          this.contract = contract;
          this.contractView.nativeElement.innerHTML = this.contract?.html;
          this.isLoading = false;
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.contractSubscription) {
      this.contractSubscription.unsubscribe();
    }
  }

  onPrintTerm() {
    const term = document.getElementById('term').innerHTML;
    const win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><body>');
    win.document.write(term);
    win.document.write('</body></html>');
    win.print();
    win.close();
  }
}
