import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Customer } from '../../../../../functions/src/models/customers/Customer';
import { OptinService } from '../../../admin/services/optin.service';
import { HtmlDialogComponent } from '../../../components/html-dialog/html-dialog.component';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
  selector: 'app-customer-scr-dialog',
  templateUrl: './customer-scr-dialog.component.html',
  styleUrls: ['./customer-scr-dialog.component.scss'],
})
export class CustomerScrDialogComponent implements OnInit {

  optin = '';
  customer: Customer;
  isChecked = false;
  ipSubscription: Subscription;


  constructor(
    private optinService: OptinService,
    private dialog: MatDialog,
    private authService: AuthService,
    private dialogRef: MatDialogRef<CustomerScrDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { customer: Customer }
  ) { this.customer = data.customer;}

  ngOnInit(): void {
    this.fillOptin();

    console.log(this.customer)
  }

  fillOptin(): void {
    this.optinService
      .getOptinConfigurationScr()
      .then((config) => {
        this.optin = config.html;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  scrModal() {
    console.log("chamou o serviço")
    const cnpjValue = this.customer.cnpj;
    const companyName = this.customer.companyName;
    const city = this.customer.address.city;
    const now = new Date();
    const day = now.getDate();
    const month = now.toLocaleString('pt-BR', { month: 'long' });
    const year = now.getFullYear();

    const optinAtualizado = this.optin
      .replace('{{cnpj}}', cnpjValue)
      .replace('{{companyName}}', companyName)
      .replace('{{city}}', city)
      .replace('{{day}}', day.toString())
      .replace('{{month}}', month)
      .replace('{{year}}', year.toString())
      .replace('{{cpf}}', '')
      .replace('{{name}}', '')
      .replace('{{date}}', '')
      .replace('{{hash}}', '');

    this.dialog.open(HtmlDialogComponent, {
      data: {
        content: optinAtualizado,
        isOnlyConfirm: true,
      },
    });
  }

  scrSave() {
    const cnpjValue = this.customer.cnpj;
    const companyName = this.customer.companyName;
    const cpf = this.customer.cpf;
    const name = this.customer.name;
    const city = this.customer.address.city;
    const now = new Date();
    const day = now.getDate();
    const month = now.toLocaleString('pt-BR', { month: 'long' });
    const year = now.getFullYear();
    const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    const fullDate = `${ day } de ${ month } de ${ year } às ${ time }`;

    const scrReplace = this.optin
      .replace('{{cnpj}}', cnpjValue)
      .replace('{{companyName}}', companyName)
      .replace('{{city}}', city)
      .replace('{{day}}', day.toString())
      .replace('{{month}}', month)
      .replace('{{year}}', year.toString())
      .replace('{{cpf}}', cpf)
      .replace('{{name}}', name)
      .replace('{{date}}', fullDate);

    const getBrowserInfo = () => {
      const ua = navigator.userAgent;
      let browserName = "Desconhecido";
      let browserVersion = "";

      if (/chrome|crios|crmo/i.test(ua) && !/edg/i.test(ua)) {
        browserName = "Chrome";
        browserVersion = ua.match(/chrome\/([\d.]+)/i)?.[1] || "";
      } else if (/firefox|fxios/i.test(ua)) {
        browserName = "Firefox";
        browserVersion = ua.match(/firefox\/([\d.]+)/i)?.[1] || "";
      } else if (/safari/i.test(ua) && !/chrome|crios|crmo/i.test(ua)) {
        browserName = "Safari";
        browserVersion = ua.match(/version\/([\d.]+)/i)?.[1] || "";
      } else if (/edg/i.test(ua)) {
        browserName = "Edge";
        browserVersion = ua.match(/edg\/([\d.]+)/i)?.[1] || "";
      } else if (/opr|opera/i.test(ua)) {
        browserName = "Opera";
        browserVersion = ua.match(/(opera|opr)\/([\d.]+)/i)?.[2] || "";
      } else if (/msie|trident/i.test(ua)) {
        browserName = "Internet Explorer";
        browserVersion = ua.match(/(msie |rv:)([\d.]+)/i)?.[2] || "";
      }
      
      this.dialogRef.close();
      return `${ browserName } ${ browserVersion }`;
      
    };

    const browser = getBrowserInfo();

    this.ipSubscription = this.authService.getClientIP().subscribe((data) => {

      const ip = data['ip'];
      const scr = {
        date: new Date(),
        ip: ip,
        html: scrReplace.replace('{{hash}}', `${ip}#${cpf}#${fullDate}#${cnpjValue}`.replace(/\s+/g, '')),
        browser: browser,
        name: name,
        cpf: cpf
      };

      console.log(scr);
      this.optinService.saveOptinConfiguration(scr, cnpjValue.replace(/\D/g, ''));

    });
  }
}
