import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Banker } from '../../../../../functions/src/models/Banker';
import { CepError, Endereco } from '../../../core/models/model-interfaces';
import { ViacepService } from '../../../core/services/viacep.service';
import { BankerService } from '../../services/banker.service';

@Component({
  selector: 'app-banker-profile-data',
  templateUrl: './banker-profile-data.component.html',
  styleUrls: ['./banker-profile-data.component.scss']
})
export class BankerProfileDataComponent implements OnInit {
  @Input() banker: Banker = null;

  editProfilePictureData = false;
  editPersonalData = false;
  editAddressData = false;
  runSearchCEP = false;
  profilePicture: string | null = null;
  lastPostalCode = '';

  personalDataFormGroup: FormGroup;
  addressDataFormGroup: FormGroup;


  constructor(private formBuilder: FormBuilder, private bankerService: BankerService, private viacep: ViacepService) { }

  ngOnInit(): void {
    if (this.banker) {
      this.createFormGroup();
      this.bankerPersonalData();
    }
    if (this.banker?.address) {
      this.createAddressFromGroup();
      this.bankerAddressData();
    }
  }

  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement?.files?.[0];

    if (file) {
      this.convertToBase64(file);
    }
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.profilePicture = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  removeProfilePicture(): void {
    this.profilePicture = null;
  }

  onEditProfilePictureData(): void {
    this.editProfilePictureData = !this.editProfilePictureData;
  }

  onSaveProfilePictureData(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (this.banker) {
        this.bankerService
          .updateBankerOnFirestore(this.banker.uid, { photoUrl: this.profilePicture })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
      this.editProfilePictureData = false;
    });
  }

  createFormGroup(): void {
    this.personalDataFormGroup = this.formBuilder.group({
      nameCtrl: new FormControl({ value: '', disabled: false }),
      occupationCtrl: new FormControl({ value: '', disabled: false }),
      whatsappCtrl: new FormControl({ value: '', disabled: false }),
      institutionCtrl: new FormControl({ value: '', disabled: false }),
      emailCtrl: new FormControl({ value: '', disabled: false }),
      phoneCtrl: new FormControl({ value: '', disabled: false }),
    });
  }

  bankerPersonalData(): void {
    this.personalDataFormGroup.get('nameCtrl').setValue(this.banker.name);
    this.personalDataFormGroup.get('occupationCtrl').setValue(this.banker.occupation);
    this.personalDataFormGroup.get('whatsappCtrl').setValue(this.banker.whatsapp);
    this.personalDataFormGroup.get('institutionCtrl').setValue(this.banker.institution.label);
    this.personalDataFormGroup.get('emailCtrl').setValue(this.banker.email);
    this.personalDataFormGroup.get('phoneCtrl').setValue(this.banker.phone);
  }

  createAddressFromGroup(): void {
    this.addressDataFormGroup = this.formBuilder.group({
      postalCodeCtrl: new FormControl({ value: '', disabled: false }),
      extraInfoCtrl: new FormControl({ value: '', disabled: false }),
      cityCtrl: new FormControl({ value: '', disabled: false }),
      streetCtrl: new FormControl({ value: '', disabled: false }),
      neighborhoodCtrl: new FormControl({ value: '', disabled: false }),
      stateCtrl: new FormControl({ value: '', disabled: false }),
    });
  }

  bankerAddressData(): void {
    this.addressDataFormGroup.get('postalCodeCtrl').setValue(this.banker.address?.postalCode);
    this.addressDataFormGroup.get('extraInfoCtrl').setValue(this.banker.address?.extraInfo);
    this.addressDataFormGroup.get('cityCtrl').setValue(this.banker.address?.city);
    this.addressDataFormGroup.get('streetCtrl').setValue(this.banker.address?.street);
    this.addressDataFormGroup.get('neighborhoodCtrl').setValue(this.banker.address?.neighborhood);
    this.addressDataFormGroup.get('stateCtrl').setValue(this.banker.address?.state);
  }

  onEditPersonalData(): void {
    this.editPersonalData = !this.editPersonalData;
  }

  onSavePersonalData(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (this.banker) {
        this.bankerService
          .updateBankerOnFirestore(this.banker.uid, {
            name: this.personalDataFormGroup.get('nameCtrl').value.trim().toUpperCase(),
            occupation: this.personalDataFormGroup.get('occupationCtrl').value.trim(),
            email: this.personalDataFormGroup.get('emailCtrl').value.trim(),
            phone: this.personalDataFormGroup.get('phoneCtrl').value.trim(),
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
      this.editPersonalData = false;
    });
  }

  onEditAddressData(): void {
    this.editAddressData = !this.editAddressData;
  }

  onSaveAddressData(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (this.banker) {
        this.bankerService
          .updateBankerOnFirestore(this.banker.uid, {
            address: {
              postalCode: this.addressDataFormGroup.get('postalCodeCtrl').value,
              extraInfo: this.addressDataFormGroup.get('extraInfoCtrl').value,
              city: this.addressDataFormGroup.get('cityCtrl').value,
              street: this.addressDataFormGroup.get('streetCtrl').value,
              neighborhood: this.addressDataFormGroup.get('neighborhoodCtrl').value,
              state: this.addressDataFormGroup.get('stateCtrl').value,
            }
          })
          .then((res) => {
            resolve(res);
          }).catch((err) => {
            reject(err);
          });
      }
      this.editAddressData = false;
    });
  }

  getAddressFromPostalCode(): void {
    const postalCode = this.addressDataFormGroup.get('postalCodeCtrl').value.replace('.', '').replace('-', '');

    if (!!postalCode && postalCode.length === 8 && postalCode !== this.lastPostalCode) {
      this.lastPostalCode = postalCode;
      this.runSearchCEP = true;
      this.viacep
        .buscarPorCep(postalCode)
        .then((endereco: Endereco) => {
          if (endereco.cep === undefined) {
            this.addressDataFormGroup.get('postalCodeCtrl').setErrors({ incorrect: true });
            this.addressDataFormGroup.get('streetCtrl').setValue('');
            this.addressDataFormGroup.get('numberCtrl').setValue('');
            this.addressDataFormGroup.get('neighborhoodCtrl').setValue('');
            this.addressDataFormGroup.get('cityCtrl').setValue('');
            this.addressDataFormGroup.get('stateCtrl').setValue('');
          } else {
            if (endereco.logradouro) {
              this.addressDataFormGroup.get('streetCtrl').setValue(endereco.logradouro);
              this.addressDataFormGroup.get('streetCtrl').disable({ onlySelf: true });
            } else {
              this.addressDataFormGroup.get('streetCtrl').setValue('');
              this.addressDataFormGroup.get('streetCtrl').enable({ onlySelf: true });
            }
            if (endereco.bairro) {
              this.addressDataFormGroup.get('neighborhoodCtrl').setValue(endereco.bairro);
              this.addressDataFormGroup.get('neighborhoodCtrl').disable({ onlySelf: true });
            } else {
              this.addressDataFormGroup.get('neighborhoodCtrl').setValue('');
              this.addressDataFormGroup.get('neighborhoodCtrl').enable({ onlySelf: true });
            }
            this.addressDataFormGroup.get('cityCtrl').setValue(endereco.localidade);
            this.addressDataFormGroup.get('stateCtrl').setValue(endereco.uf);
          }
        })
        .catch((error: CepError) => {
          this.addressDataFormGroup.get('postalCodeCtrl').setErrors({ incorrect: true });
          console.error('Error getting address info', error.descricao);
        }).finally(() => {
          this.runSearchCEP = false;
        });
    }
  }

}
