<div>
  <div *ngIf="messages?.length > 0 && !isLoadingMessages" style="overflow: hidden">

    <div style="display: flex; flex-direction: column;">
      <button mat-raised-button type="button" class="w-100" color="primary" (click)="onLoadMoreMessages()" #loadMoreBtn>
        Carregar Mais Mensagens
      </button>
    </div>   

    <div *ngFor="let message of messages;let i = index">
      <div #messageContent [ngClass]="getMessageCardStyle(message)">

        <div *ngIf="message.text || message.contactReceived?.phone" class="text-msg"
          style="display: flex; flex-direction: column;">
          <ng-container style="display: flex; flex-direction: row; justify-content: space-between;">
            <div style="display: flex; justify-content: space-between;">
              <span *ngIf="message.recipient.name === 'Capital Empreendor'"
                style="margin-bottom: 5px; font-size: 12px; font-weight: 600; text-align: left;">{{
                message.recipient.name }}</span>
              <span *ngIf="message.recipient !== 'Capital Empreendor'"
                style="margin-bottom: 5px; font-size: 12px; font-weight: 600; text-align: left;">{{ message.sender.name
                }}</span>
              <a style="color: white; cursor: pointer;" *ngIf="message.deleteMessage !== true 
              && (message.messageType === 'WHATSAPP' || message.sender.type === 'admin')" 
              (click)="toggleMenu(message.firestoreId)">
                <mat-icon>expand_more</mat-icon>
              </a>
            </div>
          </ng-container>

          <ng-container *ngIf="message.replyMessage?.sendDate">
            <div [class]="getStyleReply(message.sender.type)" style="display: flex; flex-direction: column;"
              (contextmenu)="blockRightClickMouse($event)">
              <span *ngIf="message.recipient !== 'Capital Empreendedor'"
                style="margin-bottom: 5px; font-size: 12px; font-weight: 600; text-align: left;">{{
                message.replyMessage.nameSender }}</span>
              <p *ngIf="message.replyMessage.text !== '' && message.messageType === 'WHATSAPP'"
                [innerHTML]="formatMessage(message.replyMessage.text)"></p>
              <p *ngIf="message.replyMessage.text !== '' && message.messageType !== 'WHATSAPP'"
                [innerHTML]="message.replyMessage.text"></p>

              <div *ngIf="message.replyMessage.document !== ''" class="document-inner-content"
                (click)="downloadDocuments(message.replyMessage)">
                <img *ngIf="message.replyMessage.mimeType === 'application/pdf'"
                  src="assets/images/icons/pdf-message-icon.svg" alt="pdf-icon" />
                <img
                  *ngIf="message.replyMessage.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                  src="assets/images/icons/doc-message-icon.png" alt="document-icon" />
                <img *ngIf="message.replyMessage.mimeType === 'application/msword'"
                  src="assets/images/icons/doc-message-icon.png" alt="document-icon" />
                <img
                  *ngIf="message.replyMessage.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                  src="assets/images/icons/xlsx-message-icon.png" alt="xlsx-icon" />
              </div>
              <span
                *ngIf="message.replyMessage.documentFileName !== ''">{{message.replyMessage.documentFileName}}</span>
              <div *ngIf="message.replyMessage.imageCaption !== ''" class="document-inner-content"
                (click)="downloadImage(message.replyMessage)">
                <img src="assets/images/icons/image-message-icon.png" alt="document-icon" />
              </div>
              <span>{{ formatDate(message.replyMessage.sendDate).replace(',', ' -') }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="message.contactReceived?.phone">
            <div class="contact-inner-content" (contextmenu)="openMenuContactSelected($event, message)">
              <img src="{{message.contactReceived.photo}}">
              <div class="contact-card--text--green">
                <p>{{ message.contactReceived.displayName }} </p>
                <span>{{ formatWhatsapp(message.contactReceived.phone) }}</span>
              </div>
            </div>
          </ng-container>

          <p *ngIf="message.text" [innerHTML]="formatMessage(message.text)"></p>
          <span>{{ formatDate(message.sendDate).replace(',', ' -') }}
            &nbsp;
            <mat-icon
              *ngIf="message.messageType === 'WHATSAPP' && message.sender.type === 'admin' && message.messageStatus?.status === 'SENT'"
              style="color: #88B3AB; font-size: 16px;">done</mat-icon>
            <mat-icon
              *ngIf="message.messageType === 'WHATSAPP' && message.sender.type === 'admin' && message.messageStatus?.status === 'RECEIVED'"
              style="color: #88B3AB; font-size: 16px;">done_all</mat-icon>
            <mat-icon
              *ngIf="message.messageType === 'WHATSAPP' && message.sender.type === 'admin' && message.messageStatus?.status === 'READ'"
              style="color: #3186F5; font-size: 16px;">done_all</mat-icon>
          </span>
          <span *ngIf="message.editMessage === true">Mensagem editada</span>
          <span *ngIf="message.deleteMessage === true">Mensagem apagada</span>

          <button *ngIf="message.text && message.deleteMessage !== true" (click)="forwardNote(message.text)" class="foward-message" mat-icon-button
            matTooltip="Encaminhar mensagem">
            <i class="fa fa-share" style="color: #2b2d42;"></i>
          </button>
        </div>

        <div style="display: flex; flex-direction: column;" (contextmenu)="blockRightClickMouse($event)">
          <div *ngIf="message.audio" [ngClass]="getAttachmentCardStyle(message)"
            style="display: flex; flex-direction: column;">
            <span *ngIf="message.recipient.name === 'Capital Empreendor'"
              style="margin-bottom: 5px;  font-size: 12px; font-weight: 600; text-align: left;">{{message.recipient.name}}</span>
            <span *ngIf="message.recipient !== 'Capital Empreendedor'"
              style="margin-bottom: 5px;  font-size: 12px; font-weight: 600; text-align: left;">{{message.sender.name}}</span>
            <ng-container *ngIf="message.replyMessage?.sendDate">
              <div class="reply-div" style="display: flex; flex-direction: column;">
                <span *ngIf="message.recipient !== 'Capital Empreendedor'"
                  style="margin-bottom: 5px; font-size: 12px; font-weight: 600; text-align: left;">{{
                  message.replyMessage.nameSender }}</span>
                <p *ngIf="message.replyMessage.text !== '' && message.messageType === 'WHATSAPP'"
                  [innerHTML]="formatMessage(message.replyMessage.text)"></p>
                <p *ngIf="message.replyMessage.text !== '' && message.messageType !== 'WHATSAPP'"
                  [innerHTML]="message.replyMessage.text"></p>

                <div *ngIf="message.replyMessage.document !== ''" class="document-inner-content"
                  (click)="downloadDocuments(message.replyMessage)">
                  <img *ngIf="message.replyMessage.mimeType === 'application/pdf'"
                    src="assets/images/icons/pdf-message-icon.svg" alt="pdf-icon" />
                  <img
                    *ngIf="message.replyMessage.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                    src="assets/images/icons/doc-message-icon.png" alt="document-icon" />
                  <img
                    *ngIf="message.replyMessage.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                    src="assets/images/icons/xlsx-message-icon.png" alt="xlsx-icon" />
                </div>
                <span
                  *ngIf="message.replyMessage.documentFileName !== ''">{{message.replyMessage.documentFileName}}</span>
                <div *ngIf="message.replyMessage.imageCaption !== ''" class="document-inner-content"
                  (click)="downloadImage(message.replyMessage)">
                  <img src="assets/images/icons/image-message-icon.png" alt="document-icon" />
                </div>
                <span>{{ formatDate(message.replyMessage.sendDate).replace(',', ' -') }}</span>
              </div>
            </ng-container>
            <app-audio-player [message]="message"></app-audio-player>
          </div>
        </div>

        <div style="display: flex; flex-direction: column;" (contextmenu)="blockRightClickMouse($event)">
          <div *ngIf="message.image" [ngClass]="getAttachmentCardStyle(message)">
            <div style="display: flex; flex-direction: column;">
              <span *ngIf="message.recipient.name === 'Capital Empreendor'"
                style="margin-bottom: 5px;  font-size: 12px; font-weight: 600; text-align: left;">{{message.recipient.name}}</span>
              <span *ngIf="message.recipient !== 'Capital Empreendedor'"
                style="margin-bottom: 5px;  font-size: 12px; font-weight: 600; text-align: left;">{{message.sender.name}}</span>

              <ng-container *ngIf="message.replyMessage?.sendDate">
                <div class="reply-div" style="display: flex; flex-direction: column;">
                  <span *ngIf="message.recipient !== 'Capital Empreendedor'"
                    style="margin-bottom: 5px; font-size: 12px; font-weight: 600; text-align: left;">{{
                    message.replyMessage.nameSender }}</span>
                  <p *ngIf="message.replyMessage.text !== '' && message.messageType === 'WHATSAPP'"
                    [innerHTML]="formatMessage(message.replyMessage.text)"></p>
                  <p *ngIf="message.replyMessage.text !== '' && message.messageType !== 'WHATSAPP'"
                    [innerHTML]="message.replyMessage.text"></p>

                  <div *ngIf="message.replyMessage.document !== ''" class="document-inner-content"
                    (click)="downloadDocuments(message.replyMessage)">
                    <img *ngIf="message.replyMessage.mimeType === 'application/pdf'"
                      src="assets/images/icons/pdf-message-icon.svg" alt="pdf-icon" />
                    <img
                      *ngIf="message.replyMessage.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                      src="assets/images/icons/doc-message-icon.png" alt="document-icon" />
                    <img
                      *ngIf="message.replyMessage.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                      src="assets/images/icons/xlsx-message-icon.png" alt="xlsx-icon" />
                  </div>
                  <span
                    *ngIf="message.replyMessage.documentFileName !== ''">{{message.replyMessage.documentFileName}}</span>
                  <div *ngIf="message.replyMessage.imageCaption !== ''" class="document-inner-content"
                    (click)="downloadImage(message.replyMessage)">
                    <img src="assets/images/icons/image-message-icon.png" alt="document-icon" />
                  </div>
                  <span>{{ formatDate(message.replyMessage.sendDate).replace(',', ' -') }}</span>
                </div>
              </ng-container>

              <div class="document-inner-content" (click)="downloadImage(message)">
                <img src="assets/images/icons/image-message-icon.png" alt="document-icon" />
              </div>
              <span>{{message.imageCaption}}</span>
              <span>{{ formatDate(message.sendDate).replace(',', ' -') }}
                &nbsp;
                <mat-icon
                  *ngIf="message.messageType === 'WHATSAPP' && message.sender.type === 'admin' && message.messageStatus?.status === 'SENT'"
                  style="color: #88B3AB; font-size: 16px;">done</mat-icon>
                <mat-icon
                  *ngIf="message.messageType === 'WHATSAPP' && message.sender.type === 'admin' && message.messageStatus?.status === 'RECEIVED'"
                  style="color: #88B3AB; font-size: 16px;">done_all</mat-icon>
                <mat-icon
                  *ngIf="message.messageType === 'WHATSAPP' && message.sender.type === 'admin' && message.messageStatus?.status === 'READ'"
                  style="color: #3186F5; font-size: 16px;">done_all</mat-icon>
              </span>
              <span *ngIf="message.deleteMessage === true">Mensagem apagada</span>
            </div>

            <a style="color: white; cursor: pointer;" *ngIf="message.deleteMessage !== true" (click)="toggleMenuImage(message.firestoreId)">
              <mat-icon>expand_more</mat-icon>
            </a>
          </div>
        </div>

        <div style="display: flex; flex-direction: column;" (contextmenu)="blockRightClickMouse($event)">
          <div *ngIf="message.document" [ngClass]="getAttachmentCardStyle(message)">
            <div style="display: flex; flex-direction: column;">
              <span *ngIf="message.recipient.name === 'Capital Empreendor'"
                style="margin-bottom: 5px;  font-size: 12px; font-weight: 600; text-align: left;">{{message.recipient.name}}</span>
              <span *ngIf="message.recipient !== 'Capital Empreendedor'"
                style="margin-bottom: 5px;  font-size: 12px; font-weight: 600; text-align: left;">{{message.sender.name}}</span>
              <ng-container *ngIf="message.replyMessage?.sendDate">
                <div class="reply-div" style="display: flex; flex-direction: column;">
                  <span *ngIf="message.recipient !== 'Capital Empreendedor'"
                    style="margin-bottom: 5px; font-size: 12px; font-weight: 600; text-align: left;">{{
                    message.replyMessage.nameSender }}</span>
                  <p *ngIf="message.replyMessage.text !== '' && message.messageType === 'WHATSAPP'"
                    [innerHTML]="formatMessage(message.replyMessage.text)"></p>
                  <p *ngIf="message.replyMessage.text !== '' && message.messageType !== 'WHATSAPP'"
                    [innerHTML]="message.replyMessage.text"></p>

                  <div *ngIf="message.replyMessage.document !== ''" class="document-inner-content"
                    (click)="downloadDocuments(message.replyMessage)">
                    <img *ngIf="message.replyMessage.mimeType === 'application/pdf'"
                      src="assets/images/icons/pdf-message-icon.svg" alt="pdf-icon" />
                    <img
                      *ngIf="message.replyMessage.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                      src="assets/images/icons/doc-message-icon.png" alt="document-icon" />
                    <img
                      *ngIf="message.replyMessage.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                      src="assets/images/icons/xlsx-message-icon.png" alt="xlsx-icon" />
                  </div>
                  <span
                    *ngIf="message.replyMessage.documentFileName !== ''">{{message.replyMessage.documentFileName}}</span>
                  <div *ngIf="message.replyMessage.imageCaption !== ''" class="document-inner-content"
                    (click)="downloadImage(message.replyMessage)">
                    <img src="assets/images/icons/image-message-icon.png" alt="document-icon" />
                  </div>
                  <span>{{ formatDate(message.replyMessage.sendDate).replace(',', ' -') }}</span>
                </div>
              </ng-container>

              <div class="document-inner-content" (click)="downloadDocuments(message)">
                <img *ngIf="message.mimeType === 'application/pdf'" src="assets/images/icons/pdf-message-icon.svg"
                  alt="pdf-icon" />
                <img
                  *ngIf="message.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                  src="assets/images/icons/doc-message-icon.png" alt="document-icon" />
                <img *ngIf="message.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                  src="assets/images/icons/xlsx-message-icon.png" alt="xlsx-icon" />
              </div>
              <span>{{message.documentFileName}}</span>
              <span>{{ formatDate(message.sendDate).replace(',', ' -') }}
                &nbsp;
                <mat-icon
                  *ngIf="message.messageType === 'WHATSAPP' && message.sender.type === 'admin' && message.messageStatus?.status === 'SENT'"
                  style="color: #88B3AB; font-size: 16px;">done</mat-icon>
                <mat-icon
                  *ngIf="message.messageType === 'WHATSAPP' && message.sender.type === 'admin' && message.messageStatus?.status === 'RECEIVED'"
                  style="color: #88B3AB; font-size: 16px;">done_all</mat-icon>
                <mat-icon
                  *ngIf="message.messageType === 'WHATSAPP' && message.sender.type === 'admin' && message.messageStatus?.status === 'READ'"
                  style="color: #3186F5; font-size: 16px;">done_all</mat-icon>
              </span>
            </div>

            <a style="color: white; cursor: pointer;" *ngIf="message.deleteMessage !== true" (click)="toggleMenu(message.firestoreId)">
              <mat-icon>expand_more</mat-icon>
            </a>
          </div>

        </div>

        <ng-container
          *ngIf="message.optionList?.options && message.optionList.options.length > 1 && message.messageType !== 'NOTES'">
          <div
            [ngClass]="{'whatsapp-option-button': message.messageType === 'WHATSAPP', 'chat-se-option-button': message.messageType === 'CHAT-SCE'}">
            <span style="font-size: 20px; color: white;">{{ message.optionList.title}}</span>
            <a mat-flat-button *ngFor="let option of message.optionList.options"
              (click)="handleOptionClick(option, message.messageType)">
              {{ option.title }}
            </a>
            <span>{{ formatDate(message.sendDate).replace(',', ' -') }}</span>
          </div>
        </ng-container>
        <div *ngIf="menuOpenStates[message.firestoreId]" class="menu-dropdown">
          <ul>
            <li *ngIf="message.messageType === 'WHATSAPP'" (click)="openReplyMessageDialog(message)">Responder</li>
            <div *ngIf="message.sender.type === 'admin' && message.messageType === 'WHATSAPP'" class="menu-divider"></div>
            <li *ngIf="message.sender.type === 'admin'" (click)="openEditDialog(message)">Editar</li>
            <div *ngIf="message.sender.type === 'admin'" class="menu-divider"></div>
            <li *ngIf="message.sender.type === 'admin'" (click)="openDeleteDialog(message)">Excluir</li>
            <div *ngIf="message.messageType === 'WHATSAPP' || message.sender.type === 'admin'" class="menu-divider"></div>
            <li (click)="toggleMenu(message.firestoreId)">Voltar</li>
            <li *ngIf="false">Copiar</li>
            <li *ngIf="false" (click)="openAnexarDialog()">Anexar</li>
          </ul>
        </div>
        <div *ngIf="menuOpenStatesImage[message.firestoreId]" class="menu-dropdown">
          <ul>
            <li *ngIf="message.messageType === 'WHATSAPP'" (click)="openReplyMessageDialog(message)">Responder</li>
            <div *ngIf="message.sender.type === 'admin' && message.sender.type === 'WHATSAPP'" class="menu-divider"></div>
            <li *ngIf="message.sender.type === 'admin'" (click)="openDeleteDialog(message)">Excluir</li>
            <div *ngIf="message.messageType === 'WHATSAPP' || message.sender.type === 'admin'" class="menu-divider"></div>
            <li (click)="toggleMenuImage(message.firestoreId)">Voltar</li>
          </ul>
        </div>
      </div>

      <app-chat-attributed-item *ngIf="atributedList?.length > 0"
        [attributedList]="getAttributedList(message,messages[i+1] || null) || null"></app-chat-attributed-item>


    </div>
  </div>

  <div class="w-100 p-5" *ngIf="isLoadingMessages">
    <mat-progress-spinner *ngIf="isLoadingMessages" [diameter]="60" class="mx-auto" color="primary"
      mode="indeterminate"></mat-progress-spinner>
  </div>
</div>