<div class="messages-wrapper" *ngIf="!checkingAdmin()">
  <div class="messages-header">
    <div class="messages-title">
      <div class="div-flow">
        <p> Empresa atribuída a outro admin {{adminAttributed}}! </p>
      </div>
    </div>
  </div>
</div>

<div class="messages-wrapper" *ngIf="checkingAdmin()" (click)="closeMenuContactSelected()">
  <div class="messages-header" (contextmenu)="blockRightClickMouse($event)">
    <img *ngIf="contactSelected" src="{{contactSelected.photoWhatsapp}}">
    <div class="messages-title">
      <div class="div-flow">
        <p (click)="userDetail()" style="cursor: pointer;">{{customer.companyName}}</p>
        <div class="custom-button-div disable-focus" style="color: #008348; cursor: pointer; margin-left: 10px;"
          (click)="getInfoCompany(customer)">
          <mat-icon class="info-icon">info</mat-icon>
        </div>
      </div>
      <span>{{customer.cnpj}}</span>
    </div>
    <app-chat-customer-attributed *ngIf="customer && users.length > 0" [users]="users" [customer]="customer"
      [(selectedUser)]="selectedUser" (selectedUserUidChange)="onUserSelectionChange($event)">
    </app-chat-customer-attributed>
  </div>
  <div class="messages-header" *ngIf="customer.cnpj && !contacts.length">
    <p> O cliente não possui contatos adicionados! </p>
  </div>
  <mat-tab-group class="messages-main-content" (selectedTabChange)="onTabSelectionChange($event)">
    <ng-container *ngFor="let contact of contacts">
      <mat-tab class="messages-tab">
        <ng-template mat-tab-label>
          <div class="tab-label" (contextmenu)="blockRightClickMouse($event)">
            <div class="larger-font-tab">{{ contact.name ? contact.name : contact.contactName }}</div>
            <div class="smaller-font-tab">{{ formatWhatsapp(contact.whatsapp) }}</div>
          </div>
        </ng-template>
        <div class="messages-content" style="display: flex; flex-direction: column-reverse;">

          <div class="whatsAppContact" *ngIf="customer.whatsAppContact" (contextmenu)="blockRightClickMouse($event)">
            <p *ngIf="!whatsAppValue && whatsAppDate">━━━━━━ Desativou a opção de resposta por whats em: {{
              whatsAppDate.replace(',', ' -') }} ━━━━━━</p>
            <p *ngIf="whatsAppValue && whatsAppDate">━━━━━━ Ativou a opção de resposta por whats em: {{
              whatsAppDate.replace(',', ' -') }} ━━━━━━</p>
          </div>

          <div *ngIf="sendingAttachment" class="popup-overlay">
            <div class="popup-content">
              <p>Enviando anexo...</p>
              <div class="spinner">
                <mat-spinner [diameter]="60" color="primary" class="mx-auto"></mat-spinner>
              </div>

            </div>
          </div>

          <app-message-list [contact]="contact"></app-message-list>
        </div>
        <mat-tab-group class="create-message-container">
          <mat-tab label="Chat-SCE"
            *ngIf="selectedContact.isContactMain === true || selectedContact.isContactMain === undefined">
            <div class="create-message-tab">
              <button (click)="openFileInput('chat-sce')"><mat-icon color="primary">attach_file</mat-icon></button>
              <div class="textarea-container">
                <mat-form-field *ngIf="showTemplateOptions" class="template-select">
                  <mat-select placeholder="Selecione o atalho" name="templates">
                    <mat-option *ngFor="let template of messageTemplates" (click)="selectTemplateChatSce(template)"
                      [value]="template.description">
                      {{template.nome}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <textarea rows="1" class="textarea-intern" id="textMessageChatSce"  [(ngModel)]="textMessageChatSce" name="messageChat"
                  placeholder="Nova mensagem" (keyup)="checkForSlash($event)" (input)="onInput($event)" #textareaChatSCE
                  [class.transparent-text]="showTemplateOptions" [disabled]="sendMessageLoading"></textarea>
              </div>
              <input #fileInput type="file" style="display: none" (change)="onFileSelected($event, 'Chat-SCE')"
                accept=".jpg, .png, .pdf, .docx, .xlsx">
              <button *ngIf="!sendMessageLoading" [disabled]="textMessageChatSce === ''"
                [ngClass]="{'chat-se-disabled-button': textMessageChatSce === ''}" (click)="sendTextMessage('Chat-SCE')" >
                <div>
                  <mat-icon *ngIf="textMessageChatSce === ''" [ngClass]="chat-se-disabled-icon">send</mat-icon>
                  <mat-icon *ngIf="textMessageChatSce !== ''" color="primary">send</mat-icon>
                </div>
              </button>
              <button *ngIf="sendMessageLoading" [ngClass]="{'chat-se-disabled-button': textMessageChatSce === ''}">
                <mat-spinner diameter="30" color="primary"></mat-spinner>            
              </button>
            </div>
          </mat-tab>
          <mat-tab label="Whatsapp" [disabled]="!customer.whatsAppContact.value">
            <div class="create-message-tab">
              <button (click)="openFileInput('whatsapp')"><mat-icon color="primary">attach_file</mat-icon></button>
              <input #fileInput2 type="file" style="display: none" (change)="onFileSelected($event, 'WHATSAPP')"
                accept=".jpg, .png, .pdf, .docx, .xlsx">
              <div class="textarea-container">
                <mat-form-field *ngIf="showTemplateOptions" class="template-select">
                  <mat-select placeholder="Selecione o atalho" name="templates">
                    <mat-option *ngFor="let template of messageTemplates" (click)="selectTemplateWhatsApp(template)"
                      [value]="template.description">
                      {{template.nome}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <quill-editor class="quill-editor-custom" #editor id="textMessageWhatsApp" [modules]="modules"
                  [(ngModel)]="textMessageWhatsApp" placeholder="Nova mensagem" (keyup)="checkForSlash($event)"
                  (input)="onInput($event)" [class.transparent-text]="showTemplateOptions"></quill-editor> -->
                  <textarea rows="1" class="textarea-whatsapp" id="textMessageWhatsApp" [(ngModel)]="textMessageWhatsApp" placeholder="Nova mensagem" (keyup)="checkForSlash()" (input)="onInput($event)" #textareaRef [class.transparent-text]="showTemplateOptions"></textarea>
              </div>
              <button *ngIf="!sendMessageLoading" [disabled]="textMessageWhatsApp === ''"
                [ngClass]="{'disabled-button': textMessageWhatsApp === ''}" (click)="sendTextMessage('WHATSAPP')">
                <div>
                  <mat-icon *ngIf="textMessageWhatsApp === ''" [ngClass]="chat-se-disabled-icon">send</mat-icon>
                  <mat-icon *ngIf="textMessageWhatsApp !== ''" color="primary">send</mat-icon>
                </div>
              </button>
              <button *ngIf="sendMessageLoading" [ngClass]="{'chat-se-disabled-button': textMessageWhatsApp === ''}">
                <mat-spinner diameter="30" color="primary"></mat-spinner>            
              </button>

            </div>
          </mat-tab>
          <mat-tab label="Notas Internas">
            <div class="create-message-tab">
              <div class="textarea-container">
                <div class="textarea-container">
                  <mat-form-field *ngIf="showTemplateOptions" class="template-select">
                    <mat-select placeholder="Selecione o atalho" name="templates">
                      <mat-option *ngFor="let template of messageTemplates" (click)="selectTemplateNotes(template)"
                        [value]="template.description">
                        {{template.nome}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <textarea rows="1" class="textarea-notes"  id="textMessageNotes" [(ngModel)]="textMessageNotes" placeholder="Nova mensagem"
                    (keyup)="checkForSlash($event)" (input)="onInput($event)" #textareaNotes
                    [class.transparent-text]="showTemplateOptions"></textarea>
                </div>
              </div>
              <button *ngIf="!sendMessageLoading" [disabled]="textMessageNotes === ''"
                [ngClass]="{'chat-se-disabled-button': textMessageNotes === ''}" (click)="sendTextMessage('NOTES')">
                <div>
                  <mat-icon *ngIf="textMessageNotes === ''" [ngClass]="chat-se-disabled-icon">send</mat-icon>
                  <mat-icon *ngIf="textMessageNotes !== ''" color="primary">send</mat-icon>
                </div>
              </button>
              <button *ngIf="sendMessageLoading" [ngClass]="{'chat-se-disabled-button': textMessageNotes === ''}">
                <mat-spinner diameter="30" color="primary"></mat-spinner>            
              </button>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-tab>
    </ng-container>
  </mat-tab-group>

</div>
<app-contact-form-component *ngIf="showPopupContact" (closePopup)="closePopupContact()"
  [initialContact]="messageSelected"></app-contact-form-component>
<div *ngIf="isMenuContactOpen" class="menu-dropdown-contact" [style.left.px]="coords.x" [style.top.px]="coords.y">
  <ul>
    <li (click)="addContact(messageSelected);">Adicionar Contato</li>
    <div class="menu-divider-contact"></div>
    <li (click)="closeMenuContactSelected()">Voltar</li>
  </ul>
</div>