import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import 'hammerjs';
import { ChartistModule } from 'ng-chartist';
import { ChartsModule } from 'ng2-charts';
import { OpenFinanceComponent } from '../components/open-finance/open-finance.component';
import { SharedModule } from '../components/shared.module';
import { BankersRoutes } from './bankers-routing.module';
import { BankerFileUploadComponent } from './components/banker-file-upload/banker-file-upload.component';
import { BankerProfileDataComponent } from './components/banker-profile-data/banker-profile-data.component';
import { BankerProfileNotificationsComponent } from './components/banker-profile-notifications/banker-profile-notifications.component';
import { BankerProfileSecurityComponent } from './components/banker-profile-security/banker-profile-security.component';
import { BndesCardComponent } from './components/bndes-card/bndes-card.component';
import { ChatMessageBankerComponent } from './components/chat-message-banker/chat-message-banker.component';
import { ConfirmCompleteOppComponent } from './components/confirm-complete-opp/confirm-complete-opp.component';
import { DataAggregatorComponent } from './components/data-aggregator/data-aggregator.component';
import { OpportunityNotesComponent } from './components/opportunity-notes/opportunity-notes.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { BankerSettingsComponent } from './containers/banker-settings/banker-settings.component';
import { BankerConcludedListComponent } from './containers/concluded-list/concluded-list.component';
import { BankerOpportunitiesComponent } from './containers/opportunities/opportunities.component';
import { BankerOpportunityComponent } from './containers/opportunity/opportunity.component';
import { BankerProfileComponent } from './containers/profile/profile.component';
import { BankerRejectedListComponent } from './containers/rejected-list/rejected-list.component';
import { TemplateBankersComponent } from './containers/template-bankers/template-bankers.component';

@NgModule({
  declarations: [
    BankerOpportunitiesComponent,
    BankerOpportunityComponent,
    BankerProfileComponent,
    TemplateBankersComponent,
    BankerRejectedListComponent,
    OpportunityNotesComponent,
    ConfirmCompleteOppComponent,
    BankerConcludedListComponent,
    ProgressBarComponent,
    BankerSettingsComponent,
    BankerProfileDataComponent,
    BankerProfileNotificationsComponent,
    BankerProfileSecurityComponent,
    BndesCardComponent,
    ChatMessageBankerComponent,
    BankerFileUploadComponent,
    DataAggregatorComponent,
    OpenFinanceComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ChartistModule,
    ChartsModule,
    RouterModule.forChild(BankersRoutes),
  ],
  // providers: [{
  //   provide: LocationStrategy,
  //   useClass: HashLocationStrategy
  // }]
})
export class BankersModule {}
