import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-opportunity-history-button-list',
  templateUrl: './opportunity-history-button-list.component.html',
  styleUrls: ['./opportunity-history-button-list.component.css']
})
export class OpportunityHistoryButtonListComponent {
  @Input() groupHistory = [];
  @Output() selectGroup = new EventEmitter<string>();

  onSelectGroup(groupId: string): void {
    this.selectGroup.emit(groupId);
  }


}
