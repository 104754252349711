export enum CreditasLoanUseOptions {
  'INVESTMENT_IN_OWN_BUSINESS' = 'investment_in_own_business', // Investimento
  'DEBTS_PAYMENT' = 'debts_payment', // Pagamento de dívidas
  'DEBTS_REFINANCING' = 'debts_refinancing', // Refinanciamento de dívidas
  'REAL_ESTATE_RENOVATION' = 'real_estate_renovation', // Reforma de casa
  'GOODS_ACQUISITION' = 'goods_acquisition', // Aquisição de Bens
  'OTHERS' = 'others', // Outros
}

export enum CreditasProfessionalSituationOptions {
  'CLT' = 'clt', // Assalariado/Registrado
  'SELF_EMPLOYED' = 'self_employed', // Autônomo
  'FREELANCER' = 'freelancer', // Profissional Liberal
  'BUSINESSMAN' = 'businessman', // Empresário
  'CIVIL_SERVANT' = 'civil_servant', // Funcionário Público
  'RETIRED' = 'retired', // Aposentado/Pensionista
}

export enum CreditasProposalStatus {
  'PENDING' = 'PENDING', // Enviado à Creditas - Em pré-análise
  'REJECTED' = 'REJECTED', // Rejeitado
  'ONGOING' = 'ONGOING', // Pré-aprovado e em andamento
  'PAID' = 'PAID', // Empréstimo pago
  'MANUAL_HANDLING' = 'MANUAL_HANDLING', // Tratamento Manual
}

export enum CreditasRealtyTypeOptions {
  'HOUSE' = 'house', // Casa
  'APARTMENT' = 'apartment', // Apartamento
  'COMMERCIAL' = 'commercial', // Imóvel comercial
  'LAND' = 'land', // Terreno
  'OTHERS' = 'others', // Outros
}

export enum CreditasRealtyRegistryOptions {
  'YES' = 'yes', // Sim, o solicitante possui a matrícula do imóvel usado como garantia.
  'NO' = 'no', // Não, o solicitante não possui a matrícula do imóvel usado como garantia.
  'DO_NOT_KNOW' = 'do_not_know', // O cliente não sabe o que é a matrícula do imóvel ou se possui ou não a matrícula.
}

export interface CreditasConfiguration {
  accessToken?: string;
  authUrl?: string;
  consumerKey: string;
  consumerSecret: string;
  expiresIn?: number;
  refreshToken?: string;
  tokenType?: string;
  url?: string;
}

export interface CreditasProposalStatusObject {
  id: string; // Identificador do empréstimo
  email: string;
  cnpj: string;
  status: CreditasProposalStatus;
  proposal?: {
    // Dados da proposta
    amount: number; // Valor da proposta de empréstimo
    interestRate: number; // Taxa de juros
    term: number; // Quantidade de parcelas
    url: string; // Endereço para configuração da proposta
  };
  sentAt?: string;
}

export const CreditasHeaders = {
  'Content-Type': 'application/json',
  'Accept-Version': 'v1',
};
