import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root',
})
export class OptinService {
  constructor(private angularFirestore: AngularFirestore) { }

  getOptinConfiguration(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc('configuracao/optin')
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            console.error('Optin document not found');
            reject(new Error('Optin document not found'));
          }
        })
        .catch((err) => {
          console.error('Error getting optin configuration', err);
          reject(err);
        });
    });
  }

  getOptinConfigurationScr(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc('configuracao/optinScr')
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            console.error('Optin document not found');
            reject(new Error('Optin document not found'));
          }
        })
        .catch((err) => {
          console.error('Error getting optin configuration', err);
          reject(err);
        });
    });
  }

  saveOptinConfiguration(data: any, cnpj: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection('optin-scr')
        .doc(cnpj)
        .set(data)
        .then(() => {
          console.log('Optin configuration saved successfully');
          resolve();
        })
        .catch((err) => {
          console.error('Error saving optin scr', err);
          reject(err);
        });
    });
  }

  getOptinConfigurationScrCustomers(cnpj: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection('optin-scr')
        .doc(cnpj)
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            reject('No opt-in configuration found');
          }
        })
        .catch((err) => {
          console.error('Error fetching optin scr', err);
          reject(err);
        });
    });
  }

}
