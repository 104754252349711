/* AUTH SERVICE
 *
 * This service is supposed to contain only the logic related
 * to handling the basic and common authentication. The specific
 * code for handling each one of the user types must be inserted
 * in their individual services.
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase, { firestore } from 'firebase';
import { User, UserModel, UserTypeFlags } from 'functions/src/models/User';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { analyticsReportConversion } from '../handler/googleAnalytics';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  user: BehaviorSubject<User> = new BehaviorSubject(null);
  loadedUser = false;

  userSubscription: Subscription;
  authSubscription: Subscription;
  

  idTokenUser: Observable<string>;
  userSessionToken: string;

  constructor(
    @Optional() @SkipSelf() auth: AuthService,
    private angularFireAuth: AngularFireAuth,
    private angularFirestore: AngularFirestore,
    private http: HttpClient
  ) {
    if (auth) {
      throw new Error('AuthService already injected');
    }

    // Listen for auth changes
    this.configUserObservables();
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  createAccount(email: string, password: string): Promise<UserModel> {
    let userAuth: UserModel;
    return new Promise((resolve, reject) => {
      this.angularFireAuth.auth.createUserWithEmailAndPassword(email, password).then((userCredential) => {
        userAuth = {
          uid: userCredential.user.uid,
          email: userCredential.user.email ?? '',
          emailVerified: userCredential.user.emailVerified,
          erroMsg: ''
        };
        resolve(userAuth);
      }).catch(e => {
        if (e && e.code) {
          let error = '';
          if (e.code === 'auth/email-already-in-use') {
            error = 'email-already-in-use';
          } else if (e.code === 'auth/wrong-password') {
            error = 'wrong-password';
          } else if (e.code === 'auth/invalid-email') {
            error = 'invalid-email';
          } else if (e.code === 'auth/argument-error') {
            error = 'argument-error';
          }
          reject(error);
        } else {
          console.error('login on e-mail pwd error: ', e.code);
          reject('error');
        }
      });
    });
  }

  changePassword(uid: string, password: string): Promise<void> {
    const user = this.angularFireAuth.auth.currentUser;
    return new Promise((resolve, reject) => {
      user
        .updatePassword(password)
        .then(() => {
          this.angularFirestore
            .doc(`users/${ uid }`)
            .update({ changedPassword: true })
            .then(() => {
              console.log('Password update flag successfully changed');
            })
            .catch((err) => {
              console.error('Error changing password update flag', err);
            })
            .finally(() => {
              resolve();
            });
        })
        .catch((err) => reject(err));
    });
  }

  changeEmail(email: string): Promise<void> {
    const user = this.angularFireAuth.auth.currentUser;
    const previousEmail = user.email;
    return new Promise(async (resolve) => {
      try {
        const snapshot = await this.angularFirestore
          .collection('users', (ref) => ref.where('email', '==', previousEmail))
          .get()
          .toPromise();

        if (!snapshot.empty) {
          const [docs] = snapshot.docs;
          const data = docs.data();
          const { isAgent, isBanker, isCustomer } = data;

          await user.updateEmail(email);

          try {
            await this.angularFirestore.doc(`users/${ user.uid }`).update({ email: email });
            console.log('Email update flag successfully changed (users)');
          } catch (err) {
            console.error('Error changing email update flag (users)', err);
          } finally {
            resolve();
          }

          try {
            await this.angularFirestore
              .doc(`users/${ user.uid }/logs/${ firestore.Timestamp.now().toDate().toISOString() }`)
              .set({
                date: firestore.Timestamp.now().toDate().toISOString(),
                event: 'update-email',
                user: email,
                newEmail: email,
                previousEmail: previousEmail,
              });
            console.log('Email update log successfully setted');
          } catch (err) {
            console.error('Error setting email update log', err);
          }

          if (isAgent) {
            try {
              await this.angularFirestore.doc(`agents/${ user.uid }`).update({ email: email });
              console.log('Email update flag successfully changed (agents)');
            } catch (err) {
              console.error('Error changing email update flag (agents)', err);
            }
          }

          if (isBanker) {
            try {
              await this.angularFirestore.doc(`bankers/${ user.uid }`).update({ email: email });
              console.log('Email update flag successfully changed (bankers)');
            } catch (err) {
              console.error('Error changing email update flag (bankers)', err);
            }
          }

          if (isCustomer) {
            try {
              await this.angularFirestore.doc(`customers/${ user.uid }`).update({ email: email });
              console.log('Email update flag successfully changed (customers)');
            } catch (err) {
              console.error('Error changing email update flag (customers)', err);
            }
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        resolve();
      }
    });
  }

  checkIfUserEmailExists(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      firestore()
        .collection('users')
        .where('email', '==', email.toLowerCase())
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            resolve(false);
          } else {
            resolve(true);
          }
        })
        .catch((err) => {
          console.error('Error checking if user email exists', err);
          reject(err);
        });
    });
  }

  /*
   * Uses e-mail or UID to get the user types.
   * The default key is UID. To get this info using e-mail you must
   * supply the second parameter as true
   */
  async checkUserTypes(key: string, isEmail = false): Promise<UserTypeFlags> {
    return new Promise((resolve, reject) => {
      let request;

      if (isEmail) {
        request = this.angularFirestore
          .collection('users', (ref) => ref.where(`email`, '==', key))
          .get()
          .toPromise();
      } else {
        request = this.angularFirestore.doc(`users/${ key }`).get().toPromise();
      }

      request
        .then((snapshot) => {
          if (isEmail && !snapshot.empty) {
            const { isAdmin, isAgent, isBanker, isCustomer } = snapshot.docs[0].data();

            return resolve({
              isAdmin: !!isAdmin,
              isAgent: !!isAgent,
              isBanker: !!isBanker,
              isCustomer: !!isCustomer,
            });
          } else if (!isEmail && snapshot.exists) {
            const { isAdmin, isAgent, isBanker, isCustomer } = snapshot.data();

            return resolve({
              isAdmin: !!isAdmin,
              isAgent: !!isAgent,
              isBanker: !!isBanker,
              isCustomer: !!isCustomer,
            });
          }

          return resolve(null);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  private async configUserObservables(): Promise<void> {
    this.idTokenUser = this.angularFireAuth.idToken;
    return new Promise((resolve) => {
      this.authSubscription = this.angularFireAuth.authState.subscribe((auth) => {
        if (auth) {
          if (!auth.isAnonymous) {
            this.updateUserWithAuthData(auth).then(() => {
              this.userSubscription = this.angularFirestore
                .doc<User>(`users/${ auth.uid }`)
                .valueChanges()
                .subscribe((user) => {
                  this.user.next(user);
                  resolve(null);
                });
            });
          } else {
            this.user.next({
              uid: auth.uid,
              email: auth.email,
              displayName: auth.displayName,
              emailVerified: auth.emailVerified,
              photoURL: auth.photoURL,
              isAnonymous: true,
              isAdmin: false,
              isAgent: false,
              isBanker: false,
              isCustomer: false,
            } as User);
            resolve(null);
          }
        } else {
          this.user.next(null);
          resolve(null);
        }
      });
    });
  }

  getClientIP(): Observable<any> {
    const header = new HttpHeaders();
    header.set('Content-Type', 'application/json');
    header.set('Access-Control-Allow-Origin', '*');

    return this.http.get(environment.providerIp);
  }

  getHeader(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${ this.userSessionToken }`,
      'Content-Type': 'application/json',
    });
  }

  async loginWithAnonymousAccount(): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.angularFireAuth.auth
        .signInAnonymously()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error('Error logging in anonymously', err);
          reject(err);
        });
    });
  }

  async loginWithEmailAndPassword(email: string, password: string): Promise<firebase.User> {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.auth
        .signInWithEmailAndPassword(email, password)
        .then((credential) => {
          this.updateUserWithAuthData(credential.user);
          resolve(credential.user);
        })
        .catch((err) => {
          console.error('Auth Service - Error on login', err);
          reject(err);
        });
    });
  }

  async loginWithGoogle(): Promise<firebase.User> {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.auth
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then((credential) => {
          resolve(credential.user);
        })
        .catch((err) => {
          console.error('Auth Service - Error on login with Google', err);
          reject(err);
        });
    });
  }
  
  async logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.auth
        .signOut()
        .then(() => {
          console.log('Logged out');
          resolve();
        })
        .catch((err) => {
          console.error('Error logging out', err);
          reject(err);
        });
    });
  }

  resetPassword(actionCode: string, newPassword: string, userEmail: string): Promise<void> {
    const auth = this.angularFireAuth.auth.app.auth();

    return new Promise((resolve, reject) => {
      auth
        .verifyPasswordResetCode(actionCode)
        .then((accountEmail) => {
          if (userEmail === accountEmail) {
            auth
              .confirmPasswordReset(actionCode, newPassword)
              .then(() => {
                console.log('Password reset has been confirmed and new password was updated');
                resolve();
              })
              .catch((err) => {
                console.log('Error reseting the password', err);
                reject(err);
              });
          } else {
            reject('invalid-email');
          }
        })
        .catch((err) => {
          console.error('Error verifying password reset code', err);
          reject(err);
        });
    });
  }

  sendEmailVerification(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.angularFireAuth.auth.currentUser && !this.angularFireAuth.auth.currentUser.emailVerified) {
        this.angularFireAuth.auth.currentUser
          .sendEmailVerification()
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve(false);
      }
    });
  }

  sendPasswordResetEmail(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const auth = this.angularFireAuth.auth.app.auth();
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          console.log('Reset password e-mail was sent');
          resolve();
        })
        .catch((err) => {
          console.log('Error sending reset password e-mail', err);
          reject(err);
        });
    });
  }

  updateUser(user: User): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`users/${ user.uid }`)
        .set(user, { merge: true })
        .then(() => {
          console.log('User updated', user.uid);
          resolve();
        })
        .catch((err) => {
          console.error('Error updating user data', err);
          reject(err);
        });
    });
  }

  private updateUserWithAuthData(authData: firebase.User): Promise<void> {
    const data: User = {
      uid: authData.uid,
      email: authData.email,
      emailVerified: authData.emailVerified,
      photoURL: authData.photoURL ? authData.photoURL : null,
      isAnonymous: authData.isAnonymous,
    };


      return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`users/${ authData.uid }`)
        .set(data, { merge: true })
        .then(() => {
          console.log('Updated user with auth data');
          resolve();
        })
        .catch((err) => {
          console.error('Error updating user data with auth', err);
          reject(err);
        });
    });
  }

  async verifyEmail(actionCode: string, continueUrl: string): Promise<string> {
    const auth = this.angularFireAuth.auth.app.auth();

    return new Promise((resolve, reject) => {
      auth
        .applyActionCode(actionCode)
        .then(() => {
          if (auth.currentUser) {
            auth.currentUser.reload();
            this.updateUserWithAuthData({ ...auth.currentUser, emailVerified: true });
          }

          analyticsReportConversion('funil', 'confirmar-email', 'confirmacao');
          resolve(continueUrl || '/entrar');
        })
        .catch((err) => {
          console.error('Error verifying email', err);
          reject(err);
        });
    });
  }

  verifyPasswordResetCode(code: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const auth = this.angularFireAuth.auth.app.auth();
      return auth
        .verifyPasswordResetCode(code)
        .then((email) => {
          resolve(email);
        })
        .catch((error) => {
          console.error('Error verifying reset password code', error);
          reject(error);
        });
    });
  }
}
