/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnInit } from '@angular/core';
import { Banker } from '../../../../../functions/src/models/Banker';
import { BankerService } from '../../services/banker.service';
@Component({
  selector: 'app-banker-profile-notifications',
  templateUrl: './banker-profile-notifications.component.html',
  styleUrls: ['./banker-profile-notifications.component.scss']
})
export class BankerProfileNotificationsComponent implements OnInit {
  @Input() banker: Banker;
  chatMessages = false;
  opportunityClosed = false;
  constructor(private bankerService: BankerService) { }

  ngOnInit(): void {
    this.chatMessages = this.banker.notifications?.email?.chatMessages || false;
    this.opportunityClosed = this.banker.notifications?.email?.opportunityClosed || false;
  }

  async onSetPermissionNotificationChatMessages(): Promise<void> {
    this.chatMessages = !this.chatMessages;
    const notifications = this.setNotificationParams();
    await this.bankerService
      .updateBankerOnFirestore(this.banker.uid, { notifications })
      .then((res) => {
        console.log('Success in checkbocks prermission chat messages ');
      })
      .catch((error) => {
        this.banker.notifications.email.opportunityClosed = false;
        console.log('Error Ocurrend in checkbocks prermission chat messages - ', error);
      });
  }



  async onSetPermissionNotificationOpportunityClosed(): Promise<void> {
    this.opportunityClosed = !this.opportunityClosed;

    const notifications = this.setNotificationParams();
    await this.bankerService
      .updateBankerOnFirestore(this.banker.uid, { notifications })
      .then((res) => {
        console.log('Success in checkbocks prermission opp closed ');
      })
      .catch((error) => {
        this.banker.notifications.email.opportunityClosed = false;
        console.log('Error Ocurrend in checkbocks prermission opp closed - ', error);
      });
  }

  private setNotificationParams(): Banker['notifications'] {
    return {
      statusChanged: this.banker.notifications.statusChanged,
      opportunityCreate: this.banker.notifications.opportunityCreate,
      opportunityUpdate: this.banker.notifications.opportunityUpdate,
      opportunityAccepted: this.banker.notifications.opportunityAccepted,
      opportunityRejected: this.banker.notifications.opportunityRejected,
      email: {
        opportunityClosed: this.opportunityClosed || false,
        chatMessages: this.chatMessages || false,
      }
    };
  }
}
