import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase';
import { OpportunityPhasesEntity } from '../../../../functions/src/models/opportunity/OpportunityPhases';

@Injectable({
  providedIn: 'root'
})
export class OpportunityPhasesService {

  constructor(private angularFirestore: AngularFirestore, ) { }

  getOpportunityPhases(customerId: string, institutionKey: string): Promise<OpportunityPhasesEntity[]> {
    return new Promise<OpportunityPhasesEntity[]>((resolve, reject) => {
      this.angularFirestore
        .collection('opportunityphases', ref => ref.where('customerId', '==', customerId).where('institutionKey', '==', institutionKey))
        .valueChanges()
        .subscribe(
          (oppPhasesData: OpportunityPhasesEntity[]) => {
            const oppPhases: OpportunityPhasesEntity[] = oppPhasesData.map(data => {
              const oppPhase = { ...data };

              if (oppPhase.stageChangeDate instanceof Date || typeof oppPhase.stageChangeDate === 'string') {
                oppPhase.stageChangeDate = firebase.firestore.Timestamp.fromDate(new Date(oppPhase.stageChangeDate)) || null;
              }

              return oppPhase as OpportunityPhasesEntity;
            });

            const sortedOppPhases = oppPhases.sort((a, b) => {
              if (a.stageChangeDate && b.stageChangeDate) {
                return b.stageChangeDate.toMillis() - a.stageChangeDate.toMillis();
              } else {
                return 0;
              }
            });

            resolve(sortedOppPhases);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  hasOppPhaseGreaterEqual(step: number, customerId: string, institutionKey: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.angularFirestore
        .collection('opportunityphases', ref => ref
          .where('customerId', '==', customerId)
          .where('institutionKey', '==', institutionKey)
          .where('stageNumber', '>=', step)
          .limit(1)
        )
        .get()
        .toPromise()
        .then(querySnapshot => {
          resolve(!querySnapshot.empty);
        })
        .catch(error => {
          console.log('Não foi encontrado', error);
          reject(false);
        });
    });
  }

  getOrderByPhases(customerId: string, institutionKey: string): Promise<OpportunityPhasesEntity[]> {
    return new Promise<OpportunityPhasesEntity[]>((resolve, reject) => {
      this.angularFirestore
        .collection('opportunityphases', ref => ref
          .where('customerId', '==', customerId)
          .where('institutionKey', '==', institutionKey)
          .orderBy('stageChangeDate', 'desc')

        )
        .valueChanges()
        .subscribe(
          (oppPhasesData: OpportunityPhasesEntity[]) => resolve(oppPhasesData),
          (error) => {
            console.error('Erro ao buscar fases da oportunidade:', error);
            reject(error);
          }
        );
    });
  }

}
