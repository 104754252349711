import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirmation-onboarding',
  templateUrl: './dialog-confirmation-onboarding.component.html',
  styleUrls: ['./dialog-confirmation-onboarding.component.scss']
})
export class DialogConfirmationOnboardingComponent {
  dialogData: any;
  currentStep: string;
  revenueValue: string;
  revenueDescription: string;

  constructor(private dialogRef: MatDialogRef<DialogConfirmationOnboardingComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.dialogData = data;
    this.currentStep = 'first';
    this.revenueValue = this.dialogData.revenueCtrl;
  }

  setRevenueValue(): void{
    const revenueString = this.revenueValue.replace('R$', '').replace(/\./g, '').replace(',', '.')
    const revenueStringNumber = parseFloat(revenueString.trim());

    if(revenueStringNumber > 5000000){
      this.currentStep = 'last'
      this.revenueDescription = '(Faturamento acima de 5 milhões de reais)'
    } else if(revenueStringNumber < 120000){
      this.currentStep = 'last'
      this.revenueDescription = '(Faturamento abaixo de 120 mil reais)'
    } else{
      this.dialogRef.close(true);
    }
  }

  onConfirm(): void{
    this.dialogRef.close(true);
  }

  onCancel(): void{
    this.dialogRef.close(false);
  }

}
