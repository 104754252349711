import { registerLocaleData } from '@angular/common';
import lcoaleDeAt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { AppSidebarComponent } from 'src/app/admin/templates/sidebar/sidebar.component';
import { TemplateAdminComponent } from 'src/app/admin/templates/template-admin/template-admin.component';
import { TemplateAgentsComponent } from 'src/app/agents/containers/template-agents/template-agents.component';
// proj
import { SharedModule } from 'src/app/components/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { SettingsModule } from 'src/app/core/settings';
import { CustomerModule } from 'src/app/customer/customer.module';
import { AppBlankComponent } from 'src/app/layouts/blank/blank.component';
import { AppHeaderComponent } from 'src/app/layouts/templates/header/header.component';
import { TemplateSignupComponent } from 'src/app/layouts/templates/template-signup/template-signup.component';
import { TemplateSiteComponent } from 'src/app/layouts/templates/template-site/template-site.component';
import { AgentHeaderOptionsComponent } from './agents/components/header-options/header-options.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BndesModule } from './bndes/bndes.module';
import { LogPublishersService } from './components/logger/log-publishers.service';
import { LogService } from './components/logger/log.service';
import { TemplateBndesSignupComponent } from './layouts/templates/template-bndes/template-bndes.component';
import { LoginLinkedinComponent } from './layouts/templates/login-linkedin/login-linkedin.component';
import { SelectWhatsappTemplateComponent } from './chat/components/select-whatsapp-template/select-whatsapp-template.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

registerLocaleData(lcoaleDeAt);

@NgModule({
  declarations: [
    AppComponent,
    TemplateSiteComponent,
    TemplateAdminComponent,
    TemplateAgentsComponent,
    TemplateSignupComponent,
    AgentHeaderOptionsComponent,
    AppSidebarComponent,
    AppBlankComponent,
    AppHeaderComponent,
    TemplateBndesSignupComponent,
    LoginLinkedinComponent,
    SelectWhatsappTemplateComponent,
  ],
  imports: [
    BndesModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    CustomerModule,
    SettingsModule,
    RouterModule.forRoot(AppRoutingModule),
    // AppRoutingModule,
    PerfectScrollbarModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    LogService,
    LogPublishersService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
