<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-lg-4">
      <mat-card>
        <mat-card-content>
          <mat-card-title>Cadastros Auxiliares</mat-card-title>
          <mat-card-subtitle> Consulte e altere os dados associados as intituições financeiras. </mat-card-subtitle>
          <div class="list-group">
            <button type="button" class="list-group-item list-group-item-action cursor-style"
              *ngFor="let item of cadastrosGerais" (click)="onClick(item)">
              {{ item.nome }}
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-12 col-lg-8" *ngIf="loggedAdmin">
      <mat-card *ngIf="isEmailGroup && !showDetails">
        <app-email-group [loggedAdmin]="loggedAdmin"></app-email-group>
      </mat-card>
      <mat-card *ngIf="showDetails">
        <mat-card-content>
          <mat-card-title>Alterar - {{ tituloCadastroGerais }}</mat-card-title>
          <mat-card-subtitle> Consulte e altere os dados associados as intituições financeiras. </mat-card-subtitle>
          <div *ngIf="!isCreateEdit">
            <div class="row">
              <div class="col-md-12">
                <button mat-raised-button *ngIf="loggedAdmin?.accessLevel === 'master' || loggedAdmin?.accessLevel === 'admin'" class="btn" color="accent" type="button" (click)="onAdicionarNovo()">
                  Novo
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field>
                  <input matInput (input)="applyFilter($event.target.value)" placeholder="Filtro" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <table mat-table [dataSource]="dataCadastrosGeraisItem" class="mat-elevation-z8"
                  *ngIf="tituloCadastroGerais !== 'Cupom' && tituloCadastroGerais !== 'Configurações Gerais' && tituloCadastroGerais !== 'Templates de Mensagens'">
                  <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef>Nome</th>
                    <td mat-cell *matCellDef="let cg">{{ cg.nome }}</td>
                  </ng-container>
                  <ng-container matColumnDef="limite" *ngIf="isRegimeTributario">
                    <th mat-header-cell *matHeaderCellDef>Limite</th>
                    <td mat-cell *matCellDef="let cg">{{ cg.limite }}</td>
                  </ng-container>
                  <ng-container matColumnDef="mnemonico">
                    <th mat-header-cell *matHeaderCellDef>Mnemonico</th>
                    <td mat-cell *matCellDef="let cg">{{ cg.mnemonico }}</td>
                  </ng-container>
                  <ng-container matColumnDef="ativo">
                    <th mat-header-cell *matHeaderCellDef>Ativo</th>
                    <td mat-cell *matCellDef="let cg">{{ cg.ativo === 'true' ? 'Sim' : 'Não' }}</td>
                  </ng-container>
                  <ng-container matColumnDef="acao">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let cg" (click)="$event.stopPropagation()">
                      <button *ngIf="loggedAdmin?.accessLevel === 'master'"  mat-button color="accent" (click)="onEditar(cg)">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <!-- Cupons -->
                <table mat-table [dataSource]="dataCadastrosGeraisItem" class="mat-elevation-z8"
                  *ngIf="tituloCadastroGerais === 'Cupom' ">
                  <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef>Nome Cupom</th>
                    <td mat-cell *matCellDef="let cg">{{ cg.nome }}</td>
                  </ng-container>
                  <ng-container matColumnDef="mnemonico">
                    <th mat-header-cell *matHeaderCellDef>Código</th>
                    <td mat-cell *matCellDef="let cg">{{ cg.codigo }}</td>
                  </ng-container>
                  <ng-container matColumnDef="ativo">
                    <th mat-header-cell *matHeaderCellDef>Ativo</th>
                    <td mat-cell *matCellDef="let cg">{{ cg.ativo === 'true' ? 'Sim' : 'Não' }}</td>
                  </ng-container>
                  <ng-container matColumnDef="acao">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let cg" (click)="$event.stopPropagation()">
                      <button mat-button *ngIf="loggedAdmin?.accessLevel === 'master'" color="accent" (click)="onEditarCupom(cg)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-button *ngIf="loggedAdmin?.accessLevel === 'master'" color="accent" (click)="deleteCupons(cg)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <!-- General Configs List -->

                <table mat-table [dataSource]="dataCadastrosGeraisItem" class="mat-elevation-z8"
                  *ngIf="tituloCadastroGerais === 'Configurações Gerais' ">
                  <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef>Nome</th>
                    <td mat-cell *matCellDef="let cg">{{ cg.nome }}</td>
                  </ng-container>
                  <ng-container matColumnDef="mnemonico">
                    <th mat-header-cell *matHeaderCellDef>Valor</th>
                    <td mat-cell *matCellDef="let cg">{{ cg.value }}</td>
                  </ng-container>
                  <ng-container matColumnDef="valueType" *ngIf="tituloCadastroGerais === 'Configurações Gerais' ">
                    <th mat-header-cell *matHeaderCellDef>Tipo</th>
                    <td mat-cell *matCellDef="let cg">{{ cg.valueType.name}}</td>
                  </ng-container>
                  <ng-container matColumnDef="ativo">
                    <th mat-header-cell *matHeaderCellDef>Ativo</th>
                    <td mat-cell *matCellDef="let cg">{{ cg.ativo === 'true' ? 'Sim' : 'Não' }}</td>
                  </ng-container>
                  <ng-container matColumnDef="acao">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let cg" (click)="$event.stopPropagation()">
                      <button mat-button  *ngIf="loggedAdmin?.accessLevel === 'master'" color="accent" (click)="onEditGeneralConfigs(cg)">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                 <!-- Template de Mensagens -->

             <table mat-table [dataSource]="dataCadastrosGeraisItem" class="mat-elevation-z8"
             *ngIf="tituloCadastroGerais === 'Templates de Mensagens' ">
             <ng-container matColumnDef="nome">
               <th mat-header-cell *matHeaderCellDef>Atalho</th>
               <td mat-cell *matCellDef="let cg">{{ cg.nome }}</td>
             </ng-container>
             <ng-container matColumnDef="mnemonico">
               <th mat-header-cell *matHeaderCellDef>Descrição</th>
               <td mat-cell *matCellDef="let cg">{{ cg.description }}</td>

             </ng-container>
             <ng-container matColumnDef="ativo">
               <th mat-header-cell *matHeaderCellDef>Ativo</th>
               <td mat-cell *matCellDef="let cg">{{ cg.ativo === 'true' ? 'Sim' : 'Não' }}</td>
             </ng-container>
             <ng-container matColumnDef="acao">
               <th mat-header-cell *matHeaderCellDef></th>
               <td mat-cell *matCellDef="let cg" (click)="$event.stopPropagation()">
                 <button *ngIf="['master'].includes(loggedAdmin.accessLevel)" mat-button color="accent" (click)="onEditMessage(cg)">
                   <mat-icon>edit</mat-icon>
                 </button>
                 <button *ngIf="['master'].includes(loggedAdmin.accessLevel)" mat-button color="accent" (click)="deleteMessage(cg)">
                   <mat-icon>delete</mat-icon>
                 </button>
               </td>
             </ng-container>
             <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
             <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
           </table>
           
              </div>
            </div>
          </div>
          <div *ngIf="isCreateEdit">


            <form [formGroup]="createEditFormGroup" (submit)="onSaveForm()"
              *ngIf="tituloCadastroGerais !== 'Cupom' && tituloCadastroGerais !== 'Configurações Gerais' && tituloCadastroGerais !== 'Templates de Mensagens'">
              <mat-form-field style="width: 100%">
                <input type="hidden" formControlName="idCtrl" name="id" value="" />
                <input matInput placeholder="Nome" formControlName="nomeCtrl" required name="nome"
                  (input)="slugifName($event.target.value)" />
                <mat-error *ngIf="createEditFormGroup.controls['nomeCtrl'].hasError('required')">Informe um
                  nome.</mat-error>
              </mat-form-field>
              <mat-form-field style="width: 100%" *ngIf="isRegimeTributario">
                <input type="hidden" formControlName="idCtrl" name="limite" />
                <input matInput placeholder="Limite de Faturamento" formControlName="limiteCtrl" name="limite"
                  [textMask]="{ mask: numberMask }" />
              </mat-form-field>
              <mat-form-field style="width: 100%">
                <input matInput placeholder="Mnemonico (preenchimento automático)" formControlName="mnemonicoCtrl"
                  required name="mnemonico" />
              </mat-form-field>

              <div class="row">
                <div class="col-md-12">
                  <mat-label>Ativo? </mat-label>
                  <mat-radio-group formControlName="ativoCtrl" required>
                    <mat-radio-button color="primary" value="false" class="m-r-10"
                      [checked]="createEditFormGroup.ativo">Não</mat-radio-button>&nbsp;
                    <mat-radio-button color="primary" value="true" class="m-r-10"
                      [checked]="createEditFormGroup.ativo">Sim</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="
                      createEditFormGroup.controls['ativoCtrl'].hasError('required') &&
                      createEditFormGroup.controls['ativoCtrl'].touched
                    ">Informe se está ativo.</mat-error>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-right">
                  <button mat-raised-button class="btn" color="warn" type="button"
                    (click)="onVoltar()">Voltar</button>&nbsp;
                  <button mat-raised-button class="btn" color="accent" type="submit"
                    [disabled]="!createEditFormGroup.valid">
                    Salvar
                  </button>
                </div>
              </div>
            </form>

            <!-- formularios cupom -->

            <form [formGroup]="createEditFormGroup" (submit)="onSaveCupom()" *ngIf="tituloCadastroGerais === 'Cupom' ">
              <mat-form-field style="width: 100%">
                <input matInput placeholder="Nome" formControlName="nomeCtrl" required name="nome"
                  (input)="slugifName($event.target.value)" />
                <mat-error *ngIf="createEditFormGroup.controls['nomeCtrl'].hasError('required')">Informe um
                  nome.</mat-error>
              </mat-form-field>
              <mat-form-field style="width: 100%" *ngIf="tituloCadastroGerais !== 'Cupom' ">
                <input matInput placeholder="Mnemonico (preenchimento automático)" formControlName="mnemonicoCtrl"
                  required name="mnemonico" />
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <input matInput placeholder="Código" formControlName="codigoCtrl" required name="codigo"
                  maxlength="10" />
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <input matInput placeholder="Responsável" formControlName="responsavelCtrl" required
                  name="responsavel" />
              </mat-form-field>

              <mat-form-field>
                <input matInput [matDatepicker]="dataInicio" id="dataDeInicio" placeholder="Data inicial do Cupom"
                  name="dataInicio" [min]="minDate" required formControlName="dataInicioCtrl"
                  (focus)="dataInicio.open()" />
                <mat-datepicker-toggle matSuffix [for]="dataInicio"></mat-datepicker-toggle>
                <mat-datepicker #dataInicio></mat-datepicker>
              </mat-form-field>

              <mat-form-field *ngIf="isEdit">
                <input matInput [matDatepicker]="dataFim" placeholder="Data final do Cupom" name="dataFim" required
                  [min]="minDate" formControlName="dataFimCtrl"
                  [disabled]="!createEditFormGroup.controls['dataInicioCtrl']?.value" (focus)="dataFim.open()" />
                <mat-datepicker-toggle matSuffix [for]="dataFim"></mat-datepicker-toggle>
                <mat-datepicker #dataFim></mat-datepicker>
              </mat-form-field>

              <mat-form-field *ngIf="!isEdit">
                <input matInput [matDatepicker]="dataFim" placeholder="Data final do Cupom" name="dataFim" required
                  [matDatepickerFilter]="dateFilter" formControlName="dataFimCtrl"
                  [disabled]="!createEditFormGroup.controls['dataInicioCtrl']?.value" (focus)="dataFim.open()" />
                <mat-datepicker-toggle matSuffix [for]="dataFim"></mat-datepicker-toggle>
                <mat-datepicker #dataFim></mat-datepicker>
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <input matInput placeholder="Descrição" formControlName="descricaoCtrl" required name="descricao" />
              </mat-form-field>

              <div class="row">
                <div class="col-md-12">
                  <mat-label>Tipo de desconto: </mat-label>
                  <mat-radio-group formControlName="tipoDescCtrl" required>
                    <mat-radio-button color="primary" value="%" class="m-r-10">%</mat-radio-button>&nbsp;
                    <mat-radio-button color="primary" value="Reais" class="m-r-10">Valor fixo</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>

              <mat-form-field style="width: 100%">
                <input matInput placeholder="Valor" formControlName="valorDescontoCtrl" required
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  name="valorDesconto" />
              </mat-form-field>

              <input type="hidden" formControlName="idCtrl" name="id" value="" />

              <div class="row">
                <div class="col-md-12">
                  <mat-label>Ativo? </mat-label>
                  <mat-radio-group formControlName="ativoCtrl" required>
                    <mat-radio-button color="primary" value="false" class="m-r-10"
                      [checked]="createEditFormGroup.ativo">Não</mat-radio-button>&nbsp;
                    <mat-radio-button color="primary" value="true" class="m-r-10"
                      [checked]="createEditFormGroup.ativo">Sim</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="
                      createEditFormGroup.controls['ativoCtrl'].hasError('required') &&
                      createEditFormGroup.controls['ativoCtrl'].touched
                    ">Informe se está ativo.</mat-error>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-right">
                  <button mat-raised-button class="btn" color="warn" type="button"
                    (click)="onVoltar()">Voltar</button>&nbsp;
                  <button mat-raised-button class="btn" color="accent" type="submit"
                    [disabled]="!isEdit && !createEditFormGroup.valid"
                    >
                    Salvar
                  </button>
                </div>
              </div>
            </form>

            <!-- formularios General Configs -->

            <form [formGroup]="createEditFormGroup" (submit)="onSaveGeneralConfigs()"
              *ngIf="tituloCadastroGerais === 'Configurações Gerais' ">
              <input type="hidden" formControlName="idCtrl" name="id" value="" />
              <mat-form-field style="width: 100%">
                <input matInput placeholder="Nome" formControlName="nomeCtrl" required name="nome"
                  (input)="slugifName($event.target.value)" />
                <mat-error *ngIf="createEditFormGroup.controls['nomeCtrl'].hasError('required')">Informe um
                  nome.</mat-error>
              </mat-form-field>
              <mat-form-field style="width: 100%">
                <input matInput placeholder="Mnemonico (preenchimento automático)" formControlName="mnemonicoCtrl"
                  required name="mnemonico" />
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <input matInput placeholder="Descrição da configuração" formControlName="descriptionCtrl" required
                  name="description" />
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <input matInput placeholder="Valor" formControlName="valueCtrl" required name="value" />
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <mat-label>Tipo do valor</mat-label>
                <mat-select required name="valueType" formControlName="valueTypeCtrl" [compareWith]="compareObjects">
                  <mat-option [value]="item" *ngFor="let item of valueType">{{item.name}}</mat-option>
                </mat-select>
              </mat-form-field>

              <input type="hidden" formControlName="createdAtCtrl" name="createdAt" value="" />

              <div class="row">
                <div class="col-md-12">
                  <mat-label>Ativo? </mat-label>
                  <mat-radio-group formControlName="ativoCtrl" required>
                    <mat-radio-button color="primary" value="false" class="m-r-10"
                      [checked]="createEditFormGroup.ativo">Não</mat-radio-button>&nbsp;
                    <mat-radio-button color="primary" value="true" class="m-r-10"
                      [checked]="createEditFormGroup.ativo">Sim</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="
                                  createEditFormGroup.controls['ativoCtrl'].hasError('required') &&
                                  createEditFormGroup.controls['ativoCtrl'].touched
                                ">Informe se está ativo.</mat-error>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-right">
                  <button mat-raised-button class="btn" color="warn" type="button"
                    (click)="onVoltar()">Voltar</button>&nbsp;
                  <button mat-raised-button class="btn" color="accent" type="submit"
                    [disabled]="!createEditFormGroup.valid">
                    Salvar
                  </button>
                </div>
              </div>
            </form>


             <!-- formularios Templates de Mensagens -->

             <form [formGroup]="createEditFormGroup" (submit)="onSaveTemplateMessage()"
             *ngIf="tituloCadastroGerais === 'Templates de Mensagens' ">
             <input type="hidden" formControlName="idCtrl" name="id" value="" />
             <mat-form-field style="width: 100%">
               <input matInput placeholder="Atalho" formControlName="nomeCtrl" required name="nome"
                 (input)="slugifName($event.target.value)" />
               <mat-error *ngIf="createEditFormGroup.controls['nomeCtrl'].hasError('required')">Informe um
                 nome.</mat-error>
             </mat-form-field>
             <mat-form-field style="width: 100%">
               <input matInput placeholder="Mnemonico (preenchimento automático)" formControlName="mnemonicoCtrl"
                 required name="mnemonico" />
             </mat-form-field>

             <mat-form-field style="width: 100%">
              <textarea
              matInput
              placeholder="Descrição"
              formControlName="descriptionCtrl"
              required
              name="description"
              matTextareaAutosize
              matAutosizeMinRows="2"
              ></textarea>
             </mat-form-field>

             <div class="row">
               <div class="col-md-12">
                 <mat-label>Ativo? </mat-label>
                 <mat-radio-group formControlName="ativoCtrl" required>
                   <mat-radio-button color="primary" value="false" class="m-r-10"
                     [checked]="createEditFormGroup.ativo">Não</mat-radio-button>&nbsp;
                   <mat-radio-button color="primary" value="true" class="m-r-10"
                     [checked]="createEditFormGroup.ativo">Sim</mat-radio-button>
                 </mat-radio-group>
                 <mat-error *ngIf="
                                 createEditFormGroup.controls['ativoCtrl'].hasError('required') &&
                                 createEditFormGroup.controls['ativoCtrl'].touched
                               ">Informe se está ativo.</mat-error>
               </div>
             </div>
             <div class="row">
               <div class="col-md-12 text-right">
                 <button mat-raised-button class="btn" color="warn" type="button"
                   (click)="onVoltar()">Voltar</button>&nbsp;
                 <button mat-raised-button class="btn" color="accent" type="submit"
                   [disabled]="!createEditFormGroup.valid">
                   Salvar
                 </button>
               </div>
             </div>
           </form>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
