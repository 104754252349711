import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-duvidas-frequentes',
  templateUrl: './duvidas-frequentes.component.html',
  styleUrls: ['./duvidas-frequentes.component.scss'],
})
export class DuvidasFrequentesComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
