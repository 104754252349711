import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { firestore } from 'firebase';
import { Customer, NewOtherInstitution } from 'functions/src/models/Customer';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { analyticsReportConversion } from 'src/app/core/handler/googleAnalytics';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { BndesIntegrationStatus } from '../../../../functions/src/models/Bndes';
import { User, UserRole } from '../../../../functions/src/models/User';
import { BasicStructure } from '../../../../functions/src/models/common/BasicStructure';
import { Utm } from '../../../../functions/src/models/common/Utm';
import { CustomerJourneyAboutCompanyEntity } from '../../../../functions/src/models/customers/customer-journey-about-company.entity';
import { timestampForDate } from '../../../../functions/src/utils/dates';
import { CadastrosGeraisService } from '../../admin/cadastros-gerais/cadastros-gerais.service';
import { InstituicaoFinanceiraService } from '../../admin/instituicao-financeira/instituicao-financeira.service';
import { HubspotService } from '../../core/services/hubspot.service';
import { PartnershipsService } from '../../core/services/partnerships.service';
import { ReceitaWSService } from '../../core/services/receita-ws.service';
import { CustomerService } from '../../customer/services/customer.service';
import { TemplateSignupService } from '../../layouts/templates/template-signup/template-signup.service';
import { DialogConfirmationOnboardingComponent } from '../dialog-confirmation-onboarding/dialog-confirmation-onboarding.component';

@Component({
  selector: 'app-form-create-account-no-term-vehicle',
  templateUrl: './form-create-account-no-term-vehicle.component.html',
  styleUrls: ['./form-create-account-no-term-vehicle.component.scss']
})
export class FormCreateAccountNoTermVehicleComponent implements OnInit {
  @Input() customer: Customer;
  @Input() proposalId: string;
  @Input() bndesSource: string;


  // Config and general/common control
  signupConfigSubscription: Subscription;
  generalConfigSubscription: Subscription;
  getTaxRegimesSubscription: Subscription;
  getCardMachinesSubscription: Subscription;
  subscriptions: Subscription;
  config: any;
  isProcessing = false;
  utm: Utm;
  hasPopUp = true;
  vehicleAge: boolean | null = null;
  vehiclePaid: boolean | null = null;

  // Auto-Complete
  separatorKeysCodes: number[] = [ENTER, COMMA];
  submitted = false;
  visible = true;
  selectable = true;
  removable = true;
  filteredNewOtherInstitutions: Observable<(NewOtherInstitution | string)[]>;
  newOtherInstitutions: NewOtherInstitution[] = [];
  allNewOtherInstitutions: NewOtherInstitution[] = [];
  confirmationDialogData: any;


  @ViewChild('newOtherInstitutionInput') newOtherInstitutionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;



  readonly steps = ['company', 'financial', 'personal'];
  step = 'company';
  privacyPolicyUrl: string;

  readonly selectTypeOfAnticipationOperator = [
    { id: 'cartao', value: 'Cartão' },
    { id: 'boleto', value: 'Boleto' },
    { id: 'nota_fiscal', value: 'Notas Fiscais' },
  ];

  readonly selectTypeMaritalStatus = [
    { id: 'solteiro', value: 'Solteiro(a)' },
    { id: 'casado', value: 'Casado(a)' },
    { id: 'separado', value: 'Separado(a)' },
    { id: 'divorciado', value: 'Divorciado(a)' },
    { id: 'viuvo', value: 'Viúvo(a)' },
  ];

  readonly selectTypeOfInvoices = [
    { id: 'nota_servico', value: 'Notas de Serviço' },
    { id: 'nota_produto', value: 'Notas de Produto' },

  ];

  // Forms
  companyDataFormGroup: FormGroup;
  financialDataFormGroup: FormGroup;
  personalDataFormGroup: FormGroup;

  // Company Section
  agent: string;
  CESource: string;
  // Financial Section
  hasOtherInstitutions = false;

  // Credit Section
  hasOnlyAvalGuarantee = true;

  // Type Guarantee Antecipation
  hasTypeOfAnticipation = false;

  // Type Guarantee Antecipation Card
  hasTypeOfAnticipationCard = false;

  // Type Guarantee Antecipation Nota fiscal
  hasTypeOfInvoices = false;

  // MEI Section
  hasTaxRegimeMei = false;

  // Masks and validation
  readonly MASKS = MASKS;
  formatPhone = UtilHandler.formatPhone;
  readonly numberMask = createNumberMask({
    thousandsSeparatorSymbol: '.',
    integerLimit: 15,
    prefix: 'R$ ',
    suffix: '',
  });
  texts: { title: string; subtitle: string; stageId: string }[];
  lastY: number;
  gclid: string;
  runReceitaWs: boolean;
  companyCNPJSubscription: Subscription;
  passwordMatchValidator(formGroup: FormGroup): void {
    const error =
      formGroup.get('passwordCtrl').value === formGroup.get('confirmCtrl').value ? null : { mismatch: true };
    formGroup.get('confirmCtrl').setErrors(error);
  }
  lastNameMatchValidatior(formGroup: FormGroup): void {
    const error =
      formGroup
        .get('nameCtrl')
        .value?.split(' ')
        .filter((value) => value).length > 1
        ? null
        : { invalidlastname: true };
    formGroup.get('nameCtrl').setErrors(error);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private customerService: CustomerService,
    private cadastrosGeraisService: CadastrosGeraisService,
    private instFinanceirasAutocomplete: InstituicaoFinanceiraService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private hubspotService: HubspotService,
    private signupService: TemplateSignupService,
    private partnerService: PartnershipsService,
    private receitaWsService: ReceitaWSService
  ) {
    this.subscriptions = new Subscription();
    this.utm = {};

  }
  ngOnInit(): void {
    this.urlUtm();
    this.initializeForms();
    this.subscriptions.add(
      this.customerService.getGeneralConfiguration().subscribe((config) => {
        this.privacyPolicyUrl = config.privacyPolicyUrl;
      })
    );
    this.signupService.setStep(this.step);
    this.subscriptions.add(
      this.customerService.getSignUpConfiguration().subscribe((config) => {
        this.config = config;
        this.config.minRevenue = config?.minRevenue ?? 360000;
        this.config.minRevenueStr = config?.minRevenueStr ?? 'R$ 360.000';
      })
    );

    this.getCardMachinesSubscription = this.cadastrosGeraisService.getMaquinasAtivas().subscribe((maquinas) => {
      maquinas.map((maquina) => {
        maquina['id'] = maquina.mnemonico;
        maquina['name'] = maquina.nome;
        delete maquina.ativo;
        delete maquina.mnemonico;
        delete maquina.nome;
      });
      this.config.cardMachines = maquinas;
    });
    this.getTaxRegimesSubscription = this.cadastrosGeraisService.getRegimesAtivos().subscribe((regimes) => {
      regimes.map((regime) => {
        regime['id'] = regime.mnemonico;
        regime['name'] = regime.nome;

        delete regime.ativo;
        delete regime.mnemonico;
        delete regime.nome;
      });

      this.config.taxRegimes = regimes;
    });

    this.validateAgent();
    this.customerService.customersInRegister();
  }
  ngOnDestroy(): void {
    if (this.signupConfigSubscription) {
      this.signupConfigSubscription.unsubscribe();
    }
    if (this.getTaxRegimesSubscription) {
      this.getTaxRegimesSubscription.unsubscribe();
    }
    if (this.getCardMachinesSubscription) {
      this.getCardMachinesSubscription.unsubscribe();
    }
    if (this.companyCNPJSubscription) {
      this.companyCNPJSubscription.unsubscribe();
    }
  }

  

  areAllFormsValid(): boolean {
    return (
      this.companyDataFormGroup.valid &&
      this.financialDataFormGroup.valid &&
      this.personalDataFormGroup.valid
    );
  }

  checkCompanyFields(): void {
    this.isProcessing = true;

    this.confirmationDialogData = this.companyDataFormGroup.value;

    const dialogRef = this.dialog.open(DialogConfirmationOnboardingComponent, {
      data: this.confirmationDialogData
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        Promise.all([
          this.authService.checkIfUserEmailExists(this.companyDataFormGroup.get('emailCtrl').value?.toLowerCase()),
          this.customerService.checkIfUserWhatsAppExists(this.companyDataFormGroup.get('whatsappCtrl').value),
          this.customerService.checkIfEmailExistsAsCustomer(this.companyDataFormGroup.get('emailCtrl').value?.toLowerCase()),
          this.customerService.checkIfCNPJExistsAsCustomer(this.companyDataFormGroup.get('cnpjCtrl').value),
          this.customerService.checkIfEmailAsCustomerjourneycontrol(this.companyDataFormGroup.get('cnpjCtrl').value)
        ])
          .then(async (results) => {
            const [emailAsUser, whatsappExists, emailAsCustomer, cnpjAsCustomer, emailAsJourneycontrol] = results;

            console.log("resultado do email", emailAsJourneycontrol);
            if (emailAsJourneycontrol && emailAsJourneycontrol.email && emailAsJourneycontrol.email !== this.companyDataFormGroup.get('emailCtrl').value?.toLowerCase()) {
              try {
                await this.hubspotService.deleteAllHubspot(emailAsJourneycontrol);
              } catch (error) {
                console.error('Erro ao deletar todos os dados do Hubspot:', error);
              }

              try {
                await this.hubspotService.deleteAllFb(this.companyDataFormGroup.get('cnpjCtrl').value);
              } catch (error) {
                console.error('Erro ao deletar todos os dados do Facebook:', error);
              }
            }

            if (!emailAsUser && !whatsappExists && !emailAsCustomer && !cnpjAsCustomer) {
              await this.nextStep(this.step);
              await this.signupService.setStep(this.step);
            } else if (emailAsCustomer) {
              this.showMessage(
                'E-mail já cadastrado',
                'O e-mail informado já possui uma conta. Entre no sistema para continuar.'
              );
            } else if (emailAsUser) {
              this.showMessage(
                'E-mail já cadastrado',
                'O e-mail informado já possui uma conta com outro papel no sistema. No momento só é permitido um papel por conta.'
              );
            } else if (whatsappExists) {
              this.showMessage(
                'WhatsApp já cadastrado',
                'O número de WhatsApp informado já possui uma conta. Entre no sistema para continuar.'
              );
            } else if (cnpjAsCustomer) {
              this.showMessage(
                'CNPJ já cadastrado',
                'O CNPJ informado já possui uma conta. Entre no sistema para continuar.'
              );
            }
            this.isProcessing = false;
          })
          .catch(() => {
            this.isProcessing = false;
            this.showMessage('Erro ao validar', 'Ocorreu um erro ao validar seus dados. Tente novamente.');
          });
      } else {
        this.isProcessing = false;
      }
    });
  }

  async checkFinancialFields(): Promise<void> {
    this.isProcessing = true;
    console.log(this.step)
    await this.nextStep(this.step);
    this.isProcessing = false;
  }

  getCustomerDataFromForm(): Customer {
    const customerData = this.getAboutCompanyData();
    this.getFinancialHealthData(customerData);
    this.getCreditStatusData(customerData);
    this.getAboutYou(customerData);

    const source = this.tagOrigin(); // Chame tagOrigin() uma vez e armazene o valor

    return {
      agent: this.agent,
      createdBy: this.agent
        ? {
          uid: this.agent,
          type: 'AGENT',
        }
        : null,
      email: customerData.email,
      cnpj: customerData.cnpj,
      companyName: customerData.companyName,
      whatsapp: customerData.whatsapp,
      // website: customerData.website,
      address: customerData.address,
      revenue: customerData.revenue,
      revenueStr: customerData.revenueStr,
      stepHubspot: customerData.stepHubspot,
      // debt: customerData.debt,
      // debtStr: customerData.debtStr,
      taxRegime: customerData.taxRegime,
      banks: customerData.banks,
      banksOther: customerData.banksOther,
      creditValue: customerData.creditValue,
      creditValueStr: customerData.creditValueStr,
      purpose: customerData.purpose,
      guarantees: customerData.guarantees,
      typeOfAnticipation: customerData.typeOfAnticipation,
      // guaranteesValue: customerData.guaranteesValue,
      // guaranteesValueStr: customerData.guaranteesValueStr,
      creditCard: customerData.creditCard,
      typeOfInvoices: customerData.typeOfInvoices,
      name: customerData.name,
      displayName: customerData.name,
      cpf: customerData.cpf,
      birthDate: customerData?.birthDate || null,
      maritalStatus: customerData?.maritalStatus || null,
      personalCell: customerData?.personalCell || null,
      hasPermissionToSign: customerData.hasPermissionToSign,
      whatsAppContact: customerData.whatsAppContact,
      cnae: customerData.cnae,
      utm: {
        ...this.utm,
        source: source, // Atribui o valor de source a utm.source
      },
      gclid: this.gclid,
      lastContact: null,
      attributedTo: null,
      source: source, // Atribui o valor de source a source
      userConsent: {
        privacyPolicyDate: this.companyDataFormGroup.controls.agreedPrivacyPolicyCtrl.value
          ? firestore.Timestamp.now().toMillis()
          : null,
        showUserConsent: false,
      },
      companyInformation: {},
      emailVerified: false,
    };
  }


  async createAccount(): Promise<void> {
    this.isProcessing = true;
    const customerData: Customer = this.getCustomerDataFromForm();
    const userData: User = {
      name: customerData.name,
      displayName: customerData.name,
      changedPassword: true,
      email: customerData.email,
      emailVerified: false,
      isAdmin: false,
      isAgent: false,
      isAnonymous: false,
      isBanker: false,
      isCustomer: true,
      lastInteraction: firestore.Timestamp.now(),
      mainRole: UserRole.Customer,
      photoURL: null,
      userConsent: customerData.userConsent,
    };
    try {
      this.isProcessing = true;
      if (this.proposalId) {
        customerData.proposalId = this.proposalId;
        customerData.bndesIntegrationStatus = BndesIntegrationStatus.ACTIVE;
      }

      this.setTermParamCustomer(customerData);
      this.setNoTerms(customerData);

      analyticsReportConversion('funil', 'cadastro-pessoal', 'sobre-voce');
      await this.customerService.setAboutYou(customerData);

      await this.customerService.createAccount(customerData, userData, this.personalDataFormGroup.value.passwordCtrl);
      this.isProcessing = false;

      this.router.navigate(['/confirmar-email']);
    } catch (error) {
      let title = 'Erro no cadastro';
      let text = 'Houve um problema ao tentar criar seu usuário. Por favor, tente novamente mais tarde.';
      let redirectTo = '';
      if (error.name && error.name === 'create-account-error') {
        if (error.message === 'email-already-in-use' || error.message === 'wrong-password') {
          title = 'E-mail em uso';
          text = `O e-mail ${ customerData.email } já está em uso. Faça login clicando em Entrar no canto superior direito da tela.`;
        } else if (error.message === 'invalid-email') {
          title = 'E-mail/Senha incorreto';
          text =
            'Você informou um e-mail ou senha incorreto. Por favor, verifique seus dados e tente novamente. ' +
            'Caso não lembre sua senha, clique em "Esqueci minha senha" para criar uma nova.';
        } else if (error.message === 'argument-error') {
          title = 'E-mail/Senha incorreto';
          text =
            'Os dados de acesso informados, estão incorretos. Por favor informe os dados corretos e tente novamente.';
        }
      } else if (error.message && error.message === 'save-user-error') {
        title = 'Erro no login';
        text =
          'Seu usuário foi criado com sucesso, mas houve um problema interno. Por favor, tente novamente mais tarde.';
      } else if (error.message && error.message === 'user-login-error') {
        title = 'Erro no login';
        text =
          'Seu usuário foi criado com sucesso, mas houve um problema ao tentar realizar seu login automaticamente.';
        redirectTo = '/entrar';
      } else if (error.message && error.message === 'send-email-verification-error') {
        title = 'Erro na verificação';
        text =
          'Seu cadastro foi realizado com sucesso, mas houve um problema ao tentar enviar o email de verificação de conta. Por favor, entre em contato pelo email: contato@capitalempreendedor.com.br';
      }
      this.showMessage(title, text)
        .afterClosed()
        .subscribe(() => {
          this.isProcessing = false;
          if (redirectTo) {
            this.router.navigate([redirectTo]);
          }
        });
    }
  }

  private setNoTerms(customerData: Customer): void {

      customerData.noTermsCustomer = true;
      customerData.agreedTerms = true;
     
  }

  private setTermParamCustomer(customerData: Customer): void {
    const partner = this.partnerService?.partner;
    if (!partner) {
      console.log("Cliente Não esta associado a um parceiro");
    } else {
      customerData.partnerCE = this.partnerService?.partner.partner;
      console.log("Cliente esta associado ao parceiro -", partner.partner);
      if (partner.isTerm === false) {
        customerData.noTermsCustomer = true;
        customerData.agreedTerms = true;
      }
    }
  }

  formatMoney(str: string): string {
    if (str?.indexOf(',') < 0) {
      return str + ',00';
    } else if (str?.indexOf(',') === str.length - 1) {
      return str + '00';
    } else if (str?.indexOf(',') === str.length - 2) {
      return str + '0';
    } else {
      return str || '';
    }
  }



  emailMatchValidator(formGroup: FormGroup): void {
    const error =
      formGroup.get('emailCtrl').value === formGroup.get('emailMatchCtrl').value ? null : { mismatch: true };
    formGroup.get('emailMatchCtrl').setErrors(error);
  }

  initializeForms(): void {
    this.companyDataFormGroup = this.formBuilder.group(
      {
        agreedPrivacyPolicyCtrl: new FormControl('', [Validators.required]),
        emailCtrl: new FormControl(this.customer?.email ?? '', [Validators.required, Validators.email]),
        emailMatchCtrl: new FormControl(this.customer?.email ?? '', [Validators.required, Validators.email]),
        cnpjCtrl: new FormControl(this.customer?.cnpj ?? '', [Validators.required, NgBrazilValidators.cnpj]),
        companyNameCtrl: new FormControl(this.customer?.companyName ?? '', [Validators.required]),
        whatsappCtrl: new FormControl(this.customer?.whatsapp ?? '', [
          Validators.required,
          NgBrazilValidators.telefone,
          Validators.minLength(14),
        ]),
        revenueCtrl: new FormControl(this.customer?.revenueStr?.replace(',00', '') ?? '', [Validators.required]),
        guaranteesCtrl: new FormControl(this.customer?.guarantees ?? '', [Validators.required]),
        typeOfAnticipationCtrl: new FormControl(this.customer?.typeOfAnticipation ?? ''),
        creditCardCtrl: new FormControl(this.customer ?? ''),
        typeOfInvoicesCtrl: new FormControl(this.customer ?? ''),
      },
      { validators: this.emailMatchValidator }
    );


    this.financialDataFormGroup = this.formBuilder.group({
      moreThanTwentyYearsCtrl: new FormControl(null, [Validators.required]),
      moreThanEightyPercentPaidCtrl: new FormControl(null, [Validators.required]),
    });

    this.personalDataFormGroup = this.formBuilder.group(
      {
        nameCtrl: new FormControl(this.customer?.name ?? '', [Validators.required]),
        passwordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
        cpfCtrl: new FormControl(this.customer?.cpf ?? '', [Validators.required, NgBrazilValidators.cpf]),
        whatsAppContactCtrl: new FormControl(true),
        confirmCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
        permissionCtrl: new FormControl('', [Validators.required]),
      },
      { validators: [this.passwordMatchValidator, this.lastNameMatchValidatior] }
    );


    this.observeCnpjControl();


  }
  compareObjects(o1: any, o2: any): boolean {
    return o1?.name === o2?.name && o1?.id === o2?.id;
  }


  setVehicleAge(value: boolean) {
    console.log("Setting moreThanTwentyYearsCtrl to:", value);
    this.vehicleAge = value;
    this.financialDataFormGroup.patchValue({ moreThanTwentyYearsCtrl: value });
  }

  setVehiclePaid(value: boolean) {
    console.log("Setting moreThanEightyPercentPaidCtrl to:", value);
    this.vehiclePaid = value;
    this.financialDataFormGroup.patchValue({ moreThanEightyPercentPaidCtrl: value });
  }

  async nextStep(current: string): Promise<void> {
    const customerData = this.getCustomerDataFromForm();

    console.log(current)
    this.isProcessing = true;
    switch (current) {
      case 'company':
        analyticsReportConversion('funil', 'cadastro-empresa', 'sobre-empresa');
        console.log(customerData.stepHubspot);
        const { agent } = this.activatedRoute.snapshot.params;
        console.log(customerData)
        await this.customerService.setRegisterCustomer(customerData, agent);
        break;
      case 'financial':
        analyticsReportConversion('funil', 'cadastro-financas', 'saude-financeira');
        this.getFinancialHealthData(customerData);
        await this.customerService.setFinancialHealth(customerData);
        break;
      case 'credit':
        analyticsReportConversion('funil', 'cadastro-credito', 'situ-credito');
        this.getCreditStatusData(customerData);
        await this.customerService.setCreditStatus(customerData);
        break;
    }

    this.hubspotService
      .upsertCustomer(customerData)
      .then(() => {
        console.log(`Step: ${ current } - Integrated to Hubspot`);
      })
      .catch((err) => {
        console.error(`Error integrating hubspot for step ${ current }`, err);
      });

    this.step = this.steps[this.steps.indexOf(current) + 1];
    this.isProcessing = false;
  }

  previousStep(current: string): void {
    this.step = this.steps[this.steps.indexOf(current) - 1];
  }

  private observeCnpjControl(): void {
    this.companyCNPJSubscription = this.companyDataFormGroup.get('cnpjCtrl').valueChanges.subscribe((value) => {
      if (!this.runReceitaWs && this.companyDataFormGroup.get('cnpjCtrl').valid) {
        this.runReceitaWs = true;
        this.customerService.getCustomerDataOnCustomerjourneycontrol(value).then((customerJData) => {
          let isLessThanFillData = false;
          if (customerJData) {
            const diffInMs = timestampForDate(customerJData.createdAt).getTime() - new Date().getTime();
            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
            if (diffInDays < this.config.customerJourneyFillDataTime) {
              isLessThanFillData = true;
              const mappedCustomer = this.mapCustomerJourneyToCustomer(customerJData);

              if (this.customer) {
                this.customer = { ...this.customer, ...mappedCustomer };
              } else {
                this.customer = mappedCustomer;
              }
              this.initializeForms();
            }
          }

          if (isLessThanFillData === false) {
            this.receitaWsService.requestDataReceitaWS(value).then(res => {
              if (this.customer) {
                this.customer = { ...this.customer, ...res };
                this.customer.cnae = res.cnae;

                console.log(res)
              } else {
                this.customer = res;
                this.customer.cnae = res.cnae;
              }
              this.initializeForms();
            })
          }

        }).finally(() => {
          this.runReceitaWs = false;
        });


      }
    });
  }

  mapCustomerJourneyToCustomer(
    journey: Partial<CustomerJourneyAboutCompanyEntity>
  ): Partial<Customer> {
    return {
      uid: journey.uid,
      cnpj: journey.cnpj,
      companyName: journey.razaoSocial,
      email: journey.email,
      whatsapp: journey.whatsapp,
      revenue: journey.faturamentoAnual,
      taxRegime: journey.taxRegime,
      banks: journey.banks,
      guarantees: journey.guarantees,
      creditValue: journey.creditValue,
      creditValueStr: journey.creditValue ? String(journey.creditValue) : undefined,
      revenueStr: journey.faturamentoAnual ? String(journey.faturamentoAnual) : undefined,
      purpose: journey.purpose,
      address: journey.address ? {
        zipCode: journey.address?.cep,
        street: journey.address?.logradouro,
        number: journey.address?.numero,
        extraInfo: journey.address?.complemento,
        neighborhood: journey.address?.bairro,
        city: journey.address?.cidade,
        state: journey.address?.uf,
      } : undefined,
      stepHubspot: journey.step,
      cpf: journey.cpf,
      ip: journey.ip,
      utm: journey.utmSource ? {
        source: journey.utmSource?.source,
        medium: journey.utmSource?.medium,
        campaign: journey.utmSource?.campaign,
      } : undefined,
    };
  }


  confirmWhatsApp(event: any): void {
    const valorSelecionado = event.value;
    const obj = {
      date: Date.now(), 
      value: valorSelecionado === 'Sim' ? true : false,
    };

    console.log('Objeto criado:', obj);
  }

  onChangeGuarantees(event: MatSelectChange): void {
    if (event.value.length && event.value.find((g) => g.id === 'boleto')) {
      this.hasTypeOfAnticipation = true;
    } else {
      this.hasTypeOfAnticipation = false;
      this.hasTypeOfAnticipationCard = false;
      this.hasTypeOfInvoices = false;
      this.companyDataFormGroup.get('typeOfAnticipationCtrl').clearValidators();
      this.companyDataFormGroup.get('typeOfAnticipationCtrl').setValue(null);
      this.companyDataFormGroup.get('creditCardCtrl').clearValidators();
      this.companyDataFormGroup.get('creditCardCtrl').setValue(null);
      this.companyDataFormGroup.get('typeOfInvoicesCtrl').clearValidators();
      this.companyDataFormGroup.get('typeOfInvoicesCtrl').setValue(null);
      this.companyDataFormGroup.updateValueAndValidity();
    }

    // if has only aval
    if (event.value.length === 1 && event.value[0].id === 'avalista') {
      this.hasOnlyAvalGuarantee = true;
      this.showMessage(
        'Tem certeza? ',
        'Usando garantia na operação de crédito, sua chance de tomada cresce em até 50%.'
      );
    } else {
      this.hasOnlyAvalGuarantee = false;
    }
  }

  onChangeAntecipation(event: MatSelectChange): void {

    if (event.value.length && event.value.find((model: BasicStructure) => model.id === 'cartao')) {
      this.hasTypeOfAnticipationCard = true;
    } else {
      this.hasTypeOfAnticipationCard = false;
      this.companyDataFormGroup.get('creditCardCtrl').clearValidators();
      this.companyDataFormGroup.updateValueAndValidity();
      this.companyDataFormGroup.get('creditCardCtrl').setValue(null);
    }

    if (event.value.length && event.value.find((model: BasicStructure) => model.id === 'nota_fiscal')) {
      this.hasTypeOfInvoices = true;
    } else {
      this.hasTypeOfInvoices = false;
      this.companyDataFormGroup.get('typeOfInvoicesCtrl').clearValidators();
      this.companyDataFormGroup.updateValueAndValidity();
      this.companyDataFormGroup.get('typeOfInvoicesCtrl').setValue(null);
    }
  }

  onChangeInstitutions(event): void {
    if (event.value.find((a) => a.id === 'outro')) {
      this.hasOtherInstitutions = true;
      this.financialDataFormGroup.get('otherInstitutionsCtrl').setValue('');

    } else {
      this.hasOtherInstitutions = false;
      this.financialDataFormGroup.get('otherInstitutionsCtrl').setValue('Outro');
      this.newOtherInstitutions = [];
    }
  }


  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }

  validateAgent(): void {
    const { agent } = this.activatedRoute.snapshot.params;
    if (agent) {
      let isEmailParam = false;
      if (agent.match(UtilHandler.emailRegex)) {
        isEmailParam = true;
      }

      this.customerService
        .validateAgent(agent, isEmailParam)
        .then((uid) => {
          this.agent = uid;
          this.CESource = this.tagOrigin();
        })
        .catch((err) => {
          console.error(err);
          if (err === 'not-found') {
            this.showMessage(
              'Assessor de crédito não encontrado',
              `O assessor de crédito informado não foi encontrado. Caso tenha alguma dúvida, favor encaminha-la para <strong>ops@capitalempreendedor.com.br</strong>.`
            );
          } else {
            this.showMessage(
              'Erro ao obter dados do assessor de crédito',
              `Houve um problema interno ao tentar obter os dados do assessor de crédito que foi informado. Por favor, tente novamente mais tarde.`
            );
          }
        });
    }
  }

  tagOrigin(): string {
    const source =
      this?.bndesSource ??
      (this.activatedRoute.snapshot.queryParams.utm_source ||
        this.activatedRoute.snapshot.queryParams.ce_source ||
        null);

    if (typeof source !== 'string' && source?.length > 0) {
      // verificar se é array e se for transformar em string
      return source?.join(' - ');
    } else if (source) {
      // remover posiveis caracteres que prejudiquem
      return source?.replace('[', '')?.replace(']', '') ?? source;

    } else if (this.agent) {
      return 'assessor link';
    } else {
      return 'organico';
    }
  }

  urlUtm(): void {
    const allParams = this.activatedRoute.snapshot.queryParamMap;

    const utmParms = allParams.keys.filter((f) => f.startsWith('utm_'));
    if (utmParms?.length > 0) {

      for (const paramKey of utmParms) {
        this.utm[paramKey.replace('utm_', '')] = allParams.get(paramKey);

      }
    }

    console.log('utm', this.utm)

    if (allParams.has('gclid')) {
      this.gclid = allParams.get('gclid'); // Atribui o valor do parâmetro 'gclid' à variável 'gclid'
      console.log('gclid', this.gclid);
    } else {
      console.log('gclid não encontrado na URL');
    }
  }

  revenueValidation(): void {
    const revenue = parseFloat((this.companyDataFormGroup.get('revenueCtrl')?.value ?? '1').replace(/\D/g, ''));
    if (revenue < this.config.minRevenue) {
      this.companyDataFormGroup.get('revenueCtrl').setErrors({ min: true });
    }
  }

  getAboutCompanyData(): Customer {
    const partialCustomerData: Customer = {
      email: this.companyDataFormGroup.controls.emailCtrl.value.trim().toLowerCase(),
      cnpj: this.companyDataFormGroup.controls.cnpjCtrl.value.trim(),
      companyName: this.companyDataFormGroup.controls.companyNameCtrl.value.trim().toUpperCase(),
      cnae: this.customer?.cnae || '',
      whatsapp: this.companyDataFormGroup.value.whatsappCtrl.trim(),
      revenue: parseFloat(
        (this.companyDataFormGroup.controls.revenueCtrl.value.trim() || '0').replace(/[^\,\d]/g, '').replace(/\,/g, '.')
      ),
      revenueStr: this.formatMoney(this.companyDataFormGroup.controls.revenueCtrl.value.trim()),
      stepHubspot: 1,
    };
    return partialCustomerData;
  }

  getFinancialHealthData(customerData: Customer): void {

    customerData.stepHubspot = 2;

  }

  getCreditStatusData(customerData: Customer): void {
    customerData.guarantees = this.companyDataFormGroup.controls.guaranteesCtrl.value;
    customerData.typeOfAnticipation = this.companyDataFormGroup.controls.typeOfAnticipationCtrl?.value ?? null;
    customerData.creditCard = this.companyDataFormGroup.controls.creditCardCtrl?.value ?? null;
    customerData.typeOfInvoices = this.companyDataFormGroup.controls.typeOfInvoicesCtrl?.value ?? null;

    if (this.companyDataFormGroup.controls.guaranteesCtrl.value) {
      customerData.stepHubspot = 3;
    }
  }

  getAboutYou(customerData: Customer): void {
    customerData.name = this.personalDataFormGroup.value.nameCtrl.trim().toUpperCase();
    customerData.cpf = this.personalDataFormGroup.value.cpfCtrl.trim();
    customerData.hasPermissionToSign = this.personalDataFormGroup.value.permissionCtrl;
    if (this.personalDataFormGroup.value.cpfCtrl) {
      customerData.stepHubspot = 4;
    }
    customerData.whatsAppContact = {
      date: firestore.Timestamp.now(),
      value: this.personalDataFormGroup.value.whatsAppContactCtrl,
    };
  }


  isValidForm() {

    if (this.financialDataFormGroup.invalid) {
      return true;
    }
    return false;
  }

}
