import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { BasicStructure } from 'functions/src/models/common/BasicStructure';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Constantes, UserHistory, UserHistoryLogDetail } from 'src/app/core/models/model-interfaces';
import { LogPublisher } from './log-publishers';
import { LogPublishersService } from './log-publishers.service';

// ****************************************************
// Log Level Enumeration
// ****************************************************
export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6,
}

// ****************************************************
// Log Entry Class
// ****************************************************
export class LogEntry {
  // Public Properties
  logUser: UserHistory;
  logUserDetail: UserHistoryLogDetail;

  // **************
  // Public Methods
  // **************
  buildLogString(): string {
    let value = '';

    value = this.logUserDetail.entryLogDate.toDate() + ' - ';
    value += 'Type: ' + this.logUserDetail.logLevel.name;
    value += ' - Message: ' + this.logUserDetail.message;
    if (this.logUserDetail.extraInfo.length) {
      value += ' - Extra Info: ' + this.formatParams(this.logUserDetail.extraInfo);
    }

    return value;
  }

  // ***************
  // Private Methods
  // ***************
  private formatParams(params: any[]): string {
    let ret = params.join(',');

    // Is there at least one object in the array?
    if (params.some((p) => typeof p === 'object')) {
      ret = '';
      // Build comma-delimited string
      for (const item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }
    return ret;
  }
}

// ****************************************************
// Log Service Class
// ****************************************************
@Injectable()
export class LogService {
  // Public Properties
  publishers: LogPublisher[];
  controllerName: string;
  private userSubscription: Subscription;

  constructor(private publishersService: LogPublishersService, private authService: AuthService) {
    // Set publishers
    this.publishers = this.publishersService.publishers;
  }

  // *************************
  // Public methods
  // *************************
  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, Constantes.logLevelDebug, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, Constantes.logLevelInfo, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, Constantes.logLevelWarn, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, Constantes.logLevelError, optionalParams);
  }

  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, Constantes.logLevelFatal, optionalParams);
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, Constantes.logLevelLog, optionalParams);
  }

  clear(): void {
    for (const logger of this.publishers) {
      logger.clear().subscribe((response) => console.log(response));
    }
  }

  // *************************
  // Private methods
  // *************************
  private shouldLog(level: BasicStructure): boolean {
    let ret = false;
    if (level !== Constantes.logLevelOff) {
      ret = true;
    }
    return ret;
  }

  private writeToLog(msg: string, level: BasicStructure, params: any[]) {
    if (this.shouldLog(level)) {
      this.userSubscription = this.authService.user.subscribe((user) => {
        const currentDatetime = firebase.firestore.Timestamp.now();
        const loggedUser: UserHistory = {
          nome: null,
          email: 'none@ce-system',
        };
        if (user) {
          loggedUser.nome = null;
          loggedUser.email = user.email;
          // Somente o tipo de log info e fatal, altera a data de ultima interação do usuário com sistema.
          if (level.id === Constantes.logLevelInfo.id || level.id === Constantes.logLevelFatal.id) {
            loggedUser.dataUltimaInteracao = currentDatetime;
          }
        }
        // Declare variables
        const entry: LogEntry = new LogEntry();
        entry.logUser = loggedUser;
        // Build Log Entry
        entry.logUserDetail = {
          emailLoggedUser: loggedUser.email,
          logLevel: level,
          controllerName: this.controllerName,
          message: msg,
          extraInfo: params,
          entryLogDate: currentDatetime,
        };
        for (const logger of this.publishers) {
          logger.log(entry).subscribe().unsubscribe();
        }
        if (this.userSubscription) {
          this.userSubscription.unsubscribe();
        }
      });
    }
  }
}
