import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { firestore } from 'firebase';
import { UserNotificationEntity } from 'functions/src/models/UserNotificationEntity';
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { Customer } from '../../../../functions/src/models/Customer';
import { User } from '../../../../functions/src/models/User';
import { environment } from '../../../environments/environment';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import { AuthService } from '../../core/auth/auth.service';
import { ChatContactEntity } from '../models/ChatContactEntity';
import { ChatMessageEntity } from '../models/ChatMessageEntity';
import { ChatMenuItem } from '../models/chat-menuItem';
import { ContactsCustomer, SendChatDocumentMessageRequestEntity, SendChatImageMessageRequestEntity, SendChatTextMessageRequestEntity } from '../models/contact';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  extraContactsCollection: AngularFirestoreCollection;
  lastCustomerDocument: any = null;

  customersCollection = this.firestore.collection<Customer>('customers');

  constructor(private firestore: AngularFirestore, private http: HttpClient, private authService: AuthService, private dialog: MatDialog,) { }

  isValidDateFormat(dateString: string): boolean {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    return dateRegex.test(dateString);
  }

  updateCdcMessageReadDate(contact: string, typeChat = "admin"): Promise<void> {
    const readDate = new Date()
    return this.firestore
      .collection('cdc-contacts')
      .doc(contact?.replace(/\D+/g, ''))
      .collection<ChatMessageEntity>('cdc-messages', (ref) => ref
        .where('readDate', '==', null)
        .orderBy('sendDate')
        .orderBy('readDate', 'desc'))
      .get().toPromise()
      .then(res => res.docs
        .filter(d => typeChat === "admin" ? d.data().sender?.type !== 'admin' || d.data().messageType?.toLocaleUpperCase() === 'NOTES' : d.data().sender?.type === 'admin'))
      .then(res => res.length > 0 ?
        Promise.all(res.map(async d => await d.ref.set({ readDate }, { merge: true }))) : Promise.resolve(null))
  }

  async updateLastMessageNotRead(contact: string, typeChat = "admin"): Promise<void> {
    try {
      const snapshot = await this.firestore
        .collection('cdc-contacts')
        .doc(contact?.replace(/\D+/g, ''))
        .collection('cdc-messages', ref => ref.orderBy('readDate', 'desc'))
        .get().toPromise();
  
      const filteredDocs = snapshot.docs.filter(doc => {
        const data = doc.data() as ChatMessageEntity;
        return typeChat === "admin" ? data.sender?.type !== 'admin' : data.sender?.type === 'admin';
      });
  
      if (filteredDocs.length > 0) {
        await filteredDocs[0].ref.set({ readDate: null }, { merge: true });
      } else {
        const err = "Não foi encontrada nenhuma mensagem recebida deste cliente.";

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Atenção',
            alertDescription: `${ err ?? 'Erro desconhecido' }`,
            isOnlyConfirm: true
          },
        });

        throw new Error(err);
      }
    } catch (error) {
      console.error(error.message);
      return Promise.reject(error);
    }
  }

  getCustomersByLastContact(
    limit = 100,
    filters?: string[],
    filterValues?: string[],
    menuitem?,
    loggedUser?,
    selectedMenuitem?,
    orderBy = 'lastContact'
  ): Observable<ContactsCustomer[]> {
    if (orderBy === 'notifications' && (!filterValues || filterValues.length === 0)) {
      const queryWithMessages = this.firestore.collection<ContactsCustomer>('customers', ref => {
        let query: firebase.firestore.Query = ref;
        query = this.queryCustomerbyMenu(menuitem, query, loggedUser, selectedMenuitem);
        query = query
          //.where('lastContact', '<=', today)
          .where('totalUnreadMessages', 'not-in', [0])
          .orderBy('totalUnreadMessages', 'desc')
          .orderBy('lastContact', 'desc')
        return query.limit(limit);
      }).snapshotChanges().pipe(
        map(changes => changes.map(c => ({ key: c.payload.doc.data().uid, ...c.payload.doc.data() as ContactsCustomer })))
      );
      return queryWithMessages.pipe(
        switchMap(customersWithMessages => {
          const numLoaded = customersWithMessages.length;
          const remainingLimit = limit - numLoaded;

          console.log('customersWithMessages: ', customersWithMessages.length)
          if (remainingLimit <= 0) {
            return of(customersWithMessages);
          }

          const queryWithoutMessages = this.firestore.collection<ContactsCustomer>('customers', ref => {
            let query: firebase.firestore.Query = ref;
            query = this.queryCustomerbyMenu(menuitem, query, loggedUser, selectedMenuitem);
            query = query.where('totalUnreadMessages', '==', 0)
              .orderBy('totalUnreadMessages', 'desc')
              .orderBy('lastContact', 'desc');
            return query.limit(remainingLimit); // Limita com o número restante
          }).snapshotChanges().pipe(
            map(changes => changes.map(c => ({ key: c.payload.doc.data().uid, ...c.payload.doc.data() as ContactsCustomer })))
          );

          // Combina os resultados das duas consultas
          return queryWithoutMessages.pipe(
            map(customersWithoutMessages => [...customersWithMessages, ...customersWithoutMessages])
          );
        })
      );
    } else {
      return this.firestore.collection('customers', ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = this.queryCustomerbyMenu(menuitem, query, loggedUser, selectedMenuitem);

        if (filters && filterValues) {
          if (filters.length === 0) {
            query = query.orderBy('lastContact', 'desc');
          } else {
            for (let i = 0; i < filters.length; i++) {
              const filter = filters[i];
              const filterValue = filterValues[i];

              if (filter && filterValue) {
                if (filter === 'lastContact') {
                  if (this.isValidDateFormat(filterValue)) {
                    const filterDate = this.parseDateString(filterValue);
                    const nextDay = new Date(filterDate);
                    nextDay.setDate(nextDay.getDate() + 1);
                    query = query
                      .where('lastContact', '>=', filterDate)
                      .where('lastContact', '<', nextDay)
                      .orderBy(orderBy || 'lastContact', 'desc');
                  }
                } else if (filter == "companyName" || filter == "cnpj") {
                  query = query.where(filter, '>=', filterValue.toLocaleUpperCase())
                    .where(filter, '<=', filterValue.toLocaleUpperCase() + '\uf8ff')
                    .orderBy(filter || 'companyName', 'desc');
                } else {
                  console.log('orderBy', orderBy)
                  if (orderBy === 'notifications') {
                    query = query
                      .orderBy('lastContact', 'desc')
                      .orderBy('totalUnreadMessages', 'desc');
                  } else {
                    query = query.orderBy(filter || (orderBy || 'lastContact'), 'desc');
                  }

                }
              }
            }
          }
        }
        return query.limit(limit);

      }).snapshotChanges().pipe(map(this.pipeCustomerContact));
    }
  }

  private queryCustomerbyMenu(menuitem: string, query: firestore.Query<firestore.DocumentData> | firestore.CollectionReference<firestore.DocumentData>, loggedUser: any, selectedMenuitem: ChatMenuItem): firestore.Query<firestore.DocumentData> | firestore.CollectionReference<firestore.DocumentData> {
    if (menuitem) {
      if (menuitem === 'sem-atribuicao') {
        query = query.where('attributedTo', '==', null);
      } else if (menuitem === 'atribuidos-a-mim') {
        query = query.where('attributedTo', '==', loggedUser?.uid);
      } else if (menuitem === 'colaborador' && selectedMenuitem?.uid) {
        query = query.where('attributedTo', '==', selectedMenuitem?.uid);
      }
    }
    return query;
  }

  pipeCustomerContact(docs: DocumentChangeAction<unknown>[]): ContactsCustomer[] {
    return docs?.map((d) => {
      this.lastCustomerDocument = d.payload.doc;

      const customer = d.payload.doc.data() as Customer;
      const { companyName, uid, attributedTo, attributedToName, attributedToNotification, cnpj, whatsAppContact, whatsapp, totalUnreadMessages } = customer;

      const contactCustomer: ContactsCustomer = {
        totalUnreadMessages: customer?.totalUnreadMessages ?? null,
        whatsAppContact,
        customerId: uid, lastContact: customer.lastContact ?? null, contacts: [],
        companyName, uid, whatsapp, attributedTo, attributedToName, attributedToNotification, cnpj, photoWhatsapp: customer.photoWhatsapp ?? '../../../assets/images/ellipse-gray.png'
      };

      return contactCustomer;

    }) ?? [];
  }

  parseDateString(dateString: string): Date {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
  }

  getPlugConfig(): Observable<any> {
    return this.firestore.collection('configuracao').doc('plug-message').valueChanges();
  }

  obseveContactsByCustomer(cnpj: string): Observable<ChatContactEntity[]> {
    return this.firestore.collection<ChatContactEntity>('cdc-contacts', (ref) => ref.where('cnpj', '==', cnpj)).valueChanges();
  }

  getContactsByCustomer(cnpj: string): Promise<firestore.QuerySnapshot<firestore.DocumentData>> {
    return this.firestore.collection<ChatContactEntity>('cdc-contacts', (ref) => ref.where('cnpj', '==', cnpj)).get().toPromise();
  }

  getUsersWithAdminRole(): Observable<User[]> {
    return this.firestore.collection<User>('users', ref => ref.where('isAdmin', '==', true).where('sector', '==', 'operation').where('active', '==', true)).valueChanges();
  }

  getUsersWithAdminDigitalBanker(): Observable<User[]> {
    return this.firestore.collection<User>('users', ref => ref.where('isAdmin', '==', true).where('sector', '==', 'operation').where('active', '==', true).where('digitalBanker', '==', true)).valueChanges();
  }

  customerAttributedTo(uid: string, attributedTo: string, attributedToName: string, attributedToNotification: boolean): Promise<void> {
    return this.customersCollection.doc(uid).update({ attributedTo, attributedToName, attributedToNotification });
  }

  customerDesattibuted(uid: string): Promise<void> {
    return this.customersCollection.doc(uid).update({ attributedTo: null, attributedToName: null, attributedToNotification: false });
  }

  updateAtributedNotificationOfCustomer(uid: string, attributedToNotification: boolean): Promise<void> {
    return this.updateCustomer(uid, { attributedToNotification });
  }

  updateTotalUnreadMessagesOfCustomer(uid: string, totalUnreadMessages: number): Promise<void> {
    return this.updateCustomer(uid, { totalUnreadMessages });
  }

  updateCustomer(uid: string, data: Partial<Customer>): Promise<void> {
    return this.customersCollection.doc(uid).set(data, { merge: true });
  }

  getCustomerByUid(uid: string) {
    return this.firestore.collection('customers').doc(uid).valueChanges();
  }

  getContactById(id: string) {
    return this.firestore.collection('cdc-contacts').doc(id).valueChanges();
  }

  async notifyNewMessageToCustomer(customerData: Customer, nameSender: string, message: string): Promise<void> {
    try {
      const notificationData = UserNotificationEntity.generateNotification(customerData, message, nameSender)

      await this.http
        .post(
          `${ environment.functionsUrl }/email/notify-new-note`,
          {
            ...notificationData,
            type: 'user', // estranho pois a notificação esta como type ADMIN
            senderType: notificationData.type,
          },
          { responseType: 'text', headers: this.authService.getHeader() }
        ).toPromise()
        .then(res => console.log(res))
        .catch(error => console.error(error))

      console.log('Notificação por email enviada com sucesso.');
    } catch (error) {
      console.error('Erro ao enviar notificação por email.', error);
    }
  }

  sendTextMessage(data: SendChatTextMessageRequestEntity, customer?: Customer): Promise<void> {
    const payload = { data };
    const sendMessagePromise = this.http
      .post(
        `${ environment.functionsUrl }/chatapi/send-text-message`,
        payload,
        { responseType: 'json', headers: this.authService.getHeader() }
      )
      .toPromise();

    return Promise.all([sendMessagePromise/*, sendNotificationPromise*/])
      .then(() => {
        console.log('mensagem enviada');
      })
      .catch((err) => {
        console.error('Erro ao enviar mensagem', err);

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao enviar mensagem',
            alertDescription: `Ocorreu um erro ao enviar mensagem. Erro: ${ err.error.error.message ?? 'Erro desconhecido' }`,
            isOnlyConfirm: true
          },
        });

        throw new Error('error-msg');
      });
  }

  sendImageMessage(data: SendChatImageMessageRequestEntity, customer?: Customer): Promise<void> {
    const payload = { data };
    const sendNotificationPromise = UserNotificationEntity.needNotificationCustomer(data) ? this.notifyNewMessageToCustomer(customer, data.sender?.name, 'Você recebeu uma imagem.') : null;
    const sendImageMessagePromise = this.http
      .post(
        `${ environment.functionsUrl }/chatapi/send-image-message`,
        payload,
        { responseType: 'json', headers: this.authService.getHeader() }
      )
      .toPromise();
    return Promise.all([sendImageMessagePromise, sendNotificationPromise])
      .then(() => {
        console.log('mensagem enviada');
      })
      .catch((err) => {
        console.error('Erro ao enviar imagem', err);

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao enviar imagem',
            alertDescription: `Ocorreu um erro ao enviar imagem. Erro: ${ err.error.error.message ?? 'Erro desconhecido' }`,
            isOnlyConfirm: true
          },
        });
        throw new Error('error-msg');
      });
  }
  sendDocumentMessage(data: SendChatDocumentMessageRequestEntity, customer?: Customer): Promise<void> {
    const payload = { data };
    const sendNotificationPromise = UserNotificationEntity.needNotificationCustomer(data) ? this.notifyNewMessageToCustomer(customer, data.sender?.name, 'Você recebeu um documento.') : null;
    const sendDocumentMessagePromise = this.http
      .post(
        `${ environment.functionsUrl }/chatapi/send-document-message`,
        payload,
        { responseType: 'json', headers: this.authService.getHeader() }
      )
      .toPromise()

    return Promise.all([sendDocumentMessagePromise, sendNotificationPromise])
      .then(() => {
        console.log('mensagem enviada');
      })
      .catch((err) => {
        console.error('Erro ao enviar documento', err);

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao enviar documento',
            alertDescription: `Ocorreu um erro ao enviar documento. Erro: ${ err.error.error.message ?? 'Erro desconhecido' }`,
            isOnlyConfirm: true
          },
        });
        throw new Error('error-msg');

      });
  }
  async updateLastContactCustomerFormatDate(): Promise<void> {
    try {
      const querySnapshot = await this.firestore.collection('customers', ref => ref.where('lastContact.seconds', '!=', '')).get().toPromise();

      const updatePromises: Promise<void>[] = [];

      querySnapshot.docs.forEach(doc => {
        const data = doc.data();
        const timestamp: firestore.Timestamp = data.lastContact;

        const lastContactDate = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);

        const updatePromise = doc.ref.update({ lastContact: lastContactDate });
        updatePromises.push(updatePromise);
      });

      await Promise.all(updatePromises);
    } catch (error) {
      console.error('Erro ao atualizar os customers no updateLastContactCustomerFormatDate:', error);
      throw error;
    }
  }

  getCdcMessagesCustomer(contact: string, isDescending = true) {
    return this.firestore.collection('cdc-contacts')
      .doc(contact)
      .collection<ChatMessageEntity>('cdc-messages', ref =>
        ref
          .where('contactId', '==', contact)
          .orderBy('sendDate', isDescending ? 'desc' : 'asc')
      )
      .valueChanges().pipe(map(mList => mList.filter(m => !m?.deleteMessage)));
  }

  getCdcMessages(contact: string, page: number, itemsPerPage = 20, isDescending = true): Observable<ChatMessageEntity[]> {


    console.log(`Requesting messages for contact ${ contact }, page ${ page }, itemsPerPage ${ itemsPerPage }`);

    return this.firestore.collection('cdc-contacts')
      .doc(contact)
      .collection<ChatMessageEntity>('cdc-messages', ref => {
        let query: firebase.firestore.Query = ref;

        query = query
          .where('contactId', '==', contact)
          .orderBy('sendDate', isDescending ? 'desc' : 'asc');

        query = query.limit(itemsPerPage);

        // console.log(query);
        return query;
      })
      .valueChanges();
  }


  getMessageReadCount(contact: string, uid?: string): Observable<{ unreadCount: number }> {
    return this.firestore.collection('cdc-contacts').doc(contact).collection<ChatMessageEntity>('cdc-messages', ref =>
      ref
        .where('readDate', '==', null)
        //.where('sender.uid', '==', uid)
        .orderBy('sendDate')
    ).valueChanges()
      .pipe(
        map(res => ({ unreadCount: res.filter(message => message?.sendingType?.toLocaleUpperCase() !== 'CHATBOT' && message?.sender?.uid !== '' && (message.sender.type !== 'admin' || message?.messageType?.toLocaleUpperCase() === 'NOTES')).length || 0 })),
        catchError(error => {
          console.error('Ocorreu um erro na consulta ao Firestore:', error);
          return throwError(error);
        }));
  }


  getUnReadByAllContacts(cnpj: string, customer?: ContactsCustomer): Promise<{ totalUnreadMessages: number }> {
    const result: { totalUnreadMessages: number } = { totalUnreadMessages: 0 }
    return new Promise<{
      totalUnreadMessages: number;
    }>((resolve, reject) => {
      const sub = this.obseveContactsByCustomer(cnpj)
        .pipe(
          first()
        ).subscribe({
          next: async (contactList) => {
            for (const contact of contactList) {
              const getMessageReadCountSub = await this.getMessageReadCount(
                contact.whatsapp,
              )
                .pipe(
                  first()
                ).subscribe(res => {
                  result.totalUnreadMessages = res?.unreadCount ? result.totalUnreadMessages + res.unreadCount : result.totalUnreadMessages;
                  if (customer) {
                    customer.totalUnreadMessages = result.totalUnreadMessages
                  }
                  getMessageReadCountSub.unsubscribe()
                })
            }
            await this.updateCustomer(customer.uid, { totalUnreadMessages: result.totalUnreadMessages })
            resolve(result)
            sub.unsubscribe()
          }
        })
    });
  }

  deleteMessage(message): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          `${ environment.functionsUrl }/chatapi/delete-message/${ message.contactId }/${ message.firestoreId }`,
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('mensagem deletada');
          resolve();
        })
        .catch((err) => {
          console.error('Erro ao deletar mensagem', err);
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro ao deletar a mensagem',
              alertDescription: `Ocorreu um erro ao deletar a mensagem. Erro: ${ err.error.error.message ?? 'Erro desconhecido' }`,
              isOnlyConfirm: true
            },
          });
          reject('error-msg');
        });
    });
  }


  updateMessage(message, data): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .patch(
          `${ environment.functionsUrl }/chatapi/update-message/${ message.contactId }/${ message.firestoreId }`,
          {
            data: { 'text': data, 'editMessage': true }
          },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('mensagem deletada');
          resolve();
        })
        .catch((err) => {
          console.error('Erro ao editar mensagem', err);
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro ao editar a mensagem',
              alertDescription: `Ocorreu um erro ao editar a mensagem. Erro: ${ err.error.error.message ?? 'Erro desconhecido' }`,
              isOnlyConfirm: true
            },
          });
          reject('error-msg');
        });
    });
  }

  // SendChatTextMessageRequestEntity
  updateReplyMessage(data: any): Promise<void> {
    return new Promise((resolve, reject) => {
      console.log("sent to functions to update the reply message");
      resolve();
      this.http
        .post(
          `${ environment.functionsUrl }/chatapi/send-reply-message`,
          {
            data: data
          },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('resposta da mensagem enviada');
          resolve();
        })
        .catch((err) => {
          console.error('Erro ao responder mensagem', err);
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro ao responder a mensagem',
              alertDescription: `Ocorreu um erro ao responder a mensagem. Erro: ${ err.error.error.message ?? 'Erro desconhecido' }`,
              isOnlyConfirm: true
            },
          });
          reject('error-msg');
        });
    });
  }

  getCountCdcMessagesCustomerAsync(contact: any, uid: string): Promise<{ unreadCount: number }> {
    return new Promise<{ unreadCount: number }>((resolve, reject) => {
      const queryCDC = this.firestore.collection('cdc-contacts').doc(contact).collection('cdc-messages', ref =>
        ref
          .where('readDate', '==', null)
          .where('customerId', '==', uid)
          .where('sender.type', '==', 'admin')
          .orderBy('sendDate')
      ).valueChanges();

      queryCDC.pipe(
        map((resultCDC: any[]) => {
          const unreadCount = resultCDC.length;
          resolve({ unreadCount });
        }),
        catchError(error => {
          console.error('Ocorreu um erro na consulta ao Firestore:', error);
          reject(error);
          return throwError(error);
        })
      ).subscribe();
    });
  }
}
