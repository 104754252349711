import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Serasa } from '../../../../functions/src/models/Serasa';

@Injectable({
  providedIn: 'root'
})
export class SerasaService {
  constructor( private angularFirestore: AngularFirestore) {}

  getSerasaData(uid: string): Observable<Serasa> {
    return this.angularFirestore.collection('serasa-negative-data-history', 
      ref => ref.where('uid', '==', uid).orderBy('date', 'desc').limit(1)).get().pipe(
        map(querySnapshot => {
          const dataArray = querySnapshot.docs.map(doc => doc.data() as Serasa);
          return dataArray.length > 0 ? dataArray[0] : null;
        })
      );
  }
}
