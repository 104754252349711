/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { Customer } from 'functions/src/models/Customer';
import {
    AgentNote,
    BankerNote,
    Note,
    NoteTemplate,
    NoteTemplateRecord,
    UserNote,
    UserNotification,
} from 'functions/src/models/Notes';
import { Observable, Subscriber } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ZendeskZupportApiReadComments } from '../../../../functions/src/models/Zendesk';
import { timestampForDate } from '../../../../functions/src/utils/dates';
import { fillTemplate } from '../../utils/Utils';
import { AgentsAdminService } from './agents-admin.service';
import { BankersAdminService } from './bankers-admin.service';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  constructor(
    private agentsAdmin: AgentsAdminService,
    private angularFirestore: AngularFirestore,
    private authService: AuthService,
    private bankersAdmin: BankersAdminService,
    private http: HttpClient
  ) { }

  createAgentNote(
    uid: string,
    note: AgentNote,
    notificationData: { companyName: string },
    type = 'agent'
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const now = firestore.Timestamp.now();

      this.angularFirestore
        .collection('customers')
        .doc(uid)
        .collection('agent-notes')
        .doc(timestampForDate(now).toISOString())
        .set({
          ...note,
          createdAt: now,
          id: timestampForDate(now).toISOString(),
        })
        .then(() => {
          this.agentsAdmin
            .getAgentData(note.agent)
            .pipe(take(1))
            .toPromise()
            .then((agent) => {
              if (agent?.isActive) {
                this.http
                  .post(
                    `${ environment.functionsUrl }/email/notify-new-note`,
                    {
                      [note.type.toLocaleLowerCase()]: note.createdBy.name,
                      content: note.message,
                      customer: `${ notificationData.companyName } (${ uid }) `,
                      recipients: [{ Name: agent.name, Email: agent.email }],
                      redirectTo: `${ environment.baseURL }/agents/lead/${ uid }`,
                      redirectAdminTo: `${ environment.baseURL }/admin/clientes/detalhes/${ uid }`,
                      type,
                      companyName: notificationData.companyName,
                    },
                    { responseType: 'text', headers: this.authService.getHeader() }
                  )
                  .toPromise()
                  .then(() => {
                    console.log('Note notification was sent to agent');
                  })
                  .catch((error) => {
                    console.error('Error sending note notification to agent.', error);
                  });
                // this.http
                //   .post(
                //     `${environment.functionsUrl}/zendesk/create-agent-comment`,
                //     {
                //       agentUid: note.agent,
                //       comment: note.message,
                //       senderType: note.type,
                //       customerUid: uid,
                //     },
                //     { responseType: 'text', headers: this.authService.getHeader() }
                //   )
                //   .toPromise()
                //   .then(() => {
                //     console.log('Note notification was sent to agent');
                //   })
                //   .catch((error) => {
                //     console.error('Error sending note notification to agent.', error);
                //   });
              }
            })
            .catch((error) => {
              console.error('Error getting agent data.', error);
            });

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createBankerNote(
    uid: string,
    institution: string,
    note: BankerNote,
    notificationData: {
      address: Customer['address'];
      companyName: string;
      institution: string;
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const now = firestore.Timestamp.now();
      Promise.all([
        this.angularFirestore
          .collection('customers')
          .doc(uid)
          .collection('banker-notes')
          .doc(institution)
          .set({ institution }),
        this.angularFirestore
          .collection('customers')
          .doc(uid)
          .collection('banker-notes')
          .doc(institution)
          .collection('notes')
          .doc(timestampForDate(now).toISOString())
          .set({
            ...note,
            createdAt: now,
            id: timestampForDate(now).toISOString(),
          }),
      ])
        .then(() => {
          this.bankersAdmin
            .getBankersOfOpportunity(institution, uid, notificationData.address)
            .then((bankers) => {
              if (bankers?.length) {
                const recipients = bankers
                  .filter((b) => b?.email && b?.notifications?.email?.chatMessages !== false)
                  .map((b) => ({ Name: b?.name ?? b.email, Email: b.email })) ?? [];

                this.http
                  .post(
                    `${ environment.functionsUrl }/email/notify-new-note`,
                    {
                      uid,
                      institutionKey: institution,
                      admin: note.createdBy.name,
                      content: note.message,
                      customer: notificationData.companyName,
                      recipients,
                      redirectTo: `${ environment.baseURL }/bankers/opportunity/${ uid }`,
                      redirectAdminTo: `${ environment.baseURL }/admin/clientes/detalhes/${ uid }`,
                      type: 'banker',
                      companyName: notificationData.companyName,
                      institution: notificationData.institution,
                    },
                    { responseType: 'text', headers: this.authService.getHeader() }
                  )
                  .toPromise()
                  .then(() => {
                    console.log('Note notification was sent to bankers');
                  })
                  .catch((error) => {
                    console.error('Error sending note notification to bankers.', error);
                  });
              }

              resolve();
            })
            .catch((err) => {
              console.error('Error getting bankers to notificate.', err);

              resolve();
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createUserNote(uid: string, note: UserNote, notificationData: UserNotification): Promise<void> {
    return new Promise((resolve, reject) => {
      const now = firestore.Timestamp.now();
      this.angularFirestore
        .collection('customers')
        .doc(uid)
        .collection('user-messages')
        .doc(timestampForDate(now).toISOString())
        .set({
          ...note,
          createdAt: now,
          id: timestampForDate(now).toISOString(),
        })
        .then(() => {
          this.http
            .post(
              `${ environment.functionsUrl }/email/notify-new-note`,
              {
                ...notificationData,
                type: 'user',
                senderType: notificationData.type,
              },
              { responseType: 'text', headers: this.authService.getHeader() }
            )
            .toPromise()
            .then(() => {
              console.log('Note notification was sent');
            })
            .catch((error) => {
              console.error('Error sending note notification', error);
            });
          // this.http
          //   .post(
          //     `${environment.functionsUrl}/zendesk/create-customer-comment`,
          //     {
          //       customerUid: uid,
          //       comment: note.message,
          //       senderType: note.type,
          //       agent: note.agent,
          //     },
          //     { responseType: 'text', headers: this.authService.getHeader() }
          //   )
          //   .toPromise()
          //   .then(() => {
          //     console.log('Note notification was sent');
          //   })
          //   .catch((error) => {
          //     console.error('Error sending note notification', error);
          //   });

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // send automatic message to customer/admin/agent in chat and email
  async sendAutomaticMsgCustomerChat(
    customer: Customer,
    templateType: NoteTemplate,
    senderType: UserNote['type'],
    additionalData: any = {}
  ): Promise<void> {
    try {
      const notesTemplateSnap = await firestore().collection('configuracao').doc('notes-templates').get();
      if (!notesTemplateSnap.exists) {
        throw new Error(`Cant find ${ templateType } in firestore`);
      }
      const notesTemplatesData = notesTemplateSnap.data() as NoteTemplateRecord;
      const templateRaw = notesTemplatesData[templateType];
      const message = fillTemplate(templateRaw, { ...customer, ...additionalData });
      const notificationData = this.createUserNotification({
        customer,
        senderType,
        message,
      });
      const now = firestore.Timestamp.now();
      const note: UserNote = {
        type: 'ADMIN',
        createdAt: now,
        lastUpdate: now,
        createdBy: {
          email: '',
          name: 'Mensagem Automática',
        },
        agent: customer.agent || null,
        message,
      };
      await this.createUserNote(customer.uid, note, notificationData);
    } catch (err) {
      console.error(err);
      console.error('Could not notify customer', customer.email);
    }
  }

  // creates UserNotification obj used in notes services in customer-agent chat
  private createUserNotification({
    customer,
    senderType,
    message,
  }: {
    customer: Customer;
    senderType: UserNote['type'];
    message: string;
  }): UserNotification {
    const notification: UserNotification = {
      content: message,
      recipients: [],
      name: customer.name,
      companyName: customer.companyName,
      type: senderType,
      link: {
        toAdmin: `${ environment.baseURL }/admin/clientes/detalhes/${ customer.uid }`,
        toAgent: `${ environment.baseURL }/agents/lead/${ customer.uid }`,
        toUser: `${ environment.baseURL }/mensagens`,
      },
    };
    return notification;
  }

  getAllUserNotes(uid: string /*, agent?: string */): Observable<UserNote[]> {
    const collection = this.angularFirestore
      .collection('customers')
      .doc(uid)
      .collection('user-messages', (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges();

    // if (agent) {
    //   return (collection).pipe(
    //     map( (notes: UserNote[]) =>
    //       notes.filter( note => note.agent === agent )
    //     )
    //   );
    // }
    return collection;
  }

  getUserNotesPreview(uid: string, quantity = 3 /* , agent?: string */): Observable<UserNote[]> {
    const collection = this.angularFirestore
      .collection('customers')
      .doc(uid)
      .collection('user-messages', (ref) => ref.orderBy('createdAt', 'desc').limit(quantity))
      .valueChanges();

    // if (agent) {
    //   return (collection).pipe(
    //     map( (notes: UserNote[]) =>
    //       notes.filter( note => note.agent === agent )
    //     )
    //   );
    // }
    return collection;
  }

  getAllBankerNotes(uid: string, institution: string): Observable<BankerNote[]> {
    return this.angularFirestore
      .collection('customers')
      .doc(uid)
      .collection('banker-notes')
      .doc(institution)
      .collection('notes', (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges();
  }

  getBankerNotesPreview(uid: string, institution: string, quantity: number): Observable<BankerNote[]> {
    return this.angularFirestore
      .collection('customers')
      .doc(uid)
      .collection('banker-notes')
      .doc(institution)
      .collection('notes', (ref) => ref.orderBy('createdAt', 'desc').limit(quantity))
      .valueChanges();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private createGetZendeskCommentsObservable(payload: Partial<ZendeskZupportApiReadComments>, intervalMinutes = 1) {
    // const intevalObs = interval(intervalMinutes * 60 * 1000).pipe(mergeMap(() => this.getZendeskComments(payload)));
    return new Observable((subscriber: Subscriber<{ notes: Note[] }>) => {
      this.getZendeskComments(payload).then((response) => {
        subscriber.next(response);
      });
      const intervalId = setInterval(() => {
        this.getZendeskComments(payload).then((notes) => {
          subscriber.next(notes);
        });
      }, intervalMinutes * 60 * 1000);
      return () => {
        clearInterval(intervalId);
      };
    });
  }

  private async getZendeskComments(payload: Partial<ZendeskZupportApiReadComments>) {
    const params = {
      ...payload,
      isSenderAdmin: payload.isSenderAdmin ? 'yes' : '',
      quantity: `${ payload.quantity ?? 0 }`,
    };
    const response = (await (this.http
      .get(`${ environment.functionsUrl }/zendesk/comments`, {
        responseType: 'json',
        headers: this.authService.getHeader(),
        params,
      })
      .toPromise() as unknown)) as { notes: AgentNote[] };
    response.notes = response.notes.map((note) => ({
      ...note,
      createdAt: firestore.Timestamp.fromMillis(((note.createdAt as unknown) as { _seconds: number })._seconds * 1000),
    }));
    return response;
  }

  getAgentComments(
    payload: Pick<ZendeskZupportApiReadComments, 'agentUid' | 'customerUid' | 'isSenderAdmin' | 'senderEmail'>,
    minutes = 1
  ) {
    return this.angularFirestore
      .collection('customers')
      .doc(payload.customerUid)
      .collection('agent-notes', (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges();
    // return this.createGetZendeskCommentsObservable({ ...payload, noteType: 'agent' }, minutes);
  }

  getCustomerComments(
    payload: Pick<ZendeskZupportApiReadComments, 'customerUid' | 'isSenderAdmin' | 'senderEmail'>,
    minutes = 1
  ) {
    return this.angularFirestore
      .collection('customers')
      .doc(payload.customerUid)
      .collection('user-messages', (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges();

    // return this.createGetZendeskCommentsObservable({ ...payload, noteType: 'customer' }, minutes);
  }

  getBankerComments(
    payload: Pick<ZendeskZupportApiReadComments, 'customerUid' | 'isSenderAdmin' | 'senderEmail' | 'institution'>,
    minutes = 1
  ) {
    return this.getAllBankerNotes(payload.customerUid, payload.institution);
    // return this.createGetZendeskCommentsObservable({ ...payload, noteType: 'banker' }, minutes);
  }
}
