import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarStateService {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  private currentSection = new BehaviorSubject<any>('');

  getCurrentSection() {
    return this.currentSection.asObservable();
  }

  updateCurrentSection(section: any): void {
    this.currentSection.next(section);
  }
}
