<div class="mt-2 mb-2 " *ngIf="formGroup">

    <mat-form-field class="col-12">
      <mat-label>Adicionar Lista de CNPJ </mat-label>
      <textarea matInput [formControl]="formGroup.get('openCnpjListCtrl')" placeholder="Adicionar Lista de CNPJ"
        name="openCnpjList" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="8"></textarea>
    </mat-form-field>
  
  </div>
