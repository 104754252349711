<div *ngIf="!isDetails">
  <div class="d-flex flex-row flex-wrap">
    <div class="flex-fill">
      <mat-card>
        <mat-card-content>
          <mat-card-title>Grupos de Instituições Financeiras</mat-card-title>
          <mat-card-subtitle>
            Consulte e associe as instituições financeiras aos seus devidos grupos.
          </mat-card-subtitle>
          <mat-form-field>
            <input matInput (input)="applyFilter($event.target.value)" placeholder="Filtro" />
          </mat-form-field>
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSourceGrupos" matSort #matSortGrupos="matSort">
              <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
                <td mat-cell *matCellDef="let grpinstfinanc">{{ grpinstfinanc.nome }}</td>
              </ng-container>
              <ng-container matColumnDef="prioridade">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Prioridade</th>
                <td mat-cell *matCellDef="let grpinstfinanc">
                  {{ grpinstfinanc.prioridade ? grpinstfinanc.prioridade : '-' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="acoes">
                <th mat-header-cell *matHeaderCellDef class="text-center"></th>
                <td mat-cell *matCellDef="let grpinstfinanc" (click)="$event.stopPropagation()" class="text-center">
                  <button *ngIf="['master','admin'].includes(loggedUser.accessLevel)" mat-mini-fab color="accent" (click)="onEditGrupo(grpinstfinanc.id)" matTooltip="Editar grupo">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="columnsGrupos"></tr>
              <tr mat-row *matRowDef="let row; columns: columnsGrupos"></tr>
            </table>
            <mat-paginator #paginatorTable4 [pageSizeOptions]="[20, 50, 70, 100]"></mat-paginator>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div *ngIf="isDetails">
  <mat-card>
    <mat-card-content>
      <mat-card-title>Grupos de Instituições Financeiras {{ tituloDetalheGrupo }}</mat-card-title>
      <mat-card-subtitle> Altere as associações dos grupos de instituições financeiras </mat-card-subtitle>
      <div>
        <div>
          <form [formGroup]="editFormGroup" (submit)="onSaveForm()">
            <mat-form-field>
              <input matInput placeholder="Nome" formControlName="nomeCtrl" required name="nome" />
              <mat-error *ngIf="editFormGroup.controls['nomeCtrl'].hasError('required')">Informe um nome.</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Prioridade</mat-label>
              <input
                matInput
                type="number"
                placeholder="Prioridade"
                formControlName="prioridadeCtrl"
                required
                name="prioridade"
              />
              <mat-error *ngIf="editFormGroup.controls['prioridadeCtrl'].hasError('required')">
                Informe uma prioridade para o grupo.
              </mat-error>
            </mat-form-field>
            <div>
              <mat-label>Ativo? </mat-label>
              <mat-radio-group formControlName="ativoCtrl" required>
                <mat-radio-button color="primary" value="false" class="m-r-10" [checked]="editFormGroup.ativo"
                  >Não</mat-radio-button
                >&nbsp;
                <mat-radio-button color="primary" value="true" class="m-r-10" [checked]="editFormGroup.ativo"
                  >Sim</mat-radio-button
                >
              </mat-radio-group>
              <mat-error
                *ngIf="
                  editFormGroup.controls['ativoCtrl'].hasError('required') &&
                  editFormGroup.controls['ativoCtrl'].touched
                "
              >
                Informe se está ativo.</mat-error
              >
            </div>
            <div class="text-right">
              <button mat-raised-button class="btn" color="accent" type="submit" [disabled]="!editFormGroup.valid">
                Salvar
              </button>
            </div>
          </form>
        </div>
        <br />
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>Novas instituições financeiras</mat-panel-title>
              <mat-panel-description>
                <mat-label>Inclusão de novas instituições financeiras ao grupo</mat-label>
                <mat-icon>add_circle</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
              <div class="d-flex flex-column">
                <mat-selection-list #instFinancAtivosSelecionados>
                  <mat-list-option
                    *ngFor="let instituicaoFinanceira of instituicoesFinanceirasAtivas"
                    [value]="instituicaoFinanceira"
                  >
                    {{ instituicaoFinanceira.nome }}
                  </mat-list-option>
                </mat-selection-list>
              </div>
              <br />
              <div class="d-flex justify-content-end">
                <button
                  mat-raised-button
                  class="btn"
                  color="accent"
                  type="button"
                  (click)="onAddNewInstFinanc()"
                  [disabled]="instFinancAtivosSelecionados.selectedOptions.selected.length === 0"
                >
                  Adicionar
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <br />
        <table
          mat-table
          [dataSource]="dataSourceInstituicoesFinanceiras"
          class="mat-elevation-z8"
          matSort
          #matInstituicoesFinanceiras="matSort"
        >
          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
            <td mat-cell *matCellDef="let instfinanc">{{ instfinanc.nome }}</td>
          </ng-container>
          <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Instituição Financeira</th>
            <td mat-cell *matCellDef="let instfinanc">{{ instfinanc.tipo.nome }}</td>
          </ng-container>
          <ng-container matColumnDef="acoes">
            <th mat-header-cell *matHeaderCellDef class="text-center"></th>
            <td mat-cell *matCellDef="let instfinanc" (click)="$event.stopPropagation()" class="text-center">
              <button mat-mini-fab color="warn" (click)="onRemoverInstFinanc(instfinanc)" matTooltip="Remover">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsInstituicoesFinanceiras"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsInstituicoesFinanceiras"></tr>
        </table>
      </div>
      <br />
      <div class="row top-buffer">
        <div class="col-md-12 text-right">
          <button mat-raised-button class="btn" color="warn" type="button" (click)="onBackToList()">Voltar</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
