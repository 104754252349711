<div class="dialog">
  <div class="d-flex">
    <h4 mat-dialog-title class="flex-grow-1">
      {{ document.name }}{{ exerciseYear ? ' - ' + exerciseYear : '' }}
    </h4>

    <div class="close-icon" style="text-align: right">
      <i class="material-icons" mat-dialog-close>close</i>
    </div>
  </div>
  <p>{{ document.description || '' }}</p>

  <div mat-dialog-content>
    <file-upload
      [submitedFilesCount]="submitedFilesCount"
      [maximumDocuments]="maximumDocuments"
      (completedUpload)="handleHaveAllEmissionDate($event)"
      [hasEmissionDate]="hasEmissionDate"
    >
    </file-upload>
  </div>
  <div mat-dialog-actions style="padding-top: 20px" class="d-flex" style="justify-content: center">
    <button
      mat-raised-button
      class="action-button autosized"
      [disabled]="emissionDatesCtrl || uploadProcessInProgress"
      type="submit"
      (click)="onSaveFile()"
      tabindex="2"
    >
      Enviar
    </button>
  </div>
</div>
