import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BndesProposal } from '../../../../../functions/src/models/Bndes';
import { Customer } from '../../../../../functions/src/models/Customer';
import { BndesService } from '../../../bndes/services/bndes.service';

@Component({
  selector: 'app-bndes-card',
  templateUrl: './bndes-card.component.html',
  styleUrls: ['./bndes-card.component.scss']
})
export class BndesCardComponent implements OnInit, OnDestroy {
  
  @Input() customer: Customer;

  isScoreHintVisible: boolean;
  totalDebtAmountHint: boolean;
  overdueDebtAmountHint: boolean;
  shortTermDebtAmountHint: boolean;
  debtLossAmountHint: boolean;

  bndesProposal: BndesProposal;

  getBndesProposalSubscription: Subscription;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private bndesService: BndesService) { }

  ngOnInit(): void {
    this.isScoreHintVisible = false;
    this.totalDebtAmountHint = false;
    this.overdueDebtAmountHint = false;
    this.shortTermDebtAmountHint = false;
    this.debtLossAmountHint = false;

    this.getBndesProposalSubscription = this.bndesService.getBndesProposal(this.customer.proposalId).subscribe((proposal) => {
      this.bndesProposal = proposal;
    })
  }

  ngOnDestroy(): void {
    if(this.getBndesProposalSubscription){
      this.getBndesProposalSubscription.unsubscribe();
    }
  }

}
