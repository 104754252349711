import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'app-tree-parent',
  templateUrl: './tree-parent.component.html',
  styleUrls: ['./tree-parent.component.scss'],
})
export class TreeParentComponent implements OnInit {
  @Input() public node: any;
  @Input() public label: string;

  leafKeys = [];
  nodeKeys = [];

  expanded = false;

  isEmpty = _.isEmpty;

  ngOnInit(): void {
    this.leafKeys = _.filter(Object.keys(this.node), (k) => this.isScalar(this.node[k]));
    this.nodeKeys = _.filter(Object.keys(this.node), (k) => this.isArray(this.node[k]) || this.isObject(this.node[k]));
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }

  isArray(value): boolean {
    return Array.isArray(value);
  }
  isObject(value): boolean {
    return typeof value === 'object';
  }
  isScalar(value): boolean {
    return typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean';
  }
}
