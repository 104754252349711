import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Customer } from '../../../functions/src/models/Customer';
import { ChatService } from '../core/services/chat.service';

@Component({
  selector: 'app-chat-template',
  templateUrl: './chat-template.component.html',
  styleUrls: ['./chat-template.component.scss']
})
export class ChatTemplateComponent implements OnInit, OnDestroy {

  getCustomersSubscription: Subscription;

  todayContacts: Customer[];
  yesterdayContacts: Customer[];
  otherContacts: Customer[];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private chatService: ChatService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    // this.getContacts();
  }

  ngOnDestroy(): void {
    // if(this.getCustomersSubscription){
    //   this.getCustomersSubscription.unsubscribe();
    // }
  }

  // getContacts(){
  //   return this.getCustomersSubscription = this.chatService.getCustomers().subscribe((result) => {
  //     this.sortContacts(result);
  //   })
  // }



}
