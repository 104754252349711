<div class="mt-4 w-100">
  <div>
    <div class="w-100 d-flex align-items-center">
      <mat-form-field class="w-100 mb-1" color="accent">
        <mat-chip-list #emailList aria-label="E-mail input">
          <mat-basic-chip
            class="chip"
            *ngFor="let recipient of recipients"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeRecipient('recipients', recipient)"
            [class.error]="!recipient.valid"
          >
            {{ recipient.email }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-basic-chip>
          <input
            placeholder="E-mails dos destinatários"
            [matChipInputFor]="emailList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="addRecipient('recipients', $event)"
            [readonly]="recipients.length >= recipientsLimit"
          />
        </mat-chip-list>
        <mat-hint align="start" *ngIf="!hasErrorRecipients"> Adicione até {{ recipientsLimit }} e-mails </mat-hint>
        <mat-hint align="start" *ngIf="hasErrorRecipients" class="error-hint">
          A lista contém e-mails inválidos.
        </mat-hint>
        <mat-hint align="end"> E-mails adicionados: {{ recipients.length }} </mat-hint>
      </mat-form-field>
    </div>
    <div class="w-100 d-flex align-items-center">
      <mat-form-field class="w-100 mb-1" color="accent">
        <mat-chip-list #emailListCopyTo aria-label="E-mail input">
          <mat-basic-chip
            class="chip"
            *ngFor="let recipient of copyTo"
            [selectableCc]="selectableCopyTo"
            [removable]="removable"
            (removed)="removeRecipient('copyTo', recipient)"
            [class.error]="!recipient.valid"
          >
            {{ recipient.email }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-basic-chip>
          <input
            placeholder="E-mails em Cópia"
            [matChipInputFor]="emailListCopyTo"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlurCopyTo"
            (matChipInputTokenEnd)="addRecipient('copyTo', $event)"
            [readonly]="copyTo.length >= recipientsLimit"
          />
        </mat-chip-list>
        <mat-hint align="start" *ngIf="!hasErrorCopyTo"> Adicione até {{ recipientsLimit }} e-mails </mat-hint>
        <mat-hint align="start" *ngIf="hasErrorCopyTo" class="error-hint"> A lista contém e-mails inválidos. </mat-hint>
        <mat-hint align="end"> E-mails adicionados: {{ copyTo.length }} </mat-hint>
      </mat-form-field>
    </div>
    <mat-form-field color="accent">
      <input matInput type="text" placeholder="Assunto" [(ngModel)]="subject" />
    </mat-form-field>
    <mat-form-field color="accent">
      <textarea matInput mat-autosize matAutosizeMinRows="10.1" placeholder="Mensagem" [(ngModel)]="message"></textarea>
    </mat-form-field>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Preview da mensagem</mat-panel-title>
        </mat-expansion-panel-header>

        <div><span style="font-weight: 500">Para: </span> {{ getRecipientsAsText('recipients') }}</div>
        <div>
          <span *ngIf="copyTo.length !== 0" style="font-weight: 500">Em Cópia: </span>
          {{ getRecipientsAsText('copyTo') }}
        </div>
        <div><span style="font-weight: 500">Assunto: </span> {{ subject }}</div>

        <div class="mt-3">
          <div [innerHtml]="header | safeHtml"></div>

          <p [innerHtml]="getHTMLMessage() | safeHtml"></p>

          <div [innerHtml]="footer | safeHtml"></div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="text-center mt-4">
      <button mat-stroked-button color="accent" (click)="cancelMessage()" If="!isProcessing" type="button" class="mx-1">
        <mat-icon>cancel</mat-icon> &nbsp;Cancelar
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="sendMessage()"
        *ngIf="!isProcessing"
        type="button"
        [disabled]="isUnableToSend()"
        class="mx-1"
      >
        Enviar&nbsp; <mat-icon>send</mat-icon>
      </button>
      <mat-spinner [diameter]="40" class="mx-auto my-2" *ngIf="isProcessing" color="accent"></mat-spinner>
    </div>
  </div>
</div>
