<div class="container-fluid">
  <mat-card>
    <div class="content">
      <mat-card-content>
        <mat-card-title><h1>Oportunidades Ganhas</h1></mat-card-title>

        <app-filter-oportunity [isLoadingResults]="isLoadingResults"
        [minRevenue]="minRevenue"
        [maxRevenue]="maxRevenue"
        (applyFilter)="applyFilter($event)"></app-filter-oportunity>

        <div class="w-100 p-5" *ngIf="isLoadingResults">
          <mat-spinner *ngIf="isLoadingResults" [diameter]="60" class="mx-auto" color="primary"></mat-spinner>
        </div>

        <div class="table-container" *ngIf="!isLoadingResults && length === 0">
          <p class="text-center">Nenhuma oportunidade encontrada.</p>
        </div>

        <table
          *ngIf="!isLoadingResults && length !== 0"
          mat-table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="sortData($event)"
          [matSortActive]="sortField"
          [matSortDirection]="sortDirection"
          matSortDisableClear
          class="table-generic-styles"
          >
          <ng-container matColumnDef="companyName" >
            <th  *matHeaderCellDef disableClear class="titulo">Razão Social</th>
            <td mat-cell class="bolded" *matCellDef="let row" >
              <a  style="color: #FF8641;" [routerLink]="'/bankers/opportunity/' + row.uid">
                {{ row.companyName }}
              </a>
              
            </td>
          </ng-container>

          <ng-container matColumnDef="cnpj">
            <th  *matHeaderCellDef disableClear class="titulo">CNPJ</th>
            <td mat-cell *matCellDef="let row">{{ row.cnpj }}</td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th  *matHeaderCellDef disableClear class="titulo">Criação</th>
            <td mat-cell *matCellDef="let row">{{ row.termsSignatureDate ? (row.termsSignatureDate | date) : '-' }}</td>
          </ng-container>

          <ng-container matColumnDef="taxa">
            <th  *matHeaderCellDef disableClear class="titulo">Taxa</th>
            <td mat-cell *matCellDef="let row">
              {{ !row.rate ? '-' : row.rate + ' %' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="limiteOperacaoStr">
            <th  *matHeaderCellDef disableClear class="titulo">Valor tomado</th>
            <td mat-cell *matCellDef="let row">
              {{ (row.limitOperation | currency: 'R$') || '-' }}
            </td>
          </ng-container>


          <ng-container matColumnDef="prazo">
            <th  *matHeaderCellDef disableClear class="titulo">Prazo</th>
            <td mat-cell *matCellDef="let row">
              {{ row?.deadlineForMonth ? row.deadlineForMonth + ' meses' : '-' }}
            </td>
          </ng-container>

          <!-- Actions Column -->
          <!-- <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef disableClear></th>
            <td mat-cell *matCellDef="let row; let i = index">
              <a mat-flat-button color="primary" [routerLink]="'/bankers/opportunity/' + row.uid">
                <span style="color: white; font-size: small"> Detalhes</span>
              </a>
            </td>
          </ng-container> -->

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator
          [length]="length"
          [pageIndex]="currentPage"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="paginatorEvent($event)"
        ></mat-paginator>
      </mat-card-content>
    </div>
  </mat-card>
</div>
