<div class="filter-opportunity">
  <div class="card-filter">
    <div class="mr-2" style="align-self: center; text-align: left;">Filtrar por:</div>
    <div style="align-self: center; display: flex; justify-content: space-between;">
      <mat-form-field color="primary">
        <mat-select [(ngModel)]="selectedFilter" [disabled]="isLoadingResults" (ngModelChange)="filterValue = '';">
          <mat-option [value]="item.id" *ngFor="let item of filterOptions" style="font-family: Montserrat;"> {{item.name}} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="card-filter">
    <mat-form-field style="align-self: center" color="primary">
      <mat-label class="d-flex justify-content-between">
        <span>{{ getLabel() }}</span>
        <img src="assets/images/icons/search.svg" width="18px" alt="search-icon" />
      </mat-label>
      <input *ngIf="selectedFilter !== 'status'" matInput [(ngModel)]="filterValue" (keydown.enter)="onApplyFilter()"
        [placeholder]="getLabel()" [disabled]="isLoadingResults" (blur)="onApplyFilter()" />
      <mat-select [multiple]="true" *ngIf="selectedFilter === 'status' && status" [(ngModel)]="filterValue"
        (ngModelChange)="onApplyFilter()">
        <mat-option [value]="null"> </mat-option>
        <mat-option [value]="item.id" *ngFor="let item of status"> {{item.label}} </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="card-filter">
    <mat-form-field style="align-self: center" color="primary">
      <div class="intervalo-tempo" [matMenuTriggerFor]="intervaloTempoMenu">
        <mat-label class="d-flex justify-content-between">
          <span style="font-family: Montserrat;">Calendário</span>
          <img src="assets/images/icons/calendar.svg" width="18px" alt="calendar-icon" />
        </mat-label>

      </div>
      <mat-menu #intervaloTempoMenu="matMenu" xPosition="before" yPosition="below" style="width: 440px;">
        <div class="intervalo-tempo-menu-content">
          <mat-form-field>
            <mat-label>Data inicio</mat-label>
            <input matInput [matDatepicker]="dataInicio" name="dataInicio" [(ngModel)]="dataInicioValue"
              #dataInicioValueRef (ngModelChange)="onApplyFilter()" />
            <mat-datepicker-toggle matSuffix [for]="dataInicio"></mat-datepicker-toggle>
            <mat-datepicker #dataInicio></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Data final</mat-label>
            <input matInput [matDatepicker]="dataFim" name="dataFim" [(ngModel)]="dataFimValue"
              (ngModelChange)="onApplyFilter()" #dataFimValueRef />
            <mat-datepicker-toggle matSuffix [for]="dataFim"></mat-datepicker-toggle>
            <mat-datepicker #dataFim></mat-datepicker>
          </mat-form-field>
        </div>
      </mat-menu>
    </mat-form-field>
  </div>

  <div class="card-filter" #triggerButton (click)="openSliderRangerRvenue()">
    <mat-form-field style="align-self: center">
      <mat-label class="d-flex justify-content-between">
        <span>Faixa de Valor</span>
        <img src="assets/images/icons/dollar.svg" width="18px" alt="dollar-icon" />
      </mat-label>
      <input matInput readonly />
    </mat-form-field>
  </div>

</div>