import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from '../../../../../functions/src/models/User';
import { AuthService } from '../../../core/auth/auth.service';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-select-whatsapp-template',
  templateUrl: './select-whatsapp-template.component.html',
  styleUrls: ['./select-whatsapp-template.component.scss']
})
export class SelectWhatsappTemplateComponent implements OnInit {

  @Output() modelSent = new EventEmitter<boolean>();

  items: any[] = [];
  filteredItems: any[] = [];
  searchText = '';
  selectedModel: any = null;

  isLoading = false; 

  loggedUser: User;
  loggedUserSubscription: Subscription;

  currenTime = '';

  constructor(public dialogRef: MatDialogRef<SelectWhatsappTemplateComponent>,private dialog: MatDialog, private firestore: AngularFirestore, private authService: AuthService, private chatService: ChatService,) { }

  ngOnInit(): void {
    this.fetchItemsFromFirestore(); 

     this.loggedUserSubscription = this.authService.user.subscribe((loggedUser: User) => {
          this.loggedUser = loggedUser;
        });

       
  }

 
  // Busca os dados do Firestore 
  fetchItemsFromFirestore() {
    this.firestore
      .collection('whatsapp-templates', ref => ref.orderBy('name')) 
      .valueChanges()
      .subscribe(data => {
        this.items = data;
        this.filteredItems = data; 
        console.log('this.filteredItems', this.filteredItems)
      });
  }

  filterList() {
    if (!this.searchText?.trim()) {  
      this.filteredItems = [...this.items]; 
      return;
    }

    this.filteredItems = this.items.filter(item => 
      item?.name?.toLowerCase().includes(this.searchText.toLowerCase()) 
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  selectModel(item: any) {
    this.isLoading = true; 
    this.selectedModel = item;

    const now = new Date();
    this.currenTime = now.toLocaleTimeString(); 

   
    setTimeout(() => {
      this.isLoading = false; 
    }, 2000);
  }

  cancelSelection() {
    if (!this.selectedModel) {
      this.dialogRef.close(); 
    } else {
      this.selectedModel = null; 
    }
  }


  onSendModelTemplate(): void {
    this.isLoading = true;

    this.chatService.sendModelTemplate(this.selectedModel)
    .then(() => {
      this.modelSent.emit(true); // Envia o evento de sucesso
      this.dialogRef.close(true); // Fecha o modal passando "true" como resultado
    })
    .catch(() => {
      this.dialogRef.close(false); // Fecha o modal sem sucesso
    })
    .finally(() => {
      this.isLoading = false;
    });
  }

 }
  

