<form>
  <mat-form-field>
    <mat-label>Admin atribuído</mat-label>
    <mat-select *ngIf="users.length > 0" [(ngModel)]="selectedUser" name="user"
      (selectionChange)="onUserSelectionChange($event.value)" [compareWith]="compareSelectedUser">
      <mat-option [value]="null">
        Sem atribuição
      </mat-option>
      <mat-option *ngFor="let user of users" [value]="user">
        {{ user.displayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
