<mat-card-content>
    <mat-card-title>Alterar - Grupo de Emails</mat-card-title>
    <mat-card-subtitle> Consulte e altere os dados associados as intituições financeiras. </mat-card-subtitle>
    <button *ngIf="!isFormEdit && ['master'].includes(loggedAdmin.accessLevel)" mat-raised-button class="btn" color="accent" type="button" (click)="showFormCreate()">
        Novo
    </button>

    <!-- email-groups collection list  -->

    <div class="row list" *ngIf="!isFormCreate && !isFormEdit && loggedAdmin">
        <div class="col-md-12">
            <table mat-table [dataSource]="databaseServiceCollection$" class="mat-elevation-z8">
                <ng-container matColumnDef="group">
                    <th mat-header-cell *matHeaderCellDef>Grupo</th>
                    <td mat-cell *matCellDef="let element">{{ element.group }}</td>
                </ng-container>
                <ng-container matColumnDef="emailList">
                    <th mat-header-cell *matHeaderCellDef>Lista de Emails</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngFor="let item of element.emailList">{{item.email}}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="mnemonic">
                    <th mat-header-cell *matHeaderCellDef>Mnemonico</th>
                    <td mat-cell *matCellDef="let element">{{ element.mnemonic }}</td>
                </ng-container>
                <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef>Ativo</th>
                    <td mat-cell *matCellDef="let element">{{ element.active === 'true' ? 'Sim' : 'Não' }}</td>
                </ng-container>
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                        <button *ngIf="loggedAdmin?.accessLevel === 'master'" mat-button color="accent" (click)="showFormEdit(element)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-button *ngIf="loggedAdmin?.accessLevel === 'master'" color="accent" (click)="documentDelete(element)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="databaseServiceColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: databaseServiceColumns"></tr>
            </table>
        </div>
    </div>

    <!-- form create new document in email-groups collection -->

    <form class="form-create" *ngIf="isFormCreate" [formGroup]="formCreateDocument" (submit)="documentCreate()">
        <mat-form-field style="width: 100%">
            <input matInput placeholder="Nome do Grupo de Emails" formControlName="group" required name="group"
                (input)="automaticFill($event.target.value)" />
            <mat-error *ngIf="formCreateDocument.controls['group'].hasError('required')">Informe um
                Nome para o Grupo de Emails.</mat-error>
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <input [value]="inputAutomatic" matInput placeholder="Mnemonico (preenchimento automático)"
                formControlName="mnemonic" required name="mnemonic" />
        </mat-form-field>
        <div class="row">
            <div class="col-md-12">
                <mat-label>Ativo? </mat-label>
                <mat-radio-group formControlName="active" required>
                    <mat-radio-button color="primary" value="false" class="m-r-10"
                        [checked]="formCreateDocument.active">Não</mat-radio-button>&nbsp;
                    <mat-radio-button color="primary" value="true" class="m-r-10"
                        [checked]="formCreateDocument.active">Sim</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="
              formCreateDocument.controls['active'].hasError('required') &&
              formCreateDocument.controls['active'].touched
                ">Informe se está ativo.</mat-error>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-right">
                <button mat-raised-button class="btn" color="warn" type="button"
                    (click)="showDataList()">Voltar</button>&nbsp;
                <button mat-raised-button class="btn" color="accent" type="submit"
                    [disabled]="!formCreateDocument.valid">
                    Salvar
                </button>
            </div>
        </div>
    </form>

    <!-- form edit document in email-groups collection to change group -->

    <form class="form-create" *ngIf="isFormEdit" [formGroup]="formEditDocument" (submit)="documentEdit()">
        <mat-form-field style="width: 100%">
            <input matInput placeholder="Nome do Grupo de Emails" formControlName="group" name="group" />
        </mat-form-field>
        <div class="row">
            <div class="col-md-12">
                <mat-label>Ativo? </mat-label>
                <mat-radio-group formControlName="active">
                    <mat-radio-button color="primary" value="false" class="m-r-10"
                        [checked]="databaseElement.active === false ? true : false">Não</mat-radio-button>&nbsp;
                    <mat-radio-button color="primary" value="true" class="m-r-10"
                        [checked]="databaseElement.active === true ? true : false">Sim</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-right">
                <button mat-raised-button class="btn" color="warn" type="button" (click)="showDataList()">Voltar
                </button>&nbsp;
                <button mat-raised-button class="btn" color="accent" type="submit">
                    Editar
                </button>&nbsp;
            </div>
        </div>
    </form>

    <!-- form edit document for adding emails -->

    <form *ngIf="isFormEdit" [formGroup]="formEditEmailList" (submit)="documentEditAddEmailListUser()">
        <div class="row add-email">
            <div class="col-md-12 text-right input-email-list">
                <mat-form-field>
                    <input matInput placeholder="Nome do Usuário" formControlName="name" name="name" required />
                    <mat-error *ngIf="formEditEmailList.controls['name'].hasError('required')">
                        Informe um Nome para o Usuário.
                    </mat-error>
                </mat-form-field>&nbsp;
                <mat-form-field>
                    <input matInput placeholder="Email do Usuário" formControlName="email" name="email" required
                        type="email" />
                    <mat-error *ngIf="formEditEmailList.controls['email'].hasError('required')">
                        Informe um Email para o Usuário.
                    </mat-error>
                </mat-form-field>&nbsp;
                <button style="min-width: fit-content; white-space: nowrap;" mat-raised-button class="btn"
                    color="primary" type="button
                " type="submit" (click)="$event.stopPropagation()" [disabled]="!formEditEmailList.valid">
                    Adicionar Email
                </button>&nbsp;
            </div>
        </div>
        <div class="row list email-list">
            <div class="col-md-12">
                <table mat-table [dataSource]="databaseElementEmailList" class="mat-elevation-z8">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Nome</th>
                        <td mat-cell *matCellDef="let user">
                            {{user.name}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>Email</th>
                        <td mat-cell *matCellDef="let user">
                            {{user.email}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let user" (click)="$event.stopPropagation()">
                            <button mat-button color="accent" type="button"
                                (click)="documentEditDeleteEmailListUser(user)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="formEmailListEditColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: formEmailListEditColumns"></tr>
                </table>
            </div>
        </div>
    </form>
</mat-card-content>
