/* HTML Dialog
 * - this component allows inserting any HTML snippet to a MatDialog
 * - BE CAREFUL: The HTML is NOT sanitized
 * */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-html-dialog',
  templateUrl: './html-dialog.component.html',
  styleUrls: ['./html-dialog.component.scss'],
})
export class HtmlDialogComponent {
  public title = 'Alert';
  public content: string;
  public isOnlyConfirm: boolean;

  constructor(private dialogRef: MatDialogRef<HtmlDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.title = data.title;
    this.content = data.content;
    this.isOnlyConfirm = data.isOnlyConfirm;
  }

  onOk(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
