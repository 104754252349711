import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BndesProposal } from '../../../../functions/src/models/Bndes';
import { Customer } from '../../../../functions/src/models/Customer';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class BndesService {
  constructor(private angularFirestore: AngularFirestore, private http: HttpClient, private authService: AuthService) {}

  async checkCustomerStatus(customer: Customer): Promise<{ success: boolean; err?: string }> {
    const document = await this.angularFirestore.collection('customers').doc(customer.uid).get().toPromise();
    if (!document.exists) {
      return { success: false, err: 'Nenhum cliente encontrado.' };
    } else {
      const customerDoc = document.data() as Customer;

      // Using this fields to check if BNDES customer already filled forms any time
      if (customerDoc.companyName || customerDoc.address.street || customerDoc.address.number) {
        return { success: false, err: 'Cliente já cadastrado' };
      } else {
        return { success: true };
      }
    }
  }

  async checkEmailAndCnpj(customer: Customer): Promise<{ success: boolean; err?: string }> {
    const query = await firestore()
      .collection('customers')
      .where('email', '==', customer.email)
      .where('cnpj', '==', customer.cnpj)
      .get();

    if (query.empty) {
      return { success: false, err: 'Nenhum cliente encontrado com o CNPJ e o Email informados.' };
    } else if (query.size > 1) {
      return { success: false, err: 'Há mais de um cliente cadastrado com o CNPJ e o Email informados.' };
    } else {
      return { success: true };
    }
  }

  async updateCustomer(customer: Customer): Promise<void> {
    try {
      await this.http
        .put(
          `${environment.functionsUrl}/customer/${customer.uid}`,
          { customer },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise();
    } catch (err) {
      console.error('Error creating user', err);
    }
  }

  getBndesProposal(proposalId: string): Observable<BndesProposal>{
    return this.angularFirestore.collection('bndes-proposal').doc(proposalId).valueChanges() as Observable<BndesProposal>;
  }

  getBndesProposalByCnpj(cnpjParameter: string): Observable<BndesProposal> {
    return this.angularFirestore.collection('bndes-proposal', ref => 
        ref.where('cnpj', '==', cnpjParameter)
           .orderBy('lastUpdate', 'desc')
           .limit(1)
    ).valueChanges().pipe(
        map(proposals => proposals[0] as BndesProposal)
    );
  }
}
