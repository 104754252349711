<div class="container-fluid">
  <mat-card>
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <span style="flex: 1">Consulta de Assessores de Crédito</span>
        <a
          mat-flat-button
          color="primary"
          routerLink="/admin/assessores/cadastrar"
          class="d-block"
          style="float: right"
        >
          + Cadastrar Assessor
        </a>
      </mat-card-title>
      <div class="col-12"></div>

      <div class="row px-3 mt-2" *ngIf="!isLoadingResults">
        <div class="col-12 col-sm-5 col-md-4">
          <mat-form-field class="w-100 d-block" color="primary">
            <mat-label>Filtro</mat-label>
            <mat-select [(ngModel)]="selectedFilter" class="w-100">
              <mat-option value="name"> Nome </mat-option>
              <mat-option value="email"> E-mail </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-7 col-md-8 d-flex">
          <mat-form-field style="flex: 1" color="primary">
            <mat-label>{{ labels[selectedFilter] }}</mat-label>
            <input
              matInput
              [(ngModel)]="filterValue"
              (keydown.enter)="applyFilter(true)"
              [placeholder]="labels[selectedFilter]"
            />
          </mat-form-field>
          <button mat-icon-button color="accent" (click)="clearFilter()">
            <mat-icon>clear</mat-icon>
          </button>
          <button mat-icon-button color="accent" (click)="applyFilter(true)">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>

      <div class="w-100 p-5" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults" [diameter]="60" class="mx-auto" color="primary"></mat-spinner>
      </div>

      <div class="table-container" *ngIf="!isLoadingResults && length === 0">
        <p class="text-center">Nenhum assessor encontrado.</p>
      </div>

      <div class="table-container mat-elevation-z3" *ngIf="!isLoadingResults && length > 0">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="sortData($event)"
          [matSortActive]="sortField"
          [matSortDirection]="sortDirection"
          matSortDisableClear
        >
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Nome</th>
            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
          </ng-container>

          <!-- E-mail Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>E-mail</th>
            <td mat-cell *matCellDef="let row">{{ row.email }}</td>
          </ng-container>

          <!-- Created Column -->
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Criado em</th>
            <td mat-cell *matCellDef="let row">{{ (row.createdAt | date: 'dd/MM/yyyy') || '-' }}</td>
          </ng-container>

          <!-- Last Interaction Column -->
          <ng-container matColumnDef="lastInteraction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Últ. Inter.</th>
            <td mat-cell *matCellDef="let row">{{ (row.lastInteraction | date: 'dd/MM/yyyy') || '-' }}</td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef disableClear>Status</th>
            <td mat-cell *matCellDef="let row">
              {{
                !row.emailVerified
                  ? 'Aguardando confirmação'
                  : row.isActive
                  ? !row.isApprovedByAdmin
                    ? 'Aguardando aprovação'
                    : 'Ativo'
                  : !row.isApprovedByAdmin
                  ? 'Recusado'
                  : 'Suspenso'
              }}
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef disableClear>Ações</th>
            <td mat-cell *matCellDef="let row; let i = index">
              <div *ngIf="isProcessing[i]">
                <mat-spinner [diameter]="30"></mat-spinner>
              </div>
              <div *ngIf="!isProcessing[i]">
                <div class="w-100 text-center">
                  <a
                    mat-flat-button
                    color="primary"
                    class="m-1 bt-w"
                    [routerLink]="'/admin/assessores/info/' + row.uid"
                  >
                    <mat-icon>search</mat-icon>
                  </a>
                  <a
                    mat-flat-button
                    color="accent"
                    class="m-1 bt-w"
                    *ngIf="loggedAdmin?.accessLevel === 'master' || loggedAdmin?.accessLevel === 'admin'"
                    [routerLink]="'/admin/assessores/editar/' + row.uid"
                  >
                    <mat-icon>edit</mat-icon>
                  </a>
                </div>
                <div class="w-100 text-center">
                  <button
                    mat-stroked-button
                    color="primary"
                    *ngIf="!row.isApprovedByAdmin && row.isActive"
                    class="m-1 bt-w"
                    (click)="approveAgent(row.uid, row.email)"
                  >
                    <mat-icon>check_circle_outline</mat-icon> Apr.
                  </button>
                  <button
                    mat-stroked-button
                    color="warn"
                    *ngIf="!row.isApprovedByAdmin && row.isActive"
                    class="m-1 bt-w"
                    (click)="rejectAgent(row.uid, row.email)"
                  >
                    <mat-icon>block</mat-icon> Rec.
                  </button>
                  <button
                    mat-stroked-button
                    color="primary"
                    *ngIf="!row.isActive"
                    class="m-1 bt-w"
                    (click)="enableAgent(row.uid, row.email)"
                  >
                    <mat-icon>done</mat-icon> Habil.
                  </button>
                  <button
                    mat-stroked-button
                    color="warn"
                    *ngIf="loggedAdmin?.accessLevel === 'master' && row.isApprovedByAdmin && row.isActive"
                    class="m-1 bt-w"
                    (click)="disableAgent(row.uid, row.email)"
                  >
                    <mat-icon>report_problem</mat-icon> Susp.
                  </button>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator
        [length]="length"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="paginatorEvent($event)"
      ></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
