<div class="container-fluid">
  <mat-card>
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <span style="flex: 1">Lista de contadores com contato autorizado</span>
      </mat-card-title>

      <div class="w-100 p-5" *ngIf="isLoadingAccountants">
        <mat-spinner *ngIf="isLoadingAccountants" [diameter]="60" class="mx-auto" color="primary"></mat-spinner>
      </div>

      <div class="table-container" *ngIf="!isLoadingAccountants && !accountants">
        <p class="text-center">Falha ao obter a lista de contadores.</p>
        <p class="text-center">
          <button mat-raised-button color="accent" (click)="getAccountants()">Tentar novamente</button>
        </p>
      </div>

      <div class="table-container" *ngIf="!isLoadingAccountants && accountants?.length === 0">
        <p class="text-center">Nenhum contador encontrado.</p>
      </div>

      <div class="d-flex align-items-center" *ngIf="!isLoadingAccountants && accountants?.length > 0">
        <mat-form-field color="primary" style="flex: 1" class="mr-3">
          <mat-label>Campos a exportar</mat-label>
          <mat-select [formControl]="selectedFields" multiple>
            <mat-option *ngFor="let field of displayedColumns; let i = index" [value]="field">{{
              fieldsList[i]
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <button
            mat-raised-button
            color="primary"
            (click)="exportList()"
            [disabled]="!this.selectedFields?.value?.length"
          >
            <mat-icon class="mr-2">get_app</mat-icon> Exportar
          </button>
        </div>
      </div>

      <div class="table-container mat-elevation-z3" [class.d-none]="isLoadingAccountants || !accountants?.length">
        <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>
          <!-- Company Name Column -->
          <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Razão social do cliente</th>
            <td mat-cell *matCellDef="let row">
              {{ row.companyName || '-' | uppercase }}
            </td>
          </ng-container>

          <!-- Company Email Column -->
          <ng-container matColumnDef="companyEmail">
            <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>E-mail do cliente</th>
            <td mat-cell *matCellDef="let row">{{ row.companyEmail }}</td>
          </ng-container>

          <!-- CNPJ Column -->
          <ng-container matColumnDef="cnpj">
            <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>CNPJ do cliente</th>
            <td mat-cell *matCellDef="let row">{{ row.cnpj }}</td>
          </ng-container>

          <!-- Accountant Email Column -->
          <ng-container matColumnDef="accountantEmail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>E-mail do Contador</th>
            <td mat-cell *matCellDef="let row">{{ row.accountantEmail || '-' }}</td>
          </ng-container>

          <!-- Accountant Phone Column -->
          <ng-container matColumnDef="accountantPhone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Telefone do Contador</th>
            <td mat-cell *matCellDef="let row; let i = index">
              {{ row.accountantPhone || '-' }}
            </td>
          </ng-container>

          <!-- UID Column -->
          <ng-container matColumnDef="uid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>UID</th>
            <td mat-cell *matCellDef="let row">
              {{ row.uid || '-' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
