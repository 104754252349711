import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { firestore } from 'firebase';
import { Customer, CustomerPartner, NewOtherInstitution } from 'functions/src/models/Customer';
import _moment from 'moment';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { analyticsReportConversion } from 'src/app/core/handler/googleAnalytics';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { CepError, Endereco } from 'src/app/core/models/model-interfaces';
import { ViacepService } from 'src/app/core/services/viacep.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { BndesIntegrationStatus } from '../../../../functions/src/models/Bndes';
import { User, UserRole } from '../../../../functions/src/models/User';
import { BasicStructure } from '../../../../functions/src/models/common/BasicStructure';
import { Utm } from '../../../../functions/src/models/common/Utm';
import { CustomerJourneyAboutCompanyEntity } from '../../../../functions/src/models/customers/customer-journey-about-company.entity';
import { timestampForDate } from '../../../../functions/src/utils/dates';
import { CadastrosGeraisService } from '../../admin/cadastros-gerais/cadastros-gerais.service';
import { InstituicaoFinanceiraService } from '../../admin/instituicao-financeira/instituicao-financeira.service';
import { OptinService } from '../../admin/services/optin.service';
import { HubspotService } from '../../core/services/hubspot.service';
import { PartnershipsService } from '../../core/services/partnerships.service';
import { ReceitaWSService } from '../../core/services/receita-ws.service';
import { CustomerService } from '../../customer/services/customer.service';
import { TemplateSignupService } from '../../layouts/templates/template-signup/template-signup.service';
import { AnticipationFormDialogComponent } from '../anticipation-form-dialog/anticipation-form-dialog.component';
import { DialogConfirmationOnboardingComponent } from '../dialog-confirmation-onboarding/dialog-confirmation-onboarding.component';
import { HtmlDialogComponent } from '../html-dialog/html-dialog.component';

@Component({
  selector: 'app-form-create-account',
  templateUrl: './form-create-account.component.html',
  styleUrls: ['./form-create-account.component.scss'],
})
export class FormCreateAccountComponent implements OnInit, OnDestroy {
  @Input() customer: Customer;
  @Input() proposalId: string;
  @Input() bndesSource: string;
  @ViewChild('newOtherInstitutionInput') newOtherInstitutionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  readonly steps = ['company', 'financial', 'credit', 'personal'];
  readonly selectTypeOfAnticipationOperator = [
    { id: 'cartao', value: 'Cartão' },
    { id: 'boleto', value: 'Boleto' },
    { id: 'nota_fiscal', value: 'Notas Fiscais' },
  ];
  readonly selectTypeMaritalStatus = [
    { id: 'solteiro', value: 'Solteiro(a)' },
    { id: 'casado', value: 'Casado(a)' },
    { id: 'separado', value: 'Separado(a)' },
    { id: 'divorciado', value: 'Divorciado(a)' },
    { id: 'viuvo', value: 'Viúvo(a)' },
  ];
  readonly selectTypeOfInvoices = [
    { id: 'nota_servico', value: 'Notas de Serviço' },
    { id: 'nota_produto', value: 'Notas de Produto' },
  ];
  readonly MASKS = MASKS;
  formatPhone = UtilHandler.formatPhone;
  readonly numberMask = createNumberMask({
    thousandsSeparatorSymbol: '.',
    integerLimit: 15,
    prefix: 'R$ ',
    suffix: '',
  });


  signupConfigSubscription: Subscription;
  generalConfigSubscription: Subscription;
  getTaxRegimesSubscription: Subscription;
  getCardMachinesSubscription: Subscription;
  ipSubscription: Subscription;
  subscriptions: Subscription;
  companyCNPJSubscription: Subscription;
  
  config: any;
  isProcessing = false;
  step = 'company';
  privacyPolicyUrl: string;
  utm: Utm;
  hasPopUp = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  submitted = false;
  visible = true;
  selectable = true;
  removable = true;
  filteredNewOtherInstitutions: Observable<(NewOtherInstitution | string)[]>;
  newOtherInstitutions: NewOtherInstitution[] = [];
  allNewOtherInstitutions: NewOtherInstitution[] = [];
  confirmationDialogData: any;
  agent: string;
  CESource: string;
  hasOtherInstitutions = false;
  hasOnlyAvalGuarantee = true;
  hasTypeOfAnticipation = false;
  hasTypeOfAnticipationCard = false;
  hasTypeOfInvoices = false;
  optin = '';
  hasTaxRegimeMei = false;
  texts: { title: string; subtitle: string; stageId: string }[];
  lastY: number;
  gclid: string;
  runReceitaWs: boolean;

  companyDataFormGroup: FormGroup;
  financialDataFormGroup: FormGroup;
  creditDataFormGroup: FormGroup;
  personalDataFormGroup: FormGroup;

  passwordMatchValidator(formGroup: FormGroup): void {
    const error =
      formGroup.get('passwordCtrl').value === formGroup.get('confirmCtrl').value ? null : { mismatch: true };
    formGroup.get('confirmCtrl').setErrors(error);
  }
  lastNameMatchValidatior(formGroup: FormGroup): void {
    const error =
      formGroup
        .get('nameCtrl')
        .value?.split(' ')
        .filter((value) => value).length > 1
        ? null
        : { invalidlastname: true };
    formGroup.get('nameCtrl').setErrors(error);
  }

  anticipationCreditCardValue: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private customerService: CustomerService,
    private cadastrosGeraisService: CadastrosGeraisService,
    private instFinanceirasAutocomplete: InstituicaoFinanceiraService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private hubspotService: HubspotService,
    private optinService: OptinService,
    private viacep: ViacepService,
    private signupService: TemplateSignupService,
    private partnerService: PartnershipsService,
    private receitaWsService: ReceitaWSService
  ) {
    this.subscriptions = new Subscription();
    this.utm = {};

    this.initializeOutherBanks();
  }

  ngOnInit(): void {
    this.urlUtm();
    this.initializeForms();
    this.subscriptions.add(
      this.customerService.getGeneralConfiguration().subscribe((config) => {
        this.privacyPolicyUrl = config.privacyPolicyUrl;
      })
    );
    this.signupService.setStep(this.step);
    this.subscriptions.add(
      this.customerService.getSignUpConfiguration().subscribe((config) => {
        this.config = config;
        this.config.minRevenue = config?.minRevenue ?? 360000;
        this.config.minRevenueStr = config?.minRevenueStr ?? 'R$ 360.000';
      })
    );

    this.getCardMachinesSubscription = this.cadastrosGeraisService.getMaquinasAtivas().subscribe((maquinas) => {
      maquinas.map((maquina) => {
        maquina['id'] = maquina.mnemonico;
        maquina['name'] = maquina.nome;
        delete maquina.ativo;
        delete maquina.mnemonico;
        delete maquina.nome;
      });
      this.config.cardMachines = maquinas;
    });
    this.getTaxRegimesSubscription = this.cadastrosGeraisService.getRegimesAtivos().subscribe((regimes) => {
      regimes.map((regime) => {
        regime['id'] = regime.mnemonico;
        regime['name'] = regime.nome;

        delete regime.ativo;
        delete regime.mnemonico;
        delete regime.nome;
      });

      this.config.taxRegimes = regimes;
    });

    this.validateAgent();
    this.fillOptin();
    this.customerService.customersInRegister();
  }
  ngOnDestroy(): void {
    if (this.signupConfigSubscription) {
      this.signupConfigSubscription.unsubscribe();
    }
    if (this.getTaxRegimesSubscription) {
      this.getTaxRegimesSubscription.unsubscribe();
    }
    if (this.getCardMachinesSubscription) {
      this.getCardMachinesSubscription.unsubscribe();
    }
    if (this.companyCNPJSubscription) {
      this.companyCNPJSubscription.unsubscribe();
    }
    if (this.ipSubscription) {
      this.ipSubscription.unsubscribe();
    }
  }

  async add(event: MatChipInputEvent): Promise<void> {
    const input = event.input;
    const value = event.value?.trim();

    if (!value) {
      return;
    }

    const alreadySelected = this.newOtherInstitutions.some(selected => selected.name.toLowerCase() === value.toLowerCase());

    if (!alreadySelected) {
      const formattedValue = this.capitalize(value);
      this.newOtherInstitutions.push({ id: '', nomeNoSistema: '', name: formattedValue, type: '' });
    }

    this.financialDataFormGroup.controls.otherInstitutionsCtrl.setValue('');
    if (input) {
      input.value = '';
    }
  }



  private initializeOutherBanks(): void {
    this.instFinanceirasAutocomplete.getFinancialInstitutionAutocomplete().subscribe((data) => {
      this.allNewOtherInstitutions = data;
      this.filteredNewOtherInstitutions = this.financialDataFormGroup.get('otherInstitutionsCtrl').valueChanges.pipe(
        startWith(''),
        debounceTime(200),
        map((value: string | null) => {
          if (typeof value === 'string' && value.length >= 1) {
            return this._filter(value);
          } else {
            return this.allNewOtherInstitutions;
          }
        })
      );
    });
  }


  private capitalize(value: string): string {
    const words = value.split(' ');
    const capitalizedWords = words.map(word => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return firstLetter + restOfWord;
    });
    return capitalizedWords.join(' ');
  }

  remove(newOtherInstitution: NewOtherInstitution): void {
    const index = this.newOtherInstitutions.indexOf(newOtherInstitution);
    if (index >= 0) {
      this.newOtherInstitutions.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedOption = event.option.value;

    const alreadySelected = this.newOtherInstitutions.some(selected => selected.name.toLowerCase() === selectedOption.name.toLowerCase());

    if (!alreadySelected) {
      this.newOtherInstitutions.push(selectedOption);
      this.newOtherInstitutionInput.nativeElement.value = '';
      this.financialDataFormGroup.controls.otherInstitutionsCtrl.setValue(this.newOtherInstitutions);
    }
  }

  private _filter(value: string): NewOtherInstitution[] {
    if (!value) {
      return [];
    }

    const filterValue = value.toLowerCase();
    const suggestions = this.allNewOtherInstitutions.filter(newOtherInstitution => {
      const alreadySelected = this.newOtherInstitutions.some(
        selected => selected.name.toLowerCase() === newOtherInstitution.name.toLowerCase()
      );
      return !alreadySelected && newOtherInstitution.name.toLowerCase().includes(filterValue);
    });

    return [{ id: '', nomeNoSistema: '', name: this.capitalize(value), type: '' }, ...suggestions];
  }


  areAllFormsValid(): boolean {
    return (
      this.companyDataFormGroup.valid &&
      this.financialDataFormGroup.valid &&
      this.creditDataFormGroup.valid &&
      this.personalDataFormGroup.valid
    );
  }

  openAnticipationDialog(): void{
    const dialogRef = this.dialog.open(AnticipationFormDialogComponent, {
      width: '412px'
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      this.anticipationCreditCardValue = result;
      await this.nextStep(this.step);
    })

  }

  checkCompanyFields(): void {
    this.isProcessing = true;

    this.confirmationDialogData = this.companyDataFormGroup.value;

    const dialogRef = this.dialog.open(DialogConfirmationOnboardingComponent, {
      data: this.confirmationDialogData
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        Promise.all([
          this.authService.checkIfUserEmailExists(this.companyDataFormGroup.get('emailCtrl').value?.toLowerCase()),
          this.customerService.checkIfUserWhatsAppExists(this.companyDataFormGroup.get('whatsappCtrl').value),
          this.customerService.checkIfEmailExistsAsCustomer(this.companyDataFormGroup.get('emailCtrl').value?.toLowerCase()),
          this.customerService.checkIfCNPJExistsAsCustomer(this.companyDataFormGroup.get('cnpjCtrl').value),
          this.customerService.checkIfEmailAsCustomerjourneycontrol(this.companyDataFormGroup.get('cnpjCtrl').value)
        ])
          .then(async (results) => {
            const [emailAsUser, whatsappExists, emailAsCustomer, cnpjAsCustomer, emailAsJourneycontrol] = results;

            console.log("resultado do email", emailAsJourneycontrol);
            if (emailAsJourneycontrol && emailAsJourneycontrol.email && emailAsJourneycontrol.email !== this.companyDataFormGroup.get('emailCtrl').value?.toLowerCase()) {
              try {
                await this.hubspotService.deleteAllHubspot(emailAsJourneycontrol);
              } catch (error) {
                console.error('Erro ao deletar todos os dados do Hubspot:', error);
              }

              try {
                await this.hubspotService.deleteAllFb(this.companyDataFormGroup.get('cnpjCtrl').value);
              } catch (error) {
                console.error('Erro ao deletar todos os dados do Facebook:', error);
              }
            }

            if (!emailAsUser && !whatsappExists && !emailAsCustomer && !cnpjAsCustomer) {
              await this.nextStep(this.step);
              await this.signupService.setStep(this.step);
            } else if (emailAsCustomer) {
              this.showMessage(
                'E-mail já cadastrado',
                'O e-mail informado já possui uma conta. Entre no sistema para continuar.'
              );
            } else if (emailAsUser) {
              this.showMessage(
                'E-mail já cadastrado',
                'O e-mail informado já possui uma conta com outro papel no sistema. No momento só é permitido um papel por conta.'
              );
            } else if (whatsappExists) {
              this.showMessage(
                'WhatsApp já cadastrado',
                'O número de WhatsApp informado já possui uma conta. Entre no sistema para continuar.'
              );
            } else if (cnpjAsCustomer) {
              this.showMessage(
                'CNPJ já cadastrado',
                'O CNPJ informado já possui uma conta. Entre no sistema para continuar.'
              );
            }
            this.isProcessing = false;
          })
          .catch(() => {
            this.isProcessing = false;
            this.showMessage('Erro ao validar', 'Ocorreu um erro ao validar seus dados. Tente novamente.');
          });
      } else {
        this.isProcessing = false;
      }
    });
  }

  async checkFinancialFields(): Promise<void> {
    this.isProcessing = true;
    const revenue = parseFloat((this.companyDataFormGroup.get('revenueCtrl').value || '1').replace(/\D/g, ''));
    // const debt = parseFloat((this.financialDataFormGroup.get('debtCtrl').value || '0').replace(/\D/g, ''));

    // const maxDebtPercentual = this.config?.maxDebtPercentual || 30;

    // const debtRate = debt / revenue;
    // const debtThreshold = maxDebtPercentual / 100;

    const taxRegime = this.financialDataFormGroup.get('taxRegimeCtrl').value;

    // if (debtRate > debtThreshold) {
    //   this.showMessage(
    //     'Endividamento elevado',
    //     `Infelizmente no momento só conseguimos atender empresas com dívida atual de no máximo ${ maxDebtPercentual }% do faturamento.`
    //   );
    // }
     if (taxRegime?.limiteNum && revenue > taxRegime?.limiteNum) {
      this.showMessage(
        'Faturamento Anual Incorreto',
        `O faturamento preenchido na tela anterior está incorreto, pois, segundo a legislação atual, empresas do regime tributário <strong>${ taxRegime.name }</strong>
        tem o faturamento máximo de <strong>${ taxRegime.limite }</strong>.
        Basta retornar à tela anterior, corrigir o <strong>Faturamento Anual</strong> e você poderá seguir buscando o melhor crédito para sua empresa aqui na <strong>Capital Empreendedor!</strong>`
      );
    } else {
      await this.nextStep(this.step);
    }
    this.isProcessing = false;
  }

  getCustomerDataFromForm(): Customer {
    const customerData = this.getAboutCompanyData();
    this.getFinancialHealthData(customerData);
    this.getCreditStatusData(customerData);
    this.getAboutYou(customerData);
    // this.getAddressFromZipCode();

    const source = this.tagOrigin(); // Chame tagOrigin() uma vez e armazene o valor

    return {
      agent: this.agent,
      createdBy: this.agent
        ? {
          uid: this.agent,
          type: 'AGENT',
        }
        : null,
      email: customerData.email,
      cnpj: customerData.cnpj,
      companyName: customerData.companyName,
      whatsapp: customerData.whatsapp,
      // website: customerData.website,
      address: customerData.address,
      revenue: customerData.revenue,
      revenueStr: customerData.revenueStr,
      stepHubspot: customerData.stepHubspot,
      // debt: customerData.debt,
      // debtStr: customerData.debtStr,
      taxRegime: customerData.taxRegime,
      banks: customerData.banks,
      banksOther: customerData.banksOther,
      creditValue: customerData.creditValue,
      creditValueStr: customerData.creditValueStr,
      purpose: customerData.purpose,
      guarantees: customerData.guarantees,
      typeOfAnticipation: customerData.typeOfAnticipation,
      // guaranteesValue: customerData.guaranteesValue,
      // guaranteesValueStr: customerData.guaranteesValueStr,
      creditCard: customerData.creditCard,
      typeOfInvoices: customerData.typeOfInvoices,
      name: customerData.name,
      displayName: customerData.name,
      cpf: customerData.cpf,
      birthDate: customerData?.birthDate || null,
      maritalStatus: customerData?.maritalStatus || null,
      // personalCell: customerData?.personalCell || null,
      hasPermissionToSign: customerData.hasPermissionToSign,
      whatsAppContact: customerData.whatsAppContact,
      cnae: customerData.cnae,
      utm: {
        ...this.utm,
        source: source, // Atribui o valor de source a utm.source
      },
      gclid: this.gclid,
      lastContact: null,
      attributedTo: null,
      source: source, // Atribui o valor de source a source
      userConsent: {
        privacyPolicyDate: this.companyDataFormGroup.controls.agreedPrivacyPolicyCtrl.value
          ? firestore.Timestamp.now().toMillis()
          : null,
        showUserConsent: false,
      },
      companyInformation: {},
      emailVerified: false,
    };
  }


  async createAccount(): Promise<void> {
    this.isProcessing = true;
    const customerData: Customer = this.getCustomerDataFromForm();
    const userData: User = {
      name: customerData.name,
      displayName: customerData.name,
      changedPassword: true,
      email: customerData.email,
      emailVerified: false,
      isAdmin: false,
      isAgent: false,
      isAnonymous: false,
      isBanker: false,
      isCustomer: true,
      lastInteraction: firestore.Timestamp.now(),
      mainRole: UserRole.Customer,
      photoURL: null,
      userConsent: customerData.userConsent,
    };
    try {
      this.isProcessing = true;
      if (this.proposalId) {
        customerData.proposalId = this.proposalId;
        customerData.bndesIntegrationStatus = BndesIntegrationStatus.ACTIVE;
      }

      this.scrSave();
      this.setTermParamCustomer(customerData);
      this.setTermParamCustomerByArtico(customerData);

      analyticsReportConversion('funil', 'cadastro-pessoal', 'sobre-voce');
      await this.customerService.setAboutYou(customerData);

      await this.customerService.createAccount(customerData, userData, this.personalDataFormGroup.value.passwordCtrl);
      this.isProcessing = false;

      this.router.navigate(['/confirmar-email']);
    } catch (error) {
      let title = 'Erro no cadastro';
      let text = 'Houve um problema ao tentar criar seu usuário. Por favor, tente novamente mais tarde.';
      let redirectTo = '';
      if (error.name && error.name === 'create-account-error') {
        if (error.message === 'email-already-in-use' || error.message === 'wrong-password') {
          title = 'E-mail em uso';
          text = `O e-mail ${ customerData.email } já está em uso. Faça login clicando em Entrar no canto superior direito da tela.`;
        } else if (error.message === 'invalid-email') {
          title = 'E-mail/Senha incorreto';
          text =
            'Você informou um e-mail ou senha incorreto. Por favor, verifique seus dados e tente novamente. ' +
            'Caso não lembre sua senha, clique em "Esqueci minha senha" para criar uma nova.';
        } else if (error.message === 'argument-error') {
          title = 'E-mail/Senha incorreto';
          text =
            'Os dados de acesso informados, estão incorretos. Por favor informe os dados corretos e tente novamente.';
        }
      } else if (error.message && error.message === 'save-user-error') {
        title = 'Erro no login';
        text =
          'Seu usuário foi criado com sucesso, mas houve um problema interno. Por favor, tente novamente mais tarde.';
      } else if (error.message && error.message === 'user-login-error') {
        title = 'Erro no login';
        text =
          'Seu usuário foi criado com sucesso, mas houve um problema ao tentar realizar seu login automaticamente.';
        redirectTo = '/entrar';
      } else if (error.message && error.message === 'send-email-verification-error') {
        title = 'Erro na verificação';
        text =
          'Seu cadastro foi realizado com sucesso, mas houve um problema ao tentar enviar o email de verificação de conta. Por favor, entre em contato pelo email: contato@capitalempreendedor.com.br';
      }
      this.showMessage(title, text)
        .afterClosed()
        .subscribe(() => {
          this.isProcessing = false;
          if (redirectTo) {
            this.router.navigate([redirectTo]);
          }
        });
    }
  }

  private setTermParamCustomerByArtico(customerData: Customer): void {
    if (customerData.utm?.source === 'artico') {
      customerData.noTermsCustomer = true;
      customerData.agreedTerms = true;
      customerData.partnerCE = CustomerPartner.ARTICO;
    }
  }

  private setTermParamCustomer(customerData: Customer): void {
    const partner = this.partnerService?.partner;
    if (!partner) {
      console.log("Cliente Não esta associado a um parceiro");
    } else {
      customerData.partnerCE = this.partnerService?.partner.partner;
      console.log("Cliente esta associado ao parceiro -", partner.partner);
      if (partner.isTerm === false) {
        customerData.noTermsCustomer = true;
        customerData.agreedTerms = true;
      }
    }
  }

  formatMoney(str: string): string {
    if (str?.indexOf(',') < 0) {
      return str + ',00';
    } else if (str?.indexOf(',') === str.length - 1) {
      return str + '00';
    } else if (str?.indexOf(',') === str.length - 2) {
      return str + '0';
    } else {
      return str || '';
    }
  }

  getAddressFromZipCode(): void {
    const zipCode = this.companyDataFormGroup.get('zipCodeCtrl').value.replace('.', '').replace('-', '');

    if (zipCode && zipCode.length === 8) {
      this.viacep
        .buscarPorCep(zipCode)
        .then((endereco: Endereco) => {
          // localidade and uf, it verifies fields and go directly to catch block
          // if it doesnt exist
          if (!endereco.localidade || !endereco.uf) {
            throw new Error('Error City or State are missing');
          }
          if (endereco.cep === undefined) {
            this.companyDataFormGroup.get('zipCodeCtrl').setErrors({ incorrect: true });
            this.companyDataFormGroup.get('streetCtrl').setValue('');
            this.companyDataFormGroup.get('numberCtrl').setValue('');
            this.companyDataFormGroup.get('neighborhoodCtrl').setValue('');
            this.companyDataFormGroup.get('cityCtrl').setValue('');
            this.companyDataFormGroup.get('stateCtrl').setValue('');
          } else {
            if (endereco.logradouro) {
              this.companyDataFormGroup.get('streetCtrl').setValue(endereco.logradouro);
              this.companyDataFormGroup.get('streetCtrl').disable({ onlySelf: true });
            } else {
              this.companyDataFormGroup.get('streetCtrl').setValue('');
              this.companyDataFormGroup.get('streetCtrl').enable({ onlySelf: true });
            }
            if (endereco.bairro) {
              this.companyDataFormGroup.get('neighborhoodCtrl').setValue(endereco.bairro);
              this.companyDataFormGroup.get('neighborhoodCtrl').disable({ onlySelf: true });
            } else {
              this.companyDataFormGroup.get('neighborhoodCtrl').setValue('');
              this.companyDataFormGroup.get('neighborhoodCtrl').enable({ onlySelf: true });
            }
            this.companyDataFormGroup.get('cityCtrl').setValue(endereco.localidade);
            this.companyDataFormGroup.get('stateCtrl').setValue(endereco.uf);
          }
        })
        .catch((error: CepError) => {
          this.companyDataFormGroup.get('zipCodeCtrl').setErrors({ incorrect: true });
          console.error('Error getting address info', error.descricao);
        });
    }
  }

  emailMatchValidator(formGroup: FormGroup): void {
    const error =
      formGroup.get('emailCtrl').value === formGroup.get('emailMatchCtrl').value ? null : { mismatch: true };
    formGroup.get('emailMatchCtrl').setErrors(error);
  }

  initializeForms(): void {
    this.companyDataFormGroup = this.formBuilder.group(
      {
        agreedPrivacyPolicyCtrl: new FormControl('', [Validators.required]),
        emailCtrl: new FormControl(this.customer?.email ?? '', [Validators.required, Validators.email]),
        emailMatchCtrl: new FormControl(this.customer?.email ?? '', [Validators.required, Validators.email]),
        cnpjCtrl: new FormControl(this.customer?.cnpj ?? '', [Validators.required, NgBrazilValidators.cnpj]),
        companyNameCtrl: new FormControl(this.customer?.companyName ?? '', [Validators.required]),
        whatsappCtrl: new FormControl(this.customer?.whatsapp ?? '', [
          Validators.required,
          NgBrazilValidators.telefone,
          Validators.minLength(14),
        ]),
        revenueCtrl: new FormControl(this.customer?.revenueStr?.replace(',00', '') ?? '', [Validators.required]),
        // websiteCtrl: new FormControl(''),
        zipCodeCtrl: new FormControl(this.customer?.address?.zipCode ?? '', [
          Validators.required,
          NgBrazilValidators.cep,
        ]),
        streetCtrl: new FormControl({ value: this.customer?.address?.street ?? '', disabled: true }, [
          Validators.required,
        ]),
        numberCtrl: new FormControl(this.customer?.address?.number ?? '', [Validators.required]),
        extraInfoCtrl: new FormControl(this.customer?.address?.extraInfo ?? '', []),
        neighborhoodCtrl: new FormControl({ value: this.customer?.address?.neighborhood ?? '', disabled: true }, [
          Validators.required,
        ]),
        cityCtrl: new FormControl({ value: this.customer?.address?.city ?? '', disabled: true }, [Validators.required]),
        stateCtrl: new FormControl({ value: this.customer?.address?.state ?? '', disabled: true }, [
          Validators.required,
        ])
      },
      { validators: this.emailMatchValidator }
    );


    this.financialDataFormGroup = this.formBuilder.group({
      // debtCtrl: new FormControl(this.customer?.debt ? String(this.customer.debt) : '', []),
      taxRegimeCtrl: new FormControl(this.customer?.taxRegime ?? '', [Validators.required]),
      institutionsCtrl: new FormControl(this.customer?.banks ?? '', [Validators.required]),
      otherInstitutionsCtrl: new FormControl(null),
    });

    this.creditDataFormGroup = this.formBuilder.group({
      creditValueCtrl: new FormControl(this.customer?.creditValueStr?.replace(',00', '') ?? '', [Validators.required]),
      purposeCtrl: new FormControl(this.customer?.purpose ?? '', [Validators.required]),
      guaranteesCtrl: new FormControl(this.customer?.guarantees ?? '', [Validators.required]),
      typeOfAnticipationCtrl: new FormControl(this.customer?.typeOfAnticipation ?? ''),
      // creditCardCtrl: new FormControl(this.customer?.creditCard ?? ''),
      creditCardCtrl: new FormControl(this.customer ?? ''),
      typeOfInvoicesCtrl: new FormControl(this.customer ?? ''),
      // guaranteesValueCtrl: new FormControl('0', [Validators.required]),
    });

    this.personalDataFormGroup = this.formBuilder.group(
      {
        nameCtrl: new FormControl(this.customer?.name ?? '', [Validators.required]),
        cpfCtrl: new FormControl(this.customer?.cpf ?? '', [Validators.required, NgBrazilValidators.cpf]),
        birthDateCtrl: new FormControl(this.customer?.birthDate ?? ''),
        maritalStatusCtrl: new FormControl(this.customer?.maritalStatus ?? ''),
        // personalCellCtrl: new FormControl(this.customer?.personalCell ?? '', [,
        // ])
        passwordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
        whatsAppContactCtrl: new FormControl(true),
        confirmCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
        permissionCtrl: new FormControl('', [Validators.required]),
      },
      { validators: [this.passwordMatchValidator, this.lastNameMatchValidatior] }
    );


    this.observeCnpjControl();
    // this.validateInitialZipCode();

  }
  compareObjects(o1: any, o2: any): boolean {
    return o1?.name === o2?.name && o1?.id === o2?.id;
  }

  private validateInitialZipCode(): void {
    const controls = ['zipCodeCtrl', 'streetCtrl', 'numberCtrl', 'neighborhoodCtrl', 'cityCtrl', 'stateCtrl'];
    const zipCodeControl = this.companyDataFormGroup.get('zipCodeCtrl');
    if (zipCodeControl.value && zipCodeControl?.value !== '' && zipCodeControl.value !== null) {
      for (const control of controls) {
        this.companyDataFormGroup.get(control).markAsTouched();
        this.companyDataFormGroup.get(control).updateValueAndValidity();
      }
    }

  }

  fillOptin(): void {
    this.optinService
      .getOptinConfigurationScr()
      .then((config) => {
        this.optin = config.html;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  scrModal() {
    const cnpjValue = this.companyDataFormGroup.controls.cnpjCtrl.value.trim();
    const companyName = this.companyDataFormGroup.controls.companyNameCtrl.value.trim();
    const city = this.companyDataFormGroup.controls.cityCtrl.value.trim();
    const now = new Date();
    const day = now.getDate();
    const month = now.toLocaleString('pt-BR', { month: 'long' });
    const year = now.getFullYear();

    const optinAtualizado = this.optin
      .replace('{{cnpj}}', cnpjValue)
      .replace('{{companyName}}', companyName)
      .replace('{{city}}', city)
      .replace('{{day}}', day.toString())
      .replace('{{month}}', month)
      .replace('{{year}}', year.toString())
      .replace('{{cpf}}', '')
      .replace('{{name}}', '')
      .replace('{{date}}', '')
      .replace('{{hash}}', '');

    this.dialog.open(HtmlDialogComponent, {
      data: {
        content: optinAtualizado,
        isOnlyConfirm: true,
      },
    });
  }

  scrSave() {
    const cnpjValue = this.companyDataFormGroup.controls.cnpjCtrl.value.trim();
    const companyName = this.companyDataFormGroup.controls.companyNameCtrl.value.trim();
    const cpf = this.personalDataFormGroup.value.cpfCtrl.trim();
    const name = this.personalDataFormGroup.value.nameCtrl.trim();
    const city = this.companyDataFormGroup.controls.cityCtrl.value.trim();
    const now = new Date();
    const day = now.getDate();
    const month = now.toLocaleString('pt-BR', { month: 'long' });
    const year = now.getFullYear();
    const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    const fullDate = `${ day } de ${ month } de ${ year } às ${ time }`;

    const scrReplace = this.optin
      .replace('{{cnpj}}', cnpjValue)
      .replace('{{companyName}}', companyName)
      .replace('{{city}}', city)
      .replace('{{day}}', day.toString())
      .replace('{{month}}', month)
      .replace('{{year}}', year.toString())
      .replace('{{cpf}}', cpf)
      .replace('{{name}}', name)
      .replace('{{date}}', fullDate);
      

    const getBrowserInfo = () => {
      const ua = navigator.userAgent;
      let browserName = "Desconhecido";
      let browserVersion = "";

      if (/chrome|crios|crmo/i.test(ua) && !/edg/i.test(ua)) {
        browserName = "Chrome";
        browserVersion = ua.match(/chrome\/([\d.]+)/i)?.[1] || "";
      } else if (/firefox|fxios/i.test(ua)) {
        browserName = "Firefox";
        browserVersion = ua.match(/firefox\/([\d.]+)/i)?.[1] || "";
      } else if (/safari/i.test(ua) && !/chrome|crios|crmo/i.test(ua)) {
        browserName = "Safari";
        browserVersion = ua.match(/version\/([\d.]+)/i)?.[1] || "";
      } else if (/edg/i.test(ua)) {
        browserName = "Edge";
        browserVersion = ua.match(/edg\/([\d.]+)/i)?.[1] || "";
      } else if (/opr|opera/i.test(ua)) {
        browserName = "Opera";
        browserVersion = ua.match(/(opera|opr)\/([\d.]+)/i)?.[2] || "";
      } else if (/msie|trident/i.test(ua)) {
        browserName = "Internet Explorer";
        browserVersion = ua.match(/(msie |rv:)([\d.]+)/i)?.[2] || "";
      }

      return `${ browserName } ${ browserVersion }`;
    };

    const browser = getBrowserInfo();

    this.ipSubscription = this.authService.getClientIP().subscribe((data) => {

      const ip = data['ip'];
      const scr = {
        date: new Date(),
        ip: ip,
        html: scrReplace.replace('{{hash}}', `${ip}#${cpf}#${fullDate}#${cnpjValue}`.replace(/\s+/g, '')),
        browser: browser,
        name: name,
        cpf: cpf
      };

      console.log(scr);
      this.optinService.saveOptinConfiguration(scr, cnpjValue.replace(/\D/g, ''));

    });
  }


  async nextStep(current: string): Promise<void> {
    const customerData = this.getCustomerDataFromForm();
    this.isProcessing = true;
    switch (current) {
      case 'company':
        analyticsReportConversion('funil', 'cadastro-empresa', 'sobre-empresa');
        console.log(customerData.stepHubspot);
        const { agent } = this.activatedRoute.snapshot.params;
        console.log(customerData)
        await this.customerService.setRegisterCustomer(customerData, agent);
        break;
      case 'financial':
        analyticsReportConversion('funil', 'cadastro-financas', 'saude-financeira');
        this.getFinancialHealthData(customerData);
        await this.customerService.setFinancialHealth(customerData);
        break;
      case 'credit':
        analyticsReportConversion('funil', 'cadastro-credito', 'situ-credito');
        this.getCreditStatusData(customerData);
        await this.customerService.setCreditStatus(customerData);
        break;
    }

    this.hubspotService
      .upsertCustomer(customerData)
      .then(() => {
        console.log(`Step: ${ current } - Integrated to Hubspot`);
      })
      .catch((err) => {
        console.error(`Error integrating hubspot for step ${ current }`, err);
      });

    this.step = this.steps[this.steps.indexOf(current) + 1];
    this.isProcessing = false;
  }

  previousStep(current: string): void {
    this.step = this.steps[this.steps.indexOf(current) - 1];
  }

  private observeCnpjControl(): void {
    this.companyCNPJSubscription = this.companyDataFormGroup.get('cnpjCtrl').valueChanges.subscribe((value) => {
      if (!this.runReceitaWs && this.companyDataFormGroup.get('cnpjCtrl').valid) {
        this.runReceitaWs = true;
        this.customerService.getCustomerDataOnCustomerjourneycontrol(value).then((customerJData) => {
          let isLessThanFillData = false;
          if (customerJData) {
            const diffInMs = timestampForDate(customerJData.createdAt).getTime() - new Date().getTime();
            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
            if (diffInDays < this.config.customerJourneyFillDataTime) {
              isLessThanFillData = true;
              const mappedCustomer = this.mapCustomerJourneyToCustomer(customerJData);

              if (this.customer) {
                this.customer = { ...this.customer, ...mappedCustomer };
              } else {
                this.customer = mappedCustomer;
              }
              this.initializeForms();
              this.getAddressFromZipCode();
            }
          }

          if(isLessThanFillData === false) {
            this.receitaWsService.requestDataReceitaWS(value).then(res => {
              if (this.customer) {
                this.customer = { ...this.customer, ...res };
                this.customer.cnae = res.cnae;

                console.log(res)
              } else {
                this.customer = res;
                this.customer.cnae = res.cnae;
              }
              this.initializeForms();
              this.getAddressFromZipCode();
            })
          }

        }).finally(() => {
          this.runReceitaWs = false;
          this.initializeOutherBanks();
        });


      }
    });
  }

  mapCustomerJourneyToCustomer(
    journey: Partial<CustomerJourneyAboutCompanyEntity>
  ): Partial<Customer> {
    return {
      uid: journey.uid,
      cnpj: journey.cnpj,
      companyName: journey.razaoSocial,
      email: journey.email,
      whatsapp: journey.whatsapp,
      revenue: journey.faturamentoAnual,
      // debt: journey.debt,
      taxRegime: journey.taxRegime,
      banks: journey.banks,
      guarantees: journey.guarantees,
      // guaranteesValue: journey.guaranteesValue,
      creditValue: journey.creditValue,
      creditValueStr: journey.creditValue ? String(journey.creditValue) : undefined,
      revenueStr: journey.faturamentoAnual ? String(journey.faturamentoAnual) : undefined,
      purpose: journey.purpose,
      address: journey.address ? {
        zipCode: journey.address?.cep,
        street: journey.address?.logradouro,
        number: journey.address?.numero,
        extraInfo: journey.address?.complemento,
        neighborhood: journey.address?.bairro,
        city: journey.address?.cidade,
        state: journey.address?.uf,
      } : undefined,
      stepHubspot: journey.step,
      cpf: journey.cpf,
      ip: journey.ip,
      utm: journey.utmSource ? {
        source: journey.utmSource?.source,
        medium: journey.utmSource?.medium,
        campaign: journey.utmSource?.campaign,
      } : undefined,
    };
  }


  confirmWhatsApp(event: any): void {
    // Obtenha o valor selecionado do mat-select
    const valorSelecionado = event.value;
    const obj = {
      date: Date.now(), // Você pode ajustar o valor da data de acordo com sua necessidade
      value: valorSelecionado === 'Sim' ? true : false,
    };

    // Agora, você pode fazer o que quiser com o objeto criado
    console.log('Objeto criado:', obj);
  }

  onChangeGuarantees(event: MatSelectChange): void {
    // if has type guarantee
    if (event.value.length && event.value.find((g) => g.id === 'boleto')) {
      this.hasTypeOfAnticipation = true;
    } else {
      this.hasTypeOfAnticipation = false;
      this.hasTypeOfAnticipationCard = false;
      this.hasTypeOfInvoices = false;
      this.creditDataFormGroup.get('typeOfAnticipationCtrl').clearValidators();
      this.creditDataFormGroup.get('typeOfAnticipationCtrl').setValue(null);
      this.creditDataFormGroup.get('creditCardCtrl').clearValidators();
      this.creditDataFormGroup.get('creditCardCtrl').setValue(null);
      this.creditDataFormGroup.get('typeOfInvoicesCtrl').clearValidators();
      this.creditDataFormGroup.get('typeOfInvoicesCtrl').setValue(null);
      this.creditDataFormGroup.updateValueAndValidity();
    }

    // if has only aval
    if (event.value.length === 1 && event.value[0].id === 'avalista') {
      this.hasOnlyAvalGuarantee = true;
      // this.creditDataFormGroup.get('guaranteesValueCtrl').setValue(0);
      this.showMessage(
        'Tem certeza? ',
        'Usando garantia na operação de crédito, sua chance de tomada cresce em até 50%.'
      );
    } else {
      this.hasOnlyAvalGuarantee = false;
      // this.creditDataFormGroup.get('guaranteesValueCtrl').setValue('');
    }
  }

  onChangeAntecipation(event: MatSelectChange): void {
    // if has antecipation of card
    if (event.value.length && event.value.find((model: BasicStructure) => model.id === 'cartao')) {
      this.hasTypeOfAnticipationCard = true;
    } else {
      this.hasTypeOfAnticipationCard = false;
      this.creditDataFormGroup.get('creditCardCtrl').clearValidators();
      this.creditDataFormGroup.updateValueAndValidity();
      this.creditDataFormGroup.get('creditCardCtrl').setValue(null);
    }
    // if has antecipation of Nota fiscal
    if (event.value.length && event.value.find((model: BasicStructure) => model.id === 'nota_fiscal')) {
      this.hasTypeOfInvoices = true;
    } else {
      this.hasTypeOfInvoices = false;
      this.creditDataFormGroup.get('typeOfInvoicesCtrl').clearValidators();
      this.creditDataFormGroup.updateValueAndValidity();
      this.creditDataFormGroup.get('typeOfInvoicesCtrl').setValue(null);
    }
  }

  onChangeInstitutions(event): void {
    if (event.value.find((a) => a.id === 'outro')) {
      this.hasOtherInstitutions = true;
      this.financialDataFormGroup.get('otherInstitutionsCtrl').setValue('');

    } else {
      this.hasOtherInstitutions = false;
      this.financialDataFormGroup.get('otherInstitutionsCtrl').setValue('Outro');
      this.newOtherInstitutions = [];
    }
  }

  onChangetaxRegime(event): void {
    if (event.value.id === 'mei') {
      this.hasTaxRegimeMei = true;
    } else {
      this.hasTaxRegimeMei = false;
      this.personalDataFormGroup.get('birthDateCtrl').clearValidators();
      this.personalDataFormGroup.get('maritalStatusCtrl').clearValidators();
      // this.personalDataFormGroup.get('personalCellCtrl').clearValidators();
      this.personalDataFormGroup.get('birthDateCtrl').setValue(null);
      this.personalDataFormGroup.get('maritalStatusCtrl').setValue(null);
      // this.personalDataFormGroup.get('personalCellCtrl').setValue(null);
      this.personalDataFormGroup.updateValueAndValidity();
    }
  }

  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }

  validateAgent(): void {
    const { agent } = this.activatedRoute.snapshot.params;
    if (agent) {
      let isEmailParam = false;
      if (agent.match(UtilHandler.emailRegex)) {
        isEmailParam = true;
      }

      this.customerService
        .validateAgent(agent, isEmailParam)
        .then((uid) => {
          this.agent = uid;
          this.CESource = this.tagOrigin();
        })
        .catch((err) => {
          console.error(err);
          if (err === 'not-found') {
            this.showMessage(
              'Assessor de crédito não encontrado',
              `O assessor de crédito informado não foi encontrado. Caso tenha alguma dúvida, favor encaminha-la para <strong>ops@capitalempreendedor.com.br</strong>.`
            );
          } else {
            this.showMessage(
              'Erro ao obter dados do assessor de crédito',
              `Houve um problema interno ao tentar obter os dados do assessor de crédito que foi informado. Por favor, tente novamente mais tarde.`
            );
          }
        });
    }
  }

  tagOrigin(): string {
    const source =
      this?.bndesSource ??
      (this.activatedRoute.snapshot.queryParams.utm_source ||
        this.activatedRoute.snapshot.queryParams.ce_source ||
        null);

    if (typeof source !== 'string' && source?.length > 0) {
      // verificar se é array e se for transformar em string
      return source?.join(' - ');
    } else if (source) {
      // remover posiveis caracteres que prejudiquem
      return source?.replace('[', '')?.replace(']', '') ?? source;

    } else if (this.agent) {
      return 'assessor link';
    } else {
      return 'organico';
    }
  }

  urlUtm(): void {
    const allParams = this.activatedRoute.snapshot.queryParamMap;

    const utmParms = allParams.keys.filter((f) => f.startsWith('utm_'));
    if (utmParms?.length > 0) {

      for (const paramKey of utmParms) {
        this.utm[paramKey.replace('utm_', '')] = allParams.get(paramKey);

      }
    }

    console.log('utm', this.utm)

    // Verifica se o parâmetro 'gclid' existe na URL
    if (allParams.has('gclid')) {
      this.gclid = allParams.get('gclid'); // Atribui o valor do parâmetro 'gclid' à variável 'gclid'
      console.log('gclid', this.gclid);
    } else {
      console.log('gclid não encontrado na URL');
    }

  }

  revenueValidation(): void {
    const revenue = parseFloat((this.companyDataFormGroup.get('revenueCtrl')?.value ?? '1').replace(/\D/g, ''));
    if (revenue < this.config.minRevenue) {
      this.companyDataFormGroup.get('revenueCtrl').setErrors({ min: true });
    }
  }

  getAboutCompanyData(): Customer {
    const partialCustomerData: Customer = {
      email: this.companyDataFormGroup.controls.emailCtrl.value.trim().toLowerCase(),
      cnpj: this.companyDataFormGroup.controls.cnpjCtrl.value.trim(),
      cnae: this.customer?.cnae || '',
      companyName: this.companyDataFormGroup.controls.companyNameCtrl.value.trim().toUpperCase(),
      whatsapp: this.companyDataFormGroup.value.whatsappCtrl.trim(),
      // website: this.companyDataFormGroup.controls.websiteCtrl?.value?.trim()?.toLowerCase() ?? null,
      revenue: parseFloat(
        (this.companyDataFormGroup.controls.revenueCtrl.value.trim() || '0').replace(/[^\,\d]/g, '').replace(/\,/g, '.')
      ),
      revenueStr: this.formatMoney(this.companyDataFormGroup.controls.revenueCtrl.value.trim()),
      address: {
        zipCode: this.companyDataFormGroup.controls.zipCodeCtrl.value.trim(),
        street: this.companyDataFormGroup.controls.streetCtrl.value.trim(),
        number: this.companyDataFormGroup.controls.numberCtrl.value,
        extraInfo: this.companyDataFormGroup.controls.extraInfoCtrl.value.trim(),
        neighborhood: this.companyDataFormGroup.controls.neighborhoodCtrl.value.trim(),
        city: this.companyDataFormGroup.controls.cityCtrl.value.trim(),
        state: this.companyDataFormGroup.controls.stateCtrl.value.trim(),
      },
      stepHubspot: 1,
    };
    return partialCustomerData;
  }

  getFinancialHealthData(customerData: Customer): void {
    // customerData.debt = parseFloat(
    //   (this.financialDataFormGroup.controls.debtCtrl.value?.trim() || '0').replace(/[^\,\d]/g, '').replace(/\,/g, '.')
    // );
    // customerData.debtStr = this.formatMoney(this.financialDataFormGroup.controls.debtCtrl.value?.trim());
    customerData.taxRegime = this.financialDataFormGroup.controls.taxRegimeCtrl.value;

    // salvando banks Auto-Complete
    const selectedInstitutions = this.newOtherInstitutions.length ? this.newOtherInstitutions : [];
    customerData.banks = [...this.financialDataFormGroup.controls.institutionsCtrl.value, ...selectedInstitutions];

    // salvando banksOthers Auto-Complete
    const selectedOptions: NewOtherInstitution[] = [];
    for (let i = 0; i < this.newOtherInstitutions.length; i++) {
      selectedOptions.push(this.newOtherInstitutions[i]);
    }
    customerData.banksOther = selectedOptions.map(option => option.name).join(', ');

    if (this.financialDataFormGroup.controls.taxRegimeCtrl.value) {
      customerData.stepHubspot = 2;
    }

  }

  getCreditStatusData(customerData: Customer): void {
    customerData.creditValue = parseFloat(
      (this.creditDataFormGroup.controls.creditValueCtrl.value.trim() || '0')
        .replace(/[^\,\d]/g, '')
        .replace(/\,/g, '.')
    );
    customerData.creditValueStr = this.formatMoney(this.creditDataFormGroup.controls.creditValueCtrl.value.trim());
    customerData.purpose = this.creditDataFormGroup.controls.purposeCtrl.value;
    customerData.guarantees = this.creditDataFormGroup.controls.guaranteesCtrl.value;
    customerData.typeOfAnticipation = this.creditDataFormGroup.controls.typeOfAnticipationCtrl?.value ?? null;
    // customerData.creditCard = this.creditDataFormGroup.controls.creditCardCtrl?.value ?? null;
    customerData.creditCard = this.anticipationCreditCardValue;
    customerData.typeOfInvoices = this.creditDataFormGroup.controls.typeOfInvoicesCtrl?.value ?? null;

    if (this.creditDataFormGroup.controls.guaranteesCtrl.value) {
      customerData.stepHubspot = 3;
    }

    // customerData.guaranteesValue = parseFloat(
    //   (!this.hasOnlyAvalGuarantee ? this.creditDataFormGroup.controls.guaranteesValueCtrl.value || '0' : '0')
    //     .trim()
    //     .replace(/[^\,\d]/g, '')
    //     .replace(/\,/g, '.')
    // );
    // customerData.guaranteesValueStr = this.formatMoney(
    //   (!this.hasOnlyAvalGuarantee ? this.creditDataFormGroup.controls.guaranteesValueCtrl.value || '0' : '0').trim()
    // );
  }

  getAboutYou(customerData: Customer): void {
    customerData.name = this.personalDataFormGroup.value.nameCtrl.trim().toUpperCase();
    customerData.cpf = this.personalDataFormGroup.value.cpfCtrl.trim();
    customerData.hasPermissionToSign = this.personalDataFormGroup.value.permissionCtrl;
    if (this.personalDataFormGroup.value.cpfCtrl) {
      customerData.stepHubspot = 4;
    }

    customerData.maritalStatus = this.personalDataFormGroup.value?.maritalStatusCtrl ?? null;
    // customerData.personalCell = this.personalDataFormGroup.value?.personalCellCtrl || null;
    customerData.birthDate = this.personalDataFormGroup.controls?.birthDateCtrl?.value
      ? firestore.Timestamp.fromDate(_moment(this.personalDataFormGroup.controls?.birthDateCtrl?.value).toDate())
      : null;
    customerData.whatsAppContact = {
      date: firestore.Timestamp.now(),
      value: this.personalDataFormGroup.value.whatsAppContactCtrl,
    };

  }

  // Metodo para validação, Auto-Complete
  isValidForm() {
    const banks = this.financialDataFormGroup.controls.institutionsCtrl.value;
    if (this.financialDataFormGroup.invalid) {
      return true;
    }
    if (banks.find((doc) => doc.id === 'outro') && this.newOtherInstitutions.length <= 0) {
      return true;
    }
    return false;
  }

}
