<div class="content">
  <section class="company" *ngIf="step === 'company'">
    <div class="step-description-container">
      <span class="step-label">ETAPA 1 de 2: Sobre a empresa</span>
      <h1>Informações básicas</h1>
      <p>Para encontrar as melhores opções de financiamento, precisamos de algumas informações:</p>
    </div>
    <form name="companyForm" [formGroup]="companyDataFormGroup">
      <div class="field col-12 col-lg-6">
        <mat-icon fontSet="fas" fontIcon="fa-building" class="colored-icon"></mat-icon>
        <div class="" style="width: 100%">
          <mat-form-field color="primary">
            <mat-label>CNPJ</mat-label>
            <input matInput type="text" name="CNPJ" placeholder="CNPJ" formControlName="cnpjCtrl"
              [textMask]="{ mask: MASKS.cnpj.textMask }" required />
            <mat-error *ngIf="companyDataFormGroup.get('cnpjCtrl')?.hasError('required')">
              O CNPJ é obrigatório
            </mat-error>
            <mat-error *ngIf="companyDataFormGroup.get('cnpjCtrl')?.hasError('cnpj')"> CNPJ inválido </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="field col-12 col-lg-6">
        <mat-icon fontSet="fas" fontIcon="fa-wallet" class="colored-icon"></mat-icon>

        <mat-form-field color="primary">
          <mat-label>Faturamento Anual</mat-label>
          <input matInput placeholder="Faturamento Anual" name="revenue" matTooltip="Soma do faturamento dos últimos 12 meses “não colocar os centavos”
            Exemplo - 120.000,00 = 120.000." required formControlName="revenueCtrl"
            (change)="revenueValidation()" />
          <mat-hint>Desconsidere os centavos</mat-hint>
          <mat-error *ngIf="companyDataFormGroup.get('revenueCtrl')?.hasError('required')">O faturamento é
            obrigatório</mat-error>
          <mat-error *ngIf="companyDataFormGroup.get('revenueCtrl')?.hasError('min')">
            Infelizmente no momento só conseguimos atender empresas com faturamento maior que
            {{ config?.minRevenueStr }}.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field d-flex align-items-center col-12 col-lg-6">
        <mat-icon fontSet="fas" fontIcon="fa-envelope" class="colored-icon"></mat-icon>

        <mat-form-field color="primary">
          <mat-label>E-mail</mat-label>
          <input matInput placeholder="E-mail" name="email" required formControlName="emailCtrl" oncopy="return false"
            oncut="return false" />
          <mat-error *ngIf="companyDataFormGroup.get('emailCtrl')?.hasError('required')">O e-mail é
            obrigatório</mat-error>
          <mat-error *ngIf="companyDataFormGroup.get('emailCtrl')?.hasError('email')">E-mail inválido</mat-error>
        </mat-form-field>
      </div>
      <div class="field d-flex align-items-center col-12 col-lg-6">
        <mat-icon fontSet="fas" fontIcon="fa-envelope" class="colored-icon"></mat-icon>

        <mat-form-field color="primary">
          <mat-label>Confirme seu e-mail</mat-label>
          <input matInput placeholder="Confirme seu e-mail" name="email-match" required
            formControlName="emailMatchCtrl" onpaste="return false" />
          <mat-error *ngIf="companyDataFormGroup.get('emailMatchCtrl')?.hasError('required')">Confirmação
            obrigatória</mat-error>
          <mat-error *ngIf="companyDataFormGroup.get('emailMatchCtrl')?.hasError('email')">E-mail inválido</mat-error>
          <mat-error *ngIf="companyDataFormGroup.get('emailMatchCtrl')?.hasError('mismatch')">Os e-mails devem ser
            iguais</mat-error>
        </mat-form-field>
      </div>
      <div class="field col-12 col-lg-6">
        <mat-icon fontSet="fab" fontIcon="fa-whatsapp" class="colored-icon"></mat-icon>

        <mat-form-field color="primary">
          <mat-label>WhatsApp</mat-label>
          <input matInput placeholder="WhatsApp" name="whatsapp" required formControlName="whatsappCtrl"
            [textMask]="{ mask: formatPhone, guide: false }" />
          <mat-error *ngIf="companyDataFormGroup.get('whatsappCtrl')?.hasError('required')">O WhatsApp é
            obrigatório</mat-error>
          <mat-error *ngIf="
              companyDataFormGroup.get('whatsappCtrl')?.hasError('telefone') ||
              companyDataFormGroup.get('whatsappCtrl')?.hasError('minlength')
            ">WhatsApp inválido</mat-error>
        </mat-form-field>
      </div>
      <div class="field col-12 col-lg-6">
        <mat-icon fontSet="fas" fontIcon="fa-hand-holding-usd" class="colored-icon"></mat-icon>
        <div class="flex-fill">
          <app-form-field-select
            fieldName="Garantias disponíveis"
            controlName="guaranteesCtrl"
            (selectionChange)="onChangeGuarantees($event)"
            [formGroup]="companyDataFormGroup"
            matTooltip="
              -Recebíveis: É uma linha de crédito para receber antecipadamente suas vendas realizadas a prazo através
              de cartão de crédito, boleto e/ou nota fiscal.

              -Imóvel operacional: Sede da empresa, galpão, fábrica, salas comerciais e etc.

              -Imóvel Não-Operacional: Residencial.

              -Aval: Sem garantia real, nessa linha o sócio é o garantidor."
            [options]="config?.guarantees"
            [compareWith]="true"
            [multiple]="true"
            [required]="true"
          ></app-form-field-select>
        </div>
      </div>
      <div *ngIf="privacyPolicyUrl" class="col-12 col-lg-9 mt-3 mb-4 d-flex">
        <mat-checkbox color="primary" class="mr-3 my-1 checkbox" formControlName="agreedPrivacyPolicyCtrl"
          required></mat-checkbox>
        <span style="flex: 1">Eu li e concordo com a
          <a class="link" [href]="privacyPolicyUrl" target="_blank">política de privacidade</a> e com os 
            <a class="link" (click)="scrModal()" style="color: #0091ff;" >Termos de Consulta dos Sócios (SCR).</a> </span>
      </div>
      <div class="col-12 order-12">
        <button mat-raised-button color="primary" class="action-button" type="button"
          [class.disabled]="companyDataFormGroup.invalid" [disabled]="companyDataFormGroup.invalid" *ngIf="!isProcessing">
          <img height="1" width="1" style="display: none" alt=""
            src="https://px.ads.linkedin.com/collect/?pid=695505&conversionId=4039849&fmt=gif" />
          Continuar
        </button>
        <div class="text-center">
          <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
        </div>
      </div>
    </form>
  </section>

  <section class="anticipation" *ngIf="step === 'anticipation'">
    <div class="step-description-container">
      <span class="step-label">Etapa 2 de 2: sobre seus recebíveis</span>
      <h1>Sobre seus recebíveis</h1>
      <p>Para encontrar as melhores opções de financiamento, precisamos de algumas informações:</p>
    </div>
    <form action="anticipationForm" [formGroup]="anticipationDataFormGroup">
      <div class="col-12 col-lg-5">
        <app-form-field-select
          fieldName="Tipo de Recebíveis"
          matTooltip="
          Cartão: Linha de crédito para vendas realizadas a prazo (cartão de crédito) através de maquininhas de cartão.
          Boleto: Linha de crédito para vendas realizadas a prazo por boleto.
          Notas fiscais: Linha de crédito para vendas realizadas a prazo por nota fiscal de venda."
          controlName="typeOfAnticipationCtrl"
          (selectionChange)="onChangeAnticipation($event)"
          [formGroup]="anticipationDataFormGroup"
          [options]="selectTypeOfAnticipationOperator"
          [multiple]="true"
          [required]="hasTypeOfAnticipation"
        ></app-form-field-select>
      </div>
      <div class="field col-12 col-lg-12" *ngIf="hasTypeOfAnticipationCard || hasTypeOfInvoices">
        <div class="flex-fill row">
          <div class="col-12 col-lg-6" *ngIf="hasTypeOfAnticipationCard">
            <app-form-field-select
              fieldName="Maquininha de cartão"
              controlName="creditCardCtrl"
              [formGroup]="anticipationDataFormGroup"
              [options]="config?.cardMachines"
              [multiple]="true"
              [required]="hasTypeOfAnticipationCard"
            ></app-form-field-select>
          </div>
          <div class="col-12 col-lg-6" *ngIf="hasTypeOfInvoices">
            <app-form-field-select
              fieldName="Tipo de Nota Fiscal"
              controlName="typeOfInvoicesCtrl"
              [formGroup]="anticipationDataFormGroup"
              [options]="selectTypeOfInvoices"
              [multiple]="true"
              [required]="hasTypeOfInvoices"
            ></app-form-field-select>
          </div>
        </div>
      </div>
      <div class="field-wrapper">
        <span>Faz a antecipação de cartão automática?*</span>
        <button>Não</button>
        <button>Sim</button>
      </div>
      <div class="field col-12">
        <button mat-stroked-button type="button" class="action-button text-black autosized"
          (click)="previousStep(step)" *ngIf="!isProcessing">
          Voltar
        </button>
        <button mat-raised-button color="primary" class="action-button" type="button"
          [class.disabled]="anticipationDataFormGroup.invalid" [disabled]="anticipationDataFormGroup.invalid" *ngIf="!isProcessing">
          Concluir
        </button>
        <div class="text-center">
          <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
        </div>
      </div>
    </form>
  </section>

  <section class="vehicle" *ngIf="step === 'vehicle'">
    <div class="step-description-container">
      <span class="step-label">Etapa 2 de 2: sobre seu veículo</span>
      <h1>Sobre seu veículo</h1>
      <p>Para encontrar as melhores opções de financiamento, precisamos de algumas informações:</p>
    </div>
    <form action="vehicleDataFormGroup" [formGroup]="vehicleDataFormGroup">
      <div class="field-wrapper">
        <span>Seu veículo possui menos de 20 anos?*</span>
        <button (click)="this.vehicleDataFormGroup.get('moreThanTwentyYearsCtrl')?.setValue(false)">Não</button>
        <button (click)="this.vehicleDataFormGroup.get('moreThanTwentyYearsCtrl')?.setValue(true)">Sim</button>
      </div>
      <div class="field-wrapper">
        <span>Seu veículo está pelo menos 80% quitado?*</span>
        <button (click)="this.vehicleDataFormGroup.get('moreThanEightyPercentPaid')?.setValue(false)">Não</button>
        <button (click)="this.vehicleDataFormGroup.get('moreThanEightyPercentPaid')?.setValue(true)">Sim</button>
      </div>
      <div class="field col-12">
        <button mat-stroked-button type="button" class="action-button text-black autosized"
          (click)="previousStep(step)" *ngIf="!isProcessing">
          Voltar
        </button>
        <button mat-raised-button color="primary" class="action-button" type="button"
          [class.disabled]="vehicleDataFormGroup.invalid" [disabled]="vehicleDataFormGroup.invalid" *ngIf="!isProcessing" (click)="getVehicleValue(vehicleDataFormGroup.value)">
          Concluir
        </button>
        <div class="text-center">
          <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
        </div>
      </div>
    </form>
  </section>

  <section class="property" *ngIf="step === 'property'">
    <div class="step-description-container">
      <span class="step-label">Etapa 2 de 3: sobre seu imóvel</span>
      <h1>Sobre seu imóvel</h1>
      <p>Para encontrar as melhores opções de financiamento, precisamos de algumas informações:</p>
    </div>
    <form action="propertyDataForm" [formGroup]="propertyDataFormGroup">
      <div class="field col-12 col-lg-6">
        <mat-form-field color="primary">
          <mat-label>Tipo de imóvel</mat-label>
          <input matInput placeholder="Tipo de imóvel" name="typeOfProperty" required formControlName="typeOfPropertyCtrl" />
          <mat-error *ngIf="propertyDataFormGroup.get('typeOfPropertyCtrl')?.hasError('required')">O tipo de imóvel é
            obrigatório</mat-error>
        </mat-form-field>
      </div>
      <div class="field col-12 col-lg-6">
        <mat-form-field color="primary">
          <mat-label>Valor do imóvel</mat-label>
          <input matInput placeholder="Valor do imóvel" name="propertyValue" required formControlName="propertyValueCtrl" />
          <mat-error *ngIf="propertyDataFormGroup.get('propertyValueCtrl')?.hasError('required')">O valor do imóvel é
            obrigatório</mat-error>
        </mat-form-field>
      </div>
      <div class="field col-12 col-lg-6">
        <mat-form-field color="primary">
          <mat-label>Cidade do imóvel</mat-label>
          <input matInput placeholder="Valor do imóvel" name="propertyCity" required formControlName="propertyCityCtrl" />
          <mat-error *ngIf="propertyDataFormGroup.get('propertyCityCtrl')?.hasError('required')">A cidade do imóvel é
            obrigatório</mat-error>
        </mat-form-field>
      </div>
      <div class="field col-12 col-lg-6">
        <mat-form-field color="primary">
          <mat-label>Estado do imóvel</mat-label>
          <input matInput placeholder="Estado do imóvel" name="propertyState" required formControlName="propertyStateCtrl" />
          <mat-error *ngIf="propertyDataFormGroup.get('propertyStateCtrl')?.hasError('required')">O estado do imóvel é
            obrigatório</mat-error>
        </mat-form-field>
      </div>
      <div class="field-wrapper">
        <span>Seu veículo está pelo menos 80% quitado?*</span>
        <button (click)="this.vehicleDataFormGroup.get('moreThanEightyPercentPaid')?.setValue(false)">Não</button>
        <button (click)="this.vehicleDataFormGroup.get('moreThanEightyPercentPaid')?.setValue(true)">Sim</button>
      </div>
    </form>
  </section>
</div>
