import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from '../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AsaasService {


  constructor(private http: HttpClient, private authService: AuthService, private angularFirestore: AngularFirestore,) { }


  createChargeAsaas(customerId: string, dueDate: Date, value: number, description: string, fineValue: number, interestValue: number, institutionName: string,): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.functionsUrl}/asaasfinancialapi/create-charge`,
          {
            data: {
              customerId,
              dueDate,
              value,
              description,
              fineValue,
              interestValue,
              institutionName
            }
          },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then((asaasPaymentData) => {
          console.log(asaasPaymentData)
        })
        .catch((err) => {
          console.error('Erro ao criar cobrança', err);
          reject(`error-charge: ${err.message}`);
        });
    });
  }
  
  // Função para salvar asaasPayment no Firebase
  private saveAsaasPaymentInFirebase(customerId: string, asaasPaymentData: any): Promise<void> {
    return new Promise((resolve, reject) => {
      // Assuma que 'customerCollection' é uma referência à coleção de clientes no Firebase
      const customerDocRef = this.angularFirestore.collection('customers').doc(customerId);
  
      // Adicione asaasPayment como um novo documento na subcoleção "asaas"
      customerDocRef.collection('asaas').add(asaasPaymentData)
        .then((docRef) => {
          console.log('Document written with ID: ', docRef.id);
          resolve();
        })
        .catch((firebaseErr) => {
          reject(firebaseErr);
        });
    });
  }
  

  getAsaasPaymentsByCustomerId(customerId: string): Observable<any[]> {
    return this.angularFirestore.collection('asaas', ref => ref.where('uid', '==', customerId)).valueChanges();
  }
  

  negativityChargeAsaas(payment: string, customerName: string, customerCpfCnpj: string, customerPrimaryPhone: string, customerPostalCode: string, customerAddress: string, customerAddressNumber: string, customerProvince: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.functionsUrl}/asaasfinancialapi/negativity-charge`,
          {
            data: {
              payment,
              type: "CREDIT_BUREAU",
              customerName,
              customerCpfCnpj,
              customerPrimaryPhone,
              customerPostalCode,
              customerAddress,
              customerAddressNumber,
              customerProvince,
            }

          },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Cobrança negativada');

          resolve();
        })
        .catch((err) => {
          console.error('Erro ao negativar cobrança', err);
          reject(`error-charge: ${err.message}`);
        });
    });
  }

  cancelChargeAsaas(payment: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          `${environment.functionsUrl}/asaasfinancialapi/cancel-charge/${payment}`,
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Cobrança cancelada');
          resolve();
        })
        .catch((err) => {
          console.error('Erro ao cancelar cobrança', err);
          reject(`error-charge: ${err.message}`);
        });
    });
  }


  getInterestAndFine() {
    return this.angularFirestore.collection('configuracao').doc('asaas').valueChanges();
  }


}
