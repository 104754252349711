import { Opportunity } from './../../../../functions/src/models/opportunity/Opportunity';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { Contract } from 'functions/src/models/Contract';
import { Customer } from 'functions/src/models/Customer';
import { CreditoPreAprovado, DocControleVencimento } from 'functions/src/models/model-interface';
import { AgentNote } from 'functions/src/models/Notes';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotesService } from 'src/app/admin/services/notes.service';
import { LogService } from 'src/app/components/logger/log.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import {
  OpportunityStageSituationAgent,
  OpportunityStageSituationCustomer,
} from 'functions/src/models/opportunity/OpportunityStages';
import { DocumentoPendente, TipoDocumento, UserDocuments } from '../containers/lead/lead.interface';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  tiposDocumentosCollection: AngularFirestoreCollection<TipoDocumento>;
  userDocumentsCollection: AngularFirestoreCollection<UserDocuments>;
  userDocuments: Observable<UserDocuments[]>;
  userDocumentDoc: AngularFirestoreDocument<UserDocuments>;

  constructor(
    private authService: AuthService,
    private angularFirestore: AngularFirestore,
    private http: HttpClient,
    private logger: LogService,
    private notesService: NotesService
  ) {}

  addControleVencimento(dadosControleVencimento: DocControleVencimento): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getControleVencimento(dadosControleVencimento.uid, dadosControleVencimento.tipoDocumento).then(
        (dadosExistentes) => {
          if (dadosExistentes.length > 0) {
            dadosExistentes.forEach((item, index) => {
              this.deleteControleVencimento(item['id'])
                .then(() => {
                  this.logger.info('item de controle de vencimento de documentos excluído com sucesso');
                })
                .catch((e) => {
                  this.logger.fatal('Problemas ao excluir um item de controle de vencimento de documentos');
                });
            });
          }
          const collectionRef = this.angularFirestore.collection('user-doc-controle-vencimento');
          collectionRef
            .add(dadosControleVencimento)
            .then((retorno) => {
              this.logger.info('Inclusão no controle de vencimento incluído com sucesso.');
              resolve(retorno.id);
            })
            .catch((e) => {
              this.logger.fatal('Houve um problema na inclusão no controle de vencimento de documentos.', e);
              reject('error');
            });
        }
      );
    }).catch((e) => {
      this.logger.fatal(
        'Problemas ao buscar os controle de vencimento de um usuário',
        dadosControleVencimento.uid,
        dadosControleVencimento.tipoDocumento,
        e
      );
    });
  }

  createNote(uid: string, note: AgentNote, notificationData: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const now = firestore.Timestamp.now();

      this.angularFirestore
        .collection('customers')
        .doc(uid)
        .collection('agent-notes')
        .doc(now.toDate().toISOString())
        .set({
          ...note,
          createdAt: now,
          id: now.toDate().toISOString(),
        })
        .then(() => {
          this.http
            .post(
              `${environment.functionsUrl}/email/notify-new-note`,
              {
                agent: note.createdBy.name,
                content: note.message,
                customer: notificationData.companyName,
                redirectTo: `${environment.baseURL}/admin/clientes/detalhes/${uid}/assessor`,
                type: 'admin-agent',
              },
              { responseType: 'text', headers: this.authService.getHeader() }
            )
            .toPromise()
            .then(() => {
              console.log('Note notification was sent to admin');
            })
            .catch((error) => {
              console.error('Error sending note notification to admin.', error);
            });
          this.http
            .post(
              `${environment.functionsUrl}/zendesk/create-agent-comment`,
              {
                agentUid: note.agent,
                comment: note.message,
                senderType: note.type,
                customerUid: uid,
              },
              { responseType: 'text', headers: this.authService.getHeader() }
            )
            .toPromise()
            .then(() => {
              console.log('Note notification was sent to admin');
            })
            .catch((error) => {
              console.error('Error sending note notification to admin.', error);
            });

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteControleVencimento(docId: string) {
    return this.angularFirestore.collection('user-doc-controle-vencimento').doc(docId).delete();
  }

  deleteDocumentoPendente(uid: string) {
    return this.angularFirestore.collection('user-documents-pending').doc(uid).delete();
  }

  getAllNotes(uid: string, agent: string): Observable<AgentNote[]> {
    return this.angularFirestore
      .collection('customers')
      .doc(uid)
      .collection('agent-notes', (ref) => ref.where('agent', '==', agent).orderBy('createdAt', 'desc'))
      .valueChanges();
  }

  getContractData(uid: string): Observable<Contract> {
    return this.angularFirestore.collection('contract').doc(uid).valueChanges();
  }

  getBigDataCorpSavedData(uid: string): Observable<any> {
    return this.angularFirestore.collection(`customers`).doc(uid).collection('bigdatacorp').valueChanges();
  }

  getControleVencimento(uid: string, tipoDocumento: string): Promise<any> {
    const collectionRef = this.angularFirestore.collection<DocControleVencimento>('user-doc-controle-vencimento', (x) =>
      x.where('uid', '==', uid).where('tipoDocumento', '==', tipoDocumento)
    );
    const dadosControleVencimento = collectionRef.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as DocControleVencimento;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return new Promise((resolve, reject) => {
      dadosControleVencimento.subscribe((dadosExistentes) => {
        if (dadosExistentes) {
          resolve(dadosExistentes);
        } else {
          resolve(null);
        }
      });
    }).catch((e) => {
      this.logger.log('Problemas ao buscar os controle de vencimento de um usuário', uid, tipoDocumento, e);
    });
  }

  getDetalhesBanco(idBanco: string) {
    return this.angularFirestore.doc(`instituicoes-financeiras/${idBanco}`).get();
  }

  getLeadData(uid: string): Observable<Customer> {
    return this.angularFirestore.collection('customers').doc(uid).valueChanges();
  }

  getLeadStatus(agreedTerms: boolean, opportunities: Opportunity[]): string {
    let status = 'Aguardando assinatura do termo';

    if (agreedTerms) {
      if (opportunities.length) {
        const steps = opportunities.map((op) => op.stageOpportunity.defaultprobability);
        const stepsWithoutZero = steps.filter((op) => op !== 0);
        const [step] = (stepsWithoutZero.length > 0 ? stepsWithoutZero : steps).sort((a, b) => a - b) ?? [0];

        status = OpportunityStageSituationAgent[step];
      } else {
        status = 'Sem Oportunidades Abertas';
      }
    }

    return status;
  }

  getOpportunityStatus(opportunity: Opportunity): string {
    if (opportunity) {
      return OpportunityStageSituationCustomer[opportunity.stageOpportunity?.defaultprobability] || '-';
    } else {
      return '-';
    }
  }

  getTiposDocumentos(userDocuments: UserDocuments) {
    this.tiposDocumentosCollection = this.angularFirestore.collection('tipo-documento', (x) =>
      x.where('ativo.id', '==', 'sim').where('automatico.id', '==', 'sim').orderBy('nome', 'asc')
    );
    const tipoDocumento: Observable<TipoDocumento[]> = this.tiposDocumentosCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as TipoDocumento;
          data.id = a.payload.doc.id;
          data.situacao = 'Pendente';
          data.qtdArquivosEsperados = 1;
          data.qtdArquviosSubmetidos = 0;
          if (data.anoExercicio.id === 'sim') {
            data.qtdArquivosEsperados = data.anoExercicioQtd;
          }
          if (userDocuments && userDocuments.documentos) {
            const userData = userDocuments.documentos.find((f) => f.mnemonico === data.mnemonico);
            if (userData) {
              data.situacao = userData.situacao;
              data.qtdArquviosSubmetidos = userData.qtdArquviosSubmetidos;
              data.arquivosInfo = userData.arquivosInfo;
              data.dataAprovacao = userData.dataAprovacao;
              data.motivoReprovacao = userData.motivoReprovacao;
              data.controleVencimentoId = userData.controleVencimentoId;
            }
          }
          return data;
        });
      })
    );
    return tipoDocumento;
  }
}
