<div class="container-fluid">
    <mat-card>
        <mat-card-content>
            <mat-card-title class="mb-4 d-flex align-items-center">
                <span style="flex: 1">Consulta de oportunidades</span>
            </mat-card-title>

            <div class="row px-3 mt-4">
                <div class="col-12 col-sm-5 col-md-4">
                    <mat-form-field style="width: 100%" color="primary">
                        <mat-label>Filtro</mat-label>
                        <mat-select [(ngModel)]="selectedFilter" (valueChange)="clearFilterValue()"
                            [disabled]="isLoadingResults">
                            <mat-option value="companyName"> Razão Social </mat-option>
                            <mat-option value="cnpj"> CNPJ </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-7 col-md-8 d-flex">
                    <mat-form-field style="flex: 1" color="primary">
                        <mat-label>{{ labels[selectedFilter] }}</mat-label>
                        <input matInput [(ngModel)]="filterValue" (keydown.enter)="applyFilter(true)"
                            [placeholder]="labels[selectedFilter]" [disabled]="isLoadingResults" [textMask]="{
                         mask: (selectedFilter === 'cnpj') && MASKS[selectedFilter].textMask
                        }" />
                    </mat-form-field>
                    <button mat-icon-button color="accent" (click)="clearFilter()">
                        <mat-icon>clear</mat-icon>
                    </button>
                    <button mat-icon-button color="accent" (click)="applyFilter(true)">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>
            <div class="w-100 p-5" *ngIf="isLoadingResults">
                <mat-spinner *ngIf="isLoadingResults" [diameter]="80" class="mx-auto" color="primary"></mat-spinner>
            </div>

            <div class="table-container" *ngIf="!isLoadingResults && !dataSource">
                <p class="text-center">Digite o dado a ser buscado.</p>
            </div>

            <div class="table-container" *ngIf="!isLoadingResults && dataSource && length === 0">
                <p class="text-center">Nenhum cliente encontrado.</p>
            </div>

            <div class="table-container mat-elevation-z3" *ngIf="!isLoadingResults && length > 0">
                <div style="overflow-x: scroll">
                    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                        [matSortActive]="sortField" [matSortDirection]="sortDirection" matSortDisableClear>


                        <!-- Company Name Column -->
                        <ng-container matColumnDef="companyName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Razão Social</th>
                            <td mat-cell *matCellDef="let opp">
                                {{ opp.customerData.companyName }}
                            </td>
                        </ng-container>

                        <!-- E-mail Column -->
                        <ng-container matColumnDef="email">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>E-mail</th>
                            <td mat-cell *matCellDef="let opp">{{ opp.customerData.email }}</td>
                        </ng-container>

                        <!-- CNPJ Column -->
                        <ng-container matColumnDef="cnpj">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>CNPJ</th>
                            <td mat-cell *matCellDef="let opp">
                                {{ opp.customerData.cnpj || '-' }}
                            </td>
                        </ng-container>

                        <!-- WhatsApp Column -->
                        <ng-container matColumnDef="whatsApp">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>WhatsApp</th>
                            <td mat-cell *matCellDef="let opp">
                                {{ opp.customerData.phone || '-' }}
                            </td>
                        </ng-container>


                        <!-- uid Column -->
                        <ng-container matColumnDef="uid">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Uid</th>
                            <td mat-cell *matCellDef="let opp">
                                {{ opp.uid }}
                            </td>
                        </ng-container>


                        <!-- Instituição Column -->
                        <ng-container matColumnDef="institution">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Instituição</th>
                            <td mat-cell *matCellDef="let opp">
                                {{ opp.institutionName }}
                            </td>
                        </ng-container>


                        <!-- Fase oportunidade Column -->
                        <ng-container matColumnDef="stageOpportunity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Fase oportunidade
                            </th>
                            <td mat-cell *matCellDef="let opp">

                                {{ opp.stageOpportunity.defaultprobability}}
                            </td>
                        </ng-container>


                        <!-- Limite operação Column -->
                        <ng-container matColumnDef="limitOperation">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Limite Operação</th>
                            <td mat-cell *matCellDef="let opp">
                                {{ opp?.limitOperation | currency: 'R$' || '-'}}
                            </td>
                        </ng-container>

                        <!-- Duração fase atual Column -->
                        <ng-container matColumnDef="duração fase atual">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Duração fase atual
                            </th>
                            <td mat-cell *matCellDef="let opp">
                                {{ opp.lastStageDuration }}
                            </td>
                        </ng-container>



                        <!-- Actions Column -->
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef disableClear>Ações</th>
                            <td mat-cell *matCellDef="let row; let i = index">
                                <div class="w-100 text-center">
                                    <a mat-flat-button color="primary" class="m-1 bt-w"
                                        [routerLink]="'/admin/clientes/detalhes/' + row.uid">
                                        <mat-icon>search</mat-icon>
                                    </a>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </div>
                <mat-paginator [length]="length" [pageIndex]="currentPage" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" (page)="paginatorEvent($event)">
                </mat-paginator>
            </div>

        </mat-card-content>
    </mat-card>
</div>