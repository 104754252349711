import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Agent } from 'functions/src/models/Agent';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AgentService } from '../../services/agent.service';

@Component({
  selector: 'app-agent-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class AgentProfileComponent implements OnInit, OnDestroy {
  agent: Agent;
  agentSubscription: Subscription;
  loading = true;
  processing = false;

  constructor(private authService: AuthService, private agentService: AgentService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.agentSubscription = this.agentService.agent.subscribe((agent) => {
      this.agent = agent;
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
  }

  sendEmailVerification(): void {
    this.authService
      .sendEmailVerification()
      .then(() => {
        this.showMessage(
          'E-mail enviado',
          `Um e-mail de confirmação foi enviado para <strong>${this.agent.email}</strong>. Verifique sua caixa de entrada.`
        );
      })
      .catch((err) => {
        this.showMessage('Erro', `Ocorreu um erro ao enviar o e-mail de confirmação. Tente novamente mais tarde.`);
        console.error('Error sending confirmation', err);
      });
  }

  showMessage(title: string, message: string): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '700px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }
}
