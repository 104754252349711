import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Opportunity } from '../../../../../functions/src/models/opportunity/Opportunity';
import { InstituicaoFinanceira } from '../../../admin/instituicao-financeira/instituicao-financeira';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-description-offer-dialog',
  templateUrl: './description-offer-dialog.component.html',
  styleUrls: ['./description-offer-dialog.component.scss']
})
export class DescriptionOfferDialogComponent implements AfterViewInit {

  @ViewChild('dialogContent') dialogContent: ElementRef;
  
  proposalData: Opportunity;
  institutionData: InstituicaoFinanceira;
  nameIf: string;
  customerData: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private customerService: CustomerService,) { 
    this.proposalData = data.proposal;
    this.institutionData = data.institution;
    this.nameIf = data.nameIf;
    this.customerData = data.customerData;
  }

  ngAfterViewInit(): void {
    // Certifique-se de que o modal comece no topo
    if (this.dialogContent) {
      this.dialogContent.nativeElement.scrollTop = 0;
    }
  }

  redirectToPortal(): void {
    if (this.institutionData && this.institutionData.buttonUrl) {
      let buttonUrl = this.institutionData.buttonUrl;

      if (this.nameIf === 'SuperSim') {
        buttonUrl = buttonUrl.replace('{{cnpj}}', this.customerData.cnpj);
        buttonUrl = buttonUrl.replace('{{email}}', this.customerData.email);

        this.customerService.updateNumberCustomer(1);
      }
      
      window.open(buttonUrl, '_blank');
    }
  }

  
}
