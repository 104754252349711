<div *ngIf="institution">
  <form [formGroup]="batchActionsFormGroup">
    <div class="ifs-locality-form">
      <h4 style="font-weight: 500">Abrir, reabrir ou fechar as oportunidades</h4>
      <hr class="my-2" />
      <p style="font-size: 0.9em">
        <strong>OBS: </strong> Os clientes serão selecionados a partir da data de criação de sua conta, caso
        atendam ao período especificado nos campos <strong>Data Inicial</strong> e <strong>Data Final</strong>.
        <br />A também um checkbox para o <strong>Comitê</strong>, onde se ele estiver ativo ele só alterara os
        clientes aprovados pelo comitê. Vale lembrar que ambos os campos não são obrigatórios.
      </p>
      <p style="font-size: 0.9em">Ações:</p>
      <ul>
        <li>
          <strong>Abrir:</strong> Abre uma nova oportunidade na fase padrão da instituição ({{
          institution.defaultInitialStage || 10
          }}%) para os clientes que não possuem nenhuma oportunidade da instituição atual, independente da fase,
          desde que se adequem à parametrização atual.
        </li>

        <li>
          <strong>Reabrir:</strong> Reabre todas as oportunidades em 0% (Fechado e perdido) para a fase padrão
          da instituição ({{ institution.defaultInitialStage || 10 }}%), desde que se adequem à
          parametrização atual.
        </li>

        <li>
          <strong>Fechar:</strong> Fecha todas as oportunidades dos clientes com oportunidades em andamento
          (fase diferente de 0% e 100%) desta instituição.
        </li>
        <li>
          <strong>Lista de CNPJ:</strong> Atualizar todas as oportunidades dos clientes com o CNPJ listados abaixo, para fase desejada (copiar e colar da planilha).
        </li>
      </ul>
      <div class="col-12" *ngIf="actions">
        <mat-form-field color="primary">
          <mat-label>Ação</mat-label>
          <mat-select formControlName="actionCtrl" required (selectionChange)="optionsBatchActions()">
            <mat-option></mat-option>
            <mat-option *ngFor="let action of actions" [value]="action">{{ action.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="batchActionsFormGroup.get('actionCtrl').hasError('required')">
            Informe a ação desejada.
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field class="col-12 col-lg-6" *ngIf="actionSelected?.id !== 'list-cnpj'">
        <mat-label style="font-weight: 500">Data Inicial</mat-label>
        <input matInput [matDatepicker]="dateInit" placeholder="Data Inicial" name="dateInit"
          formControlName="dateInitCtrl" (focus)="dateInit.open()" />
        <mat-datepicker-toggle matSuffix [for]="dateInit"></mat-datepicker-toggle>
        <mat-datepicker #dateInit></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-12 col-lg-6" *ngIf="actionSelected?.id !== 'list-cnpj'">
        <mat-label style="font-weight: 500">Data Final</mat-label>
        <input matInput [matDatepicker]="dateFinish" placeholder="Data Final" name="dateFinish"
          formControlName="dateFinishCtrl" (focus)="dateFinish.open()" />
        <mat-datepicker-toggle matSuffix [for]="dateFinish"></mat-datepicker-toggle>
        <mat-datepicker #dateFinish></mat-datepicker>
      </mat-form-field>
      <div class="col-12" *ngIf="actionSelected?.id !== 'list-cnpj'">
        <mat-slide-toggle name="AprovadoPorComite" color="primary" formControlName="approvedByCommitteeCtrl">
          Aprovado por comitê
        </mat-slide-toggle>
      </div>

      <app-list-opportunities-by-cnpj *ngIf="actionSelected?.id === 'list-cnpj'"
        [formGroup]="batchActionsFormGroup"></app-list-opportunities-by-cnpj>

      <div class="d-flex justify-content-center" style="margin-top: 40px">
        <button mat-raised-button class="btn" color="primary" type="button" (click)="onIdentifyOppsForIfs()">
          <span *ngIf="!isLoadingAction"> Executar </span>
          <mat-spinner [diameter]="20" class="mx-3 my-2" *ngIf="isLoadingAction"></mat-spinner>
        </button>
      </div>
    </div>
  </form>
</div>
