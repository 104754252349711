<div class="delete-account-container">
  <h3>Cancelar conta</h3>
  <p>Olá, {{customer?.name}},</p>
  <p>É uma pena que queira cancelar sua conta. Se houver algo específico que possamos fazer para melhorar sua
    experiência, por favor entre em contato, estamos sempre buscando melhorar a experiência dos nossos clientes.</p>
  <button routerLink="/mensagens" class="contact-button">Fale conosco</button>
  <form [formGroup]="deleteAccountForm">
    <mat-form-field appearance="outline">
      <mat-label>Por que deseja cancelar sua conta?*</mat-label>
      <mat-select formControlName="reason">
        <mat-option *ngFor="let reason of cancelationReasons" [value]="reason.id">{{ reason.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <input formControlName="password" class="password-input" placeholder="Digite sua senha" type="password" />
    <button class="cancel-button" *ngIf="!isLoading" (click)="onSubmit(deleteAccountForm.value)"
      [disabled]="deleteAccountForm.invalid || buttonDisabled || cancellationRequested">
      Solicitar cancelamento
    </button>
    <div class="cancel-div">
      <mat-spinner color="primary" *ngIf="isLoading" diameter="40"></mat-spinner>
    </div>

  </form>
  <div class="extra-card-container" *ngIf="isExtraCardOpen || cancellationRequested">
    <img src="../../../../assets/images/icons/info.png" />
    <div class="content">
      <h4>Aguarde</h4>
      <p>Seu pedido de cancelamento já foi enviado à nossa equipe e em até 24 horas será concluído. Agradecemos por ter
        sido parte da nossa comunidade. Se mudar de ideia, estaremos aqui para ajudar.</p>
    </div>
  </div>
</div>