import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from 'functions/src/models/Customer';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AgentLeadsService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  searchLeads({ agent, filterField, filterValue, orderField, orderDirection, pageSize, page }): Promise<any> {
    return new Promise((resolve, reject) => {
      if (['name', 'cnpj', 'companyName', 'name', 'createdAt'].includes(filterField)) {
        orderField = filterField;
      }

      let params = new HttpParams();
      params = params.append('agent', agent);
      params = params.append('filterField', filterField);
      params = params.append('filterValue', filterValue);
      params = params.append('orderField', orderField);
      params = params.append('orderDirection', orderDirection);
      params = params.append('page', page);
      params = params.append('pageSize', pageSize);

      this.http
        .get(`${environment.functionsUrl}/agent/leads/?${params}`, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res) => {
          resolve(res as any);
        })
        .catch((err) => {
          console.error('Error getting leads of agent.', err);
          reject(err);
        });
    });
  }

  createCustomerAccount(customerData: Customer): Promise<any> {
    return this.http
      .post(
        `${environment.functionsUrl}/customer/create`,
        { user: customerData },
        { responseType: 'json', headers: this.authService.getHeader() }
      )
      .toPromise();
  }

  notifyCustomerViaEmail(
    customerData: Customer,
    agentData: {
      name: string;
      email: string;
    }
  ): Promise<any> {
    return this.http
      .post(
        `${environment.functionsUrl}/email/notify-customer-created`,
        {
          customerEmail: customerData.email,
          customerPassword: customerData.password,
          customerName: customerData.name,
          agentName: agentData.name,
          agentEmail: agentData.email,
          createdBy: 'AGENT',
        },
        { responseType: 'json', headers: this.authService.getHeader() }
      )
      .toPromise();
  }
}
