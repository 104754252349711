import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { environment } from '../../../environments/environment';

import { Observable, Subscription, of, timer } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Agent } from 'functions/src/models/Agent';
import { User, UserTypeFlags } from 'functions/src/models/User';
import { AgentService } from 'src/app/agents/services/agent.service';
import { UserCredential } from '../models/model-interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: Observable<User>;
  idTokenUser: Observable<string>;
  userSessionToken: string;

  confirmUserSubscription: Subscription;
  userSubscription: Subscription;

  constructor(
    @Optional() @SkipSelf() auth: AuthService,
    private angularFireAuth: AngularFireAuth,
    private angularFirestore: AngularFirestore,
    private router: Router,
    private http: HttpClient,
    private agentService: AgentService
  ) {
    if (auth) {
      throw new Error('AuthService already injected');
    }
    // monitoramos eventos de autenticacao
    this.configUserObservables();
  }

  async checkUserType(email: string): Promise<UserTypeFlags> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`users/${email.trim().toLowerCase()}`)
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.exists) {
            const { isAdmin, isAgent, isBanker } = doc.data();
            return resolve({
              isAdmin: !!isAdmin,
              isAgent: !!isAgent,
              isBanker: !!isBanker,
              isUser: !isAgent && !isBanker,
            });
          }
          return resolve(null);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  sendEmailResetPassword(userEmail: string) {
    return new Promise((resolve, reject) => {
      const auth = this.angularFireAuth.auth.app.auth();
      auth
        .sendPasswordResetEmail(userEmail)
        .then(() => {
          console.log('Reset password e-mail sent');
          resolve();
        })
        .catch((err) => {
          console.log('Error sending reset password e-mail', err);
          reject(err);
        });
    });
  }

  confirmResetCode(actionCode): Promise<string> {
    return new Promise((resolve, reject) => {
      const auth = this.angularFireAuth.auth.app.auth();
      return auth
        .verifyPasswordResetCode(actionCode)
        .then((email) => {
          resolve(email);
        })
        .catch((error) => {
          console.error('Error validating reset password code', error);
          reject(error);
        });
    });
  }

  resetPassword(actionCode: string, newPassword: string, userEmail: string): Promise<void> {
    const auth = this.angularFireAuth.auth.app.auth();

    return new Promise((resolve, reject) => {
      auth
        .verifyPasswordResetCode(actionCode)
        .then((accountEmail) => {
          if (userEmail === accountEmail) {
            auth
              .confirmPasswordReset(actionCode, newPassword)
              .then(() => {
                console.log('Password reset has been confirmed and new password updated');
                resolve();
              })
              .catch((err) => {
                console.log('Error reseting the password', err);
                reject(err);
              });
          } else {
            reject(new Error('E-mail inválido'));
          }
        })
        .catch((err) => {
          console.log('Error validating password reset code', err);
          reject(err);
        });
    });
  }

  getHeader(): any {
    return {
      Authorization: 'Bearer ' + this.userSessionToken,
      'Content-Type': 'application/json',
    };
  }

  async emailPasswordLoginAsPromise(login): Promise<firebase.User> {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.auth
        .signInWithEmailAndPassword(login.email, login.password)
        .then((credential) => {
          this.updateUserWithAuth(credential.user);
          resolve(credential.user);
        })
        .catch((err) => {
          console.error('Auth Service - Error on login', err);
          reject(err);
        });
    });
  }

  async loginWithVerification(userEmail: string, userPwd: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const credential = firebase.auth.EmailAuthProvider.credential(userEmail, userPwd);
      this.angularFireAuth.auth
        .signInWithCredential(credential)
        .then((currentUser) => {
          currentUser.user.sendEmailVerification();
          resolve();
        })
        .catch((err) => {
          console.error('Error logging in', err);
          reject(err);
        });
    });
  }

  async loginWithAnonymousAccount() {
    return this.angularFireAuth.auth.signInAnonymously().catch((error) => {
      console.error('Não foi possível realizar um login anonimo', error);
    });
  }

  async signOut(): Promise<void> {
    try {
      await this.angularFireAuth.auth.signOut();
      this.router.navigate(['/']);
    } catch (e) {
      console.error('Error on SignOut', e);
    }
  }

  async signOutNoRedirect(): Promise<void> {
    try {
      await this.angularFireAuth.auth.signOut();
    } catch (e) {
      console.error('Error on SignOut', e);
    }
  }

  createAccount(account): Promise<UserCredential> {
    let userAuth: UserCredential;
    return new Promise((resolveCAC, rejectCAC) => {
      const prevUser = this.angularFireAuth.auth.currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(account.email, account.senha);
      this.angularFireAuth.auth.currentUser
        .linkWithCredential(credential)
        .then((userCredential) => {
          userAuth = {
            uid: userCredential.user.uid,
            email: userCredential.user.email,
            emailVerified: userCredential.user.emailVerified,
            erroMsg: '',
          };
          userCredential.user.sendEmailVerification();
          // prevUser.delete();
          resolveCAC(userAuth);
        })
        .catch((e) => {
          if (e.code === 'auth/email-already-in-use') {
            userAuth = {
              uid: '',
              email: '',
              emailVerified: false,
              erroMsg: 'E-mail em uso.',
            };
            resolveCAC(userAuth);
          } else {
            rejectCAC(e);
          }
        });
    });
  }

  createAccountNoVerification(account): Promise<UserCredential> {
    let userAuth: UserCredential;
    return new Promise((resolveCAC, rejectCAC) => {
      const prevUser = this.angularFireAuth.auth.currentUser;
      this.angularFireAuth.auth
        .createUserWithEmailAndPassword(account.email, account.senha)
        .then((userCredential) => {
          userAuth = {
            uid: userCredential.user.uid,
            email: userCredential.user.email,
            emailVerified: userCredential.user.emailVerified,
            erroMsg: '',
          };
          this.angularFireAuth.auth
            .signInWithEmailAndPassword(account.email, account.senha)
            .then(() => {
              // prevUser.delete();
              resolveCAC(userAuth);
            })
            .catch((error) => {
              rejectCAC(error);
            });
        })
        .catch((e) => {
          if (e.code === 'auth/email-already-in-use') {
            userAuth = {
              uid: '',
              email: '',
              emailVerified: false,
              erroMsg: 'E-mail em uso.',
            };
            resolveCAC(userAuth);
          } else {
            rejectCAC(e);
          }
        });
    });
  }

  refreshCurrentUser() {
    return this.angularFireAuth.auth.currentUser.reload();
  }

  createOrUpdateUserDataFirestore(
    user,
    accountInfo,
    avaliacaoChecklist,
    operacaoCreditoInicial,
    listCreditoPreAprovado
  ) {
    const data: User = {
      uid: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
      statusContrato: 'none',
      estaAtivo: true,
    };
    if (accountInfo) {
      data.nome = accountInfo.nome.toUpperCase();
      data.displayName = accountInfo.nome.toUpperCase();
      data.dataUltimaInteracao = firebase.firestore.Timestamp.now();

      if (accountInfo.notificacoes) {
        data.notificacoes = accountInfo.notificacoes;
      }
      if (accountInfo.telefone) {
        data.telefone = accountInfo.telefone;
      }
      if (accountInfo.situacao) {
        data.situacao = accountInfo.situacao;
      }
    }
    if (avaliacaoChecklist) {
      data.avaliacaoChecklist = avaliacaoChecklist;
    }
    if (operacaoCreditoInicial) {
      data.operacaoCreditoInicial = operacaoCreditoInicial;
    }
    if (listCreditoPreAprovado) {
      data.listCreditoPreAprovado = listCreditoPreAprovado;
    }
    if (user.concordaComTermos) {
      data.concordaComTermos = user.concordaComTermos;
      data.datetimeAceite = firebase.firestore.Timestamp.now();
    }
    if (user.companyName) {
      data.companyName = user.companyName;
    }

    const userRef: AngularFirestoreDocument<any> = this.angularFirestore.doc(`users/${data.email}`);

    return new Promise((resolveCDF, rejectCDF) => {
      userRef
        .set(data, { merge: true })
        .then((s) => {
          resolveCDF('sucesso');
        })
        .catch((e) => {
          console.error('Problemas na criação do usuário.', e);
          rejectCDF('erro');
        });
    });
  }

  /**
   *
   */
  resendConfirmationEmailAsPromise(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.angularFireAuth.auth.currentUser && !this.angularFireAuth.auth.currentUser.emailVerified) {
        this.angularFireAuth.auth.currentUser
          .sendEmailVerification()
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve();
      }
    });
  }

  /**
   * Este metodo liga um timer para um polling de deteccao da acao do usuario confirmar o email que recebeu
   * (so deve estar ativo quando uma das telas do usuario esta visivel)
   */
  startPollUserConfirm() {
    const confirmUserTimer = timer(10 * 1000, 5 * 1000); // trigger inicial 10s; depois a cada 5s.
    this.confirmUserSubscription = confirmUserTimer.subscribe((_) => {
      console.log('user poll');

      const authStateObservable = this.angularFireAuth.authState.pipe(take(1));

      authStateObservable.subscribe((authState) => {
        authState
          .reload()
          .then(() => {
            this.configUserObservables();
          })
          .catch((e) => {
            console.error('user reload error', e);
            this.configUserObservables();
          });
      });
    });
  }

  /**
   * pahra o polling ed deteccao do usuario
   */
  stopPollUserConfirm() {
    if (this.confirmUserSubscription) {
      this.confirmUserSubscription.unsubscribe();
    }
  }

  /**
   * private functions
   */

  /**
   * configura observable e subscriptions do usuario (auth firebase e collection)
   */
  private configUserObservables(): void {
    this.idTokenUser = this.angularFireAuth.idToken;

    this.user = this.angularFireAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          if (!user.isAnonymous) {
            this.updateUserWithAuth(user);
            return this.angularFirestore.doc<User>(`users/${user.uid}`).valueChanges();
          } else {
            return of({
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              isAnonymous: true,
              isAgent: false,
              nome: '',
            } as User);
          }
        } else {
          return of(null);
        }
      })
    );
  }

  private updateUserWithAuth(authUser) {
    const userRef: AngularFirestoreDocument<any> = this.angularFirestore.doc(`users/${authUser.email}`);

    const data: User = {
      uid: authUser.uid,
      email: authUser.email,
      displayName: authUser.displayName,
      emailVerified: authUser.emailVerified,
      photoURL: authUser.photoURL ? authUser.photoURL : null,
      isAnonymous: authUser.isAnonymous,
    };

    return userRef
      .set(data, { merge: true })
      .then(() => {
        console.log('Updated user data');
      })
      .catch((err) => {
        console.error('Error updating user data', err);
      });
  }

  updateUser(user) {
    const userRef: AngularFirestoreDocument<any> = this.angularFirestore.doc(`users/${user.email}`);
    return userRef
      .set(user, { merge: true })
      .then(() => {
        console.log('updateUser ok');
      })
      .catch((e) => {
        console.error('updateUser', e);
      });
  }

  async sendEmailVerifiedAccount(userData) {
    try {
      const result = await this.http
        .post(`${environment.functionsUrl}/usermanagement/send-email-conta-verificada`, userData, {
          responseType: 'text',
          headers: this.getHeader(),
        })
        .toPromise();
      return result;
    } catch (error) {
      console.error('Problema ao enviar e-mail de conta confirmada');
    }
  }

  async sendEmailVerifiedAgent(userData) {
    try {
      const result = await this.http
        .post(`${environment.functionsUrl}/email/agent-verified-account`, userData, {
          responseType: 'text',
          headers: this.getHeader(),
        })
        .toPromise();
      return result;
    } catch (error) {
      console.error('Problema ao enviar e-mail de conta confirmada');
    }
  }

  async checkIfAgentExists(agent: string, isEmail = false): Promise<string> {
    return new Promise((resolve, reject) => {
      if (isEmail) {
        this.angularFirestore
          .collection('agents', (ref) => ref.where('email', '==', agent.trim().toLowerCase()))
          .get()
          .toPromise()
          .then((query) => {
            if (query.empty) {
              return reject('not-found');
            } else {
              return resolve(query.docs[0].data().uid);
            }
          })
          .catch((err) => {
            return reject(err);
          });
      } else {
        this.angularFirestore
          .collection('agents')
          .doc(agent.trim())
          .get()
          .toPromise()
          .then((query) => {
            if (!query.exists) {
              return reject('not-found');
            } else {
              return resolve(query.data().uid);
            }
          })
          .catch((err) => {
            return reject(err);
          });
      }
    });
  }

  getUserAgentData(uid): Promise<Agent> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection('agents')
        .doc(uid.trim())
        .get()
        .toPromise()
        .then((query) => {
          if (!query.exists) {
            return resolve();
          } else {
            return resolve(query.data());
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }
}
