import { Component } from '@angular/core';

@Component({
  selector: 'app-aside-chat-faq',
  templateUrl: './aside-chat-faq.component.html',
  styleUrls: ['./aside-chat-faq.component.scss']
})
export class AsideChatFaqComponent {
  currentLayer: string;

  constructor() {
    this.currentLayer = 'first';
   }

  setLayer(layer: string): void {
    this.currentLayer = layer;
  }

}
