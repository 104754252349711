import { Customer } from '../models/Customer';
export interface BndesOfferServiceRequest {
  cnpj?: string;
  cpf?: string;
  mei?: boolean;
  cpfEmpresarioIndividual?: string;
  dataNascimento?: Date;
  tempoConstituicao?: number;
  cnaePrincipal?: string;
  cnaesSecundarios?: string[];
  cep: string;
  valorFaturamento: number;
  valorFinanciamento: number;
  finalidade: LoanPurpose;
}

export interface BndesOfferServiceResponse {
  idOferta?: number;
  tipoOferta: OfferType;
  tipoApoio?: GuaranteesType;
  taxaJuros?: {
    min: number;
    max?: number;
  };
  prazo?: {
    min: number;
    max?: number;
  };
  taxaDesconto?: {
    min: number;
    max?: number;
  };
  prazoAntecipacao?: {
    min: number;
    max?: number;
  };
  opcaoGarantia?: GuaranteeOptions[];
  documentos?: string[];
  prazoLiberacaoRecursos?: number;
}

export interface BndesProposalServiceRequest {
  uid?: string;
  idProposta: number;
  idOferta: number;
  cnpj?: string;
  cpf?: string;
  nome?: string;
  mei?: string;
  cpfEmpresarioIndividual?: string;
  dataNascimento?: Date;
  tempoConstituicao?: number;
  cnaePrincipal?: string;
  cnaesSecundarios?: string[];
  cep: string;
  valorFaturamento: number;
  valorFinanciamento: number;
  finalidade: LoanPurpose;
  descricao?: string;
  contato: {
    nome: string;
    email: string;
    telefone: string;
  };
}

export interface BndesProposal {
  cnpj: string;
  creditScore?: number;
  createdAt?: string;
  customer: Customer;
  idOferta: number;
  idProposta: number;
  proposalId: string;
  urlLandingPage: string;
  uid?: string;
  proposalSituation?: ProposalSituation;
  valorIndicadorDividaTotal?: number;
  valorIndicadorDividaVencida?: number;
  valorIndicadorDividaCurtoPrazo?: number;
  valorIndicadorDividaBaixadaPrejuizo?: number;
  lastUpdate: firebase.firestore.Timestamp;
}

export interface BndesProposalServiceResponse {
  contato: {
    nome?: string;
    email: string;
    telefone: string;
  };
  urlLandingPage: string;
}

export interface BndesUpdateSituationRequest {
  idProposta: number;
  situacaoProposta: ProposalSituation;
  motivoSituacaoProposta?: string;
  dataSituacaoProposta?: Date;
  tipoApoio?: GuaranteesType;
  taxaJuros?: number;
  taxaDesconto?: number;
  valorContratado?: number;
  prazoOperacao?: number;
  prazoAntecipacao?: number;
  opcaoGarantia?: [GuaranteeOptions];
  dataContratacao?: Date;
}

export interface BndesConfiguration {
  contact?: { name: string; email: string; phone: string };
  situationUrl?: string;
  authenticationToken?: string;
  requestTokenUrl?: string;
  accessToken?: string;
  clientId?: string;
  clientSecret?: string;
  // scope: "am_application_scope default",
  // token_type: "Bearer",
  expiresTokenDate?: string;
  expiresTokenSeconds?: number;
}

export interface BndesTokenResponse {
  access_token: string;
  scope: string;
  token_type: string;
  expires_in: number;
}

export enum LoanPurpose {
  PROJETO_DE_INVESTIMENTO = 'Projeto de investimento',
  NECESSIDADES_DO_DIA_A_DIA = 'Crédito para as necessidades do dia a dia',
  ONIBUS = 'Aquisição de ônibus',
  CAMINHOES = 'Aquisição de caminhões',
  EQUIPAMENTOS = 'Aquisição de demais máquinas e equipamentos',
  TRATORES_COLHEITADEIRAS_E_PULVERIZADORES = 'Aquisição de tratores, colheitadeiras e pulverizadores',
  MICROCREDITO = 'Microcrédito (capital de giro até R$ 20 mil)',
  PAINEIS_SOLARES = 'Aquisição de painéis solares',
}

export enum OfferType {
  NAO_HA_OFERTA = 'N',
  OFERTA_GENERICA = 'G',
  OFERTA_INDIVIDUALIZADA = 'I',
}

export enum GuaranteesType {
  COM_GARANTIA_REAL = 'C',
  SEM_GARANTIA_REAL = 'S',
  ANTECIPACAO_DE_RECEBIVEIS = 'R',
}

export enum GuaranteeOptions {
  AVAL_FIANCA = 'A',
  IMOVEL = 'I',
  VEICULO = 'V',
  RECEBIVEIS = 'R',
  SEM_GARANTIA = 'S',
  OUTROS = 'O',
}

export enum ProposalSituation {
  RECEBIDA = 'RECEBIDA',
  EM_ANALISE = 'EM_ANALISE',
  CONTRATADA_COM_LINHAS_PROPRIAS = 'CONTRATADA_COM_LINHAS_PROPRIAS',
  RECUSADA = 'RECUSADA',
  CANCELADA = 'CANCELADA',
  EXPIRADA = 'EXPIRADA',
}

export enum ProposalMotivationSituation {
  NEGATIVA_CREDITO = 'NEGATIVA_CREDITO',
  FALTA_DOCUMENTACAO_OU_CADASTRO = 'FALTA_DOCUMENTACAO_OU_CADASTRO',
  GARANTIAS_INSUFICIENTES = 'GARANTIAS_INSUFICIENTES',
  OUTROS = 'OUTROS',
}

export enum BndesIntegrationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
