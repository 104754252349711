import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

// firebase
import { AngularFireModule } from '@angular/fire';
import { environment } from '../../environments/environment';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { DatePipe } from '@angular/common';
import { AuthService } from './auth/legacy-auth.service';
import { FinanciamentoHandler } from './handler/financiamento.handler';

@NgModule({
  imports: [
    HttpClientModule,

    AngularFireModule.initializeApp(environment.firebase),

    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
  ],
  providers: [AuthService, FinanciamentoHandler, DatePipe],
  declarations: [],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
    };
  }
}
