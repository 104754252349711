<div class="container">
    <mat-card class="custom-card">
        <mat-card-content>
            <mat-card-title>Alterar Onboardings existentes</mat-card-title>
        
                

                <div class="edit-form" style=" padding-right: 10px; color: #909090;" >
                    <input matInput placeholder="Buscar por nome" class="input"  [(ngModel)]="searchPartner"  (input)="onInputChange()" (keyup.enter)="applyFilter()" />
                    <mat-icon class="search-icon" (click)="applyFilter()">search</mat-icon>
                </div>
                

                <div class="table-container">
                    <table mat-table [dataSource]="dataSourcePartner" class="mat-elevation-z8" >
                      
                      <!-- Definição da coluna 'partner' -->
                      <ng-container matColumnDef="partner">
                        <th mat-header-cell *matHeaderCellDef > </th>
                        <td mat-cell *matCellDef="let partner" class="first-column">{{ partner.partner }}</td>
                      </ng-container>
                  
                      <!-- Definição da coluna 'searchIcon' -->
                      <ng-container matColumnDef="searchIcon">
                        <th mat-header-cell *matHeaderCellDef>  </th>
                        <td mat-cell *matCellDef="let partner" [routerLink]="'/admin/parceiro/editar/' + partner.partner">
                          <a mat-flat-button matTooltip="Editar" style="border: none;">
                            <img src="../../../assets/images/icons/pencil-writing.png" alt="">
                            
                          </a>
                        </td>
                      </ng-container>
                  
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns" 
                        ></tr>
                     
                    </table>
                </div>

                    <!-- <div class="cancel-span">
                      <span  (click)="clearFilter()">Cancelar</span>
                    </div> -->

        </mat-card-content>
    </mat-card>
</div>
