import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Customer } from '../../../../../functions/src/models/Customer';
import { Document, Document as DocumentMongoDB } from '../../../../../functions/src/models/documents/UserDocument';
import { UploadDialogComponent } from '../../../components/upload-dialog/upload-dialog.component';
import { ContractService } from '../../services/contract.service';
import { CustomerService } from '../../services/customer.service';
import { UserDocumentService } from '../../services/user-document.service';

@Component({
  selector: 'app-customer-documents-credit-dialog',
  templateUrl: './customer-documents-credit-dialog.component.html',
  styleUrls: ['./customer-documents-credit-dialog.component.scss'],
})
export class CustomerDocumentsCreditDialogComponent implements OnInit {
  contadorEmail = '';
  customer: Customer;
  documents: Document[] = [];
  documentRevenue?: Document;
  documentSelected: DocumentMongoDB;

  constructor(
    private dialogRef: MatDialogRef<CustomerDocumentsCreditDialogComponent>,
    private userDocumentService: UserDocumentService,
    private contractService: ContractService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { customer: Customer }
  ) {
    this.customer = data.customer;
    console.log(this.customer)
  }

  ngOnInit(): void {
    this.getDocuments();
  }

  getDocuments(): void {
    this.userDocumentService
      .getDocuments({ uid: this.customer.uid })
      .then(documents => {
        this.documents = documents;
        this.documentRevenue = documents.find(doc => doc.mnemonic === 'faturamento_ultimos_36_meses');
        console.log(this.documentRevenue)
        console.log(this.documents)
      })
      .catch(error => {
        console.error('Erro ao carregar documentos:', error);
      });
  }

  uploadDocument(): void {
    console.log('Carregar documento de faturamento acionado');

    const confirmUpload = this.dialog
      .open(UploadDialogComponent, {
        data: {
          item: this.documentRevenue,
          exerciseYear: '',
          customer: this.customer,
          dataUserDocuments: this.documents,
          hasEmissionDate: false,
        },
      })
      .afterClosed()
      .subscribe((doc) => {
        let document = this.documents.find((d) => d.typeId === doc.typeId);
        document = doc;
        if (confirmUpload) {
          confirmUpload.unsubscribe();
        }
      });

    this.documentSelected = this.documentRevenue;

  }

  closeDialog(response: boolean | string): void {
    this.dialogRef.close(response);
  }

  continue(): void {
    const accountantData: Customer['accountantData'] = {
      email: (this.contadorEmail || '').trim(),
      phone: ''

    };

    if (this.contadorEmail) {
      this.customerService
        .updateCustomerOnFirestore(this.customer.uid, { accountantData })
        .then(() => {
          try {
            this.contractService.sendEmailToAccountant(accountantData.email, this.customer.companyName, this.customer.email.toLowerCase(),);

          } catch (err) {
            console.error('Error notifying accountant', err);
          }

        })

    }
    this.dialogRef.close(true);
  }
}
