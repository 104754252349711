<div
  class="content"
  [class.d-flex]="isLoading"
  [class.flex-column]="isLoading"
  [style.min-height]="isLoading ? '100%' : 'unset'"
>
  <div class="d-flex">
    <div style="flex: 1">

    </div>
    <button mat-button class="d-flex align-items-center flex-row" (click)="logout()">
      <mat-icon fontSet="fas" fontIcon="fa-sign-out-alt" class="colored-icon mr-2"></mat-icon> Sair
    </button>
  </div>

  <section class="d-flex align-items-center justify-content-center" style="flex: 1" *ngIf="isLoading">
    <mat-spinner [diameter]="80"></mat-spinner>
  </section>

  <section *ngIf="!isLoading && step === 'opportunities'">
    <div>
      <span class="step-label">ETAPA 2 de 3: Sobre você</span>
      <h1>E-mail confirmado!</h1>
      <p>Seu cadastro está quase pronto! Finalize-o para ter acesso a todas as oportunidades disponíveis.</p>
    </div>

    <div class="colored-box">
      <h1 class="header">Confira empréstimos disponíveis!</h1>
      <p>Sujeitos a verificação documental e análise de crédito da instituição financeira.</p>

        <div class="box-content">
          <div>

            <div class="content-custom-wrapper">
              <div class="custom-box" *ngFor="let opp of oportunidades; let i = index">
                <div class="icon">
                  {{ i + 1 }}
                </div>

                <div class="content-custom">
                  <span class="title">Oportunidade {{ i + 1 }}</span>
                  <div class="subtitles">
                    <div class="subtitle">
                      <img src="../../../assets/images/icons/calendar.png"   alt="Calendar Icon">
                      <span class="text-custom"><b> Prazo:</b> Até {{ opp.term }}</span>
                    </div>
                    <div class="subtitle">
                      <span class="icon-text-wrapper">
                        <span class="text-wrapper">
                          <img src="../../../assets/images/icons/shield-check.png" alt="Check Icon">
                          <span class="text"><b> Garantias:</b>  {{ generateGuaranteesString(opp.guarantees) }}</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      <p class="mt-4 mb-2 " style="font-weight: 600; margin-left: 31px;">Finalize seu cadastro para ver todas as opções disponíveis</p>
    </div>

    <button mat-raised-button color="primary" class="action-button" type="button" (click)="nextStep()">Próximo</button>
  </section>

  <section *ngIf="!isLoading && step === 'terms'">
    <div>
      <span class="step-label">ETAPA 3 de 3: Termos e condições</span>
      <h1>Termos de Uso</h1>
      <p>Estamos quase lá! </p>
      <p style="margin-top: -22px;">Leia com atenção os <span class="link" (click)="openDialogTerms()">Termos de Uso</span>. </p>

      <p><b style="color: #666666;">Importante:</b>  a nossa <span class="link" (click)="openModalTac()">Taxa de Abertura de Crédito (TAC)</span> só será cobrada SE e QUANDO você decidir contratar crédito com um de nossos parceiros.
       <b style="color: #008348;"> Não cobramos mensalidade e caso não finalize nenhuma contratação, não haverá qualquer tipo de cobrança.</b></p>

    </div>

    <div class="colored-box">
      <h1 class="header">Simulação da taxa de abertura de crédito</h1>

      <p style="font-size: 13px;">Simule qual seria o impacto mensal da nossa TAC em cada parcela do seu crédito ideal.</p>

      <div style="overflow-x: auto; overflow-y: hidden">
        <div class="d-flex flex-column flex-md-row align-items-center">
          <div class="mt-2 mx-0 d-flex" style="flex: 1; width: 100%;">
            <mat-form-field color="primary">
              <mat-label>Valor da operação</mat-label>
              <input
                matInput
                placeholder="Valor da operação"
                name="opp-value"
                [(ngModel)]="simulationValue"
                [textMask]="{ mask: numberMask }"
              />
            </mat-form-field>
          </div>
          <div class="mt-2 mx-0 d-flex" style="flex: 1; width: 100%;">
            <mat-form-field color="primary">
              <mat-label>Prazo</mat-label>
              <mat-select placeholder="Prazo" [(ngModel)]="simulationTerm" name="opp-term">
                <mat-option *ngFor="let term of termOptions" [value]="term">
                  {{ term }} {{ term > 1 ? 'meses' : 'mês' }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div>
            <p class="my-2">
              Nossa taxa de abertura de crédito será de {{ 100 * calculateMontlyFee() }}% a.m.
              <!-- ou <strong>{{ calculateMontlyValue() }}</strong> -->
            </p>
          </div>
          <div>
            <button mat-icon-button color="accent" (click)="openModalTac()">
              <mat-icon fontSet="fas" fontIcon="fa-info-circle"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <ng-template #modalTemplate>
        <div class="custom-modal">
          <button class="close-button" (click)="closeModal()">&#10005;</button>

          <h1 style="font-size: 27px;"><b> Simulação da Taxa de Abertura de Crédito (TAC) </b></h1>
          <div class="colored-box-tac">
          <div >
            <p><b>Cálculo da TAC</b></p>
            <p><b> Valor solicitado:</b> {{valueStr | currency:'BRL':'symbol':'1.2-2' }} </p>
            <p><b> TAC:</b> {{calcValue}} </p>
          </div>
          </div>

          <p>Nossa TAC (<b> 4,5% </b> sobre o valor contratado com o parceiro) reflete os custos e esforços que temos para conectar sua empresa
            a inúmeros parceiros financeiros que oferecem crédito alinhado ao seu perfil, além de oferecer suporte durante todas as operações,
             incluindo defesas de crédito e negociações.</p>
          <p>A cobrança ocorre apenas após a confirmação do recebimento do crédito.</p>
          <p>Veja mais detalhes nos <span class="link" (click)="openDialogTerms()">Termos de Uso</span>. </p>

          <div class="btn">
            <button class="custom-button" (click)="closeModal()">Fechar</button>
           </div>
        </div>
    </ng-template>

    <div class="col-12 col-lg-9 mt-3 mb-4 d-flex">
      <mat-checkbox color="primary" class="mr-3 my-1 checkbox" [(ngModel)]="agreesTerms" required></mat-checkbox>
      <span style="flex: 1"
        >Eu li e concordo com os <span class="link" (click)="openDialogTerms()">termos e condições</span>.</span
      >
    </div>

    <div class="button-container mb-4">
      <button mat-stroked-button class="action-button text-black autosized" type="button" (click)="previousStep()">
        Voltar
      </button>
      <button
        mat-raised-button
        color="primary"
        class="action-button"
        type="button"
        (click)="acceptTerms()"
        [class.disabled]="!agreesTerms"
        [disabled]="!agreesTerms"
      >
        Finalizar Cadastro
      </button>
    </div>
  </section>

  <section *ngIf="!isLoading && step === 'processing'">
    <div>
      <span class="step-label">TUDO PRONTO</span>
      <h1>Cadastro concluído!</h1>
      <p>
        Estamos terminando de configurar seu painel. Em alguns instantes você acessar nossa plataforma e conferir todos
        os empréstimos disponíveis para a sua empresa!
      </p>
      <!-- <p>
        Enquanto isso, confira abaixo todos os serviços oferecidos pela Capital Empreendedor!
      </p> -->

      <div class="button-container mb-4">
        <a
          mat-raised-button
          color="primary"
          class="action-button autosized px-3"
          *ngIf="!processingError"
          type="button"
          [routerLink]="'/documentacao'"
          [disabled]="processingSignature"
          [class.disabled]="processingSignature"
        >
          <mat-spinner [diameter]="20" *ngIf="processingSignature" class="d-inline-block mr-2"></mat-spinner>
          <span *ngIf="processingSignature" class="d-inline-block">Configurando o painel</span>

          <mat-icon
            fontSet="fas"
            fontIcon="fa-check-circle"
            class="d-inline-flex align-items-center justify-content-center mr-2"
            *ngIf="!processingSignature"
          ></mat-icon>
          <span *ngIf="!processingSignature" class="d-inline-block">Ir para meu painel</span>
        </a>

        <button
          mat-raised-button
          color="primary"
          class="action-button autosized px-3"
          *ngIf="processingError"
          type="button"
          (click)="acceptTerms()"
        >
          <mat-icon
            fontSet="fas"
            fontIcon="fa-check-circle"
            class="d-inline-flex align-items-center justify-content-center mr-2"
          ></mat-icon>
          <span class="d-inline-block">Tentar novamente</span>
        </button>
      </div>
    </div>
  </section>
</div>
