<div appAccordion class="snav">
  <div appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" group="{{ menuitem.state }}">
    <a appAccordionToggle [routerLink]="['/admin/', menuitem.state]" *ngIf="menuitem.type === 'link'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span class="text">{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>
    <a appAccordionToggle [routerLink]="['/admin/', menuitem.state]" *ngIf="menuitem.type === 'chat' && user && user.isAdmin && user.active && user.sector === 'operation'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span class="text">{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>
    <a appAccordionToggle href="{{ menuitem.state }}" *ngIf="menuitem.type === 'extLink'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span class="text">{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>
    <a appAccordionToggle href="{{ menuitem.state }}" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span class="text">{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>
    <a class="sub" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span class="text">{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
      <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
    </a>
    <div class="sub-item" *ngIf="menuitem.type === 'sub'">
      <div *ngFor="let childitem of menuitem.children" routerLinkActive="selected">
        <a
          [routerLink]="['/admin/', menuitem.state, childitem.state]"
          *ngIf="childitem.type === 'link'"
          class="relative"
          routerLinkActive="selected"
        >
          <mat-icon class="sub-icon">{{ childitem.icon }}</mat-icon>
          <span class="text">{{ childitem.name }}</span>
          <span fxFlex></span>
        </a>
      </div>
      <div *ngFor="let childitem of menuitem.children">
        <a
          class=""
          href="javascript: void(0);"
          *ngIf="childitem.type === 'subchild'"
          (click)="clickEvent()"
          [ngClass]="status ? 'selected' : ''"
        >
          <span class="text">{{ childitem.name }}</span>
          <span fxFlex></span>
          <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <div class="child-sub-item" *ngIf="childitem.type === 'subchild'">
          <div *ngFor="let child of childitem.subchildren" routerLinkActive="selected">
            <a
              [routerLink]="['/admin/', menuitem.state, childitem.state, child.state]"
              routerLinkActive="selected"
              class="text"
              >{{ child.name }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="separator text-muted" *ngIf="menuitem.type === 'separator'">
      <span class="text">{{ menuitem.name }}</span>
    </div>
  </div>
</div>
