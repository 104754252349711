import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}
export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const MENUITEMS = [
  { state: 'dashboard', type: 'link', name: 'Dashboard', icon: 'pie_chart' },
  { state: 'chat-sce', type: 'chat', name: 'Chat SCE', icon: 'message' },
  {
    state: 'clientes',
    type: 'sub',
    name: 'Clientes',
    icon: 'account_circle',
    children: [
      { state: 'buscar', name: 'Consulta de clientes', type: 'link', icon: 'search' },
      { state: 'oportunidades', name: 'Consulta de oportunidades', type: 'link', icon: 'search' },
      { state: 'cadastrar', name: 'Cadastrar cliente', type: 'link', icon: 'add' },
      { state: 'relatorios', name: 'Relatórios', type: 'link', icon: 'list' },
    ],
  },
  {
    state: 'assessores',
    type: 'sub',
    name: 'Assessores de Crédito',
    icon: 'how_to_reg',
    children: [
      { state: 'buscar', name: 'Consulta de Assessores', type: 'link', icon: 'search' },
      { state: 'cadastrar', name: 'Cadastrar Assessor', type: 'link', icon: 'add' },
      { state: 'relatorios', name: 'Relatórios', type: 'link', icon: 'description' },
    ],
  },
  {
    state: 'operadores',
    type: 'sub',
    name: 'Operadores de Crédito',
    icon: 'account_balance_wallet',
    children: [
      { state: 'buscar', name: 'Consulta de Operadores', type: 'link', icon: 'search' },
      { state: 'cadastrar', name: 'Cadastrar Operador', type: 'link', icon: 'add' },
      { state: 'relatorios', name: 'Relatórios', type: 'link', icon: 'description' },
    ],
  },
  { state: 'contadores', type: 'link', name: 'Contadores', icon: 'contact_mail' },
  { state: 'documentos', name: 'Tipos de Documentos', type: 'link', icon: 'description' },
  {
    state: 'instituicoes',
    type: 'sub',
    name: 'Instituições Financeiras',
    icon: 'account_balance',
    children: [
      { state: 'triagem-clientes', name: 'Triagem de clientes', type: 'link', icon: 'settings' },
      { state: 'instituicoes', name: 'Cadastrar/Alterar', type: 'link', icon: 'create' },
      { state: 'grupos', name: 'Grupos Instituições Financeiras', type: 'link', icon: 'group_work' },
    ],
  },
  { state: 'cadastros-gerais', type: 'link', name: 'Cadastros Auxiliares', icon: 'post_add' },

 { state: 'parceiro',
    type: 'sub',
    name: 'Configurar parceiro',
    icon: 'list_alt',
    children: [
      { state: 'cadastrar', name: 'Cadastrar', type: 'link', icon: 'add' },
      { state: 'visualizar', name: 'Visualizar', type: 'link', icon: 'search' },
    ],
  },
  { state: 'consulta-bigdatacorp', type: 'link', name: 'Consulta BigDataCorp', icon: 'search' },
  {
    state: 'configuracoes',
    type: 'sub',
    name: 'Configurações',
    icon: 'settings',
    children: [{ state: 'backups', name: 'Backups', type: 'link', icon: 'backup' }],
  },
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
