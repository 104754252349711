import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firestore } from 'firebase';
import { Customer } from '../../../../../functions/src/models/Customer';
import { CustomerService } from '../../services/customer.service';
import { WhatsappDialogComponent } from '../whatsapp-dialog/whatsapp-dialog.component';

@Component({
  selector: 'app-aside-chat-faq',
  templateUrl: './aside-chat-faq.component.html',
  styleUrls: ['./aside-chat-faq.component.scss']
})
export class AsideChatFaqComponent {
  currentLayer: string;
  isModalVisible?: boolean = false;
  isWhatsApp: boolean;
  customerData: Customer;
  isMobile: boolean;
  @Output() openChat = new EventEmitter<void>();

  constructor(private dialog: MatDialog, private customerService: CustomerService) {
    this.checkScreenSize();
   }

  setLayer(layer: string): void {
    this.currentLayer = layer;
  }

  updateCustomer(event) {
    const dateNow = firestore.Timestamp.now();
    this.isWhatsApp = event.checked;

    const whatsAppContact: Customer['whatsAppContact'] = {
      date: dateNow,
      value: this.isWhatsApp,
    };
    this.customerService.updateCustomerOnFirestore(this.customerData.uid, { whatsAppContact });
  }

  openChatAndCloseFaq() {
    this.openChat.emit();
    this.currentLayer = '';
  }

  openWhatsappModal($event): void {
    this.dialog.open(WhatsappDialogComponent, {
      maxWidth: '480px',
      panelClass: 'whatsapp-dialog-customized'
    });
    this.updateCustomer($event);
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 768;
    if(this.isMobile){
      this.currentLayer = 'second';
    } else{
      this.currentLayer = 'first';
    }
  }

}
