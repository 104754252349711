import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Contract } from 'functions/src/models/Contract';
import { Customer } from 'functions/src/models/Customer';
import { User } from 'functions/src/models/User';
import { Opportunity } from 'functions/src/models/opportunity/Opportunity';
import { OpportunityHundredInterface } from 'functions/src/models/opportunity/OpportunityHundred';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import _moment from 'moment';
import { Subscription } from 'rxjs';
import { AsaasConfiguration } from '../../../../functions/src/models/Asaas';
import {
  Document as DocumentMongoDB,
  UserDocuments
} from '../../../../functions/src/models/documents/UserDocument';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import { AsaasService } from '../services/asaas.service';
import { OpportunityManagementService } from '../services/opportunity-management.service';
import { UserManagementService } from '../services/user-management.service';
import { InstituicaoFinanceira, TipoDocumento } from './../../../../functions/src/models/model-interface';
import { timestampForDate } from './../../../../functions/src/utils/dates';


_moment.locale('pt-BR');
export const MY_FORMATS = {
  parse: {
    dateInput: 'LLL',
  },
  display: {
    dateInput: 'LLL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LLL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-user-charge',
  templateUrl: './user-charge.component.html',
  styleUrls: ['./user-charge.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class UserChargeComponent implements OnInit {
  @Input() customer: Customer;
  @Input() loggedUser: User;
  @Input() opportunities: Opportunity[];
  asaasPayments: any;
  charge: any;
  uniqueInstitutions: any[];


  constructor(
    private ums: UserManagementService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private oppService: OpportunityManagementService,
    private asaasService: AsaasService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,

  ) {
    this.datePipe = new DatePipe('pt-BR');
    this.documents = [];
    this.tituloDetalheUsuario = '-';
    this.customerUID = this.activatedRoute.snapshot.params.uid;
    this.checkChargeLink = false;

    if (!this.customerUID) {
      this.router.navigate(['admin/clientes/buscar']);
      console.error('Customer without uid');
    }

  }

  institutions: InstituicaoFinanceira[];
  linesCreditActive: Opportunity[];
  configSubscription: Subscription;
  tipeDocuments: TipoDocumento[];
  documents: DocumentMongoDB[];
  contract: Contract;
  ip?: string;
  userDataSubscription: Subscription;
  userContractSubscription: Subscription;
  userDocumentsSubscription: Subscription;
  documentosAtivosSubscription: Subscription;
  linhasCreditoAtivasSubscription: Subscription;
  dataUserDocuments: UserDocuments;
  tituloDetalheUsuario: string;
  tituloDocumento: string;
  editFormGroup: FormGroup;
  datePipe: DatePipe;
  agreedTerms = false;
  allUsersDocuments: UserDocuments;
  customerUID: string;
  html: '';
  deadlineForMonth: Opportunity;
  rate: Opportunity;
  limitOperation: Opportunity;
  runOpportunities = false;
  expandedElement: Opportunity;
  expandedElementId: string;
  selectedValueStage: any;
  setOpp: any;
  opportunityHundred: OpportunityHundredInterface[];
  createChargeFormGroup: FormGroup;
  customerId: string;
  dueDate: Date;
  value: number;
  description: string;
  checkChargeLink = false;
  checkNegativity = false;
  selectedOpportunityHundred = new FormControl;
  selectedCharge = new FormControl;
  isProcessing: boolean;
  logger: any;
  sendChargeFormGroup: FormGroup;
  file: string;
  isLoading = false;
  fineValue: number;
  interestValue: number;
  emailForm: FormGroup;



  async ngOnInit(): Promise<void> {
    this.tituloDetalheUsuario = ' - ' + this.customer.name.toUpperCase();
    this.userContractSubscription = this.ums.getDadosContratoUsuario(this.customer.uid).subscribe((contract) => {
      console.log(`Dados contrato subscribe ${ new Date().toISOString() }`);
    });

    this.getOppHundred();
    this.getCharges();

    this.createChargeFormGroup = this.formBuilder.group({
      dueDateCtrl: new FormControl('', Validators.required),
      valueCtrl: new FormControl('', Validators.required),
      descriptionCtrl: new FormControl('', Validators.required),
      interestCtrl: new FormControl('', Validators.required, ),
      fineCtrl: new FormControl('', Validators.required),
    });

    this.asaasService.getInterestAndFine().subscribe((documento: AsaasConfiguration) => {
      this.fineValue = documento.fineValue;
      this.interestValue = documento.interestValue;

    });
    this.emailForm = new FormGroup({
      ccOne: new FormControl(''),
      ccTwo: new FormControl(''),
    });

    if (!this.customer.asaasPaymentData && this.customer.asaasPaymentData?.invoiceUrl === null) {
      this.checkChargeLink = false;
    } else {
      this.checkChargeLink = true;
    }

    if (!this.customer.asaasPaymentData && this.customer.asaasPaymentData?.invoiceUrl === null) {
      this.checkNegativity = false;
    } else {
      this.checkNegativity = true;
    }


  }

  getOpportunityHundred() {
    this.setOpp = this.selectedOpportunityHundred.value;
  }


  async getOppHundred() {
    await this.oppService.getOpportunitiesHundred(this.customer.uid)
      .then((result) => {
        this.opportunityHundred = result.map((res) => ({

          institutionName: res.institutionName,
          limitOperation: res.limitOperation,
          rate: res.rate,
          modalityStr: res.modalityStr,
          deadlineForMonth: res.deadlineForMonth,

        })) as OpportunityHundredInterface[];
      });
  }

  getCharges(): void {
    const customerId = this.customer.uid;
    this.asaasService.getAsaasPaymentsByCustomerId(customerId).subscribe((asaasPayments) => {
      console.log('Boletos para o cliente:', asaasPayments);
      this.asaasPayments = asaasPayments;
      this.uniqueInstitutions = [...new Set(asaasPayments.map(payment => payment.institutionName))];
    });
  }
  
  formatDate(date: any): string {
    if (date) {
      // Converta o timestamp para uma data no fuso horário UTC
      const utcDate = new Date(timestampForDate(date));
    
      // Adicione um dia à data
      utcDate.setDate(utcDate.getDate() + 1);
    
      // Crie um objeto Intl.DateTimeFormat para o fuso horário 'America/Sao_Paulo'
      const saoPauloFormatter = new Intl.DateTimeFormat('pt-BR', { timeZone: 'America/Sao_Paulo' });
    
      // Formate a data para string
      return saoPauloFormatter.format(utcDate);
    } else {
      return '';
    }
  }
  
  displayStatus(status: string): { label: string; colorClass: string } {
    switch (status) {
      case 'PENDING':
        return { label: 'Pendente', colorClass: 'pending-color' };
      case 'RECEIVED':
        return { label: 'Pago', colorClass: 'received-color' };
      case 'OVERDUE':
        return { label: 'Atrasado', colorClass: 'overdue-color' };
      default:
        return { label: status, colorClass: 'default-color' };
    }
  }
  

  selectCharge() {
    this.charge = this.selectedCharge.value;
    console.log(this.charge)
  }

  gerarPdf() {
    const doc = new jsPDF();
    this.isLoading = true;

    const address =
      [' Rua: ' + this.customer.address?.street, ' Numero: ' + this.customer.address?.number,
      ' Bairro: ' + this.customer.address?.neighborhood, ' Cidade: ' + this.customer.address?.city,
      ' Estado: ' + this.customer.address?.state];
    const date = (timestampForDate(this.customer.termsSignatureDate));
    const dateString = 'Data: ' + date;
    const logo = 'assets/images/logo-pdf.png';
    const rate = this.setOpp?.rate  === null ? '' :  this.setOpp?.rate + '%';
    const term = this.setOpp?.deadlineForMonth  === null ? '' : this.setOpp?.deadlineForMonth + ' meses';
    const valueLimitOperation = this.setOpp?.limitOperation.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    const charge = this.customer.asaasPaymentData?.invoiceUrl || '';


    doc.addImage(logo, 'PNG', 60, 10, 100, 20);
    autoTable(doc, {
      margin: { top: 40 },
      body: [[{
        content: 'Abaixo, as informações do crédito obtido pela sua empresa através da Capital Empreendedor',
        styles: {
          halign: 'center',
          fontSize: 18,
          fontStyle: 'bold',
        }
      }]],
      theme: 'plain'
    });

    autoTable(doc, {
      body: [[{
        content: 'Crédito tomado.',
        styles: {
          halign: 'left',
          fontSize: 14
        }
      }]],
      theme: 'plain'
    });


    autoTable(doc, {
      body: [
        ['Instituição', this.setOpp.institutionName],
        ['Valor', valueLimitOperation],
        ['Juros', rate],
        ['Modalidade', this.setOpp.modalityStr],
        ['Prazo', term],
      ],
      theme: 'grid'
    });



    autoTable(doc, {
      body: [[{
        content: 'Dados da empresa.',
        styles: {
          halign: 'left',
          fontSize: 14
        }
      }]],
      theme: 'plain'
    });

    autoTable(doc, {
      body: [
        ['Nome', this.customer.companyName],
        ['CNPJ', this.customer.cnpj],
        ['Endereço', address],
      ],
      theme: 'grid'
    });
    autoTable(doc, {
      body: [[{
        content: 'Dados do responsável pela empresa e usuário do sistema.',
        styles: {
          halign: 'left',
          fontSize: 14
        }
      }]],
      theme: 'plain'
    });

    autoTable(doc, {
      body: [
        ['Nome', this.customer.name],
        ['E-mail', this.customer.email],
        ['CPF', this.customer.cpf],
        ['WhatsApp', this.customer.whatsapp],
      ],
      theme: 'grid'
    });

    autoTable(doc, {
      body: [[{
        content: 'Relação com Capital Empreendedor: termo validado pelo usuário acima no sistema.',
        styles: {
          halign: 'left',
          fontSize: 14
        }
      }]],
      theme: 'plain'
    });

    autoTable(doc, {
      body: [
        ['Boleto para pagamento', { content: charge, styles: { textColor: [0, 0, 255] } }],
        ['Link do termo', { content: 'https://painel.capitalempreendedor.com.br/meu-termo', styles: { textColor: [0, 0, 255] } }],
        ['Termo aceito', dateString],
        ['Ip da validação', this.customer.ip],
      ],
      theme: 'grid',

    });



    this.tituloDocumento = this.customer.name.toLowerCase();
    doc.save(this.tituloDocumento + '.pdf');
    this.isLoading = false;
  }

  negativityCharge(asaasPaymentId: string) {
    this.isLoading = true;
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      disableClose: true,
      data: {
        alertTitle: 'Deseja realmente negativar o boleto?',
        alertDescription: 'Verifique a data de vencimento antes de negativar o boleto.',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Resultado do dialog:', result);
      // Verifique se o usuário confirmou a ação
      if (result === true) {
        // Chame o serviço aqui
        this.asaasService.negativityChargeAsaas(
          asaasPaymentId,
          this.customer.companyName,
          this.customer.cnpj,
          this.customer.whatsapp,
          this.customer.address?.zipCode,
          this.customer.address?.street,
          this.customer.address?.number,
          this.customer.address?.neighborhood,
        ).then(() => {
          console.log('sucesso')
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Boleto Negativado',
              alertDescription: `Boleto Negativado.`,
              isOnlyConfirm: true,
            },
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.checkChargeLink = false;
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro ao negativar boleto',
              alertDescription: `Ocorreu um erro ao negativar o boleto: ${err}.`,
              isOnlyConfirm: true,
            },
          });
          this.isLoading = false;
        });
      }
      this.isLoading = false;
    });
    
  }



  cancelCharge(asaasPaymentId: string) {
    this.isLoading = true;
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      disableClose: true,
      data: {
        alertTitle: 'Deseja realmente cancelar o boleto?',
        alertDescription: 'Verifique a data de vencimento antes de cancelar o boleto.',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Resultado do dialog:', result);
      // Verifique se o usuário confirmou a ação
      if (result === true) {
        // Chame o serviço aqui
        this.asaasService.cancelChargeAsaas(asaasPaymentId).then(() => {
          console.log('sucesso')
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Boleto cancelado',
              alertDescription: `Boleto cancelado.`,
              isOnlyConfirm: true,
            },
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.checkChargeLink = false;
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro ao cancelar boleto',
              alertDescription: `Ocorreu um erro ao cancelar o boleto: ${err}.`,
              isOnlyConfirm: true,
            },
          });
          this.isLoading = false;
        });
      }
      this.isLoading = false;
    });
  }

  createCharge() {
    this.isLoading = true;
    if (this.createChargeFormGroup.valid) {
      this.asaasService.createChargeAsaas(
        this.customerId = this.customer.uid,
        this.dueDate = this.createChargeFormGroup.get('dueDateCtrl').value,
        this.value = this.createChargeFormGroup.get('valueCtrl').value,
        this.description = this.createChargeFormGroup.get('descriptionCtrl').value,

        this.fineValue = this.createChargeFormGroup.get('fineCtrl').value,
        this.interestValue = this.createChargeFormGroup.get('interestCtrl').value,
        this.setOpp.institutionName

      ).then(() => {
        this.checkChargeLink = true;
        this.isLoading = false;
      })
      .catch((err) => {
        this.checkChargeLink = false;
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao gerar boleto',
            alertDescription: `Ocorreu um erro ao gerar o boleto: ${err}.`,
            isOnlyConfirm: true,
          },
        });
        this.isLoading = false;
      });
      this.isLoading = false;
    }
    
    this.createChargeFormGroup.reset();
  }


  async setValueDoc(event): Promise<void> {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      this.file = reader.result.toString().split(',')[1];
      const ccOne = this.emailForm.get('ccOne').value;
      const ccTwo = this.emailForm.get('ccTwo').value;
      await this.sendEmailCharge(this.file, ccOne || null, ccTwo || null);
      console.log('passou aqui 1');
    };
  }


  // Função para enviar os dados para o service
  async sendEmailCharge(file: string, ccOne?: string, ccTwo?: string): Promise<void> {
    this.isLoading = true;
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '700px',
        data: {
          alertTitle: 'Enviar cobrança?',
          alertDescription:
            `Essa ação envia um e-mail de cobrança para o cliente: ${ this.customer.name }.` +
            `<br>Deseja realmente enviar o e-mail ao cliente ?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.ums.sendEmailCharge(file, {
            name: this.customer.name,
            email: this.customer.email,
            ccOne: ccOne,
            ccTwo: ccTwo,
            chargeLink: this.customer.asaasPaymentData.invoiceUrl,
            companyName: this.customer.companyName
          })
            .then(() => {
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Sucesso',
                  alertDescription: `Envio realizado com sucesso.`,
                  isOnlyConfirm: true,

                },
              });
              this.isLoading = false;
              this.emailForm.reset();
            })
            .catch((error) => {
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro',
                  alertDescription: `Houve um problema ao tentar enviar o email.`,
                  isOnlyConfirm: true,
                },
              });
              this.logger.error('Houve um problema ao tentar enviar o email', error);
              this.isLoading = true;
            });
        }
      });
      this.isLoading = false;
  }

}



