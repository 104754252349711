import { Routes } from '@angular/router';
import { AuthGuardAgents } from '../core/auth/auth.guard';
import { PrivacyPolicyComponent } from '../customer/containers/privacy-policy/privacy-policy.component';
import { AgentCustomerRegisterComponent } from './containers/agent-customer-register/agent-customer-register.component';
import { AgentDashboardComponent } from './containers/dashboard/dashboard.component';
import { AgentInviteComponent } from './containers/invite/invite.component';
import { AgentLeadComponent } from './containers/lead/lead.component';
import { AgentLeadsComponent } from './containers/leads/leads.component';
import { PendingDocumentsComponent } from './containers/pending-documents/pending-documents.component';
import { AgentProfileComponent } from './containers/profile/profile.component';

export const AgentsRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'signup',
  },
  {
    path: 'login',
    redirectTo: '/entrar',
  },
  {
    path: 'profile',
    component: AgentProfileComponent,
    data: { title: 'Assessores - Perfil' },
    canActivate: [AuthGuardAgents],
  },
  {
    path: 'dashboard',
    component: AgentDashboardComponent,
    data: { title: 'Assessores - Dashboard' },
    canActivate: [AuthGuardAgents],
  },
  {
    path: 'create-customer',
    component: AgentCustomerRegisterComponent,
    canActivate: [AuthGuardAgents],
  },
  {
    path: 'leads',
    component: AgentLeadsComponent,
    data: { title: 'Assessores - Leads' },
    canActivate: [AuthGuardAgents],
  },
  {
    path: 'lead/:id',
    component: AgentLeadComponent,
    data: { title: 'Assessores - Lead' },
    canActivate: [AuthGuardAgents],
  },
  {
    path: 'invite',
    component: AgentInviteComponent,
    data: { title: 'Assessores - Indique' },
    canActivate: [AuthGuardAgents],
  },
  {
    path: 'pending-documents',
    component: PendingDocumentsComponent,
    data: { title: 'Assessores - Documentos Pendentes' },
    canActivate: [AuthGuardAgents],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: { title: 'Aceitar Política de Privacidade' },
    canActivate: [AuthGuardAgents],
  },
  {
    path: '**',
    redirectTo: '/entrar',
  },
];
