<div class="content">
  <section *ngIf="step === 'company'">
    <div>
      <span class="step-label">ETAPA 1 de 3: Sobre a empresa</span>
      <h1>Informações básicas</h1>
      <p>Para encontrar as melhores opões de financiamento, precisamos de algumas informações:</p>
    </div>
    <form name="companyForm" [formGroup]="companyDataFormGroup">
      <div class="form row">
        <div class="field col-12 col-lg-6">
          <mat-icon fontSet="fas" fontIcon="fa-building" class="colored-icon"></mat-icon>

          <div class="" style="width: 100%">
            <mat-form-field color="primary">
              <mat-label>CNPJ</mat-label>
              <input matInput type="text" name="CNPJ" placeholder="CNPJ" formControlName="cnpjCtrl"
                [textMask]="{ mask: MASKS.cnpj.textMask }" required [disabled]="runReceitaWs" />
              <mat-error *ngIf="companyDataFormGroup.get('cnpjCtrl')?.hasError('required')">
                O CNPJ é obrigatório
              </mat-error>
              <mat-error *ngIf="companyDataFormGroup.get('cnpjCtrl')?.hasError('cnpj')"> CNPJ inválido </mat-error>
            </mat-form-field>
            <mat-spinner style="align-items: center;" *ngIf="runReceitaWs" color="primary" diameter="20"></mat-spinner>
          </div>

        </div>

        <div class="field col-12 col-lg-6">
          <mat-icon fontSet="fas" fontIcon="fa-building" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Razão Social</mat-label>
            <input matInput type="text" matTooltip="Nome da sua empresa." name="companyName" placeholder="Razão Social"
              formControlName="companyNameCtrl" required />
            <mat-error *ngIf="companyDataFormGroup.get('companyNameCtrl')?.hasError('required')">
              A Razão Social é obrigatória
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field d-flex align-items-center col-12 col-lg-6">
          <mat-icon fontSet="fas" fontIcon="fa-envelope" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>E-mail</mat-label>
            <input matInput placeholder="E-mail" name="email" required formControlName="emailCtrl" oncopy="return false"
              oncut="return false" />
            <mat-error *ngIf="companyDataFormGroup.get('emailCtrl')?.hasError('required')">O e-mail é
              obrigatório</mat-error>
            <mat-error *ngIf="companyDataFormGroup.get('emailCtrl')?.hasError('email')">E-mail inválido</mat-error>
          </mat-form-field>
        </div>

        <div class="field d-flex align-items-center col-12 col-lg-6">
          <mat-icon fontSet="fas" fontIcon="fa-envelope" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Confirme seu e-mail</mat-label>
            <input matInput placeholder="Confirme seu e-mail" name="email-match" required
              formControlName="emailMatchCtrl" onpaste="return false" />
            <mat-error *ngIf="companyDataFormGroup.get('emailMatchCtrl')?.hasError('required')">Confirmação
              obrigatória</mat-error>
            <mat-error *ngIf="companyDataFormGroup.get('emailMatchCtrl')?.hasError('email')">E-mail inválido</mat-error>
            <mat-error *ngIf="companyDataFormGroup.get('emailMatchCtrl')?.hasError('mismatch')">Os e-mails devem ser
              iguais</mat-error>
          </mat-form-field>
        </div>

        <div class="field col-12 col-lg-6">
          <mat-icon fontSet="fab" fontIcon="fa-whatsapp" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>WhatsApp</mat-label>
            <input matInput placeholder="WhatsApp" name="whatsapp" required formControlName="whatsappCtrl"
              [textMask]="{ mask: formatPhone, guide: false }" />
            <mat-error *ngIf="companyDataFormGroup.get('whatsappCtrl')?.hasError('required')">O WhatsApp é
              obrigatório</mat-error>
            <mat-error *ngIf="
                companyDataFormGroup.get('whatsappCtrl')?.hasError('telefone') ||
                companyDataFormGroup.get('whatsappCtrl')?.hasError('minlength')
              ">WhatsApp inválido</mat-error>
          </mat-form-field>
        </div>

        <div class="field col-12 col-lg-6">
          <mat-icon fontSet="fas" fontIcon="fa-wallet" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Faturamento Anual</mat-label>
            <input matInput placeholder="Faturamento Anual" name="revenue" matTooltip="Soma do faturamento dos últimos 12 meses “não colocar os centavos”
              Exemplo - 120.000,00 = 120.000." required formControlName="revenueCtrl" [textMask]="{ mask: numberMask }"
              (change)="revenueValidation()" />
            <mat-hint>Desconsidere os centavos</mat-hint>
            <mat-error *ngIf="companyDataFormGroup.get('revenueCtrl')?.hasError('required')">O faturamento é
              obrigatório</mat-error>
            <mat-error *ngIf="companyDataFormGroup.get('revenueCtrl')?.hasError('min')">
              Infelizmente no momento só conseguimos atender empresas com faturamento maior que
              {{ config?.minRevenueStr }}.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field col-12 col-lg-6">
          <mat-icon fontSet="fas" fontIcon="fa-map-marker-alt" class="colored-icon"></mat-icon>
          <mat-form-field color="primary">
            <input matInput placeholder="CEP da empresa" formControlName="zipCodeCtrl" #zipCode="matInput" name="zipCode"
              [textMask]="{ mask: MASKS.cep.textMask, guide: false }" required minlength="10"
              (blur)="getAddressFromZipCode()"
              (keyup)="zipCode.value?.length === 10 ? getAddressFromZipCode() : false" />
            <mat-error *ngIf="personalDataFormGroup.get('zipCodeCtrl')?.hasError('required')">
              O CEP é obrigatório.
            </mat-error>
            <mat-error *ngIf="personalDataFormGroup.get('zipCodeCtrl')?.hasError('minlength')">
              Informe um CEP válido.
            </mat-error>
            <mat-error *ngIf="personalDataFormGroup.get('zipCodeCtrl')?.hasError('incorrect')">
              O CEP informado não foi encontrado.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field col-12 col-lg-4 mobileTag">
          <mat-form-field color="primary">
            <input matInput type="number" placeholder="Número" formControlName="numberCtrl" required name="number" />
            <mat-error *ngIf="personalDataFormGroup.get('numberCtrl')?.hasError('required')">
              O número é obrigatório.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field col-12 col-lg-4" style="margin-left: 30px;">
          <mat-form-field color="primary">
            <input matInput placeholder="Complemento" formControlName="extraInfoCtrl" name="extraInfo" />
          </mat-form-field>
        </div>
        <div class="field col-12 col-lg-6 mobileTag">
          <mat-form-field color="primary">
            <input matInput placeholder="Logradouro" formControlName="streetCtrl" required name="street" />
            <mat-error *ngIf="personalDataFormGroup.get('streetCtrl')?.hasError('required')">
              O logradouro é obrigatório.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field col-12 col-lg-4" style="margin-left: 30px;">
          <mat-form-field color="primary">
            <input matInput placeholder="Bairro" formControlName="neighborhoodCtrl" required name="neighborhood" />
            <mat-error *ngIf="personalDataFormGroup.get('neighborhoodCtrl')?.hasError('required')">
              O bairro é obrigatório.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field col-12 col-lg-6 mobileTag">
          <mat-form-field class="city-field" color="primary">
            <input matInput placeholder="Cidade" formControlName="cityCtrl" required name="city" />
            <mat-error *ngIf="personalDataFormGroup.get('cityCtrl')?.hasError('required')">
              A cidade é obrigatória.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field col-12 col-lg-6" style="margin-left: 30px;">
          <mat-form-field color="primary">
            <input matInput placeholder="Estado" formControlName="stateCtrl" required name="state" />
            <mat-error *ngIf="personalDataFormGroup.get('stateCtrl')?.hasError('required')">
              O estado é obrigatório.
            </mat-error>
          </mat-form-field>
        </div>
        

        <div *ngIf="privacyPolicyUrl" class="col-12 col-lg-9 mt-3 mb-4 d-flex">
          <mat-checkbox color="primary" class="mr-3 my-1 checkbox" formControlName="agreedPrivacyPolicyCtrl"
            required></mat-checkbox>
          <span style="flex: 1">Eu li e concordo com a
            <a class="link" [href]="privacyPolicyUrl" target="_blank">política de privacidade</a> e com os 
            <a class="link" (click)="scrModal()" style="color: #0091ff;" >Termos de Consulta dos Sócios (SCR).</a> </span>
        </div>

        <div class="field col-12 order-12">
          <button mat-raised-button color="primary" class="action-button" type="button"
            [class.disabled]="companyDataFormGroup.invalid" [disabled]="companyDataFormGroup.invalid"
            (click)="checkCompanyFields()" *ngIf="!isProcessing">
            <img height="1" width="1" style="display: none" alt=""
              src="https://px.ads.linkedin.com/collect/?pid=695505&conversionId=4039849&fmt=gif" />
            Próximo
          </button>
          <div class="text-center">
            <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
          </div>
        </div>
      </div>
    </form>
  </section>

  <section *ngIf="step === 'financial'">
    <div>
      <span class="step-label">ETAPA 1 de 3: Sobre a empresa</span>
      <h1>Saúde Financeira</h1>
      <p>Para encontrar as melhores opões de financiamento, precisamos de algumas informações:</p>
    </div>

    <form name="financialForm" [formGroup]="financialDataFormGroup">
      <div class="form row">
        <div class="field col-12 col-lg-8">
          <mat-icon fontSet="fas" fontIcon="fa-university" class="colored-icon"></mat-icon>

          <mat-form-field color="accent" class="w-100">
            <mat-label>Regime Tributário</mat-label>
            <mat-select placeholder="Regime Tributário" formControlName="taxRegimeCtrl"
            [compareWith]="compareObjects"
            matTooltip="Para identificar seu regime tributário, verifique com seu contador.
            Caso não tenha contador seu regime tributário é simples nacional." name="taxRegime"
              (selectionChange)="onChangetaxRegime($event)" required>
              <mat-option *ngFor="let taxReg of config?.taxRegimes" [value]="taxReg">
                {{ taxReg.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="financialDataFormGroup.get('taxRegimeCtrl')?.hasError('required')">Selecione o regime
              tributário</mat-error>
          </mat-form-field>
        </div>
        <div class="field col-12 col-lg-8">
          <mat-icon fontSet="fas" fontIcon="fa-university" class="colored-icon"></mat-icon>

          <mat-form-field color="accent" class="w-100">
            <mat-label>Instituições de Crédito Atuais</mat-label>
            <mat-select placeholder="Instituições de Crédito Atuais"
            formControlName="institutionsCtrl"
            [compareWith]="compareObjects"
            matTooltip="Listar todas as instituições financeiras que a sua empresa já possui
              relacionamento. Essas instituições financeiras não serão verificadas." name="institutions" multiple
              (selectionChange)="onChangeInstitutions($event)" required>
              <mat-option *ngFor="let institution of config?.institutions" [value]="institution">
                {{ institution.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="financialDataFormGroup.get('institutionsCtrl')?.hasError('required')">Selecione pelo menos
              uma instituição</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-8" [class.d-none]="!hasOtherInstitutions" [class.field]="hasOtherInstitutions">
          <mat-icon fontSet="fas" fontIcon="fa-university" class="colored-icon"></mat-icon>

          <!-- formulario do campo outras instituições -->
          <mat-form-field color="accent" class="w-100">
            <mat-chip-list #chipList aria-label="Selecione suas instituições Financeiras">
              <mat-chip *ngFor="let newOtherInstitution of newOtherInstitutions" [selectable]="selectable"
                [removable]="removable" (removed)="remove(newOtherInstitution)">
                {{newOtherInstitution.name}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input placeholder="Outras instituições Financeiras" #newOtherInstitutionInput
                formControlName="otherInstitutionsCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let newOtherInstitution of filteredNewOtherInstitutions | async"
                [value]="newOtherInstitution">
                {{newOtherInstitution.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="field col-12">
          <button mat-stroked-button type="button" class="action-button text-black autosized"
            (click)="previousStep(step)" *ngIf="!isProcessing">
            Voltar
          </button>
          <button mat-raised-button color="primary" class="action-button" type="button" [class.disabled]="isValidForm()"
            [disabled]="isValidForm()" (click)="checkFinancialFields()" *ngIf="!isProcessing">
            Próximo
          </button>
          <div class="text-center">
            <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
          </div>
        </div>
      </div>
    </form>
  </section>

  <section *ngIf="step === 'credit'">
    <div>
      <span class="step-label">ETAPA 1 de 3: Sobre a empresa</span>
      <h1>Situação de crédito</h1>
      <p>Para encontrar as melhores opões de financiamento, precisamos de algumas informações:</p>
    </div>

    <form name="creditForm" [formGroup]="creditDataFormGroup">
      <div class="form column">
        <div class="field col-12 col-lg-7">
          <mat-icon fontSet="fas" fontIcon="fa-comment-dollar" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Quanto busca de capital?</mat-label>
            <input matInput placeholder="Quanto busca de capital?" name="credit-value" required
              formControlName="creditValueCtrl" [textMask]="{ mask: numberMask }" />
            <mat-hint>Desconsidere os centavos</mat-hint>
            <mat-error *ngIf="creditDataFormGroup.get('creditValueCtrl')?.hasError('required')">O valor de capital é
              obrigatório</mat-error>
          </mat-form-field>
        </div>

        <div class="field col-12 col-lg-7">
          <mat-icon fontSet="fas" fontIcon="fa-bullseye" class="colored-icon"></mat-icon>
          <div class="flex-fill mp-2">
            <app-form-field-select
              fieldName="Finalidade"
              controlName="purposeCtrl"
              [formGroup]="creditDataFormGroup"
              [options]="config?.purposes"
              [compareWith]="compareObjects"
              [required]="true"
            ></app-form-field-select>
          </div>
        </div>

        <div class="field col-12 col-lg-12">
          <div class="flex-fill row">
            <div class="field col-12 col-lg-7">
              <mat-icon fontSet="fas" fontIcon="fa-hand-holding-usd" class="colored-icon"></mat-icon>
              <div class="flex-fill">
                <app-form-field-select
                  fieldName="Garantias disponíveis"
                  controlName="guaranteesCtrl"
                  (selectionChange)="onChangeGuarantees($event)"
                  [formGroup]="creditDataFormGroup"
                  matTooltip="
                    -Recebíveis: É uma linha de crédito para receber antecipadamente suas vendas realizadas a prazo através
                    de cartão de crédito, boleto e/ou nota fiscal.

                    -Imóvel operacional: Sede da empresa, galpão, fábrica, salas comerciais e etc.

                    -Imóvel Não-Operacional: Residencial.

                    -Aval: Sem garantia real, nessa linha o sócio é o garantidor."
                  [options]="config?.guarantees"
                  [compareWith]="true"
                  [multiple]="true"
                  [required]="true"
                ></app-form-field-select>
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <app-form-field-select
                *ngIf="hasTypeOfAnticipation"
                fieldName="Tipo de Recebíveis"
                matTooltip="
                Cartão: Linha de crédito para vendas realizadas a prazo (cartão de crédito) através de maquininhas de cartão.
                Boleto: Linha de crédito para vendas realizadas a prazo por boleto.
                Notas fiscais: Linha de crédito para vendas realizadas a prazo por nota fiscal de venda."
                controlName="typeOfAnticipationCtrl"
                (selectionChange)="onChangeAntecipation($event)"
                [formGroup]="creditDataFormGroup"
                [options]="selectTypeOfAnticipationOperator"
                [multiple]="true"
                [required]="hasTypeOfAnticipation"
              ></app-form-field-select>
            </div>
          </div>
        </div>

        <div class="field col-12 col-lg-12" *ngIf="hasTypeOfAnticipationCard || hasTypeOfInvoices">
          <div class="flex-fill row">
            <!-- <div class="col-12 col-lg-6" *ngIf="hasTypeOfAnticipationCard">
              <app-form-field-select
                fieldName="Maquininha de cartão"
                controlName="creditCardCtrl"
                [formGroup]="creditDataFormGroup"
                [options]="config?.cardMachines"
                [multiple]="true"
                [required]="hasTypeOfAnticipationCard"
              ></app-form-field-select>
            </div> -->
            <div class="col-12 col-lg-6" *ngIf="hasTypeOfInvoices">
              <app-form-field-select
                fieldName="Tipo de Nota Fiscal"
                controlName="typeOfInvoicesCtrl"
                [formGroup]="creditDataFormGroup"
                [options]="selectTypeOfInvoices"
                [multiple]="true"
                [required]="hasTypeOfInvoices"
              ></app-form-field-select>
            </div>
          </div>
        </div>

        <div class="field col-12">
          <button mat-stroked-button type="button" class="action-button text-black autosized"
            (click)="previousStep(step)" *ngIf="!isProcessing">
            Voltar
          </button>
          <button mat-raised-button color="primary" class="action-button" type="button"
            [class.disabled]="creditDataFormGroup.invalid" [disabled]="creditDataFormGroup.invalid"
            (click)="openAnticipationDialog(step)" *ngIf="!isProcessing">
            Próximo
          </button>
          <div class="text-center">
            <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
          </div>
        </div>
      </div>
    </form>
  </section>

  <section *ngIf="step === 'personal'">
    <div>
      <span class="step-label">ETAPA 2 DE 3: INFORMAÇÕES FINAIS</span>
      <h1>Estamos quase lá!</h1>
      <p>Para encontrar as melhores opões de financiamento, precisamos de algumas informações:</p>
    </div>

    <form name="personalForm" [formGroup]="personalDataFormGroup">
      <div class="form row">
        <div class="field col-12 col-lg-10">
          <mat-icon fontSet="fas" fontIcon="fa-user" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Nome</mat-label>
            <input matInput type="text" placeholder="Nome" name="name" required formControlName="nameCtrl" />
            <mat-error *ngIf="personalDataFormGroup.get('nameCtrl')?.hasError('required')">O nome e sobrenome é
              obrigatório</mat-error>
            <mat-error *ngIf="
                personalDataFormGroup.get('nameCtrl')?.value !== '' &&
                personalDataFormGroup.get('nameCtrl')?.hasError('invalidlastname')
              ">Esse campo deve conter nome e sobrenome</mat-error>
          </mat-form-field>
        </div>

        <div class="field col-12 col-lg-4">
          <mat-icon fontSet="fas" fontIcon="fa-id-card" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>CPF</mat-label>
            <input matInput placeholder="CPF" name="cpf" required formControlName="cpfCtrl"
              [textMask]="{ mask: MASKS.cpf.textMask }" />
            <mat-error *ngIf="personalDataFormGroup.get('cpfCtrl')?.hasError('required')">O CPF é
              obrigatório</mat-error>
            <mat-error *ngIf="personalDataFormGroup.get('cpfCtrl')?.hasError('cpf')">CPF inválido</mat-error>
          </mat-form-field>
        </div>

        <div class="field col-12 col-lg-5" *ngIf="hasTaxRegimeMei">
          <mat-icon fontSet="fas" fontIcon="fa-university" class="colored-icon"></mat-icon>
          <mat-form-field color="primary">
            <mat-label>Data de Nascimento</mat-label>
            <input matInput [matDatepicker]="birthDate" placeholder="Data de Nascimento" name="birthDate"
              formControlName="birthDateCtrl" [required]="hasTaxRegimeMei" (focus)="birthDate.open()" />
            <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
            <mat-datepicker #birthDate></mat-datepicker>

            <mat-error *ngIf="personalDataFormGroup.get('birthDateCtrl')?.hasError('required')">A data de nascimento é
              obrigatório
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field col-12 col-lg-10" *ngIf="hasTaxRegimeMei">
          <mat-icon fontSet="fas" fontIcon="fa-university" class="colored-icon"></mat-icon>
          <div class="flex-fill mr-6">
            <app-form-field-select fieldName="Estado Civil" controlName="maritalStatusCtrl"
              [formGroup]="personalDataFormGroup" [options]="selectTypeMaritalStatus"
              [required]="hasTaxRegimeMei"></app-form-field-select>
          </div>
        </div>
        <!-- <div class="field col-12 col-lg-5" *ngIf="hasTaxRegimeMei">
          <mat-icon fontSet="fas" fontIcon="fa-mobile-alt" class="colored-icon"></mat-icon>
          <mat-form-field color="primary">
            <mat-label>Celular</mat-label>
            <input matInput placeholder="Celular" name="Celular" [required]="hasTaxRegimeMei"
              formControlName="personalCellCtrl" [textMask]="{ mask: formatPhone, guide: false }" />
            <mat-error *ngIf="personalDataFormGroup.get('personalCell')?.hasError('required')">O Celular é
              obrigatório</mat-error>
            <mat-error *ngIf="
                personalDataFormGroup.get('personalCell')?.hasError('telefone') ||
                personalDataFormGroup.get('personalCell')?.hasError('minlength')
              ">Celular inválido</mat-error>
          </mat-form-field>
        </div> -->

        <div class="field col-12 col-lg-5">
          <mat-icon fontSet="fas" fontIcon="fa-key" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Senha</mat-label>
            <input matInput type="password" placeholder="Senha" formControlName="passwordCtrl" required />
            <mat-error *ngIf="personalDataFormGroup.controls['passwordCtrl'].hasError('required')">
              Informe uma senha de acesso.
            </mat-error>
            <mat-error *ngIf="personalDataFormGroup.controls['passwordCtrl'].hasError('minlength')">
              A senha deve ter 6 caracteres ou mais.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field col-12 col-lg-5">
          <mat-icon fontSet="fas" fontIcon="fa-key" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Confirmar senha</mat-label>
            <input matInput type="password" placeholder="Confirmar senha" formControlName="confirmCtrl" required />
            <mat-error *ngIf="personalDataFormGroup.controls['confirmCtrl'].hasError('required')">
              Confirme a senha criada.
            </mat-error>
            <mat-error *ngIf="personalDataFormGroup.controls['confirmCtrl'].hasError('mismatch')">
              Confirmação de senha não confere.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-9 mt-3 mb-4">
          <mat-checkbox color="primary" class="ml-2 checkbox" formControlName="permissionCtrl" required>
            <span class="pre-wrap">Confirmo que tenho poderes de assinar pela empresa tomadora de crédito.</span>
          </mat-checkbox>
          <!-- <mat-checkbox color="primary" class="ml-2 checkbox" formControlName="privacyCtrl" required>
            <span class="pre-wrap">Concordo com a Política de Privacidade.</span>
          </mat-checkbox> -->
          <mat-checkbox color="primary" class="ml-2 mt-3 checkbox" formControlName="whatsAppContactCtrl" name="whatsAppContact">
            <span  class="pre-wrap"> Aceito receber contato sobre as propostas por whatsapp </span>
            </mat-checkbox>
        </div>


        <div class="field col-12">
          <button mat-stroked-button type="button" class="action-button text-black autosized"
            (click)="previousStep(step)" *ngIf="!isProcessing">
            Voltar
          </button>
          <button mat-raised-button color="primary" class="action-button" type="button"
            [class.disabled]="!areAllFormsValid()" [disabled]="!areAllFormsValid()" (click)="createAccount()"
            *ngIf="!isProcessing">
            Próximo
          </button>
          <div class="text-center">
            <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
