import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OpportunityStage } from '../../../../functions/src/models/opportunity/OpportunityStage';
import { OpportunityManagementService } from '../../admin/services/opportunity-management.service';

@Component({
  selector: 'app-list-open-opportunities-by-cnpj',
  templateUrl: './list-open-opportunities-by-cnpj.component.html',
  styleUrls: ['./list-open-opportunities-by-cnpj.component.css']
})
export class ListOpenOpportunitiesByCnpjComponent implements OnInit {
   opportunityStepsOptions: OpportunityStage[];
 @Input() formGroup?: FormGroup;
  constructor(  private oppService: OpportunityManagementService) {  this.opportunityStepsOptions = this.oppService.getAllOppStages();}

  ngOnInit(): void {
  }

}
