import { Component, Input, OnInit } from '@angular/core';
import { OpportunityStageSituationBanker } from '../../../../../functions/src/models/opportunity/OpportunityStages';
import { stepsProgressBar } from './stepsProgressBar';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() status: number;
  @Input() oppLastUpdate: Date;
  imgProgressBar: string;
  progressText: string;
  stepsProgressBar = stepsProgressBar;

  async ngOnInit(): Promise<void> {
    this.getImg();
  }


  getImg(): void {
    const step = this.getSteps();
    const progressText = OpportunityStageSituationBanker[this.status];
    const stepText = step !== 0 ? `Etapa ${ step }:` : '';
    this.progressText = ` ${ stepText } ${ progressText }`;
  }

  getSteps(): number {
    if (this.status === 30) { return 1; }
    if (this.status === 40) { return 2; }
    if (this.status === 60) { return 3; }
    if (this.status === 80) { return 4; }
    if (this.status === 90) { return 5; }
    if (this.status === 100) { return 6; }
    return 0;
  }
}
