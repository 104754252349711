<mat-dialog-content>
  <div class="d-flex flex-column flex-sm-row">
    <h3 style="font-weight: bold; flex: 1">Registros de acompanhamento</h3>
    <button mat-raised-button color="primary" (click)="isCreateMode = true" class="m-0 ml-sm-3 ml-md-5">
      <mat-icon>post_add</mat-icon> Novo Registro
    </button>
  </div>
  <hr style="background-color: #9e9e9e" class="mb-3" />

  <div *ngIf="isCreateMode" class="mb-3">
    <mat-card class="p-3 border">
      <mat-card-content>
        <h3 style="font-weight: 500">Novo Registro</h3>

        <mat-form-field class="w-100" color="accent">
          <textarea
            matInput
            placeholder="Descrição"
            mat-autosize
            matAutosizeMinRows="5"
            [(ngModel)]="newNote.message"
            required
          ></textarea>
        </mat-form-field>

        <div class="text-right">
          <button mat-raised-button type="button" (click)="cancelNote()" class="mx-2">
            <mat-icon>clear</mat-icon> Cancelar
          </button>
          <button
            mat-raised-button
            color="accent"
            type="button"
            [disabled]="!newNote.message"
            (click)="createNote()"
            class="mx-2"
          >
            <mat-icon>save</mat-icon> Salvar
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="d-flex align-items-center">
    <mat-form-field style="flex: 1" class="my-2">
      <mat-label>Buscar registros</mat-label>
      <input
        matInput
        type="text"
        name="searchValue"
        [(ngModel)]="searchValue"
        placeholder="Digite o texto a ser buscado"
        (keydown.enter)="searchNotes()"
        [disabled]="isLoadingNotes"
      />
    </mat-form-field>

    <button mat-button (click)="clearSearch()" [disabled]="isLoadingNotes">
      <mat-icon>clear</mat-icon>
    </button>
    <button mat-button (click)="searchNotes()" [disabled]="isLoadingNotes">
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <div class="text-center" *ngIf="isLoadingNotes">
    <mat-spinner diameter="50" class="mx-auto"></mat-spinner>
  </div>

  <div *ngIf="!isLoadingNotes">
    <p class="p-4 m-3 text-center" *ngIf="this.notes?.length === 0">Ainda não há nenhum registro.</p>

    <mat-card
      *ngFor="let note of filteredNotes; let i = index"
      class="my-2 note"
      [class.admin-note]="note.type === 'ADMIN'"
    >
      <mat-card-content class="p-3">
        <div style="white-space: pre-line">
          {{ note.message }}
        </div>
        <div *ngIf="note.attachments?.length">
          <a style="color: blue" href="{{ attachment.contentUrl }}" *ngFor="let attachment of note.attachments">{{
            attachment.fileName
          }}</a>
        </div>
        <mat-card-footer class="text-right p-3">
          <span class="date-label"
            >{{ note.createdBy?.name }} - {{ note.createdAt?.toDate() | date: 'dd/MM/yyyy HH:mm:ss' }}
            {{ note.type === 'ADMIN' ? '- (Admin)' : '' }} - {{ note.agent }}</span
          >
          <button (click)="forwardNote(note.message)" mat-icon-button matTooltip="Encaminhar mensagem">
            <mat-icon>forward</mat-icon>
          </button>
        </mat-card-footer>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>
