import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { firestore } from 'firebase';
import { Customer } from 'functions/src/models/Customer';
import _moment from 'moment';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { Subscription } from 'rxjs';
import { AgentLeadsService } from 'src/app/agents/services/agent-leads.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { analyticsReportConversion } from 'src/app/core/handler/googleAnalytics';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { CepError, Endereco } from 'src/app/core/models/model-interfaces';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { RDStationService } from 'src/app/core/services/rdstation.service';
import { ViacepService } from 'src/app/core/services/viacep.service';
import { CustomerService } from 'src/app/customer/services/customer.service';
import { TemplateSignupService } from 'src/app/layouts/templates/template-signup/template-signup.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { CadastrosGeraisService } from '../../cadastros-gerais/cadastros-gerais.service';
import { CustomersAdminService } from '../../services/customers-admin.service';
@Component({
  selector: 'app-admin-create-customer',
  templateUrl: './admin-create-customer.component.html',
  styleUrls: ['./admin-create-customer.component.scss'],
})
export class AdminCreateCustomerComponent implements OnInit, OnDestroy {
  loggedUserUid: string;
  signupConfigSubscription: Subscription;
  config: any;
  isProcessing = false;

  readonly steps = ['company', 'financial', 'credit', 'personal'];
  step = 'company';
  readonly selectTypeOfAnticipationOperator = [
    { id: 'cartao', value: 'Cartão' },
    { id: 'boleto', value: 'Boleto' },
    { id: 'nota_fiscal', value: 'Notas Fiscais' },
  ];

  readonly selectTypeMaritalStatus = [
    { id: 'solteiro', value: 'Solteiro(a)' },
    { id: 'casado', value: 'Casado(a)' },
    { id: 'separado', value: 'Separado(a)' },
    { id: 'divorciado', value: 'Divorciado(a)' },
    { id: 'viuvo', value: 'Viúvo(a)' },
  ];

  manualSignupConfigSubscription: Subscription;
  getTaxRegimesSubscription: Subscription;
  getCardMachinesSubscription: Subscription;

  daysForCustomerSignTerm = 30;
  // Forms
  customerFormGroup: FormGroup;

  // Financial Section
  hasOtherInstitutions = false;

  // Credit Section
  hasOnlyAvalGuarantee = true;

  // Tipe Antecipation
  hasTypeOfAnticipation = false;
  hasTypeOfAnticipationCard = false;
  // ceSource of Admin
  defaultSourceAdmin = 'admin';
  // MEI Section
  hasTaxRegimeMei = false;

  // Masks and validation
  readonly MASKS = MASKS;
  formatPhone = UtilHandler.formatPhone;
  readonly numberMask = createNumberMask({
    decimalLimit: 2,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: true,
    integerLimit: 15,
    prefix: 'R$ ',
    suffix: '',
  });

  constructor(
    private authService: AuthService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private cadastrosGeraisService: CadastrosGeraisService,
    private imageService: TemplateSignupService,
    private rdStationService: RDStationService,
    private viacep: ViacepService,
    private agentLeadsService: AgentLeadsService,
    private customerAdminService: CustomersAdminService
  ) {
    this.imageService.setImagePath('assets/images/signup/illustration2.svg');
  }

  ngOnInit(): void {
    this.initializeForms();
    this.authService.user.subscribe((user) => {
      this.loggedUserUid = user.uid;
    });

    this.signupConfigSubscription = this.customerService.getSignUpConfiguration().subscribe((config) => {
      this.config = config;
      this.config.minRevenue = config?.minRevenue || 360000;
      this.config.minRevenueStr = config?.minRevenueStr || 'R$ 360.000';
    });

    this.manualSignupConfigSubscription = this.customerService.getSignUpConfiguration().subscribe((config) => {
      this.config = config;
      this.config.minRevenue = config?.minRevenue || 360000;
      this.config.minRevenueStr = config?.minRevenueStr || 'R$ 360.000';
    });

    this.getTaxRegimesSubscription = this.cadastrosGeraisService.getRegimesAtivos().subscribe((regimes) => {
      regimes.map((regime) => {
        regime['id'] = regime.mnemonico;
        regime['name'] = regime.nome;

        delete regime.ativo;
        delete regime.mnemonico;
        delete regime.nome;
      });

      this.config.taxRegimes = regimes;
    });
    this.getCardMachinesSubscription = this.cadastrosGeraisService.getMaquinasAtivas().subscribe((maquinas) => {
      maquinas.map((maquina) => {
        maquina['id'] = maquina.mnemonico;
        maquina['name'] = maquina.nome;
        delete maquina.ativo;
        delete maquina.mnemonico;
        delete maquina.nome;
      });
      this.config.cardMachines = maquinas;
    });

    this.manualSignupConfigSubscription = this.configurationService.getConfiguration('agents').subscribe((config) => {
      this.daysForCustomerSignTerm = config.daysForCustomerSignTerm || 30;
    });
  }

  ngOnDestroy(): void {
    if (this.signupConfigSubscription) {
      this.signupConfigSubscription.unsubscribe();
    }

    if (this.manualSignupConfigSubscription) {
      this.manualSignupConfigSubscription.unsubscribe();
    }

    if (this.getTaxRegimesSubscription) {
      this.getTaxRegimesSubscription.unsubscribe();
    }
    if (this.getCardMachinesSubscription) {
      this.getCardMachinesSubscription.unsubscribe();
    }
  }

  areAllFormsValid(): boolean {
    return this.customerFormGroup.valid;
  }

  async checkCompanyFields(): Promise<boolean> {
    this.isProcessing = true;
    const [emailAsUser, emailAsCustomer, cnpjAsCustomer] = await Promise.all([
      this.authService.checkIfUserEmailExists(this.customerFormGroup.get('emailCtrl').value?.toLowerCase()),
      this.customerService.checkIfEmailExistsAsCustomer(this.customerFormGroup.get('emailCtrl').value?.toLowerCase()),
      this.customerService.checkIfCNPJExistsAsCustomer(this.customerFormGroup.get('cnpjCtrl').value),
    ]);
    try {
      this.isProcessing = false;

      if (!emailAsUser && !emailAsCustomer && !cnpjAsCustomer) {
        return true;
      } else if (emailAsCustomer) {
        this.showMessage(
          'E-mail já cadastrado',
          'O e-mail informado já possui uma conta. Entre no sistema para continuar.'
        );
      } else if (emailAsUser) {
        this.showMessage(
          'E-mail já cadastrado',
          'O e-mail informado já possui uma conta com outro papel no sistema. No momento só é permitido um papel por conta.'
        );
      } else if (cnpjAsCustomer) {
        this.showMessage(
          'CNPJ já cadastrado',
          'O CNPJ informado já possui uma conta. Entre no sistema para continuar.'
        );
      }
      return false;
    } catch (err) {
      this.isProcessing = false;
      console.error(err);
      this.showMessage('Erro ao validar', 'Ocorreu um erro ao validar seus dados. Tente novamente.');
      return false;
    }
  }

  checkFinancialFields(): boolean {
    const revenue = parseFloat((this.customerFormGroup.get('revenueCtrl').value ?? '1').replace(/\D/g, ''));
    const debt = parseFloat((this.customerFormGroup.get('debtCtrl')?.value ?? '0').replace(/\D/g, ''));

    const minRevenue = this.config?.minRevenue ?? 360000;
    const minRevenueStr = this.config?.minRevenueStr ?? 'R$ 360.000';
    const maxDebtPercentual = this.config?.maxDebtPercentual ?? 30;

    const debtRate = debt / revenue;
    const debtThreshold = maxDebtPercentual / 100;
    if (revenue < minRevenue) {
      this.showMessage(
        'Faturamento insuficiente',
        `Infelizmente no momento só conseguimos atender empresas com faturamento de pelo menos ${minRevenueStr}.`
      );
      return false;
    } else if (debtRate > debtThreshold) {
      this.showMessage(
        'Endividamento elevado',
        `Infelizmente no momento só conseguimos atender empresas com dívida atual de no máximo ${maxDebtPercentual}% do faturamento.`
      );
      return false;
    } else {
      return true;
    }
  }

  generatePassword(length: number): string {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return Array(length)
      .fill('')
      .map(() => charset.charAt(Math.random() * charset.length))
      .join('');
  }

  async createAccount(): Promise<void> {
    if (!(await this.checkCompanyFields()) || !this.checkFinancialFields()) {
      return;
    }

    this.isProcessing = true;

    const customer: Customer = {
      createdBy: {
        uid: this.loggedUserUid,
        type: 'ADMIN',
      },
      email: this.customerFormGroup.controls.emailCtrl.value.trim().toLowerCase(),
      cnpj: this.customerFormGroup.controls.cnpjCtrl.value.trim(),
      companyName: this.customerFormGroup.controls.companyNameCtrl.value.trim().toUpperCase(),
      address: {
        zipCode: this.customerFormGroup.controls.zipCodeCtrl.value.trim(),
        street: this.customerFormGroup.controls.streetCtrl.value.trim(),
        number: this.customerFormGroup.controls.numberCtrl.value,
        extraInfo: this.customerFormGroup.controls.extraInfoCtrl.value.trim(),
        neighborhood: this.customerFormGroup.controls.neighborhoodCtrl.value.trim(),
        city: this.customerFormGroup.controls.cityCtrl.value.trim(),
        state: this.customerFormGroup.controls.stateCtrl.value.trim(),
      },
      revenue: parseFloat(
        (this.customerFormGroup.controls.revenueCtrl.value.trim() || '0').replace(/[^\,\d]/g, '').replace(/\,/g, '.')
      ),
      revenueStr: this.formatMoney(this.customerFormGroup.controls.revenueCtrl.value.trim()),
      debt: parseFloat(
        (this.customerFormGroup.controls.debtCtrl.value.trim() || '0').replace(/[^\,\d]/g, '').replace(/\,/g, '.')
      ),
      debtStr: this.formatMoney(this.customerFormGroup.controls.debtCtrl?.value.trim()),
      taxRegime: this.customerFormGroup.controls.taxRegimeCtrl.value,
      banks: this.customerFormGroup.controls.institutionsCtrl.value,
      banksOther: this.customerFormGroup.controls.otherInstitutionsCtrl.value.trim(),

      creditValue: parseFloat(
        (this.customerFormGroup.controls.creditValueCtrl.value.trim() || '0')
          .replace(/[^\,\d]/g, '')
          .replace(/\,/g, '.')
      ),
      creditValueStr: this.formatMoney(this.customerFormGroup.controls.creditValueCtrl.value.trim()),
      purpose: this.customerFormGroup.controls.purposeCtrl.value,
      guarantees: this.customerFormGroup.controls.guaranteesCtrl.value,
      typeOfAnticipation: this.customerFormGroup.controls.typeOfAnticipationCtrl?.value ?? null,
      creditCard: this.customerFormGroup.controls.creditCardCtrl?.value ?? null,
      guaranteesValue: parseFloat(
        (!this.hasOnlyAvalGuarantee ? this.customerFormGroup.controls.guaranteesValueCtrl.value || '0' : '0')
          .trim()
          .replace(/[^\,\d]/g, '')
          .replace(/\,/g, '.')
      ),
      guaranteesValueStr: this.formatMoney(
        (!this.hasOnlyAvalGuarantee ? this.customerFormGroup.controls.guaranteesValueCtrl.value || '0' : '0').trim()
      ),

      name: this.customerFormGroup.value.nameCtrl.trim().toUpperCase(),
      cpf: this.customerFormGroup.value.cpfCtrl.trim(),
      whatsapp: this.customerFormGroup.value.whatsappCtrl.trim(),
      password: this.generatePassword(10),
      hasPermissionToSign: true,
      // agreedPrivacyPolicy: this.customerFormGroup.value.privacyCtrl,
      createdAtMillis: firestore.Timestamp.now().toMillis(),
      source: this.defaultSourceAdmin,
      // website: this.customerFormGroup.controls.websiteCtrl.value.trim().toLowerCase(),

      birthDate: this.customerFormGroup.controls?.birthDateCtrl?.value
        ? firestore.Timestamp.fromDate(_moment(this.customerFormGroup.controls?.birthDateCtrl?.value).toDate())
        : null,
      maritalStatus: this.customerFormGroup.controls?.maritalStatusCtrl?.value ?? null,
      personalCell: this.customerFormGroup.controls?.personalCellCtrl?.value ?? null,
    };

    try {
      await this.customerAdminService.createCustomerAccount(customer);

      await this.customerAdminService.notifyCustomerViaEmail(customer);

      console.log('User successfully created', customer.email);
      this.isProcessing = false;

      try {
        analyticsReportConversion('funil', 'cadastro-pessoal', 'sobre-voce');
      } catch (error) {
        console.error('Error converting on analytics', error);
      }

      this.showMessage(
        `Lead Cadastrado`,
        `O lead ${customer.companyName} (${customer.email}) foi cadastrado com sucesso.`
      )
        .afterClosed()
        .toPromise()
        .then(() => {
          this.customerFormGroup.reset();
        });
    } catch (err) {
      console.error(err);
      try {
        const parsedErr = JSON.parse(err.error);
        // Error creating auth or saving on database
        console.error('Error creating user', parsedErr);
        if (parsedErr.code === 'auth/email-already-exists') {
          this.showMessage(
            'E-mail em uso',
            `O e-mail ${customer.email} já está em uso. Faça login clicando em Entrar no canto superior direito da tela.`
          );

          console.error('Error creating user - E-mail already exists', customer.email);
          this.isProcessing = false;
        } else {
          this.showMessage(
            'Erro no cadastro',
            `Houve um problema ao tentar criar seu usuário. Por favor, tente novamente mais tarde.`
          );
          this.isProcessing = false;
        }
      } catch (err2) {
        console.error(`Error`, err, err2);
        this.showMessage(
          'Erro no cadastro',
          `Houve um problema ao tentar criar seu usuário. Por favor, tente novamente mais tarde.`
        );
        this.isProcessing = false;
      }
    }
  }

  formatMoney(str: string): string {
    if (str?.indexOf(',') < 0) {
      return str + ',00';
    } else if (str?.indexOf(',') === str.length - 1) {
      return str + '00';
    } else if (str?.indexOf(',') === str.length - 2) {
      return str + '0';
    } else {
      return str || '';
    }
  }

  getAddressFromZipCode(): void {
    const zipCode = this.customerFormGroup.get('zipCodeCtrl').value.replace('.', '').replace('-', '');

    if (!!zipCode && zipCode.length === 8) {
      this.viacep
        .buscarPorCep(zipCode)
        .then((endereco: Endereco) => {
          if (!endereco.localidade || !endereco.uf) {
            throw new Error('Error City or State are missing');
          }
          if (endereco.cep === undefined) {
            this.customerFormGroup.get('zipCodeCtrl').setErrors({ incorrect: true });
            this.customerFormGroup.get('streetCtrl').setValue('');
            this.customerFormGroup.get('numberCtrl').setValue('');
            this.customerFormGroup.get('neighborhoodCtrl').setValue('');
            this.customerFormGroup.get('cityCtrl').setValue('');
            this.customerFormGroup.get('stateCtrl').setValue('');
          } else {
            if (endereco.logradouro) {
              this.customerFormGroup.get('streetCtrl').setValue(endereco.logradouro);
              this.customerFormGroup.get('streetCtrl').disable({ onlySelf: true });
            } else {
              this.customerFormGroup.get('streetCtrl').setValue('');
              this.customerFormGroup.get('streetCtrl').enable({ onlySelf: true });
            }
            if (endereco.bairro) {
              this.customerFormGroup.get('neighborhoodCtrl').setValue(endereco.bairro);
              this.customerFormGroup.get('neighborhoodCtrl').disable({ onlySelf: true });
            } else {
              this.customerFormGroup.get('neighborhoodCtrl').setValue('');
              this.customerFormGroup.get('neighborhoodCtrl').enable({ onlySelf: true });
            }
            this.customerFormGroup.get('cityCtrl').setValue(endereco.localidade);
            this.customerFormGroup.get('stateCtrl').setValue(endereco.uf);
          }
        })
        .catch((error: CepError) => {
          this.customerFormGroup.get('zipCodeCtrl').setErrors({ incorrect: true });
          console.error('Error getting address info', error.descricao);
        });
    }
  }

  initializeForms(): void {
    this.customerFormGroup = this.formBuilder.group({
      emailCtrl: new FormControl('', [Validators.required, Validators.email]),
      cnpjCtrl: new FormControl('', [Validators.required, NgBrazilValidators.cnpj]),
      companyNameCtrl: new FormControl('', [Validators.required]),
      zipCodeCtrl: new FormControl('', [Validators.required, NgBrazilValidators.cep]),
      streetCtrl: new FormControl({ value: '', disabled: true }, [Validators.required]),
      numberCtrl: new FormControl('', [Validators.required]),
      extraInfoCtrl: new FormControl('', []),
      neighborhoodCtrl: new FormControl({ value: '', disabled: true }, [Validators.required]),
      cityCtrl: new FormControl({ value: '', disabled: true }, [Validators.required]),
      stateCtrl: new FormControl({ value: '', disabled: true }, [Validators.required]),
      revenueCtrl: new FormControl('', [Validators.required]),
      debtCtrl: new FormControl('', []),
      taxRegimeCtrl: new FormControl('', [Validators.required]),
      institutionsCtrl: new FormControl('', [Validators.required]),
      otherInstitutionsCtrl: new FormControl('Outro', [Validators.required]),
      creditValueCtrl: new FormControl('', [Validators.required]),
      purposeCtrl: new FormControl('', [Validators.required]),
      guaranteesCtrl: new FormControl('', [Validators.required]),
      typeOfAnticipationCtrl: new FormControl(''),
      creditCardCtrl: new FormControl('', []),
      guaranteesValueCtrl: new FormControl('0', []),
      nameCtrl: new FormControl('', [Validators.required]),
      cpfCtrl: new FormControl('', [Validators.required, NgBrazilValidators.cpf]),
      whatsappCtrl: new FormControl('', [Validators.required, NgBrazilValidators.telefone, Validators.minLength(15)]),
      // websiteCtrl: new FormControl(''),

      birthDateCtrl: new FormControl(''),
      maritalStatusCtrl: new FormControl(''),
      personalCellCtrl: new FormControl('', [NgBrazilValidators.telefone]),
      // permissionCtrl: new FormControl('', [Validators.required]),
    });
  }

  onChangeGuarantees(event): void {
    // if has type guarantee
    if (event.value.length && event.value.find((g) => g.id === 'boleto')) {
      this.hasTypeOfAnticipation = true;
    } else {
      this.hasTypeOfAnticipation = false;
      this.hasTypeOfAnticipationCard = false;
      this.customerFormGroup.get('typeOfAnticipationCtrl').clearValidators();
      this.customerFormGroup.get('typeOfAnticipationCtrl').setValue(null);
      this.customerFormGroup.get('creditCardCtrl').clearValidators();
      this.customerFormGroup.get('creditCardCtrl').setValue(null);
      this.customerFormGroup.updateValueAndValidity();
    }

    // if has only aval
    if (event.value.length === 1 && event.value[0].id === 'avalista') {
      this.hasOnlyAvalGuarantee = true;
      this.customerFormGroup.get('guaranteesValueCtrl').setValue(0);
    } else {
      this.hasOnlyAvalGuarantee = false;
      this.customerFormGroup.get('guaranteesValueCtrl').setValue('');
    }
  }

  onChangeAntecipation(event): void {
    // if has antecipation of card

    if (event.value.length && event.value.find((c) => c.id === 'cartao')) {
      this.hasTypeOfAnticipationCard = true;
    } else {
      this.hasTypeOfAnticipationCard = false;
      this.customerFormGroup.get('creditCardCtrl').clearValidators();
      this.customerFormGroup.updateValueAndValidity();
      this.customerFormGroup.get('creditCardCtrl').setValue(null);
    }
  }

  onChangeInstitutions(event): void {
    if (event.value.find((a) => a.id === 'outro')) {
      this.hasOtherInstitutions = true;
      this.customerFormGroup.get('otherInstitutionsCtrl').setValue('');
    } else {
      this.hasOtherInstitutions = false;
      this.customerFormGroup.get('otherInstitutionsCtrl').setValue('Outro');
    }
  }

  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }

  revenueValidation(): void {
    const revenue = parseFloat((this.customerFormGroup.get('revenueCtrl')?.value ?? '1').replace(/\D/g, ''));
    if (revenue < this.config.minRevenue) {
      this.customerFormGroup.get('revenueCtrl').setErrors({ min: true });
    }
  }
  onChangetaxRegime(event): void {
    if (event.value.id === 'mei') {
      this.hasTaxRegimeMei = true;
    } else {
      this.hasTaxRegimeMei = false;
      this.customerFormGroup.get('birthDateCtrl').clearValidators();
      this.customerFormGroup.get('maritalStatusCtrl').clearValidators();
      this.customerFormGroup.get('personalCellCtrl').clearValidators();
      this.customerFormGroup.get('birthDateCtrl').setValue(null);
      this.customerFormGroup.get('maritalStatusCtrl').setValue(null);
      this.customerFormGroup.get('personalCellCtrl').setValue(null);
      this.customerFormGroup.updateValueAndValidity();
    }
  }
}
