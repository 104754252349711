import { Component, Input, OnInit } from '@angular/core';
import { OpenFinanceService } from '../../core/services/open-finance.service';

@Component({
  selector: 'app-open-finance-admin',
  templateUrl: './open-finance-admin.component.html',
  styleUrls: ['./open-finance-admin.component.scss']
})
export class OpenFinanceAdminComponent implements OnInit {
  @Input() uid: string;
  openFinanceData: any[];

  constructor(private openFinanceService: OpenFinanceService) {}

  ngOnInit(): void {
    if (this.uid) {
      this.getOpenFinanceData(this.uid);
    }
  }

  private getOpenFinanceData(uid: string): void {
    this.openFinanceService.getOpenFinanceData(uid).subscribe(data => {
      this.openFinanceData = data;
    });
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  isObjectOrArray(value: any): boolean {
    return typeof value === 'object' && value !== null;
  }
}
