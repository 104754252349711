import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { firestore } from 'firebase';
import { Banker } from 'functions/src/models/Banker';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { BankerService } from '../../services/banker.service';
import { OpportunityService } from '../../services/opportunity.service';

@Component({
  selector: 'app-rejected-list',
  templateUrl: './rejected-list.component.html',
  styleUrls: ['./rejected-list.component.scss'],
})
export class BankerRejectedListComponent implements OnInit, OnDestroy {
  opportunities = [];

  banker: Banker;
  bankerSubscription: Subscription;

  // Control
  isLoadingResults = true;

  displayedColumns: string[] = ['companyName', 'cnpj', 'createdAt', 'requestedValue', 'annualRevenue', 'actions'];
  dataSource: MatTableDataSource<any>;

  // Filters
  labels = {
    companyName: 'Razão Social',
    cnpj: 'CNPJ',
  };
  selectedFilter = 'companyName';
  currentFilterValue = '';
  filterValue = '';

  // Pagination
  length = 0;
  pageSize = 100;
  pageSizeOptions: number[] = [10, 25, 40, 100];
  currentPage = 0;

  // Sorting
  sortField = 'createdAt';
  sortDirection = 'desc';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private bankerService: BankerService,
    private dialog: MatDialog,
    private opportunityService: OpportunityService
  ) {}

  ngOnInit(): void {
    this.isLoadingResults = true;

    this.bankerService.redirectAccordingStatus('/bankers/rejected', '/bankers/rejected');

    this.bankerSubscription = this.bankerService.banker.subscribe((banker) => {
      this.banker = banker;

      if (banker?.uid) {
        this.isLoadingResults = true;
        this.applyFilter();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.bankerSubscription) {
      this.bankerSubscription.unsubscribe();
    }
  }

  applyFilter(): void {
    this.currentFilterValue = this.filterValue;

    this.isLoadingResults = true;

    this.opportunityService
      .searchRejectedOpportunities({
        bank: this.banker.institution?.name,
        banker: this.banker.uid,
        filterField: this.selectedFilter,
        filterValue: this.currentFilterValue,
        orderField: this.sortField,
        orderDirection: this.sortDirection,
        pageSize: this.pageSize,
        page: this.currentPage,
        isRegionalized: this.banker.isRegionalized || false,
        regionalization: this.banker.regionalization || null,
        minRevenue: this.banker.revenueRange?.minRevenue || null,
        maxRevenue: this.banker.revenueRange?.maxRevenue || null,
      })
      .then((response) => {
        this.opportunities = response.results.map((op) => ({
          ...op,
          createdAt: firestore.Timestamp.fromMillis(op.createdAt).toDate(),
          termsSignatureDate: firestore.Timestamp.fromMillis(op.termsSignatureDate).toDate(),
        }));

        this.length = response.total;

        this.dataSource = new MatTableDataSource(this.opportunities);
        this.isLoadingResults = false;
      })
      .catch((err) => {
        console.error('Error getting opportunities of banker.', err);
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '700px',
            data: {
              alertTitle: 'Erro ao buscar oportunidades',
              alertDescription: `Ocorreu um erro ao buscar suas oportunidades. Por favor, tente novamente mais tarde.`,
              isOnlyConfirm: true,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.isLoadingResults = false;
          });
      });
  }

  setPageSizeOptions(setPageSizeOptionsInput: string): void {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
    }
  }

  paginatorEvent(event: PageEvent): void {
    if (event.pageSize !== this.pageSize) {
      this.pageSize = event.pageSize;
      this.currentPage = 0;
    } else {
      this.currentPage = event.pageIndex;
    }
    this.applyFilter();
  }

  sortData(event: Sort): void {
    this.sortDirection = event.direction || 'asc';
    this.sortField = event.active;
    this.currentPage = 0;
    this.applyFilter();
  }

  clearFilter(): void {
    this.currentFilterValue = '';
    this.filterValue = '';
    this.currentPage = 0;
    this.applyFilter();
  }
}
