<div class="text-center">
    <h2>Lista de documentos</h2>
</div>

<!-- Verifica se documents está vazio antes de exibir -->
<ng-container *ngIf="documents; else loadingSpinner">
    <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let document of documents">
            {{ document.name }} - {{ document.situation }}
        </mat-list-item>
    </mat-list>
</ng-container>

<!-- Spinner para exibir enquanto os documentos estão sendo carregados -->
<ng-template #loadingSpinner>
    <div class="spinner-container">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
