import { MediaMatcher } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { User } from 'functions/src/models/User';
import * as $ from 'jquery';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MenuItems } from 'src/app/admin/components/menu-items/menu-items';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SettingsState, initialState } from 'src/app/core/settings/settings.reducer';
import { environment as env } from 'src/environments/environment';

const environmentLabels = {
  PROD: 'Production',
  'PROD:LOCAL': 'Production - Local',
  'DEV:REMOTE': 'Staging',
  'DEV:LOCAL': 'Development',
};

@Component({
  selector: 'anms-template-admin',
  templateUrl: './template-admin.component.html',
  styleUrls: ['./template-admin.component.scss'],
})
export class TemplateAdminComponent implements OnInit, OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  public config: PerfectScrollbarConfigInterface = {};

  private unsubscribe$: Subject<void> = new Subject<void>();
  userSubscription: Subscription;

  @HostBinding('class') componentCssClass;

  // isProd = env.production;
  isProd = env.envName === 'PROD';
  envName = env.envName;
  envLabel = environmentLabels[env.envName] || environmentLabels['DEV:LOCAL'];
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = 'assets/images/capital-white-xs.svg';
  logoFull = 'assets/images/new-capital-white.png';
  loginAction = null;
  user: User;
  toggled = false;
  expanded = false;
  divDisabled: any;

  constructor(
    public menuItems: MenuItems,
    public authService: AuthService,
    public overlayContainer: OverlayContainer,
    private router: Router,
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');

    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.toggled = !this.mobileQuery.matches;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Verifique se a URL contém a parte "/desabilitar-div"
        this.divDisabled = event.url.includes('chat-sce');
      }

    });
  }

  ngOnInit(): void {
    this.subscribeToSettings();
    this.subscribeToIsAuthenticated();
    this.subscribeToRouterEvents();

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  private _mobileQueryListener(): void  {
    return this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    // This is for the topbar search
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
    (<any>$('.srh-btn, .cl-srh-btn')).on('click', function () {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
      (<any>$('.app-search')).toggle(200);
    });
    // This is for the megamenu
  }

  onLoginClick(): void {
    this.router.navigate(['/entrar']);
  }

  onLogoutClick(): void {
    this.authService.logout().then(() => {
      this.router.navigate(['/entrar']);
    });
  }

  toggle(): void {
    this.toggled = !this.toggled;
    this.expanded = !this.expanded;
  }

  private subscribeToIsAuthenticated(): void {
    this.userSubscription = this.authService.user.subscribe((user) => {
      this.user = user;
    });
  }

  private subscribeToSettings(): void {
    this.setTheme(initialState);
  }

  private setTheme(settings: SettingsState) {
    const effectiveTheme = settings.theme.toLowerCase();
    this.componentCssClass = effectiveTheme;
    const classList = this.overlayContainer.getContainerElement().classList;
    const toRemove = Array.from(classList).filter((item: string) => item.includes('-theme'));
    if (toRemove.length) {
      classList.remove(...toRemove);
    }
    classList.add(effectiveTheme);
  }

  private subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd || event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event) => {
        if (event instanceof ActivationEnd) {
          this.setPageTitle(event);
        }
      });
  }

  private setPageTitle(event: ActivationEnd) {
    let lastChild = event.snapshot;
    while (lastChild.children.length) {
      lastChild = lastChild.children[0];
    }
    const { title } = lastChild.data;
    this.titleService.setTitle(title ? `${title} - ${env.appName}` : env.appName);
  }
}
