import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatService } from '../../../chat/services/chat.service';

@Component({
  selector: 'app-whatsapp-dialog',
  templateUrl: './whatsapp-dialog.component.html',
  styleUrls: ['./whatsapp-dialog.component.scss']
})
export class WhatsappDialogComponent implements OnInit {
  numeroCapital?: string = "";
  textoDuvida?: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private chatService: ChatService) {
    this.getPhoneNumber();
   }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

  getPhoneNumber(): void {
    this.chatService.getPlugConfig().subscribe((data: any) => {
      this.numeroCapital = data?.phone;
      console.log('this.numeroCapital', this.numeroCapital);
    });
  }

  construirLink(): string {
    const numero: string = this.numeroCapital;
    const texto: string = encodeURIComponent(this.textoDuvida);
    return `https://wa.me/${ numero }?text=${ texto }`;
  }

}
