import flat from 'flat';
import _ from 'lodash';

export const formatNumberAsCurrency = (value: number): string => {
  return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
};

export const extractCnpjNumber = (value: string): number => {
  return Number(value.match(/[0-9]+/g).join(''));
};

export const parseErrorToString = (err: object): string => {
  return JSON.stringify(err, null, 2) ?? '';
};

export const fillTemplate = (template: string, object: Record<string, any>): string => {
  const flatObj = flat(object) as Record<string, string>;
  let result = template;
  _.toPairs(flatObj).forEach(([key, value]) => {
    result = result.split(`{${key}}`).join(value); // substitui replaceAll
  });
  return result;
};

export const parseCpf = (cpf: string): string => {
  if (!cpf) {
    return null;
  }
  return cpf?.replace(/\D/g, '').replace(/^(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
};

export const parseZipcode = (zipcode: string): string => {
  if (!zipcode) {
    return null;
  }
  return zipcode?.replace(/\D/g, '').replace(/^(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');
};

export const parseCnpj = (cnpj: string): string => {
  if (!cnpj) {
    return null;
  }
  return cnpj?.replace(/\D/g, '').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export const parsePhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return null;
  } else if (phoneNumber?.replace(/\D/g, '').length === 10) {
    return phoneNumber?.replace(/\D/g, '').replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  } else if (phoneNumber?.replace(/\D/g, '').length === 11) {
    return phoneNumber?.replace(/\D/g, '').replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
  return null;
};
