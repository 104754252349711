import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from '../../../../../functions/src/models/User';
import { Customer } from '../../../../../functions/src/models/customers/Customer';

@Component({
  selector: 'app-customer-attributed',
  templateUrl: './customer-attributed.component.html',
  styleUrls: ['./customer-attributed.component.scss']
})
export class CustomerAttributedComponent implements OnInit, OnChanges {
  @Input() users: User[] = [];
  @Input() customer: Customer;
  @Input() selectedUser: User;
  @Output() selectedUserUidChange = new EventEmitter<User>();
  ngOnInit(): void {
    this.updateSelectedUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['users'] || changes['customer']) {
      console.log('Detectou mudanças em users ou customer');
      this.updateSelectedUser();
    }
  }
  

  onUserSelectionChange(selected: User): void {
    this.selectedUser = selected;

    this.selectedUserUidChange.emit(selected);
  }

  compareSelectedUser(user1: User, user2: User): boolean {
    return user1 && user2 ? user1.uid === user2.uid : user1 === user2;
  }

  private updateSelectedUser(): void {
    console.log('users:', this.users);
    console.log('customer.digitalBanker:', this.customer?.digitalBanker);
  
    if (this.users?.length > 0 && this.customer?.digitalBanker) {
      const foundUser = this.users.find(u => u.uid === this.customer.digitalBanker) || null;
  
      if (foundUser?.uid !== this.selectedUser?.uid) {
        console.log('Atualizando selectedUser com base no customer.digitalBanker');
        this.selectedUser = foundUser;
        this.selectedUserUidChange.emit(this.selectedUser);
      }
    } else {
      console.log('Dados insuficientes para atualizar selectedUser');
    }
  }
  
  
  
}
