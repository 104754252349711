import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { createNumberMask } from 'text-mask-addons';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-customer-screening-modal',
  templateUrl: './customer-screening-modal.component.html',
  styleUrls: ['./customer-screening-modal.component.scss']
})
export class CustomerScreeningModalComponent implements OnInit {

  customerScreeningForm: FormGroup;
  customer: any;
  readonly MASKS = MASKS;
  readonly numberMask = createNumberMask({
    decimalLimit: 2,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: false,
    integerLimit: 15,
    prefix: 'R$ ',
    suffix: '',
  });

  constructor(private dialog: MatDialog, private dialogRef: MatDialogRef<CustomerScreeningModalComponent>,private formBuilder: FormBuilder, private ums: UserManagementService, @Inject(MAT_DIALOG_DATA) data) {
    this.customer = data;
   }

  ngOnInit(): void {
    this.initializeForm();
    this.onFillForm();
  }

  openInNewTab(uid: string) {
    const url = '/admin/clientes/detalhes/' + uid;
    window.open(url, '_blank');
  }
  
  initializeForm(): void{
    this.customerScreeningForm = this.formBuilder.group({
      cnpj: new FormControl('', [Validators.required, NgBrazilValidators.cnpj]),
      companyName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      creditValue: new FormControl('', Validators.required),
      debt: new FormControl(''),
      guaranteesValue: new FormControl(''),
      purpose: new FormControl('', Validators.required),
      monthlyRevenue: new FormControl('', Validators.required),
      zipcode: new FormControl('', [Validators.required, NgBrazilValidators.cep]),
    })
  }

  onFillForm(): void{
    this.customerScreeningForm.get('cnpj')?.setValue(this.customer.cnpj);
    this.customerScreeningForm.get('companyName')?.setValue(this.customer.companyName);
    this.customerScreeningForm.get('email')?.setValue(this.customer.email);
    this.customerScreeningForm.get('creditValue')?.setValue(this.customer.creditValue);
    this.customerScreeningForm.get('debt')?.setValue(this.customer.debt);
    this.customerScreeningForm.get('guaranteesValue')?.setValue(this.customer.guaranteesValue);
    this.customerScreeningForm.get('purpose')?.setValue(this.customer.purpose.name);
    this.customerScreeningForm.get('monthlyRevenue')?.setValue(this.customer.revenue);
    this.customerScreeningForm.get('zipcode')?.setValue(this.customer.address.zipCode);
  }



}
