import { Routes } from '@angular/router';
import { ChatTemplateComponent } from '../chat/chat-template.component';
import { ContainerAssignedToMeComponent } from '../chat/containers/container-assigned-to-me/container-assigned-to-me.component';
import { ContainerColaboratorComponent } from '../chat/containers/container-colaborator/container-colaborator.component';
import { ContainerContactsComponent } from '../chat/containers/container-contacts/container-contacts.component';
import { ContainerNoAssignedComponent } from '../chat/containers/container-no-assigned/container-no-assigned.component';
import { MessagesComponent } from '../chat/containers/messages/messages.component';
import { AuthGuardAdmin } from '../core/auth/auth.guard';
import { BigdatacorpComponent } from './bigdatacorp/bigdatacorp.component';
import { CadastrosGeraisComponent } from './cadastros-gerais/cadastros-gerais.component';
import { AccountantReportsComponent } from './containers/accountant-reports/accountant-reports.component';
import { AdminCreateCustomerComponent } from './containers/admin-create-customer/admin-create-customer.component';
import { AgentCreateComponent } from './containers/agent-create/agent-create.component';
import { AgentEditComponent } from './containers/agent-edit/agent-edit.component';
import { AgentReportsComponent } from './containers/agent-reports/agent-reports.component';
import { AgentViewComponent } from './containers/agent-view/agent-view.component';
import { AgentsComponent } from './containers/agents/agents.component';
import { BackupsComponent } from './containers/backups/backups.component';
import { BankerCreateComponent } from './containers/banker-create/banker-create.component';
import { BankerEditComponent } from './containers/banker-edit/banker-edit.component';
import { BankerViewComponent } from './containers/banker-view/banker-view.component';
import { BankersListComponent } from './containers/bankers-list/bankers-list.component';
import { BankersReportsComponent } from './containers/bankers-reports/bankers-reports.component';
import { CustomerReportsComponent } from './containers/customers-reports/customer-reports.component';
import { PartnerEditComponent } from './containers/partner-edit/partner-edit.component';
import { PartnershipsRegisterComponent } from './containers/partnerships-register/partnerships-register.component';
import { PartnershipsViewComponent } from './containers/partnerships-view/partnerships-view.component';
import { CustomerScreeningComponent } from './customer-screening/customer-screening.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GrupoInstituicaoFinanceiraComponent } from './grupo-instituicao-financeira/grupo-instituicao-financeira.component';
import { InstituicaoFinanceiraComponent } from './instituicao-financeira/instituicao-financeira.component';
import { OppListComponent } from './opp-list/opp-list.component';
import { TipoDocumentoComponent } from './tipo-documento/tipo-documento.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserListComponent } from './user-list/user-list.component';

const ChatAssignedRoutes: Routes = [
  {
    path: '', // Caminho vazio para a rota padrão/redirecionamento.
    pathMatch: 'full',
    redirectTo: 'cliente', // Redireciona para uma rota filho padrão.
  },
  {
    path: 'cliente/:id',
    component: MessagesComponent,
  },
  {
    path: 'cliente',
    component: MessagesComponent,
  },
]

export const AdminRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'clientes',
    children: [
      {
        path: 'buscar',
        component: UserListComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'detalhes/:uid/:tab',
        component: UserDetailComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'detalhes/:uid',
        component: UserDetailComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'oportunidades',
        component: OppListComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'cadastrar',
        component: AdminCreateCustomerComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'relatorios',
        component: CustomerReportsComponent,
        canActivate: [AuthGuardAdmin],
      },
    ],
  },
  {
    path: 'cadastros-gerais',
    component: CadastrosGeraisComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'instituicoes/instituicoes',
    component: InstituicaoFinanceiraComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'instituicoes/grupos',
    component: GrupoInstituicaoFinanceiraComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'instituicoes/triagem-clientes',
    component: CustomerScreeningComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'documentos',
    component: TipoDocumentoComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'assessores',
    children: [
      {
        path: 'buscar',
        component: AgentsComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'info/:id',
        component: AgentViewComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'cadastrar',
        component: AgentCreateComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'editar/:id',
        component: AgentEditComponent,
        canActivate: [AuthGuardAdmin],
        data: { requiredRoles: ['master', 'admin'] }
      },
      {
        path: 'relatorios',
        component: AgentReportsComponent,
        canActivate: [AuthGuardAdmin],
      },
    ],
  },
  {
    path: 'operadores',
    children: [
      {
        path: 'buscar',
        component: BankersListComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'info/:id',
        component: BankerViewComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'cadastrar',
        component: BankerCreateComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'editar/:id',
        component: BankerEditComponent,
        canActivate: [AuthGuardAdmin],
        data: { requiredRoles: ['master', 'admin'] }
      },
      {
        path: 'relatorios',
        component: BankersReportsComponent,
        canActivate: [AuthGuardAdmin],
      },
    ],
  },
  {
    path: 'contadores',
    component: AccountantReportsComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'configuracoes',
    children: [
      {
        path: 'backups',
        component: BackupsComponent,
        canActivate: [AuthGuardAdmin],
      },
    ],
  },

  {
    path: 'parceiro',
    children: [
      {
        path: 'cadastrar',
        component: PartnershipsRegisterComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'visualizar',
        component: PartnershipsViewComponent,
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'editar/:id',
        component: PartnerEditComponent,
        canActivate: [AuthGuardAdmin],
      },
    ],
  },
  {
    path: 'consulta-bigdatacorp',
    component: BigdatacorpComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'chat-sce',
    children: [
      {
        path: '',
        redirectTo: 'atribuidos-a-mim',
        pathMatch: 'full'
      },
      {
        path: 'atribuidos-a-mim',
        component: ContainerAssignedToMeComponent,
        children: ChatAssignedRoutes
      },
      {
        path: 'sem-atribuicao',
        component: ContainerNoAssignedComponent,
        children: ChatAssignedRoutes
      },
      {
        path: 'contatos',
        component: ContainerContactsComponent,
        children: ChatAssignedRoutes
      },
      {
        path: 'colaborador/:uid',
        component: ContainerColaboratorComponent,
        canActivate: [AuthGuardAdmin],
        children: ChatAssignedRoutes,
        data: { requiredRoles: ['master'] }
      },
    ],
    component: ChatTemplateComponent,
    canActivate: [AuthGuardAdmin],
  },
];