import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Customer } from 'functions/src/models/Customer';
import { User, UserRole } from 'functions/src/models/User';
import { Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { analyticsReportConversion } from 'src/app/core/handler/googleAnalytics';
import { TemplateSignupService as ImageService } from 'src/app/layouts/templates/template-signup/template-signup.service';
import { CustomerService } from '../../services/customer.service';

class LoginData {
  constructor(public email: string, public password: string) { }
}

@Component({
  selector: 'app-user-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class UserLoginComponent implements OnInit, OnDestroy {
  // Control
  isProcessing = false;
  resetEmailWasSent = false;
  authSubscription: Subscription;
  isLoading = true;

  // Login
  loginData = new LoginData('', '');

  // Reset Password
  isResetPassword = false;
  emailResetPassword = '';

  isCapsLockOn = false;  // Flag para verificar o CapsLock

  // origem
  CESource: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private imageService: ImageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.imageService.setImagePath('assets/images/signup/illustration1.svg');
  }

  ngOnInit(): void {
    this.tagOrigin();
    this.authSubscription = this.authService.user.subscribe((user) => {
      if (user) {
        if (!user.isAnonymous) {
          this.isLoading = true;
          this.redirectAccordingRoles();
        } else {
          this.isLoading = false;
        }
      }
    });

    if (this.activatedRoute.snapshot.params.email) {
      this.loginData = (this.activatedRoute.snapshot.params.email || '').toLowerCase();
    }
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  checkCapsLock(event: KeyboardEvent): void {
    this.isCapsLockOn = event.getModifierState('CapsLock');
  }

  login(): void {
    this.isProcessing = true;

    this.authService
      .checkUserTypes((this.loginData.email || '').trim().toLowerCase(), true)
      .then((result) => {
        if (!result) {
          this.showErrorMessage('Problemas na autenticação', `O usuário informado não foi encontrado.`);
          this.isProcessing = false;
        } else {
          this.authService
            .loginWithEmailAndPassword((this.loginData.email || '').trim().toLowerCase(), this.loginData.password)
            .then(() => {
              console.log('User authenticated');

              this.redirectAccordingRoles();

              this.isProcessing = false;
            })
            .catch((err) => {
              console.error('Error on login', err.message);
              if (err.code === 'auth/invalid-email') {
                this.showErrorMessage('Problemas na autenticação', `Usuário inválidos.`);
              } else if (err.code === 'auth/wrong-password') {
                this.showErrorMessage('Problemas na autenticação', `Senha inválidos.`);
              } else if (err.code === 'auth/user-not-found') {
                this.showErrorMessage('Problemas na autenticação', `O usuário informado não foi encontrado.`);
              } else {
                this.showErrorMessage(
                  'Problemas na autenticação',
                  `Ocorreu um erro ao realizar o login. Tente novamente mais tarde.`
                );
              }
              this.isProcessing = false;
            });
        }
      })
      .catch((err) => {
        console.error('Error getting user type', err);
        this.showErrorMessage(
          'Problemas na autenticação',
          `Ocorreu um erro ao realizar o login. Tente novamente mais tarde.`
        );
        this.isProcessing = false;
      });
  }

  loginWithGoogle(): void {
    this.isProcessing = true;
    this.authService
      .loginWithGoogle()
      .then((user) => {
        console.log('Usuário logado com Google:', user);
        this.redirectAccordingRoles();
        this.isProcessing = false;
      })
      .catch((err) => {
        console.error('Erro ao logar com Google:', err);
        this.showErrorMessage(
          'Problemas na autenticação',
          `Ocorreu um erro ao realizar o login com Google. Tente novamente mais tarde.`
        );
        this.isProcessing = false;
      });
  }

  redirectAccordingRoles(): void {
    this.authService.user
      .pipe(take(1))
      .toPromise()
      .then(async (user: User) => {
        if (user && !user.isAnonymous) {
          const redirect = this.route.snapshot.queryParamMap.get('redirect');

          const redirected = redirect ? await this.router.navigate([redirect]) : null;
          if (!redirected) {
            // router Admin
            if (user?.mainRole === UserRole.Admin && user.isAdmin) {
              this.router.navigate(['/admin/clientes/buscar']);
            }

            // router Agent
            if (user?.mainRole === UserRole.Agent && user.isAgent) {
              if (!user?.userConsent?.privacyPolicyDate) {
                this.router.navigate(['/agents/privacy-policy']);
              } else {
                this.router.navigate(['/agents/leads']);
              }
            }

            // router Banker
            if (user?.mainRole === UserRole.Banker && user.isBanker) {
              if (!user?.userConsent?.privacyPolicyDate) {
                this.router.navigate(['/bankers/privacy-policy']);
              } else {
                this.router.navigate(['/bankers/opportunities']);
              }
            }

            // router Customer
            if (user?.mainRole === UserRole.Customer && user.isCustomer) {
              if (!user?.userConsent?.privacyPolicyDate) {
                this.router.navigate(['/politica-de-privacidade']);
              } else {
                const customerSubscription = this.customerService.customer
                  .pipe(skipWhile((c) => !c?.uid))
                  .subscribe((customer: Customer) => {
                    if (!user.changedPassword) {
                      this.router.navigate(['/mudar-senha']);
                    } else if (!user.emailVerified) {
                      this.router.navigate(['/confirmar-email']);
                    } else if (customer?.agreedTerms || customer?.concordaComTermos) {
                      this.router.navigate(['/home']);
                    } else {
                      this.router.navigate(['/termos']);
                    }

                    if (customerSubscription) {
                      customerSubscription.unsubscribe();
                    }
                  });
              }
            }
          } else {
            console.log('Redirected to', redirect);
          }
        }
        this.isLoading = false;
      })
      .catch((err) => {
        console.error('Error redirecting user', err);
        this.isLoading = false;
      });
  }

  redirectToSignUp(): void {
    analyticsReportConversion('funil', 'cadastrar', 'compare-gratis');
    this.router.navigate(['/cadastrar'], { queryParams: this.getCESourceValue() });
  }

  sendEmailResetPassword(): void {
    this.isProcessing = true;
    this.resetEmailWasSent = false;

    this.authService
      .sendPasswordResetEmail(this.emailResetPassword.trim().toLowerCase())
      .then(() => {
        this.isProcessing = false;
        this.resetEmailWasSent = true;
        console.log('Reset email was sent');
      })
      .catch((err) => {
        if (err.code === 'auth/invalid-email') {
          this.showErrorMessage('Problemas ao enviar redefinição', `Email inválido.`);
        } else if (err.code === 'auth/user-not-found') {
          this.showErrorMessage('Problemas ao enviar redefinição', `O usuário informado não foi encontrado.`);
        } else {
          console.log('Error on login', err);
          this.showErrorMessage(
            'Problemas ao enviar redefinição',
            `Ocorreu um erro ao enviar o e-mail de redefinição de senha. Tente novamente mais tarde.`
          );
        }
        this.isProcessing = false;
      });
  }

  showErrorMessage(title: string, message: string): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }

  showLogin(): void {
    this.isResetPassword = false;
    this.emailResetPassword = '';
  }

  showResetPassword(): void {
    this.emailResetPassword = this.loginData.email;
    this.isResetPassword = true;
  }

  tagOrigin(): void {
    this.CESource =
      this.activatedRoute.snapshot.queryParams.utm_source ||
      this.activatedRoute.snapshot.queryParams.ce_source ||
      'organico';
  }

  getCESourceValue(): Params {
    // so passa a tag ce_source se ela nao for a padrao
    return this.activatedRoute.snapshot.queryParams;
  }
}
