import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Customer } from 'functions/src/models/Customer';
import { Observable } from 'rxjs';
import { UserDocumentService } from 'src/app/customer/services/user-document.service';
import {
  ArquivoInfo as ArquivoInfoMongoDB,
  Document as DocumentMongoDB,
} from '../../../../functions/src/models/documents/UserDocument';
import { timestampForDate } from '../../../../functions/src/utils/dates';
import { FileUploadComponent } from '../file-upload/file-upload.component';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
})
export class UploadDialogComponent implements OnInit {
  document: DocumentMongoDB;
  exerciseYear: string;
  emissionDate: FormGroup;
  customer: Customer;
  maximumDocuments: number;
  minimumDocuments: number;
  submitedFilesCount: number;
  multipleDocuments: boolean;
  fileStatus: boolean;
  uploadFiles: Array<any> = [];
  emissionDatesCtrl = false;
  uploadProcessInProgress = false;
  // Main task
  task: AngularFireUploadTask;
  // Progress monitoring
  percentage: Observable<number>;
  snapshot: Observable<any>;
  // Download URL
  downloadURL: Observable<string>;
  // State for appdropzone CSS toggling
  isHovering: boolean;
  hasEmissionDate = true;

  @ViewChild('urlImageCtrl', { static: false }) imageUrl: ElementRef;
  @ViewChild(FileUploadComponent, { static: false }) fileUpload: FileUploadComponent;

  constructor(
    private dialogRef: MatDialogRef<UploadDialogComponent>,
    private userDocumentService: UserDocumentService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.document = data.item;
    this.exerciseYear = data.exerciseYear;
    this.customer = data.customer;
    this.maximumDocuments = this.getMaximumDocument();
    this.minimumDocuments = data.item?.minimumDocuments || 1;
    this.submitedFilesCount = data.item.qtySubmittedFiles;
    if (data.hasEmissionDate !== undefined) {
      this.hasEmissionDate = data.hasEmissionDate;
    }
    if (this.exerciseYear) {
      this.fileStatus =
        (data.item?.fileInfo && data.item.fileInfo.filter((item) => item.financialYear === this.exerciseYear)) === null
          ? false
          : true;
    } else {
      this.fileStatus = true;
    }
  }

  getMaximumDocument(): number {
    const files = this.exerciseYear !== '' ? this.document.fileInfo.filter((item) => item.financialYear === this.exerciseYear) : this.document.fileInfo
    const maximumDocuments = this.document?.maximumDocuments || this.document?.minimumDocuments || 1;
    return maximumDocuments - (files?.length || 0);

  }

  ngOnInit(): void {
    this.emissionDatesCtrl = true;
  }

  async onSaveFile() {
    this.uploadProcessInProgress = true;
    this.fileUpload.uploadMetadata.forEach((file) => {
      const fileInfo: ArquivoInfoMongoDB = {
        userUploader: this.customer.email,
        dateUpload: timestampForDate(file.uploadDate),
        dateRelease: timestampForDate(file.emissionDate),
        path: file.downloadURL,
        fileName: file.file.name,
      };
      if (this?.exerciseYear) {
        fileInfo.financialYear = this.exerciseYear;
      }
      if (!this.document.fileInfo) {
        this.document.fileInfo = [];
        this.document.fileInfo = [];
        this.document.fileInfo.push(fileInfo);
        this.document.qtySubmittedFiles += this.document.fileInfo.length;
      } else {
        this.document.fileInfo.push(fileInfo);
        this.document.qtySubmittedFiles += this.document.fileInfo.length;
      }
    });

    this.document.situation = this.userDocumentService.calculateDocumentSituation(this.document);
    this.document.uid = this.document?.uid ?? this.customer.uid;
    await this.userDocumentService.patchDocument(this.document);
    this.uploadProcessInProgress = false;
    this.dialogRef.close(this.document);
  }

  getUserStatus(dataUserDocuments, detailUserData) {
    let situacaoUsuario = <any> {};
    // sem pendencias de documentos
    const f1 = dataUserDocuments.filter((a) => a.situacao !== 'Envio Completo' && a.situacao !== 'Aprovado');
    // recupera todas as oportunidades abertas
    const f2 = detailUserData.listCreditoPreAprovado.filter((a) => a.linhaAtiva && !a.linhaFechada);
    // recupera todas as oportunidades que estão não fechadas
    const f3 = detailUserData.listCreditoPreAprovado.filter((a) => !a.linhaFechada);
    if (f1 && f1.length === 0) {
      // Não há documentos pendentes de envio.
      if (f2 && f2.length > 0) {
        // Existem oportunidades de crédito abertas e não há documentos pendentes.
        situacaoUsuario = { id: 'oportunidade-aberta', name: 'Oportunidade aberta' };
      } else if (f3 && f3.length === 0) {
        // Não existem linhas abertas, documentos pendentes e todas as linhas estão fechadas
        situacaoUsuario = { id: 'operacao-fechada', name: 'Operação de crédito concluída' };
      } else {
        // Não exitem oportunidades abertas e nem documentos pendentes.
        situacaoUsuario = { id: 'sem-pendencia-oportunidade', name: 'Sem pendências e oportunidades' };
      }
    } else {
      // Existem documentos pendentes independentes das situações das oportunidades
      situacaoUsuario = { id: 'documentos-pendentes', name: 'Documentos pendentes' };
    }

    return situacaoUsuario;
  }

  onClose() {
    this.dialogRef.close(false);
  }

  toggleHover(event: boolean): void {
    this.isHovering = event;
  }

  // Determines if the upload task is active
  isActive(snapshot): boolean {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  getImageUrl(): string {
    if (this.imageUrl) {
      return this.imageUrl.nativeElement.value;
    } else {
      return null;
    }
  }

  handleHaveAllEmissionDate(e): void {
    this.emissionDatesCtrl = !e;
    this.maximumDocuments = this.getMaximumDocument();

  }
}
