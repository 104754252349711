import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { firestore } from 'firebase';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { Subscription } from 'rxjs';
import { Customer } from '../../../../functions/src/models/Customer';
import { BasicStructure } from '../../../../functions/src/models/common/BasicStructure';
import { Utm } from '../../../../functions/src/models/common/Utm';
import { CadastrosGeraisService } from '../../admin/cadastros-gerais/cadastros-gerais.service';
import { AuthService } from '../../core/auth/auth.service';
import { analyticsReportConversion } from '../../core/handler/googleAnalytics';
import { HubspotService } from '../../core/services/hubspot.service';
import { CustomerService } from '../../customer/services/customer.service';
import { TemplateSignupService } from '../../layouts/templates/template-signup/template-signup.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { DialogConfirmationOnboardingComponent } from '../dialog-confirmation-onboarding/dialog-confirmation-onboarding.component';

@Component({
  selector: 'app-form-create-account-no-term',
  templateUrl: './form-create-account-no-term.component.html',
  styleUrls: ['./form-create-account-no-term.component.scss']
})
export class FormCreateAccountNoTermComponent implements OnInit {
  @Input() bndesSource: string;

  readonly steps = ['company', 'financial', 'credit', 'personal'];

  readonly MASKS = MASKS;

  readonly selectTypeOfAnticipationOperator = [
    { id: 'cartao', value: 'Cartão' },
    { id: 'boleto', value: 'Boleto' },
    { id: 'nota_fiscal', value: 'Notas Fiscais' },
  ];

  readonly selectTypeOfInvoices = [
    { id: 'nota_servico', value: 'Notas de Serviço' },
    { id: 'nota_produto', value: 'Notas de Produto' },
  ];

  readonly selectTypeOfProperty = [
    { id: 'nota_servico', value: 'Notas de Serviço' },
    { id: 'nota_produto', value: 'Notas de Produto' },
  ];


  step = 'vehicle';
  config: any;
  privacyPolicyUrl: string;
  isProcessing = false;
  confirmationDialogData: any;
  agent: string;
  utm: Utm;
  gclid: string;
  hasTypeOfAnticipation = true;
  hasTypeOfAnticipationCard = true;
  hasTypeOfInvoices = true;

  companyDataFormGroup: FormGroup;
  anticipationDataFormGroup: FormGroup;
  vehicleDataFormGroup: FormGroup;
  propertyDataFormGroup: FormGroup;

  subscriptions: Subscription;
  getCardMachinesSubscription: Subscription;

  constructor(private formBuilder: FormBuilder, private customerService: CustomerService, private signupService: TemplateSignupService, private dialog: MatDialog, private authService: AuthService, private hubspotService: HubspotService, private activatedRoute: ActivatedRoute, private cadastrosGeraisService: CadastrosGeraisService) {
    this.subscriptions = new Subscription();
    this.utm = {};
   }

  ngOnInit(): void {
    this.initializeForms();
    this.subscriptions.add(
      this.customerService.getGeneralConfiguration().subscribe((config) => {
        this.privacyPolicyUrl = config.privacyPolicyUrl;
      })
    );
    this.signupService.setStep(this.step);
    this.subscriptions.add(
      this.customerService.getSignUpConfiguration().subscribe((config) => {
        this.config = config;
        this.config.minRevenue = config?.minRevenue ?? 360000;
        this.config.minRevenueStr = config?.minRevenueStr ?? 'R$ 360.000';
      })
    );
    this.getCardMachinesSubscription = this.cadastrosGeraisService.getMaquinasAtivas().subscribe((maquinas) => {
      maquinas.map((maquina) => {
        maquina['id'] = maquina.mnemonico;
        maquina['name'] = maquina.nome;
        delete maquina.ativo;
        delete maquina.mnemonico;
        delete maquina.nome;
      });
      this.config.cardMachines = maquinas;
    });
  }

  emailMatchValidator(formGroup: FormGroup): void {
    const error =
      formGroup.get('emailCtrl').value === formGroup.get('emailMatchCtrl').value ? null : { mismatch: true };
    formGroup.get('emailMatchCtrl').setErrors(error);
  }

  initializeForms(): void{
    this.companyDataFormGroup = this.formBuilder.group(
      {
        agreedPrivacyPolicyCtrl: new FormControl('', [Validators.required]),
        cnpjCtrl: new FormControl('', [Validators.required, NgBrazilValidators.cnpj]),
        emailCtrl: new FormControl('', [Validators.required, Validators.email]),
        emailMatchCtrl: new FormControl('', [Validators.required, Validators.email]),
        whatsappCtrl: new FormControl('', [
          Validators.required,
          NgBrazilValidators.telefone,
          Validators.minLength(14),
        ]),
        revenueCtrl: new FormControl('', [Validators.required]),
        guaranteesCtrl: new FormControl('', [Validators.required])
      },
      { validators: this.emailMatchValidator }
    )
    this.anticipationDataFormGroup = this.formBuilder.group(
      {
        typeOfAnticipationCtrl: new FormControl(''),
        creditCardCtrl: new FormControl(''),
        typeOfInvoicesCtrl: new FormControl(''),
        automaticAnticipation: new FormControl('')
      }
    )
    this.vehicleDataFormGroup = this.formBuilder.group(
      {
        moreThanTwentyYearsCtrl: new FormControl(''),
        moreThanEightyPercentPaid: new FormControl('')
      }
    )
    this.propertyDataFormGroup = this.formBuilder.group({
      typeOfPropertyCtrl: new FormControl(''),
      propertyValueCtrl: new FormControl(''),
      propertyCityCtrl: new FormControl(''),
      propertyStateCtrl: new FormControl(''),
      isFullPaidOffCtrl: new FormControl('')
    })
  }

  getAboutCompanyData(): Customer{
    const partialCustomerData: Customer = {
      email: this.companyDataFormGroup.controls.emailCtrl.value.trim().toLowerCase(),
      cnpj: this.companyDataFormGroup.controls.cnpjCtrl.value.trim(),
      companyName: this.companyDataFormGroup.controls.companyNameCtrl.value.trim().toUpperCase(),
      whatsapp: this.companyDataFormGroup.value.whatsappCtrl.trim(),
      guarantees: this.companyDataFormGroup.controls.guaranteesCtrl.value.trim(),
      revenue: parseFloat(
        (this.companyDataFormGroup.controls.revenueCtrl.value.trim() || '0').replace(/[^\,\d]/g, '').replace(/\,/g, '.')
      ),
      revenueStr: this.formatMoney(this.companyDataFormGroup.controls.revenueCtrl.value.trim()),
      stepHubspot: 1,
    };
    return partialCustomerData;
  }

  getAnticipationData(customerData: Customer): void{
    customerData.typeOfAnticipation = this.anticipationDataFormGroup.controls.taxRegimeCtrl.value;
  }

  getCustomerDataFromForm(): Customer{
    const customerData = this.getAboutCompanyData();
    this.getAnticipationData(customerData)

    const source = this.tagOrigin();

   return {
      agent: this.agent,
      createdBy: this.agent
        ? {
          uid: this.agent,
          type: 'AGENT',
        }
        : null,
      email: customerData.email,
      cnpj: customerData.cnpj,
      companyName: customerData.companyName,
      whatsapp: customerData.whatsapp,
      address: customerData.address,
      revenue: customerData.revenue,
      revenueStr: customerData.revenueStr,
      stepHubspot: customerData.stepHubspot,
      taxRegime: customerData.taxRegime,
      banks: customerData.banks,
      banksOther: customerData.banksOther,
      creditValue: customerData.creditValue,
      creditValueStr: customerData.creditValueStr,
      purpose: customerData.purpose,
      guarantees: customerData.guarantees,
      typeOfAnticipation: customerData.typeOfAnticipation,
      creditCard: customerData.creditCard,
      typeOfInvoices: customerData.typeOfInvoices,
      name: customerData.name,
      displayName: customerData.name,
      cpf: customerData.cpf,
      birthDate: customerData?.birthDate || null,
      maritalStatus: customerData?.maritalStatus || null,
      personalCell: customerData?.personalCell || null,
      hasPermissionToSign: customerData.hasPermissionToSign,
      whatsAppContact: customerData.whatsAppContact,
      cnae: customerData.cnae,
      utm: {
        ...this.utm,
        source: source,
      },
      gclid: this.gclid,
      lastContact: null,
      attributedTo: null,
      source: source,
      userConsent: {
        privacyPolicyDate: this.companyDataFormGroup.controls.agreedPrivacyPolicyCtrl.value
          ? firestore.Timestamp.now().toMillis()
          : null,
        showUserConsent: false,
      },
      companyInformation: {},
      emailVerified: false,
    };
  }

  checkCompanyFields(){
    this.isProcessing = true;
    this.confirmationDialogData = this.companyDataFormGroup.value;
    const dialogRef = this.dialog.open(DialogConfirmationOnboardingComponent, {
      data: this.confirmationDialogData
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        Promise.all([
          this.authService.checkIfUserEmailExists(this.companyDataFormGroup.get('emailCtrl').value?.toLowerCase()),
          this.customerService.checkIfUserWhatsAppExists(this.companyDataFormGroup.get('whatsappCtrl').value),
          this.customerService.checkIfEmailExistsAsCustomer(this.companyDataFormGroup.get('emailCtrl').value?.toLowerCase()),
          this.customerService.checkIfCNPJExistsAsCustomer(this.companyDataFormGroup.get('cnpjCtrl').value)
        ])
        .then(async (results) => {
          const [emailAsUser, whatsappExists, emailAsCustomer, cnpjAsCustomer] = results;
          console.log("resultado do results", results);

          if(!emailAsUser && !whatsappExists && !emailAsCustomer && !cnpjAsCustomer){
            await this.nextStep(this.step);
            await this.signupService.setStep(this.step);
          } else if (emailAsCustomer){
            this.showMessage(
              'E-mail já cadastrado',
              'O e-mail informado já possui uma conta. Entre no sistema para continuar.'
            );
          } else if (emailAsUser){
            this.showMessage(
              'E-mail já cadastrado',
              'O e-mail informado já possui uma conta com outro papel no sistema. No momento só é permitido um papel por conta.'
            );
          } else if (whatsappExists) {
            this.showMessage(
              'WhatsApp já cadastrado',
              'O número de WhatsApp informado já possui uma conta. Entre no sistema para continuar.'
            );
          } else if (cnpjAsCustomer){
            this.showMessage(
              'CNPJ já cadastrado',
              'O CNPJ informado já possui uma conta. Entre no sistema para continuar.'
            );
          }
          this.isProcessing = false;
        })
        .catch(() => {
          this.isProcessing = false;
          this.showMessage('Erro ao validar', 'Ocorreu um erro ao validar seus dados. Tente novamente.');
        });
      } else {
        this.isProcessing = false;
      }
    })
  }

  async nextStep(current: string): Promise<void> {
    const customerData = this.getCustomerDataFromForm();
    this.isProcessing = true;
    switch (current) {
      case 'company':
        analyticsReportConversion('funil', 'cadastro-empresa', 'sobre-empresa');
        console.log(customerData.stepHubspot);
        const { agent } = this.activatedRoute.snapshot.params;
        console.log(customerData)
        await this.customerService.setRegisterCustomer(customerData, agent);
        break;
      // case 'financial':
      //   analyticsReportConversion('funil', 'cadastro-financas', 'saude-financeira');
      //   this.getFinancialHealthData(customerData);
      //   await this.customerService.setFinancialHealth(customerData);
      //   break;
      // case 'credit':
      //   analyticsReportConversion('funil', 'cadastro-credito', 'situ-credito');
      //   this.getCreditStatusData(customerData);
      //   await this.customerService.setCreditStatus(customerData);
      //   break;
    }

    this.hubspotService
      .upsertCustomer(customerData)
      .then(() => {
        console.log(`Step: ${ current } - Integrated to Hubspot`);
      })
      .catch((err) => {
        console.error(`Error integrating hubspot for step ${ current }`, err);
      });

    this.step = this.steps[this.steps.indexOf(current) + 1];
    this.isProcessing = false;
  }

  previousStep(current: string): void {
    this.step = this.steps[this.steps.indexOf(current) - 1];
  }

  onChangeAnticipation(event: MatSelectChange): void {
    // if has antecipation of card
    if (event.value.length && event.value.find((model: BasicStructure) => model.id === 'cartao')) {
      this.hasTypeOfAnticipationCard = true;
    } else {
      this.hasTypeOfAnticipationCard = false;
      this.anticipationDataFormGroup.get('creditCardCtrl').clearValidators();
      this.anticipationDataFormGroup.updateValueAndValidity();
      this.anticipationDataFormGroup.get('creditCardCtrl').setValue(null);
    }
    // if has antecipation of Nota fiscal
    if (event.value.length && event.value.find((model: BasicStructure) => model.id === 'nota_fiscal')) {
      this.hasTypeOfInvoices = true;
    } else {
      this.hasTypeOfInvoices = false;
      this.anticipationDataFormGroup.get('typeOfInvoicesCtrl').clearValidators();
      this.anticipationDataFormGroup.updateValueAndValidity();
      this.anticipationDataFormGroup.get('typeOfInvoicesCtrl').setValue(null);
    }
  }

  tagOrigin(): string {
    const source =
      this?.bndesSource ??
      (this.activatedRoute.snapshot.queryParams.utm_source ||
        this.activatedRoute.snapshot.queryParams.ce_source ||
        null);

    if (typeof source !== 'string' && source?.length > 0) {
      // verificar se é array e se for transformar em string
      return source?.join(' - ');
    } else if (source) {
      // remover posiveis caracteres que prejudiquem
      return source?.replace('[', '')?.replace(']', '') ?? source;

    } else if (this.agent) {
      return 'assessor link';
    } else {
      return 'organico';
    }
  }

  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }

  formatMoney(str: string): string {
    if (str?.indexOf(',') < 0) {
      return str + ',00';
    } else if (str?.indexOf(',') === str.length - 1) {
      return str + '00';
    } else if (str?.indexOf(',') === str.length - 2) {
      return str + '0';
    } else {
      return str || '';
    }
  }

  areAllFormsValid(): boolean{
    return (
      this.companyDataFormGroup.valid
    )
  }

  revenueValidation(): void {
    const revenue = parseFloat((this.companyDataFormGroup.get('revenueCtrl')?.value ?? '1').replace(/\D/g, ''));
    if (revenue < this.config.minRevenue) {
      this.companyDataFormGroup.get('revenueCtrl').setErrors({ min: true });
    }
  }

  getVehicleValue(value: any){
    console.log('getVehicleValue', value);
  }

}
