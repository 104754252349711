import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

export interface IntegrationOpportunity {
  documentsAssociatedApproved?: boolean;
  initialStep: number;
  institutionKey: string;
  isActive: boolean;
  isAvailable: boolean;
  signed_SCR?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class IntegrateService {
  private collectionRef = 'configuracao/automatic-integrations/integrations';

  constructor(
    private angularFirestore: AngularFirestore
  ) {
  }

  findIntegrateByName(nomeNoSistema: string): Observable<IntegrationOpportunity> {
    return this.angularFirestore.collection(this.collectionRef).doc<IntegrationOpportunity>(nomeNoSistema).valueChanges();
  }

  setIntegrate(nomeNoSistema: string, data: Partial<IntegrationOpportunity>): Promise<void> {
    return this.angularFirestore.collection(this.collectionRef).doc(nomeNoSistema).set(data, { merge: true });

  }
}
