import { Routes } from '@angular/router';
import { AuthGuardCustomer } from '../core/auth/auth.guard';
import { LoginLinkedinComponent } from '../layouts/templates/login-linkedin/login-linkedin.component';
import { TemplateSignupComponent } from '../layouts/templates/template-signup/template-signup.component';
import { TemplateSiteComponent } from '../layouts/templates/template-site/template-site.component';
import { DeleteAccountComponent } from './components/delete-account/delete-account.component';
import { UserSecurityFirstLayerComponent } from './components/user-security-first-layer/user-security-first-layer.component';
import { UserSecurityComponent } from './components/user-security/user-security.component';
import { AgentComponent } from './containers/agent/agent.component';
import { CreateAccountComponent } from './containers/create-account/create-account.component';
import { CustomEmailActionsComponent } from './containers/custom-email-actions/custom-email-actions.component';
import { CustomerChangePasswordComponent } from './containers/customer-change-password/customer-change-password.component';
import { CustomerSettingsComponent } from './containers/customer-settings/customer-settings.component';
import { DuvidasFrequentesComponent } from './containers/duvidas-frequentes/duvidas-frequentes.component';
import { EmailInformationComponent } from './containers/email-information/email-information.component';
import { HomeComponent } from './containers/home/home.component';
import { UserLoginComponent } from './containers/login/login.component';
import { PrivacyPolicyComponent } from './containers/privacy-policy/privacy-policy.component';
import { TermsComponent } from './containers/terms/terms.component';
import { UserContractComponent } from './containers/user-contract/user-contract.component';
import { UserCreditLinesComponent } from './containers/user-credit-lines/user-credit-lines.component';
import { UserDocumentComponent } from './containers/user-document/user-document.component';
import { UserNotesContainerComponent } from './containers/user-notes-container/user-notes-container.component';
import { OpenFinanceInfoPageComponent } from './open-finance-info-page/open-finance-info-page.component';

export const CustomerRoutes: Routes = [
  {
    path: '', // Login and signup flow
    component: TemplateSignupComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'entrar',
      },
      { path: 'entrar', component: UserLoginComponent, data: { title: 'Autenticar' } },
      { path: 'login-linkedin', component: LoginLinkedinComponent, data: { title: 'Autenticar' } },
      { path: 'login-linkedin/:partner', component: LoginLinkedinComponent, data: { title: 'Autenticar' } },
      { path: 'entrar/:email', component: UserLoginComponent, data: { title: 'Autenticar' } },
      { path: 'cadastrar', component: CreateAccountComponent, data: { title: 'Criar Conta' } },
      { path: 'cadastrar/:agent', component: CreateAccountComponent, data: { title: 'Criar Conta' } },
      { path: 'validar', component: CustomEmailActionsComponent, data: { title: 'Validação de E-mails' } },
      {
        path: 'confirmar-email',
        component: EmailInformationComponent,
        data: { title: 'Confirme seu E-mail' },
        canActivate: [AuthGuardCustomer],
      },
      {
        path: 'termos',
        component: TermsComponent,
        data: { title: 'Finalizar Cadastro' },
        canActivate: [AuthGuardCustomer],
      },
      {
        path: 'mudar-senha',
        component: CustomerChangePasswordComponent,
        data: { title: 'Escolha uma Nova Senha' },
        canActivate: [AuthGuardCustomer],
      },
      {
        path: 'politica-de-privacidade',
        component: PrivacyPolicyComponent,
        data: { title: 'Aceitar Política de Privacidade' },
        canActivate: [AuthGuardCustomer],
      },

      // Redirect similar URLs to the correct path
      { path: 'login', redirectTo: 'entrar' },

      // Legacy:
      { path: 'user-login', redirectTo: '/entrar' },
      { path: 'create-account', redirectTo: '/cadastrar' },
      { path: 'custom-email-actions', redirectTo: '/validar' },
      { path: 'main', redirectTo: '/entrar' },
    ],
  },
  {
    path: '', // Inside Customer Area
    component: TemplateSiteComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/entrar',
      },
      { path: 'home', component: HomeComponent, data: { title: 'Home' }, canActivate: [AuthGuardCustomer] },
      
      {
        path: 'documentacao',
        component: UserDocumentComponent,
        data: { title: 'Documentação' },
        canActivate: [AuthGuardCustomer],
      },
      {
        path: 'credito',
        component: UserCreditLinesComponent,
        data: { title: 'Crédito' },
        canActivate: [AuthGuardCustomer],
      },
      {
        path: 'meu-termo',
        component: UserContractComponent,
        data: { title: 'Termos de Serviço' },
        canActivate: [AuthGuardCustomer],
      },
      {
        path: 'meu-assessor',
        component: AgentComponent,
        data: { title: 'Meu Assessor' },
        canActivate: [AuthGuardCustomer],
      },
      { path: 'mensagens', 
        component: UserNotesContainerComponent, 
        data: { title: 'Mensagens' },
        canActivate: [AuthGuardCustomer] },

     
      {
        path: 'minha-conta',
        // component: UserSettingsComponent,
        component: CustomerSettingsComponent,
        children: [
          {path: '', component: UserSecurityFirstLayerComponent},
          {path: 'alterar-minha-senha', component: UserSecurityComponent},
          {path: 'cancelar-minha-conta', component: DeleteAccountComponent},
          {path: 'dados-extras', component: CustomerSettingsComponent},
          {path: 'dados-serasa', component: CustomerSettingsComponent}

        ],
        data: { title: 'Minha Conta' },
        canActivate: [AuthGuardCustomer],
      },
      // { path: 'main/:agent', component: MainComponent, data: { title: 'Home' } },
      { path: 'duvidas-frequentes', component: DuvidasFrequentesComponent, data: { title: 'Dúvidas Frequentes' } },

      { path: 'open-finance-page', component: OpenFinanceInfoPageComponent, data: { title: 'Open Finance' }, canActivate: [AuthGuardCustomer] },
    
      { path: 'user-document', redirectTo: '/documentacao' },
      { path: 'user-credit-lines', redirectTo: '/credito' },
      { path: 'user-agent', redirectTo: '/meu-assessor' },
      { path: 'user-contract', redirectTo: '/meu-termo' },
      { path: 'messages', redirectTo: '/mensagens' },
    ],
  },
];
