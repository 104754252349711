import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { StatsService } from '../../services/stats.service';

interface AccountantData {
  uid?: string;
  cnpj?: string;
  companyName?: string;
  companyEmail?: string;
  accountantEmail?: string;
  accountantPhone?: string;
}

@Component({
  selector: 'app-accountant-reports',
  templateUrl: './accountant-reports.component.html',
  styleUrls: ['./accountant-reports.component.scss'],
})
export class AccountantReportsComponent implements OnInit {
  accountants = [];
  isLoadingAccountants = true;
  displayedColumns: string[] = ['companyName', 'companyEmail', 'cnpj', 'accountantEmail', 'accountantPhone', 'uid'];
  dataSource: MatTableDataSource<AccountantData>;

  pageSizeOptions: number[] = [10, 25, 50, 100];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selectedFields;
  readonly fieldsList = [
    'Razão social',
    'E-mail da empresa',
    'CNPJ da empresa',
    'E-mail do contador',
    'Telefone do contador',
    'UID',
  ];

  constructor(private statsService: StatsService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.getAccountants();

    this.selectedFields = new FormControl();
  }

  getAccountants(): void {
    this.isLoadingAccountants = true;
    this.statsService
      .getAccountants()
      .then((result) => {
        this.accountants = result.accountants;
        this.selectedFields.setValue(this.displayedColumns);
        this.dataSource = new MatTableDataSource(this.accountants);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.isLoadingAccountants = false;
      })
      .catch((err) => {
        console.error('Error getting accountants', err);
        this.accountants = null;

        this.dataSource = new MatTableDataSource([]);

        this.isLoadingAccountants = false;

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '700px',
          data: {
            alertTitle: 'Erro ao obter contadores',
            alertDescription: `Houve um problema ao obter a lista dos contadores.`,
            isOnlyConfirm: true,
          },
        });
      });
  }

  exportList(): void {
    if (this.selectedFields?.value?.length) {
      const data = this.accountants.map((a) => _.omit(a, _.xor(this.selectedFields.value, this.displayedColumns)));

      const blob = new Blob([this.statsService.generateCSV(data)], { type: 'text/csv' });
      const date = new Date().toLocaleDateString();

      saveAs(blob, `contadores-${date}.csv`.toLowerCase().replace(/\s/g, '-'));
    }
  }
}
