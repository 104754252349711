import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PartnershipsEntity } from '../../../core/entities/partnerships.entity';
import { PartnershipsService } from '../../../core/services/partnerships.service';

@Component({
  selector: 'app-partner-edit',
  templateUrl: './partner-edit.component.html',
  styleUrls: ['./partner-edit.component.scss']
})
export class PartnerEditComponent implements OnInit {
partner: string;

partnership: PartnershipsEntity

  constructor(private activatedRoute: ActivatedRoute,  private partnershipServe: PartnershipsService) { }

  ngOnInit(): void {

    this.partner = this.activatedRoute.snapshot.params.id;


    this.partnershipServe.getPartner(this.partner).then(() =>{
      this.partnership = this.partnershipServe.partner

    
    })
  }

}
