import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Customer } from '../../../../../functions/src/models/Customer';
import { PartnershipsEntity } from '../../../core/entities/partnerships.entity';
import { PartnershipsService } from '../../../core/services/partnerships.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { TemplateSignupService } from './template-signup.service';

interface TextItem {
  title: string;
  subtitle: string;
  stageId: string;
}

@Component({
  selector: 'app-template-signup',
  templateUrl: './template-signup.component.html',
  styleUrls: ['./template-signup.component.scss'],
})
export class TemplateSignupComponent implements OnInit, OnDestroy {
  image = '';
  imageSubscription: Subscription;
  step: string;
  order: string;
  texts: TextItem[];
  backgroundImage?: SafeStyle;
  partnerShips?: PartnershipsEntity;
  customerSubscription: Subscription;

  constructor(public router: Router, private sanitizer: DomSanitizer, private customerService: CustomerService, private activatedRoute: ActivatedRoute, private templateSignupService: TemplateSignupService, private partnerService: PartnershipsService) { }

  async ngOnInit(): Promise<void> {

    this.customerSubscription = this.customerService.customer.subscribe(async (customer: Customer) => {
      if (customer?.partnerCE) {
        await this.getPartnerShips(customer.partnerCE);
      } else {
        await this.getPartnerByUtm();
      }
      this.setBanner();
      this.getOrder();
    })

    this.imageSubscription = this.templateSignupService.imagePath.subscribe((image) => {
      this.image = image;
    });

    this.templateSignupService.getStep().subscribe((step: string) => {
      this.step = step || 'company';
    });

    this.templateSignupService.getTexts()
      .then((data: any) => {
        this.texts = data.stages.map((stage: TextItem) => ({
          title: stage.title,
          subtitle: stage.subtitle,
          stageId: stage.stageId
        }));
      })
      .catch((error) => {
        console.error('Erro ao obter os textos', error);
      });

  }

  private async getPartnerByUtm(): Promise<void> {
    const utm = this.activatedRoute.snapshot.queryParams;
    const source = utm['utm_source'];
    if (source && source !== 'organico') {
      await this.getPartnerShips(source);
    }
  }

  private setBanner(): void {
    let imageBanner = 'https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fbackground2.png?alt=media&token=ab336b19-d94f-4a8d-b5aa-d12254f3b2f0';
    if (this.partnerShips?.banner) imageBanner = this.partnerShips.banner;
    this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(`url(${ imageBanner })`);
  }

  private async getPartnerShips(id: string): Promise<void> {
    console.log('Partner Ships')
    await this.partnerService.getPartner(id.toUpperCase());
    this.partnerShips = this.partnerService.partner;

  }

  getTitleByStep(step: string): string {
    const stage = this.texts?.find((item: TextItem) => item.stageId === step);
    return stage ? stage.title : '';
  }
  getSubTitleByStep(step: string): string {
    const stage = this.texts?.find((item: TextItem) => item.stageId === step);
    return stage ? stage.subtitle : '';
  }

  getTitleFontSize(): string {
    return this.partnerShips?.fontTitleSize || '30px';
  }

  getSubTitleFontSize(): string {
    return this.partnerShips?.fontSubTitleSize || '20px';
  }

  getOrder(): string {
    if (this.partnerShips?.order ) {
      this.order = this.partnerShips?.order

       return this.order
    }else{
      this.order = 'content-area-first';
      return this.order
    }

  }

  formatText(text: string): string {
    // Substitui <br> por quebra de linha
    const textWithLineBreaks = text.replace(/<br>/g, '<br>');

    // Substitui <bold>...</bold> por negrito
    const textWithBold = textWithLineBreaks.replace(/<strong>(.*?)<\/strong>/g, (_, content) => `<strong>${content}</strong>`);

    return textWithBold;
  }

  ngOnDestroy(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
    if (this.imageSubscription) {
      this.imageSubscription.unsubscribe();
    }
  }
  redirectToSignUp(): void {
    this.router.navigate(['/cadastrar'], { queryParams: this.getQueryParams() });
  }

  redirectToLogin(): void {
    this.router.navigate(['/entrar'], { queryParams: this.getQueryParams() });
  }

  getQueryParams(): Params {
    // so passa a tag ce_source se ela nao for a padrao
    return this.activatedRoute.snapshot.queryParams;
  }
}
