import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from '../../../../functions/src/models/User';
import { Opportunity } from '../../../../functions/src/models/opportunity/Opportunity';
import { AuthService } from '../../core/auth/auth.service';
import { OpportunityManagementService } from '../services/opportunity-management.service';
import { UserManagementService } from '../services/user-management.service';

@Component({
  selector: 'app-customer-contact',
  templateUrl: './customer-contact.component.html',
  styleUrls: ['./customer-contact.component.scss']
})
export class CustomerContactComponent implements OnInit, OnDestroy {

  @Input() public opportunity: Opportunity;

  contactFormGroup: FormGroup;
  loggedUser: User;
  loggedUserSubscription: Subscription;

  returnList = [{ id: 'sim', name: 'Sim' },
  { id: 'nao', name: 'Não' }, ];

  constructor(
    private oppService: OpportunityManagementService,
    private ums: UserManagementService,
    private authService: AuthService,
  ) {}

  filtercontactings: Observable<User[]>;
  contactings: string[] = [];

  getUsersSubscription: Subscription;

  ngOnInit(): void {
    this.onLoadForm();
    this.getContactings();

    this.filtercontactings = this.contactFormGroup.controls['contactingNameControl'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser: User) => {
      this.loggedUser = loggedUser;
    });
  }

  ngOnDestroy(): void {
    if (this.getUsersSubscription) {
      this.getUsersSubscription.unsubscribe();
    }
  }

  getContactings(): void {
    this.getUsersSubscription = this.ums.getAllAdminUsers().subscribe((users) => {
      const allAdminUsers = users.docs.map((doc) => doc.data());
      allAdminUsers.map((user: User) => this.contactings.push(user.name));
    });
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.contactings.filter(contacting => contacting.toLowerCase().includes(filterValue));
  }

  onLoadForm(): void {

    this.contactFormGroup = new FormGroup({
      customerContactCtrl: new FormControl(''),
      lastContactDateCtrl: new FormControl(),
      returnContactCtrl: new FormControl(''),
      descriptionContactCtrl: new FormControl(''),
      contactingNameControl: new FormControl('')

    });
    this.contactFormGroup.get('returnContactCtrl').setValue(this.opportunity.returnContact);
    this.contactFormGroup.get('customerContactCtrl').setValue(this.opportunity.customerContact);
    this.contactFormGroup.get('lastContactDateCtrl').setValue(this.opportunity.lastContactDate);
    this.contactFormGroup.get('contactingNameControl').setValue(this.opportunity.contactingName);
    this.contactFormGroup.get('descriptionContactCtrl').setValue(this.opportunity.descriptionContact);
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1?.name === o2?.name && o1?.id === o2?.id;
  }

  async saveContact() {
    const createdBy = {
      uid: this.loggedUser.uid,
      name: this.loggedUser.displayName || this.loggedUser.name
    };
    this.opportunity.customerContact = this.contactFormGroup.controls.customerContactCtrl.value;
    this.opportunity.lastContactDate = this.contactFormGroup.controls.lastContactDateCtrl.value;
    this.opportunity.returnContact = this.contactFormGroup.controls.returnContactCtrl.value;
    this.opportunity.contactingName = this.contactFormGroup.controls.contactingNameControl.value.toUpperCase();
    this.opportunity.descriptionContact = this.contactFormGroup.controls.descriptionContactCtrl.value;
    await this.oppService.updateOpportunity(this.opportunity.uid, this.opportunity, createdBy);
  }
}
