import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';
import { LogService } from 'src/app/components/logger/log.service';
import { environment } from 'src/environments/environment';
import {
  Backup,
  BackupStatus,
  BackupStatusLabel,
  BackupTriggerTypeLabel,
  FirebaseCollections,
} from '../../../../functions/src/models/Firebase';
import { AuthService } from '../../core/auth/auth.service';
import { BackupItem } from '../containers/backups/backups.component';

@Injectable({
  providedIn: 'root',
})
export class BackupsService {
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private logger: LogService,
    private angularFirestore: AngularFirestore
  ) {}

  async backupCollection(
    collection: any,
    triggeredAt: string,
    requestedCollections: FirebaseCollections[]
  ): Promise<object> {
    try {
      console.log(`Requesting to backup collection: ${collection}`);

      return await this.http
        .post(
          `${environment.functionsUrl}/firebase/collections/${collection}`,
          { triggeredAt, requestedCollections },
          {
            headers: this.authService.getHeader(),
          }
        )
        .toPromise();
    } catch (error) {
      this.logger.fatal(`Backup Service - Error while backuping collection - ${collection}`, error);
      throw error;
    }
  }

  async getBackups(): Promise<BackupItem[] | undefined> {
    return new Promise<BackupItem[] | undefined>(async (resolve, reject) => {
      try {
        console.log(`Listing backups`);

        const backupsRef = await this.angularFirestore
          .collection('backups', (ref) => ref.orderBy('triggeredAt', 'desc'))
          .get()
          .pipe(take(1))
          .toPromise();
        const backupsDocs = backupsRef.docs;
        const Icons = {
          [BackupStatus.SUCCESS]: { name: 'check_circle', color: '#2b8a3e' },
          [BackupStatus.FAILED]: { name: 'error', color: '#c92a2a' },
          [BackupStatus.STARTED]: { name: 'cached', color: '#e67700' },
          [BackupStatus.INDETERMINATE]: { name: 'indeterminate_check_box', color: '#666' },
        };
        const backups = (backupsDocs.map((doc) => {
          const data = doc.data();

          if (
            data.status === BackupStatus.STARTED &&
            new Date(data.triggeredAt).getTime() + 3600000 < new Date().getTime()
          ) {
            data.status = BackupStatus.INDETERMINATE;
          }

          const statusLabel = BackupStatusLabel[data.status];
          const statusIcon = Icons[data.status];

          const triggerTypeLabel = BackupTriggerTypeLabel[data.triggerType];
          const triggeredAt = new Date(data.triggeredAt).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });

          const collectionsItems = data.collections.map((collection) => ({
            icon: Icons[collection.status],
            name: collection.name,
          }));
          return { ...data, collectionsItems, triggeredAt, statusIcon, statusLabel, triggerTypeLabel };
        }) as unknown[]) as Backup[];

        resolve(backups);
      } catch (error) {
        console.error(`Error while listing backups: ${error}`);
        reject(error);
      }
    });
  }
}
