<mat-tab-group>
  <mat-tab label="Gerar boletos">
    <div>
      <mat-form-field style="width: 350px; margin-left: 25px;">
        <mat-label>Oportunidades de crédito em 100% </mat-label>
        <mat-select [formControl]="selectedOpportunityHundred">
          <mat-option></mat-option>
          <mat-option (click)="getOpportunityHundred()" *ngFor="let OpportunityHundredInterface of opportunityHundred"
            [value]="OpportunityHundredInterface">
            {{ OpportunityHundredInterface.institutionName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="accountant-card">
        <p class="mb-2"><span style="font-weight: 500">Cupom cadastrado:</span> {{ customer.cupomCode }}
        </p>
        <p class="mb-2"><span style="font-weight: 500">Validade do Cupom:</span> {{ customer.dataFim }}
        </p>
      </div>
      <mat-accordion class="example-headers-align" multi >
        <mat-expansion-panel [disabled]="!selectedOpportunityHundred.value">
          <mat-expansion-panel-header>
            <mat-panel-title>1 - Gerar boleto de cobrança </mat-panel-title>
          </mat-expansion-panel-header>
          <h3 style="font-weight: 400">Gerar boleto de cobrança referente ao
            crédito tomado.</h3>

          <form [formGroup]="createChargeFormGroup">
            <mat-form-field appearance="fill">
              <mat-label>Valor</mat-label>
              <input matInput type="number" pattern="^\d+(\.\d{1,2})?$" formControlName="valueCtrl" required>
              <mat-error>O valor é obrigatório e deve ter até duas casas após a vírgula.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Vencimento</mat-label>
              <input matInput type="date" min="1" formControlName="dueDateCtrl" required>
              <mat-error>O valor é obrigatório.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Descrição</mat-label>
              <input matInput formControlName="descriptionCtrl" required>
              <mat-error>O valor é obrigatório.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Juros</mat-label>
              <input matInput type="number" formControlName="interestCtrl" [(ngModel)]="interestValue"
                name="interestValue" required>
              <mat-error>O valor é obrigatório.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Multa</mat-label>
              <input matInput type="number" formControlName="fineCtrl" [(ngModel)]="fineValue" name="fineValue"
                required>
              <mat-error>O valor é obrigatório.</mat-error>
            </mat-form-field>
            <div class="col-12 text-center">
              <button class="ml-2" mat-raised-button (click)="createCharge()" [color]="'primary'">

                <span *ngIf="!isLoading"> Gerar boleto </span>
                
              </button>
              <div style="display: flex; align-items: center; justify-content: center;">
                <mat-spinner diameter="40" *ngIf="isLoading" ></mat-spinner>
              </div>
                
            </div>
            
          </form>
        </mat-expansion-panel>
        <mat-expansion-panel [disabled]="!selectedOpportunityHundred.value">
          <mat-expansion-panel-header>
            <mat-panel-title>2 - Gerar Relatório de cobrança </mat-panel-title>
          </mat-expansion-panel-header>
          <h3 style="font-weight: 400">Gerar relatório com dados do cliente, crédito tomado e boleto para pagamento.
          </h3>
          <a class="ml-2" mat-raised-button type="button" [color]="'primary'" (click)="gerarPdf()">Gerar relatorio
            pdf</a>
        </mat-expansion-panel>
        <mat-expansion-panel [disabled]="!selectedOpportunityHundred.value">
          <mat-expansion-panel-header>
            <mat-panel-title>3 - Enviar e-mail de cobrança </mat-panel-title>
          </mat-expansion-panel-header>
          <h3>Selecione o relatório pdf para enviar ao cliente.</h3>
          <form [formGroup]="emailForm">
            <div class="ml-2" style="padding:  20px 0 20px 0 ;">
              <mat-form-field appearance="fill">
                <mat-label>Email para Copia </mat-label>
                <input matInput formControlName="ccOne">
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Email para Copia </mat-label>
                <input matInput formControlName="ccTwo">
              </mat-form-field>

              <input type="file" accept="application/pdf" color="'primary'" (change)="setValueDoc($event)">
            </div>
            <div class="w-100 p-5" *ngIf="isLoading">
              <mat-spinner *ngIf="isLoading" [diameter]="60" class="mx-auto" color="primary" style="margin: 20px;"></mat-spinner>
            </div>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-tab>
  <mat-tab label="Boletos gerados">


    <mat-form-field style="width: 350px; margin-left: 25px;">
      <mat-label>Instituição do boleto</mat-label>
      <mat-select [(value)]="selectedCharge" (change)="selectCharge()">
        <mat-option selected> Todos boletos</mat-option>
        <mat-option *ngFor="let institution of uniqueInstitutions" [value]="institution">
          {{ institution }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    
    <div *ngIf="selectedCharge">
      <div *ngFor="let AsaasPayment of asaasPayments">
        <div *ngIf="AsaasPayment.institutionName === selectedCharge" class="card">
          <h3>Informações e status</h3>
          <div style="display: flex;">
            <div style="margin: 10px;">
              <p>Boleto: <span style="color: #008348;">Emitido </span></p>
              <p>Gerado em: {{ formatDate(AsaasPayment.dateCreated).replace(',', ' -') }}</p>
              <p>Vencimento: {{ formatDate(AsaasPayment.dueDate) }}</p>
            </div>
    
            <div style="margin: 10px;">
             <p>Status: 
              <span [ngClass]="displayStatus(AsaasPayment.status).colorClass">
                {{ displayStatus(AsaasPayment.status).label }}
              </span>
             </p> 
              <p>Valor cobrado: {{ AsaasPayment.value | currency:'BRL':'symbol':'1.2-2' }}</p>
              <p *ngIf="AsaasPayment.deleted" style="color: red;">Cancelado </p>
            </div>
          </div>
          <a class="ml-2" mat-raised-button [color]="'primary'" style="color: white;"
          href="{{AsaasPayment.invoiceUrl}}" target="_blank">Acessar boleto</a>

          <a class="ml-2" mat-raised-button style="color: white; background-color: rgb(196, 21, 21);"
            (click)="negativityCharge(AsaasPayment.id)" *ngIf="AsaasPayment.status === 'OVERDUE'">Negativar Boleto</a>
          <a class="ml-2" mat-raised-button style="color: white; background-color: #ff8641;"
            (click)="cancelCharge(AsaasPayment.id)" *ngIf="AsaasPayment.status === 'PENDING'">Cancelar Boleto</a>

<div style="display: flex; align-items: center; justify-content: center;">
          <mat-spinner diameter="40" *ngIf="isLoading" ></mat-spinner>
        </div>
        </div>
        
      </div>
    </div>










    <div *ngIf="!selectedCharge">
      <div *ngFor="let AsaasPayment of asaasPayments">
        <div class="card">
          <h3>Informações e status</h3>
          <div style="display: flex;">
            <div style="margin: 10px;">
              <p>Boleto: <span style="color: #008348;">Emitido </span></p>
              <p>Gerado em: {{ formatDate(AsaasPayment.dateCreated).replace(',', ' -') }}</p>
              <p>Vencimento: {{ formatDate(AsaasPayment.dueDate) }}</p>
            </div>
    
            <div style="margin: 10px;">
             <p>Status: 
              <span [ngClass]="displayStatus(AsaasPayment.status).colorClass">
                {{ displayStatus(AsaasPayment.status).label }}
              </span>
             </p> 
              <p>Valor cobrado: {{ AsaasPayment.value | currency:'BRL':'symbol':'1.2-2' }}</p>
              <p *ngIf="AsaasPayment.deleted" style="color: red;">Cancelado </p>
            </div>
          </div>
          <a class="ml-2" mat-raised-button [color]="'primary'" style="color: white;"
          href="{{AsaasPayment.invoiceUrl}}" target="_blank">Acessar boleto</a>

          <a class="ml-2" mat-raised-button style="color: white; background-color: rgb(196, 21, 21);"
            (click)="negativityCharge(AsaasPayment.id)" *ngIf="AsaasPayment.status === 'OVERDUE'">Negativar Boleto</a>
          <a class="ml-2" mat-raised-button style="color: white; background-color: #ff8641;"
            (click)="cancelCharge(AsaasPayment.id)" *ngIf="AsaasPayment.status === 'PENDING'">Cancelar Boleto</a>

<div style="display: flex; align-items: center; justify-content: center;">
          <mat-spinner diameter="40" *ngIf="isLoading" ></mat-spinner>
        </div>
        </div>
        
      </div>
    </div>
    

  </mat-tab>

</mat-tab-group>