import { BndesIntegrationStatus } from './Bndes';
import { CNAE } from './cnae/CNAE';
import { BasicStructure } from './common/BasicStructure';
import { Utm } from './common/Utm';
import { AvaliacaoChecklist, CreditoPreAprovado, SolicitacaoCadastroAuth } from './model-interface';
import { UserConsent } from './User';

export interface Customer {
  // New Format
  accountantData?: {
    email: string;
    phone: string;
  };
  digitalBanker?: string;
  erps?: {
    omie?: {
      appKey: string;
      appSecret: string;
    };
  };
  propertyState?: string;
  propertyCity?: string;
  propertyValue?: string;
  typeOfProperty?: string;
  isActiveChatbot?: boolean;
  moreThanEightyPercentPaidProperty?: boolean;
  automaticAnt?: boolean;
  scoreSerasa?: number;
  moreThanTwentyYears?: boolean;
  moreThanEightyPercentPaid?: boolean;
  digitalBankerName?: string;
  attributedTo?: string;
  attributedToName?: string;
  photoWhatsapp?: string;
  lastContact?: firebase.firestore.Timestamp;
  totalUnreadMessages?: number;
  totalUnreadMessagesByCustomer?: number;
  asaasPaymentData?: {
    asaasPaymentId: string;
    customerId: string;
    dueDate: Date;
    invoiceUrl: string;
    paymentId: string;
    value: number;
  };
  cupomCode?: string;
  utm?: Utm;
  utmSignature?: Utm;
  dataFim?: {};
  valorCupom?: {};
  tipoDescCupom?: {};
  address?: {
    zipCode?: string;
    street?: string;
    number?: string;
    extraInfo?: string;
    neighborhood?: string;
    city?: string;
    state?: string;
  };
  stepHubspot?: number;
  agent?: string;
  agentPermissions?: {
    canSendDocs?: boolean;
    canVisualizeDocs?: boolean;
  };
  attributedToNotification?: boolean;
  whatsAppContact?: {
    date: firebase.firestore.Timestamp;
    value: boolean;
  };
  gclid?: string;
  agreedTerms?: boolean;
  banks?: BasicStructure[];
  banksOther?: string;
  canceledTerms?: {
    date?: firebase.firestore.Timestamp;
    motivation?: string;
  };
  bndesIntegrationStatus?: BndesIntegrationStatus;
  cnpj?: string;
  cnae?: string;
  cpf?: string;
  clicksignSignerKey?: string;
  companyInformation?: {
    age?: number;
    activities?: CNAE[];
    legalNature?: any;
    foundedDate?: firebase.firestore.Timestamp;
    foundedAge?: firebase.firestore.Timestamp;
    tradeName?: string;
    taxRegime?: string;
    score?: string;
  };
  companyName?: string;
  createdAt?: firebase.firestore.Timestamp;
  createdAtMillis?: number;
  createdBy?: {
    type: 'ADMIN' | 'AGENT' | 'SELF' | 'OAUTH';
    uid?: string;
  };
  creditValue?: number;
  creditValueStr?: string;
  creditCard?: BasicStructure[];
  debt?: number;
  debtStr?: string;
  displayName?: string;
  domainName?: string;
  email?: string;
  emailVerified?: boolean;
  guarantees?: BasicStructure[];
  typeOfAnticipation?: BasicStructure[];
  typeOfInvoices?: BasicStructure[];
  guaranteesValue?: number;
  guaranteesValueStr?: string;
  hasPermissionToSign?: boolean;
  birthDate?: firebase.firestore.Timestamp;
  bacenSignatureDate?: firebase.firestore.Timestamp;
  maritalStatus?: BasicStructure;
  personalCell?: string;
  hubspot?: {
    customerId?: string;
    companyId?: string;
    dealId?: string;
  };
  hasOpenFinance?: boolean;
  hasRecurrence?: boolean;
  proposalId?: string;
  ip?: string;
  isAdmin?: boolean;
  isAgent?: boolean;
  isAnonymous?: boolean;
  isBanker?: boolean;
  noTermsCustomer?: boolean;
  lastInteraction?: firebase.firestore.Timestamp;
  name?: string;
  notification?: {
    documents: boolean;
  };
  noTouch?: boolean;
  password?: string;
  phone?: string;
  photoURL?: string;
  purpose?: BasicStructure;
  restrictions?: {
    check?: boolean;
    fraud?: boolean;
    pefin?: boolean;
    refin?: boolean;
  };
  revenue?: number;
  revenueStr?: string;
  showOnboarding?: boolean;
  source?: string;
  taxRegime?: BasicStructure;
  termsSignatureDate?: firebase.firestore.Timestamp;

  uid?: string;
  whatsapp?: string;
  zendeskId?: number;

  approvedByCommittee?: boolean;
  lastDateApprovedByComittee?: firebase.firestore.Timestamp;

  agreedMakeDataAvailable?: boolean;

  documentApproval?: boolean;
  documentApprovalDate?: firebase.firestore.Timestamp;
  // Legacy Fields
  additionalData?: {
    numberOfEmployees?: string;
    hasRestriction?: boolean;
    relationshipWithCompany?: string;
    terms?: {
      accessToInformations?: boolean;
      whatsappAuthorization?: boolean;
      policyTerms?: boolean;
    };
    birthDate?: string;
    bacenSignatureDate?: string;
    loanUse?: any;
    professionalStatus?: any;
    realEstateData?: {
      debt?: number;
      hasDeed?: boolean;
      value?: number;
      type?: string;
      zipCode?: string;
      address?: string;
      number?: string;
      neighborhood?: string;
      city?: string;
      state?: string;
      country?: string;
    };
    autoData?: {
      brand?: string;
      manufacturer?: string;
      model?: string;
      modelYear?: number;
      licensePlate?: string;
      value?: number;
      year?: number;
    };
  };


  avaliacaoChecklist?: AvaliacaoChecklist;
  bigdatacorp?: any;
  chaveCancelamento?: string;
  concordaComTermos?: boolean;
  concordaComTermosExterno?: boolean;
  contratoFullPath?: string;
  contratoPath?: string;
  dataUltimaInteracao?: firebase.firestore.Timestamp;
  datetimeAceite?: firebase.firestore.Timestamp;
  datetimeCancelamento?: firebase.firestore.Timestamp;
  documentos?: any;
  emailUserCreator?: string;
  estaAtivo?: boolean;
  hasAdmPendency?: boolean;
  idAccountSalesforce?: string;
  idAgentPartnerSalesforce?: string;
  idContactSalesforce?: string;
  listCreditoExcluida?: CreditoPreAprovado[];
  listCreditoPreAprovado?: CreditoPreAprovado[];
  motivoCancelamento?: string;
  nome?: string;
  notificacoes?: {
    chat: { pendenciaParaAdm: boolean };
    documento: { pendenciaParaAdm: boolean };
    hasAdmPendency: boolean;
    oportunidade: { pendenciaParaAdm: boolean };
  };
  operacaoCreditoInicial?: any;
  origem?: string;
  pendencyType?: string;
  permiteCancelamento?: boolean;
  situacao?: BasicStructure;
  solicitacaoCadastroAuth?: SolicitacaoCadastroAuth;
  statusContrato?: any;
  telefone?: string;
  userType?: BasicStructure;
  userConsent?: UserConsent;
  website?: string;
  personType?: BasicStructure[];
  partnerCE?: string;
  lastRecurrenceDate?: firebase.firestore.Timestamp;
  recurrenceDate?: firebase.firestore.Timestamp;
  qsa?: {
    nome?: string;
    qual?: string;
  }[];
}

export enum CustomerPartner {
  'SIMCO' = 'SIMCO',
  'ARTICO' = 'ARTICO',
}

export interface NewOtherInstitution {
  id?: string;
  nomeNoSistema: string;
  name: string;
  type: string;
}

export interface CustomerResult {
  page: number;
  pageSize: number;
  total: number;
  results: any[];
}

export enum OrderDirection {
  'asc' = 'asc',
  'desc' = 'desc',
}
