<div class="customer-screening-container">
  <h1>Triagem de clientes</h1>
  <h2>Cadastro automático de clientes nos paineis das instituições financeiras.</h2>
  <h3>Selecione a instituição financeira:</h3>
  <mat-form-field appearance="outline">
    <mat-select class="institution-select">
      <mat-option class="institution-option" *ngFor="let institution of screeningInstitutions" [value]="institution.nomeNoSistema" (click)="getScreeningInstitution(institution.nomeNoSistema)" (click)="getCustomerScreening(institution)">{{ institution?.name || 'Instituição sem nome' }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="institutionSelected" class="table-container">
          <div class="text-center" *ngIf="isLoadingCustomers">
        <mat-spinner diameter="50" color="primary" class="mx-auto"></mat-spinner>
      </div>
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="checkbox" class="checkbox">
        <th mat-header-cell *matHeaderCellDef>Selecionar cliente</th>
        <td mat-cell *matCellDef="let customer">
          <mat-checkbox color="primary" (change)="toggleCustomerSelection(customer)"></mat-checkbox>
        </td>
        
      </ng-container>

      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef>Razão social</th>
        <td mat-cell *matCellDef="let customer">
          {{ customer.companyName || 'CLIENTE SEM NOME' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let customer">
          {{ customer.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef disableClear>Ações</th>
        <td mat-cell *matCellDef="let customer">
            <div>
              <a
                mat-flat-button
                color="primary" (click)="openModal(customer)"
              >
                <img src="../../../assets/images/icons/search.svg">
              </a>
            </div>
          </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div class="btn-container">
      <button class="green-button">Executar cadastro</button>

      <button class="border-button" (click)="exportList()">Exportar como XLSX</button>
    </div>
  </div>
</div>
