<div class="dialog-container" *ngIf="firstStep">
  <h2 mat-dialog-title>Você possui maquininha de cartão?</h2>
  <div class="actions-container">
    <button (click)="setAnticipationCardOption('close')">Não</button>
    <button (click)="setAnticipationCardOption('selectAnticipationCard')">Sim</button>
  </div>
</div>

<div class="dialog-container" *ngIf="secondStep">
  <h2 mat-dialog-title class="brand-dialog-title">Qual é a marca?</h2>
  <app-form-field-select
    fieldName="Selecione uma ou mais opções"
    controlName="creditCardCtrl"
    [formGroup]="anticipationFormGroup"
    [options]="config?.cardMachines"
    [multiple]="true"
  ></app-form-field-select>
  <div class="actions-container">
    <button class="green-button" (click)="setAnticipationCardOption('anticipationSelected')">Confirmar</button>
  </div>
</div>
