<div class="popup-overlay" (click)="onOverlayClick($event)">
  <mat-card class="example-card" (click)="onPopUpClick($event)">
    <mat-card-header>
      <mat-card-title>Adicionar contato</mat-card-title>
    </mat-card-header>
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <mat-label class="label">Nome</mat-label>
        <mat-form-field class="input">
          <input matInput type="text" id="contactName" formControlName="contactNameCtrl" class="input" required>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-label class="label">Descrição</mat-label>
        <mat-form-field class="input">
          <input matInput type="text" id="description" formControlName="descriptionCtrl" class="input" required>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-label class="label">WhatsApp</mat-label>
        <mat-form-field class="input">
          <input matInput type="text" id="whatsapp" formControlName="whatsappCtrl" class="input" required
            [textMask]="{ mask: formatPhone, guide: false }">
          <mat-error *ngIf="
          contactForm.get('whatsappCtrl')?.hasError('telefone') ||
          contactForm.get('whatsappCtrl')?.hasError('minlength')
          ">WhatsApp inválido</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-label class="label" matTooltip="Defina se o contato é ligado a um cliente ou a uma instituição financeira">Tipo</mat-label>
        <mat-form-field class="demo-full-width" class="input">
          <select matNativeControl id="type" formControlName="typeCtrl" required>
            <option value=""></option>
            <option value="customer">Cliente</option>
            <option value="banker" [disabled]="true">Operador</option>
          </select>
        </mat-form-field>
      </div>
      <div class="form-group" *ngIf="contactForm.get('typeCtrl').value === 'customer'">
        <mat-label class="label">CNPJ</mat-label>
        <mat-form-field class="input">
          <input matInput type="text" id="cnpj" formControlName="cnpjCtrl" class="input" required
            [textMask]="{mask: MASKS.cnpj.textMask}">
          <mat-error *ngIf="
            contactForm.get('cnpjCtrl')?.hasError('cnpj') ||
            contactForm.get('cnpjCtrl')?.hasError('minlength')
            ">Cnpj inválido</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group" *ngIf="contactForm.get('typeCtrl').value === 'banker'">
        <label class="label" for="bankName">Nome da instituição</label>
        <div class="input-container">
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let newOtherInstitution of newOtherInstitutions" [selectable]="selectable"
              [removable]="removable" (removed)="remove(newOtherInstitution)">
              {{ newOtherInstitution.name }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input *ngIf="isInputBank === true" #newOtherInstitutionInput formControlName="bankNameCtrl"
              [matAutocomplete]="auto" class="input" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let newOtherInstitution of filteredNewOtherInstitutions | async"
              [value]="newOtherInstitution">
              {{ newOtherInstitution.name }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
      <div class="buttons">
        <button mat-raised-button type="submit" [disabled]="!contactForm.valid">Salvar</button>
      </div>
    </form>
  </mat-card>
</div>