<div>
  <div class="text-center" *ngIf="isLoadingNotes">
    <mat-spinner diameter="60" class="mx-auto"></mat-spinner>
  </div>

  <div *ngIf="!isLoadingNotes">
    <div class="div-note-top"></div>


    <div class="div-note-records" id="div-scroll">
      <p class="p-4 m-3" *ngIf="this.messages?.length === 0">Ainda não há nenhuma mensagem.</p>

      <div class="messages-content">
        <div *ngFor="let message of messages; let i = index">
          <div [ngClass]="getMessageCardStyle(message)">
            <ng-container *ngIf="message.text && message.messageType !== 'NOTES'">
              <p>
                <span *ngIf="message.recipient.name === 'Capital Empreendor'"
                  style="margin-bottom: 10px; font-size: 12px; font-weight: 600;">{{message.recipient.name}}</span>
                <span *ngIf="message.recipient !== 'Capital Empreendedor'"
                  style="margin-bottom: 10px; font-size: 12px; font-weight: 600;">{{message.sender.name}}</span>
                <br>
                <span [innerHTML]="formatMessage(message.text)"></span>
                <br>
                <span>{{ formatDate(message.sendDate).replace(',', ' -') }}</span>
              </p>
            </ng-container>
            <ng-container *ngIf="message.document && message.messageType !== 'NOTES'">
              <p>
                <span *ngIf="message.recipient.name === 'Capital Empreendor'"
                  style="margin-bottom: 10px; font-size: 12px; font-weight: 600;">{{message.recipient.name}}</span>
                <span *ngIf="message.recipient !== 'Capital Empreendedor'"
                  style="margin-bottom: 10px; font-size: 12px; font-weight: 600;">{{message.sender.name}}</span>
                <br>
                <img style="background-color: #00C46C;cursor: pointer;" (click)="downloadDocuments(message)"
                  *ngIf="message.mimeType === 'application/pdf'" src="assets/images/icons/pdf-message-icon.svg"
                  alt="pdf-icon" />
                <img style="background-color: #00C46C;cursor: pointer;" (click)="downloadDocuments(message)"
                  *ngIf="message.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                  src="assets/images/icons/doc-message-icon.png" alt="document-icon" />
                <img style="background-color: #00C46C;cursor: pointer;" (click)="downloadDocuments(message)"
                  *ngIf="message.mimeType === 'application/msword'" src="assets/images/icons/doc-message-icon.png"
                  alt="document-icon" />
                <img style="background-color: #00C46C;cursor: pointer;" (click)="downloadDocuments(message)"
                  *ngIf="message.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                  src="assets/images/icons/xlsx-message-icon.png" alt="xlsx-icon" />
                <br>
                <span>{{message.documentFileName}}</span>
                <br>
                <span>{{ formatDate(message.sendDate).replace(',', ' -') }}</span>
              </p>
            </ng-container>
            <ng-container *ngIf="message.image && message.messageType !== 'NOTES'">
              <p>
                <span *ngIf="message.recipient.name === 'Capital Empreendor'"
                  style="margin-bottom: 10px; font-size: 12px; font-weight: 600;">{{message.recipient.name}}</span>
                <span *ngIf="message.recipient !== 'Capital Empreendedor'"
                  style="margin-bottom: 10px; font-size: 12px; font-weight: 600;">{{message.sender.name}}</span>
                <br>
                <img style="background-color: #00C46C;cursor: pointer;" (click)="downloadImage(message)"
                  src="assets/images/icons/image-message-icon.png" alt="document-icon" />
                <br>
                <span>{{message.imageCaption}}</span>
                <br>
                <span>{{ formatDate(message.sendDate).replace(',', ' -') }} </span>
              </p>
            </ng-container>
            <ng-container
              *ngIf="message.optionList?.options && message.optionList.options.length > 1 && message.messageType === 'CHAT-SCE'">
              <div
                [ngClass]="{'whatsapp-option-button': message.messageType === 'WHATSAPP', 'chat-se-option-button': message.messageType === 'CHAT-SCE'}">
                <span style="font-size: 20px; color: white;">{{ message.optionList.title}}</span>
                <a mat-flat-button *ngFor="let option of message.optionList.options"
                  (click)="handleOptionClick(option, message.messageType)">
                  {{ option.title }}
                </a>
              </div>
            </ng-container>
          </div>
          <div
            *ngIf="i === messages.length - 1 && message.text && message.messageType !== 'NOTES' &&
          message.sender.type === 'customer' && (this.customerData?.attributedTo === null || this.customerData?.isActiveChatbot === true)"
            ngClass="message-card-internal">
            <ng-container>
              <p class="waiting-message">
                <mat-spinner color="accent" diameter="20"></mat-spinner>
                <span>Analisando...</span>
              </p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="div-note-blank"></div>

    <div class="text-area-wrapper"
      style="display: flex; flex-direction: row; padding: 10px; justify-content: space-between;">

      <div class="col-12 col-6 col-lg-5 mr-3" *ngIf="type === 'ADMIN'">
        <mat-form-field>
          <mat-select placeholder="Selecione o atalho" name="templates" [(ngModel)]="newNote.message">
            <mat-option *ngFor="let template of templates" [value]="template.description">
              {{template.name}}

            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="order-sm-3 order-xs-3 text-right py-2 ml-3" style="margin-top: 20px;">

        
        <input #fileInput type="file" style="display: none" (change)="onFileSelected($event, 'Chat-SCE')"
          accept=".jpg, .png, .pdf, .doc, .docx, .xlsx">

      </div>
      <div class="flex-grow-1 div-form-field order-sm-2 order-xs-1" style="display: flex;" id="new-message-area">
        <button (click)="openFileInput()" style="border: none; background: transparent;">
          <mat-icon style="color: #909090; font-size: 28px;">attach_file</mat-icon></button>

        <mat-form-field appearance="filled" class="w-100" color="primary">
          <textarea class="text-area-customer-chat" matInput placeholder='Escreva sua mensagem' mat-autosize
            matAutosizeMinRows="2" matAutosizeMaxRows="10" [(ngModel)]="textMessageChatSce"
            (keydown)="onKeyDown($event)">
          ></textarea>

        </mat-form-field>
        <div *ngIf="type === 'ADMIN'" class="select-template--wrapper">
          <mat-form-field *ngIf="showTemplateOptions">
            <mat-select placeholder="Selecione o atalho" name="templates">
              <mat-option *ngFor="let template of templates" (click)="selectTemplate(template)"
                [value]="template.description">
                {{template.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="order-sm-3 order-xs-3 text-right py-2 ml-3" id="send-button">

        <button mat-button type="button" (click)="sendTextMessage('Chat-SCE')" class="transparent-button">
          <mat-icon class="black-icon">send</mat-icon>
        </button>
        
      </div>
    </div>

  </div>
  <!-- <mat-slide-toggle *ngIf="this.type === 'USER'" color="primary" [checked] = "isWhatsApp" (change)="updateCustomer($event)" >Gostaria de ser respondido via Whatsapp!</mat-slide-toggle> -->

</div>