import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Document } from '../../../../functions/src/models/documents/UserDocument';
import { UserDocumentService } from '../../customer/services/user-document.service';

@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.scss']
})
export class UserDocumentsComponent {

  uid: string;
  documents: Document[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private documenteService: UserDocumentService
  ) {
    this.uid = data.uid;
    // Chama o serviço e lida com a promessa retornada
    this.documenteService.getDocumentsPending(this.uid)
      .then(documents => {
        this.documents = documents;
      })
      .catch(error => {
        console.error('Erro ao carregar documentos:', error);
      });

  }

}
