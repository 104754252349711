<div >
  <h4 mat-dialog-title>Faixa do valor solicitado</h4>
  <div class="faixa-valor-menu-content">
    <div class="faixa-valor-sliders">
      <mat-slider style="width: 100%;" color="primary" [min]="1" [max]="maxRevenue" step="1" [(ngModel)]="minRevenue"
        (ngModelChange)="onMinSliderChange($event)" [thumbLabel]="true" [displayWith]="formatValue"></mat-slider>

      <mat-slider class="custom-slider" style="width: 100%;" color="primary" [min]="minRevenue" [max]="maxRevenue * 1.20" step="1"
        [(ngModel)]="maxRevenue" (ngModelChange)="onMaxSliderChange($event)" [thumbLabel]="true"
        [displayWith]="formatValue"></mat-slider>
    </div>
    <div class="faixa-valor-inputs">
      <div>
        <span>Valor Minimo</span>
        <label for="minRevenue">R$ <input matInput [textMask]="{ mask: numberMask }" id="minRevenue" [(ngModel)]="minRevenue"
            (ngModelChange)="onMinSliderChange($event)"></label>
      </div>

      <div>
        <span>Valor Maximo</span>
        <label for="maxRevenue">R$ <input matInput [textMask]="{ mask: numberMask }" id="maxRevenue" [(ngModel)]="maxRevenue"
            (ngModelChange)="onMaxSliderChange($event)">
        </label>
      </div>
    </div>
  </div>
</div>