import packageJson from '../../package.json';

export const environment = {
  appName: 'Capital Empreendedor',
  envName: 'STAG:REMOTE',
  production: false,
  test: false,
  baseURL: 'https://staging.capitalempreendedor.com.br',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    material: packageJson.dependencies['@angular/material/*'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
  },
  functionsUrl: 'https://us-central1-capital-empreendedor-staging.cloudfunctions.net',
  firebase: {
    apiKey: 'AIzaSyBqVaDTEDRpCZTR-Vcg7ZdEjJEKrdrBP8k',
    authDomain: 'capital-empreendedor-staging.firebaseapp.com',
    databaseURL: 'https://capital-empreendedor-staging.firebaseio.com',
    projectId: 'capital-empreendedor-staging',
    storageBucket: 'capital-empreendedor-staging.appspot.com',
    messagingSenderId: '65069234011',
    appId: '1:65069234011:web:220b3b13e0bb4002860c7e',
    measurementId: 'G-3MHY8MGMP5',
  },
  clicksign: {
    key: '336192852d6552162a110fd2c8ee6b1c',
  },
  keyPass: 'capitalempreendedordesenvolvimento',
  providerIp: 'https://api.ipify.org?format=json',
  defaultPwd: 'rfd983JJK@',
  serviceAccountId: '3276edfaee6aa7048ef43460faf4541c351a1e9f',
  urlCustomToken: 'autenticationsystemapi',
  keyCustomToken: 'PYwRqIPglD3cQA4Zbt63SBHrq8lW72eMPQe7audCyWeFWZexJaqccyJJwwfsnA64',
  url: 'https://capital-empreendedor-staging.firebaseapp.com',
  acessKey: 'cEHwn0xb87RgGej1DFZJ0PJpKlxBwcWJ'
};
