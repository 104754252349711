import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { timestampForDate } from 'functions/src/utils/dates';
import { Subscription } from 'rxjs';
import { DateUtilHandler } from '../../../../../functions/src/utils/DateUtilHandler';
import { AuthService } from '../../../core';
import { ChatAttributedEntity } from '../../models/ChatAttributedEntity';
import { ChatMenuItem } from '../../models/chat-menuItem';
import { ContactsCustomer } from '../../models/contact';
import { ChatAttributedService } from '../../services/chat-attributed.service';
import { ChatMenuService } from '../../services/chat-menu.service';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-assigned',
  templateUrl: './assigned.component.html',
  styleUrls: ['./assigned.component.scss'],
})


export class AssignedComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() loggedUser: { uid: string; name: string | 'Unknown'; email: string };
  selectedMenuItem: ChatMenuItem;
  customers: ContactsCustomer[] = [];
  todayContacts: ContactsCustomer[] = [];
  otherContacts: ContactsCustomer[] = [];
  companySelected: ContactsCustomer;

  @ViewChild('cnpjInput') cnpjInput!: ElementRef<HTMLInputElement>;
  @ViewChild('companyInput') companyInput!: ElementRef<HTMLInputElement>;
  @ViewChild('dateInput') dateInput!: ElementRef<HTMLInputElement>;

  contacts: string[] = [];
  filters: string[] = ['cnpj', 'companyName', 'lastContact'];
  filtersValues: string[] = [];
  showCustomerLabel: string;
  showPopup = false;
  originUserDetails = false;
  selectedFilter: 'cnpj' | 'companyName' | 'lastContact' = 'cnpj';
  isLoadingToday = false;
  isLoadingYesterday = false;
  isLoadingOtherdays = false;
  isMenuOpen = false;
  menuChange = false;
  typeSorting = "textsms"
  coords: { x: number; y: number } = { x: 0, y: 0 };

  private customersSubscription: Subscription;
  private loadedItemsCount = 0;
  private itemsPerPage = 100;

  runLoadMore = false;
  private notificationSound: HTMLAudioElement = new Audio('../../../assets/audios/notification-audio.mp3')

  constructor(
    private chatService: ChatService,
    private chatMenuService: ChatMenuService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private chatAtributtedService: ChatAttributedService
  ) {



  }

  ngOnInit(): void {
    this.updateFormatDateLastContactCustomer();
  }

  ngAfterViewInit(): void {
    this.setMenu();
  }

  ngOnDestroy(): void {
    if (this.customersSubscription) {
      this.customersSubscription.unsubscribe();
    }
  }

  private async setMenu(): Promise<void> {
    const menuitem = this.chatMenuService.getMenuItem();
    this.selectedMenuItem = menuitem;



    this.loadedItemsCount = 0;
    this.closeMenuContactSelected();
    this.showCustomerLabel = menuitem.name;

    if (menuitem?.state === 'contatos' && menuitem?.type === 'link') {
      this.originUserDetails = true;
    }
    if (menuitem?.state === 'atribuidos-a-mim' && menuitem?.type === 'link') {
      this.originUserDetails = false;
    }


    await this.loadCustomersWithContacts(this.selectedMenuItem?.state);
  }
  async updateFormatDateLastContactCustomer(): Promise<void> {
    try {
      await this.chatService.updateLastContactCustomerFormatDate();
    } catch (error) {
      console.error('Erro no updateFormatDateLastContactCustomer:', error);
    }
  }
  selectFilter(filter: 'cnpj' | 'companyName' | 'lastContact'): void {
    this.selectedFilter = filter;
  }

  openPopup() {
    this.showPopup = true;
  }

  closePopup() {
    this.showPopup = false;
  }

  async filterContacts() {
    this.menuChange = true;
    await this.loadCustomersWithContacts(this.selectedMenuItem.state);
  }

  formatDateInput(event: any) {
    const input = event.target;

    if (event.inputType !== 'deleteContentBackward') {
      let value = input.value.replace(/\D/g, '');

      if (value.length > 8) {
        value = value.slice(0, 8);
      }

      if (value.length >= 4) {
        value = value.replace(/(\d{2})(\d{2})(\d{0,4})/, '$1/$2/$3');
      } else if (value.length >= 2) {
        value = value.replace(/(\d{2})(\d{0,2})/, '$1/$2');
      }

      input.value = value;
    }
  }

  async loadCustomersWithContacts(menuitem?: string, nameDiv?: string): Promise<void> {
    if (this.customersSubscription) {
      this.customersSubscription.unsubscribe();
    }

    this.loadFilters();
    this.loadingFields(nameDiv ?? '', true);

    let filtersQuery = this.filters;
    let filtersValuesQuery = this.filtersValues;

    if (this.filters?.includes('lastContact')) {
      filtersQuery = ['lastContact'];
      filtersValuesQuery = [this.dateInput.nativeElement.value];
    }


    const filterOrderBy = this.typeSorting === 'assigned' ? 'attributedTo' : this.typeSorting === 'notifications' ? 'notifications' : 'lastContact';

    this.customersSubscription = this.chatService.getCustomersByLastContact(
      this.loadedItemsCount || this.itemsPerPage,
      filtersQuery,
      filtersValuesQuery, menuitem, this.loggedUser, this.selectedMenuItem, filterOrderBy
    ).subscribe(async (result: ContactsCustomer[]) => {
      const customerUids = this.customers.map(c => c.uid) || []
      const resultCsutomersUids = result?.map(c => c.uid) || []

      const customerAdd = result.filter(c => !customerUids.includes(c.uid)) || []
      const customerUpdate = this.customers.filter(c => resultCsutomersUids.includes(c.uid)) || []
      const customerRemove = resultCsutomersUids.length > 0 ? this.customers.filter(c => !resultCsutomersUids?.includes(c.uid)) || [] : this.customers;

      this.removeCustomerOfList(customerRemove)
      Promise.all(customerAdd.map(async customer => await this.addContactInList(customer)))
      customerUpdate.forEach(c => this.updateContactOfList(result, c))

      this.todayContacts = this.todayContactsFilter(this.customers);
      this.otherContacts = this.otherContactsFilter(this.customers);

      const lastCustomer = this.todayContacts[0] || this.otherContacts[0] || null;

      if(lastCustomer){
        this.showNotification(lastCustomer);
      }

      this.runLoadMore = false
      await this.loadingFields(nameDiv ?? '', false);
    });
  }

  async addContactInList(customer: ContactsCustomer): Promise<void> {

    customer.lastContactStr = customer?.lastContact ? this.datePipe.transform(timestampForDate(customer.lastContact), 'dd/MM/yyyy - HH:mm') : '';
    if (customer?.totalUnreadMessages === null) {
      this.chatService.getUnReadByAllContacts(customer.cnpj, customer)
    }
    this.customers.push(customer);

  }

  private updateContactOfList(result: ContactsCustomer[], customer: ContactsCustomer): void {
    const customerUpdate = result.find(c => c.uid === customer.uid);

    if (customer.lastContact !== customerUpdate.lastContact) {
      customer.lastContact = customerUpdate.lastContact;
      customer.lastContactStr = customer?.lastContact ? this.datePipe.transform(timestampForDate(customer.lastContact), 'dd/MM/yyyy - HH:mm') : '';
      if (customerUpdate?.totalUnreadMessages === null) {
        this.chatService.getUnReadByAllContacts(customer.cnpj, customer)
      }
    }

    if (customer.totalUnreadMessages !== customerUpdate.totalUnreadMessages) {
      customer.totalUnreadMessages = customerUpdate.totalUnreadMessages;
      this.playNotification(customer)
    }
    if (customer.attributedToNotification !== customerUpdate.attributedToNotification) {
      customer.attributedToNotification = customerUpdate.attributedToNotification;
    }
  }

  private removeCustomerOfList(customersRemove: ContactsCustomer[]): void {
    for (const customerRemove of customersRemove) {
      const index = this.customers.findIndex(c => c.uid === customerRemove.uid)
      if (this.customers === undefined || !this.customers?.length) {
        this.customers = []
      } else {
        this.customers.splice(index, 1);
      }

    }
  }

  loadingFields(field: string, value: boolean): void {
    if (field.includes('Today')) {
      this.isLoadingToday = value;
    } else if (field.includes('Yesterday')) {
      this.isLoadingYesterday = value;
    } else if (field.includes('Otherdays')) {
      this.isLoadingOtherdays = value;
    }
  }

  async loadMoreItems(): Promise<void> {
    this.runLoadMore = true
    this.loadedItemsCount = this.loadedItemsCount + this.itemsPerPage;
    this.loadCustomersWithContacts(this.selectedMenuItem?.state);
  }

  // No seu componente TypeScript
  trackByFn(index: number, item: any) {
    return item.id; // Use uma propriedade única do item, como um ID, se disponível
  }

  loadFilters(): void {
    const cnpjValue = this.cnpjInput.nativeElement.value;
    const companyValue = this.companyInput.nativeElement.value;
    const dateValue = this.dateInput.nativeElement.value;

    this.filters = [];
    this.filtersValues = [];

    if (this.isValidValue(cnpjValue)) {
      this.filters.push('cnpj');
      this.filtersValues.push(cnpjValue);
    }

    if (this.isValidValue(companyValue)) {
      this.filters.push('companyName');
      this.filtersValues.push(companyValue);
    }

    if (this.isValidValue(dateValue)) {
      this.filters.push('lastContact');
      this.filtersValues.push(dateValue);
    }
  }

  isValidValue(value: any): boolean {
    return value !== null && value !== undefined && String(value).trim() !== '';
  }

  openMenuContactSelected(event: MouseEvent, contact: ContactsCustomer): void {
    this.closeMenuContactSelected();
    event.preventDefault();
    const originalX = (event.currentTarget as HTMLElement).getBoundingClientRect().left;
    this.coords = { x: event.clientX - (originalX > 600 ? 300 : 0), y: event.clientY };
    this.isMenuOpen = true;
    this.companySelected = contact;
  }

  closeMenuContactSelected(): void {
    this.isMenuOpen = false;
    this.companySelected = null;
  }

  async markUnreadMessageMenuContactSelected(company: ContactsCustomer): Promise<void> {
    return this.chatService.updateLastMessageNotRead(company.whatsapp?.replace(/\D+/g, ''))
    .then(() => this.chatService.updateTotalUnreadMessagesOfCustomer(company.uid, 1))
    .then(() => console.log('Marcar contato selecionado como não lido'))
    .catch(error => console.error(`Erro ao unread do uid ${ company.uid }: ${ error }`))
    .finally(() => this.closeMenuContactSelected());
  }

  async markReadMessageMenuContactSelected(company: ContactsCustomer): Promise<void> {
    try {
      const contacts = await this.chatService.getContactsByCustomer(company.cnpj)
      const updateCdcMessageReadDatePromise = contacts.docs.map(async contact => await this.chatService.updateCdcMessageReadDate(contact.id))
      return Promise.all([
        ...updateCdcMessageReadDatePromise,
        this.chatService.updateTotalUnreadMessagesOfCustomer(company.uid, 0)])
        .then(res => console.log('Marcar contato selecionado como lido'))
        .catch(error => console.error(`Erro ao read do uid ${ company.uid }: ${ error }`))
        .finally(() => this.closeMenuContactSelected());
    } catch (error) {
      console.error(`Erro ao read do uid 2 ${ company.uid }: ${ error }`)
    }

  }

  async assignedForMe(company: ContactsCustomer): Promise<void> {
    const promises: Promise<void>[] = [];
    const attributedData = ChatAttributedEntity.generateChatAttributed(this.loggedUser, { atribuitedTo: this.loggedUser });
    promises.push(this.chatService.customerAttributedTo(company.uid, this.loggedUser.uid, this.loggedUser.name, false))
    promises.push(this.chatAtributtedService.addAttributedTo(company.uid, attributedData)
      .then(() => {
        return;
      }));

    await Promise.all(promises)
      .then(async () => await this.loadCustomersWithContacts(this.selectedMenuItem?.state))
      .catch(error => console.error(`Erro ao salvar atribuição do uid ${ company.uid }: ${ error }`))
      .finally(() => this.closeMenuContactSelected());
  }

  async removeAssigned(company: ContactsCustomer): Promise<void> {
    const promises: Promise<void>[] = [];
    const desatribuitedTo = {
      uid: company.attributedTo,
      name: company.attributedToName,
      email: '',
    }
    const attributedData = ChatAttributedEntity.generateChatAttributed(this.loggedUser, { desatribuitedTo })
    promises.push(this.chatService.customerAttributedTo(company.uid, null, null, false))
    promises.push(this.chatAtributtedService.addAttributedTo(company.uid, attributedData)
      .then(() => {
        return;
      }))


    await Promise.all(promises)
      .then(async () => await this.loadCustomersWithContacts(this.selectedMenuItem?.state))
      .catch(error => console.error(`Erro ao remover atribuição do uid ${ company.uid }: ${ error }`))
      .finally(() => this.closeMenuContactSelected());
  }

  blockRightClickMouse(event: MouseEvent): void {
    event.preventDefault();
    this.closeMenuContactSelected();
  }

  otherContactsFilter(customers: ContactsCustomer[]): ContactsCustomer[] {
    const today = new Date();

    return this.sortContacts(customers.filter(c => {
      const dataItem = timestampForDate(c?.lastContact) ?? null;
      if (today.getFullYear() === dataItem.getFullYear() &&
        today.getMonth() === dataItem.getMonth() &&
        today.getDate() === dataItem.getDate()) {
        return false
      } else {
        return true;
      }
    }));
  }


  todayContactsFilter(customers: ContactsCustomer[]): ContactsCustomer[] {
    const today = new Date();

    return this.sortContacts(customers.filter(c => {
      const dataItem = timestampForDate(c?.lastContact) ?? null;
      if (today.getFullYear() === dataItem.getFullYear() &&
        today.getMonth() === dataItem.getMonth() &&
        today.getDate() === dataItem.getDate()) {
        return true
      } else {
        return false;
      }
    }));
  }

  lastContactstring = (lastContact: ContactsCustomer['lastContact']): string => this.datePipe.transform(timestampForDate(lastContact), 'dd/MM/yyyy - HH:mm')

  private sortByLastContact(a: ContactsCustomer, b: ContactsCustomer): number {
    const dateA = DateUtilHandler.convertToDateType(a.lastContact)?.toISOString();
    const dateB = DateUtilHandler.convertToDateType(b.lastContact)?.toISOString();
    return dateA < dateB ? 1 : -1;
  }

  sortContacts(customers: ContactsCustomer[]): ContactsCustomer[] {
    return customers.sort(this.typeSorting === 'assigned' ? this.sortByAttributedToNotification : this.typeSorting === 'notifications' ? this.sortByUnReadMessages : this.sortByLastContact)

  }

  sortByUnReadMessages(a: ContactsCustomer, b: ContactsCustomer): number {
    if (a.totalUnreadMessages && !b.totalUnreadMessages) {
      return -1;
    } else if (!a.totalUnreadMessages && b.totalUnreadMessages) {
      return 1;
    } else {
      const dateA = DateUtilHandler.convertToDateType(a.lastContact)?.toISOString();
      const dateB = DateUtilHandler.convertToDateType(b.lastContact)?.toISOString();

      return dateA < dateB ? 1 : -1;
    }
  }

  sortByAttributedToNotification(a: ContactsCustomer, b: ContactsCustomer): number {
    if (a.attributedToNotification && !b.attributedToNotification) {
      return -1;
    } else if (!a.attributedToNotification && b.attributedToNotification) {
      return 1;
    } else {
      return a.lastContactStr < b.lastContactStr ? 1 : -1;
    }
  }

  async sortList(type: string): Promise<void> {
    if (this.typeSorting !== type) {
      this.typeSorting = type;
      //await this.updateFormatDateLastContactCustomer();
      await this.loadCustomersWithContacts(this.selectedMenuItem?.state);
    }

  }

  playNotification(customer: ContactsCustomer): void{
    if(customer.totalUnreadMessages > 0){
      this.notificationSound.play();
    }
  }

  showNotification(customer: any): void{
    if(customer.totalUnreadMessages > 0){
      document.title = `Você possui ${customer.totalUnreadMessages} mensagens não lidas`;
    }else {
      document.title = 'Capital Empreendedor';
    }
  }
}
