/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnInit } from '@angular/core';
import { Banker } from '../../../../../functions/src/models/Banker';

@Component({
  selector: 'app-banker-settings',
  templateUrl: './banker-settings.component.html',
  styleUrls: ['./banker-settings.component.scss']
})
export class BankerSettingsComponent implements OnInit {
  @Input() banker: Banker = null;

  constructor() { }

  ngOnInit(): void {
  }
}
