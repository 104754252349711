import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import firebase from 'firebase/app';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { LogService } from 'src/app/components/logger/log.service';
import { User } from '../../../../functions/src/models/User';
import { AuthService } from '../../core/auth/auth.service';
import { GrupoInstituicaoFinanceira, InstituicaoFinanceira } from './grupo-instituicao-financeira';
import { GrupoInstituicaoFinanceiraService } from './grupo-instituicao-financeira.service';

@Component({
  selector: 'app-grupo-instituicao-financeira',
  templateUrl: './grupo-instituicao-financeira.component.html',
  styleUrls: ['./grupo-instituicao-financeira.component.scss'],
})
export class GrupoInstituicaoFinanceiraComponent implements OnInit, OnDestroy {
  isDetails = false;
  tituloDetalheGrupo: string;
  editFormGroup: FormGroup;

  columnsGrupos: string[] = ['nome', 'prioridade', 'acoes'];
  dataSourceGrupos: MatTableDataSource<GrupoInstituicaoFinanceira>;
  dataTableGrupos: GrupoInstituicaoFinanceira[];
  @ViewChild('matSortGrupos', { static: false }) matSortGrupos: MatSort;
  @ViewChild('paginatorGrupos', { static: false }) matPaginatorGrupos: MatPaginator;

  columnsInstituicoesFinanceiras: string[] = ['nome', 'tipo', 'acoes'];
  dataSourceInstituicoesFinanceiras: MatTableDataSource<InstituicaoFinanceira>;
  dataTableInstituicoesFinanceiras: InstituicaoFinanceira[];
  @ViewChild('matInstituicoesFinanceiras', { static: false }) matSortInstituicoesFinanceiras: MatSort;
  @ViewChild('paginatorInstituicoesFinanceiras', { static: false }) matPaginatorInstituicoesFinanceiras: MatPaginator;

  instituicoesFinanceirasAtivas: InstituicaoFinanceira[];
  grupoInstituicoesFinanceirasData: GrupoInstituicaoFinanceira;

  @ViewChild('instFinancAtivosSelecionados', { static: false }) instFinancAtivosSelecionados;

  getGruposIFSubscription: Subscription;
  getGrupoIFSubsctiption: Subscription;
  getIFSubscription: Subscription;
  loggedUserSubscription: Subscription;
  loggedUser: User;

  constructor(private dialog: MatDialog, private _data: GrupoInstituicaoFinanceiraService, private logger: LogService, private authService: AuthService) {
    this.logger.controllerName = this.constructor.name;
    this.isDetails = false;
    this.tituloDetalheGrupo = '';
  }

  ngOnInit(): void {
    this.getGruposIFSubscription = this._data.getGruposInstituicoesFinanceiras().subscribe((result) => {
      this.dataTableGrupos = result;
      this.dataSourceGrupos = new MatTableDataSource(this.dataTableGrupos);
      this.dataSourceGrupos.sort = this.matSortGrupos;
      this.dataSourceGrupos.paginator = this.matPaginatorGrupos;
    });

    this.loggedUserSubscription = this.authService.user.subscribe((userLogged) => {
      this.loggedUser = userLogged;
      if (['master', 'admin'].includes(this.loggedUser.accessLevel)) {
        console.log('User Admin have permision by create and edit');
      } else {
        this.editFormGroup.disable();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.getGruposIFSubscription) {
      this.getGruposIFSubscription.unsubscribe();
    }
    if (this.getGrupoIFSubsctiption) {
      this.getGrupoIFSubsctiption.unsubscribe();
    }
    if (this.getIFSubscription) {
      this.getIFSubscription.unsubscribe();
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceGrupos.filter = filterValue;
  }

  onEditGrupo(grupoInstituicaoFinanceiraId: string) {
    this.editFormGroup = new FormGroup({
      nomeCtrl: new FormControl('', Validators.required),
      prioridadeCtrl: new FormControl('', Validators.required),
      ativoCtrl: new FormControl('', Validators.required),
    });
    this.isDetails = true;
    this.getGrupoIFSubsctiption = this._data
      .getGrupoInstituicaoFinanceira(grupoInstituicaoFinanceiraId)
      .subscribe((grupo) => {
        grupo.id = grupoInstituicaoFinanceiraId;
        this.grupoInstituicoesFinanceirasData = grupo;
        this.tituloDetalheGrupo = ' - ' + this.grupoInstituicoesFinanceirasData.nome;
        this.getIFSubscription = this._data.getInstituicoesFinanceiras().subscribe((instFinac) => {
          this.instituicoesFinanceirasAtivas = instFinac.filter((item) => {
            if (this.grupoInstituicoesFinanceirasData.instituicoesFinanceiras) {
              const iFinanc = this.grupoInstituicoesFinanceirasData.instituicoesFinanceiras.find(
                (f) => f.id === item.id
              );
              if (!iFinanc) {
                return item;
              }
            } else {
              return item;
            }
          });
        });
        this.dataSourceInstituicoesFinanceiras = new MatTableDataSource(
          this.grupoInstituicoesFinanceirasData.instituicoesFinanceiras
        );
        this.dataSourceInstituicoesFinanceiras.sort = this.matSortInstituicoesFinanceiras;
        this.dataSourceInstituicoesFinanceiras.paginator = this.matPaginatorInstituicoesFinanceiras;

        this.editFormGroup.get('nomeCtrl').setValue(this.grupoInstituicoesFinanceirasData.nome);
        this.editFormGroup.get('prioridadeCtrl').setValue(this.grupoInstituicoesFinanceirasData.prioridade);
        this.editFormGroup.get('ativoCtrl').setValue(this.grupoInstituicoesFinanceirasData.ativo);
      });
  }

  onSaveForm() {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Adicionar Instituição Financeira',
          alertDescription: 'Deseja realmente associar essas instituições financeiras ao grupo?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.grupoInstituicoesFinanceirasData.nome = this.editFormGroup.controls.nomeCtrl.value;
          this.grupoInstituicoesFinanceirasData.prioridade = this.editFormGroup.controls.prioridadeCtrl.value;
          this.grupoInstituicoesFinanceirasData.ativo = this.editFormGroup.controls.ativoCtrl.value;
          this._data.updateGrupoInstituicaoFinanceira(this.grupoInstituicoesFinanceirasData);
        }
      });
  }

  onBackToList() {
    this.isDetails = false;
    this.tituloDetalheGrupo = '';
  }

  onAddNewInstFinanc() {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Adicionar Instituição Financeira',
          alertDescription: 'Deseja realmente associar essas instituições financeiras ao grupo?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (!this.grupoInstituicoesFinanceirasData.instituicoesFinanceiras) {
            this.grupoInstituicoesFinanceirasData.instituicoesFinanceiras = [];
          }
          this.instFinancAtivosSelecionados.selectedOptions.selected.forEach((item) => {
            const instituicaoFinanceira: InstituicaoFinanceira = {
              id: item.value.id,
              nome: item.value.nome,
              tipo: item.value.tipo,
              dataInclusao: firebase.firestore.Timestamp.now(),
            };
            this.grupoInstituicoesFinanceirasData.instituicoesFinanceiras.push(instituicaoFinanceira);
          });
          this._data.updateGrupoInstituicaoFinanceira(this.grupoInstituicoesFinanceirasData);
        }
      });
  }

  onRemoverInstFinanc(index) {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Remover instituição financeira',
          alertDescription: `Deseja realmente remover essa instituição financeira desse grupo?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.grupoInstituicoesFinanceirasData.instituicoesFinanceiras.splice(index, 1);
          this._data.updateGrupoInstituicaoFinanceira(this.grupoInstituicoesFinanceirasData);
        }
      });
  }
}
