<mat-form-field class="w-100">
  <mat-label>{{ fieldName }}</mat-label>
  <mat-select
    [multiple]="multiple"
    [required]="required"
    [formControl]="formControl"
    [compareWith]="compareObjectsById"
    (selectionChange)="onChangeSelect($event)"
  >
    <mat-option *ngFor="let option of options" [value]="option">
      {{ option?.value || option?.name }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="formControl?.hasError('required')">
    <span *ngIf="['garantiasCtrl', 'guaranteesCtrl'].includes(controlName)"> Informe as garantias disponíveis. </span>
  </mat-error>
</mat-form-field>
