<div class="user-credit-lines-container">
  <div class="inner-container">
    <div class="content-wrapper">
      <div class="main-content">
        <div class="user-credit-lines-content">
          <h1 class="mb-4">Ofertas de crédito</h1>

          <p *ngIf="isCustomerNoTouch">
            Nesta página você receberá suas <b>Ofertas de crédito</b> e poderá <b>escolher a ideal</b> para sua empresa.
            Ao dar andamento em uma oferta, a instituição parceira pode entrar em contato para prosseguir com maior
            agilidade
          </p>

          <p *ngIf="!isCustomerNoTouch">
            Nesta página você receberá suas <span style="color: #FF7E1D;"><strong>ofertas de crédito</strong></span> e
            poderá
            <span style="color: #FF7E1D;"><strong>escolher a ideal para sua empresa!</strong></span>
            Para isso, carregue seus documentos pendentes na aba <a [routerLink]="['/documentacao']"
              style="color: #FF7E1D;"><strong>Meus
                Documentos</strong></a>.
            Quanto antes você enviar, maiores suas chances de obter <b> o empréstimo mais barato e mais
              rápido!</b>
          </p>
          <div class="div-healp">
            <a *ngIf="!enableLoading && hasOppsByRecurrence" (click)="openDialog()" mat-flat-button color="primary"
              class="action-button p-l-2 p-r-2 ml-0 ml-sm-3" type="button">
              Peça Crédito Novamente!
            </a>
          </div>
          <div class="text-center" *ngIf="enableLoading">
            <mat-spinner diameter="60" class="mx-auto"></mat-spinner>
          </div>
        </div>

        <div class="example-card-container">
          <mat-card class="example-card">
            <mat-card-content>
              <mat-card-title>Meu pedido</mat-card-title>
              <div>
                <p>Valor pedido: {{ customer?.creditValueStr.slice(0, -3) }}</p>
                <p class="guarantees-field">Garantias: {{ guarantees }}</p>
              </div>
              <div class="example-card-btn">
                <span>Tem dúvidas?</span>
                <button type="button" routerLink="/mensagens"> Fale conosco </button>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="bndesProposal" class="card-score">
            Meu score de crédito: <span> {{bndesProposal?.creditScore}}
              <mat-icon (mouseover)="isScoreHintVisible = true"
                (mouseout)="isScoreHintVisible = false">contact_support</mat-icon></span>
            <mat-hint *ngIf="isScoreHintVisible" class="scoreHint">Score é uma pontuação para avaliar
              o histórico financeiro do consumidor. Quanto maior a pontuação, melhor a avaliação de
              crédito do consumidor</mat-hint>
          </mat-card>
        </div>

      </div>

      <div *ngIf="!enableLoading">
        <div class=" d-flex content">
          <p>
            Precisa de ajuda?
          </p>
          <div class="button-ajuste">
            <button style="width: 141px;" class="button-custom" routerLink="/mensagens">Fale conosco</button>
            <button style="width: 201px;" class="button-custom">
              <a href="https://www.capitalempreendedor.com.br/faq" target="_blank" style=" color: #008348;">Perguntas
                frequentes</a>
            </button>
          </div>
          <button class="button-planilha" (click)="exportOpportunities()">Exportar ofertas para Planilha</button>
        </div>
        <mat-tab-group>
          <mat-tab label="Linhas atuais">
            <div class="opportunity-cards">
              <div class="row">
                <div class="col-12 col-md-4" *ngFor="let operacao of opportunities">
                  <div class="custom-card" [class]="getCardBorderColorClass(operacao)">
                    <div class="card-header">
                      <ng-container
                        *ngIf="operationToCustomerVisibleCondition(operacao) && operacao.institutionName === 'Pronampe - Capital Empreendedor' || operacao.institutionName === 'FGI PEAC - Capital Empreendedor'">
                        <img src="{{ operacao?.logoPath }}" style="height: 120px;">
                      </ng-container>
                      <div class="d-flex align-items-center"
                        style=" justify-content: space-between; color: #00C46C; height: 30px; margin: 10px;">
                        <ng-container
                          *ngIf="operationToCustomerVisibleCondition(operacao) && operacao.institutionName != 'Pronampe - Capital Empreendedor' && operacao.institutionName != 'FGI PEAC - Capital Empreendedor'">
                          <img src="{{ operacao?.logoPath }}" alt="">
                        </ng-container>
                        <ng-container *ngIf="iconCheck(operacao)">
                          <img src="../../../assets/images/icons/check.png" alt="" style="margin-left: auto;">
                        </ng-container>
                        <ng-container *ngIf="iconX(operacao)">
                          <img src="../../../assets/images/icons/x.png" alt="" style="margin-left: auto;">
                        </ng-container>
                        <ng-container *ngIf="!iconCheck(operacao) && !iconX(operacao) ">

                        </ng-container>
                      </div>

                      <h3
                        *ngIf="operacao.institutionName != 'Pronampe - Capital Empreendedor' && operacao.institutionName != 'FGI PEAC - Capital Empreendedor' " style="margin-top: 10px;">
                        {{ operationToCustomerVisibleText(operacao) }}</h3>

                      <ng-container *ngIf="operacao.institutionName === 'Pronampe - Capital Empreendedor'"
                        style="margin-top: 15px;">
                        <p>Prazo: Até 48 meses</p>
                        <p>Taxa: Selic +6% a.a</p>
                        <p>Limite: Até R$150.000</p>
                      </ng-container>


                      <ng-container *ngIf="operacao.institutionName === 'FGI PEAC - Capital Empreendedor'"
                        style="margin-top: 15px;">
                        <p>Prazo: Até 60 meses</p>
                        <p>Taxa: 1,59% ao mês Pré-fixado*</p>
                        <p>Limite: Até R$5.000.000</p>
                      </ng-container>
                      <ng-container *ngIf="operacao.institutionName === 'FGI PEAC - Capital Empreendedor'">
                        <p style="font-size: 10px;">*Sujeito ao Score Crédito</p>
                      </ng-container>

                    </div>
                    <ng-container
                      *ngIf="(operacao.institutionName === 'Pronampe - Capital Empreendedor' && operacao.stageOpportunity.defaultprobability != 40 && operacao.stageOpportunity.defaultprobability != 60 || operacao.institutionName != 'Pronampe - Capital Empreendedor') && (operacao.institutionName === 'FGI PEAC - Capital Empreendedor' && operacao.stageOpportunity.defaultprobability != 40 && operacao.stageOpportunity.defaultprobability != 60 || operacao.institutionName != 'FGI PEAC - Capital Empreendedor')">
                      <div class="card-content" *ngIf="!isExpired(operacao)">
                        <h4 [class.waiting-for-response]="isWaitingForCustomerResponse(operacao)" *ngIf="operacao.stageSituation != 'Perfil não enquadrado'">{{
                          operacao?.stageSituation }}</h4>
                        <button *ngIf="showDetailsButton(operacao)" mat-raised-button type="button"
                          class="button-detalhe" (click)="onOpenProposalDialog(operacao)">
                          Ver detalhes</button>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="operacao.institutionName === 'Pronampe - Capital Empreendedor' && operacao.stageOpportunity.defaultprobability === 40 || operacao.institutionName === 'Pronampe - Capital Empreendedor' && operacao.stageOpportunity.defaultprobability === 60 || operacao.institutionName === 'FGI PEAC - Capital Empreendedor' && operacao.stageOpportunity.defaultprobability === 40 || operacao.institutionName === 'FGI PEAC - Capital Empreendedor' && operacao.stageOpportunity.defaultprobability === 60 ">
                      <button *ngIf="showDetailsButton(operacao)" mat-raised-button type="button" class="button-detalhe"
                        (click)="documentsDialog(operacao)">
                        Carregar Documentos</button>
                    </ng-container>

                    <div *ngIf="isExpired(operacao)" class="card-content">

                      <div>
                        <h4 style="color: #F93232;">Proposta expirada</h4>
                      </div>

                      <div>
                        <button *ngIf="showDetailsButton(operacao)" mat-raised-button type="button"
                          class="button-detalhe" (click)="onOpenProposalDialog(operacao)">
                          Ver detalhes</button>
                      </div>

                      <ng-container>

                      </ng-container>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngFor="let group of opportunityHistoryGroups">
            <ng-template mat-tab-label>
              {{ group.label }}
            </ng-template>

            <table mat-table [dataSource]="group.dataSource" class="table-generic-styles">

              <ng-container matColumnDef="img">
                <th mat-header-cell *matHeaderCellDef>#</th>
                <td mat-cell *matCellDef="let history">
                  <div *ngIf="getLogoPath(history)">
                    <img [src]="getLogoPath(history)" width="50px" />
                  </div>
                  <div *ngIf="!getLogoPath(history)">
                    <mat-icon>account_balance</mat-icon>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="institutionName">
                <th mat-header-cell *matHeaderCellDef>Instituição</th>
                <td mat-cell *matCellDef="let history">{{ history.institutionName }}</td>
              </ng-container>

              <ng-container matColumnDef="deadlineForMonth">
                <th mat-header-cell *matHeaderCellDef>Prazo em meses</th>
                <td mat-cell *matCellDef="let history">{{ history.deadlineForMonth }}</td>
              </ng-container>

              <ng-container matColumnDef="limitOperation">
                <th mat-header-cell *matHeaderCellDef>Valor do Crédito</th>
                <td mat-cell *matCellDef="let history">{{ history.limitOperation | currency: 'BRL':true:'1.2-2' }}</td>
              </ng-container>

              <ng-container matColumnDef="rate">
                <th mat-header-cell *matHeaderCellDef>Taxa</th>
                <td mat-cell *matCellDef="let history">{{ history.rate }} %</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="opportunityHistoryColumns"></tr>

              <tr mat-row *matRowDef="let row; columns: opportunityHistoryColumns"></tr>
            </table>
          </mat-tab>
        </mat-tab-group>
        <div class="cta-documents" *ngIf="!isCustomerNoTouch">
          <p>Termine de enviar seus documentos para receber mais propostas!</p>
          <button><a href="/documentacao">Enviar documentos</a></button>
        </div>
      </div>
    </div>
  </div>
</div>