import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CadastrosGeraisService } from '../../admin/cadastros-gerais/cadastros-gerais.service';
import { CustomerService } from '../../customer/services/customer.service';
import { DialogConfirmationOnboardingComponent } from '../dialog-confirmation-onboarding/dialog-confirmation-onboarding.component';

@Component({
  selector: 'app-anticipation-form-dialog',
  templateUrl: './anticipation-form-dialog.component.html',
  styleUrls: ['./anticipation-form-dialog.component.scss']
})
export class AnticipationFormDialogComponent implements OnInit {
  hasAnticipationCard: boolean;
  config: any;
  firstStep = true;
  secondStep: boolean;

  getCardMachinesSubscription: Subscription;
  subscriptions: Subscription;

  anticipationFormGroup: FormGroup;

  constructor(private router: Router, private formBuilder: FormBuilder, private cadastrosGeraisService: CadastrosGeraisService, private customerService: CustomerService, private dialogRef: MatDialogRef<DialogConfirmationOnboardingComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.subscriptions = new Subscription();
   }

  ngOnInit(): void {
    this.subscriptions.add(
      this.customerService.getSignUpConfiguration().subscribe((config) => {
        this.config = config;
        this.config.minRevenue = config?.minRevenue ?? 360000;
        this.config.minRevenueStr = config?.minRevenueStr ?? 'R$ 360.000';
      })
    );

    this.cadastrosGeraisService.getMaquinasAtivas().subscribe((maquinas) => {
      maquinas.map((maquina) => {
        maquina['id'] = maquina.mnemonico;
        maquina['name'] = maquina.nome;
        delete maquina.ativo;
        delete maquina.mnemonico;
        delete maquina.nome;
      });
      this.config.cardMachines = maquinas;
    });
    this.initializeForm();
  }

  initializeForm(): void{
    this.anticipationFormGroup = this.formBuilder.group({
      creditCardCtrl: [[]]
    })
  }

  setAnticipationCardOption(option: string): void{
    if(option === 'close'){
      this.dialogRef.close(null);
    } else if(option === 'selectAnticipationCard'){
      this.firstStep = false;
      this.secondStep = true;
    } else if(option === 'anticipationSelected'){
      this.secondStep = false;
      this.dialogRef.close(this.anticipationFormGroup.value);
    }
  }

}
