<div *ngIf="user" class="fix-botton border-top w-100" style="background-color: white">
  <div class="d-flex pt-2 pb-2 w-100 inner-container" style="font-size: 12px; justify-content: space-between">
    <p class="col-9 ml-2">
      Este website armazena cookies no seu computador. Esses cookies são usados ​​para melhorar sua experiência no site
      e fornecer serviços personalizados para você, tanto no website, quanto em outras mídias. Para saber mais sobre os
      cookies que usamos, consulte nossa
      <a class="link" [href]="cookiesPolicyUrl" target="_blank">Política de Cookie</a> e nossa
      <a class="link" [href]="privacyPolicyUrl" target="_blank">Política de Privacidade</a>. Não rastrearemos suas
      informações quando você visitar nosso site, porém, para cumprir suas preferências, precisaremos usar apenas um
      pequeno cookie, para que você não seja solicitado a tomar essa decisão novamente.
    </p>
    <div
      class="col-3 btn-container"
      style="display: flex; flex-direction: row; align-self: center; justify-content: flex-end; align-self: center"
    >
      <button (click)="signCookies(false)" mat-stroked-button color="primary" class="btn col-5 mr-2" type="button">
        <span> Recusar</span>
      </button>
      <button
        (click)="signCookies(true)"
        mat-flat-button
        color="primary"
        class="btn col-5"
        style="color: white; margin-right: 16px"
        type="button"
      >
        <span> Aceitar </span>
      </button>
    </div>
  </div>
</div>
