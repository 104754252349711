import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Banker } from 'functions/src/models/Banker';
import { Subscription } from 'rxjs';
import { bankersSidenavAllowedURLs, breakpoints, environmentLabels } from 'src/app/constants';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment as env } from 'src/environments/environment';
import { SidebarOption } from '../../models/sidebar-options';
import { BankerService } from '../../services/banker.service';

@Component({
  selector: 'app-template-bankers',
  templateUrl: './template-bankers.component.html',
  styleUrls: ['./template-bankers.component.scss'],
})
export class TemplateBankersComponent implements OnInit, OnDestroy {
  readonly urlPrivacyPolicy =
    'https://f.hubspotusercontent10.net/hubfs/8256969/Institucional/Pol%C3%ADtica%20de%20Privacidade%20-%20Capital%20Empreendedor.pdf';

  isAuthenticatedAndApproved = false;

  isProd = env.envName === 'PROD';
  envName = env.envName;
  envLabel = environmentLabels[env.envName] || environmentLabels['DEV:LOCAL'];
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = 'assets/images/capital-white-xs.png';
  logoFull = 'assets/images/new-capital-white.png';

  mobileQuery: MediaQueryList;
  toggled = false;
  expanded = true;
  visible = false;

  isLoading = true;

  menuOptions: Array<SidebarOption> = [
    // {name: 'Dashboard', img: "../../../../assets/images/sidebar-icons/dashboard.png", state: "/bankers/profile"},
    {name: 'Oportunidades Ativas', img: '../../../../assets/images/sidebar-icons/trending-up.png', state: '/bankers/opportunities'},
    {name: 'Oportunidades Ganhas', img: '../../../../assets/images/sidebar-icons/award.png', state: '/bankers/concluded'},
    {name: 'Minha conta', img: '../../../../assets/images/sidebar-icons/user.png', state: '/bankers/profile'},
  ];

  bankerSubscription: Subscription;
  banker: Banker;

  constructor(
    private authService: AuthService,
    private bankerService: BankerService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    // Listener p/ resize de tela -> Ajuste da sidebar
    this.mobileQuery = media.matchMedia(`(max-width: ${breakpoints.lg}px)`);
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.toggled = !this.mobileQuery.matches;
  }

  ngOnInit(): void {
    this.bankerSubscription = this.bankerService.banker.subscribe((banker) => {
      this.banker = banker;
      this.isAuthenticatedAndApproved = !!banker && !banker.isAnonymous && banker.isActive;
      this.visible = this.shouldSidenavBeVisible();
      this.isLoading = !banker;
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    if (this.bankerSubscription) {
      this.bankerSubscription.unsubscribe();
    }
  }

  private _mobileQueryListener(): void  {
    return this.changeDetectorRef?.detectChanges();
  }

  shouldSidenavBeVisible(): boolean {
    return (
      this.isAuthenticatedAndApproved &&
      bankersSidenavAllowedURLs.map((u) => this.router.url.indexOf(u) >= 0).reduce((r, u) => r || u, false)
    );
  }

  toggle(): void {
    // Sidebar toggle
    if (this.mobileQuery.matches) {
      this.toggled = !this.toggled;
      this.expanded = true;
    } else {
      this.toggled = true;
      this.expanded = !this.expanded;
    }
  }

  onLoginClick(): void {
    this.router.navigate(['/bankers/login']);
  }

  onLogoutClick(): void {
    this.authService.logout().finally(() => {
      this.router.navigate(['/entrar']);
    });
  }
}
