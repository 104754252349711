<div class="modal">
  <div class="container">
    <div class="logo-container">
      <img alt="logo" class="logo"
        src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2FAF_ImagemPage_BNDES02%201.png?alt=media&token=c45d9c44-b80b-4ddb-ba2b-da0ea0d7098e" />
      <span class="logo-titulo">CRÉDITO PARA SUA EMPRESA!</span>
      <span class="logo-label">Peça já seu crédito para sua empresa e desfrute de nossa ajuda.</span>
    </div>
    <div class="form">
      <div class="form-container">
        <section *ngIf="step === 'company'">
          <span class="step-label">ETAPA 1 de 3: Sobre a empresa</span>
          <h1>Sobre sua empresa</h1>
          <p>Para começar sua análise, precisamos das seguintes informações <br> sobre sua empresa:</p>
          <div class="form-columns">
            <div class="column">
              <form name="companyForm" [formGroup]="companyDataFormGroup">
                <div class="d-flex">
                  <mat-icon fontSet="fab" fontIcon="fa-whatsapp" class="colored-icon"></mat-icon>
                  <mat-form-field color="primary">
                    <mat-label>WhatsApp</mat-label>
                    <input matInput class="form-input" placeholder="WhatsApp" name="whatsapp" required
                      formControlName="whatsappCtrl" [textMask]="{ mask: formatPhone, guide: false }" />
                  </mat-form-field>
                </div>
                <!-- <div class="d-flex">
                  <mat-icon fontSet="fas" fontIcon="fa-globe" class="colored-icon"></mat-icon>
                  <mat-form-field color="primary">
                    <mat-label>Website da empresa</mat-label>
                    <input matInput class="form-input" placeholder="Website" name="website"
                      formControlName="websiteCtrl" />
                  </mat-form-field>
                </div> -->
                <div class="d-flex">
                  <mat-icon fontSet="fas" fontIcon="fa-wallet" class="colored-icon"></mat-icon>
                  <mat-form-field color="primary">
                    <mat-label>Faturamento Anual</mat-label>
                    <input matInput class="form-input" placeholder="Faturamento Anual" name="revenue" matTooltip="Soma do faturamento dos últimos 12 meses “não colocar os centavos”
                            Exemplo - 120.000,00 = 120.000." required formControlName="revenueCtrl"
                      [textMask]="{ mask: numberMask }" (change)="revenueValidation()" />
                    <mat-hint>Desconsidere os centavos</mat-hint>
                  </mat-form-field>
                </div>
              </form>
            </div>
            <div class="column">
              <form name="financialForm" [formGroup]="financialDataFormGroup">
                <div class="d-flex" style="margin-top: 5px;">
                  <mat-icon fontSet="fas" fontIcon="fa-file-invoice-dollar" class="colored-icon"></mat-icon>
                  <mat-form-field color="primary">
                    <mat-label>Dívida Atual</mat-label>
                    <input matInput class="form-input" placeholder="Dívida Atual"
                      matTooltip="Soma de todas as dívidas (despesas, limites de crédito, capital de giro e etc)."
                      name="debt" formControlName="debtCtrl" [textMask]="{ mask: numberMask }" />
                    <mat-hint>Desconsidere os centavos</mat-hint>
                  </mat-form-field>
                </div>
                <div class="d-flex" style="margin-top: 5px;">
                  <mat-icon fontSet="fas" fontIcon="fa-university" class="colored-icon"></mat-icon>
                  <mat-form-field color="accent" class="w-100">
                    <mat-label>Regime Tributário</mat-label>
                    <mat-select placeholder="Regime Tributário" formControlName="taxRegimeCtrl" matTooltip="Para identificar seu regime tributário, verifique com seu contador.
                      Caso não tenha contador seu regime tributário é simples nacional." name="taxRegime"
                      (selectionChange)="onChangetaxRegime($event)" required>
                      <mat-option *ngFor="let taxReg of config?.taxRegimes" [value]="taxReg">
                        {{ taxReg.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </form>
            </div>
          </div>
          <div class="d-flex">
            <div class="text-left" style="margin-top: 20px;">
              <button mat-raised-button color="primary" style="color: white;" class="action-button" type="button"
                (click)="this.step = 'financial'" [class.disabled]="isValidForm()" [disabled]="isValidForm()"
                *ngIf="!validateParams">
                Próximo
              </button>
            </div>
          </div>
        </section>
        <section *ngIf="step === 'financial'">
          <span class="step-label">ETAPA 2 de 3: situação de crédito</span>
          <h1>Pedido de crédito</h1>
          <p>Para começar sua análise, precisamos das seguintes informações <br> sobre sua empresa:</p>
          <div class="form-columns">
            <div class="column">
              <form name="creditDataForm" [formGroup]="creditDataFormGroup">
                <div class="d-flex">
                  <mat-icon fontSet="fas" fontIcon="fa-comment-dollar" class="colored-icon"></mat-icon>
                  <mat-form-field color="primary">
                    <mat-label>Quanto busca de capital?</mat-label>
                    <input matInput placeholder="Quanto busca de capital?" name="credit-value" required
                      formControlName="creditValueCtrl" [textMask]="{ mask: numberMask }" />
                    <mat-hint>Desconsidere os centavos</mat-hint>
                    <mat-error *ngIf="creditDataFormGroup.get('creditValueCtrl')?.hasError('required')">O valor de
                      capital é
                      obrigatório</mat-error>
                  </mat-form-field>
                </div>
                <div class="d-flex">
                  <mat-icon fontSet="fas" fontIcon="fa-bullseye" class="colored-icon"></mat-icon>
                  <div class="flex-fill mp-2">
                    <app-form-field-select fieldName="Finalidade" controlName="purposeCtrl"
                      [formGroup]="creditDataFormGroup" [options]="config?.purposes" [compareWith]="true"
                      [required]="true"></app-form-field-select>
                  </div>
                </div>
                <div class="d-flex">
                  <mat-icon fontSet="fas" fontIcon="fa-hand-holding-usd" class="colored-icon"></mat-icon>
                  <div class="flex-fill">
                    <app-form-field-select fieldName="Garantias disponíveis" controlName="guaranteesCtrl"
                      (selectionChange)="onChangeGuarantees($event)" [formGroup]="creditDataFormGroup" matTooltip="
                    -Recebíveis: É uma linha de crédito para receber antecipadamente suas vendas realizadas a prazo através
                    de cartão de crédito, boleto e/ou nota fiscal.

                    -Imóvel operacional: Sede da empresa, galpão, fábrica, salas comerciais e etc.

                    -Imóvel Não-Operacional: Residencial.

                    -Aval: Sem garantia real, nessa linha o sócio é o garantidor." [options]="config?.guarantees"
                      [compareWith]="true" [multiple]="true" [required]="true"></app-form-field-select>
                  </div>
                </div>
              </form>
            </div>
            <div class="column">
              <form name="creditDataForm" [formGroup]="creditDataFormGroup">
                <div class="d-flex" style="margin-top: 5px;">
                  <app-form-field-select *ngIf="hasTypeOfAnticipation" fieldName="Tipo de Recebíveis" matTooltip="
                Cartão: Linha de crédito para vendas realizadas a prazo (cartão de crédito) através de maquininhas de cartão.
                Boleto: Linha de crédito para vendas realizadas a prazo por boleto.
                Notas fiscais: Linha de crédito para vendas realizadas a prazo por nota fiscal de venda."
                    controlName="typeOfAnticipationCtrl" (selectionChange)="onChangeAntecipation($event)"
                    [formGroup]="creditDataFormGroup" [options]="selectTypeOfAnticipationOperator" [multiple]="true"
                    [required]="hasTypeOfAnticipation"></app-form-field-select>
                </div>
                <div *ngIf="hasTypeOfAnticipationCard || hasTypeOfInvoices">
                  <div class="d-flex" style="margin-top: 5px;" *ngIf="hasTypeOfAnticipationCard">
                    <app-form-field-select fieldName="Maquininha de cartão" controlName="creditCardCtrl"
                      [formGroup]="creditDataFormGroup" [options]="config?.cardMachines" [multiple]="true"
                      [required]="hasTypeOfAnticipationCard"></app-form-field-select>
                  </div>
                  <div class="d-flex" style="margin-top: 5px;" *ngIf="hasTypeOfInvoices">
                    <app-form-field-select fieldName="Tipo de Nota Fiscal" controlName="typeOfInvoicesCtrl"
                      [formGroup]="creditDataFormGroup" [options]="selectTypeOfInvoices" [multiple]="true"
                      [required]="hasTypeOfInvoices"></app-form-field-select>
                  </div>
                </div>
                <div [class.d-none]="hasOnlyAvalGuarantee" [class.field]="!hasOnlyAvalGuarantee">
                  <div class="d-flex" style="margin-top: 5px;">
                    <mat-icon fontSet="fas" fontIcon="fa-coins" class="colored-icon"></mat-icon>
                    <mat-form-field color="accent" class="w-100">
                      <mat-label>Valor Total das Garantias</mat-label>
                      <input matInput placeholder="Valor Total das Garantias" [textMask]="{ mask: numberMask }"
                        formControlName="guaranteesValueCtrl" name="guarantees-value"
                         />
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="d-flex">

            <div class="text-left" style="margin-top: 20px;">
              <button button mat-raised-button color="primary" style="color: white;" class="action-button" type="button"
                (click)="this.step = 'documents'" [class.disabled]="isValid()" [disabled]="isValid()">
                Próximo
              </button>
            </div>
          </div>
        </section>
        <section *ngIf="step === 'documents'">
          <div class="form-txt">

            <span class="step-label">ETAPA 3 de 3: atualização de documentos</span>
            <h1>Atualização de documentos</h1>
            <p>Para finalizarmos sua solicitação, precisamos que atualize alguns <br>
              documentos que podem estar obsoletos desde sua última<br> tomada de crédito. <br>
              Por favor atualize os seguintes documentos na aba “Documentação”:</p>
            <br>

            <h3>Documentos da empresa:</h3>
            <p>•Extrato bancário dos últimos 180 dias <br>
              •Faturamento dos Últimos 24 meses <br>
              •Comprovante de endereço emitido nos últimos 90 dias <br>
              •Contrato social atualizado (em caso de alteração) <br> </p>
            <br>

            <h3>Documentos dos sócios:</h3>
            <p>•Comprovante de endereço emitido nos últimos 90 dias <br>
              •IRPF (declaração + recibo) <br>
              •RG + CPF ou CNH (em caso de acrescimo ou alteração dos sócios) <br></p>

            <div class="text-left" style="margin-top: 20px;">
              <button mat-raised-button color="primary" style="color: white;" class="action-button" type="button"
                (click)="updateCustomer()" *ngIf="!runReprocesseRecurrence" [disabled]="validateParams">
                Finalizar
              </button>
              <mat-spinner diameter='20' *ngIf="runReprocesseRecurrence"></mat-spinner>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
