<div class="p-4 agent-signup-page">
  <div class="agent-signup-form">
    <form [formGroup]="createAgentFormGroup">
      <div class="text-center">
        <img alt="logo" src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fnewlogo.svg?alt=media&token=670d09c9-9b73-453f-9598-cce88c9486e6" class="mt-4 mb-3" />
        <h3 class="my-3">Assessores - Cadastro</h3>
      </div>
      <div class="text-center message">
        <h5>Atenção!</h5>
        <span class="message-text"
          >Esta tela de cadastro é apenas para ASSESSORES DE CRÉDITO. Se você deseja se cadastrar como cliente,
          <a routerLink="/cadastrar" [queryParams]="getCESourceValue()">clique aqui</a>.</span
        >
      </div>
      <div class="fields">
        <div class="field">
          <mat-form-field color="accent" class="w-100">
            <input matInput placeholder="Nome completo" formControlName="nameCtrl" required name="fullname" />
            <mat-error *ngIf="createAgentFormGroup.controls['nameCtrl'].hasError('required')">
              Informe seu nome completo.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field color="accent" class="w-100">
            <input matInput type="email" placeholder="E-mail" formControlName="emailCtrl" required />
            <mat-error *ngIf="createAgentFormGroup.controls['emailCtrl'].hasError('required')">
              Informe seu e-mail.
            </mat-error>
            <mat-error *ngIf="createAgentFormGroup.controls['emailCtrl'].hasError('email')">
              O e-mail deve ser válido.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field color="accent" class="w-100">
            <input
              matInput
              placeholder="Telefone"
              formControlName="phoneCtrl"
              name="phone"
              phone
              #phone
              [textMask]="{ mask: formatPhone, guide: false }"
              required
            />
            <mat-error *ngIf="createAgentFormGroup.controls['phoneCtrl'].hasError('required')">
              Informe um telefone para contato.
            </mat-error>
            <mat-error *ngIf="createAgentFormGroup.controls['phoneCtrl'].hasError('telefone')">
              Telefone inválido.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field color="accent">
            <mat-label>CNPJ</mat-label>
            <input
              matInput
              type="text"
              placeholder="CNPJ"
              formControlName="CNPJCtrl"
              name="CNPJ"
              [textMask]="{ mask: MASKS.cnpj.textMask }"
            />
            <mat-error *ngIf="createAgentFormGroup.controls['CNPJCtrl'].hasError('cnpj')"> CNPJ inválido</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field color="primary">
            <mat-label> Linkedin (link) </mat-label>
            <input
              matInput
              type="text"
              placeholder="Linkedin"
              formControlName="linkedinCtrl"
              name="linkedin"
              required
            />
            <mat-error *ngIf="createAgentFormGroup.controls['linkedinCtrl'].hasError('linkedin')">
              Informe o link para o seu Linkedin
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field color="accent" class="w-100">
            <input matInput type="password" placeholder="Senha" formControlName="passwordCtrl" required />
            <mat-error *ngIf="createAgentFormGroup.controls['passwordCtrl'].hasError('required')">
              Informe uma senha de acesso.
            </mat-error>
            <mat-error *ngIf="createAgentFormGroup.controls['passwordCtrl'].hasError('minlength')">
              A senha deve ter 6 caracteres ou mais.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field color="accent" class="w-100">
            <input matInput type="password" placeholder="Confirme a senha" formControlName="confirmCtrl" required />
            <mat-error *ngIf="createAgentFormGroup.controls['confirmCtrl'].hasError('required')">
              Confirme a senha criada.
            </mat-error>
            <mat-error *ngIf="createAgentFormGroup.controls['confirmCtrl'].hasError('mismatch')">
              Confirmação de senha não confere.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="my-2 text-right tip">* Campos obrigatórios</div>

        <div class="flex-fill mt-3 mb-4 d-flex">
          <mat-checkbox
            color="primary"
            class="mr-3 checkbox"
            formControlName="agreedPrivacyPolicyCtrl"
            (click)="changeCheckBoxValue()"
            required
          >
          </mat-checkbox>
          <span style="flex: 1">
            Eu li e concordo com a
            <a class="colored-link" [href]="urlPrivacyPolicy" target="_blank">política de privacidade</a>.
          </span>
        </div>
        <div class="my-3">
          <button
            mat-raised-button
            *ngIf="!isProcessing"
            color="primary"
            class="btn-block btn-lg"
            type="submit"
            [disabled]="createAgentFormGroup.invalid"
            (click)="createAgent()"
          >
            Cadastre-se
          </button>
          <mat-spinner [diameter]="40" color="accent" *ngIf="isProcessing" class="mx-auto"></mat-spinner>
        </div>
        <div class="text-center my-4">
          <span class="d-inline-block mx-1">Já é assessor?</span>
          <a [routerLink]="['/entrar']" [queryParams]="getCESourceValue()" class="colored-link d-inline-block mx-1"
            >Clique aqui para fazer login</a
          >
        </div>
      </div>
    </form>
  </div>
</div>
