<div class="background d-flex align-items-center">
  <div class="container clearfix">
    <div class="businessLoan">
      <div class="row">
        <div class="col-lg-7 d-flex align-items-center">
          <h1 class="mt-2 mb-4">Já oferecemos mais de R$1 bilhão em crédito para pequenas e médias empresas</h1>
        </div>
        <div class="col-md-8 offset-md-2 col-lg-5 offset-lg-0">
          <div class="businessLogin"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div style="clear: both"></div>
