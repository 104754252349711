<div class="container">
  <mat-card>
    <mat-card-content>
      <mat-card-title class="mb-3 d-flex align-items-center">
        <span style="flex: 1">Dashboard</span>
      </mat-card-title>

      <div class="dashboard-card-forms">
        <mat-form-field appearance="outline">
          <mat-label>Período de avaliação</mat-label>
          <mat-select (selectionChange)="getData()" [(value)]="daysRangeSelected" [disabled]="isLoading">
            <mat-option *ngFor="let option of options" [(value)]="option.value">
              {{ option.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    </mat-card-content>
  </mat-card>

  <div>
    <div *ngIf="averageClosingTime">
      <mat-card class="dashboard-card">
        <mat-card-content class="dashboard-card-content">
          <h6>Tempo Médio de Fechamento de Oportunidades</h6>
          <span class="mt-3 d-inline-block fw-500">{{ averageClosingTime }} dias</span>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div *ngIf="dataSourceTable">
    <mat-card>
      <mat-card-content>
        <mat-card-title class="mb-3">Oportunidades fechadas no período</mat-card-title>
        <div>
          <table
            mat-table
            [dataSource]="dataSourceCredit"
            matSort
            #matSortCredit="matSort"
            style="width: 100%; text-align: left; align-items: center"
          >
            <ng-container matColumnDef="institution">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Instituição</th>
              <td mat-cell *matCellDef="let data">{{ data.institution }}</td>
            </ng-container>
            <ng-container matColumnDef="company">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome da empresa</th>
              <td mat-cell *matCellDef="let data">{{ data.companyName }}</td>
            </ng-container>
            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de assinatura do termo</th>
              <td mat-cell *matCellDef="let data">
                {{ (data.termsSignatureDate?.toDate() | date: 'dd/LL/yyyy') || '-' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="closingDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de fechamento</th>
              <td mat-cell *matCellDef="let data">{{ (data.closingDate?.toDate() | date: 'dd/LL/yyyy') || '-' }}</td>
            </ng-container>
            <ng-container matColumnDef="timeToMoney">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Tempo para fechamento</th>
              <td mat-cell *matCellDef="let data">{{ data.timeToMoney?.toFixed(0) }} dias</td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef disableClear>Acessar</th>
              <td mat-cell *matCellDef="let data; let i = index">
                <a mat-stroked-button [routerLink]="'/admin/clientes/detalhes/' + data.uid" target="_blank">
                  <mat-icon>open_in_new</mat-icon>
                </a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsCredit"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsCredit"></tr>
          </table>
          <mat-paginator #paginatorCredit [pageSizeOptions]="[20, 50, 80, 100]"></mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
