import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { User } from '../../../../../functions/src/models/User';
import { AuthService } from '../../../core';
import { ChatMenuItem } from '../../models/chat-menuItem';
import { ChatService } from '../../services/chat.service';


@Component({
  selector: 'app-chat-menu',
  templateUrl: './chat-menu.component.html',
  styleUrls: ['./chat-menu.component.scss']
})
export class ChatMenuComponent implements OnInit, OnDestroy {
  MENUCONTACTS: ChatMenuItem[] = [
    {
      state: 'conversas',
      type: 'sub',
      name: 'Conversas',
      children: [
        { state: 'atribuidos-a-mim', name: 'Atribuidos a mim', type: 'link', selected: false },
        { state: 'sem-atribuicao', name: 'Sem Atribuição', type: 'link', selected: false },
        { state: 'contatos', name: 'Contatos', type: 'link', accessLevel: 'master', selected: false },
      ],
    },
    {
      state: 'colaboradores',
      type: 'sub',
      name: 'Colaboradores',
      accessLevel: 'master',
      children: [],
    },
  ];
  users: User[] = [];
  loggedUser: { uid: string; name: string | 'Unknown'; email: string; accessLevel: string };
  nameUser: string;
  accessLevel: string;
  userList: ChatMenuItem[] = [];
  id: string;
  getUserWithAdminRoleSubscription: Subscription;
  paramsRouteSubscription: Subscription;
  loggedUserSubscription: Subscription;
  routerSubscripition: Subscription;

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private router: Router,
  ) {
    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser) => {
      this.loggedUser = {
        uid: loggedUser.uid,
        name: loggedUser.name || 'Unknown',
        email: loggedUser.email,
        accessLevel: loggedUser.accessLevel,
      };
      this.accessLevel = loggedUser?.accessLevel;
      this.nameUser = loggedUser?.displayName;
      this.getUsersColaborators();
    });
  }

  ngOnInit(): void {
    this.routerSubscripition = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.verifyRoute();
    });


    this.verifyRoute();
  }

  ngOnDestroy(): void {
    if (this.loggedUserSubscription) {
      this.loggedUserSubscription.unsubscribe();
    }
    if (this.getUserWithAdminRoleSubscription) {
      this.getUserWithAdminRoleSubscription.unsubscribe();
    }
    if (this.paramsRouteSubscription) {
      this.paramsRouteSubscription.unsubscribe();
    }
    if (this.routerSubscripition) {
      this.routerSubscripition.unsubscribe();
    }
  }

  private getUsersColaborators(): void {
    if (this.accessLevel === 'master') {
      this.getUserWithAdminRoleSubscription = this.chatService.getUsersWithAdminRole().subscribe(users => {
        this.userList = users.map(user => ({
          state: 'colaborador',
          name: user.displayName,
          uid: user.uid,
          type: 'link',
          selected: false,
        }));

        const menu = this.MENUCONTACTS.find(m => m.state === 'colaboradores' && m.type === 'sub');
        menu.children = this.userList;

      });
    }

  }

  verifyRoute(): void {
    const url = this.router.url;
    this.id = this.router.url.split('cliente/')[1];
    const menu = this.MENUCONTACTS.find(m => m.state === 'conversas' && m.type === 'sub');
    menu.selected = false;
    if (menu) {
      menu.children.forEach(m => {
        if (url.includes(`/${ m.state }/`)) {
          menu.selected = true;
          menu.children.find(c => c.state === m.state).selected = true;
        }
      })
    }

  }

  navigate(param: string): void {
    const url = `/admin/chat-sce/${ param }/cliente/${ this.id || '' }`;
    this.router.navigate([url]);
  }

  onMenuitemSelected(menuitem: ChatMenuItem): void {
    this.clearSelection(this.MENUCONTACTS);

    if (!menuitem.selected) {
      menuitem.selected = true;
    }
    if (menuitem.state === 'colaborador') {
      this.navigate(menuitem.state + '/' + menuitem.uid);
    } else {
      this.navigate(menuitem.state);
    }

  }

  clearSelection(items: ChatMenuItem[]): void {
    items.forEach(item => {
      item.selected = false;
      if (item.children) {
        this.clearSelection(item.children);
      }
    });
  }

}
