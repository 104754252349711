import { Component, Input, OnInit } from '@angular/core';
import { Customer } from '../../../../../functions/src/models/Customer';

@Component({
  selector: 'app-user-company-data',
  templateUrl: './user-company-data.component.html',
  styleUrls: ['./user-company-data.component.scss']
})
export class UserCompanyDataComponent implements OnInit {
  @Input() customer: Customer;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

}
