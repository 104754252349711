import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { firestore } from 'firebase';
import { CustomerService } from 'src/app/customer/services/customer.service';
import { Customer } from '../../../../../functions/src/models/Customer';
import { ChatService } from '../../../chat/services/chat.service';
import { WhatsappDialogComponent } from '../../components/whatsapp-dialog/whatsapp-dialog.component';
import { SidebarStateService } from '../../services/sidebar-state.service';

@Component({
  selector: 'app-user-notes-container',
  templateUrl: './user-notes-container.component.html',
  styleUrls: ['./user-notes-container.component.scss'],
})
export class UserNotesContainerComponent implements OnInit {
  customer: string;
  createdBy: {
    email: string;
    name: string;
  };
  customerUid: Customer
  isLoading = true;
  isModalVisible?: boolean = false;
  textoDuvida?: string = "";
  numeroCapital?: string = "";
  isBabyCustomer: boolean;
  isMobile: boolean;
  firstLayerMobile: boolean;
  chatLayerMobile: boolean;
  faqLayerMobile: boolean;
  isWhatsApp: boolean;
  customerData: Customer;

  constructor(private customerService: CustomerService, private chatService: ChatService, private activatedRoute: ActivatedRoute, private sidebarStateService: SidebarStateService, private dialog: MatDialog) {
    this.getPhoneNumber();
    const routeSnapshot = this.activatedRoute.snapshot;
    const routeData = routeSnapshot.data;
    this.sidebarStateService.updateCurrentSection(routeData);
    this.firstLayerMobile = true;
    this.chatLayerMobile = false;
  }

  ngOnInit(): void {
    this.customerService.customer.subscribe((c) => {
      this.isBabyCustomer = this.customerService.checkCustomerBabyLayout(c);
      console.log('isBabyCustomer', this.isBabyCustomer);
      if (c?.uid) {
        this.customerUid = c;
        this.customer = c.uid;
        this.createdBy = {
          email: c.email || null,
          name: c.name || null,
        };

        this.isLoading = false;
      }
    });
    this.checkScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 768;
    console.log('isMobile', this.isMobile);
  }

  openChatMobile(): void {
    this.firstLayerMobile = false;
    this.faqLayerMobile = false;
    this.chatLayerMobile = true;
  }

  returnChat(): void {
    this.firstLayerMobile = true;
    this.faqLayerMobile = false;
    this.chatLayerMobile = false;
  }

  openFaqMobile(): void {
    this.firstLayerMobile = false;
    this.faqLayerMobile = true;
  }

  checkCriteria(): boolean {
    const customer = this.customerUid;

    const customerHasRequiredRevenue = customer?.revenue >= 240000;
    const customerGuaranteeIds = customer?.guarantees?.map((guarantee) => guarantee?.id);
    const requiredGuaranteeIds = ['imovel-operacional', 'imovel-nao-operacional', 'veiculos', 'avalista', 'recebiveis'];
    const customerHasRequiredGuarantees = requiredGuaranteeIds
      .some((required) => customerGuaranteeIds?.some((id) => id.includes(required)));
    const customerHasRequiredCompanyAge = customer?.companyInformation?.age > 2;

    return customerHasRequiredRevenue && (customerHasRequiredGuarantees || customerHasRequiredCompanyAge);
  }


  getPhoneNumber(): void {
    this.chatService.getPlugConfig().subscribe((data: any) => {
      this.numeroCapital = data?.phone;
      console.log('this.numeroCapital', this.numeroCapital);
    });
  }

  construirLink(): string {
    const numero: string = this.numeroCapital;
    if(!this.textoDuvida){
      this.textoDuvida = "Olá, gostaria de ajuda com meu pedido de crédito na Capital Empreendedor";
    }
    const texto: string = encodeURIComponent(this.textoDuvida);
    return `https://wa.me/${ numero }?text=${ texto }`;
  }

  showModal() {
    this.isModalVisible = true;
    console.log('this.modalVisible', this.isModalVisible)
  }

  updateCustomer(event) {
    const dateNow = firestore.Timestamp.now();
    this.isWhatsApp = event.checked;

    const whatsAppContact: Customer['whatsAppContact'] = {
      date: dateNow,
      value: this.isWhatsApp,
    };
    this.customerService.updateCustomerOnFirestore(this.customerData.uid, { whatsAppContact });
  }

  openWhatsappModal($event): void {
    const config: MatDialogConfig = {
      width: '480px',
      panelClass: 'whatsapp-dialog-customized'
    };

    this.dialog.open(WhatsappDialogComponent, config);
    this.updateCustomer($event);
  }

  closeModal() {
    this.isModalVisible = false;
  }
}
