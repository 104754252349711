<div class="baby-layout" *ngIf="!isMobile">
  <div class="chat-page-header">
    <div>
      <h1>Olá</h1>
      <h2>Estamos aqui para te ajudar!</h2>
    </div>
  </div>
  <hr />
  <div class="main-content">
    <div class="chat-wrapper">
      <app-user-notes [type]="'USER'" [customer]="customer" [createdBy]="createdBy" *ngIf="!isLoading"></app-user-notes>
    </div>
    <div class="chat-aside-wrapper">
      <app-aside-chat-faq></app-aside-chat-faq>
    </div>
  </div>
</div>

<div class="baby-layout-mobile" *ngIf="isMobile">
  <div class="inner-header">
    <h2>Olá</h2>
    <h3>Estamos aqui para te ajudar!</h3>
  </div>
  <div class="first-layer-mobile-container" *ngIf="firstLayerMobile">
    <div class="cards-buttons">
      <button (click)="openChatMobile()">
        <img src="../../../../assets/images/icons/Vector.png" />
        <span>Chat</span>
      </button>
      <button (click)="openFaqMobile()">
        <img src="../../../../assets/images/icons/_.png" />
        <span>Perguntas frequentes</span>
      </button>
      <button>
        <img src="../../../../assets/images/icons/1200px-Picture_icon_BLACK.svg 1.png" />
        <span>Mídias</span>
      </button>
      <button (click)="openWhatsappModal($event)">
        <img src="../../../../assets/images/icons/whatsapp-icon.png" />
        <span>Agilize o atendimento</span>
      </button>
    </div>
    <button class="start-chat-button" (click)="openChatMobile()">Iniciar chat</button>
  </div>
  <div class="chat-layer-mobile-container" *ngIf="chatLayerMobile">
    <app-user-notes [type]="'USER'" [customer]="customer" [createdBy]="createdBy" *ngIf="!isLoading"></app-user-notes>
  </div>
  <div class="faq-layer-mobile-container" *ngIf="faqLayerMobile">
    <app-aside-chat-faq (openChat)="returnChat()"></app-aside-chat-faq>
  </div>
</div>
