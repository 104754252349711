import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Customer } from '../../../../functions/src/models/Customer';
import { BasicStructure } from '../../../../functions/src/models/common/BasicStructure';
import { momentDuration, timestampForDate } from '../../../../functions/src/utils/dates';

@Component({
  selector: 'app-info-company-dialog',
  templateUrl: './info-company-dialog.component.html',
  styleUrls: ['./info-company-dialog.component.scss']
})
export class InfoCompanyDialogComponent implements OnInit {
  public alertTitle: string;
  dataSource = new MatTableDataSource<Customer>([]);
  guaranteesStr: string;

  public guarantees: BasicStructure[];

  constructor(private dialogRef: MatDialogRef<InfoCompanyDialogComponent>, @Inject(MAT_DIALOG_DATA) data: {
    alertTitle: string;
    dataSource: Customer[];
    guarantees: BasicStructure[];
   
  }) {
    this.alertTitle = data.alertTitle;
    this.dataSource = new MatTableDataSource<Customer>(data.dataSource);
    this.guaranteesStr = data.guarantees
      .map(guarantee => guarantee?.name)
      .filter(name => name)
      .join(', ');
  }

  ngOnInit(): void {

   
  }

  calcular(date): string {
    const moment = momentDuration(timestampForDate(date)); 
    const years = Math.floor(moment.asYears()); 
  
    // Verifica se years é um número válido
    if (!isNaN(years)) {
      return years.toString(); 
    } else {
      return ''; 
    }
  }
 

  onGoBack(): void {
    this.dialogRef.close();
  }

  formatarData(date): string {
    const timestamp = timestampForDate(date); 

    // Verifica se o valor é uma data válida
    if (!isNaN(timestamp.getTime())) {
      return timestamp.toLocaleDateString(); 
    } else {
      return ''; 
    }
  }
}
