import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

interface TextItem {
  title: string;
  subtitle: string;
  stageId: string;
}

@Injectable({
  providedIn: 'root',
})
export class TemplateSignupService {
  texts: { title: string; subtitle: string; stageId: string }[];
  private _image = '';
  private stepSubject = new BehaviorSubject<string>('');
  private _imagePath = new BehaviorSubject<string>(this._image);
  readonly imagePath = this._imagePath.asObservable();

  constructor(private angularFirestore: AngularFirestore) { }

  setImagePath(path: string): void {
    this._image = path;
    this._imagePath.next(this._image);
  }

  getImagePath(): string {
    return this._image;
  }

  getStep() {
    return this.stepSubject.asObservable();
  }

  setStep(step: string) {
    this.stepSubject.next(step);
  }

  getTexts(): Promise<TextItem[]> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection(`configuracao`)
        .doc('onboarding-texts')
        .get()
        .toPromise()
        .then((doc) => {
          if (!doc.exists) {
            console.log('Não foram encontrados os textos');
            reject(new Error('Não foram encontrados os textos'));
          } else {
            const docData = doc.data() as TextItem[];
            console.log('Texts ok');
            resolve(docData);
          }
        })
        .catch((err) => {
          console.error('Erro ao encontrar os textos', err);
          reject(err);
        });
    });
  }
}
