  <div class="modal-container">
    <div class="modal-header">
      <h1>Selecionar modelo do whatsapp</h1>
      <button mat-button class="close-btn" (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  
    <div class="modal-content">
      <div class="left-section">
        <div class="search-container">
          <img src="assets/images/icons/search-icon.svg" width="18px" alt="search-icon" class="search-icon" />
          <input class="search-input" type="text" placeholder="Pesquisar modelos" [(ngModel)]="searchText"
            (keyup)="filterList()" />
        </div>
  
        <div class="result-list">
          <div class="list-header">Nome do modelo</div>
          <div *ngFor="let item of filteredItems" class="list-item"   [ngClass]="{ 'selected': selectedModel === item }"  (click)="selectModel(item)">
            {{ item.name }}
          </div>
        </div>
      </div>
  
      <div class="vertical-line"></div>
      <div class="container">
      <div class="right-section" [ngClass]="{ 'with-model': selectedModel }">
        <div *ngIf="isLoading; else modelContent" style="margin: 118px 203px 161px;">
            <img src="assets/images/icons/Loader.svg" alt="Carregando..." class="spinner-icon" />
          </div>
          <ng-template #modelContent>
        <div *ngIf="selectedModel; else defaultImage" >
            <h2 class="title">Visualização de modelo</h2>
            <p class="model-title">Modelo: {{ selectedModel.name }}</p>

            <div class="template-container">
            <div class="model-box">
                <p class="user-name">{{loggedUser.name}}:</p>
              <p style="font-size: 13.5px; text-align: left;">{{ selectedModel.model }}</p>

              <div class="info-container">
                <img src="assets/images/icons/whatsapp.png" alt="icon-whatsapp" class="icon-whatsapp" />
                <span class="current-time">{{ currenTime }}</span>
            </div>

            </div>
        </div>
          </div>
          </ng-template>
        
          <ng-template #defaultImage>
            <img src="assets/images/icons/msg 1.png" alt="msg 1" class="msg-icon" />
          </ng-template>
  
        <div class="button-container">
          <button style="    margin-left: 24px;" mat-button class="cancel-btn" (click)="cancelSelection()">Cancelar</button>
          <button mat-button class="send-btn" [disabled]="!selectedModel" 
          [ngClass]="{'disabled-btn': !selectedModel || isLoading}" style="color: #FFFFFF;" (click)="onSendModelTemplate()">Enviar modelo</button>
        </div>
      </div>
      </div>
    </div>
  </div>