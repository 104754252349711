import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { ContractConfiguration } from 'functions/src/models/Contract';
import { Utm } from '../../../../functions/src/models/common/Utm';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(private angularFirestore: AngularFirestore) {}

  getContractConfiguration(): Promise<ContractConfiguration> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc('configuracao/contract')
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data() as ContractConfiguration);
          } else {
            console.error('Configuration document not found');
            reject(new Error('Configuration document not found'));
          }
        })
        .catch((err) => {
          console.error('Error getting contract configuration', err);
          reject(err);
        });
    });
  }

  async updateCustomerJourneyControl(cnpj: string): Promise<void>{
      const termSignatureDate = new Date();
      await this.angularFirestore.collection('customerjourneycontrol').doc(cnpj).update({termSignatureDate});
  }

  async updateNumberCustomer(increment: number): Promise<void> {
    try {
      const docRef = this.angularFirestore.doc('configuracao/webflow');
      const doc = await docRef.get().toPromise();
      
      if (doc.exists) {
        const currentData = doc.data();
        const currentNumberCustomer = currentData?.numberCustomer || 0;
        const newNumberCustomer = currentNumberCustomer + increment;
        
        await docRef.update({ numberCustomer: newNumberCustomer });
        console.log(`Updated numberCustomer to ${newNumberCustomer}`);
      } else {
        console.error('Webflow document not found');
      }
    } catch (err) {
      console.error('Error updating numberCustomer', err);
    }
  }

  async updateCustomerJourneyControlUtm(cnpj: string, utmSource: Utm): Promise<void>{
    console.log('chamou serviço 1')
    console.log(cnpj)
    await this.angularFirestore.collection('customerjourneycontrol').doc( cnpj ).update({utmSource});
}
  
async updateCustomerUtmSignature(uid: string, utmSignature: Utm): Promise<void>{
  console.log('chamou serviço 2')
  console.log(uid)
  await this.angularFirestore.collection('customers').doc( uid ).update({utmSignature});
}

  
}
