<div class="chat-aside-container">
  <div class="go-back-button-wrapper" *ngIf="currentLayer !== 'first'">


    <button (click)="openChatAndCloseFaq()" *ngIf="isMobile">
      <mat-icon>chevron_left</mat-icon>
      <span>Voltar</span>
    </button>

    <button (click)="setLayer('first')" *ngIf="!isMobile">
      <mat-icon>chevron_left</mat-icon>
      <span>Voltar</span>
    </button>


  </div>
  <div class="main-content">
    <h2>Atendimento Capital Empreendedor</h2>
    <button class="faq-button-wrapper" *ngIf="currentLayer === 'first'" (click)="setLayer('second')">
      <i class="fas fa-question fa-2x"></i>
      <p>Perguntas frequentes</p>
    </button>
    <button class="faq-button-wrapper" *ngIf="currentLayer === 'first'" (click)="openWhatsappModal($event)">
      <img src="./../../../../assets/images/icons/whatsapp-icon.png" alt="whatsapp-icon">
      <p>Agilize o atendimento</p>
    </button>

    <button class="faq-button-wrapper" *ngIf="currentLayer === 'first' && isMobile" (click)="openChatAndCloseFaq()" >
      <img src="../../../../assets/images/icons/Vector.png" alt="chat-icon"/>
      <p>Chat</p>
    </button>

    <div class="second-layer" *ngIf="currentLayer === 'second'">
      <div class="input-container">
        <mat-icon>search</mat-icon>
        <input placeholder="Pesquisar" />
      </div>
      <h4>Selecione uma opção:</h4>
      <button class="faq-option" (click)="setLayer('guarantees')">
        <span>Por que preciso de garantias?</span>
        <mat-icon>chevron_right</mat-icon>
      </button>
      <button class="faq-option" (click)="setLayer('howToWorks')">
        <span>Como funciona</span>
        <mat-icon>chevron_right</mat-icon>
      </button>
      <button class="faq-option" (click)="setLayer('extraDoubts')">
        <span>Outras dúvidas</span>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
    <div class="answer-layer" *ngIf="currentLayer === 'guarantees'">
      <h3>Por que preciso de garantias?</h3>
      <p>Para empresas com menos de 2 anos de CNPJ, a exigência de garantias como veículos ou imóveis <b>é necessária devido às políticas da maioria dos bancos e instituições financeiras.</b> Eles pedem um histórico mínimo de 2 anos para avaliar a saúde financeira e a estabilidade do negócio.<b><br />Como empresas mais novas ainda não possuem esse histórico estabelecido, a adição de garantias oferece uma segurança adicional aos bancos, permitindo que sua empresa tenha acesso ao crédito necessário para crescer e se desenvolver.</b><br />Se precisar de ajuda para entender mais sobre as opções de garantias, nossa equipe está à disposição para te orientar!</p>
    </div>
    <div class="answer-layer" *ngIf="currentLayer === 'howToWorks'">
      <h3>Como funciona a Capital Empreendedor?</h3>
      <p>A Capital Empreendedor é uma empresa que ajuda empreendedores como você a <b>conseguir dinheiro para investir em seus negócios.</b> <br />Nós oferecemos diferentes tipos de empréstimos e financiamentos para que você possa escolher o que se encaixa melhor nas suas necessidades!</p>
    </div>
    <div class="extra-doubts-layer" *ngIf="currentLayer === 'extraDoubts'">
      <div class="input-container">
        <mat-icon>search</mat-icon>
        <input placeholder="Pesquisar" />
      </div>
      <button class="faq-option">
        <a href="https://www.capitalempreendedor.com.br/faq" target="_blank">Documentação</a>
        <mat-icon>chevron_right</mat-icon>
      </button>
      <button class="faq-option">
        <a href="https://www.capitalempreendedor.com.br/faq" target="_blank">Termo e taxa</a>
        <mat-icon>chevron_right</mat-icon>
      </button>
      <button class="faq-option">
        <a href="https://www.capitalempreendedor.com.br/faq" target="_blank">Garantias</a>
        <mat-icon>chevron_right</mat-icon>
      </button>
      <button class="faq-option">
        <a href="https://www.capitalempreendedor.com.br/faq" target="_blank">Plataforma</a>
        <mat-icon>chevron_right</mat-icon>
      </button>
      <button class="faq-option">
        <a href="https://www.capitalempreendedor.com.br/faq" target="_blank">Análise de crédito</a>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>
</div>
