import { FormFieldSelectComponent } from './form-field-select/form-field-select.component';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule],
  declarations: [FormFieldSelectComponent],
  exports: [FormFieldSelectComponent],
})
export class FormFieldsModule {}
