import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserManagementService } from '../../../admin/services/user-management.service';
import { AuthService } from '../../../core/auth/auth.service';
import { AssignedComponent } from '../../components/assigned/assigned.component';
import { ChatMenuItem } from '../../models/chat-menuItem';
import { ChatMenuService } from '../../services/chat-menu.service';

@Component({
  selector: 'app-container-colaborator',
  templateUrl: './container-colaborator.component.html',
  styleUrls: ['./container-colaborator.component.scss']
})
export class ContainerColaboratorComponent implements OnInit, OnDestroy {  
  @ViewChild('app-assigned') assignedComponent: AssignedComponent;
  
  paramsRouteSubscription: Subscription;
  uid: string;
  isLoadingUser = false;
  getUserByUidSubscription: Subscription;

  loggedUserSubscription: Subscription;
  loggedUser: { uid: string; name: string | 'Unknown'; email: string };

  constructor(private chatMenuService: ChatMenuService, private activatedRoute: ActivatedRoute,
    private userService: UserManagementService, private authService: AuthService) {
    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser) => {
      this.loggedUser = {
        uid: loggedUser.uid,
        name: loggedUser.name || 'Unknown',
        email: loggedUser.email,
      };
    });
  }

  ngOnInit(): void {
    this.paramsRouteSubscription = this.activatedRoute.params.subscribe(params => {
      this.uid = params['uid'];

      this.isLoadingUser = false;
      this.getUserByUidSubscription = this.userService.getUserByUid(this.uid).subscribe(user => {
        this.isLoadingUser = true;
        this.onMenuitemSelected({ state: 'colaborador', name: 'Atribuidos a ' + (user?.displayName || user?.name || 'Admin'), uid: user.uid, type: 'link' });

        this.assignedComponent?.ngOnInit()
      })

    });
  }
  ngOnDestroy(): void {
    if (this.paramsRouteSubscription) {
      this.paramsRouteSubscription.unsubscribe();
    }
    
    if (this.getUserByUidSubscription) {
      this.getUserByUidSubscription.unsubscribe();
    }   
    
    if (this.loggedUserSubscription) {
      this.loggedUserSubscription.unsubscribe();
    }
  }

  onMenuitemSelected(menuitem: ChatMenuItem): void {
    if (!menuitem.selected) {
      menuitem.selected = true;
    }
    this.chatMenuService.setMenuItem(menuitem);
  }


}
