<div class="container">
  <mat-card *ngIf="isLoading">
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <a
          mat-mini-fab
          color="primary"
          type="button"
          [routerLink]="'/admin/assessores/info/' + agentCode"
          matTooltip="Voltar à visualização"
          class="mr-3"
        >
          <mat-icon>chevron_left</mat-icon>
        </a>
        <span style="flex: 1">Editar Assessor de Crédito</span>
      </mat-card-title>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!isLoading">
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <a
          mat-mini-fab
          color="accent"
          type="button"
          [routerLink]="'/admin/assessores/info/' + agentCode"
          matTooltip="Voltar à visualização"
          class="mr-3"
        >
          <mat-icon>chevron_left</mat-icon>
        </a>
        <span style="flex: 1">Editar Assessor: {{ agent.name }}</span>
      </mat-card-title>
      <section class="mt-4 agent-profile-form">
        <form [formGroup]="editAgentFormGroup">
          <div class="fields">
            <div class="field">
              <mat-form-field color="primary" class="w-100">
                <input matInput type="email" placeholder="E-mail" formControlName="emailCtrl" />
                <mat-error *ngIf="editAgentFormGroup.controls['emailCtrl'].hasError('required')">
                  Informe o e-mail do assessor.
                </mat-error>
                <mat-error *ngIf="editAgentFormGroup.controls['emailCtrl'].hasError('email')">
                  O e-mail deve ser válido.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary" class="w-100">
                <input matInput placeholder="Nome completo" formControlName="nameCtrl" required name="fullname" />
                <mat-error *ngIf="editAgentFormGroup.controls['nameCtrl'].hasError('required')">
                  Informe o nome completo do assessor.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary" class="w-100">
                <input
                  matInput
                  placeholder="Telefone"
                  formControlName="phoneCtrl"
                  name="phone"
                  phone
                  #phone
                  [textMask]="{ mask: formatPhone, guide: false }"
                  required
                />
                <mat-error *ngIf="editAgentFormGroup.controls['phoneCtrl'].hasError('required')">
                  Informe um telefone do assessor para contato.
                </mat-error>
                <mat-error *ngIf="editAgentFormGroup.controls['phoneCtrl'].hasError('telefone')">
                  Telefone Inválido.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field color="primary">
                <mat-label>CNPJ</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="CNPJ"
                  formControlName="CNPJCtrl"
                  name="CNPJ"
                  [textMask]="{ mask: MASKS.cnpj.textMask }"
                />
                <mat-error *ngIf="editAgentFormGroup.controls['CNPJCtrl'].hasError('cnpj')"> CNPJ inválido</mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field color="primary">
                <mat-label> Linkedin </mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Linkedin"
                  formControlName="linkedinCtrl"
                  name="linkedin"
                  required
                />
                <mat-error *ngIf="editAgentFormGroup.controls['linkedinCtrl'].hasError('required')">
                  Informe o Linkedin do assessor
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field color="primary">
                <mat-label> Origem </mat-label>
                <input matInput type="text" placeholder="Origem" formControlName="origemCtrl" name="origem" required />
                <mat-error *ngIf="editAgentFormGroup.controls['origemCtrl'].hasError('required')">
                  Informe a origem do assessor
                </mat-error>
              </mat-form-field>
            </div>
            <div class="my-2 text-right tip">* Campos obrigatórios</div>
            <div class="mt-3">
              <button
                mat-raised-button
                *ngIf="!isProcessing"
                color="primary"
                class="btn-block btn-lg"
                type="submit"
                [disabled]="editAgentFormGroup.invalid || shouldButtonDisable()"
                (click)="editAgent()"
              >
                Atualizar dados
              </button>
              <mat-spinner [diameter]="40" color="primary" *ngIf="isProcessing" class="mx-auto"></mat-spinner>
            </div>
          </div>
        </form>
      </section>
    </mat-card-content>
  </mat-card>
</div>
