<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-xl-8">
      <mat-card>
        <mat-card-content>
          <div class="d-flex flex-row">
            <div style="margin-right: 1.5em">
              <a mat-mini-fab color="accent" type="button" routerLink="/admin/clientes/buscar"
                matTooltip="Voltar a listagem">
                <mat-icon>chevron_left</mat-icon>
              </a>
            </div>
            <div style="display: flex;">
              <div>
                <mat-card-title #title> Listagem de Usuários {{ tituloDetalheUsuario }}
                <a *ngIf="customer?.partnerCE"
                  style="color:white; background-color: #00C46C; padding: 5px 10px 5px 10px; border-radius: 10px;">
                  {{customer?.partnerCE}}
                </a>
                <a *ngIf="customer?.scoreSerasa"
                  style="color:white; background-color: #00C46C; padding: 5px 10px 5px 10px; border-radius: 10px;">
                  Score - {{customer?.scoreSerasa}}
                </a>
              </mat-card-title>

              <mat-card-subtitle> Consulte e altere os usuários do sistema. </mat-card-subtitle>
              </div>
              <ng-container>
                <div class="small-select">
                  <select 
                    [(ngModel)]="selectedUser" 
                    name="user" 
                    (ngModelChange)="onUserSelectionChange($event)" 
                    [disabled]="loggedUser.accessLevel !== 'master'"
                   >
                    <option [ngValue]="null">Sem atribuição</option>
                    <option *ngFor="let user of users" [ngValue]="user">
                      {{ user?.displayName }}
                    </option>
                  </select>
                </div>
              </ng-container>
              
              
              
            </div>
          </div>

          <mat-tab-group #tabGroup>
            <mat-tab>
              <ng-template mat-tab-label> Dados do usuário </ng-template>

              <div style="overflow: hidden">
                <form [formGroup]="editFormGroup" (submit)="onSaveForm()" *ngIf="customer">
                  <h3 class="border-b mt-4 mb-3">Informações pessoais</h3>

                  <div class="row">
                    <div class="col-12 col-xl-6">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">Nome</mat-label>
                        <input matInput placeholder="Nome" formControlName="nomeCtrl" required name="nome" />
                        <mat-error *ngIf="editFormGroup.controls['nomeCtrl'].hasError('required')">
                          Informe o nome do usuário.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-xl-6">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">E-mail</mat-label>
                        <input matInput placeholder="E-mail" formControlName="emailCtrl" required name="email" />
                        <mat-error *ngIf="editFormGroup.controls['emailCtrl'].hasError('required')">
                          Informe o e-mail do usuário.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">CPF</mat-label>
                        <input matInput placeholder="CPF" formControlName="cpfCtrl" required name="cpf"
                          [textMask]="{ mask: MASKS.cpf.textMask }" />
                        <mat-error *ngIf="editFormGroup.get('cpfCtrl')?.hasError('required')">
                          O CPF é obrigatório.
                        </mat-error>
                        <mat-error *ngIf="editFormGroup.get('cpfCtrl')?.errors?.cpf">
                          Informe um CPF válido.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">WhatsApp</mat-label>
                        <input matInput placeholder="WhatsApp" formControlName="whatsappCtrl" name="whatsapp" whatsapp
                          #whatsapp [textMask]="{ mask: formatPhone, guide: false }" required />
                        <mat-error *ngIf="editFormGroup.controls['whatsappCtrl'].hasError('required')">
                          Informe o WhatsApp do usuário.
                        </mat-error>
                        <mat-error *ngIf="
                            editFormGroup.controls['whatsappCtrl'].hasError('telefone') ||
                            editFormGroup.controls['whatsappCtrl'].hasError('minlength')
                          ">
                          WhatsApp inválido
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">Telefone</mat-label>
                        <input matInput placeholder="Telefone" formControlName="telefoneCtrl" name="telefone" telefone
                          #telefone [textMask]="{ mask: formatPhone, guide: false }" />
                        <mat-error *ngIf="editFormGroup.controls['telefoneCtrl'].hasError('telefone')">
                          Telefone inválido.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">Data do cadastro</mat-label>
                        <input matInput [matDatepicker]="dp" placeholder="Data do cadastro" name="dataCadastro"
                          formControlName="dataCadastroCtrl" required (focus)="dp.open()" />
                        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                        <mat-error *ngIf="editFormGroup.controls['dataCadastroCtrl'].hasError('required')">
                          Informe a data de cadastro do usuário.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-12 button-detail">
                      <button class="mr-3" mat-raised-button type="button" (click)="sendEmailVerification()"
                        *ngIf="customer?.emailVerified === false" [disabled]="isSendingEmailVerification">
                        <span *ngIf="!isSendingEmailVerification"> Reenviar confirmação de email </span>
                        <mat-spinner [diameter]="20" class="mx-3 my-2" *ngIf="isSendingEmailVerification"></mat-spinner>
                      </button>

                      <div>
                        <button class="mr-3 " mat-raised-button type="button" routerLink="/admin/chat-sce"
                          (click)="goToChat(customer)">
                          <span> Iniciar conversa </span>
                        </button>
                      </div>
                      <div>
                        <button *ngIf="customer?.hubspot?.companyId" class="mr-3" mat-raised-button type="button"
                          (click)="hubspotLink()">
                          Ir para Hubspot


                        </button>
                      </div>
                    </div>
                  </div>

                  <h3 class="border-b mt-4 mb-3">Informações da empresa</h3>

                  <div class="d-flex flex-column">
                    <mat-form-field style="width: 100%">
                      <mat-label style="font-weight: 500">Razão Social</mat-label>
                      <input matInput placeholder="Razão Social" formControlName="razaoSocialCtrl" required
                        name="razaoSocial" />
                      <mat-error *ngIf="editFormGroup.controls['razaoSocialCtrl'].hasError('required')">
                        Informe a razão social da empresa.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field style="width: 100%">
                      <mat-label style="font-weight: 500">CNPJ</mat-label>
                      <input matInput placeholder="CNPJ" formControlName="cnpjCtrl" required name="cnpj"
                        [textMask]="{ mask: MASKS.cnpj.textMask }" (blur)="onValidCnpj($event.target.value)" />
                      <mat-error *ngIf="editFormGroup.controls['cnpjCtrl'].hasError('required')">
                        Informe o cnpj da empresa.
                      </mat-error>
                      <mat-error *ngIf="editFormGroup.controls['cnpjCtrl'].hasError('cnpj')">
                        Informe um CNPJ válido.</mat-error>
                      <mat-error *ngIf="editFormGroup.controls['cnpjCtrl'].hasError('exists')">
                        CNPJ já cadastrado.
                      </mat-error>
                    </mat-form-field>
                    
                    <mat-form-field style="width: 100%">
                      <mat-label style="font-weight: 500">Cep</mat-label>
                      <input matInput placeholder="Cep" formControlName="cepCtrl" [required]="!customer.noTermsCustomer" name="cep"
                        #cepCtrl="matInput" [textMask]="{ mask: MASKS.cep.textMask, guide: false }"
                        (blur)="onBuscarCEP()" (keyup)="cep.value?.length === 10 ? onBuscarCEP() : false"
                        minlength="10" />
                      <mat-error *ngIf="editFormGroup.controls['cepCtrl'].hasError('required')">
                        Informe o cep do endereço da empresa.
                      </mat-error>
                      <mat-error *ngIf="
                          !editFormGroup.get('cepCtrl')?.hasError('required') &&
                          editFormGroup.get('cepCtrl')?.hasError('minlength')
                        ">
                        Informe um CEP válido.
                      </mat-error>
                      <mat-error *ngIf="
                          !editFormGroup.get('cepCtrl')?.hasError('required') &&
                          editFormGroup.get('cepCtrl')?.hasError('incorrect')
                        ">
                        O CEP informado não foi encontrado.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field style="width: 100%">
                      <mat-label style="font-weight: 500">Logradouro</mat-label>
                      <input matInput placeholder="Logradouro" formControlName="logradouroCtrl" [required]="!customer.noTermsCustomer"
                        name="logradouro" />
                      <mat-error *ngIf="editFormGroup.controls['logradouroCtrl'].hasError('required')">
                        Informe o logradouro da empresa.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field style="width: 100%">
                      <mat-label style="font-weight: 500">Complemento</mat-label>
                      <input matInput placeholder="Complemento" formControlName="complementoCtrl" name="complemento" />
                    </mat-form-field>

                    <mat-form-field style="width: 100%">
                      <mat-label style="font-weight: 500">Número</mat-label>
                      <input matInput placeholder="Número" formControlName="numeroCtrl" [required]="!customer.noTermsCustomer" name="numero" />
                      <mat-error *ngIf="editFormGroup.controls['numeroCtrl'].hasError('required')">
                        Informe o número do endereço da empresa.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field style="width: 100%">
                      <mat-label style="font-weight: 500">Bairro</mat-label>
                      <input matInput placeholder="Bairro" formControlName="bairroCtrl" [required]="!customer.noTermsCustomer" name="bairro" />
                      <mat-error *ngIf="editFormGroup.controls['bairroCtrl'].hasError('required')">
                        Informe o bairro da empresa.
                      </mat-error>
                    </mat-form-field>

                    <div class="d-flex">
                      <div class="flex-grow-1" style="padding-right: 10px">
                        <mat-form-field>
                          <mat-label style="font-weight: 500">Cidade</mat-label>
                          <input matInput placeholder="Cidade" formControlName="cidadeCtrl" [required]="!customer.noTermsCustomer" name="cidade" />
                          <mat-error *ngIf="editFormGroup.controls['cidadeCtrl'].hasError('required')">
                            Informe a cidade da empresa.
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div>
                        <mat-form-field>
                          <mat-label style="font-weight: 500">Estado</mat-label>
                          <input matInput placeholder="Estado" formControlName="estadoCtrl" [required]="!customer.noTermsCustomer" name="estado" />
                          <mat-error *ngIf="editFormGroup.controls['estadoCtrl'].hasError('required')">
                            Informe o Estado/UF da empresa.
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <mat-form-field style="width: 100%">
                      <mat-label style="font-weight: 500">Termo criado em</mat-label>
                      <input matInput [matDatepicker]="dpDataCriacaoTermo" placeholder="Termo criado em"
                        name="dataContrato" formControlName="dataContratoCtrl" [required]="!customer.noTermsCustomer"
                        (focus)="dpDataCriacaoTermo.open()" />
                      <mat-datepicker-toggle matSuffix [for]="dpDataCriacaoTermo"></mat-datepicker-toggle>
                      <mat-datepicker #dpDataCriacaoTermo></mat-datepicker>
                      <mat-error *ngIf="editFormGroup.controls['dataContratoCtrl'].hasError('required')">
                        Informe a data de criação do termo de serviço.
                      </mat-error>
                    </mat-form-field>

                    <div class="d-flex">
                      <mat-form-field style="padding-right: 10px">
                        <mat-label style="font-weight: 500">Concordou com o termo</mat-label>
                        <mat-select placeholder="Concordou com o termo" formControlName="concordaComTermosCtrl"
                          name="concordaComTermos" [required]="!customer.noTermsCustomer" [compareWith]="compareObjects">
                          <mat-option *ngFor="let concordaComTermosCtrl of opcaoSimNaoTermo"
                            [value]="concordaComTermosCtrl">
                            {{ concordaComTermosCtrl.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editFormGroup.controls['concordaComTermosCtrl'].hasError('required')">
                          Informe se o usuário concordou com o termo de serviço.
                        </mat-error>
                      </mat-form-field>
                      <mat-form-field>
                        <mat-label style="font-weight: 500">Termo aceito em</mat-label>
                        <input matInput [matDatepicker]="dpDatetimeAceite" placeholder="Termo aceito em"
                          name="datetimeAceite" formControlName="datetimeAceiteCtrl"
                          (focus)="dpDatetimeAceite.open()" />
                        <mat-datepicker-toggle matSuffix [for]="dpDatetimeAceite"></mat-datepicker-toggle>
                        <mat-datepicker #dpDatetimeAceite></mat-datepicker>
                        <mat-error *ngIf="editFormGroup.controls['datetimeAceiteCtrl'].hasError('required')">
                          Informe a data do termo de serviço.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <mat-form-field style="width: 100%">
                      <mat-label>Permite cancelamento do termo</mat-label>
                      <mat-select placeholder="Permite cancelamento do termo" formControlName="permiteCancelamentoCtrl"
                        name="permiteCancelamento" required [compareWith]="compareObjects">
                        <mat-option *ngFor="let permiteCancelamentoCtrl of opcaoSimNao"
                          [value]="permiteCancelamentoCtrl">
                          {{ permiteCancelamentoCtrl.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="editFormGroup.controls['permiteCancelamentoCtrl'].hasError('required')">
                        Informe se o sistema ainda permite cancelamento do termo de aceite.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field style="width: 100%">
                      <mat-label>Data do cancelamento do termo</mat-label>
                      <input matInput [matDatepicker]="dpDataCancelamento" placeholder="Data do cancelamento do termo"
                        name="dataCancelamento" formControlName="datetimeCancelamentoCtrl"
                        (focus)="dpDataCancelamento.open()" />
                      <mat-datepicker-toggle matSuffix [for]="dpDataCancelamento"></mat-datepicker-toggle>
                      <mat-datepicker #dpDataCancelamento></mat-datepicker>
                      <mat-error *ngIf="editFormGroup.controls['datetimeCancelamentoCtrl'].hasError('required')">
                        Informe a data do cancelamento do termo de aceite.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field style="width: 100%">
                      <textarea matInput placeholder="Motivo cancelamento do termo"
                        formControlName="motivoCancelamentoCtrl" name="motivoCancelamento" matTextareaAutosize
                        matAutosizeMinRows="1" matAutosizeMaxRows="5"></textarea>
                      <mat-error *ngIf="editFormGroup.controls['motivoCancelamentoCtrl'].hasError('required')">
                        Informe o motivo do cancelamento do termo de serviço.
                      </mat-error>
                    </mat-form-field>

                    <!----Política de Privacidade -->
                    <div class="d-flex col-12 m-0 p-0">
                      <mat-form-field class="col-4 p-0 m-0 mr-2">
                        <mat-label style="font-weight: 500">Concordou com a Política de Privacidade</mat-label>
                        <mat-select placeholder="Concordou com a Política de Privacidade"
                          formControlName="agreedPrivacyPolicyCtrl" name="agreedPrivacyPolicy" required
                          [compareWith]="compareObjects">
                          <mat-option *ngFor="let agreedPrivacyPolicyCtrl of opcaoSimNaoTermo"
                            [value]="agreedPrivacyPolicyCtrl">
                            {{ agreedPrivacyPolicyCtrl.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editFormGroup.controls['agreedPrivacyPolicyCtrl'].hasError('required')">
                          Informe se o usuário concordou com o termo de serviço.
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="col-8 ml-2">
                        <mat-label style="font-weight: 500">Política de Privacidade Aceita em</mat-label>
                        <input matInput [matDatepicker]="dpPrivacyPolicyDate"
                          placeholder="Política de Privacidade Aceita em" name="privacyPolicyDate"
                          formControlName="privacyPolicyDateCtrl"
                          [disabled]="editFormGroup.get('agreedPrivacyPolicyCtrl').value.id !== 'sim'" readonly
                          (click)="dpPrivacyPolicyDate.open()" />
                        <mat-datepicker-toggle matSuffix [for]="dpPrivacyPolicyDate"></mat-datepicker-toggle>
                        <mat-datepicker #dpPrivacyPolicyDate></mat-datepicker>
                        <mat-error *ngIf="editFormGroup.controls['privacyPolicyDateCtrl'].hasError('required')">
                          Informe a data de aceite da política de privacidade.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <!----Política de Cookies -->
                    <div class="d-flex col-12 m-0 p-0">
                      <mat-form-field class="col-4 p-0 m-0 mr-2">
                        <mat-label style="font-weight: 500">Concordou com a Política de Cookies</mat-label>
                        <mat-select placeholder="Concordou com a Política de Cookies"
                          formControlName="agreedPolicyCookieCtrl" name="agreedPolicyCookie" required
                          [compareWith]="compareObjects">
                          <mat-option *ngFor="let option of opcaoSimNaoTermo" [value]="option">
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editFormGroup.controls['agreedPrivacyPolicyCtrl'].hasError('required')">
                          Informe se o usuário concordou com o termo de serviço.
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="col-8 ml-2">
                        <mat-label style="font-weight: 500">Política de Cookies Aceita em</mat-label>
                        <input matInput [matDatepicker]="dpPolicyCookieDate" placeholder="Política de Cookies Aceita em"
                          name="policyCookieDate" formControlName="policyCookieDateCtrl"
                          [disabled]="editFormGroup.get('agreedPolicyCookieCtrl').value.id !== 'sim'" readonly
                          (click)="dpPolicyCookieDate.open()" />
                        <mat-datepicker-toggle matSuffix [for]="dpPolicyCookieDate"></mat-datepicker-toggle>
                        <mat-datepicker #dpPolicyCookieDate></mat-datepicker>
                        <mat-error *ngIf="editFormGroup.controls['agreedPolicyCookieCtrl'].hasError('required')">
                          Informe a data de aceite da política de cookies.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="d-flex flex-row">
                      <mat-form-field style="padding-right: 10px">
                        <input matInput placeholder="E-mail do Contador" formControlName="emailContadorCtrl" />
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput placeholder="Telefone do Contador" formControlName="telefoneContadorCtrl"
                          [textMask]="{ mask: formatPhone, guide: false }" />
                        <mat-error *ngIf="editFormGroup.controls['telefoneContadorCtrl'].hasError('telefone')">
                          Telefone inválido.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <h3 class="border-b mt-4 mb-3">Dados de avaliação</h3>

                  <div class="row">
                    <div class="col-12 col-lg-4">
                      <mat-form-field style="padding-right: 10px">
                        <mat-label style="font-weight: 500">Faturamento anual (R$)</mat-label>
                        <input matInput placeholder="Faturamento anual (R$)" formControlName="valorFaturamentoStrCtrl"
                          name="valorFaturamentoStr" [textMask]="{ mask: numberMask }" />
                        <mat-error *ngIf="editFormGroup.controls['valorFaturamentoStrCtrl'].hasError('required')">
                          Informe o valor de faturamento anual da empresa.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-lg-4">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">Quanto busca de capital (R$)</mat-label>
                        <input matInput placeholder="Quanto busca de capital (R$)" formControlName="valorCapitalStrCtrl"
                          name="valorCapitalStr" [textMask]="{ mask: numberMask }" />
                        <mat-error *ngIf="editFormGroup.controls['valorCapitalStrCtrl'].hasError('required')">
                          Informe um valor do capital buscado pela empresa.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-lg-4">
                      <mat-form-field>
                        <mat-label style="font-weight: 500">Dívida atual (R$)</mat-label>
                        <input matInput placeholder="Dívida atual (R$)" formControlName="valorDividaStrCtrl"
                          name="valorDividaStr" [textMask]="{ mask: numberMask }" />
                        <mat-error *ngIf="editFormGroup.controls['valorDividaStrCtrl'].hasError('required')">
                          Informe o valor da dívida atual da empresa.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="d-flex col-12">
                      <div class="col-3 p-0 m-0">
                        <mat-form-field>
                          <mat-label style="font-weight: 500">Idade da empresa (anos)</mat-label>
                          <input matInput placeholder="Idade da empresa (anos)" formControlName="idadeEmpresaCtrl"
                            name="idadeEmpresa" type="number" (change)="parseCompanyAge()" (keyup)="parseCompanyAge()"
                            required />
                          <mat-error *ngIf="editFormGroup.controls['idadeEmpresaCtrl'].hasError('required')">
                            Informe a idade da empresa.
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-3">
                        <mat-form-field>
                          <mat-label style="font-weight: 500">Tempo de CNPJ</mat-label>
                          <mat-select placeholder="Tempo de cnpj" formControlName="tempoAtividadeCtrl"
                            name="tempoAtividade" required [compareWith]="compareObjects">
                            <mat-option *ngFor="let tempoAtividade of optionsTempoAtividade" [value]="tempoAtividade">
                              {{ tempoAtividade.name }}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="editFormGroup.controls['tempoAtividadeCtrl'].hasError('required')">
                            Informe o tempo de CNPJ.
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-6 p-0 m-0">
                        <mat-form-field style="padding-right: 0">
                          <mat-label style="font-weight: 500"> Data de Fundação </mat-label>
                          <input matInput [matDatepicker]="dpfoundedDate" placeholder="Data de Fundação"
                            name="foundedDate" formControlName="foundedDateCtrl" (focus)="dpfoundedDate.open()" />

                          <mat-datepicker-toggle matSuffix [for]="dpfoundedDate"></mat-datepicker-toggle>
                          <mat-datepicker #dpfoundedDate></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-center my-3">
                      <p *ngIf="editFormGroup.controls['scoreCtrl'].value" style="
                          width: fit-content;
                          font-size: 1em;
                          margin: 0 1em;
                          padding: 1em 1.5em;
                          border-radius: 0.5em;
                          background-color: rgba(205, 243, 225, 0.5);
                          display: flex;
                          align-items: center;
                        ">
                        <span class="mr-2">Score: </span>
                        <span class="mr-1" style="color: #05c46b; font-weight: bold; font-size: 1.1em">{{
                          editFormGroup.controls['scoreCtrl'].value
                          }}</span>
                      </p>
                      <!-- <button
                        mat-raised-button
                        type="button"
                        (click)="runAutomaticIntegration()"
                        [color]="'primary'"
                        *ngIf="!editFormGroup.controls['scoreCtrl'].value"
                      >
                        Obter score
                      </button> -->
                    </div>

                    <div class="col-12" [class.col-lg-6]="hasOther">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">Banco de crédito atual</mat-label>
                        <mat-select placeholder="Banco de crédito atual" formControlName="bancosCtrl" name="bancos"
                          multiple (selectionChange)="onChangeBancos($event)" [required]="!customer.noTermsCustomer"
                          [compareWith]="compareObjectsById">
                          <mat-option *ngFor="let banco of config?.institutions" [value]="banco">
                            {{ banco.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editFormGroup.controls['bancosCtrl'].hasError('required')">
                          Informe os bancos de crédito atuais.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-lg-6" *ngIf="hasOther">
                      <mat-form-field class="w-100">
                        <mat-chip-list #chipList aria-label="Selecione suas instituições Financeiras">
                          <mat-chip *ngFor="let newOtherInstitution of newOtherInstitutions" [selectable]="selectable"
                            [removable]="removable" (removed)="remove(newOtherInstitution)">
                            {{newOtherInstitution.name}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                          </mat-chip>
                          <input placeholder="Outras instituições Financeiras" #newOtherInstitutionInput
                            formControlName="outrosBancosCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                            [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                          <mat-option *ngFor="let newOtherInstitution of filteredNewOtherInstitutions | async"
                            [value]="newOtherInstitution">
                            {{newOtherInstitution.name}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <!----Fields for Guarantees--->
                    <div class="col-12 d-flex">
                      <div class="flex-fill {{ hasTypeOfAnticipation || !hasOnlyAvalGuarantee ? 'mr-4' : 'mr-0' }}">
                        <app-form-field-select fieldName="Garantias disponíveis" controlName="garantiasCtrl"
                          (selectionChange)="onChangeGuarantees($event)" [formGroup]="editFormGroup"
                          [options]="config?.guarantees" [compareWith]="true" [multiple]="true"
                          [required]="true"></app-form-field-select>
                      </div>

                      <div *ngIf="hasTypeOfAnticipation" class="flex-fill">
                        <app-form-field-select fieldName="Antecipação de Recebíveis"
                          controlName="typeOfAnticipationCtrl" (selectionChange)="onChangeAntecipation($event)"
                          [formGroup]="editFormGroup" [options]="selectTypeOfAnticipationOperator" [compareWith]="true"
                          [multiple]="true" [required]="hasTypeOfAnticipation"></app-form-field-select>
                      </div>

                      <div *ngIf="hasTypeOfAnticipationCard"
                        class="flex-fill {{ hasTypeOfAnticipationCard ? 'ml-4' : 'ml-0' }}">
                        <app-form-field-select fieldName="Maquininha de Cartão" controlName="creditCardCtrl"
                          [formGroup]="editFormGroup" [options]="config.cardMachines" [compareWith]="true"
                          [required]="hasTypeOfAnticipationCard" [multiple]="true"></app-form-field-select>
                      </div>
                    </div>

                    <!-- <div class="row">
                      <mat-form-field *ngIf="!hasOnlyAvalGuarantee" color="primary"
                        class="flex-fill {{ !hasOnlyAvalGuarantee ? 'ml-4' : 'none' }}">
                        <mat-label>Valor Total das Garantias</mat-label>
                        <input matInput placeholder="Valor da garantia (R$)" [textMask]="{ mask: numberMask }"
                          formControlName="valorTotalStrCtrl" name="guarantees-value"
                          [required]="true" />
                        <mat-error
                *ngIf="editFormGroup.controls['valorTotalStrCtrl'].hasError('required')">
                Informe o valor da garantia
              </mat-error>
                      </mat-form-field>
                    </div> -->

                    <div class="col-12 col-lg-6" *ngIf="hasTypeOfInvoices">
                      <app-form-field-select fieldName="Tipo de Nota Fiscal" controlName="typeOfInvoicesCtrl"
                        [formGroup]="editFormGroup" [options]="selectTypeOfInvoices" [compareWith]="true"
                        [multiple]="true">
                      </app-form-field-select>
                    </div>

                    <div class="row col-12 col-lg-6" *ngIf="!hasOnlyAvalGuarantee">
                      <mat-form-field color="primary" class="flex-fill {{ !hasOnlyAvalGuarantee ? 'ml-4' : 'none' }}">
                        <mat-label>Valor Total das Garantias</mat-label>
                        <input matInput placeholder="Valor da garantia (R$)" [textMask]="{ mask: numberMask }"
                          formControlName="valorTotalStrCtrl" name="guarantees-value"/>
                        <!-- <mat-error
                          *ngIf="editFormGroup.controls['valorTotalStrCtrl'].hasError('required')">
                          Informe o valor da garantia
                        </mat-error> -->
                      </mat-form-field>
                    </div>

                    <div class="col-12">
                      <mat-form-field color="primary" class="flex-fill ">
                        <mat-label style="font-weight: 500">Tipo de Pessoa</mat-label>
                        <mat-select placeholder="Tipo de pessoa" formControlName="personTypeCtrl" name="personType"
                          multiple>
                          <mat-option *ngFor="let personType of selectPersonType " [value]="personType">
                            {{ personType.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-lg-6">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">Regime Tributário</mat-label>
                        <mat-select placeholder="Regime Tributário" formControlName="regimeTribCtrl" name="taxRegime"
                          (selectionChange)="onChangetaxRegime($event)" [compareWith]="compareTaxRegimes">
                          <mat-option *ngFor="let taxReg of config?.taxRegimes || []" [value]="taxReg">
                            {{ taxReg.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editFormGroup.controls['regimeTribCtrl'].hasError('required')">
                          Informe o regime tributário da empresa.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-12 col-lg-6" *ngIf="hasTaxRegimeMei">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">Celular Pessoal</mat-label>
                        <input matInput placeholder="Celular Pessoal" formControlName="personalCellCtrl"
                          name="personalCell" celularPessoal #celularPessoal
                          [textMask]="{ mask: formatPhone, guide: false }" /></mat-form-field>
                    </div>

                    <div class="col-12 col-lg-6" *ngIf="hasTaxRegimeMei">
                      <mat-form-field color="accent" class="w-100">
                        <mat-label>Data de Nascimento</mat-label>
                        <input matInput [matDatepicker]="birthDate" placeholder="Data de Nascimento" name="birthDate"
                          formControlName="birthDateCtrl" (focus)="birthDate.open()" />
                        <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
                        <mat-datepicker #birthDate></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-lg-6" *ngIf="hasTaxRegimeMei">
                      <app-form-field-select fieldName="Estado Civil" controlName="maritalStatusCtrl"
                        [formGroup]="editFormGroup" [options]="selectTypeMaritalStatus"></app-form-field-select>
                    </div>
                    <div class="col-12 col-lg-6">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">Finalidade do crédito</mat-label>
                        <mat-select placeholder="Finalidade do crédito" formControlName="finalidadeCtrl"
                          name="finalidade" [compareWith]="compareObjects">
                          <mat-option *ngFor="let purpose of config?.purposes || []" [value]="purpose">
                            {{ purpose.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editFormGroup.controls['regimeTribCtrl'].hasError('required')">
                          Informe o regime tributário da empresa.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-lg-6">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">Natureza legal</mat-label>
                        <input matInput placeholder="Natureza legal"
                          value="{{ customer?.companyInformation?.legalNature?.Activity }}" name="legalNature"
                          readonly />
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-lg-6">
                      <mat-form-field class="w-100">
                        <input matInput placeholder="Tipo da empresa"
                          value="{{ customer?.companyInformation?.taxRegime }}" name="taxRegimeBDC" readonly />
                      </mat-form-field>
                    </div>

                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <input matInput placeholder="Nome fantasia"
                          value="{{ customer?.companyInformation?.tradeName }}" name="tradeName" readonly />
                      </mat-form-field>
                    </div>
                  </div>

                  <table border="1" style="margin-bottom: 40px"
                    *ngIf="customer?.companyInformation?.activities?.length > 0">
                    <thead>
                      <tr>
                        <th>CNAE</th>
                        <th>Atividade</th>
                        <th>Principal</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let act of customer?.companyInformation?.activities">
                      <tr>
                        <td>{{ act?.Code }}</td>
                        <td>{{ act?.Activity }}</td>
                        <td>{{ act?.IsMain ? 'Sim' : 'Não' }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="row">
                    <div class="col-12 col-md-6 col-xl-3">
                      <mat-form-field class="w-100">
                        <mat-label>Pefin</mat-label>
                        <mat-select placeholder="Pefin" formControlName="pefinCtrl" name="pefin"
                          [compareWith]="compareObjects">
                          <mat-option *ngFor="let pefinCtrl of opcaoSimNao" [value]="pefinCtrl">
                            {{ pefinCtrl.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editFormGroup.controls['pefinCtrl'].hasError('required')">
                          Informe se o usuário tem restrições no pefin.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6 col-xl-3">
                      <mat-form-field class="w-100">
                        <mat-label>Refin</mat-label>
                        <mat-select placeholder="Refin" formControlName="refinCtrl" name="refin"
                          [compareWith]="compareObjects">
                          <mat-option *ngFor="let refinCtrl of opcaoSimNao" [value]="refinCtrl">
                            {{ refinCtrl.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editFormGroup.controls['refinCtrl'].hasError('required')">
                          Informe se o usuário tem restrições no refin.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6 col-xl-3">
                      <mat-form-field class="w-100">
                        <mat-label>Cheque sem fundo</mat-label>
                        <mat-select placeholder="Cheque sem fundo" formControlName="chequeSemFundoCtrl"
                          name="chequeSemFundo" [compareWith]="compareObjects">
                          <mat-option *ngFor="let chequeSemFundoCtrl of opcaoSimNao" [value]="chequeSemFundoCtrl">
                            {{ chequeSemFundoCtrl.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editFormGroup.controls['chequeSemFundoCtrl'].hasError('required')">
                          Informe se usuário tem restrição por cheques sem fundos.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6 col-xl-3">
                      <mat-form-field class="w-100">
                        <mat-label>Fraude</mat-label>
                        <mat-select placeholder="Fraude" formControlName="fraudeCtrl" name="faude"
                          [compareWith]="compareObjects">
                          <mat-option *ngFor="let fraudeCtrl of opcaoSimNao" [value]="fraudeCtrl">
                            {{ fraudeCtrl.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editFormGroup.controls['fraudeCtrl'].hasError('required')">
                          Informe se usuário tem restrição por fraude.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <mat-spinner class="mx-auto my-3" *ngIf="isProcessingSerasa" color="primary"
                    diameter="40"></mat-spinner>
                  <button mat-raised-button (click)="getNegativeDataFromSerasa()" type="button" [color]="'primary'"
                    *ngIf="!isProcessingSerasa">
                    Obter Informações Serasa
                  </button>

                  <h3 class="border-b mt-4 mb-3">Informações adicionais</h3>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label style="font-weight: 500">Origem</mat-label>
                        <input matInput placeholder="Origem" formControlName="origemCtrl" required name="Origem" />
                        <mat-error *ngIf="editFormGroup.controls['origemCtrl'].hasError('required')">
                          Informe a origem do usuário.
                        </mat-error>
                      </mat-form-field>
                      <div class="accountant-card">
                        <p class="mb-2"><span style="font-weight: 500">Cupom cadastrado:</span> {{ customer.cupomCode }}
                        </p>
                        <p class="mb-2"><span style="font-weight: 500">Validade do Cupom:</span> {{ customer.dataFim }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- approvedByCommittee -->
                  <div class="row">
                    <div class="col-12">
                      <mat-slide-toggle *ngIf="customer" color="primary" name="AprovadoPorComite" 
                        formControlName="approvedByCommitteeCtrl" (click)="startApprovalCommittee()">Aprovado pelo
                        comitê
                      </mat-slide-toggle>
                    </div>
                  </div>

                  <!------------------------------------------------------------------------>
                  <!------------------------------------------------------------------------>
                  <div class="text-center mt-4 mb-3">
                    <button [disabled]="editFormGroup.invalid" mat-raised-button color="primary" type="submit"
                      *ngIf="!isProcessing">
                      <mat-icon>playlist_add_check</mat-icon>
                      &nbsp;Salvar e Processar
                    </button>
                    <button mat-button type="button" readonly *ngIf="isProcessing">
                      <mat-spinner [diameter]="40" color="primary"></mat-spinner>
                    </button>
                  </div>
                </form>

                <div class="text-center mt-4 mb-3">
                  <button (click)="openPopup()" mat-raised-button color="primary">Adicionar contato</button>
                  <app-contact-form-component *ngIf="showPopup"
                    (closePopup)="closePopup()"></app-contact-form-component>
                </div>


              </div>
            </mat-tab>

            <mat-tab [disabled]="!agreedTerms">
              <ng-template mat-tab-label> Linhas de crédito </ng-template>
              <br />
              <app-customer-opportunity *ngIf="customer && loggedUser" [customer]="customer" [loggedUser]="loggedUser"
                (needToCheckDocuments)="getDocuments()" [startCommittee]="this.startCommittee">
              </app-customer-opportunity>
            </mat-tab>

            <mat-tab [disabled]="!agreedTerms">
              <ng-template mat-tab-label> Lista de documentos </ng-template>

              <br />

              <div *ngIf="!isUpload" style="margin: 2px">
                <div class="text-right">
                  <button mat-raised-button color="accent" type="button" (click)="onReprocessDocuments()"
                    [disabled]="processingDocuments">
                    <mat-icon>autorenew</mat-icon>&nbsp;Reprocessar Documentos
                  </button>
                </div>

                <br />

                <mat-accordion class="example-headers-align">
                  <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title>Novos Documentos</mat-panel-title>
                      <mat-panel-description>
                        <mat-label>Requisição de novos documentos para o usuário</mat-label>
                        <mat-icon>add_circle</mat-icon>
                      </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div>
                      <div class="d-flex flex-column">
                        <mat-selection-list #documentosAtivosSelecionados>
                          <mat-list-option *ngFor="let tipeDocument of tipeDocuments" [value]="tipeDocument">
                            {{ tipeDocument.nome }}
                          </mat-list-option>
                        </mat-selection-list>
                      </div>

                      <br />

                      <div class="d-flex justify-content-end">
                        <button mat-raised-button class="btn" color="accent" type="button" (click)="onAddNewDoc()"
                          [disabled]="documentosAtivosSelecionados.selectedOptions.selected.length === 0">
                          <mat-icon>add</mat-icon> &nbsp;Adicionar
                        </button>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <div class="text-left">
                  <button mat-raised-button color="primary" type="button" 
                  style="color: white; margin-top: 20px;" (click)="sendMsgDocs()">
                    Solicitar Documentos
                  </button>
                </div>
                <br/>
                <div class="text-left">
                  <button mat-raised-button color="primary" type="button" 
                  style="color: white; margin-top: 20px;" (click)="generatePDF()">
                    Autorização SCR
                  </button>
                </div>
                <br/>

                <mat-spinner *ngIf="runDocuments" [diameter]="80" class="mx-auto"></mat-spinner>



                <mat-accordion class="example-headers-align">
                  <mat-expansion-panel *ngFor="let item of documents; let idx = index">
                    <mat-expansion-panel-header (click)="getDocumentInfo(item); verifyUploadStatus(item)">
                      <mat-panel-title>
                        {{ item?.name }}
                      </mat-panel-title>
                      <mat-panel-description>
                        <mat-label>
                          <mat-chip-list>
                            <mat-chip [color]="item?.situation === 'Reprovado' ? 'warn' : 'primary'"
                              [selected]="item?.situation === 'Aprovado' || item?.situation === 'Reprovado'">
                              {{ item?.situation }}
                            </mat-chip>
                          </mat-chip-list>
                        </mat-label>

                        <mat-icon *ngIf="item?.pendingIssueForAdmin" class="pendencia-element">*</mat-icon>
                      </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="d-flex flex-row">
                      <mat-form-field>
                        <mat-label>Ordem de exibição (ordenação crescente)</mat-label>
                        <input matInput type="number" placeholder="Ordem" [value]="item.ordenation" min="0" step="1"
                          pattern="\d+" onInput="validity.valid||(value=0);" [(ngModel)]="item.ordenation" />
                      </mat-form-field>

                      <div style="margin-left: 30px">
                        <button mat-flat-button type="button" color="accent" class="btn"
                          (click)="onSalvarOrdenacao(item?.mnemonic)" style="height: 40px">
                          <mat-icon>check</mat-icon> Salvar ordenação
                        </button>
                      </div>
                    </div>

                    <div class="descricao">
                      <div class="d-flex justify-content-between flex-row">
                        <h5>Descrição</h5>
                      </div>
                      <div class="d-flex justify-content-between flex-column flex-md-row">
                        <mat-label>{{ item?.description }}</mat-label>
                      </div>
                    </div>

                    <div class="detalhes">
                      <h5>Detalhes</h5>

                      <div class="d-flex flex-column">
                        <div *ngIf="item?.situation === 'Aprovado' && item?.approvalOrDisapprovalDate">
                          <mat-label>
                            Aprovado em
                            {{
                            item?.approvalOrDisapprovalDate
                            ? (item?.approvalOrDisapprovalDate | date: 'dd/MM/yyyy HH:mm:ss')
                            : '-'
                            }}
                          </mat-label>
                        </div>
                        <div *ngIf="item?.situation === 'Reprovado' && item?.approvalOrDisapprovalDate">
                          <mat-label>
                            Reprovado em {{ item?.approvalOrDisapprovalDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                          </mat-label>

                          <br />

                          <mat-label *ngIf="item?.disapprovalReason"> Motivo: {{ item?.disapprovalReason }} </mat-label>
                        </div>
                      </div>

                      <div style="padding-top: 10px">
                        <mat-label>
                          Aguardando
                          {{
                          item.qtyExpectedFiles < item.qtySubmittedFiles ? 0 : item.qtyExpectedFiles -
                            item.qtySubmittedFiles }} de {{ item.qtyExpectedFiles }} {{ item.qtyExpectedFiles> 1 ?
                            'arquivos' : 'arquivo' }}
                        </mat-label>
                      </div>

                      <div class="d-flex flex-column flex-md-row" *ngIf="item?.expirationDate?.id === 'sim'"
                        style="padding-top: 10px">
                        <mat-label> Validade: {{ item?.qtyDays }} dias</mat-label>
                      </div>

                      <div class="instrucoes">
                        <h4>Instruções</h4>
                        <div innerHtml="{{ item?.instrucoes }}" style="padding-top: 5px"></div>
                      </div>

                      <div class="d-flex align-items-center flex-column flex-md-row"
                        *ngIf="item.financialYear?.id === 'sim' && item?.financialYears?.length > 0"
                        style="padding-top: 5px">
                        <mat-label> Anos de Exercício:&nbsp; </mat-label>
                        <mat-accordion class="year-list">
                          <mat-expansion-panel class="year" *ngFor="let year of item.financialYears; let i = index">
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                {{ year }}
                              </mat-panel-title>
                              <mat-panel-description>
                                {{ getDocumentStatus(item, year) }}
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                            <p class="mb-2">
                              Anexe o {{ item.name }} de {{ year }} abaixo e informe a data de emissão do documento.
                            </p>
                            <div *ngIf="item?.fileInfo?.length > 0">
                              <div *ngFor="let file of item.fileInfo; let i = index">
                                <p *ngIf="file?.financialYear === year" class="file">
                                  <a class="attached-file-link cursor-style" [href]="file.path" target="_blank">
                                    {{ file?.fileName ? file.fileName : item.name }}
                                  </a>
                                  <mat-icon inline="true" class="ml-2 icon"
                                    (click)="onRemoveFileDocument(i, item, file)">clear</mat-icon>
                                  <span *ngIf="file.dateUpload">
                                    &nbsp; {{ (file.dateUpload | date: 'dd/MM/yyyy HH:mm:ss') }}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div *ngIf="item.situation !== 'Aprovado'">
                              <p class="file" *ngIf="item?.fileInfo?.length === 0">Nenhum arquivo anexado</p>
                              <div class="attach-file">
                                <span matTooltip="Limite de arquivos submetidos atingido."
                                  [matTooltipDisabled]="canUpload">
                                  <button mat-raised-button color="primary" (click)="onSendNewFile(item, year)"
                                    [disabled]="
                                      item.situation === 'Aprovado' || getDocumentStatus(item, year) === 'Enviado'
                                    ">
                                    <mat-icon class="mr-2">attach_file</mat-icon>
                                    <mat-label>Enviar arquivo</mat-label>
                                  </button>
                                </span>
                              </div>
                            </div>

                            <ng-container *ngIf="customer?.hasRecurrence === true">
                              <div style="padding-top: 5px" *ngIf="item?.fileInfo.length > 0">
                                <span>Houve indicação de atualização: </span>
                                <mat-label [ngClass]="{
                                      'status-aguardando': determineStatus(item) === 'Aguardando',
                                      'status-houve-alteracao': determineStatus(item) === 'Houve atualização',
                                      'status-nao-houve-alteracao': determineStatus(item) === 'Não houve atualização'
                                    }"> {{ determineStatus(item)}}</mat-label>

                              </div>
                            </ng-container>

                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                      <div class="d-flex flex-column files" *ngIf="item.financialYear?.id === 'nao'"
                        style="padding-top: 5px">
                        <p class="mb-2">Anexe o {{ item.name }} abaixo e informe a data de emissão do documento.</p>
                        <div *ngIf="item.financialYear.id === 'nao'">
                          <div *ngIf="item?.fileInfo?.length > 0">
                            <p class="file" *ngFor="let file of item.fileInfo">
                              <a class="attached-file-link cursor-style" [href]="file.path" target="_blank">
                                {{ file?.fileName ? file.fileName : item.name }}
                              </a>
                              <mat-icon inline="true" class="ml-2 icon"
                                (click)="onRemoveFileDocument(null, item, file)">clear</mat-icon>
                              <span *ngIf="file.dateUpload">
                                &nbsp; {{ (file.dateUpload | date: 'dd/MM/yyyy HH:mm:ss') }}
                              </span>
                            </p>
                          </div>
                          <div *ngIf="item?.fileInfOld?.length > 0">
                            <p class="file" *ngFor="let file of item.fileInfOld">
                              <a class="attached-file-link cursor-style" [href]="file.path" target="_blank">
                                {{ file?.fileName ? file.fileName : item.name }}
                              </a>
                              <mat-icon inline="true" class="ml-2 icon"
                                (click)="onRemoveFileDocument(null, item, file)">clear</mat-icon>
                              <span *ngIf="file.dateUpload">
                                &nbsp; {{ (file.dateUpload | date: 'dd/MM/yyyy HH:mm:ss') }}
                              </span>
                            </p>
                          </div>
                          <div *ngIf="canUpload">
                            <p class="file"
                              *ngIf="!selectedDocument?.length || !selectedDocument[0]?.documents?.length">
                              Nenhum arquivo anexado
                            </p>
                            <div class="attach-file">
                              <span matTooltip="Limite de arquivos submetidos atingido."
                                [matTooltipDisabled]="canUpload">
                                <button mat-raised-button color="primary" (click)="onSendNewFile(item)"
                                  [disabled]="item.situation === 'Aprovado' || !canUpload">
                                  <mat-icon class="mr-2">attach_file</mat-icon>
                                  <mat-label>Enviar arquivo</mat-label>
                                </button>
                              </span>
                            </div>
                            <ng-container *ngIf="customer?.hasRecurrence === true">
                              <div style="padding-top: 5px" *ngIf="item?.fileInfo.length > 0">
                                <span>Houve indicação de atualização: </span>
                                <mat-label [ngClass]="{
                                    'status-aguardando': determineStatus(item) === 'Aguardando',
                                    'status-houve-alteracao': determineStatus(item) === 'Houve atualização',
                                    'status-nao-houve-alteracao': determineStatus(item) === 'Não houve atualização'
                                  }"> {{ determineStatus(item)}}</mat-label>

                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="acoes">
                      <div class="d-flex flex-column flex-md-row">
                        <button mat-raised-button color="primary" (click)="onApproveDocuments(item.typeId)"
                          *ngIf="item.situation === 'Reprovado' || item.situation === 'Enviado Parcialmente' || item.situation === 'Envio Completo'"
                          class="mr-2">
                          <mat-icon>thumb_up</mat-icon>
                          &nbsp;Aprovar
                        </button>

                        <button mat-raised-button color="warn" (click)="onReprovarDocumentos(item.typeId)"
                          *ngIf="item.situation === 'Envio Completo' || item.situation === 'Aprovado' || item.situation === 'Enviado Parcialmente'"
                          class="mr-2">
                          <mat-icon>thumb_down</mat-icon>
                          &nbsp;Reprovar
                        </button>

                        <button mat-stroked-button color="warn"
                          [class.ml-4]="item.situation === 'Reprovado' || item.situation === 'Envio Completo'"
                          (click)="onRemoverDocumento(item.typeId)">
                          <mat-icon>delete_forever</mat-icon> &nbsp;Remover
                        </button>
                      </div>
                    </div>

                    <!-- <div class="instrucoes">
                      <h4>Instruções</h4>
                      <div innerHtml="{{ item?.instrucoes }}" style="padding-top: 5px"></div>
                    </div> -->
                  </mat-expansion-panel>
                </mat-accordion>
              </div>

              <div *ngIf="isUpload">
                <h4>Documento: {{ tipoDocumentoSelecionado.name }}</h4>

                <form [formGroup]="fileInfoFormGroup" (submit)="onSalvarArquivo()">
                  <file-upload></file-upload>

                  <div style="padding-top: 40px" class="d-flex flex-column">
                    <mat-form-field>
                      <input matInput [matDatepicker]="dp" placeholder="Data de emissão" name="dataEmissao"
                        formControlName="dataEmissaoCtrl" required (focus)="dp.open()" />
                      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                      <mat-error *ngIf="fileInfoFormGroup.controls['dataEmissaoCtrl'].hasError('required')">
                        Informe a data de emissão do documento.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="tipoDocumentoSelecionado?.financialYear.id === 'sim'">
                      <mat-select placeholder="Ano de Exercício" formControlName="anoExercicioCtrl" name="anoExercicio"
                        required>
                        <mat-option *ngFor="let anoExercicioCtrl of tipoDocumentoSelecionado?.financialYears"
                          [value]="anoExercicioCtrl">
                          {{ anoExercicioCtrl }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="fileInfoFormGroup.controls['anoExercicioCtrl'].hasError('required')">
                        Informe um ano de execício do documento.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div style="padding-top: 10px">
                    <mat-label>Aguardando {{ item.qtyExpectedFiles - item.qtySubmittedFiles }} de
                      {{ item.qtyExpectedFiles }}
                      {{ item.qtyExpectedFiles > 1 ? 'arquivos' : 'arquivo' }}</mat-label>
                  </div>
                  <div class="d-flex flex-column flex-md-row" *ngIf="item.expirationDate.id === 'sim'"
                    style="padding-top: 10px">
                    <mat-label> Validade: {{ item.qtyDays }} dias</mat-label>
                  </div>
                  <div class="d-flex align-items-center flex-column flex-md-row" *ngIf="item.financialYear.id === 'sim'"
                    style="padding-top: 5px">
                    <mat-label> Anos de Exercício:&nbsp; </mat-label>
                  </div>
                  <div class="arquivos" *ngIf="item.fileInfo && item.fileInfo.length">
                    <div class="d-flex flex-column">
                      <mat-nav-list>
                        <mat-list-item *ngFor="let file of selectedDocument; let i = index">
                          <a *ngIf="file.documents?.path" matLine href="{{ file.path }}" target="_blank">
                            {{ file.fileName || 'Documento' }}
                          </a>
                          <p *ngIf="!file?.path">Nenhum arquivo anexado</p>
                        </mat-list-item>
                      </mat-nav-list>
                    </div>
                  </div>
                  <div class="acoes">
                    <h5>Ações</h5>
                    <div class="d-flex flex-column flex-md-row">
                      <button mat-raised-button color="primary" (click)="onApproveDocuments(item.typeId)" [disabled]="
                          item.situation === 'Aprovado' ||
                          item.situation === 'Reprovado' ||
                          item.situation !== 'Envio Completo'
                        ">
                        <mat-icon>thumb_up</mat-icon>
                        &nbsp;Aprovar</button>&nbsp;

                      <button mat-raised-button color="warn" (click)="onReprovarDocumentos(item.typeId)" [disabled]="
                          item.situation === 'Aprovado' ||
                          item.situation === 'Reprovado' ||
                          item.situation !== 'Envio Completo'
                        ">
                        <mat-icon>thumb_down</mat-icon>
                        &nbsp;Reprovar</button>&nbsp;

                      <button mat-stroked-button color="warn" class="ml-4" (click)="onRemoverDocumento(idx)">
                        <mat-icon>delete_forever</mat-icon>
                        &nbsp;Remover
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="text-left mt-4" style="display: flex; justify-content: space-between;">
                <button mat-raised-button (click)="downloadAllDocs(documents)" color="primary"
                  [disabled]="!hasUploadedDocs(documents)" class="mr-4">
                  <mat-icon class="mr-2">cloud_download</mat-icon> Baixar Documentos (zip)
                </button>
                <div style="display: flex; flex-direction: column;">
                  <mat-slide-toggle class="ml-2" color="primary" [checked]="customer?.documentApproval"
                    (change)="toggleDocumentApproval()">
                    Documentação aprovada
                    <span *ngIf="customer?.documentApprovalDate && customer?.documentApproval">
                      {{ customer.documentApprovalDate.toDate() | date: 'dd/MM/yyyy HH:mm:ss' }}
                    </span>
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-tab>
            <!-- <mat-tab>
              <div class="user-notes-tab">
                <ng-template mat-tab-label> Mensagens </ng-template>

                <app-user-notes [type]="'ADMIN'" [customer]="customer?.uid" [createdBy]="createdBy"> </app-user-notes>
              </div>
            </mat-tab> -->

            <mat-tab>
              <ng-template mat-tab-label> Dados adicionais </ng-template>

              <div class="p-3">
                <h3 style="font-weight: bold" class="d-flex align-items-center">
                  Dados extras sobre o cliente
                  <span style="flex: 1" class="text-right">
                    <a href="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/manuais%2Freferencia-dados-adicionais.pdf?alt=media"
                      target="_blank" color="accent" class="my-2" mat-raised-button>
                      Referência <mat-icon class="ml-2">open_in_new</mat-icon>
                    </a>
                  </span>
                </h3>

                <hr style="background-color: #9e9e9e" class="mb-3" />
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel class="menu-exp">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <strong>
                          Open Finance - Pluggy
                        </strong>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <app-open-finance-admin *ngIf="customer?.uid" [uid]="customer?.uid"></app-open-finance-admin>

                  </mat-expansion-panel>

                  <mat-expansion-panel class="menu-exp">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <strong>
                          ERP's
                        </strong>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <app-customer-erps-result-admin *ngIf="customer?.uid" [customer]="customer?.uid"></app-customer-erps-result-admin>

                  </mat-expansion-panel>


                  <mat-expansion-panel class="menu-exp">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <strong>
                          Dados extras - Big Data Corp
                        </strong>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                      <mat-spinner class="mx-auto my-3" *ngIf="isProcessingBDC" color="primary"
                        diameter="40"></mat-spinner>

                      <div class="d-flex align-items-center mt-2 flex-wrap" *ngIf="!isProcessingBDC">
                        <button mat-raised-button (click)="runAutomaticIntegration()"
                          [color]="hasBasicData() && hasOnlineQuery() ? 'accent' : 'primary'" style="width: 350px"
                          matTooltip="Dados básicos e score">
                          Executar Consultas Automáticas do Cadastro
                        </button>

                        <div class="d-flex flex-column">
                          <div class="d-flex align-items-center my-2">
                            <span *ngIf="hasBasicData()" class="ml-3 mr-2"> Consulta <strong>já</strong> realizada
                            </span>
                            <span *ngIf="!hasBasicData()" class="ml-3 mr-2"> Consulta <strong>não</strong> realizada
                            </span>
                            <mat-icon *ngIf="hasBasicData()">check_circle</mat-icon>
                            <mat-icon *ngIf="!hasBasicData()">clear</mat-icon>
                          </div>

                          <div class="d-flex align-items-center my-2" *ngIf="hasOnlineQuery()">
                            <span class="ml-3 mr-2"> Score obtido </span>
                            <mat-icon>check_circle</mat-icon>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex align-items-center mt-2 flex-wrap" *ngIf="!isProcessingBDC">
                        <button mat-raised-button (click)="getGeneralDataFromIntegration()"
                          [color]="hasGeneralData() ? 'accent' : 'primary'" style="width: 350px">
                          Executar Consulta - Geral
                        </button>

                        <div class="d-flex align-items-center my-2">
                          <span *ngIf="hasGeneralData()" class="ml-3 mr-2"> Consulta <strong>já</strong> realizada
                          </span>
                          <span *ngIf="!hasGeneralData()" class="ml-3 mr-2"> Consulta <strong>não</strong> realizada
                          </span>

                          <mat-icon *ngIf="hasGeneralData()">check_circle</mat-icon>
                          <mat-icon *ngIf="!hasGeneralData()">clear</mat-icon>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div *ngIf="!bigdatacorpData || objectKeys(bigdatacorpData)?.length <= 0">
                        <p class="text-center p-4">Nenhum dado encontrado.</p>
                      </div>

                      <div *ngIf="bigdatacorpData && objectKeys(bigdatacorpData).length">
                        <app-tree [tree]="bigdatacorpData"></app-tree>
                      </div>
                    </div>
                  </mat-expansion-panel>

                  <mat-expansion-panel class="menu-exp">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <strong>
                          Registrato SCR
                        </strong>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <app-card-bmp-moneyplus *ngIf="customer?.uid" [customer]="customer?.uid"
                      [bmpMoneyPlusData]="bmpMoneyPlusData"></app-card-bmp-moneyplus>
                  </mat-expansion-panel>
                </mat-accordion>
                <hr style="background-color: #9e9e9e" class="mb-3" />
              </div>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label> Assessor de crédito </ng-template>

              <div class="p-3">
                <div class="agent-info" *ngIf="!customer?.agent">
                  <p>Nenhum assessor de crédito vinculado.</p>
                </div>

                <div class="agent-info" *ngIf="customer?.agent && !agent">
                  <p>O assessor de crédito vinculado é inválido.</p>
                </div>

                <div class="agent-info" *ngIf="customer?.agent && agent">
                  <h3>Assessor de crédito vinculado:</h3>
                  <p><strong>Nome:</strong> {{ agent.name || '-' }}</p>
                  <p><strong>E-mail:</strong> {{ agent.email || '-' }}</p>
                  <p><strong>Telefone:</strong> {{ agent.phone || '-' }}</p>
                </div>

                <div class="mt-3 d-flex align-items-center">
                  <mat-form-field color="primary" class="mr-3" style="flex: 1">
                    <mat-label>UID do Assessor:</mat-label>
                    <input matInput type="text" [(ngModel)]="agentUID" placeholder="UID do Agente" />
                  </mat-form-field>

                  <button mat-raised-button type="button" class="btn" color="primary"
                    [disabled]="(agent && agent.uid === agentUID) || (!agent && !agentUID) || isProcessingAgent || !['master','admin'].includes(loggedUser.accessLevel) "
                    (click)="saveAgent()">
                    <span *ngIf="!isProcessingAgent">Salvar assessor</span>
                    <mat-spinner class="mx-auto" *ngIf="isProcessingAgent" color="primary" diameter="20"></mat-spinner>
                  </button>
                </div>
              </div>

              <div class="notes-area my-4">
                <div class="d-flex flex-column flex-sm-row">
                  <h3 style="font-weight: bold; flex: 1">Registros de acompanhamento</h3>
                  <button mat-raised-button color="primary" class="btn" (click)="isAgentNoteCreateMode = true"
                    [disabled]="!agentUID">
                    <mat-icon>post_add</mat-icon> Novo Registro
                  </button>
                </div>

                <hr style="background-color: #9e9e9e" class="mb-3" />

                <div *ngIf="agentUID && isAgentNoteCreateMode">
                  <mat-card class="p-3">
                    <mat-card-content>
                      <h3 style="font-weight: 500">Novo Registro</h3>

                      <mat-form-field class="w-100" color="accent">
                        <textarea matInput placeholder="Descrição" mat-autosize matAutosizeMinRows="5"
                          [(ngModel)]="newAgentNote.message" required></textarea>
                      </mat-form-field>

                      <div class="text-right">
                        <button mat-raised-button type="button" (click)="cancelAgentNote()" class="mx-2 btn">
                          <mat-icon>clear</mat-icon> Cancelar
                        </button>
                        <button mat-raised-button color="accent" type="button" [disabled]="!newAgentNote.message"
                          (click)="createAgentNote()" class="mx-2 btn">
                          <mat-icon>save</mat-icon> Salvar
                        </button>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>

                <div class="text-center" *ngIf="isLoadingAgentNotes">
                  <mat-spinner diameter="60" class="mx-auto"></mat-spinner>
                </div>

                <div *ngIf="!isLoadingAgentNotes">
                  <p class="p-4 m-3 text-center" *ngIf="this.agentNotes?.length === 0">Ainda não há nenhum registro.</p>

                  <p class="mb-2 mt-4" style="font-weight: 500" *ngIf="this.agentNotes?.length > 0">
                    Últimos registros:
                  </p>

                  <mat-card *ngFor="let note of agentNotes; let i = index" class="my-2 note"
                    [class.admin-note]="note.type === 'ADMIN'">
                    <mat-card-content class="p-3">
                      <div style="white-space: pre-line">
                        {{ note.message }}
                      </div>
                      <div *ngIf="note.attachments?.length">
                        <a style="color: blue" href="{{ attachment.contentUrl }}"
                          *ngFor="let attachment of note.attachments">{{ attachment.fileName }}</a>
                      </div>

                      <mat-card-footer class="text-right p-3">
                        <span class="date-label">{{ note.createdBy?.name }} -
                          {{ note?.createdAt ? (note.createdAt?.toDate() | date: 'dd/MM/yyyy HH:mm:ss') : '-' }}
                          {{ note.type === 'ADMIN' ? '- (Admin)' : '' }} - {{ note.agent }}
                        </span>
                        <button (click)="forwardNote(note.message, 'AGENT')" mat-icon-button
                          matTooltip="Encaminhar mensagem">
                          <mat-icon>forward</mat-icon>
                        </button>
                      </mat-card-footer>
                    </mat-card-content>
                  </mat-card>

                  <div class="text-center mt-3" *ngIf="this.agentNotes?.length > 0">
                    <button mat-raised-button class="btn" color="accent" type="button" (click)="viewMoreAgentNotes()">
                      Ver mais <mat-icon>open_in_new</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab [disabled]="!agreedTerms || (noTermsCustomer && !agreedTerms)">
              <ng-template mat-tab-label> Termos de Serviço </ng-template>

              <div id="term" style="margin: 10px" #templateContrato></div>

              <div class="col-12 d-flex justify-content-between mt-2">
                <button *ngIf="['master','admin'].includes(loggedUser.accessLevel)" mat-stroked-button color="warn"
                  [disabled]="isProcessing" type="button" (click)="cancelTerms(title)">
                  Cancelar Termo
                </button>

                <mat-spinner [diameter]="40" class="mx-3 my-2" *ngIf="isProcessing"></mat-spinner>

                <button mat-stroked-button color="primary" type="button" [disabled]="isProcessing"
                  (click)="resendTerms()">
                  Reenviar Termo
                </button>

                <button mat-raised-button color="primary" style="color: white" (click)="onPrintTerm()">
                  <mat-icon>print</mat-icon> Imprimir
                </button>
              </div>
            </mat-tab>
            <mat-tab *ngIf="['master','admin'].includes(loggedUser.accessLevel)" [disabled]="(noTermsCustomer && !agreedTerms)">
              <ng-template mat-tab-label> Cobrança </ng-template>
              <br />
              <app-user-charge *ngIf="customer" [formGroup]="editFormGroup" [customer]="customer">
              </app-user-charge>
            </mat-tab>
            <mat-tab *ngIf="loggedUser?.accessLevel === 'master'">
              <ng-template mat-tab-label> Excluir Cliente </ng-template>

              <div style="margin: 10px" class="notes-area mt-4">
                <h3 style="font-weight: 500"><strong>Excluir usuário</strong></h3>
                <p>ATENÇÃO: A conta do usuário será excluída definitivamente e não poderá ser recuperada!</p>
                <div class="text-center">
                  <button mat-raised-button (click)="deleteCustomerFromAdmin()" color="warn"
                    [disabled]="isProcessingExclused">
                    Excluir Cliente
                    <mat-spinner *ngIf="isProcessingExclused" style="color: white" diameter="40"
                      class="mx-auto"></mat-spinner>
                  </button>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>

          <div class="row top-buffer">
            <div class="col-md-12 text-left">
              <a mat-button class="btn" color="accent" type="button" routerLink="/admin/clientes/buscar">
                <mat-icon>chevron_left</mat-icon> &nbsp;Voltar a listagem
              </a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-12 col-xl-4">
      <app-customer-info-card [serasaDataInput]="serasaData"
        (serasaDataChange)="onSerasaDataChange($event)"></app-customer-info-card>
      <app-serasa-card [isAdmin]="true" [uid]="customerUID"></app-serasa-card>

      <mat-expansion-panel class="container-integracao">
        <mat-expansion-panel-header>
          <mat-panel-title class="title">
            Integrações
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-spinner class="mx-auto my-3" *ngIf="isProcessingScrBmp" color="primary" diameter="40"></mat-spinner>
        <div class="button-integracao" *ngIf="!isProcessingScrBmp">
          <button mat-raised-button (click)="getIntegrationSCR()" type="button">Obter registrato SCR </button>
        </div>
      </mat-expansion-panel>

      <app-user-notes-history [customer]="customer?.uid" [createdBy]="createdBy"></app-user-notes-history>

    </div>
  </div>
</div>