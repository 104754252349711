<div class="dialog">
  <h3 mat-dialog-title>Concluir Oportunidade</h3>

  <div mat-dialog-content>
    <p class="mb-3">Informe as condições finais de contratação</p>

    <div class="text-center spinner" *ngIf="isLoading">
      <mat-spinner diameter="50" class="mx-auto"></mat-spinner>
    </div>

    <section *ngIf="!isLoading">
      <hr />

      <div class="row">
        <div class="col-12 col-lg-2 d-flex align-items-center">
          <span>Taxa:</span>
        </div>
        <mat-radio-group class="col-12 col-lg-10" aria-label="Selecione a opção de taxa" [(ngModel)]="updateInterest">
          <div class="row">
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <mat-radio-button [value]="false" [checked]="!updateInterest" [disabled]="!oppInfo.interest">
                {{ oppInfo.interest }}% a.m.
              </mat-radio-button>
            </div>
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <mat-radio-button [value]="true"></mat-radio-button>
              <mat-form-field color="primary" class="w-100">
                <mat-label>Juros (% a.m.)</mat-label>
                <input
                  matInput
                  size="6"
                  type="number"
                  name="interest"
                  #interest="ngModel"
                  [disabled]="!updateInterest"
                  [(ngModel)]="newInterest"
                  placeholder="Juros (% a.m.)"
                  [required]="updateInterest"
                  min="1"
                />
                <mat-error *ngIf="interest.errors?.required">Informe a taxa de juros</mat-error>
                <mat-error *ngIf="interest.errors?.min">Informe uma taxa válida</mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-radio-group>
      </div>

      <hr />

      <div class="row">
        <div class="col-12 col-lg-2 d-flex align-items-center">
          <span>Prazo:</span>
        </div>

        <mat-radio-group class="col-12 col-lg-10" aria-label="Selecione a opção de prazo" [(ngModel)]="updateTerm">
          <div class="row">
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <mat-radio-button [value]="false" [checked]="!updateTerm" [disabled]="!oppInfo.term">
                {{ oppInfo.term }} meses
              </mat-radio-button>
            </div>
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <mat-radio-button [value]="true"></mat-radio-button>
              <mat-form-field color="primary" class="w-100">
                <mat-label>Prazo (meses)</mat-label>
                <input
                  matInput
                  size="6"
                  type="number"
                  name="term"
                  #term="ngModel"
                  [disabled]="!updateTerm"
                  [(ngModel)]="newTerm"
                  placeholder="Prazo (meses)"
                  [required]="updateTerm"
                  min="1"
                />
                <mat-error *ngIf="term.errors?.required">Informe o prazo</mat-error>
                <mat-error *ngIf="term.errors?.min">Informe um prazo válido</mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-radio-group>
      </div>

      <hr />

      <div class="row">
        <div class="col-12 col-lg-2 d-flex align-items-center">
          <span>Valor:</span>
        </div>

        <mat-radio-group class="col-12 col-lg-10" aria-label="Selecione a opção de valor" [(ngModel)]="updateAmount">
          <div class="row">
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <mat-radio-button [value]="false" [checked]="!updateAmount" [disabled]="!oppInfo.amount">
                R$ {{ oppInfo.amount }}
              </mat-radio-button>
            </div>
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <mat-radio-button [value]="true"></mat-radio-button>
              <mat-form-field color="primary" class="w-100">
                <mat-label>Valor (R$)</mat-label>
                <input
                  matInput
                  size="6"
                  type="number"
                  name="amount"
                  #amount="ngModel"
                  [disabled]="!updateAmount"
                  [(ngModel)]="newAmount"
                  placeholder="Valor (R$)"
                  [required]="updateAmount"
                  min="1"
                />
                <mat-error *ngIf="amount.errors?.required">Informe o valor</mat-error>
                <mat-error *ngIf="amount.errors?.min">Informe um valor válido</mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-radio-group>
      </div>

      <hr />
    </section>

    <p class="my-3">Confira se as informações acima estão corretas antes de confirmar a conclusão.</p>

    <div class="btn-container">
      <button class="m-1" [disabled]="isLoading" mat-stroked-button color="accent" (click)="cancel()">Cancelar</button>
      <button
        class="m-1"
        [disabled]="isLoading"
        mat-raised-button
        color="primary"
        (click)="handleSubmit()"
        [disabled]="!isValidData()"
      >
        <span style="color: white">Concluir</span>
      </button>
    </div>
  </div>
</div>
