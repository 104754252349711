import { A11yModule } from '@angular/cdk/a11y';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { BidiModule } from '@angular/cdk/bidi';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextMaskModule } from 'angular2-text-mask';
// NgBrazil + TextMaskModule
import { NgBrazil } from 'ng-brazil';
import { QuillModule } from 'ngx-quill';
import { ActionInLoteComponent } from '../admin/components/action-in-lote/action-in-lote.component';
import { MenuItems } from '../admin/components/menu-items/menu-items';
import { FormDatepickerComponent } from '../bankers/components/form-datepicker/form-datepicker.component';
import { AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective } from './accordion';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { AlertInputComponent } from './alert-input/alert-input.component';
import { BigInputActionComponent } from './big-input/big-input-action.component';
import { BigInputComponent } from './big-input/big-input.component';
import { BndesScoreComponent } from './bndes-score/bndes-score.component';
import { CardBmpMoneyplusComponent } from './card-bmp-moneyplus/card-bmp-moneyplus.component';
import { ClicksignDialogComponent } from './clicksign-widget/clicksign-dialog.component';
import { CookieConfirmationComponent } from './cookie-confirmation/cookie-confirmation.component';
import { CreditStatusDialogComponent } from './credit-status-dialog/credit-status-dialog.component';
import { DialogConfirmationOnboardingComponent } from './dialog-confirmation-onboarding/dialog-confirmation-onboarding.component';
import { EmailCardComponent } from './email-card/email-card.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FilterOportunityComponent } from './filter-oportunity/filter-oportunity.component';
import { FormCreateAccountNoTermAnticipationComponent } from './form-create-account-no-term-anticipation/form-create-account-no-term-anticipation.component';
import { FormCreateAccountNoTermDefaultComponent } from './form-create-account-no-term-default/form-create-account-no-term-default.component';
import { FormCreateAccountNoTermPropertyComponent } from './form-create-account-no-term-property/form-create-account-no-term-property.component';
import { FormCreateAccountNoTermVehicleComponent } from './form-create-account-no-term-vehicle/form-create-account-no-term-vehicle.component';
import { FormCreateAccountNoTermComponent } from './form-create-account-no-term/form-create-account-no-term.component';
import { FormCreateAccountComponent } from './form-create-account/form-create-account.component';
import { FormFieldsModule } from './form-fields/form-fields.module';
import { ForwardNotesComponent } from './forward-notes/forward-notes.component';
import { HtmlDialogComponent } from './html-dialog/html-dialog.component';
import { InfoCompanyDialogComponent } from './info-company-dialog/info-company-dialog.component';
import { ListOpenOpportunitiesByCnpjComponent } from './list-open-opportunities-by-cnpj/list-open-opportunities-by-cnpj.component';
import { ListOpportunitiesByCnpjComponent } from './list-opportunities-by-cnpj/list-opportunities-by-cnpj.component';
import { NotesDialogComponent } from './notes-dialog/notes-dialog.component';
import { OpportunityHistoryButtonListComponent } from './opportunity-history-button-list/opportunity-history-button-list.component';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { ScoreDialogComponent } from './score-dialog/score-dialog.component';
import { ScoreGaugeMiniComponent } from './score-gauge-mini/score-gauge-mini.component';
import { ScoreGaugeComponent } from './score-gauge/score-gauge.component';
import { SerasaCardComponent } from './serasa-card/serasa-card.component';
import { SliderRangeRevenueComponent } from './slider-range-revenue/slider-range-revenue.component';
import { SpinnerComponent } from './spinner.component';
import { TableDialogComponent } from './table-dialog/table-dialog.component';
import { TemplateMessagesInstitutionComponent } from './template-messages-institution/template-messages-institution.component';
import { TreeChildComponent } from './tree-view/tree-child/tree-child.component';
import { TreeParentComponent } from './tree-view/tree-parent/tree-parent.component';
import { TreeComponent } from './tree-view/tree/tree.component';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { DropZoneDirective } from './upload/drop-zone.directive';
import { FileSizePipe } from './upload/file-size.pipe';
import { UserDocumentsComponent } from './user-documents/user-documents.component';
import { UserFeedDialogComponent } from './user-feed-dialog/user-feed-dialog.component';
import { UserNotesDialogComponent } from './user-notes-dialog/user-notes-dialog.component';
import { UserNotesHistoryComponent } from './user-notes-history/user-notes-history.component';
import { UserNotesComponent } from './user-notes/user-notes.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    A11yModule,
    BidiModule,
    CdkAccordionModule,
    CdkTableModule,
    ClipboardModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    FormFieldsModule,
    QuillModule.forRoot(),
  ],
  declarations: [
    BigInputComponent,
    BigInputActionComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ClicksignDialogComponent,
    SpinnerComponent,
    AlertDialogComponent,
    UploadDialogComponent,
    DropZoneDirective,
    FileSizePipe,
    FileUploadComponent,
    TreeParentComponent,
    TreeChildComponent,
    TreeComponent,
    UserNotesComponent,
    UserNotesDialogComponent,
    HtmlDialogComponent,
    SafeHtmlPipe,
    ForwardNotesComponent,
    UserNotesHistoryComponent,
    UserFeedDialogComponent,
    EmailCardComponent,
    ScoreDialogComponent,
    CookieConfirmationComponent,
    UserSettingsComponent,
    FormCreateAccountComponent,
    TemplateMessagesInstitutionComponent,
    CreditStatusDialogComponent,
    OpportunityHistoryButtonListComponent,
    ListOpportunitiesByCnpjComponent,
    ActionInLoteComponent,
    FilterOportunityComponent,
    UserDocumentsComponent,
    FormDatepickerComponent,
    TableDialogComponent,
    InfoCompanyDialogComponent,
    BndesScoreComponent,
    SliderRangeRevenueComponent,
    AlertInputComponent,
    SerasaCardComponent,
    CardBmpMoneyplusComponent,
    DialogConfirmationOnboardingComponent,
    NotesDialogComponent,
    ListOpenOpportunitiesByCnpjComponent,
    ScoreGaugeComponent,
    ScoreGaugeMiniComponent,
    FormCreateAccountNoTermComponent,
    FormCreateAccountNoTermAnticipationComponent,
    FormCreateAccountNoTermVehicleComponent,
    FormCreateAccountNoTermPropertyComponent,
    FormCreateAccountNoTermDefaultComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgBrazil,
    TextMaskModule,
    BigInputComponent,
    BigInputActionComponent,
    TreeComponent,
    UserNotesHistoryComponent,
    EmailCardComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    A11yModule,
    AlertDialogComponent,
    UploadDialogComponent,
    BidiModule,
    CdkAccordionModule,
    CdkTableModule,
    ClicksignDialogComponent,
    ClipboardModule,
    DropZoneDirective,
    FileSizePipe,
    FileUploadComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    QuillModule,
    UserNotesComponent,
    UserNotesDialogComponent,
    HtmlDialogComponent,
    SafeHtmlPipe,
    CookieConfirmationComponent,
    FormFieldsModule,
    UserSettingsComponent,
    FormCreateAccountComponent,
    TemplateMessagesInstitutionComponent,
    TreeParentComponent,
    OpportunityHistoryButtonListComponent,
    ListOpportunitiesByCnpjComponent,
    ActionInLoteComponent,
    FilterOportunityComponent,
    FormDatepickerComponent,
    BndesScoreComponent,
    SliderRangeRevenueComponent,
    SerasaCardComponent,
    CardBmpMoneyplusComponent,
    ScoreGaugeComponent,
    ScoreGaugeMiniComponent,
    FormCreateAccountNoTermAnticipationComponent,
    FormCreateAccountNoTermPropertyComponent,
    FormCreateAccountNoTermVehicleComponent,
    FormCreateAccountNoTermDefaultComponent
  ],
  providers: [MenuItems],
  entryComponents: [ScoreDialogComponent],
})
export class SharedModule { }
