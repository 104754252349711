import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Agent } from 'functions/src/models/Agent';
import { AgentService } from '../../services/agent.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-agent-header-options',
  templateUrl: './header-options.component.html',
  styleUrls: ['./header-options.component.scss'],
})
export class AgentHeaderOptionsComponent implements OnInit, OnDestroy {
  agent: Agent;
  agentSubscription: Subscription;

  constructor(private router: Router, private authService: AuthService, private agentService: AgentService) {}

  ngOnInit(): void {
    this.agentSubscription = this.agentService.agent.subscribe((agent) => {
      this.agent = agent;
    });
  }

  ngOnDestroy(): void {
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
  }

  onLoginClick(): void {
    this.router.navigate(['/entrar']);
  }

  onLogoutClick(): void {
    this.authService.logout().finally(() => {
      this.router.navigate(['/entrar']);
    });
  }
}
