import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BigDataCorpService {
  constructor(private authService: AuthService, private http: HttpClient, private angularFirestore: AngularFirestore) { }

  getBigDataCorpSavedData(uid: string): Observable<any> {
    return this.angularFirestore.collection(`customers`).doc(uid).collection('bigdatacorp').valueChanges();
  }

  getSavedScore(uid: string): Observable<any> {
    return this.angularFirestore
      .collection(`customers`)
      .doc(uid)
      .collection('bigdatacorp')
      .doc('OnlineQuery')
      .valueChanges();
  }

  runAutomaticIntegration(uid: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.http
          .post(
            `${ environment.functionsUrl }/bigdatacorp/automatic-integration`,
            { uid },
            { responseType: 'json', headers: this.authService.getHeader() }
          )
          .toPromise();
        resolve();
      } catch (err) {
        console.error('Error running automatic integration.', err);
        reject(err);
      }
    });
  }

  getGenralForTools(data: { cnpj: string; datasets: string[] }): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.http
          .post(
            `${ environment.functionsUrl }/bigdatacorp/general-for-tools`,
            data,
            { responseType: 'json', headers: this.authService.getHeader() }
          )
          .toPromise()
          .then((res: any) => {
            resolve(res);
          });
      } catch (err) {
        console.error('Error buscar cnpj.', err);
        reject(err);
      }
    });
  }
}
