<div class="dialog">
  <h4 mat-dialog-title>{{ title }}</h4>
  <div mat-dialog-content>
    <div [innerHtml]="content | safeHtml"></div>
    <div mat-dialog-actions style="padding-top: 20px" class="d-flex justify-content-center">
      <button
        mat-stroked-button
        class="action-button text-black autosized"
        type="button"
        tabindex="-1"
        (click)="onCancel()"
        *ngIf="!isOnlyConfirm"
      >
        Cancelar
      </button>
      <button
        mat-stroked-button
        class="action-button text-black autosized"
        type="button"
        (click)="onOk()"
        *ngIf="isOnlyConfirm"
      >
        Fechar
      </button>
      <button
        mat-raised-button
        color="primary"
        class="action-button autosized"
        type="submit"
        (click)="onOk()"
        tabindex="2"
        *ngIf="!isOnlyConfirm"
      >
        Confirmar
      </button>
    </div>
  </div>
</div>
