<div class="dialog-container" *ngIf="currentStep === 'first'">
  <h2 mat-dialog-title>Revise seus dados</h2>
  <p>Por favor revise se os dados inseridos estão CORRETOS:</p>
  <div mat-dialog-content class="dialog-main-content">
    <div>
      <h3>CNPJ:</h3><span>{{dialogData?.cnpjCtrl}}</span>
    </div>
    <div>
      <h3>Email:</h3><span>{{dialogData?.emailCtrl}}</span>
    </div>
    <div>
      <h3>Whatsapp:</h3><span>{{dialogData?.whatsappCtrl}}</span>
    </div>
    <div mat-dialog-actions class="actions-container">
      <button class="green-button" (click)="setRevenueValue()">Confirmar</button>
      <button class="border-button" (click)="onCancel()">Voltar</button>
    </div>
  </div>
</div>

<div class="dialog-container" *ngIf="currentStep === 'last'">
  <h2 mat-dialog-title>Confirme o valor do seu <span class="highlight">Faturamento Anual</span></h2>
  <p class="revenue-value">{{dialogData?.revenueCtrl}}</p>
  <span class="revenue-description">{{revenueDescription}}</span>
  <div mat-dialog-actions class="actions-container">
    <button class="green-button" (click)="onConfirm()">Confirmar</button>
    <button class="border-button" (click)="onCancel()">Voltar</button>
  </div>
</div>
