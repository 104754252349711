<h2>Iniciar recorrência para o cliente</h2>
<form [formGroup]="faturamentoForm">
  <mat-form-field appearance="fill">
    <mat-label>Faturamento Anual</mat-label>
    <input matInput formControlName="faturamentoAnual" type="number" placeholder="Digite o novo faturamento anual">
  </mat-form-field>
  
  <div style="display: flex; align-items: center; justify-content: center;">
    <button mat-stroked-button class="close-modal-button" type="button" (click)="onCancel()">Cancelar</button>
    <button mat-stroked-button class="close-modal-button" type="button" (click)="onSubmit()">Continuar</button>
  </div>
</form>
