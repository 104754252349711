import { Customer } from 'functions/src/models/Customer';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Agent } from 'functions/src/models/Agent';
import { AgentNote } from 'functions/src/models/Notes';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { NotesService } from '../../../admin/services/notes.service';
import { LeadService } from '../../services/lead.service';

@Component({
  selector: 'app-lead-notes',
  templateUrl: './lead-notes.component.html',
  styleUrls: ['./lead-notes.component.scss'],
})
export class LeadNotesComponent implements OnInit, OnDestroy {
  agent: Agent;
  customerUid: string;
  notificationData: any;

  isCreateMode = false;
  isLoadingNotes = true;

  newNote: AgentNote = {
    message: '',
  };
  searchValue = '';

  notes: AgentNote[] = [];
  filteredNotes: AgentNote[];
  notesSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private notesService: NotesService,
    public leadService: LeadService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.agent = data.agent;
    this.customerUid = data.customerUid;
    this.notificationData = data.notificationData;
  }

  ngOnInit(): void {
    this.notesService
      .getAgentComments({
        customerUid: this.customerUid,
        senderEmail: this.agent.email,
        isSenderAdmin: '',
        agentUid: this.agent.uid,
      })
      .subscribe((notes) => {
        this.notes = notes.slice(0);
        this.isLoadingNotes = false;
      });
  }

  ngOnDestroy(): void {
    if (this.notesSubscription) {
      this.notesSubscription.unsubscribe();
    }
  }

  cancelNote(): void {
    this.isCreateMode = false;
    this.clearNewNote();
  }

  clearNewNote(): void {
    this.newNote.message = '';
  }

  clearSearch(): void {
    this.searchValue = '';
    this.searchNotes();
  }

  createNote(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Salvar registro',
          alertDescription: 'Deseja realmente salvar esse registro de acompanhamento?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const note: AgentNote = {
            ...this.newNote,
            type: 'AGENT',
            agent: this.agent.uid,
            createdBy: {
              name: this.agent.name,
              email: this.agent.email,
            },
          };

          this.notesService
            .createAgentNote(this.customerUid, note, this.notificationData, 'agent-admin')
            .then(() => {
              // this.notes = [{ ...note, createdAt: firestore.Timestamp.now() }, ...this.notes];

              this.isCreateMode = false;
              this.clearNewNote();
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Registro salvo',
                  alertDescription: 'O registro de acompanhamento foi salvo com sucesso.',
                  isOnlyConfirm: true,
                },
              });
            })
            .catch((err) => {
              console.error('Error creating note', err);

              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao salvar',
                  alertDescription: 'Não foi possível salvar o registro. Tente novamente mais tarde.',
                  isOnlyConfirm: true,
                },
              });
            });
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  searchNotes(): void {
    if (this.searchValue) {
      this.filteredNotes = _.filter(
        this.notes,
        (n) => n.message?.toUpperCase().indexOf(this.searchValue.toUpperCase()) >= 0
      );
    } else {
      this.filteredNotes = this.notes;
    }
  }
}
