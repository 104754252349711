import { UserConsent } from './User';

export interface Banker {
  address?: {
    city?: string;
    extraInfo?: string;
    neighborhood?: string;
    number?: string;
    postalCode?: string;
    state?: string;
    street?: string;
  };
  changedPassword?: boolean;
  createdAt?: firebase.firestore.Timestamp;
  displayName?: string;
  email?: string;
  emailVerified?: boolean;
  institution?: {
    label?: string;
    name?: string;
  };
  isAnonymous?: boolean;
  isActive?: boolean;
  isRegionalized?: boolean;
  lastInteraction?: firebase.firestore.Timestamp;
  manuallyCreated?: boolean;
  revenueRange?: {
    maxRevenue?: number;
    maxRevenueStr?: string;
    minRevenue?: number;
    minRevenueStr?: string;
  };
  name?: string;
  notifications?: {
    email?: BankerEmailNotifications;
    statusChanged?: boolean;
    opportunityCreate?: boolean;
    opportunityUpdate?: boolean;
    opportunityAccepted?: boolean;
    opportunityRejected?: boolean;
  };
  occupation?: string;
  phone?: string;
  photoUrl?: string;
  regionalization?: BasicRegionalization[];
  status?: string;
  uid?: string;
  userConsent?: UserConsent;
  whatsapp?: string;
  website?: string;
}
interface BankerEmailNotifications {
  chatMessages?: boolean;
  opportunityClosed?: boolean;
}
export interface BasicRegionalization {
  name: string;
  type: string;
  state?: BasicState;
  region?: BasicRegion;
  city?: { name: string };
  zipCode?: string;
  zipCodeDigitQuantity?: number;
}
export interface BasicState {
  id: number;
  name: string;
  abdr: string;
}
export interface BasicRegion {
  id: number;
  name: string;
  states: { name: string; abdr: string }[];
}

export interface BankerLog {
  date: firebase.firestore.Timestamp;
  event: BankerEvent;
  user?: string;
  description?: string;
  proposal?: BankerProposal;
  contract?: BankerContract;
}

export interface BankersResult {
  page: number;
  pageSize: number;
  total: number;
  results: any[];
}

export interface BankerProposal {
  user: string;
  term: string;
  interest: number;
  amount: number;
  description?: string;
}

export interface BankerContract {
  path?: string;
  submission?: firebase.firestore.Timestamp;
  sender?: string;
}

export interface BankerRegionalizationData {
  digitQuantity: number;
  postalCode: string;
}

export enum BankerStatus {
  'waiting-approval' = 'waiting-approval',
  'approved' = 'approved',
  'rejected' = 'rejected',
  'incomplete' = 'incomplete',
  'suspended' = 'suspended',
}

export enum BankerEvent {
  'created' = 'created',
  'updated' = 'updated',
  'rejected' = 'rejected',
  'confirmed' = 'confirmed',
  'enabled' = 'enabled',
  'disabled' = 'disabled',
  'approved' = 'approved',
  'admin_changed_password' = 'admin_changed_password',
  'updated_contract' = 'updated_contract',
  'updated_proposal' = 'updated_proposal',
  'reject_opportunity' = 'reject_opportunity',
  'reopen_opportunity' = 'reopen_opportunity',
  'report_opportunity' = 'report_opportunity',
}

export enum OrderDirection {
  'asc' = 'asc',
  'desc' = 'desc',
}

export enum BankerOpportunityStatus {
  'waiting' = '1 - Aguardando documentação do cliente',
  'pending' = '2 - Aguardando proposta',
  'ongoing' = '3 - Proposta em andamento',
  'concluded' = '4 - Oportunidade concluída',
  'rejected' = '5 - Oportunidade rejeitada',
}
