
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { Customer } from 'functions/src/models/Customer';
import { OpportunityHistoryEntity } from 'functions/src/models/opportunity/OpportunityHistory';
import {
  OpportunityStageSituationCustomer, OpportunityStageSituationCustomerNoTouch
} from 'functions/src/models/opportunity/OpportunityStages';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { LogService } from 'src/app/components/logger/log.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BndesProposal } from '../../../../../functions/src/models/Bndes';
import { Opportunity } from '../../../../../functions/src/models/opportunity/Opportunity';
import { timestampForDate } from '../../../../../functions/src/utils/dates';
import { InstituicaoFinanceira } from '../../../admin/instituicao-financeira/instituicao-financeira';
import { BigDataCorpService } from '../../../admin/services/bigdatacorp.service';
import { OpportunityManagementService } from '../../../admin/services/opportunity-management.service';
import { BndesService } from '../../../bndes/services/bndes.service';
import { CreditRecurrenceComponent } from '../../../components/credit-recurrence/credit-recurrence.component';
import { ScoreDialogComponent } from '../../../components/score-dialog/score-dialog.component';
import { CustomerCreditDialogComponent } from '../../components/customer-credit-dialog/customer-credit-dialog.component';
import { CustomerService } from '../../services/customer.service';
import { FinancialInstitutionsService } from '../../services/financial-institutions.service';
import { OpportunityHistoryService } from '../../services/opportunity-history.service';
import { StatsService } from './../../../admin/services/stats.service';

import { OpportunityPhasesService } from '../../services/opportunity-phases.service';
import { SidebarStateService } from '../../services/sidebar-state.service';

@Component({
  selector: 'app-user-credit-lines',
  templateUrl: './user-credit-lines.component.html',
  styleUrls: ['./user-credit-lines.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserCreditLinesComponent implements OnInit, OnDestroy {
  opportunities: Opportunity[];
  defaultColumns = ['img', 'name', 'term', 'interest', 'value', 'status', 'actions'];
  creditDataSource: MatTableDataSource<Opportunity>;
  opportunityHistory: OpportunityHistoryEntity[];
  opportunityHistoryGroups: { label: string; dataSource: MatTableDataSource<OpportunityHistoryEntity> }[] = [];
  opportunityHistoryColumns: string[] = ['img', 'institutionName', 'deadlineForMonth', 'limitOperation', 'rate'];
  monthNames: string[] = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ];
  customer: Customer;
  guarantees: any;
  customerUID: string;
  isCustomerNoTouch: boolean;
  customerSubscription: Subscription;
  enableLoading = true;
  hasOppsByRecurrence = false;

  bigdatacorpSubscription: Subscription;

  bndesProposal: BndesProposal;
  getBndesProposalSubscription: Subscription;
  isScoreHintVisible: boolean;

  shouldUseFullTable: boolean;
  layoutSubscription: Subscription;
  verifyResolved: boolean;
  isDetailsButtonVisible: boolean;
  requestRefused: boolean;
  verifyModalResolved = false;
  mostrarBotao: boolean;

  readonly closingReasonOptions = [
    { id: 'Declinado pelo Cliente - Taxa', value: 'Declinado pelo Cliente - Taxa' },
    { id: 'Declinado pelo Cliente - Limite', value: 'Declinado pelo Cliente - Limite' },
    { id: 'Declinado pelo Cliente - Garantia', value: 'Declinado pelo Cliente - Garantia' },
    { id: 'Declinado pelo Cliente - Prazo', value: 'Declinado pelo Cliente - Prazo' },
  ];

  @ViewChild(FileUploadComponent, { static: false }) fileUpload: FileUploadComponent;
  institutions: InstituicaoFinanceira[];

  constructor(
    public authService: AuthService,
    private bigdatacorpService: BigDataCorpService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private financialInstitutionsService: FinancialInstitutionsService,
    private logger: LogService,
    private oppManagementService: OpportunityManagementService,
    private opportunityHistoryService: OpportunityHistoryService,
    private statsService: StatsService,
    breakpointObserver: BreakpointObserver,
    private bndesService: BndesService,
    private activatedRoute: ActivatedRoute,
    private sidebarStateService: SidebarStateService,
    private oppPhasesService: OpportunityPhasesService,
  ) {
    this.logger.controllerName = this.constructor.name;
    this.enableLoading = true;

    this.layoutSubscription = breakpointObserver.observe(['(min-width: 1080px)']).subscribe((result) => {
      this.shouldUseFullTable = result.matches;
    });

    const routeSnapshot = this.activatedRoute.snapshot;
    const routeData = routeSnapshot.data;
    this.sidebarStateService.updateCurrentSection(routeData);
  }

  ngOnInit(): void {
    this.customerSubscription = this.customerService.customer.subscribe(async (customer) => {
      this.customer = customer;

      if (this.customer?.uid) {
        this.customerUID = this.customer.uid;
        this.isCustomerNoTouch = this.customerService.checkNoTouch(this.customer);
        const allGuarantees = customer.guarantees;

        this.getOpportunityHistoryByUid(this.customerUID);

        const guaranteesNames = allGuarantees.map((guarantee) => guarantee.name);
        this.guarantees = guaranteesNames;

        this.bigdatacorpSubscription = this.bigdatacorpService.getSavedScore(this.customer.uid).subscribe((doc) => {
          if (doc?.QueryResultData?.Score) {
            this.customer.companyInformation = {
              ...this.customer.companyInformation,
              score: Number(doc.QueryResultData.Score).toFixed(2),

            };
          }
        });
      }

      if (!(this.opportunities?.length > 0)) {
        await this.getOpportunities();
      } else {
        this.opportunities = [];
      }

      this.isScoreHintVisible = false;
      if (this.customer) {
        if (this.customer.proposalId) {
          this.getBndesProposalSubscription = this.bndesService.getBndesProposal(this.customer.proposalId).subscribe((proposal) => {
            this.bndesProposal = proposal;
          });
        }
        if (!this.bndesProposal) {
          this.bndesService.getBndesProposalByCnpj(this.customer.cnpj).subscribe((proposalByCnpj) => {
            this.bndesProposal = proposalByCnpj;
          });
        }
      }
    });

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreditRecurrenceComponent, {
      width: '250px',
      data: {
        customer: this.customer
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  getOpportunityHistoryByUid(uid: string): void {
    this.opportunityHistoryService
      .getOpportunityHistoryByUid(uid)
      .then((history): void => {
        this.opportunityHistory = history.filter((oppHistory) => oppHistory.stageOpportunity.defaultprobability === 100);
        this.groupOpportunityHistoryByMonth();
      })
      .catch((error) => {
        console.error('Erro ao buscar opportunity history:', error);
      });
  }

  groupOpportunityHistoryByMonth(): void {
    const groupedData: { [key: string]: OpportunityHistoryEntity[] } = {};

    this.opportunityHistory.forEach((opp) => {
      const dateClosing = opp.closingDate.toDate();
      const closingDate = new Date(dateClosing);
      const monthYear = `${ this.monthNames[closingDate.getMonth()] } - ${ closingDate.getFullYear() }`;

      if (groupedData[monthYear]) {
        groupedData[monthYear].push(opp);
      } else {
        groupedData[monthYear] = [opp];
      }
    });

    this.opportunityHistoryGroups = Object.keys(groupedData).map((key) => ({
      label: key,
      dataSource: new MatTableDataSource(groupedData[key]),
    }));

    this.enableLoading = false;
  }

  exportOpportunities(): void {
    if (this.opportunities && this.opportunities.length) {
      const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const extension = 'xlsx';

      const fieldMapping = {
        institutionName: 'Instituição',
        deadlineForMonth: 'Prazo em meses',
        limitOperation: 'Limite Operação',
        rate: 'Taxa',
        stageSituation: 'Estágio da Oportunidade',
      };

      const selectedOpportunitiesData = this.opportunities.map((opportunity, index) => {
        const filteredData = {};
        const fieldsToInclude = Object.keys(fieldMapping);
        const institutionPrefix = opportunity.stageOpportunity.defaultprobability < 40 ? `Instituição ${index + 1}` : opportunity.institutionName;

        for (const field of fieldsToInclude) {
          let fieldValue;
          if (opportunity.stageOpportunity.defaultprobability < 40) {
            fieldValue = '-';
          } else {
            fieldValue = field === 'limitOperation' ? this.formatCurrency(opportunity[field]) : opportunity[field];
            if (field === 'rate') {
              fieldValue = fieldValue + '%';
            }
          }
          if (field === 'institutionName') {
            fieldValue = institutionPrefix;
          }

          filteredData[fieldMapping[field]] = fieldValue !== undefined ? fieldValue : 'Valor padrão';
        }
        return filteredData;
      });

      const blob = new Blob([this.statsService.generateXLSX(selectedOpportunitiesData)], { type });
      const date = new Date().toLocaleDateString();
      saveAs(blob, `Planilha de Oportunidades_${date}.${extension}`.toLowerCase().replace(/\s/g, '-'));
    }
  }

  private formatCurrency(value: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }

  getOpportunities(opps?: Opportunity[]): Promise<void> {
    return Promise.all([opps || this.oppManagementService.getOpportunities(this.customerUID)]).then(
      async ([opportunities]) => {
        const [opportunity] = opportunities.sort((a, b) => a.closingDate < b.closingDate ? 1 : -1)
        const allOppsClosed = !opportunities?.find(opp => ![100, 0].includes(opp.stageOpportunity.defaultprobability))
        let hasAllOppsByRecurrence = true;

        if (opportunity && allOppsClosed) {
          for (const opp of opportunities) {
            this.onHasOppsByRecurrence(opp);
            // Se uma oportunidade não atender à condição de recorrência, marque como false
            if (!this.hasOppsByRecurrence) {
              hasAllOppsByRecurrence = false;
              break;
            }
          }
          // Atualiza o valor de hasOppsByRecurrence baseado nas verificações
          this.hasOppsByRecurrence = hasAllOppsByRecurrence;
        }

        this.opportunities = (
          await Promise.all(
            opportunities.map(async (opp) => {
              const institutionData = await this.financialInstitutionsService
                .getFinancialInstitution(opp.institutionId)
                .pipe(take(1))
                .toPromise();

              if (institutionData?.arquivoInfo && opp.lineActive) {
                opp.logoPath = institutionData.arquivoInfo.path;
              }

              const defaultprobability = opp.stageOpportunity?.defaultprobability || 0;
              const isRevenueAboveThreshold = this.customer.revenue >= 3000000;
              const isRateAvailable = institutionData?.defaultRate;
              const visibleDataStageopportunity = defaultprobability > 0 && defaultprobability < 40;

              if (isRateAvailable && !opp.lineActive && isRevenueAboveThreshold && visibleDataStageopportunity) {
                opp.lineActive = true;
                opp.rate = isRateAvailable;
              } else if (visibleDataStageopportunity) {
                opp.lineActive = false;
              }

              opp.stageSituation = OpportunityStageSituationCustomer[opp.stageOpportunity?.defaultprobability] || '-';

              if(institutionData?.noTouch?.id === "sim") {
                opp.stageSituation = OpportunityStageSituationCustomerNoTouch[opp.stageOpportunity?.defaultprobability] || '-';
              }
              try{
                opp.showDetailBtn = await this.oppPhasesService.hasOppPhaseGreaterEqual(30, opp.uid, opp.institutionKey);
                if(opp.showDetailBtn  === false && opp.stageOpportunity?.defaultprobability === 0){
                  opp.stageSituation = "Perfil não enquadrado";
                }
              }
              catch{
                opp.showDetailBtn = false;
              }
              
              return opp;
            })
          )
        ).sort((opp1, opp2) =>
          opp1.stageOpportunity?.defaultprobability <= opp2.stageOpportunity?.defaultprobability ? 1 : -1
        );
        this.enableLoading = false;
        this.creditDataSource = new MatTableDataSource(this.opportunities);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }

    if (this.layoutSubscription) {
      this.layoutSubscription.unsubscribe();
    }

    if(this.getBndesProposalSubscription){
      this.getBndesProposalSubscription.unsubscribe();
    }
  }

  getCardBorderColorClass(opp: Opportunity): string {
    const stage = opp?.stageOpportunity?.defaultprobability;
    switch (stage) {
      case 0:
        return 'red-border';
      case 40:
        return 'yellow-border';
      case 60:
      case 80:
      case 90:
        return 'blue-border';
      case 100:
        return 'green-border';
      default:
        return 'grey-border';
    }
  }


  openScoreDialog(): void {
    this.dialog.open(ScoreDialogComponent);
  }

  async onOpenProposalDialog(opportunity: Opportunity): Promise<void> {
    let institution = null;
    const nameIf = this.operationToCustomerVisibleText(opportunity);

    try {
      institution = await this.financialInstitutionsService
        .getFinancialInstitution(opportunity.institutionId)
        .pipe(take(1))
        .toPromise();
    } catch (err) {
      console.error('Error getting institution data', err);
    }

    const dialogRef = this.dialog
      .open(CustomerCreditDialogComponent, {
        minWidth: '50%',
        maxWidth: '713px',
        data: {
          proposal: opportunity,
          institution,
          nameIf
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response === true) {
          this.enableLoading = true;
          this.onCustomerProceedOpp(opportunity);
        } else if (response !== undefined) {
          opportunity.motivationOfCancellation = response.nome;
          this.enableLoading = true;
          this.onCustomerRejectOpp(opportunity, response.nome);
        }

        if (dialogRef) {
          dialogRef.unsubscribe();
        }
      });
  }

  isWaitingForCustomerResponse(operacao: any): boolean {
    return operacao?.stageSituation === 'Oferta disponível! Veja agora!';
  }

  onHasOppsByRecurrenceOld(opp: Opportunity): void {
    const today = new Date();
    const fechado = new Date(opp.closingDate);
    const diffInMs = Math.abs(today.getTime() - fechado.getTime());
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    console.log('diffInDays', diffInDays, this.hasOppsByRecurrence);
    this.hasOppsByRecurrence = diffInDays >= 90;
  }

  onHasOppsByRecurrence(opp: Opportunity): void {
    const lastRecurrenceDate = this.customer.lastRecurrenceDate ? timestampForDate(this.customer.lastRecurrenceDate) : null;
    const closingDate = new Date(opp.closingDate);
    const dateOpp = !lastRecurrenceDate ? closingDate : (closingDate > lastRecurrenceDate ? closingDate : lastRecurrenceDate);
    const diffInMs = Math.abs(new Date().getTime() - dateOpp.getTime());
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    this.hasOppsByRecurrence = diffInDays >= 90;
  }

  isDisabled(opp: Opportunity): boolean {
    const stage = opp?.stageOpportunity.defaultprobability;
    return stage === 100;
  }


  onCustomerProceedOpp(selectedOpp: Opportunity): void {
    this.oppManagementService
      .proceedCustomerOpp(this.customer, selectedOpp)
      .then(() => this.getOpportunities())
      .finally(() => (this.enableLoading = false));
  }

  onCustomerRejectOpp(selectedOpp: Opportunity, reason: string): void {
    this.oppManagementService
      .rejectCustomerOpp(this.customer, selectedOpp, reason)
      .then(() => this.getOpportunities())
      .finally(() => (this.enableLoading = false));
  }

  showActionBtn(opp: Opportunity): boolean {
    const stage = opp?.stageOpportunity?.defaultprobability;
    return stage >= 40;
  }

  operationToCustomerVisibleText(opp: Opportunity): string {
    const stage = opp?.stageOpportunity?.defaultprobability;
    return stage > 30
      ? opp.institutionName
      : 'Instituição ' +
      (this.opportunities?.filter((o) => o?.stageOpportunity?.defaultprobability < 40).indexOf(opp) + 1);
  }

  operationToCustomerVisibleCondition(opp: Opportunity): boolean {
    const stage = opp?.stageOpportunity?.defaultprobability;
    return stage > 30;
  }

  iconCheck(opp: Opportunity): boolean {
    const stage = opp?.stageOpportunity?.defaultprobability;
    return stage === 100;
  }

  iconX(opp: Opportunity): boolean {
    const stage = opp?.stageOpportunity?.defaultprobability;
    return stage === 0;
  }

  getLogoPath(opp: Opportunity): string | null {
    if (opp.logoPath && opp.logoPath.indexOf('http') >= 0 && opp.stageOpportunity?.defaultprobability > 30) {
      return opp.logoPath;
    } else {
      return null;
    }
  }

  pegarFase(opp: any){
    this.oppPhasesService.hasOppPhaseGreaterEqual(30, opp.uid, opp.institutionKey).then((res) => {
      console.log('res', res);
    })
  }

  showDetailsButton(opp: any){
    if((opp.stageOpportunity.defaultprobability === 0 && !opp.showDetailBtn) || opp.stageOpportunity.defaultprobability > 0 &&  opp.stageOpportunity.defaultprobability <= 20 ){
      return false
    }
    return true
  }

  calcValidityMessage(opportunity: any): string {
    if (opportunity && opportunity.lastStageChangeDate && opportunity.proposedValidity) {
      const validDays = opportunity.proposedValidity;
  
      if (typeof validDays === 'number') {
        const creationDate = new Date(opportunity.lastStageChangeDate);
        const expirationDate = new Date(creationDate);
        expirationDate.setDate(creationDate.getDate() + validDays);
  
        const currentDate = new Date();
        const timeDiff = expirationDate.getTime() - currentDate.getTime();
        const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
  
        if (daysRemaining > 0) {
          return `Faltam ${daysRemaining} dias para expirar, até ${expirationDate.toLocaleDateString()}`;
        } else {
          return `Venceu em ${expirationDate.toLocaleDateString()}`;
        }
      }
    }
    return '-';
  }

  isExpired(opportunity: any): boolean {
    const today = new Date();
    const creationDate = new Date(opportunity.lastStageChangeDate);
    const proposedValidity = opportunity.proposedValidity ? opportunity.proposedValidity : today;
    const expirationDate = new Date(creationDate);
    expirationDate.setDate(expirationDate.getDate() + proposedValidity);

    return today >= expirationDate;
  }
  
}
