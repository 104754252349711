import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../core/auth/auth.service';
import { ChatMenuItem } from '../../models/chat-menuItem';
import { ChatMenuService } from '../../services/chat-menu.service';

@Component({
  selector: 'app-container-assigned-to-me',
  templateUrl: './container-assigned-to-me.component.html',
  styleUrls: ['./container-assigned-to-me.component.scss']
})
export class ContainerAssignedToMeComponent implements OnDestroy {
  loggedUserSubscription: Subscription;
  loggedUser: { uid: string; name: string | 'Unknown'; email: string };
  constructor(private chatMenuService: ChatMenuService, private authService: AuthService) {
    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser) => {
      this.loggedUser = {
        uid: loggedUser.uid,
        name: loggedUser.name || 'Unknown',
        email: loggedUser.email,
      };
    });
    this.onMenuitemSelected({ state: 'atribuidos-a-mim', name: 'Atribuidos a mim', type: 'link' });
  }

  onMenuitemSelected(menuitem: ChatMenuItem): void {
    if (!menuitem.selected) {
      menuitem.selected = true;
    }
    this.chatMenuService.setMenuItem(menuitem);
  }

  ngOnDestroy(): void {

    if (this.loggedUserSubscription) {
      this.loggedUserSubscription.unsubscribe();
    }
  }

}
