import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { firestore } from 'firebase';
import { User, UserRole } from 'functions/src/models/User';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TemplateSignupService as ImageService } from 'src/app/layouts/templates/template-signup/template-signup.service';
import { UserManagementService } from '../../../admin/services/user-management.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
  isProcessing = false;
  resetEmailWasSent = false;
  agreedPrivacyPolicy = false;
  isLoading = true;

  readonly urlPrivacyPolicy =
    'https://f.hubspotusercontent10.net/hubfs/8256969/Institucional/Pol%C3%ADtica%20de%20Privacidade%20-%20Capital%20Empreendedor.pdf';
  authSubscription: Subscription;
  user: User;
  type: string;
  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private imageService: ImageService,
    private router: Router,
    private userManagementService: UserManagementService
  ) {
    this.imageService.setImagePath('assets/images/signup/illustration1.svg');
  }

  ngOnInit(): void {
    this.authSubscription = this.authService.user.subscribe((user) => {
      if (user) {
        this.isLoading = false;
        this.user = user;
        if (user?.userConsent?.privacyPolicyDate) {
          this.router.navigate(['/entrar']);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  logout(): void {
    this.authService.logout().then(() => {
      this.router.navigate(['/entrar']);
    });
  }

  agreedPrivatyPolicy(): void {
    this.isProcessing = true;

    try {
      const privacyPolicyDate = firestore.Timestamp.now().toMillis();
      this.userManagementService
        .signPrivacyPolicy(this.user.uid, privacyPolicyDate, this.user.mainRole)
        .then(() => {
          this.isProcessing = false;
        })
        .catch(() => {
          this.isProcessing = false;
          this.showMessage(
            'Erro',
            'Ocorreu um erro ao tentar realizar o aceite da Política de Privacidade. Tente novamente.'
          );
        });
    } catch (error) {}
  }

  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }
}
