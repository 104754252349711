import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { User } from '../../../../functions/src/models/User';
import { InstituicaoFinanceira } from '../instituicao-financeira/instituicao-financeira';
import { InstituicaoFinanceiraService } from '../instituicao-financeira/instituicao-financeira.service';


@Component({
  selector: 'app-remuneracao-utm',
  templateUrl: './remuneracao-utm.component.html',
  styleUrls: ['./remuneracao-utm.component.scss']
})
export class RemuneracaoUtmComponent implements OnInit {
  isChecked: boolean;
  isCheckedRemuneracao: boolean;
  createEditFormGroup: FormGroup;

  @Input() instituicaoFinanceira: InstituicaoFinanceira;
  @Input() loggedUser: User;


  constructor(
    public _data: InstituicaoFinanceiraService

  ) {

     this.isChecked = true;
     this.isCheckedRemuneracao = false;

  }

  ngOnInit(): void {
    this.onLoadForm();


    if (this.instituicaoFinanceira) {
      this.isCheckedRemuneracao = this.instituicaoFinanceira?.haveRemuneration;

      const typeBoolean = typeof this.instituicaoFinanceira?.marketingCommission === 'boolean' ? this.instituicaoFinanceira?.marketingCommission : true;
      this.isChecked = typeBoolean;

    }

}


  onLoadForm(): void {
    this.createEditFormGroup = new FormGroup ({
    marketingCommissionCtrl: new FormControl(),
    haveRemunerationCtrl: new FormControl(),
    descriptionRemunerationCtrl: new FormControl(''),
    utmSourceStrCtrl: new FormControl(''),
    commissionPayTypeCtrl: new FormControl(''),
    commissionPayValueCtrl: new FormControl(''),
    });

    this.createEditFormGroup.get('marketingCommissionCtrl').setValue(this.instituicaoFinanceira.marketingCommission);
    this.createEditFormGroup.get('haveRemunerationCtrl').setValue(this.instituicaoFinanceira.haveRemuneration);
    this.createEditFormGroup.get('descriptionRemunerationCtrl').setValue(this.instituicaoFinanceira.descriptionRemuneration);
    this.createEditFormGroup.get('utmSourceStrCtrl').setValue(this.instituicaoFinanceira.utmSourceStr);
    this.createEditFormGroup.get('commissionPayTypeCtrl').setValue(this.instituicaoFinanceira.commissionPayType);
    this.createEditFormGroup.get('commissionPayValueCtrl').setValue(this.instituicaoFinanceira.commissionPayValue);
  }


  toggleMarketing(event): void {
    this.isChecked = event.checked;
  }

  toggleRemuneracao(event): void {
    this.isCheckedRemuneracao = event.checked;
  }

  onSaveForm(): void {

    this.instituicaoFinanceira.descriptionRemuneration = this.createEditFormGroup.controls.descriptionRemunerationCtrl.value || null,
    this.instituicaoFinanceira.utmSourceStr = this.createEditFormGroup.controls?.utmSourceStrCtrl?.value || null,
    this.instituicaoFinanceira.marketingCommission = this.createEditFormGroup.controls?.marketingCommissionCtrl?.value || false,
    this.instituicaoFinanceira.haveRemuneration = this.createEditFormGroup.controls.haveRemunerationCtrl.value || false,
    this.instituicaoFinanceira.commissionPayType = this.createEditFormGroup.controls?.commissionPayTypeCtrl?.value || null,
    this.instituicaoFinanceira.commissionPayValue = this.createEditFormGroup.controls?.commissionPayValueCtrl?.value || null,

    this._data.updateInstituicaoFinanceira(this.instituicaoFinanceira);

  }

}
