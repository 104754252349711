import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-security-first-layer',
  templateUrl: './user-security-first-layer.component.html',
  styleUrls: ['./user-security-first-layer.component.scss']
})
export class UserSecurityFirstLayerComponent implements OnInit {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor( private router: Router) { }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

  navigateToChangePassword(): void {
    this.router.navigate(['minha-conta', 'alterar-minha-senha']);
  }

  navigateToCancelAccount(): void {
    this.router.navigate(['minha-conta', 'cancelar-minha-conta']);
  }

}
