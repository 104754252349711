/* eslint-disable @typescript-eslint/no-var-requires */
import { animate, state, style, transition, trigger } from '@angular/animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import firebase from 'firebase/app';
import { User } from 'functions/src/models/User';
import { BasicStructure } from 'functions/src/models/common/BasicStructure';
import { GetInstitutionDocuments, InstitutionDocuments } from 'functions/src/models/model-interface';
import {
  OpportunityStageSituation,
  OpportunityStageSituationAdmin
} from 'functions/src/models/opportunity/OpportunityStages';
import _ from 'lodash';
import _moment from 'moment';
import { MASKS } from 'ng-brazil';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { LogService } from 'src/app/components/logger/log.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { ArquivoInfo, Document } from '../../../../functions/src/models/UserDocument';
import { OpportunityStage } from '../../../../functions/src/models/opportunity/OpportunityStage';
import { UtilHandler } from '../../core/handler/util.handler';
import { CadastrosGeraisItem } from '../cadastros-gerais/cadastros-gerais';
import { CadastrosGeraisService } from '../cadastros-gerais/cadastros-gerais.service';
import { OpportunityStreamDialogProps } from '../components/opportunity-stream-dialog/opportunity-stream-dialog-interface';
import { OpportunityStreamDialogComponent } from '../components/opportunity-stream-dialog/opportunity-stream-dialog.component';
import { OpportunityManagementService } from '../services/opportunity-management.service';
import { IntegrateService, IntegrationOpportunity } from './Integrate.service';
import {
  BasicCnae,
  BasicRegionalization,
  BasicTypeCnae,
  CnaeMatchOption,
  ContaBancariaSF,
  InstituicaoFinanceira
} from './instituicao-financeira';
import { InstituicaoFinanceiraService } from './instituicao-financeira.service';

_moment.locale('pt-BR');
export const MY_FORMATS = {
  parse: {
    dateInput: 'LLL',
  },
  display: {
    dateInput: 'LLL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LLL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-instituicao-financeira',
  templateUrl: './instituicao-financeira.component.html',
  styleUrls: ['./instituicao-financeira.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ display: 'none', height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InstituicaoFinanceiraComponent implements OnInit, OnDestroy {
  @ViewChild('matSortCnae', { static: false }) set matSortC(ms1: MatSort) {
    this.matSortCnae = ms1;
    this.dataSourceCnae.sort = this.matSortCnae;
  }
  @ViewChild('paginatorCnae', { static: false }) set matPaginatorC(mp1: MatPaginator) {
    this.paginatorCnae = mp1;
    this.dataSourceCnae.paginator = this.paginatorCnae;
  }
  @ViewChild('matSortInstFinanc', { static: false }) set matSortIF(ms1: MatSort) {
    this.matSortInstFinanc = ms1;
    this.dataSourceInstFinanc.sort = this.matSortInstFinanc;
  }
  @ViewChild('paginatorInstFinanc', { static: false }) set matPaginatorIF(mp1: MatPaginator) {
    this.paginatorInstFinanc = mp1;
    this.dataSourceInstFinanc.paginator = this.paginatorInstFinanc;
  }

  constructor(
    public _data: InstituicaoFinanceiraService,
    private formBuilder: FormBuilder,
    private cadastrosGeraisService: CadastrosGeraisService,
    private dialog: MatDialog,
    private logger: LogService,
    private authService: AuthService,
    private configService: ConfigurationService,
    private integrateService: IntegrateService,
    private oppService: OpportunityManagementService
  ) {
    this.logger.controllerName = this.constructor.name;
    this.dataSourceInstFinanc = new MatTableDataSource([]);
    this.dataSourceCnae = new MatTableDataSource([]);
    this.isCreateEdit = false;
    this.associatedDocumentsOptions = [];
    this.tituloFormInstituicao = '';
    this.apiActiveConfig = false;
    this.opportunityStepsOptions = oppService.getAllOppStages();
    this.FiDocuments = { documentsData: [], documents: [], };
  }
  readonly MASKS = MASKS;
  oppStagesValues = Object.keys(OpportunityStageSituationAdmin);
  oppStage = OpportunityStageSituationAdmin;
  createEditFormGroup: FormGroup;
  createEditFormGroupLocality: FormGroup;
  CNAEFormGroup: FormGroup;
  localityFormGroup: FormGroup;
  isCreateEdit: boolean;
  tituloFormInstituicao: string;
  isEdit = false;
  isLoadingAction = false;
  isTypeOfAnticipationEnabled = false;
  istypeOfAnticipationCard = false;
  expandedElement: InstituicaoFinanceira;
  instituicaoFinanceira: InstituicaoFinanceira;
  expandedElementId: string;
  newlocality = false;
  isRegionalized: boolean;
  regionalization: Array<BasicRegionalization>;
  states: Array<any> = [];
  cities: Array<any> = [];
  citiesList: Array<any> = [];
  regions: Array<any> = [];

  integrate?: IntegrationOpportunity;

  isDialogVisible = false;
  dialogView: 'first' | 'second' = 'first';
  oppStreamDialogData: OpportunityStreamDialogProps;

  stageAproved: OpportunityStage;
  runSaveInstitutionDocuments = false;
  // Cnae
  optionsCnae: Array<BasicCnae>;
  cnaeLote: string;
  selectedOption: any;
  readonly selectTypecnaeMatchOptions = [
    { id: 'aceita_todos', name: 'Aceita todos os CNAEs' },
    { id: 'aceita_somente_esses', name: 'Aceita somente os CNAEs listados abaixo' },
    { id: 'nao_aceita_esses', name: 'Não aceita os CNAEs listados abaixo' },
  ];

  columnsCnae: string[] = ['id', 'type', 'description', 'actions'];
  dataSourceCnae: MatTableDataSource<BasicCnae>;

  cnaeMatchOption: CnaeMatchOption;
  dataCnaeTable: BasicCnae[];
  private matSortCnae: MatSort;
  private paginatorCnae: MatPaginator;
  typeOfAnticipation: any;

  columnsInstFinanc: string[] = ['imagem', 'nome', 'tipo', 'faturamentoMinimo', 'faturamentoMaximo', 'ativo', 'acao'];
  dataSourceInstFinanc: MatTableDataSource<InstituicaoFinanceira>;
  dataInstFinancTable: InstituicaoFinanceira[];
  private matSortInstFinanc: MatSort;
  private paginatorInstFinanc: MatPaginator;

  readonly criteria: BasicStructure[] = [
    { id: 'region', name: 'Região' },
    { id: 'state', name: 'Estado / Cidade' },
    // { id: 'zipcode', name: 'CEP' }
  ];
  readonly opcaoSimNao: BasicStructure[] = [
    { id: 'sim', name: 'Sim' },
    { id: 'nao', name: 'Não' },
  ];
  readonly opcaoOppPhase = [
    { id: '30', name: '30%' },
    { id: '40', name: '40%' },
    { id: '60', name: '60%' },
    { id: '80', name: '80%' },
    { id: '90', name: '90%' },
  ];
  readonly opportunityStreamOptions = [
    {id: 'touch', name: 'Touch'},
    {id: 'no-touch', name: 'No touch'},
  ]

  autoMsgList;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  intervalInDaysList = [];

  readonly cnaeTypes: BasicTypeCnae[] = [
    { id: 'divisoes', name: 'Divisão', digitsNumber: 2 },
    { id: 'grupos', name: 'Grupo', digitsNumber: 3 },
    { id: 'classes', name: 'Classe', digitsNumber: 5 },
    { id: 'subclasses', name: 'Subclasse', digitsNumber: 7 },
  ];

  tipoInstituicaoFinanceira: CadastrosGeraisItem[];
  setores: CadastrosGeraisItem[];
  garantias: CadastrosGeraisItem[];
  creditCard: CadastrosGeraisItem[];
  taxRegimes: BasicStructure[];
  linhas: CadastrosGeraisItem[];
  modalidades: CadastrosGeraisItem[];
  contasBancarias: ContaBancariaSF[];

  currencyMask = createNumberMask({
    decimalLimit: 2,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: false,
    integerLimit: 15,
    prefix: 'R$ ',
    suffix: '',
  });
  scoreMask = createNumberMask({
    decimalLimit: 2,
    thousandsSeparatorSymbol: '',
    decimalSymbol: ',',
    allowDecimal: true,
    integerLimit: 4,
    prefix: '',
    suffix: '',
  });
  showPopup = false;

  @ViewChild('documentosAtivosSelecionados', { static: false }) documentosAtivosSelecionados;
  FiDocuments: GetInstitutionDocuments;
  associatedDocumentsOptions: BasicStructure[];
  documentosAtivos: Document[];
  tituloInstFinanc: string;

  getIFSubscription: Subscription;
  getTipoIFAtivasSubscription: Subscription;
  getSetoresAtivosSubscription: Subscription;
  getGarantiasAtivasSubscription: Subscription;
  getCreditCardAtivasSubscription: Subscription;
  getTypeOfAnticipationSubscription: Subscription;
  getTaxRegimesSubscription: Subscription;
  getLinhasAtivasSubscription: Subscription;
  getModalidadesAtivasSubscription: Subscription;
  getInstFinancDocumentsSubscription: Subscription;
  getTiposDocumentosAtivosSubscription: Subscription;
  fileInfoFormGroup: FormGroup;
  @ViewChild(FileUploadComponent, { static: false }) fileUpload: FileUploadComponent;
  loggedUser: User;
  loggedUserSubscription: Subscription;
  apiActiveConfig: boolean;



  readonly opportunityStepsOptions: OpportunityStage[];

  // filters
  ifsSelectedGuarantees: FormControl;
  ifsGuaranteesOperator: FormControl;
  ifsSelectedModality: FormControl;
  ifsModalityOperator: FormControl;
  ifscreditCardOperator: FormControl;
  ifsSelectedTaxRegime: FormControl;
  ifsSelectedAnticipations: FormControl;
  ifsTaxRegimeOperator: FormControl;
  ifsName: FormControl;
  ifsAtivo: FormControl;
  ifsNoTouch: FormControl;
  ifsMin: FormControl;
  ifsMax: FormControl;

  typeOfAnticipationList: BasicStructure[];

  hasPermission = false;

  avaiableAPI = '';
  switchDisabledAPI = false;
  switchedCheckedAPI = false;

  private validatePermission(): void {

    if (!this.isCreateEdit) {
      console.log('Admin - Have Search Institution');
    } else if (this.loggedUser?.accessLevel === 'master' || this.loggedUser?.accessLevel === 'admin') {
      console.log('Admin - Have Permision');
      this.hasPermission = true;
      this.CNAEFormGroup.enable();
      this.localityFormGroup.enable();
      this.localityFormGroup.enable();
      this.createEditFormGroup.enable();
      this.createEditFormGroupLocality?.enable();
    } else if (!this.isEdit) {
      console.log('Admin - Have Permision by Created Institution');
    } else {
      this.hasPermission = false;
      this.CNAEFormGroup.disable();
      this.localityFormGroup.disable();
      this.localityFormGroup.disable();
      this.createEditFormGroup.disable();
      this.createEditFormGroupLocality?.disable();
    }
  }

  ngOnInit(): void {
    // filters
    this.onLoadFormControlsByFilters();

    // Regionalizations
    this.onLoadLocalityFormGroup();

    // Cnae
    this.onLoadCnaeFormGroup();

    this.loggedUserSubscription = this.authService.user.subscribe((userLogged) => {
      this.loggedUser = userLogged;
      this.validatePermission();
    });

    this.getIFSubscription = this._data.getInstituicoesFinanceiras().subscribe((result) => {
      this.dataInstFinancTable = result;
      this.dataSourceInstFinanc = new MatTableDataSource(this.dataInstFinancTable);
      this.dataSourceInstFinanc.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'tipo':
            return item.tipo.nome;
          case 'ativo':
            return item.ativo.name;
          default:
            return item[property];
        }
      };
      this.dataSourceInstFinanc.sort = this.matSortInstFinanc;
      this.dataSourceInstFinanc.paginator = this.paginatorInstFinanc;

      if (this.expandedElement && this.expandedElement.ativo) {
        // Caso já esteja expandido, obtem nova referencia do objeto atualizado pelo stream.
        this.expandedElement = this.dataSourceInstFinanc.filteredData.find((a) => a.id === this.expandedElementId);
      }
    });

    this.getTipoIFAtivasSubscription = this.cadastrosGeraisService
      .getTipoInstituicoesFinanceiraAtivas()
      .subscribe((tipos) => {
        this.tipoInstituicaoFinanceira = tipos;
      });

    this.getSetoresAtivosSubscription = this.cadastrosGeraisService.getSetoresAtivos().subscribe((setores) => {
      this.setores = setores;
    });

    this.getGarantiasAtivasSubscription = this.cadastrosGeraisService.getGarantiasAtivas().subscribe((garantias) => {
      this.garantias = garantias;
    });

    this.getCreditCardAtivasSubscription = this.cadastrosGeraisService.getMaquinasAtivas().subscribe((creditCard) => {
      this.creditCard = creditCard;
    });

    this.getTypeOfAnticipationSubscription = this.configService.getConfiguration('signup').subscribe((config) => {
      this.typeOfAnticipationList = config.typeOfAnticipation;
    });

    this.getTaxRegimesSubscription = this.cadastrosGeraisService.getRegimesAtivos().subscribe((regimes) => {
      regimes.map((regime) => {
        regime['id'] = regime.mnemonico;
        regime['name'] = regime.nome;

        delete regime.ativo;
        delete regime.mnemonico;
        delete regime.nome;
      });

      this.taxRegimes = regimes;
    });

    this.getLinhasAtivasSubscription = this.cadastrosGeraisService.getLinhasAtivas().subscribe((linhas) => {
      this.linhas = linhas;
    });

    this.getModalidadesAtivasSubscription = this.cadastrosGeraisService
      .getModalidadesAtivas()
      .subscribe((modalidades) => {
        this.modalidades = modalidades;
      });

    this._data
      .getStates()
      .toPromise()
      .then((states) => {
        states.map((st) => {
          this.states.push({ id: st.id, name: st.nome, abdr: st.sigla });
        });
      })
      .catch((err) => {
        console.error('Erro ao buscar os Estados: ', err);
      });

    /*this.getRegionsSubscription = */
    this._data
      .getRegions()
      .toPromise()
      .then((regions) => {
        // regions igual a todas as regiões
        regions.forEach((region) => {
          // region igual a cada região
          this._data
            .getUfsRegions(region.id)
            .toPromise()
            .then((statesOfRegion) => {
              // statesOfRegion igual a todos os estados que sao de uma determinada região
              const statesArray = [];

              statesOfRegion.map((st) => statesArray.push({ name: st.nome, abdr: st.sigla }));
              this.regions.push({
                id: region.id,
                name: region.nome,
                states: statesArray,
              });
            })
            .catch((err) => {
              console.error('Erro ao buscar os Estados de cada Região: ', err);
            });
        });
      })
      .catch((err) => {
        console.error('Erro ao buscar as Rgiões: ', err);
      });
  }

  private onLoadFormControlsByFilters(): void {
    this.ifsSelectedGuarantees = new FormControl({ value: '', disabled: true });
    this.ifsGuaranteesOperator = new FormControl();
    this.ifsSelectedModality = new FormControl({ value: '', disabled: true });
    this.ifsModalityOperator = new FormControl();
    this.ifsSelectedTaxRegime = new FormControl({ value: '', disabled: true });
    this.ifsSelectedAnticipations = new FormControl({ value: '', disabled: false });
    this.ifsTaxRegimeOperator = new FormControl();
    this.ifsName = new FormControl();
    this.ifsAtivo = new FormControl();
    this.ifsNoTouch = new FormControl();
    this.ifsMin = new FormControl();
    this.ifsMax = new FormControl();
  }

  private onLoadLocalityFormGroup(): void {
    this.localityFormGroup = this.formBuilder.group({
      ifsLocalityNameCtrl: new FormControl('', Validators.required),
      ifsLocalityTypeCtrl: new FormControl('' /* , Validators.required */),
      ifsLocalityStateCtrl: new FormControl('' /* , Validators.required */),
      ifsLocalityRegionsCtrl: new FormControl('' /* , Validators.required */),
      ifsLocalityCityCtrl: new FormControl('' /* , Validators.required */),
      ifsLocalityZipCodeCtrl: new FormControl('', [/* Validators.required, */ Validators.minLength(10)]),
      ifsLocalityZipCodeQTDNumberCtrl: new FormControl('', [
            /* Validators.required, */ Validators.min(1),
        Validators.max(8),
      ]),
    });
  }

  compareOppStage(s1, s2): boolean {
    return s1?.defaultprobability === s2?.defaultprobability;
  }

  private onLoadCnaeFormGroup(): void {
    this.CNAEFormGroup = this.formBuilder.group({
      cnaeCtrl: new FormControl({ value: '', disabled: true }),
      typeCtrl: new FormControl(),
      descriptionCtrl: new FormControl(''),
    });
  }

  getCitiesforUF(id: number): void {
    this.cities = [];
    this._data
      .getCities(id)
      .toPromise()
      .then((cities) => {
        cities.map((city) => this.cities.push({ name: city.nome }));
      })
      .catch((err) => {
        console.error('Erro ao buscar as Cidades : ', err);
      });
  }

  getCitiesListforUF(id: number, index: number): void {
    this.citiesList[index] = [];
    this._data
      .getCities(id)
      .toPromise()
      .then((c) => {
        c.map((city) => this.citiesList[index].push({ name: city.nome }));
      })
      .catch((err) => {
        console.error('Erro ao buscar as Cidades : ', err);
      });
  }

  compareName(option, value): boolean {
    return option.name === value.name;
  }

  citiesListFunction(reg: BasicRegionalization[]): void {
    this.citiesList = [];
    if (reg.length > 0) {
      reg.forEach((r) => {
        if (r.state) {
          const c = [];

          this._data
            .getCities(r?.state?.id)
            .toPromise()
            .then((cities) => {
              cities.map((city) => {
                c.push({ name: city.nome });
              });
            })
            .catch((err) => {
              console.error('Erro ao buscar as Cidades : ', err);
            });
          this.citiesList.push(c);
        } else {
          this.citiesList.push(null);
        }
      });
    }
  }

  isLocalityInvalid(): boolean {
    const type = this.localityFormGroup.get('ifsLocalityTypeCtrl').value || 'zipcode';

    if (
      !this.localityFormGroup.get('ifsLocalityNameCtrl').value ||
      this.localityFormGroup.get('ifsLocalityNameCtrl').invalid
    ) {
      return true;
    } else {
      if (type === 'region') {
        return !this.localityFormGroup.get('ifsLocalityRegionsCtrl').value;
      }

      if (type === 'state') {
        return !this.localityFormGroup.get('ifsLocalityStateCtrl').value;
      }

      if (type === 'zipcode') {
        return (
          !this.localityFormGroup.get('ifsLocalityZipCodeCtrl').value ||
          !this.localityFormGroup.get('ifsLocalityZipCodeQTDNumberCtrl').value
        );
      } else {
        return (
          (!this.localityFormGroup.get('ifsLocalityZipCodeCtrl').value &&
            this.localityFormGroup.get('ifsLocalityZipCodeQTDNumberCtrl').value) ||
          (this.localityFormGroup.get('ifsLocalityZipCodeCtrl').value &&
            !this.localityFormGroup.get('ifsLocalityZipCodeQTDNumberCtrl').value)
        );
      }
    }
  }

  removeLocality(l): void {
    this.regionalization.splice(this.regionalization.indexOf(l), 1);
    this.citiesList.splice(this.citiesList.indexOf(l), 1);
    this._data.updateInstituicaoFinanceira(this.instituicaoFinanceira);
  }

  saveLocality(): void {
    this.isRegionalized = true;
    this.instituicaoFinanceira.isRegionalized = this.isRegionalized;
    this.instituicaoFinanceira.regionalization = this.regionalization;
    this._data.updateInstituicaoFinanceira(this.instituicaoFinanceira);
  }

  onAddNewLocality(): void {
    const type = this.localityFormGroup.get('ifsLocalityTypeCtrl').value || 'zipcode';
    this.regionalization.push({
      type,
      name: this.localityFormGroup.get('ifsLocalityNameCtrl')?.value || null,
      region: type === 'region' ? this.localityFormGroup.get('ifsLocalityRegionsCtrl')?.value || null : null,
      state: type === 'state' ? this.localityFormGroup.get('ifsLocalityStateCtrl')?.value || null : null,
      city: type === 'state' ? this.localityFormGroup.get('ifsLocalityCityCtrl')?.value || null : null,
      zipCode: this.localityFormGroup.get('ifsLocalityZipCodeCtrl')?.value || '',
      zipCodeDigitQuantity: this.localityFormGroup.get('ifsLocalityZipCodeQTDNumberCtrl')?.value || 0,
    });

    const stateID = this.localityFormGroup.get('ifsLocalityStateCtrl')?.value?.id || null;
    if (stateID) {
      this._data
        .getCities(stateID)
        .toPromise()
        .then((cities) => {
          this.citiesList.push(cities.map((city) => ({ name: city.nome })));
        });
    } else {
      this.citiesList.push(null);
    }

    // salvar regionalizacao no banco
    this.saveLocality();

    // resetar o valor dos campos e setar os erros para null
    this.localityFormGroup.get('ifsLocalityTypeCtrl')?.reset();
    this.localityFormGroup.get('ifsLocalityTypeCtrl')?.setErrors(null);
    this.localityFormGroup.get('ifsLocalityTypeCtrl')?.markAsUntouched();

    this.localityFormGroup.get('ifsLocalityNameCtrl')?.reset('');
    this.localityFormGroup.get('ifsLocalityNameCtrl')?.setErrors(null);
    this.localityFormGroup.get('ifsLocalityNameCtrl')?.markAsUntouched();

    this.localityFormGroup.get('ifsLocalityStateCtrl')?.reset('');
    this.localityFormGroup.get('ifsLocalityStateCtrl')?.setErrors(null);
    this.localityFormGroup.get('ifsLocalityStateCtrl')?.markAsUntouched();

    this.localityFormGroup.get('ifsLocalityStateCtrl')?.reset('');
    this.localityFormGroup.get('ifsLocalityStateCtrl')?.setErrors(null);
    this.localityFormGroup.get('ifsLocalityStateCtrl')?.markAsUntouched();

    this.localityFormGroup.get('ifsLocalityRegionsCtrl')?.reset('');
    this.localityFormGroup.get('ifsLocalityRegionsCtrl')?.setErrors(null);
    this.localityFormGroup.get('ifsLocalityRegionsCtrl')?.markAsUntouched();

    this.localityFormGroup.get('ifsLocalityCityCtrl')?.reset('');
    this.localityFormGroup.get('ifsLocalityCityCtrl')?.setErrors(null);
    this.localityFormGroup.get('ifsLocalityCityCtrl')?.markAsUntouched();

    this.localityFormGroup.get('ifsLocalityZipCodeCtrl').setValue('');
    this.localityFormGroup.get('ifsLocalityZipCodeCtrl')?.reset('');
    this.localityFormGroup.get('ifsLocalityZipCodeCtrl')?.setErrors(null);
    this.localityFormGroup.get('ifsLocalityZipCodeCtrl')?.markAsUntouched();

    this.localityFormGroup.get('ifsLocalityZipCodeQTDNumberCtrl')?.reset('');
    this.localityFormGroup.get('ifsLocalityZipCodeQTDNumberCtrl')?.setErrors(null);
    this.localityFormGroup.get('ifsLocalityZipCodeQTDNumberCtrl')?.markAsUntouched();
  }

  ngOnDestroy(): void {
    if (this.getIFSubscription) {
      this.getIFSubscription.unsubscribe();
    }
    if (this.getTipoIFAtivasSubscription) {
      this.getTipoIFAtivasSubscription.unsubscribe();
    }
    if (this.getSetoresAtivosSubscription) {
      this.getSetoresAtivosSubscription.unsubscribe();
    }
    if (this.getGarantiasAtivasSubscription) {
      this.getGarantiasAtivasSubscription.unsubscribe();
    }
    if (this.getCreditCardAtivasSubscription) {
      this.getCreditCardAtivasSubscription.unsubscribe();
    }
    if (this.getTypeOfAnticipationSubscription) {
      this.getTypeOfAnticipationSubscription.unsubscribe();
    }
    if (this.getTaxRegimesSubscription) {
      this.getTaxRegimesSubscription.unsubscribe();
    }
    if (this.getLinhasAtivasSubscription) {
      this.getLinhasAtivasSubscription.unsubscribe();
    }
    if (this.getModalidadesAtivasSubscription) {
      this.getModalidadesAtivasSubscription.unsubscribe();
    }
    if (this.getInstFinancDocumentsSubscription) {
      this.getInstFinancDocumentsSubscription.unsubscribe();
    }
    if (this.getTiposDocumentosAtivosSubscription) {
      this.getTiposDocumentosAtivosSubscription.unsubscribe();
    }
    if (this.loggedUserSubscription) {
      this.loggedUserSubscription.unsubscribe();
    }
  }

  filterObjectArray(Instituions: InstituicaoFinanceira[], param: string, operator: string, value: string[]) {
    const data = _.filter(Instituions, (ifs) => {
      if (operator && value) {
        if (!ifs[`${ param }`]) {
          return false;
        } else {
          const ifsParam = ifs[`${ param }`].map((g) => g.id).sort();
          value = value.sort();

          switch (operator) {
            case 'exactly':
              if (
                ifsParam.length !== _.intersection(ifsParam, value).length ||
                _.intersection(ifsParam, value).length !== value.length
              ) {
                return false;
              }
              break;

            case 'yes-all':
              if (_.intersection(ifsParam, value).length !== value.length) {
                return false;
              }
              break;

            case 'yes-any':
              if (_.intersection(ifsParam, value).length === 0) {
                return false;
              }
              break;

            case 'not-all':
              if (_.intersection(ifsParam, value).length !== 0) {
                return false;
              }
              break;

            case 'not-any':
              if (_.intersection(ifsParam, value).length === value.length) {
                return false;
              }
              break;

            default:
              return false;
          }
        }
      }
      return true;
    });
    return data;
  }

  onClickExpanded() {
    if (this.expandedElement) {
      this.expandedElementId = this.expandedElement.id;
    } else {
      this.expandedElementId = null;
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceInstFinanc.filter = filterValue;
  }

  filterFaturamento(institutions: InstituicaoFinanceira[], param: string, value: number) {
    const data = institutions;
    const dataFilter = [];
    data.map((d) => {
      if (param.indexOf('Min') > -1 && d[`${ param }`] >= Number(value)) {
        dataFilter.push(d);
      } else if (param.indexOf('Max') > -1 && d[`${ param }`] <= Number(value)) {
        dataFilter.push(d);
      }
    });

    return dataFilter;
  }

  faturamentoMaximo(): number {
    return this.ifsMax?.value ? Number(this.ifsMax?.value) : null;
  }

  faturamentoMinimo(): number {
    return this.ifsMin?.value ? Number(this.ifsMin?.value) : null;
  }

  getData(): void {
    /// atribuido todos as Instituições Financeiras
    let data = this.dataInstFinancTable;
    data = this.ifsName?.value
      ? data.filter((item) => item?.nome?.toUpperCase()?.includes(this.ifsName?.value?.toUpperCase()))
      : data;
    // filtro de ativo/inativo
    data = this.ifsAtivo?.value ? data.filter((item) => item?.ativo?.id?.includes(this.ifsAtivo?.value)) : data;
    //filtro de no touch
    data = this.ifsNoTouch?.value ? (this.ifsNoTouch?.value === "sim" ? data.filter((item) => item?.noTouch?.id?.includes(this.ifsNoTouch?.value)) : data.filter((item) => item?.noTouch?.id !== 'sim')): data;
    // filtro de garantias
    data = this.ifsSelectedGuarantees?.value?.length
      ? this.filterObjectArray(data, 'garantias', this.ifsGuaranteesOperator?.value, this.ifsSelectedGuarantees?.value)
      : data;

    // filtro de recebiveis (testar)
    data = this.ifsSelectedAnticipations?.value?.length
      ? this.filterObjectArray(
        data,
        'typeOfAnticipation',
        this.ifsGuaranteesOperator?.value,
        this.ifsSelectedAnticipations?.value
      )
      : data;

    // filtro de modalidades
    data = this.ifsSelectedModality?.value?.length
      ? this.filterObjectArray(data, 'modalidade', this.ifsModalityOperator?.value, this.ifsSelectedModality?.value)
      : data;
    // filtro de modalidades
    data = this.ifsSelectedTaxRegime?.value?.length
      ? this.filterObjectArray(data, 'taxRegime', this.ifsTaxRegimeOperator?.value, this.ifsSelectedTaxRegime?.value)
      : data;
    // filtro de faturamento min
    data = this.ifsMin?.value ? this.filterFaturamento(data, 'faturamentoMinimo', this.ifsMin?.value) : data;
    // filtro de faturamento max
    data = this.ifsMax?.value ? this.filterFaturamento(data, 'faturamentoMaximo', this.ifsMax?.value) : data;

    this.dataSourceInstFinanc.data = data;
  }

  private valMinMaxValidator(formGroup: FormGroup) {
    const valorMinimo = formGroup.get('faturamentoMinimoStrCtrl').value
      ? +formGroup.get('faturamentoMinimoStrCtrl').value.replace(/\D+/g, '')
      : 0;
    const valorMaximo = formGroup.get('faturamentoMaximoStrCtrl').value
      ? +formGroup.get('faturamentoMaximoStrCtrl').value.replace(/\D+/g, '')
      : 0;
    const error = valorMinimo > valorMaximo ? { mismatch: true } : null;

    if (valorMaximo > 0) {
      formGroup.get('faturamentoMinimoStrCtrl').setErrors(error);
    }
    formGroup.get('faturamentoMaximoStrCtrl').setErrors(error);

    const capitalMinimo = formGroup.get('capitalMinimoStrCtrl').value
      ? +formGroup.get('capitalMinimoStrCtrl').value.replace(/\D+/g, '')
      : 0;
    const capitalMaximo = formGroup.get('capitalMaximoStrCtrl').value
      ? +formGroup.get('capitalMaximoStrCtrl').value.replace(/\D+/g, '')
      : 0;
    const capitalerror = capitalMinimo > capitalMaximo ? { mismatch: true } : null;

    if (capitalMaximo > 0) {
      formGroup.get('capitalMinimoStrCtrl').setErrors(capitalerror);
    }
    formGroup.get('capitalMaximoStrCtrl').setErrors(capitalerror);

    const scoreMin = formGroup.get('scoreMinStrCtrl').value
      ? +formGroup.get('scoreMinStrCtrl').value.replace(',', '.')
      : 0;
    const scoreMax = formGroup.get('scoreMaxStrCtrl').value
      ? +formGroup.get('scoreMaxStrCtrl').value.replace(',', '.')
      : 0;
    const scoreError = scoreMin > scoreMax ? { mismatch: true } : null;

    if (scoreMax > 0) {
      formGroup.get('scoreMinStrCtrl').setErrors(scoreError);
    }
    formGroup.get('scoreMaxStrCtrl').setErrors(scoreError);
    return null;
  }

  onLoadForm(): void {
    this.createEditFormGroup = this.formBuilder.group(
      {
        idCtrl: new FormControl('', []),
        nomeCtrl: new FormControl('', Validators.required),
        tipoCtrl: new FormControl('', Validators.required),
        defaultInitialStageCtrl: new FormControl(10, Validators.required),
        opportunityStreamCtrl: new FormControl('',[]),
        descriptionCtrl: new FormControl('', []),
        commentsCtrl: new FormControl('', []),
        publicDescriptionCtrl: new FormControl('', []),
        faturamentoMinimoStrCtrl: new FormControl('', Validators.required),
        faturamentoMaximoStrCtrl: new FormControl('', Validators.required),
        scoreMinStrCtrl: new FormControl('', []),
        scoreMaxStrCtrl: new FormControl('', []),
        endividamento25pCtrl: new FormControl('', Validators.required),
        minCompanyAgeCtrl: new FormControl(''),
        minCompanyMonthCtrl: new FormControl(''),
        recuperacaoJudicialCtrl: new FormControl('', []),
        setoresCtrl: new FormControl('', []),
        garantiasCtrl: new FormControl('', Validators.required),
        creditCardCtrl: new FormControl(''),
        cnaeMatchOptionCtrl: new FormControl(''),
        typeOfAnticipationCtrl: new FormControl('', []),
        linhasCtrl: new FormControl('', []),
        ativoCtrl: new FormControl('', Validators.required),
        noTouchCtrl: new FormControl('', []),
        valorOperacaoStrCtrl: new FormControl('', []),
        modalidadeCtrl: new FormControl('', []),
        taxRegimeCtrl: new FormControl('', []),
        prazoCtrl: new FormControl('', Validators.required),
        capitalMinimoStrCtrl: new FormControl('', []),
        capitalMaximoStrCtrl: new FormControl('', []),
        pefinCtrl: new FormControl('', Validators.required),
        refinCtrl: new FormControl('', Validators.required),
        fraudeCtrl: new FormControl(''),
        chequeSemFundoCtrl: new FormControl(''),
        nomeNoSistemaCtrl: new FormControl({ value: '', disabled: this.isEdit }, Validators.required),
        naoIncluirAvaliacaoCtrl: new FormControl('', []),
        automaticBacenCtrl: new FormControl('', Validators.required),
        defaultBacenStageCtrl: new FormControl(20, Validators.required),
        committeeStageCtrl: new FormControl(Validators.required),
        clicksignTemplateKeyCtrl: new FormControl('', []),
        documentTypeBacenCtrl: new FormControl('', []),
        remuneracaoVariavelCtrl: new FormControl({ value: true }, []),
      },
      { validator: this.valMinMaxValidator }
    );

    console.log(this.createEditFormGroup)
  }

  onAdicionarNovo(): void {
    this.isEdit = false;
    this.tituloFormInstituicao = ' - Novo cadastro';
    this.isCreateEdit = true;
    this.isTypeOfAnticipationEnabled = false;
    this.istypeOfAnticipationCard = false;
    this.onLoadForm();
    this.validatePermission();
    const optionYes: BasicStructure = { id: 'sim', name: 'Sim' };
    const optionNo: BasicStructure = { id: 'nao', name: 'Não' };

    const formFields = [
      { name: 'ativoCtrl', value: optionYes },
      { name: 'noTouchCtrl', value: optionNo },
      { name: 'pefinCtrl', value: optionYes },
      { name: 'refinCtrl', value: optionYes },
      { name: 'chequeSemFundoCtrl', value: optionYes },
      { name: 'fraudeCtrl', value: optionYes },
      { name: 'automaticBacenCtrl', value: optionNo },
    ];
    formFields.forEach(({ name, value }) => {
      this.createEditFormGroup.get(name).setValue(value);
    });
  }

  onEditar(instituicaoFinanceira: InstituicaoFinanceira): void {

    console.log('chamou o editar')
    this.instituicaoFinanceira = instituicaoFinanceira;

    // cnae
    this.dataCnaeTable = instituicaoFinanceira?.cnae || [];
    this.dataSourceCnae = new MatTableDataSource(this.dataCnaeTable);
    this.dataSourceCnae.sort = this.matSortCnae;
    this.dataSourceCnae.paginator = this.paginatorCnae;

    if (this.instituicaoFinanceira?.cnaeMatchOption) {
      this.selectedOption = this.selectTypecnaeMatchOptions.find(
        (op) => op.id === this.instituicaoFinanceira.cnaeMatchOption.id
      );
    } else {
      this.selectedOption = this.selectTypecnaeMatchOptions[0];
    }

    // regionalization
    instituicaoFinanceira.isRegionalized = this.isRegionalized || (instituicaoFinanceira?.regionalization?.length > 0 || false);
    this.regionalization = instituicaoFinanceira?.regionalization || [];
    this.citiesListFunction(this.regionalization);

    this.isEdit = true;
    this.tituloFormInstituicao = ' - ' + instituicaoFinanceira.nome;
    this.isCreateEdit = true;

    if (instituicaoFinanceira.typeOfAnticipation && instituicaoFinanceira.typeOfAnticipation.length > 0) {
      this.isTypeOfAnticipationEnabled = true;
    }

    if (
      instituicaoFinanceira?.typeOfAnticipation &&
      instituicaoFinanceira?.typeOfAnticipation.find((d) => d.id === 'cartao')
    ) {
      this.istypeOfAnticipationCard = true;
    }

    this.onLoadForm();
    this.getDocumentos(instituicaoFinanceira.id);
    this.loadFileUpload();
    this.validatePermission();

    const optionYes: BasicStructure = { id: 'sim', name: 'Sim' };
    const optionNo: BasicStructure = { id: 'nao', name: 'Não' };
    enum FormFieldsOptions {
      CONVERT_TO_STRING = 'convert_to_string',
      CHECK_IF_EXISTS = 'check_if_exists',
      CURRENCY = 'currency',
      OPTIONAL = 'optional',
    }

    const formFields = [
      { name: 'id', options: [] },
      { name: 'nome', options: [] },
      { name: 'nomeNoSistema', options: [] },
      { name: 'tipo', options: [] },
      { name: 'defaultInitialStage', options: [], defaultValue: 10 },
      { name: 'committeeStage', options: []},
      { name: 'opportunityStream', options: [] },
      { name: 'description', options: [] },
      { name: 'comments', options: [] },
      { name: 'publicDescription', options: [] },
      {
        name: 'faturamentoMinimo',
        options: [FormFieldsOptions.CHECK_IF_EXISTS, FormFieldsOptions.CONVERT_TO_STRING, FormFieldsOptions.CURRENCY],
      },
      {
        name: 'faturamentoMaximo',
        options: [FormFieldsOptions.CHECK_IF_EXISTS, FormFieldsOptions.CONVERT_TO_STRING, FormFieldsOptions.CURRENCY],
      },
      { name: 'endividamento25p', options: [] },
      { name: 'minCompanyAge', options: [] },
      { name: 'minCompanyMonth', options: [] },
      { name: 'recuperacaoJudicial', options: [] },
      { name: 'setores', options: [] },
      { name: 'garantias', options: [] },
      { name: 'creditCard', options: [] },
      { name: 'typeOfAnticipation', options: [] },
      { name: 'linhas', options: [] },
      { name: 'ativo', options: [] },
      { name: 'noTouch', options: [] },
      {
        name: 'valorOperacao',
        options: [
          FormFieldsOptions.CHECK_IF_EXISTS,
          FormFieldsOptions.CONVERT_TO_STRING,
          FormFieldsOptions.CURRENCY,
          FormFieldsOptions.OPTIONAL,
        ],
      },
      { name: 'modalidade', options: [] },
      { name: 'taxRegime', options: [] },
      { name: 'prazo', options: [] },
      {
        name: 'capitalMinimo',
        options: [
          FormFieldsOptions.CHECK_IF_EXISTS,
          FormFieldsOptions.CONVERT_TO_STRING,
          FormFieldsOptions.CURRENCY,
          FormFieldsOptions.OPTIONAL,
        ],
      },
      {
        name: 'capitalMaximo',
        options: [
          FormFieldsOptions.CHECK_IF_EXISTS,
          FormFieldsOptions.CONVERT_TO_STRING,
          FormFieldsOptions.CURRENCY,
          FormFieldsOptions.OPTIONAL,
        ],
      },
      { name: 'pefin', options: [], defaultValue: optionYes },
      { name: 'refin', options: [], defaultValue: optionYes },
      { name: 'chequeSemFundo', options: [], defaultValue: optionYes },
      { name: 'fraude', options: [], defaultValue: optionYes },
      { name: 'naoIncluirAvaliacao', options: [FormFieldsOptions.CHECK_IF_EXISTS] },
      { name: 'remuneracaoVariavel', options: [FormFieldsOptions.CHECK_IF_EXISTS] },
      { name: 'automaticBacen', options: [FormFieldsOptions.CHECK_IF_EXISTS], defaultValue: optionNo },
      { name: 'defaultBacenStage', options: [], defaultValue: 20 },
      { name: 'committeeStageCtrl', option: []},
      { name: 'clicksignTemplateKey', options: [FormFieldsOptions.CHECK_IF_EXISTS] },
      { name: 'documentTypeBacen', options: [FormFieldsOptions.CHECK_IF_EXISTS] },
    ];

    formFields.forEach(({ name, options, defaultValue }) => {
      const checkExist = options?.includes(FormFieldsOptions.CHECK_IF_EXISTS);
      const toString = options?.includes(FormFieldsOptions.CONVERT_TO_STRING);
      const isCurrency = options?.includes(FormFieldsOptions.CURRENCY);
      const isOptional = options?.includes(FormFieldsOptions.OPTIONAL);

      // If is optional, permit null values
      if (isOptional) {
        if (instituicaoFinanceira[name] === null) {
          this.createEditFormGroup.get(`${ name }StrCtrl`).setValue(null);
          return;
        }
        // if value !== null, simply continues
      }

      // Currency have special rules
      if (isCurrency) {
        const value =
          UtilHandler.getFormattedPrice(instituicaoFinanceira[name] ?? 0, { removeCents: true }) || defaultValue;
        this.createEditFormGroup.get(`${ name }StrCtrl`).setValue(value);
        return;
      }
      if (checkExist) {
        if (instituicaoFinanceira[name] !== null && instituicaoFinanceira[name] !== undefined) {
          if (toString) {
            this.createEditFormGroup
              .get(`${ name }StrCtrl`)?.setValue(
                instituicaoFinanceira[`${ name }Str`] || (instituicaoFinanceira[name] || defaultValue).toString()
              );
          } else {
            this.createEditFormGroup.get(`${ name }Ctrl`)?.setValue(instituicaoFinanceira[name] || defaultValue);
          }
        }
      } else {
        if (toString) {
          this.createEditFormGroup
            .get(`${ name }StrCtrl`)?.setValue(instituicaoFinanceira[`${ name }Str`] || (instituicaoFinanceira[name] || defaultValue).toString());
        } else {
          this.createEditFormGroup.get(`${ name }Ctrl`)?.setValue(instituicaoFinanceira[name] || defaultValue);
        }
      }
    });

    

    this.integrateService.findIntegrateByName(instituicaoFinanceira.nomeNoSistema).subscribe((result) => {
      if (result) {
        if (!!result?.isAvailable) {
          this.apiActiveConfig = true;
        }
        this.integrate = result;
        this.integrate.isActive = !!result?.isActive || false;
      }
    })

  }

  getDocumentos(instFinancId) {
    console.log('chamou serviço de docs')
    this.getInstFinancDocumentsSubscription = this._data.getInstFinancDocuments(instFinancId).subscribe((docs) => {

      
      if (docs && docs.documentsData && docs.documentsData.length > 0) {
        this.FiDocuments = docs;
        console.log(this.FiDocuments)
        this.FiDocuments.stageAproved = this.FiDocuments.stageAproved || null;
        this.associatedDocumentsOptions = docs.documentsData.map((doc) => ({
          id: doc.id,
          name: doc.nome,
        }));
      } else {
        this.FiDocuments = { documentsData: [], documents: [] };
        this.associatedDocumentsOptions = [];
      }

      this.getTiposDocumentosAtivosSubscription = this._data.getTiposDocumentosAtivos().subscribe((tiposDocumentos) => {
        this.documentosAtivos = tiposDocumentos.filter((item) => {
          const td = this.FiDocuments.documentsData.find((f) => f.id === item.id);

          if (!td) {
            item.situacao = 'Pendente';
            item.qtdArquivosEsperados = 1;
            item.qtdArquviosSubmetidos = 0;
            item.instituicaoFinanceiraId = instFinancId;

            if (item.anoExercicio.id === 'sim') {
              item.qtdArquivosEsperados = item.anoExercicioQtd;
            }

            return item;
          }
        });
      });
    });
  }

  async onSaveStagesDocument(): Promise<void> {
    this.runSaveInstitutionDocuments = true
    try {
      const updateStrages: Partial<InstitutionDocuments> = {
        stageAproved: this.FiDocuments.stageAproved
      }

      await this._data.updateFinancialInstitutionDocuments(this.instituicaoFinanceira.id, updateStrages);
      console.log('Success Save Stages Documents');
    } catch (error) {
      console.log('Error Save Stages Documents', error);
    }
    this.runSaveInstitutionDocuments = false
  }

  onVoltar() {
    if (this.getInstFinancDocumentsSubscription) {
      this.getInstFinancDocumentsSubscription.unsubscribe();
    }
    if (this.getTiposDocumentosAtivosSubscription) {
      this.getTiposDocumentosAtivosSubscription.unsubscribe();
    }
    this.tituloFormInstituicao = '';
    this.isCreateEdit = false;
    this.isTypeOfAnticipationEnabled = false;
    this.istypeOfAnticipationCard = false;
  }

  onSaveForm() {
    const instituicaoFinanceira: InstituicaoFinanceira = {
      nome: this.createEditFormGroup.value?.nomeCtrl ?? null,
      nomeNoSistema: this.createEditFormGroup.controls.nomeNoSistemaCtrl.value ?? null,
      tipo: this.createEditFormGroup.value?.tipoCtrl ?? null,
      description: this.createEditFormGroup.value?.descriptionCtrl ?? null,
      publicDescription: this.createEditFormGroup.value?.publicDescriptionCtrl ?? null,
      faturamentoMinimo: +this.createEditFormGroup.value?.faturamentoMinimoStrCtrl.replace(/\D+/g, '') || null,
      faturamentoMaximo: +this.createEditFormGroup.value?.faturamentoMaximoStrCtrl.replace(/\D+/g, '') || null,
      scoreMin: +this.createEditFormGroup.value?.scoreMinStrCtrl.replace(',', '.') || null,
      scoreMax: +this.createEditFormGroup.value?.scoreMaxStrCtrl.replace(',', '.') || null,
      defaultInitialStage: +this.createEditFormGroup.value?.defaultInitialStageCtrl as OpportunityStageSituation || null,
      opportunityStream: this.createEditFormGroup.value?.opportunityStreamCtrl ?? null,
      endividamento25p: this.createEditFormGroup.value?.endividamento25pCtrl ?? null,
      minCompanyAge: this.createEditFormGroup.value?.minCompanyAgeCtrl ?? 0,
      minCompanyMonth: this.createEditFormGroup.value?.minCompanyMonthCtrl ?? 0,
      recuperacaoJudicial: this.createEditFormGroup?.value?.recuperacaoJudicialCtrl ?? null,
      ativo: this.createEditFormGroup.value?.ativoCtrl ?? null,
      noTouch: this.createEditFormGroup.value?.noTouchCtrl ?? null,
      setores: this.createEditFormGroup?.value?.setoresCtrl ?? null,
      garantias: this.createEditFormGroup.value?.garantiasCtrl ?? null,
      creditCard: this.createEditFormGroup.value?.creditCardCtrl ?? null,
      typeOfAnticipation: this.createEditFormGroup.value?.typeOfAnticipationCtrl ?? null,
      linhas: this.createEditFormGroup.value?.linhasCtrl ?? null,
      valorOperacao: this.createEditFormGroup.value?.valorOperacaoStrCtrl
        ? this.createEditFormGroup.value?.valorOperacaoStrCtrl.replace(/\D+/g, '')
        : null,
      modalidade: this.createEditFormGroup.value?.modalidadeCtrl || [],
      taxRegime: this.createEditFormGroup.value?.taxRegimeCtrl ?? null,
      prazo: this.createEditFormGroup.value?.prazoCtrl ?? null,
      capitalMinimo: this.createEditFormGroup.value?.capitalMinimoStrCtrl
        ? +this.createEditFormGroup.value?.capitalMinimoStrCtrl.replace(/\D+/g, '')
        : null,
      capitalMaximo: this.createEditFormGroup.value?.capitalMaximoStrCtrl
        ? +this.createEditFormGroup.value?.capitalMaximoStrCtrl.replace(/\D+/g, '')
        : null,
      pefin: this.createEditFormGroup.value?.pefinCtrl ?? null,
      refin: this.createEditFormGroup.value?.refinCtrl ?? null,
      fraude: this.createEditFormGroup.value?.fraudeCtrl ?? null,
      chequeSemFundo: this.createEditFormGroup.value?.chequeSemFundoCtrl ?? null,
      naoIncluirAvaliacao: this.createEditFormGroup?.value?.naoIncluirAvaliacaoCtrl ?? null,
      automaticBacen: this.createEditFormGroup.value?.automaticBacenCtrl ?? null,
      defaultBacenStage: +this.createEditFormGroup.value?.defaultBacenStageCtrl as OpportunityStageSituation || null,
      committeeStage: +this.createEditFormGroup.value?.committeeStageCtrl as OpportunityStageSituation || null,
      clicksignTemplateKey: this.createEditFormGroup?.value?.clicksignTemplateKeyCtrl ?? null,
      documentTypeBacen: this.createEditFormGroup?.value?.documentTypeBacenCtrl ?? null,
      remuneracaoVariavel: this.createEditFormGroup?.value?.remuneracaoVariavelCtrl ?? false,
      
    };
  
    if(this.oppStreamDialogData != null){
      instituicaoFinanceira.instructions= {
      numberOfInstructions: this.oppStreamDialogData?.numberOfInstructions ?? null,
      firstInstruction: this.oppStreamDialogData?.firstInstruction ?? null,
      secondInstruction: this.oppStreamDialogData?.secondInstruction ?? null,
      thirdInstruction: this.oppStreamDialogData?.thirdInstruction ?? null,
      fourthInstruction: this.oppStreamDialogData?.fourthInstruction ?? null,
      fifthInstruction: this.oppStreamDialogData?.fifthInstruction ?? null,
    },
    instituicaoFinanceira.comments= this.oppStreamDialogData.comments ? this.oppStreamDialogData.comments : (this.createEditFormGroup.value?.commentsCtrl ? this.createEditFormGroup.value?.commentsCtrl: null),
    instituicaoFinanceira.proposedValidity= this.oppStreamDialogData.proposedValidity ?? null,
    instituicaoFinanceira.buttonText= this.oppStreamDialogData.buttonText ?? null,
    instituicaoFinanceira.buttonUrl= this.oppStreamDialogData.buttonUrl ?? null,
    instituicaoFinanceira.useReturnApi= this.oppStreamDialogData.useReturnApi ?? null
    }
    


    instituicaoFinanceira.faturamentoMinimoStr = UtilHandler.getFormattedPrice(instituicaoFinanceira.faturamentoMinimo);
    instituicaoFinanceira.faturamentoMaximoStr = UtilHandler.getFormattedPrice(instituicaoFinanceira.faturamentoMaximo);
    instituicaoFinanceira.scoreMinStr = instituicaoFinanceira.scoreMin?.toString() || null;
    instituicaoFinanceira.scoreMaxStr = instituicaoFinanceira.scoreMax?.toString() || null;
  
    // Regionalização
    if (this.isCreateEdit) {
      instituicaoFinanceira.isRegionalized = this.isRegionalized || (this.regionalization?.length > 0 || false);
      instituicaoFinanceira.regionalization = this.regionalization || [];
    }
  
    if (this.createEditFormGroup.value?.valorOperacaoStrCtrl) {
      instituicaoFinanceira.valorOperacaoStr = UtilHandler.getFormattedPrice(instituicaoFinanceira.valorOperacao);
    }
    if (instituicaoFinanceira.capitalMinimo) {
      instituicaoFinanceira.capitalMinimoStr = UtilHandler.getFormattedPrice(instituicaoFinanceira.capitalMinimo);
    }
    if (instituicaoFinanceira.capitalMaximo) {
      instituicaoFinanceira.capitalMaximoStr = UtilHandler.getFormattedPrice(instituicaoFinanceira.capitalMaximo);
    }
  
    if (this.createEditFormGroup.value?.idCtrl === '') {
      this._data.addInstituicaoFinanceira(instituicaoFinanceira);
    } else {
      instituicaoFinanceira.id = this.createEditFormGroup.value?.idCtrl;
      this._data.updateInstituicaoFinanceira(instituicaoFinanceira);
    }
    this.tituloFormInstituicao = '';
    this.isCreateEdit = false;
  }
  
  

  toggleAutomaticApi(): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '800px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja realmente ${ !this.integrate.isActive ? 'desativar' : 'ativar' } a API automática?`
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (!result) {
          this.integrate.isActive = !this.integrate.isActive;
        } else {
          this.integrateService.setIntegrate(this.createEditFormGroup.controls.nomeNoSistemaCtrl.value, { isActive: this.integrate.isActive });
        }
      });
  }

  onToggleApi(): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '800px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja realmente alterar as regras de funcionamento da API?`
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.integrateService.setIntegrate(this.createEditFormGroup.controls.nomeNoSistemaCtrl.value, this.integrate);

        }
      });
  }

  selectedGuaranteesChange(event): void {
    const garantee = this.garantias.find((garantia) => garantia.nome === 'Recebiveis');
    this.isTypeOfAnticipationEnabled = !!event.value.find((selected) => selected === garantee.id);
  }

  selectedGuaranteesAnticipationChange(event): void {
    this.isTypeOfAnticipationEnabled = !!event.value.find((selected) => selected.nome === 'Recebiveis');
    if (!this.isTypeOfAnticipationEnabled) {
      this.istypeOfAnticipationCard = this.isTypeOfAnticipationEnabled;

      this.createEditFormGroup.get('creditCardCtrl').clearValidators();
      this.createEditFormGroup.get('typeOfAnticipationCtrl').clearValidators();

      this.createEditFormGroup.get('creditCardCtrl').setValue(null);
      this.createEditFormGroup.get('typeOfAnticipationCtrl').setValue(null);
    }
  }

  selecteChangeAntecipation(event): void {
    this.istypeOfAnticipationCard = !!event.value.find((selected) => selected.id === 'cartao');
    if (!this.istypeOfAnticipationCard) {
      this.createEditFormGroup.get('creditCardCtrl').clearValidators();

      this.createEditFormGroup.get('creditCardCtrl').setValue(null);
    }
  }

  compareTaxRegimes(regimes1: BasicStructure, regimes2: BasicStructure): boolean {
    if (regimes1?.id === regimes2?.id) {
      return true;
    } else if (['simples', 'simples_nacional'].includes(regimes1.id) && ['simples', 'simples_nacional'].includes(regimes2.id)) {
      return true;
    } else if (['lucro-presumido', 'lucro_presumido'].includes(regimes1.id) && ['simples', 'simples_nacional'].includes(regimes2.id)) {
      return true;
    } else if (['lucro-real', 'lucro_real'].includes(regimes1.id) && ['simples', 'simples_nacional'].includes(regimes2.id)) {
      return true;
    }
    return false;
  }


  compareObjects(o1: any, o2: any): boolean {
    return o1?.name === o2?.name && o1?.id === o2?.id;
  }

  compareObjectsMn(o1: any, o2: any): boolean {
    return o1.mnemonico === o2.mnemonico;
  }

  compareStages(o1: string | number, o2: string | number): boolean {
    return Number(o1) === Number(o2);
  }
  

  getFormattedPrice(price: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
  }

  toggleDocumentAutomaticProp(item: Document): void {
    const documentsParam = {
      documents: this.FiDocuments.documentsData.map((doc) => ({
        id: doc.id,
        automatic: doc.automatic ?? true,
      })),
    };

    this._data.updateFinancialInstitutionDocuments(item.instituicaoFinanceiraId, documentsParam);
  }

  onAddNewDoc() {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Adicionar Documentos',
          alertDescription: `Deseja realmente associar esse(s) documento(s) à instituição financeira <strong>${ this.createEditFormGroup.get('nomeCtrl').value
            }</strong>?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          let instFinancId: string;
          let maxOrder: number = Math.max(
            ...this.FiDocuments.documentsData.map((o) => {
              return o.ordenacao;
            })
          );

          maxOrder = maxOrder + 1;
          this.documentosAtivosSelecionados.selectedOptions.selected.forEach((item) => {
            const tipoDocumento: Document = item.value;
            tipoDocumento.automatic = true;
            instFinancId = tipoDocumento.instituicaoFinanceiraId;
            this.FiDocuments.documentsData.push(tipoDocumento);
            maxOrder = maxOrder + 1;
          });

          const documentsParam = {
            documents: this.FiDocuments.documentsData.map((doc) => ({
              id: doc.id,
              automatic: doc.automatic ?? true,
            })),
          };

          this._data.updateFinancialInstitutionDocuments(instFinancId, documentsParam);
        }
      });
  }

  changeCnae(event): void {
    this.instituicaoFinanceira.cnaeMatchOption = event.value;
    this._data.updateInstituicaoFinanceira(this.instituicaoFinanceira);
  }

  openPopup() {
    this.showPopup = true;
  }

  closePopup() {
    this.showPopup = false;
  }


  onRemoverDocumento(instFinancId, index): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Remover tipo documento',
          alertDescription:
            `Deseja realmente remover esse tipo de documento da lista da instituição financeira ` +
            this.createEditFormGroup.get('nomeCtrl').value +
            `?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // remove o documento selecionado do array.
          this.FiDocuments.documentsData.splice(index, 1);

          const documentsParam = {
            documents: this.FiDocuments.documentsData.map((doc) => ({
              id: doc.id,
              automatic: doc.automatic ?? true,
            })),
          };

          this._data.updateFinancialInstitutionDocuments(instFinancId, documentsParam);
        }
      });
  }

  slugifName(name) {
    if (!this.isEdit) {
      const slugify = require('@sindresorhus/slugify');
      const result = slugify(name, { separator: '_' });

      this.createEditFormGroup.get('nomeNoSistemaCtrl').setValue(result);
    }
  }

  loadFileUpload() {
    this.fileInfoFormGroup = new FormGroup({});
  }

  onSalvarArquivo() {
    const imgUrl = this.fileUpload.getImageUrl();
    if (!imgUrl) {
      this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Atenção',
            alertDescription: 'Carregue uma imagem.',
            isOnlyConfirm: true,
          },
        })
        .afterClosed()
        .subscribe();
    } else {
      this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '600px',
          data: { alertTitle: 'Confimação', alertDescription: 'Deseja realmente salvar essa imagem?' },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            const arquivoInfo: ArquivoInfo = {
              path: imgUrl,
              usuarioUploader: this.loggedUser.email,
              dataUpload: firebase.firestore.Timestamp.now(),
            };

            this._data.updateImagemBanco(arquivoInfo, this.createEditFormGroup.value?.idCtrl);
          }
        });
    }
  }

  formatCnae(value: BasicCnae | string): string[] {
    if (value === null) {
      return null;
    }
    if (typeof value === 'string') {
      return [value?.replace(/([^\d])+/gim, '')];
    }
  }

  onChangeCnaeValue(type: BasicTypeCnae, value?: BasicCnae | string): void {
    if (type !== null) {
      this.CNAEFormGroup.get('cnaeCtrl').enable();
      if (!value) {
        value = '0';
      } else {
        if (typeof value === 'object') {
          this.CNAEFormGroup.get('cnaeCtrl')?.setValue(value.id);
          this.CNAEFormGroup.get('typeCtrl')?.setValue(value.type);
          this.CNAEFormGroup.get('descriptionCtrl')?.setValue(value.description);
        }
        if (typeof value === 'string') {
          this.CNAEFormGroup.get('descriptionCtrl')?.setValue(null);
          this.getCnaes(type, value);
        }
      }
    } else {
      this.CNAEFormGroup.get('cnaeCtrl').disable();
    }
  }

  getCnaes(type: BasicTypeCnae, value?: string): void {
    this.optionsCnae = [];
    this._data.getCnaes(type, value).subscribe((result) => {
      const cnaes = result.map((cnae) => {
        return {
          id: cnae.id,
          description: cnae.descricao,
          type: type,
        };
      }) as BasicCnae[];
      if (value === ' ' || value === null) {
        this.optionsCnae = [];
      } else {
        this.optionsCnae = _.filter(_.uniqBy(cnaes, 'id'), (cnae) => {
          const cnaeID = cnae.id?.replace(/\D/g, '').slice(0, value.length);
          if (cnaeID === value) {
            return true;
          }
          return false;
        });
      }
    });
  }

  isCnaeInvalid(cnaeForm: FormGroup): boolean {
    if (cnaeForm.get('cnaeCtrl').value === null || cnaeForm.get('cnaeCtrl').value === '') {
      return true;
    }
    if (cnaeForm.get('descriptionCtrl').value === null || cnaeForm.get('descriptionCtrl').value === '') {
      return true;
    }
    if (cnaeForm.get('typeCtrl').value === null) {
      return true;
    }
    return false;
  }

  addNewCnae(): void {
    if (this.dataCnaeTable && this.dataCnaeTable.length > 0) {
      this.dataCnaeTable.push({
        id: this.CNAEFormGroup.get('cnaeCtrl')?.value,
        type: this.CNAEFormGroup.get('typeCtrl')?.value,
        description: this.CNAEFormGroup.get('descriptionCtrl')?.value,
      });
    } else {
      this.dataCnaeTable = [
        {
          id: this.CNAEFormGroup.get('cnaeCtrl')?.value,
          type: this.CNAEFormGroup.get('typeCtrl')?.value,
          description: this.CNAEFormGroup.get('descriptionCtrl')?.value,
        },
      ];
    }

    this.dataSourceCnae = new MatTableDataSource(this.dataCnaeTable);
    this.dataSourceCnae.sort = this.matSortCnae;
    this.dataSourceCnae.paginator = this.paginatorCnae;
    // clear fields
    this.CNAEFormGroup.get('cnaeCtrl')?.reset();
    this.CNAEFormGroup.get('cnaeCtrl')?.setErrors(null);
    this.CNAEFormGroup.get('cnaeCtrl')?.markAsUntouched();
    this.CNAEFormGroup.get('typeCtrl')?.reset();
    this.CNAEFormGroup.get('typeCtrl')?.setErrors(null);
    this.CNAEFormGroup.get('typeCtrl')?.markAsUntouched();
    this.CNAEFormGroup.get('descriptionCtrl')?.reset();
    this.CNAEFormGroup.get('descriptionCtrl')?.setErrors(null);
    this.CNAEFormGroup.get('descriptionCtrl')?.markAsUntouched();
  }

  addCNAEsInLote(): void {
    const cnaesStr = this.cnaeLote.split('\n');
    cnaesStr.map((c) => {
      const id = c.split('\t')[0].replace('-', '');
      const description = c.split('\t')[1];

      if (id && description) {
        if (this.dataCnaeTable && this.dataCnaeTable.length > 0) {
          this.dataCnaeTable.push({
            id,
            type: null,
            description,
          });
        } else {
          this.dataCnaeTable = [
            {
              id,
              type: null,
              description,
            },
          ];
        }
      }

      this.dataSourceCnae = new MatTableDataSource(this.dataCnaeTable);
      this.dataSourceCnae.sort = this.matSortCnae;
      this.dataSourceCnae.paginator = this.paginatorCnae;

      this.cnaeLote = null;
    });
  }

  removeCnae(c: BasicCnae): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Remover CNAE',
          alertDescription: `Deseja realmente remover essa CNAE?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.dataCnaeTable.splice(this.dataCnaeTable.indexOf(c), 1);
          this.dataSourceCnae = new MatTableDataSource(this.dataCnaeTable);
          this.dataSourceCnae.sort = this.matSortCnae;
          this.dataSourceCnae.paginator = this.paginatorCnae;
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'CNAE Removido',
              alertDescription: `O CNAE foi removido da lista, para confirmar a remoção clique em salvar as alterações.`,
              isOnlyConfirm: true,
            },
          });
        }
      });
  }

  saveCnae(): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Salvar as Alterações',
          alertDescription: `Deseja realmente salvar as alterações de CNAEs nessa Instituição? `,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.instituicaoFinanceira.cnae = this.dataCnaeTable;
          this._data.updateInstituicaoFinanceira(this.instituicaoFinanceira).then(() => {
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Sucesso ao Salvar',
                alertDescription: `As Alterações de CNAEs foram salvas com sucesso`,
                isOnlyConfirm: true,
              },
            });
          });
        }
      });
  }

  clearFields(): void {
    this.ifsSelectedGuarantees.setValue(null);
    this.ifsGuaranteesOperator.setValue(null);
    this.ifsSelectedModality.setValue(null);
    this.ifsModalityOperator.setValue(null);
    this.ifsSelectedTaxRegime.setValue(null);
    this.ifsTaxRegimeOperator.setValue(null);
    this.ifsSelectedAnticipations.setValue(null);
    this.ifsName.setValue(null);
    this.ifsAtivo.setValue(null);
    this.ifsNoTouch.setValue(null);
    this.ifsMin.setValue(null);
    this.ifsMax.setValue(null);

    this.getData();
  }

  openOppStreamDialog(): Promise<any> {
    // Limpa o valor armazenado em `oppStreamDialogData` antes de abrir o modal
    this.oppStreamDialogData = null;
  
    const selectedValue = this.createEditFormGroup.get('opportunityStreamCtrl')?.value;
    const dialogRef = this.dialog.open(OpportunityStreamDialogComponent, {
      data: { 
        value: selectedValue,
        institution: this.instituicaoFinanceira
      }
    });
  
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.oppStreamDialogData = result;
        if (this.createEditFormGroup.valid) {
          this.onSaveForm();
        }
        
        return result;
      }
      return null; // ou qualquer valor padrão que você queira retornar quando não houver resultado
    });
  }
  
  

}
