import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from '../../../../../functions/src/models/User';
import { BasicStructure } from '../../../../../functions/src/models/common/BasicStructure';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { AuthService } from '../../../core/auth/auth.service';
import { ActionInLoteParams } from '../../../core/models/ActionInLoteParams';
import { InstituicaoFinanceira } from '../../instituicao-financeira/instituicao-financeira';
import { InstituicaoFinanceiraService } from '../../instituicao-financeira/instituicao-financeira.service';
import { OpportunityManagementService } from '../../services/opportunity-management.service';

@Component({
  selector: 'app-action-in-lote',
  templateUrl: './action-in-lote.component.html',
  styleUrls: ['./action-in-lote.component.css']
})
export class ActionInLoteComponent implements OnInit, OnDestroy {
  @Input() institution: InstituicaoFinanceira;
  @Input() hasPermission: boolean;

  batchActionsFormGroup: FormGroup;

  readonly actions: BasicStructure[] = [
    { id: 'open', name: 'Abrir' },
    { id: 'reopen', name: 'Reabrir' },
    { id: 'close', name: 'Fechar' },
    { id: 'list-cnpj', name: 'Lista de Cnpj' },
  ];
  actionSelected?: BasicStructure;

  isLoadingAction = false;
  loggedUserSubscription: Subscription;
  loggedUser: User;
  constructor(private opsService: OpportunityManagementService, private authService: AuthService, private formBuilder: FormBuilder, private dialog: MatDialog, public instService: InstituicaoFinanceiraService) {

  }
  ngOnInit(): void {
    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser: User) => {
      this.loggedUser = loggedUser;

    });

    this.batchActionsFormGroup = this.formBuilder.group({
      actionCtrl: new FormControl('', Validators.required),
      dateInitCtrl: new FormControl(''),
      dateFinishCtrl: new FormControl(''),
      approvedByCommitteeCtrl: new FormControl({ value: '', disabled: false }),
      listCnpjCtrl: new FormControl(''),
      stageChangeCtrl: new FormControl(null),
    });

    this.validatePermission();
  }

  ngOnDestroy(): void {
    if (this.loggedUserSubscription) {
      this.loggedUserSubscription.unsubscribe();
    }
  }

  validatePermission(): void {
    if (this.hasPermission) {
      this.batchActionsFormGroup.enable();
    } else {
      this.batchActionsFormGroup.disable();
    }
  }

  optionsBatchActions(): void {
    this.actionSelected = this.batchActionsFormGroup.controls['actionCtrl'].value;
  }

  onIdentifyOppsForIfs(): void {
    const { actionCtrl: action, dateInitCtrl, dateFinishCtrl, listCnpjCtrl, approvedByCommitteeCtrl: approvedByCommittee, stageChangeCtrl: stageChange } = this.batchActionsFormGroup.getRawValue();
    const stage = this.opsService.getOppStageFromNumber(stageChange || 0);
    const startDate = dateInitCtrl ? new Date(dateInitCtrl).toISOString() : null;
    const endDate = dateFinishCtrl
      ? new Date(new Date(new Date(dateFinishCtrl).setHours(24)).setMinutes(-1)).toISOString()
      : null;
    const listCnpj = listCnpjCtrl?.split('\n') as string[] ?? null;

    const params = {
      action, startDate, endDate, listCnpj, approvedByCommittee, stage
    };


    if (!!action) {
      const alertTitle = this.getAlertTitle(action, this.institution.nome);
      const alertDescription = this.getAlertDescription({ action, startDate, endDate });
      this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle,
            alertDescription: 'Deseja realmente ' + alertDescription + '?',
          },
        })
        .afterClosed()
        .subscribe(async (result) => {
          try {
            await this.actionInLote(result, params);
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Sucesso ao ' + alertTitle,
                alertDescription: alertDescription + ' foi efetuado com sucesso.',
                isOnlyConfirm: true,
              },
            });
          } catch (error) {
            console.error('Erro ao ', alertTitle, ':', error);
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao ' + alertTitle,
                alertDescription: 'Ocorreu um erro ao ' + alertDescription + '.',
                isOnlyConfirm: true,
              },
            });
          }

        });
    }
  }

  async actionInLote(result: boolean, { action,
    startDate,
    endDate,
    listCnpj,
    approvedByCommittee,
    stage }: ActionInLoteParams): Promise<void> {
    {
      this.isLoadingAction = true;
      try {
        const createdBy = {
          uid: this.loggedUser.uid,
          name: this.loggedUser.displayName || this.loggedUser.name,
        };
        if (!result) {
          this.isLoadingAction = false;
          console.log('Admin opitou por não continuar');
          return;
        } else if (action.id === 'list-cnpj') {
          await this.opsService
            .updateOpportunitiesByIF(this.institution.nomeNoSistema, { listCnpj, stage, createdBy });
        } else {
          await this.instService
            .identifyOppsForIfs({
              institution:
                this.institution.id,
              action: action.id, startDate, endDate, approvedByCommittee, createdBy
            });
        }

      } catch (error) {
      }
      this.isLoadingAction = false;
    }
  }

  getAlertDescription({ action, startDate, endDate }: { action: BasicStructure; startDate?: string; endDate?: string }): string {
    const haveDate = startDate || endDate ? true : false;
    let alertDescription = '';
    alertDescription = `${ action.name } as oportunidades da instituição ${ this.institution.nome }${ haveDate ? ',' : ''
      }`;
    if (action.id === 'list-cnpj') {
      alertDescription = `Atualizar as oportunidades da instituição ${ this.institution.nome }, dos clientes na lista de CNPJ`;
    } else {
      alertDescription = `${ action.name } as oportunidades da instituição ${ this.institution.nome }`;
    }

    if (startDate) {
      alertDescription += `, ${ startDate ? ` começando da data ${ new Date(startDate).toLocaleDateString() }` : '' }`;
    } else if (endDate) {
      alertDescription += `${ endDate ? ` até a data ${ new Date(endDate).toLocaleDateString() }` : '' }`;
    }

    return alertDescription;

  }

  getAlertTitle(action: BasicStructure, instName: string): string {
    let alertTitle = `${ action.name } as Oportunidades da Instituição ${ instName }`;

    if (action.id === 'cnpj') {
      alertTitle = `Lista de CNPJ das Oportunidades da Instituição ${ instName }`;
    }

    return alertTitle;
  }


}
