export enum BCrediRealtyTypeOptions {
  'REGISTERED_RESIDENTIAL_PROPERTY' = 'registred_residential_property', // Imóvel residencial com escritura
  'REGISTERED_COMMERCIAL_PROPERTY' = 'registred_commercial_property', // Imóvel comercial com escritura
  'REGISTERED_APARTMENT' = 'registred_apartment', // Apartamento com escritura
  'NOT_REGISTERED_OR_LAND' = 'not_registred_or_land', // Imóvel sem registro ou terra
}

export enum BCrediFinancingTypeOptions { // colocar como fixo no handler (property-secured...)
  'PROPERTY_SECURED_CREDIT' = 'Crédito com garantia de imóvel',
  'REAL_ESTATE_FINANCING' = 'Financiamento imobiliário',
  'ANTICIPATED_INCOME' = 'Antecipação de renda',
}

export enum BCrediProposalStatus {
  'OPEN' = 'Em aberto', // ou colocar "open" no lugar de "Em aberto"?
  'AWAITING_AUTHORIZATION' = 'Aguardando autorização',
  'LEAD_CONTACT_ATTEMPT' = 'Tentativa de contato (SDR/pré-venda)',
  'LEAD_CONTACT_MADE' = 'Contato realizado',
  'LEAD_QUALIFIED' = 'Qualificado',
  'LEAD_LOST' = 'Perdido (ver campo lost_reason e lost_sub_reason)',
  // "LEAD_QUALIFIED" = "", TEM 2 CAMPOS COM ESSE NOME DA DOC DA BCREDI
  'OPPORTUNITY_CONTACT_ATTEMPT' = 'Tentativa de contato (consultor/negociação)',
  'UNDER_NEGOTIATION' = 'Em negociação',
  'AWAITING_DOCUMENTATION' = 'Aguardando documentação da análise de crédito',
  'CREDIT_ANALYSIS' = 'Em análise de crédito',
  'CREDIT_APPROVED' = 'Análise de crédito aprovada',
  'BANK_SLIP_SENT' = 'Boleto da avaliação do imóvel enviado',
  'REALTY_VALUATION' = 'Avaliação do imóvel',
  'LEGAL_ANALYSIS' = 'Análise jurídica',
  'CONTRACT_ISSUANCE' = 'Emissão do contrato',
  'CONTRACT_SIGNATURE' = 'Assinatura do contrato',
  'REGISTRY' = 'Contrato está para registro da alienação em cartório',
  'PAID_WITH_REGISTRY_PROTOCOL' = 'Pago em protocolo',
  'PAID' = 'Pago',
  'OPPORTUNITY_LOST' = 'Perdido',
  'MANUAL_HANDLING' = 'MANUAL_HANDLING', // Tratamento Manual. Mantém? estava na Creditas.ts, mas não na doc deles
}

export enum BcrediLostReason {
  'COMPETITION' = 'Conseguiu crédito de outra forma',
  'INVALID_CONTACT' = 'Contato inválido',
  'CONTACT_DOESNT_BELONG_TO_LEAD' = 'Contato não pertence à indicação',
  'LOAN_VALUE_BELOW_MINIMUM' = 'Outros',
  'PERSON_WITHOUT_WARRANTY' = 'Sem garantia',
  'COMPANY_WITHOUT_WARRANTY' = 'Sem garantia',
  'DUPLICITY' = 'Indicação já existe em nosso sistema',
  'TOO_MANY_CALLS' = 'Outros',
  'TIMEOUT' = 'Outros',
  'WARRANTY_NOT_ACCEPTED' = 'Imóvel não aceito',
  'DOESNT_PROVE_INCOME' = 'Renda incompatível',
  'NOT_INFORMED' = 'Indicação não informou motivo de desistência',
  'ANOTHER_PRODUCT' = 'Outros',
  'SEARCH' = 'Outros',
  'VEHICLE_REFINANCING' = 'Sem garantia',
  'UNMET_REGION' = 'Região não atendida',
  'BACEN_LOW_SCORE_BCREDI' = 'Crédito não aprovado',
  'PROTESTED_CLIENT' = 'Possui apontamentos ou protestos no Serasa/CCF',
  'PAST_DUE_OR_LOSS_HISTORY' = 'Histórico de dívidas',
  'CREDIT_POLICY' = 'Indeferido devido a Política de Crédito',
  'DOESNT_ANSWER_SALESPERSON' = 'Indicação não atende nossos contatos',
  'NO_LONGER_NEED_CREDIT' = 'Indicação não precisa mais de crédito',
  'LEGAL_PENDING' = 'Possui pendências jurídicas',
  'PROPERTY_SALE' = 'Imóvel está à venda',
  'OTHER_REASONS' = 'Outros',
}

export enum BcrediLostSubReason {
  'DOESNT_PROVE_INCOME' = 'Indicação não comprovou renda',
  'VEHICLE_MONETIZATION' = 'Monetização de automóvel em andamento',
  'INSUFFICIENT_INCOME' = 'Indicação não possui renda suficiente',
  'NOT_PROVEN_INCOME_MOVEMENT' = 'Indicação não comprovou movimentação de renda',
  'PROPERTY_TYPE' = 'Tipo de imóvel não aceito',
  'DOCUMENTATION' = 'Problemas com documentação',
  'PROPERTY_VALUE_OUT_OF_POLICY' = 'Valor do imóvel insuficiente para o empréstimo',
  'POLICY' = 'Valor do imóvel fora da política',
  'INHABITANTS_QUANTITY' = 'Cidade com número de habitantes abaixo do mínimo permitido',
  'HOME_EQUITY_LOAN' = 'Indicação fora do perfil CGI (não possui imóvel)',
  'PAYROLL_LOAN' = 'Indicação optou por crédito consignado',
  'GOT_WITH_RELATIVE_OR_FRIEND' = 'Indicação conseguiu empréstimo com parente ou amigo',
  'DUPLICITY' = 'Duplicata',
  'VEHICLE_LEASING' = 'Indicação procura crédito com garantia de veículos',
  'FINANCING_PRODUCT' = 'Imóvel esta em financiamento com saldo devedor acima do mínimo do que a gente pode disponibilizar',
  'COMPANY' = 'Imóvel é uma empresa',
  'COMPARISON' = 'Indicação negociando com outra empresa',
}

export interface BCrediConfiguration {
  accessToken?: string;
  url?: string;
}

export const BCrediHeaders = {
  'Content-Type': 'application/json',
};
