<mat-dialog-content>
  <mat-card>
    <mat-card-title> Encaminhar Mensagem </mat-card-title>

    <mat-card-content>
      <mat-form-field appearance="fill">
        <mat-label>Destinatario</mat-label>
        <mat-select [(value)]="forwardTo">
          <mat-option *ngIf="noteFrom !== 'BANKER'" value="BANKER">Operador de Crédito</mat-option>
          <mat-option *ngIf="noteFrom !== 'AGENT' && !!customerData?.agent" value="AGENT">Assessor</mat-option>
          <mat-option *ngIf="noteFrom !== 'USER'" value="USER">Usuário e Assessor</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="forwardTo === 'BANKER'">
        <mat-label>Instituição Destinatária</mat-label>
        <mat-select [(value)]="forwardToBankInstitution">
          <mat-option *ngFor="let inst of bankersInstitutions" [value]="inst">{{ inst.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="warning mt-3 mb-4" *ngIf="forwardTo === 'USER'">
        <h5 class="d-flex align-items-center"><mat-icon class="mr-2">warning</mat-icon> Atenção!</h5>
        <span class="warning-text">
          <p>
            As mensagens adicionadas abaixo ficarão disponíveis para o cliente, que será notificado sobre cada nova
            mensagem.
          </p>
        </span>
      </div>

      <mat-form-field class="w-100" color="accent" style="width: 100%">
        <textarea
          matInput
          placeholder="Conteúdo"
          mat-autosize
          matAutosizeMinRows="5"
          [(ngModel)]="noteText"
          required
        ></textarea>
      </mat-form-field>

      <div class="text-right">
        <button mat-raised-button type="button" class="mx-2" mat-dialog-close>
          <mat-icon>clear</mat-icon> Cancelar
        </button>
        <button
          mat-raised-button
          color="accent"
          type="button"
          [disabled]="
            noteText === '' ||
            forwardTo === undefined ||
            (forwardTo === 'BANKER' ? forwardToBankInstitution === undefined : false)
          "
          (click)="createNote()"
          class="mx-2"
        >
          <mat-icon>save</mat-icon> Salvar
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
