
import { animate, state, style, transition, trigger } from '@angular/animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import firebase, { firestore } from 'firebase';
import { Agent } from 'functions/src/models/Agent';
import { Contract } from 'functions/src/models/Contract';
import { Customer, CustomerPartner, NewOtherInstitution } from 'functions/src/models/Customer';
import { AgentNote } from 'functions/src/models/Notes';
import { User, UserRole } from 'functions/src/models/User';
import 'jspdf-autotable';
import JSZip from 'jszip';
import _, { isDate } from 'lodash';
import _moment from 'moment';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { ChatMenuItem } from 'src/app/chat/models/chat-menuItem';
import { ContactsCustomer } from 'src/app/chat/models/contact';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { LogService } from 'src/app/components/logger/log.service';
import { UploadDialogComponent } from 'src/app/components/upload-dialog/upload-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { CepError, Endereco } from 'src/app/core/models/model-interfaces';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { UserDocumentService } from 'src/app/customer/services/user-document.service';
import { environment } from 'src/environments/environment';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { DocControleVencimento, Document, DocumentAction } from '../../../../functions/src/models/UserDocument';
import { BasicStructure } from '../../../../functions/src/models/common/BasicStructure';
import {
  ArquivoInfo as ArquivoInfoMongoDB,
  Document as DocumentMongoDB,
  UserDocuments
} from '../../../../functions/src/models/documents/UserDocument';
import { MoneyplusEntity } from '../../../../functions/src/models/moneyplus/Moneyplus';
import { ChatService } from '../../chat/services/chat.service';
import { HubspotService } from '../../core/services/hubspot.service';
import { ScrBmpService } from '../../core/services/scr-bmp.service';
import { ViacepService } from '../../core/services/viacep.service';
import { CustomerService } from '../../customer/services/customer.service';
import { FinancialInstitutionsService } from '../../customer/services/financial-institutions.service';
import { CadastrosGeraisService } from '../cadastros-gerais/cadastros-gerais.service';
import { AgentNotesComponent } from '../components/agent-notes/agent-notes.component';
import { CustomerOpportunityComponent } from '../containers/customer-opportunity/customer-opportunity.component';
import { InstituicaoFinanceiraService } from '../instituicao-financeira/instituicao-financeira.service';
import { AgentsAdminService } from '../services/agents-admin.service';
import { BigDataCorpService } from '../services/bigdatacorp.service';
import { CustomersAdminService } from '../services/customers-admin.service';
import { NotesService } from '../services/notes.service';
import { UserManagementService } from '../services/user-management.service';
import { FaseOportunidade } from '../user-management/user-management';
import { TipoDocumento } from './../../../../functions/src/models/model-interface';
import { timestampForDate } from './../../../../functions/src/utils/dates';


interface Recipient {
  email: string;
  valid: boolean;
  color: string;
}
_moment.locale('pt-BR');
export const MY_FORMATS = {
  parse: {
    dateInput: 'LLL',
  },
  display: {
    dateInput: 'LLL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LLL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserDetailComponent implements OnInit, OnDestroy {

  @Input() customers: any[] = [];
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  serasaData: any;
  selectedMenuitem: ChatMenuItem | undefined;
  menuContacts: ChatMenuItem[];
  isLoadingDoc = false;
  startCommittee = false;

  constructor(
    private ums: UserManagementService,
    private userDocumentService: UserDocumentService,
    private authService: AuthService,
    private dialog: MatDialog,
    private agentsAdminService: AgentsAdminService,
    private formBuilder: FormBuilder,
    private viacep: ViacepService,
    private logger: LogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private notesService: NotesService,
    private configService: ConfigurationService,
    private cadastrosGeraisService: CadastrosGeraisService,
    private customerService: CustomerService,
    private bigdatacorpService: BigDataCorpService,
    private customersAdminService: CustomersAdminService,
    private financialInstitutionsService: FinancialInstitutionsService,
    private instFinanceirasAutocomplete: InstituicaoFinanceiraService,
    private chatService: ChatService,
    private hubspotService: HubspotService,
    private scrBmpService: ScrBmpService,
  ) {
    this.datePipe = new DatePipe('pt-BR');
    this.documents = [];
    this.tituloDetalheUsuario = '-';
    this.customerUID = this.activatedRoute.snapshot.params.uid;

    // Populate MENUCONTACTS with data from userList
    this.menuContacts = [
      {
        state: 'contatos',
        name: 'Contatos',
        type: 'link',
        selected: true
      },
    ];

    // faz a captura dos dados do service no inicio Auto-Complete
    this.instFinanceirasAutocomplete.getFinancialInstitutionAutocomplete().subscribe((data) => {
      this.allNewOtherInstitutions = data;
      this.filteredNewOtherInstitutions = this.editFormGroup.get('outrosBancosCtrl').valueChanges.pipe(
        startWith(''),
        debounceTime(200),
        map((value: string) => {
          if (value.length >= 1) {
            return this._filter(value);
          } else {
            return [];
          }
        })
      );
    });

    if (!this.customerUID) {
      this.router.navigate(['admin/clientes/buscar']);
      console.error('Customer without uid');
    }

    if (this.activatedRoute.snapshot.params.tab) {
      this.tab.selectedIndex = this.tabOptions.indexOf(this.activatedRoute.snapshot.params.tab.selectedIndex) || 0;
    }
  }
  readonly MASKS = MASKS;
  formatPhone = UtilHandler.formatPhone;
  isEdit = false;
  isProcessingExclused = false;
  isProcessing = false;
  isSendingEmailVerification = false;
  isAddingCreditLine = false;
  defaultCESource = 'organico';

  @ViewChild(MatTabGroup) tab: MatTabGroup;
  readonly tabOptions = ['dados', 'credito', 'documentacao', 'mensagens', 'big-data', 'assessor', 'termo'];

  // Type Guarantee of Antecipation
  readonly selectTypeOfAnticipationOperator = [
    { id: 'cartao', value: 'Cartão' },
    { id: 'boleto', value: 'Boleto' },
    { id: 'nota_fiscal', value: 'Notas Fiscais' },
  ];
  hasOnlyAvalGuarantee = false;
  hasTypeOfAnticipation = false;

  readonly selectTypeMaritalStatus = [
    { id: 'solteiro', name: 'Solteiro (a)' },
    { id: 'casado', name: 'Casado (a)' },
    { id: 'separado', name: 'Separado (a)' },
    { id: 'divorciado', name: 'Divorciado (a)' },
    { id: 'viuvo', name: 'Viúvo (a)' },
  ];

  readonly optionsBanco: BasicStructure[] = [
    { id: 'banco_do_brasil', name: 'Banco do Brasil' },
    { id: 'bradesco', name: 'Bradesco' },
    { id: 'caixa_economica_federal', name: 'Caixa' },
    { id: 'itau', name: 'Itaú' },
    { id: 'santander', name: 'Santander' },
    { id: 'outro', name: 'Outro' },
  ];

  readonly optionsGarantias: BasicStructure[] = [
    { id: 'imovel-nao-operacional', name: 'Imóvel Não-Operacional' },
    { id: 'imovel-operacional', name: 'Imóvel Operacional' },
    { id: 'boleto', name: 'Antecipação (Boleto, Cartão ou Notas Fiscais)' },
    { id: 'veiculos', name: 'Veículos' },
    { id: 'avalista', name: 'Aval' },
  ];

  readonly optionsTempoAtividade: BasicStructure[] = [
    { id: 'menos1ano', name: '-1 ano' },
    { id: 'mais1ano', name: ' +1 ano' },
    { id: 'mais2anos', name: '+2 anos' },
    { id: 'mais5anos', name: '+5 anos' },
    { id: 'mais10anos', name: '+10 anos' },
  ];

  readonly opcaoSimNao: BasicStructure[] = [
    { id: 'sim', name: 'Sim' },
    { id: 'nao', name: 'Não' },
  ];

  readonly opcaoSimNaoTermo: BasicStructure[] = [
    { id: 'nao', name: 'Não' },
    { id: 'sim', name: 'Sim (Pelo Sistema)' },
    // { id: 'sim-fora-sistema', name: 'Sim (Fora do Sistema)' }
  ];

  readonly selectTypeOfInvoices = [
    { id: 'nota_servico', value: 'Notas de Serviço' },
    { id: 'nota_produto', value: 'Notas de Produto' },

  ];

  readonly selectPersonType = [
    { id: 'fisica', value: 'Física' },
    { id: 'juridica', value: 'Jurídica' },
  ];

  readonly faseOportunidadeInicial: FaseOportunidade;

  config: any;
  configSubscription: Subscription;

  loggedUser: User;
  tipeDocuments: TipoDocumento[];
  documents: DocumentMongoDB[];
  customer: Customer;
  contract: Contract;
  ip?: string;

  userDataSubscription: Subscription;
  userContractSubscription: Subscription;
  userDocumentsSubscription: Subscription;
  documentosAtivosSubscription: Subscription;
  linhasCreditoAtivasSubscription: Subscription;
  yearDocuments: any;
  selectedDocument: any;
  dataUserDocuments: UserDocuments;
  uploadYearDocument: boolean;
  canUpload: boolean;

  tituloDetalheUsuario: string;
  tituloDocumento: string;
  limiteCreditoStr = 'R$ 0,00';
  limiteCredito = 0;


  showPopup = false;


  editFormGroup: FormGroup;
  hasOther = false;
  readonly numberMask = createNumberMask({
    decimalLimit: 2,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: false,
    integerLimit: 15,
    prefix: 'R$ ',
    suffix: '',
  });
  @ViewChild(CustomerOpportunityComponent, { static: false }) customerOpportunityC: CustomerOpportunityComponent;
  @ViewChild('documentosAtivosSelecionados', { static: false }) documentosAtivosSelecionados;
  @ViewChild('cep', { static: false }) cep;
  public ultimoCepBuscado = '';
  placeholderLogr: string;
  placeholderBair: string;

  @ViewChild('templateContrato', { static: false }) templateContrato: ElementRef;

  datePipe: DatePipe;
  agreedTerms = false;
  noTermsCustomer = false;

  loggedUserSubscription: Subscription;
  getUsersSubscription: Subscription;
  getUserSubscription: Subscription;
  getDocumentosIFSubscription: Subscription;
  getTiposDocumentosSubscription: Subscription;
  userCnpjValidationSubscription: Subscription;
  getDetalhesBancoSubscription: Subscription;
  getTaxRegimesSubscription: Subscription;
  getCardMachinesSubscription: Subscription;

  getUserDocPendencySubscription: Subscription;
  allUsersDocuments: UserDocuments;

  bigdatacorpSubscription: Subscription;
  bigdatacorpData: any;
  isProcessingBDC = false;
  isProcessingSerasa = false;
  runDocuments = false;
  isProcessingScrBmp = false;
  processingDocuments = false;
  bmpMoneyPlusData: MoneyplusEntity;

  isUpload: boolean;
  documentSelected: DocumentMongoDB;
  fileInfoFormGroup: FormGroup;
  @ViewChild(FileUploadComponent, { static: false }) fileUpload: FileUploadComponent;
  selectedValueStage: any;
  isSelectingValueStage: boolean;
  customerUID: string;
  sentDoc: boolean;

  // Agent
  agentSubscription: Subscription;
  agent: Agent;
  agentUID = '';
  isProcessingAgent = false;

  // Send documents by e-mail
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  recipients: Recipient[] = [];
  copyTo: Recipient[] = [];
  removable = true;
  readonly recipientsLimit = 10;
  visible = true;
  selectable = false;
  selectableCopyTo = false;
  addOnBlur = true;
  addOnBlurCopyTo = true;
  disabled = true;
  hasErrorRecipients = false;
  hasErrorCopyTo = false;

  // Message Preview
  @ViewChild('messagePreview', { static: false }) messagePreview: ElementRef;
  subject = '';
  message = '';
  selectedDocuments: any = {};

  // Agent Notes
  isAgentNoteCreateMode = false;
  isLoadingAgentNotes = false;
  newAgentNote: AgentNote = {
    message: '',
  };
  agentNotes: AgentNote[] = [];
  agentNotesSubscription: Subscription;
  readonly MAX_AGENT_NOTES_PREVIEW_QUANTITY = 5;

  // User Notes
  createdBy = {};

  hasTypeOfAnticipationCard = false;

  // Type Guarantee Antecipation Nota fiscal
  hasTypeOfInvoices = false;

  // MEI Section
  hasTaxRegimeMei = false;
  status: string;

  // Auto-Complete
  submitted = false;
  banksOtherArray: any[] = [];
  selectedOptions: NewOtherInstitution[] = [];
  filteredNewOtherInstitutions: Observable<(NewOtherInstitution | string)[]>;
  newOtherInstitutions: NewOtherInstitution[] = [];
  allNewOtherInstitutions: NewOtherInstitution[] = [];
  @ViewChild('newOtherInstitutionInput') newOtherInstitutionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;



  verifyIsDate = (param: any): boolean => (param ? isDate(param) ?? false : false);
  timestampForDate = (data: any): Date => timestampForDate(data) ?? null;
  objectKeys = (obj: any): string[] => Object.keys(obj) ?? null;
  ngOnInit(): void {
    this.configSubscription = this.configService.getConfiguration('signup').subscribe((config) => {
      console.log(`Signup subscribe ${ new Date().toISOString() }`);
      this.config = config;
    });

    this.getTaxRegimesSubscription = this.cadastrosGeraisService.getRegimesAtivos().subscribe((regimes) => {
      regimes.map((regime) => {
        regime['id'] = regime.mnemonico;
        regime['name'] = regime.nome;

        delete regime.ativo;
        delete regime.mnemonico;
        delete regime.nome;
      });

      this.config.taxRegimes = regimes;
    });
    this.getCardMachinesSubscription = this.cadastrosGeraisService.getMaquinasAtivas().subscribe((maquinas) => {
      maquinas.map((maquina) => {
        maquina['id'] = maquina.mnemonico;
        maquina['name'] = maquina.nome;
        delete maquina.ativo;
        delete maquina.mnemonico;
        delete maquina.nome;
      });
      this.config.cardMachines = maquinas;
    });

    this.onLoadForm();
    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser) => {
      console.log(`User subscribe ${ new Date().toISOString() }`);
      this.loggedUser = loggedUser;
      this.createdBy = {
        uid: loggedUser?.uid,
        name: loggedUser?.name || 'Unknown',
        email: loggedUser?.email,
      };
    });

    this.documentosAtivosSubscription = this.ums.getTiposDocumentosAtivos().subscribe((tiposDocumentos) => {
      this.tipeDocuments = tiposDocumentos;
      console.log(`Documentos ativos subscribe ${ new Date().toISOString() }`);
      this.getDocuments();
    });

    this.userDataSubscription = this.ums.getCustomer(this.customerUID).subscribe((customer) => {
      console.log(`User data subscribe ${ new Date().toISOString() }`);
      if (!customer) {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Falha na Pesquisa',
            alertDescription: 'Usuário Não Encontrado',
            isOnlyConfirm: true,
          },
        });

        this.router.navigate(['admin/clientes/buscar']);
      }
      this.customer = customer;
      this.agreedTerms = this.customer.agreedTerms;
      this.noTermsCustomer = this.customer?.noTermsCustomer;
      // this.isLoadingOpportunitiesFirestore = false;
      if (
        this.customer?.typeOfAnticipation?.length > 0 &&
        this.customer?.typeOfAnticipation?.find((a) => a.id === 'cartao')
      ) {
        this.hasTypeOfAnticipationCard = true;
      }

      if (
        this.customer?.typeOfAnticipation?.length > 0 &&
        this.customer?.typeOfAnticipation?.find((n) => n.id === 'nota_fiscal')
      ) {
        this.hasTypeOfInvoices = true;
      }

      if (this.customer?.companyInformation) {
        if (this.customer?.companyInformation?.foundedDate) {
          this.customer.companyInformation.foundedDate = customer.companyInformation.foundedDate.seconds
            ? new firestore.Timestamp(
              customer.companyInformation.foundedDate.seconds,
              customer.companyInformation.foundedDate.nanoseconds || 999000000
            )
            : Object.keys(customer?.companyInformation?.foundedDate).length > 0
              ? customer.companyInformation.foundedDate
              : null;
        } else if (this.customer?.companyInformation?.foundedAge) {
          this.customer.companyInformation.foundedDate = customer.companyInformation.foundedAge.seconds
            ? new firestore.Timestamp(
              customer.companyInformation.foundedAge.seconds,
              customer.companyInformation.foundedAge.nanoseconds || 999000000
            )
            : Object.keys(customer?.companyInformation?.foundedAge).length > 0
              ? customer.companyInformation.foundedAge
              : null;
        } else {
          this.customer.companyInformation.foundedDate = null;
        }
      }

      this.agentNotesSubscription = this.notesService
        .getAgentComments({
          customerUid: this.customer.uid,
          isSenderAdmin: 'yes',
          agentUid: this.customer.agent,
        })
        .subscribe((notes) => {
          this.agentNotes = notes.slice(0, this.MAX_AGENT_NOTES_PREVIEW_QUANTITY);
          console.log(`Agent notes subscribe ${ new Date().toISOString() }`);
        });

      if (customer.agent) {
        this.agentSubscription = this.agentsAdminService.getAgentData(customer.agent).subscribe((agent) => {
          console.log(`Agent subscribe ${ new Date().toISOString() }`);
          this.agent = agent;
          this.agentUID = agent.uid;
        });
      } else {
        this.agentSubscription = null;
        this.agent = null;
        this.agentUID = '';
      }

      this.onFillFormUserData();

      if (customer && customer.listCreditoPreAprovado && customer.listCreditoPreAprovado.length > 0) {
        if (customer.listCreditoPreAprovado[0].limiteCredito > 0) {
          this.limiteCreditoStr = UtilHandler.getFormattedPrice(customer.listCreditoPreAprovado[0].limiteCredito);
          this.limiteCredito = customer.listCreditoPreAprovado[0].limiteCredito;
        } else {
          this.limiteCreditoStr = 'R$ 0,00';
          this.limiteCredito = 0;
        }
      }

      this.tituloDetalheUsuario = ' - ' + this.customer.name.toUpperCase();
      this.userContractSubscription = this.ums.getDadosContratoUsuario(this.customer.uid).subscribe((contract) => {
        console.log(`Dados contrato subscribe ${ new Date().toISOString() }`);
        if (contract.exists && contract.data()) {
          this.contract = contract.data();
          if (this.agreedTerms) {
            if (this.contract && this.contract.html) {
              this.templateContrato.nativeElement.innerHTML = this.contract.html;
            } else {
              if (this.customer.concordaComTermosExterno) {
                this.templateContrato.nativeElement.innerHTML =
                  'Visualização não disponível para contratos assinados fora do sistema.';
              } else {
                this.templateContrato.nativeElement.innerHTML =
                  'Visualização não disponível para esse tipo de contrato.';
              }
            }
          }
          this.onFillFormUserContract();
        } else {
          this.contract = {
            companyName: this.customer.companyName?.toUpperCase(),
            uid: this.customer.uid,
          };
        }
      });

      this.bigdatacorpSubscription = this.bigdatacorpService
        .getBigDataCorpSavedData(this.customerUID)
        .subscribe((docs) => {
          console.log(`BigDataCorp subscribe ${ new Date().toISOString() }`);
          this.bigdatacorpData = {};
          docs.forEach((doc) => {
            if (Object.keys(doc)?.length > 1) {
              if (
                !!Object.keys(doc).find(
                  (key) => key === 'Origin' && doc['Origin'].search('Murabei Credit Score') !== -1
                )
              ) {
                this.bigdatacorpData.OnlineQuery = { ...this.bigdatacorpData.OnlineQuery, ...doc };
              } else {
                this.bigdatacorpData.BasicData = { ...this.bigdatacorpData.BasicData, ...doc };
              }
            } else {
              _.forEach(doc, (value, key) => {
                if (!!value && !!key) {
                  this.bigdatacorpData[key] = value;
                }
              });
            }
          });

          if (!!this.bigdatacorpData?.OnlineQuery) {
            this.editFormGroup
              .get('scoreCtrl')
              .setValue(Number(this.bigdatacorpData?.OnlineQuery?.QueryResultData?.Score).toFixed(2));
          }
        });

      if (this.customer.banks.find((a) => a.id === 'outro')) {
        this.hasOther = true;
      } else {
        this.hasOther = false;
      }

      this.banksOtherArray = this.customer.banksOther?.split(',').map(option => ({ name: option.trim() })) || [];
      this.selectedOptions = this.allNewOtherInstitutions.filter(option => this.banksOtherArray.some(bank => bank.name === option.name));

      for (const option of this.selectedOptions) {
        if (!this.newOtherInstitutions.some(existingOption => existingOption.name === option.name)) {
          this.newOtherInstitutions.push(option);
        }
      }
    });

  }

  ngOnDestroy(): void {
    if (this.loggedUserSubscription) {
      this.loggedUserSubscription.unsubscribe();
    }
    if (this.getUsersSubscription) {
      this.getUsersSubscription.unsubscribe();
    }
    if (this.getUserSubscription) {
      this.getUserSubscription.unsubscribe();
    }
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
    if (this.bigdatacorpSubscription) {
      this.bigdatacorpSubscription.unsubscribe();
    }
    if (this.agentNotesSubscription) {
      this.agentNotesSubscription.unsubscribe();
    }
    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }
    if (this.userDocumentsSubscription) {
      this.userDocumentsSubscription.unsubscribe();
    }
    if (this.userContractSubscription) {
      this.userContractSubscription.unsubscribe();
    }
    if (this.linhasCreditoAtivasSubscription) {
      this.linhasCreditoAtivasSubscription.unsubscribe();
    }
    if (this.documentosAtivosSubscription) {
      this.documentosAtivosSubscription.unsubscribe();
    }
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    if (this.getDocumentosIFSubscription) {
      this.getDocumentosIFSubscription.unsubscribe();
    }
    if (this.getTiposDocumentosSubscription) {
      this.getTiposDocumentosSubscription.unsubscribe();
    }
    if (this.getUserDocPendencySubscription) {
      this.getUserDocPendencySubscription.unsubscribe();
    }
    if (this.getDetalhesBancoSubscription) {
      this.getDetalhesBancoSubscription.unsubscribe();
    }
    if (this.getTaxRegimesSubscription) {
      this.getTaxRegimesSubscription.unsubscribe();
    }
    if (this.getCardMachinesSubscription) {
      this.getCardMachinesSubscription.unsubscribe();
    }
  }

  // Método para adicionar uma opção Auto-Complete
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Verifica se a opção já foi selecionada
    const alreadySelected = this.newOtherInstitutions.some(selected => selected.name.toLowerCase() === value.trim().toLowerCase());

    if ((value || '').trim() && !alreadySelected) {
      const formattedValue = this.capitalize(value.trim());
      this.newOtherInstitutions.push({ nomeNoSistema: '', name: formattedValue, type: '' });
    }

    // Limpa o input
    input.value = null;
  }

  // Função para capitalizar a primeira letra de cada palavra
  private capitalize(value: string): string {
    const words = value.split(' ');
    const capitalizedWords = words.map(word => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return firstLetter + restOfWord;
    });
    return capitalizedWords.join(' ');
  }

  // Metodo para remover uma opção selecionada Auto-Complete
  remove(newOtherInstitution: NewOtherInstitution): void {
    const index = this.newOtherInstitutions.indexOf(newOtherInstitution);
    if (index >= 0) {
      this.newOtherInstitutions.splice(index, 1);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedOption = event.option.value;

    // Verifica se a opção já foi selecionada
    const alreadySelected = this.newOtherInstitutions.some(selected => selected.name.toLowerCase() === selectedOption.name.toLowerCase());

    if (!alreadySelected) {
      this.newOtherInstitutions.push(selectedOption);
      this.newOtherInstitutionInput.nativeElement.value = '';
      this.editFormGroup.controls.otherInstitutionsCtrl.setValue(this.newOtherInstitutions);
    }
  }


  // Faz a filtragem da lista capturada no onInit Auto-Complete
  private _filter(value: string): NewOtherInstitution[] {
    const filterValue = value.toLowerCase();
    return this.allNewOtherInstitutions.filter(newOtherInstitution => {
      // Verifica se o banco já está selecionado
      const alreadySelected = this.newOtherInstitutions.some(selected => selected.name.toLowerCase() === newOtherInstitution.name.toLowerCase());
      return !alreadySelected && newOtherInstitution.name.toLowerCase().includes(filterValue);
    });
  }

  getDocuments(): void {
    this.runDocuments = true;
    this.userDocumentService
      .getDocuments({ uid: this.customerUID })
      .then((documents) => {
        console.log(`User documents subscribe ${ new Date().toISOString() }`);
        // error getDocuments return the old doc type dont return the same type
        this.documents =
          documents?.length > 0
            ? documents
              .map((d) => {
                if (d.financialYear?.id === 'sim') {
                  const currentYear = new Date().getFullYear();
                  let financialYears: DocumentMongoDB['financialYears'] = null;
                  const anoExercicioCorrente = d.anoExercicioCorrente ?? false;
                  let countYear = 0;

                  while (countYear < d.qtyFinancialYear) {

                    let financialYear;

                    if (anoExercicioCorrente || (anoExercicioCorrente && d.financialYears?.includes(currentYear.toString()))) {
                      financialYear = (currentYear - countYear).toString();
                      countYear++;
                    } else {
                      countYear++;
                      financialYear = (currentYear - countYear).toString();
                    }

                    if (countYear === 1) {
                      financialYears = [financialYear];
                    } else {
                      if (financialYears?.length > 0 && !financialYears.includes(financialYear)) {
                        console.log('year', financialYear);
                        financialYears.push(financialYear);
                      }
                    }
                  }

                  d.financialYears = financialYears;
                }


                return d;
              })
              ?.sort((o1, o2) => {
                if (o1.ordenation > o2.ordenation) {
                  return 1;
                } else if (o1.ordenation < o2.ordenation) {
                  return -1;
                } else {
                  return 0;
                }
              }).map(d => {
                const typeDocument = this.tipeDocuments.find(t => t.id === d.typeId);
                if (typeDocument && typeDocument.maximumDocuments !== d.maximumDocuments) d.maximumDocuments = typeDocument.maximumDocuments;
                if (typeDocument && typeDocument.minimumDocuments !== d.minimumDocuments) d.minimumDocuments = typeDocument.minimumDocuments;
                return d;
              })
            : [];

        if (this.documents?.length > 0) {
          this.getApprovedDocuments().forEach((td, index) => {
            if (td.financialYear.id === 'sim') {
              td.financialYears.forEach((ae) => {
                this.selectedDocuments[`${ td.mnemonic }${ ae }`] = {
                  selected: false,
                  name: `${ td.name } - ${ ae }`,
                  id: td.mnemonic,
                  year: ae,
                  index,
                };
              });
            } else {
              this.selectedDocuments[td.mnemonic] = {
                selected: false,
                name: td.name,
                id: td.mnemonic,
                year: null,
                index,
              };
            }

          });

        }

        // error tiposDocumentos dont return the same type
        this.tipeDocuments = this.tipeDocuments.filter((item) => {
          const td = this.documents?.find((f) => f?.typeId === item?.id) ?? null;
          if (!td) {
            item.situacao = 'Pendente';
            item.qtdArquivosEsperados = 1;
            item.email = this.customerUID;
            if (item?.anoExercicio?.id === 'sim') {
              item.qtdArquivosEsperados = item.anoExercicioQtd;
            }
            item.qtdArquviosSubmetidos = 0;
            return item;
          }
        });
      })
      .finally(() => {
        this.runDocuments = false;
      });
  }

  onLoadForm(): void {
    this.editFormGroup = this.formBuilder.group({
      nomeCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      emailCtrl: new FormControl('', Validators.required),
      cpfCtrl: new FormControl({ value: '', disabled: false }, [Validators.required, NgBrazilValidators.cpf]),
      telefoneCtrl: new FormControl({ value: '', disabled: false }, [NgBrazilValidators.telefone]),
      personalCellCtrl: new FormControl({ value: '', disabled: false }, [NgBrazilValidators.telefone]),
      birthDateCtrl: new FormControl({ value: '' }, []),
      maritalStatusCtrl: new FormControl({ value: '' }, []),
      whatsappCtrl: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(15),
        NgBrazilValidators.telefone,
      ]),
      dataCadastroCtrl: new FormControl({ value: '', disabled: true }, Validators.required),
      razaoSocialCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      cnpjCtrl: new FormControl({ value: '', disabled: false }, [Validators.required, NgBrazilValidators.cnpj]),
      cepCtrl: new FormControl({ value: '', disabled: false }, [Validators.required, NgBrazilValidators.cep]),
      logradouroCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      complementoCtrl: new FormControl({ value: '', disabled: false }),
      numeroCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      bairroCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      cidadeCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      estadoCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      agreedPrivacyPolicyCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      agreedPolicyCookieCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      privacyPolicyDateCtrl: new FormControl({ value: '', disabled: true }, []),
      policyCookieDateCtrl: new FormControl({ value: '', disabled: true }, []),
      dataContratoCtrl: new FormControl({ value: '', disabled: true }, Validators.required),
      concordaComTermosCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      datetimeAceiteCtrl: new FormControl({ value: '', disabled: false }, []),
      valorCapitalStrCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      tempoAtividadeCtrl: new FormControl({ value: '', disabled: true }, Validators.required),
      scoreCtrl: new FormControl({ value: '', disabled: true }, Validators.required),
      valorFaturamentoStrCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      valorDividaStrCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      valorTotalStrCtrl: new FormControl({ value: '', disabled: false }),
      idadeEmpresaCtrl: new FormControl({ value: '', disabled: false }, [Validators.required]),
      foundedDateCtrl: new FormControl({ value: '', disabled: false }, []),

      bancosCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      outrosBancosCtrl: new FormControl({ value: '', disabled: false }, []),
      garantiasCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      typeOfAnticipationCtrl: new FormControl({ value: '', disabled: false }),
      regimeTribCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      finalidadeCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      creditCardCtrl: new FormControl('', []),
      typeOfInvoicesCtrl: new FormControl({ value: '' }, []),
      permiteCancelamentoCtrl: new FormControl({ value: '', disabled: true }, Validators.required),
      datetimeCancelamentoCtrl: new FormControl({ value: '', disabled: true }, []),
      motivoCancelamentoCtrl: new FormControl({ value: '', disabled: true }, []),

      pefinCtrl: new FormControl('', []),
      refinCtrl: new FormControl('', []),
      chequeSemFundoCtrl: new FormControl('', []),
      fraudeCtrl: new FormControl('', []),

      emailContadorCtrl: new FormControl({ value: '', disabled: false }, [Validators.email]),
      telefoneContadorCtrl: new FormControl({ value: '', disabled: false }, [NgBrazilValidators.telefone]),
      origemCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      approvedByCommitteeCtrl: new FormControl({ value: '', disabled: false }, []),
      // websiteCtrl: new FormControl(''),
      personTypeCtrl: new FormControl({ value: '' }, [])
    });

    this.editFormGroup.statusChanges.subscribe(status => {
      if (status === 'INVALID') {
        this.findInvalidControls(this.editFormGroup);
      }
    });

  }

  findInvalidControls(form: FormGroup): void {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
  }

  onFillFormUserData(): void {
    this.hasTypeOfAnticipation = !!this.customer.typeOfAnticipation?.length;
    this.hasOnlyAvalGuarantee = !this.customer.guaranteesValue;
    if (this.customer?.taxRegime) {
      this.onChangetaxRegime({ value: this.customer.taxRegime });
    }

    const date = firebase.firestore.Timestamp.now().toDate();
    const foundedDate = this.customer?.companyInformation?.foundedDate instanceof firebase.firestore.Timestamp
      ? new Date(this.customer?.companyInformation?.foundedDate.toMillis())
      : this.customer?.companyInformation?.foundedDate || new Date();

    const ageInMilliseconds = date.getTime() - foundedDate.getTime();
    const ageInYears = Math.floor(ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));

    if (this.customer && this.customer.companyInformation) {
      this.customer.companyInformation.age = ageInYears;
    }

    this.editFormGroup.get('nomeCtrl').setValue(this.customer.name.toUpperCase());
    this.editFormGroup.get('emailCtrl').setValue(this.customer.email);
    this.editFormGroup.get('cpfCtrl').setValue(this.customer.cpf);
    this.editFormGroup.get('cnpjCtrl').setValue(this.customer.cnpj);
    this.editFormGroup.get('telefoneCtrl').setValue(this.customer.phone);
    this.editFormGroup.get('whatsappCtrl').setValue(this.customer.whatsapp);

    this.editFormGroup.get('valorCapitalStrCtrl').setValue(this.customer.creditValue);

    this.editFormGroup.get('valorFaturamentoStrCtrl').setValue(this.customer.revenue);
    this.editFormGroup.get('valorDividaStrCtrl').setValue(this.customer.debt);
    this.editFormGroup.get('bancosCtrl').setValue(this.customer.banks);
    this.editFormGroup.get('outrosBancosCtrl').setValue(this.customer.banksOther);
    this.editFormGroup.get('garantiasCtrl').setValue(this.customer.guarantees);
    this.editFormGroup.get('razaoSocialCtrl').setValue(this.customer.companyName?.toUpperCase());
    this.editFormGroup.get('cepCtrl').setValue(this.customer.address?.zipCode);
    this.editFormGroup.get('logradouroCtrl').setValue(this.customer.address?.street);
    this.editFormGroup.get('complementoCtrl').setValue(this.customer.address?.extraInfo);
    this.editFormGroup.get('numeroCtrl').setValue(this.customer.address?.number);
    this.editFormGroup.get('bairroCtrl').setValue(this.customer.address?.neighborhood);
    this.editFormGroup.get('cidadeCtrl').setValue(this.customer.address?.city);
    this.editFormGroup.get('estadoCtrl').setValue(this.customer.address?.state);
    this.editFormGroup.get('idadeEmpresaCtrl').setValue(this.customer.companyInformation?.age);
    this.editFormGroup.get('finalidadeCtrl').setValue(this.customer.purpose);
    this.editFormGroup.get('regimeTribCtrl').setValue(this.customer.taxRegime);
    this.editFormGroup.get('origemCtrl').setValue(this.customer.source || this.defaultCESource);
    this.parseCompanyAge();
    if (!!this.customer?.guaranteesValue) {
      this.editFormGroup.get('valorTotalStrCtrl').setValue(this.customer.guaranteesValue);
    }

    // customer with mei
    this.editFormGroup
      .get('birthDateCtrl')
      .setValue(this.customer?.birthDate ? timestampForDate(this.customer.birthDate) : null);
    this.editFormGroup.get('personalCellCtrl').setValue(this.customer?.personalCell ?? null);
    this.editFormGroup.get('maritalStatusCtrl').setValue(this.customer?.maritalStatus ?? null);

    if (this.customer.createdAt) {
      this.editFormGroup.get('dataCadastroCtrl').setValue(timestampForDate(this.customer.createdAt) || null);
    }
    if (this.customer?.typeOfAnticipation?.length > 0) {
      this.editFormGroup.get('typeOfAnticipationCtrl').setValue(this.customer?.typeOfAnticipation);
    }
    if (this.customer?.creditCard?.length > 0) {
      this.editFormGroup.get('creditCardCtrl').setValue(this.customer?.creditCard);
    }
    this.editFormGroup.get('typeOfInvoicesCtrl').setValue(this.customer?.typeOfInvoices ?? null);


    if (this.customer.restrictions?.pefin) {
      this.editFormGroup.get('pefinCtrl').setValue(this.customer.restrictions?.pefin);
    }
    if (this.customer.restrictions?.refin) {
      this.editFormGroup.get('refinCtrl').setValue(this.customer.restrictions?.refin);
    }
    if (this.customer.restrictions?.check) {
      this.editFormGroup.get('chequeSemFundoCtrl').setValue(this.customer.restrictions?.check);
    }

    if (this.customer.restrictions?.fraud) {
      this.editFormGroup.get('fraudeCtrl').setValue(this.customer.restrictions.fraud);
    }

    if (this.customer.accountantData?.email) {
      this.editFormGroup.get('emailContadorCtrl').setValue(this.customer.accountantData?.email);
    }

    if (this.customer.accountantData?.phone) {
      this.editFormGroup.get('telefoneContadorCtrl').setValue(this.customer.accountantData?.phone);
    }

    if (this.customer.userConsent?.privacyPolicyDate) {
      this.editFormGroup.get('agreedPrivacyPolicyCtrl').setValue(this.opcaoSimNaoTermo[1]);
      this.editFormGroup.get('privacyPolicyDateCtrl').setValue(new Date(this.customer.userConsent.privacyPolicyDate));
    } else {
      this.editFormGroup.get('agreedPrivacyPolicyCtrl').setValue(this.opcaoSimNaoTermo[0]);
    }
    if (this.customer.userConsent?.cookiePolicy?.agreedCookie) {
      this.editFormGroup.get('agreedPolicyCookieCtrl').setValue(this.opcaoSimNaoTermo[1]);
      this.editFormGroup
        .get('policyCookieDateCtrl')
        .setValue(new Date(this.customer.userConsent.cookiePolicy.agreedCookiesDate));
    } else {
      this.editFormGroup.get('agreedPolicyCookieCtrl').setValue(this.opcaoSimNaoTermo[0]);
    }

    if (this.customer.agreedTerms) {
      this.editFormGroup.get('concordaComTermosCtrl').setValue(this.opcaoSimNaoTermo[1]);
    } else {
      this.editFormGroup.get('concordaComTermosCtrl').setValue(this.opcaoSimNaoTermo[0]);
    }

    if (this.customer.termsSignatureDate) {
      this.editFormGroup.get('datetimeAceiteCtrl').setValue(timestampForDate(this.customer.termsSignatureDate));
    }

    if (this.customer?.companyInformation?.foundedDate) {
      this.editFormGroup
        .get('foundedDateCtrl')
        .setValue(timestampForDate(this.customer.companyInformation.foundedDate));
    }

    if (!this.customer.cpf) {
      this.customer.cpf = null;
    }
    if (!this.customer.cnpj) {
      this.customer.cnpj = null;
    }
    if (!this.customer.phone) {
      this.customer.phone = null;
    }

    this.editFormGroup.get('approvedByCommitteeCtrl').setValue(this.customer.approvedByCommittee);
    this.editFormGroup.get('websiteCtrl').setValue(this.customer.website?.toLowerCase());
    this.editFormGroup.get('personTypeCtrl').setValue(this.customer.personType)
  }



  openPopup() {
    this.showPopup = true;
  }

  closePopup() {
    this.showPopup = false;
  }

  onFillFormUserContract(): void {
    if (this.contract) {
      this.editFormGroup.get('dataContratoCtrl').setValue(timestampForDate(this.contract.createdAt));
    } else {
      this.contract = {
        address: {
          zipCode: null,
          street: null,
          number: null,
          neighborhood: null,
          city: null,
          state: null,
        },
        banks: this.customer.banks?.join(',') || '',
        cnpj: this.customer.cnpj,
        companyName: this.customer.companyName,
        cpf: this.customer.cpf,
        html: '',
        ip: null,
        uid: this.customer.uid,
      };
      // this.contract = {
      //   id: this.customer.email,
      //   statusContrato: 'none',
      //   dadosContrato: {
      //     bairro: null,
      //     bancos: null,
      //     cep: null,
      //     cidade: null,
      //     cnpj: this.customer.avaliacaoChecklist.cnpj,
      //     cpf: this.customer.avaliacaoChecklist.cpf,
      //     complemento: null,
      //     data: null,
      //     dataContrato: null,
      //     emailContratante: this.customer.email,
      //     endereco: null,
      //     estado: null,
      //     logradouro: null,
      //     nomeContratante: this.customer.nome.toUpperCase(),
      //     numero: null,
      //     razaoSocial: null,
      //   },
      //   htmlContrato: null
      // };
    }

    if (this.customer.permiteCancelamento) {
      const datetimeNow = _moment();
      const datetime24h = _moment(timestampForDate(this.customer.termsSignatureDate)).add(24, 'hours');
      if (datetimeNow < datetime24h) {
        this.editFormGroup.get('permiteCancelamentoCtrl').setValue(this.opcaoSimNao[0]);
      } else {
        this.editFormGroup.get('permiteCancelamentoCtrl').setValue(this.opcaoSimNao[1]);
      }
    } else {
      this.editFormGroup.get('permiteCancelamentoCtrl').setValue(this.opcaoSimNao[1]);
    }
    if (this.customer?.canceledTerms?.date) {
      this.editFormGroup
        .get('datetimeCancelamentoCtrl')
        .setValue(timestampForDate(this.customer?.canceledTerms?.date) ?? null);
    }
    this.editFormGroup.get('motivoCancelamentoCtrl').setValue(this.customer?.canceledTerms?.motivation ?? '');
  }

  onAddNewDoc(): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Adicionar Documentos',
          alertDescription: 'Deseja realmente associar esses documentos ao usuário?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          console.log('result', result);
          const addNewDocs: any[] = [];
          // const documentosPendentes = [];
          // const documentsName = [];
          let maxOrder: number = _.max(this.documents.map((o) => o?.ordenation)) ?? 0;
          maxOrder = maxOrder + 1;
          console.log(
            'onAddNewDoc this.documentosAtivosSelecionados.selectedOptions.selected',
            this.documentosAtivosSelecionados.selectedOptions.selected
          );
          this.documentosAtivosSelecionados.selectedOptions.selected.forEach((item) => {
            const tipoDocumento: DocumentMongoDB = item.value;
            tipoDocumento.situation = 'Pendente';
            tipoDocumento.ordenation = maxOrder;
            tipoDocumento.qtyExpectedFiles = 1;
            tipoDocumento.fileInfo = [];
            tipoDocumento.expirationControlId = null;
            tipoDocumento.approvalOrDisapprovalDate = null;
            tipoDocumento.pendingIssueForUser = true;
            maxOrder = maxOrder + 1;
            tipoDocumento.uid = this.customer.uid;
            tipoDocumento.email = this.customer.email;
            addNewDocs.push(tipoDocumento);
          });
          this.userDocumentService.postDocuments(addNewDocs).then(() => {
            const documentsToSign = this.documentosAtivosSelecionados.selectedOptions.selected.filter(
              (item: { value: Document }) => item.value.documentAction === DocumentAction.SIGN
            );
            if (this.customer?.partnerCE !== CustomerPartner.SIMCO) {
              documentsToSign?.forEach((item: { value: Document }) => {
                this.customersAdminService.notifyNewDocumentToSign({
                  documentName: item.value.nome,
                  customer: this.customer,
                });
              });
            }


            this.getDocuments();
          });
        }
      });
  }

  hasUploadedDocs(customerDocs): boolean {
    const uploadedDocs = customerDocs.filter((doc) => {
      if (doc.situation !== 'Pendente') {
        return true;
      } else {
        return false;
      }
    });
    return uploadedDocs.length > 0;
  }

  async downloadAllDocs(customerDocs: DocumentMongoDB[]): Promise<void> {
    const docsZip = new JSZip();

    this.isLoadingDoc = true;

    const docExtensionRegex = /(\.[\w]+)(?=\?)/;

    for (const doc of customerDocs) {
      for (let idx = 0; idx < doc?.fileInfo?.length; idx++) {
        const info = doc?.fileInfo[idx];
        const extension = docExtensionRegex.exec(info.path);

        // Verifica se o campo documentAction é igual a 'sign'
        if (doc.documentAction === 'sign') {
          continue; // Pula este documento e não o adiciona ao zip
        }

        try {
          const doczipData = (await this.userDocumentService.downloadFile(info.path)) ?? null;
          const infoUploadDate = info?.dateUpload.toString() ?? '';
          if (!!doczipData) {
            docsZip.file(
              (info?.financialYear
                ? doc.name + ' - ' + info.financialYear + ' - ' + (idx + 1) + ' - ' + infoUploadDate
                : doc.name + ' - ' + (idx + 1) + ' - ' + infoUploadDate
              ).replace(/\s+/g, '-') + (extension && extension[0]),
              doczipData
            );
          }
        } catch (error) {
          this.dialog
            .open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao Baixar Documentos',
                alertDescription:
                  'Erro na geração do zip do arquivo - ' +
                  doc.name +
                  '. Se ouver mais documentos será gerado um arquivo zip sem o documento especifico.',
                isOnlyConfirm: true,
              },
            })
            .afterClosed();
          console.error('Erro na geração do zip do arquivo - ', doc.name, ' : ', error);
        }
      }
    }

    const zipName =
      this.customer.companyName?.toLowerCase().replace(/\s+/g, '-') + '-' + new Date(Date.now()).toISOString() + '.zip';
    docsZip.generateAsync({ type: 'blob' }).then(function (docs) {
      saveAs(docs, zipName);
    }).finally(() => {
      this.isLoadingDoc = false;  // Termina o carregamento
    });
  }


  onApproveDocuments(typeId: string): void {
    // aprovar documentos

    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Aprovar documento',
          alertDescription: 'Deseja realmente aprovar esse documento?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const document = this.documents.find((d) => d.typeId === typeId);
          document.situation = 'Aprovado';
          document.approvalOrDisapprovalDate = new Date();
          document.pendingIssueForAdmin = false;
          document.userAproReprov = this.loggedUser.uid;
          document.uid = document?.uid || this.customer.uid;
          this.userDocumentService.patchDocument(document).then(() => {
            this.ums.aprovarDocumentosUsuario(this.customer.uid, document);
          });
        }
      });
  }

  onReprovarDocumentos(typeId: string): void {
    // reprovar documento

    const dataTextDescription = {
      placeholder: 'Motivo',
      errorMessage: 'É obrigatório informar um motivo.',
      dataTextCtrl: '',
    };
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Reprovar documento',
          alertDescription: 'Informe abaixo o motivo pelo qual está reprovando o documento.',
          hasTextDescription: true,
          dataTextDescription: dataTextDescription,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.onSalvardocsRep(typeId, dataTextDescription.dataTextCtrl);
          const document = this.documents.find((d) => d.typeId === typeId);
          document.situation = 'Reprovado';
          document.approvalOrDisapprovalDate = new Date();
          document.disapprovalReason = dataTextDescription.dataTextCtrl;
          document.pendingIssueForAdmin = false;
          document.pendingIssueForUser = true;
          document.email = this.customer.email;
          document.userAproReprov = this.loggedUser.uid;
          document.uid = this.customer.uid;
          this.userDocumentService.patchDocument(document);
          this.userDocumentService.setDocumentoReprovado(document);
        }
      });
  }

  onSalvarOrdenacao(): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Ordem de exibição',
          alertDescription: 'Deseja realmente alterar a ordem de exibição desse documento?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // this.userDocumentService.setDocuments(this.documents, this.customer.uid);
        }
      });
  }

  onSalvardocsRep(typeId: string, dataTextDescription: string): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Deseja manter algum documento?',
          alertDescription: 'Deseja realmente manter algum documento?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (result) {
            const document = this.documents.find((d) => d.typeId === typeId);
            document.incomplete = true;
            document.situation = 'Reprovado';
            document.approvalOrDisapprovalDate = new Date();
            document.disapprovalReason = dataTextDescription;
            document.pendingIssueForAdmin = false;
            document.pendingIssueForUser = true;
            document.email = this.customer.email;
            document.userAproReprov = this.loggedUser.uid;
            document.uid = this.customer.uid;
            this.userDocumentService.patchDocument(document);
            this.userDocumentService.setDocumentoReprovado(document);
          }
        }
      });
  }

  onRemoverDocumento(typeId): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Remover tipo documento',
          alertDescription:
            `Deseja realmente remover esse tipo de documento da lista deste usuário? <br>` +
            ` - Obs.: Todos os documentos associados à ele serão excluídos.`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // remove o documento selecionado do array.
          const exclusedDocument = this.documents.find((d) => d.typeId === typeId);

          if (!!exclusedDocument) {
            this.userDocumentService.deleteDocument(this.customer.uid, exclusedDocument?.typeId).then(() => {
              if (exclusedDocument?.expirationControlId) {
                this.ums.deleteControleVencimento(exclusedDocument?.expirationControlId);
              }
            });
          }
          this.ngOnInit();
        }
      });
  }

  onReprocessDocuments(): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmar Ação',
          alertDescription: `Deseja realmente reprocessar os documentos do cliente?`,
        },
      })
      .afterClosed()
      .toPromise()
      .then((confirmed) => {
        if (confirmed) {
          this.processingDocuments = true;
          this.ums
            .reprocessDocuments(this.customer as unknown, [...this.documents] as any[])
            .then(async () => {
              this.processingDocuments = false;
              this.dialog
                .open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Documentos Reprocessados',
                    alertDescription: `Documentos reprocessados com sucesso.`,
                    isOnlyConfirm: true,
                  },
                })
                .afterClosed()
                .subscribe(() => this.ngOnInit());
            })
            .catch((err) => {
              console.error(err);
              this.processingDocuments = false;
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro',
                  alertDescription: `Não foi possível reprocessar os documentos.`,
                  isOnlyConfirm: true,
                },
              });
            });
        }
      });
  }

  onChangeBancos(event): void {
    if (event.value.find((a) => a.id === 'outro')) {
      this.hasOther = true;
    } else {
      this.hasOther = false;
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1?.name === o2?.name && o1?.id === o2?.id;
  }

  compareObjectsById(o1: any, o2: any): boolean {
    return o1?.id === o2?.id;
  }

  compareObjectsColumns(o1: any, o2: any): boolean {
    return o1 && o2 && o1.name === o2.name && o1.id === o2.id;
  }

  compareTaxRegimes(regimes1: BasicStructure, regimes2: BasicStructure): boolean {
    if (regimes1?.id === regimes2?.id) {
      return true;
    } else if (
      ['simples', 'simples_nacional'].includes(regimes1.id) &&
      ['simples', 'simples_nacional'].includes(regimes2.id)
    ) {
      return true;
    } else if (
      ['lucro-presumido', 'lucro_presumido'].includes(regimes1.id) &&
      ['simples', 'simples_nacional'].includes(regimes2.id)
    ) {
      return true;
    } else if (
      ['lucro-real', 'lucro_real'].includes(regimes1.id) &&
      ['simples', 'simples_nacional'].includes(regimes2.id)
    ) {
      return true;
    }
    return false;
  }

  onValidCnpj(userCnpj: string): void {
    if (userCnpj.length === 18) {
      let qtdRegistros = 1;
      if (userCnpj === this.customer.cnpj) {
        qtdRegistros = 2;
      }
      this.userCnpjValidationSubscription = this.ums.getCustomerByCNPJ(userCnpj).subscribe((user) => {
        if (!user.empty && user.docs.length >= qtdRegistros) {
          this.editFormGroup.controls.cnpjCtrl.setErrors({ exists: true });
        }
      });
    }
  }

  onSaveForm(): void {
    if (this.editFormGroup.controls.cnpjCtrl.hasError('exists')) {
      // Não permitir concordar com o termo na área administrativa
      this.dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Campo - CNPJ',
          alertDescription: `O CNPJ informado já está associado à outro usuário.`,
          isOnlyConfirm: true,
        },
      });
      this.isProcessing = false;
    } else if (this.editFormGroup.controls.concordaComTermosCtrl.value.id === 'sim' && !this.customer.agreedTerms) {
      // Não permitir concordar com o termo na área administrativa
      this.dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Campo - Concordou com o termo.',
          alertDescription:
            `Os Termos de Serviços só pode ser aceito pelo próprio usuário do sistema.<br>` +
            `Caso o usuário tenha assinado os Termos de Serviços fora do sistema, escolha a opção 'Sim (Fora do Sistema)' e informe a data do aceite.`,
          isOnlyConfirm: true,
        },
      });
      this.isProcessing = false;
    } else {
      this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Salvar alterações',
            alertDescription: 'Deseja realmente salvar as alterações realizadas para esse usuário?',
          },
        })
        .afterClosed()
        .subscribe(
          async (result) => {
            if (result) {
              this.isProcessing = true;
              this.customer.name = this.editFormGroup.controls.nomeCtrl.value.toUpperCase();
              if (this.editFormGroup.controls.cpfCtrl.value) {
                this.customer.cpf = this.editFormGroup.controls.cpfCtrl.value;
                if (this.contract) {
                  this.contract.cpf = this.editFormGroup.controls.cpfCtrl.value;
                }
              }
              if (this.editFormGroup.controls.cnpjCtrl.value) {
                this.customer.cnpj = this.editFormGroup.controls.cnpjCtrl.value;
                this.contract.cnpj = this.editFormGroup.controls.cnpjCtrl.value;
              }
              if (this.editFormGroup.controls.telefoneCtrl.value) {
                this.customer.phone = this.editFormGroup.controls.telefoneCtrl.value;
              }

              this.customer.createdAt = firestore.Timestamp.fromDate(
                _moment(this.editFormGroup.controls.dataCadastroCtrl.value).toDate()
              );

              this.customer.companyName = this.editFormGroup.controls.razaoSocialCtrl.value.toUpperCase();
              this.contract.companyName = this.editFormGroup.controls.razaoSocialCtrl.value.toUpperCase();
              // this.customer.website = this.editFormGroup.controls?.websiteCtrl?.value?.toLowerCase() ?? null;

              this.customer.address = {
                zipCode: this.editFormGroup.controls.cepCtrl.value,
                street: this.editFormGroup.controls.logradouroCtrl.value,
                number: this.editFormGroup.controls.numeroCtrl.value,
                neighborhood: this.editFormGroup.controls.bairroCtrl.value,
                city: this.editFormGroup.controls.cidadeCtrl.value,
                state: this.editFormGroup.controls.estadoCtrl.value,
                extraInfo: this.editFormGroup.controls.complementoCtrl.value || '',
              };

              this.customer.accountantData = {
                email: this.editFormGroup.controls.emailContadorCtrl.value,
                phone: this.editFormGroup.controls.telefoneContadorCtrl.value,
              };

              this.contract.address = this.customer.address;

              if (this.editFormGroup.controls.dataContratoCtrl.value) {
                this.contract.createdAt = firestore.Timestamp.fromDate(
                  _moment(this.editFormGroup.controls.dataContratoCtrl.value).toDate()
                );
              }

              if (this.editFormGroup.controls.datetimeAceiteCtrl.value) {
                this.customer.termsSignatureDate = firestore.Timestamp.fromDate(
                  _moment(this.editFormGroup.controls.datetimeAceiteCtrl.value).toDate()
                );
                this.contract.signedAt = this.customer.termsSignatureDate;
              }
              // Alterar a situação do usuário somente se houver alteração em dados referente ao termo.
              let hasUpdateTerm = false;
              if (
                this.editFormGroup.controls.concordaComTermosCtrl.value.id !==
                (this.customer.agreedTerms ? 'sim' : 'nao') &&
                this.editFormGroup.controls.concordaComTermosCtrl.value.id !==
                (this.customer.concordaComTermosExterno ? 'sim-fora-sistema' : 'nao')
              ) {
                hasUpdateTerm = true;
              }

              if (this.editFormGroup.controls.foundedDateCtrl.value) {
                this.customer.companyInformation.foundedDate = firestore.Timestamp.fromDate(
                  _moment(this.editFormGroup.controls.foundedDateCtrl.value).toDate()
                );
              }

              if (this.editFormGroup.controls.concordaComTermosCtrl.value) {
                const concordaComTermo = this.editFormGroup.controls.concordaComTermosCtrl.value;

                if (concordaComTermo.id === 'sim-fora-sistema') {
                  this.customer.concordaComTermosExterno = true;
                  this.customer.agreedTerms = true;
                } else if (concordaComTermo.id === 'sim') {
                  this.customer.agreedTerms = true;
                  this.customer.concordaComTermosExterno = false;
                } else {
                  this.customer.concordaComTermos = false;
                  this.customer.concordaComTermosExterno = false;
                }
              } else {
                this.customer.agreedTerms = false;
                this.customer.concordaComTermosExterno = false;
              }
              this.agreedTerms = this.customer.agreedTerms;

              if (isNaN(this.editFormGroup.controls.valorCapitalStrCtrl.value)) {
                this.customer.creditValue = +this.editFormGroup.controls.valorCapitalStrCtrl.value.replace(/\D+/g, '');
              } else {
                this.customer.creditValue = +this.editFormGroup.controls.valorCapitalStrCtrl.value;
              }
              this.customer.creditValueStr = UtilHandler.getFormattedPrice(this.customer.creditValue);

              if (isNaN(this.editFormGroup.controls.valorFaturamentoStrCtrl.value)) {
                this.customer.revenue = +this.editFormGroup.controls.valorFaturamentoStrCtrl.value.replace(/\D+/g, '');
              } else {
                this.customer.revenue = +this.editFormGroup.controls.valorFaturamentoStrCtrl.value;
              }
              this.customer.revenueStr = UtilHandler.getFormattedPrice(this.customer.revenue);

              if (this.editFormGroup.value?.creditCardCtrl?.length > 0) {
                this.customer.creditCard = this.editFormGroup.value.creditCardCtrl;
              } else {
                this.customer.creditCard = null;
              }
              if (isNaN(this.editFormGroup.controls.valorDividaStrCtrl.value)) {
                this.customer.debt = +this.editFormGroup.controls.valorDividaStrCtrl.value.replace(/\D+/g, '');
              } else {
                this.customer.debt = +this.editFormGroup.controls.valorDividaStrCtrl.value;
              }
              this.customer.debtStr = UtilHandler.getFormattedPrice(this.customer.debt);
              // salvando banks Auto-Complete
              const selectedInstitutions = this.newOtherInstitutions.length ? this.newOtherInstitutions : [];
              this.customer.banks = [...this.editFormGroup.controls.bancosCtrl.value, ...selectedInstitutions];
              // salvando banksOthers Auto-Complete
              const selectedOptions: NewOtherInstitution[] = [];
              for (let i = 0; i < this.newOtherInstitutions.length; i++) {
                selectedOptions.push(this.newOtherInstitutions[i]);
              }
              this.customer.banksOther = selectedOptions.map(option => option.name).join(', ');

              this.customer.guarantees = this.editFormGroup.controls.garantiasCtrl.value;
              this.customer.typeOfAnticipation = this.hasTypeOfAnticipation
                ? this.editFormGroup.controls?.typeOfAnticipationCtrl?.value ?? null
                : null;

              if (!this.hasOnlyAvalGuarantee && this.editFormGroup.controls.valorTotalStrCtrl.value) {
                if (isNaN(this.editFormGroup.controls.valorTotalStrCtrl.value)) {
                  this.customer.guaranteesValue = +this.editFormGroup.controls.valorTotalStrCtrl.value.replace(
                    /\D+/g,
                    ''
                  );
                } else {
                  this.customer.guaranteesValue = +this.editFormGroup.controls.valorTotalStrCtrl.value;
                }
              } else {
                this.customer.guaranteesValue = 0;
              }
              this.customer.guaranteesValueStr = UtilHandler.getFormattedPrice(this.customer.guaranteesValue);

              if (this.editFormGroup.value?.creditCardCtrl?.length > 0) {
                this.customer.creditCard = this.editFormGroup.value.creditCardCtrl;
              } else {
                this.customer.creditCard = null;
              }

              this.customer.restrictions = {
                pefin: this.editFormGroup.value.pefinCtrl,
                refin: this.editFormGroup.value.refinCtrl,
                check: this.editFormGroup.value.chequeSemFundoCtrl,
                fraud: this.editFormGroup.value.fraudeCtrl,
              };

              this.customer.whatsapp = this.editFormGroup.value.whatsappCtrl;

              this.customer.personalCell = this.editFormGroup.value?.personalCellCtrl ?? null;

              this.customer.birthDate = this.editFormGroup.controls?.birthDateCtrl?.value
                ? firestore.Timestamp.fromDate(_moment(this.editFormGroup.controls?.birthDateCtrl?.value).toDate())
                : null;

              this.customer.maritalStatus = this.editFormGroup.value?.maritalStatusCtrl ?? null;
              this.customer.typeOfInvoices = this.editFormGroup.value?.typeOfInvoicesCtrl ?? null;
              this.customer.personType = this.editFormGroup.value?.personTypeCtrl ?? null;


              this.customer.purpose = this.editFormGroup.value?.finalidadeCtrl ?? null;

              this.customer.taxRegime = this.editFormGroup.value?.regimeTribCtrl ?? null;

              if (!this.customer.companyInformation) {
                this.customer.companyInformation = {};
              }
              // origem
              if (this.editFormGroup.controls.origemCtrl.value) {
                this.customer.source = this.editFormGroup.controls.origemCtrl.value || this.defaultCESource;
              }

              // approved by committee
              this.customer.approvedByCommittee = this.editFormGroup.controls.approvedByCommitteeCtrl.value;

              this.customer.companyInformation.age = this.editFormGroup.value.idadeEmpresaCtrl;

              // todo => Use backend function
              const newEmail = this.editFormGroup.get('emailCtrl').value;
              if (newEmail !== this.customer.email) {
                try {
                  const emailUsed = await this.authService.checkIfUserEmailExists(newEmail);
                  if (!emailUsed) {
                    await this.ums
                      .changeUserEmail(
                        this.customer.uid,
                        this.editFormGroup.get('emailCtrl').value,
                        this.customer.email,
                        this.loggedUser.email,
                        'customers'
                      )
                      .catch((err) => {
                        this.editFormGroup.get('emailCtrl').setValue(this.customer.email);
                        console.error(err);
                      });
                    await this.updateCustomer(hasUpdateTerm, this.customer);
                  } else {
                    this.dialog
                      .open(AlertDialogComponent, {
                        maxWidth: '600px',
                        data: {
                          alertTitle: 'Email em uso',
                          alertDescription: `O email ${ newEmail } já está em uso.`,
                          isOnlyConfirm: true,
                        },
                      })
                      .afterClosed()
                      .subscribe(() => {
                        this.editFormGroup.get('emailCtrl').setValue(this.customer.email);
                        this.isProcessing = false;
                      });
                  }
                } catch (error) {
                  console.error(error);
                  this.dialog.open(AlertDialogComponent, {
                    maxWidth: '600px',
                    data: {
                      alertTitle: 'Erro ao alterar o Email',
                      alertDescription: 'Ocorreu um erro ao alterar o Email informado. Tente novamente mais tarde.',
                      isOnlyConfirm: true,
                    },
                  });
                }
              } else {
                this.updateCustomer(hasUpdateTerm, this.customer);
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  updateCustomer(hasUpdateTerm: boolean, customerData: Customer): void {
    this.ums
      .updateUserBackend(customerData, this.contract, hasUpdateTerm)
      .then(() => {
        this.logger.log('Usuário alterado com sucesso');
        if (this.editFormGroup.valid) {
          const dialogSubs = this.dialog
            .open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Processar',
                alertDescription:
                  `As alterações foram realizadas com sucesso.` +
                  `<br>Deseja <b>processar</b> as linhas de crédito para esse usuário?`,
              },
            })
            .afterClosed()
            .subscribe(
              (resposta) => {
                if (resposta) {
                  this.isProcessing = true;
                  this.ums
                    .reprocessOpportunities(customerData.uid)
                    .then(() => {
                      this.dialog
                        .open(AlertDialogComponent, {
                          maxWidth: '600px',
                          data: {
                            alertTitle: 'Processar',
                            alertDescription: `As oportunidades foram processadas com sucesso.`,
                            isOnlyConfirm: true,
                          },
                        })
                        .afterClosed()
                        .subscribe(() => {
                          this.ngOnInit()
                        });
                    })
                    .catch(() => {
                      this.dialog.open(AlertDialogComponent, {
                        maxWidth: '600px',
                        data: {
                          alertTitle: 'Erro ao Processar',
                          alertDescription: `Ocorreu um erro ao tentar reprocessar o cliente`,
                          isOnlyConfirm: true,
                        },
                      });
                    })
                    .finally(async () => {
                      this.isProcessing = false;
                      this.customerOpportunityC.getOpportunities();
                      try {
                        await this.hubspotService.upsertCustomer(customerData);
                        console.log("Integrating on hubspot after reprocessing!");
                      }
                      catch {
                        console.log("Error - integrating on hubspot after reprocessing!!");
                      }
                    });
                }

                if (dialogSubs) {
                  dialogSubs.unsubscribe();
                }
              },
              (err) => {
                console.error(err);
              }
            );
        } else {
        }
      })
      .catch((e) => {
        this.logger.error('Problemas ao tentar alterar um usuário', this.customer.uid);
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro',
            alertDescription: `Problemas ao tentar alterar um usuário.`,
            isOnlyConfirm: true,
          },
        });
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }

  onBuscarCEP(): void {
    const cepValue = this.cep.value.replace('.', '').replace('-', '');
    if (cepValue !== null && cepValue !== '' && cepValue.length === 8 && this.ultimoCepBuscado !== this.cep.value) {
      this.ultimoCepBuscado = this.cep.value;

      this.viacep
        .buscarPorCep(cepValue)
        .then((endereco: Endereco) => {
          if (endereco.cep === undefined) {
            this.editFormGroup.get('cepCtrl').setErrors({ incorrect: true });

            this.editFormGroup.get('logradouroCtrl').setValue('');
            this.editFormGroup.get('bairroCtrl').setValue('');
            this.editFormGroup.get('cidadeCtrl').setValue('');
            this.editFormGroup.get('estadoCtrl').setValue('');
          } else {
            if (endereco.logradouro) {
              this.placeholderLogr = 'Endereço (preenchimento automático)';
              this.editFormGroup.get('logradouroCtrl').setValue(endereco.logradouro);
              this.editFormGroup.get('logradouroCtrl').disable({ onlySelf: true });
            } else {
              this.placeholderLogr = 'Endereço';
              this.editFormGroup.get('logradouroCtrl').setValue('');
              this.editFormGroup.get('logradouroCtrl').enable({ onlySelf: true });
            }
            if (endereco.bairro) {
              this.placeholderBair = 'Bairro (preenchimento automático)';
              this.editFormGroup.get('bairroCtrl').setValue(endereco.bairro);
              this.editFormGroup.get('bairroCtrl').disable({ onlySelf: true });
            } else {
              this.placeholderBair = 'Bairro';
              this.editFormGroup.get('bairroCtrl').setValue('');
              this.editFormGroup.get('bairroCtrl').enable({ onlySelf: true });
            }
            this.editFormGroup.get('cidadeCtrl').setValue(endereco.localidade);
            this.editFormGroup.get('estadoCtrl').setValue(endereco.uf);
          }
        })
        .catch((error: CepError) => {
          this.editFormGroup.get('cepCtrl').setErrors({ incorrect: true });
          this.logger.error('Erro na busca de cep', error.descricao);
        });
    }
  }

  resendTerms(): void {
    if (this.agreedTerms && this.contract.html) {
      this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '700px',
          data: {
            alertTitle: 'Reenviar Termos de Serviço',
            alertDescription:
              `Essa ação reenvia um e-mail com os Termos de Serviço para o usuário: ${ this.customer.email }.` +
              `<br>Deseja realmente continuar?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.isProcessing = true;
            this.ums
              .resendContract(this.contract, { name: this.customer.name, email: this.customer.email })
              .then(() => {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Sucesso',
                    alertDescription: `Reenvio realizado com sucesso.`,
                    isOnlyConfirm: true,
                  },
                });
                this.isProcessing = false;
              })
              .catch((error) => {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro',
                    alertDescription: `Houve um problema ao tentar reenviar os Termos de Serviço.`,
                    isOnlyConfirm: true,
                  },
                });
                this.logger.error('Houve um problema ao tentar reenviar os Termos de Serviço', error);
                this.isProcessing = false;
              });
          }
        });
    }
  }

  cancelTerms(el: HTMLElement): void {
    if (this.agreedTerms && this.contract.html) {
      const dataTextDescription = {
        placeholder: 'Motivo',
        errorMessage: 'É obrigatório informar um motivo.',
        dataTextCtrl: '',
      };
      this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '700px',
          data: {
            alertTitle: 'Cancelar Termos de Serviço',
            alertDescription:
              `Essa ação cancela os Termos de Serviço para o usuário: ${ this.customer.email }.` +
              `<br>Deseja realmente confirmar?`,
            isInput: true,
            hasTextDescription: true,
            dataTextDescription: dataTextDescription,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.isProcessing = true;

            this.customerService
              .canceledTerms(this.customer.uid, dataTextDescription.dataTextCtrl)
              .then(() => {
                this.dialog
                  .open(AlertDialogComponent, {
                    maxWidth: '700px',
                    data: {
                      alertTitle: 'Termos de Serviço Cancelado',
                      alertDescription:
                        `Os Termos de Serviço do usuário: ${ this.customer.email } foi cancelado com sucesso.` +
                        `<br>Todas suas oportunidades foram fechadas.`,
                      isOnlyConfirm: true,
                    },
                  })
                  .afterClosed()
                  .subscribe(() => {
                    this.tab.selectedIndex = this.tabOptions.indexOf('dados');
                    el.scrollIntoView();
                  });
              })
              .catch(() => {
                this.dialog
                  .open(AlertDialogComponent, {
                    maxWidth: '700px',
                    data: {
                      alertTitle: 'Erro ao Cancelar Termos de Serviço',
                      alertDescription: `Não foi possivel cancelar os Termos de Serviço do usuário: ${ this.customer.email }.`,
                      isOnlyConfirm: true,
                    },
                  })
                  .afterClosed()
                  .subscribe();
              })
              .finally(() => {
                this.isProcessing = false;
              });
          } else {
          }
        });
    }
  }

  onSalvarArquivo(): void {
    const newFile: ArquivoInfoMongoDB = {
      dateRelease: this.fileInfoFormGroup.controls.dataEmissaoCtrl.value,
      financialYear: this.fileInfoFormGroup.controls.anoExercicioCtrl.value,
    };
    // Validar se a data de emissão não é uma data futura.
    const dateNow = new Date();
    const timestampNow = firestore.Timestamp.now();
    if (newFile.dateRelease <= dateNow) {
      const imgUrl = this.fileUpload.getImageUrl();
      if (imgUrl) {
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '600px',
            data: { alertTitle: 'Confimação', alertDescription: 'Deseja realmente salvar esse documento?' },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              newFile.path = imgUrl;
              newFile.userUploader = this.loggedUser.uid;
              newFile.dateUpload = new Date();
              this.documentSelected.uid = this.customer.uid;
              if (!this.documentSelected.fileInfo) {
                this.documentSelected.fileInfo = [];
                this.documentSelected.fileInfo.push(newFile);
                if (this.documentSelected.qtySubmittedFiles < this.documentSelected.qtyExpectedFiles) {
                  this.documentSelected.qtySubmittedFiles = this.documentSelected.qtySubmittedFiles + 1;
                } else {
                  this.documentSelected.qtySubmittedFiles = this.documentSelected.qtyExpectedFiles;
                }
              } else {
                if (this.documentSelected.financialYear.id === 'sim') {
                  const fileInfo = this.documentSelected.fileInfo.find(
                    (a) => a.financialYear === newFile.financialYear
                  );
                  if (fileInfo) {
                    fileInfo.financialYear = newFile.financialYear;
                    fileInfo.dateRelease = newFile.dateRelease;
                    fileInfo.path = newFile.path;
                    fileInfo.userUploader = newFile.userUploader;
                    fileInfo.dateUpload = newFile.dateUpload;
                  } else {
                    this.documentSelected.fileInfo.push(newFile);
                    if (this.documentSelected.qtySubmittedFiles < this.documentSelected.qtyExpectedFiles) {
                      this.documentSelected.qtySubmittedFiles = this.documentSelected.qtySubmittedFiles + 1;
                    } else {
                      this.documentSelected.qtySubmittedFiles = this.documentSelected.qtyExpectedFiles;
                    }
                  }
                } else {
                  this.documentSelected.fileInfo = [newFile];
                  if (this.documentSelected.qtySubmittedFiles < this.documentSelected.qtyExpectedFiles) {
                    this.documentSelected.qtySubmittedFiles = this.documentSelected.qtySubmittedFiles + 1;
                  } else {
                    this.documentSelected.qtySubmittedFiles = this.documentSelected.qtyExpectedFiles;
                  }
                }
              }
              if (this.documentSelected.qtySubmittedFiles !== this.documentSelected.qtyExpectedFiles) {
                this.documentSelected.situation = 'Enviado Parcialmente';
              } else {
                this.documentSelected.situation = 'Envio Completo';
              }

              let mnemonicoDocumento = null;
              if (this.documentSelected.mnemonic) {
                mnemonicoDocumento = this.documentSelected.mnemonic;
              }
              if (this.documentSelected.pendingIssueForAdmin) {
                this.documentSelected.pendingIssueForAdmin = false;
              }
              // sempre que realizar um upload de um documento que tiver data de emissão, incluí-lo na tabela de
              // controle de vencimento. Será retirado dessa tabela após a detecção do vencimento, aprovação ou reprovação do documento
              if (this.documentSelected.expirationDate.id === 'sim') {
                /*const dataVencimento = _moment(this.fileInfoFormGroup.controls.dataEmissaoCtrl.value).add(
                  this.documentSelected.qtyDays,
                  'days'
                );*/
                const dateEmission = firestore.Timestamp.fromDate(
                  _moment(this.fileInfoFormGroup.controls.dataEmissaoCtrl.value).toDate()
                );
                const dataVencimento = firestore.Timestamp.fromMillis(
                  dateEmission.toMillis() + 1000 * 60 * 60 * 24 * this.documentSelected.qtyDays
                );

                const dataControleVencimento: DocControleVencimento = {
                  uid: this.documentSelected.uid,
                  tipoDocumento: this.documentSelected.mnemonic,
                  qtdDias: this.documentSelected.qtyDays,
                  dataEmissao: timestampNow,
                  dataVencimento: dataVencimento,
                  estaVencido: false,
                  dataCriacao: timestampNow,
                };
                this.documentSelected.uid = this.customer.uid;
                this.userDocumentService
                  .addControleVencimento(dataControleVencimento)
                  .then((res) => {
                    this.documentSelected.expirationControlId = res;
                    this.documentSelected.uid = this.customer.uid;
                    this.userDocumentService.patchDocument(this.documentSelected);
                  })
                  .catch((e) => {
                    this.logger.error('Problemas ao incluir o novo controle de vencimento de documentos', e);
                  });
              } else {
                this.documentSelected.uid = this.customer.uid;
                console.log('onSalvarArquivo this.documentSelected', this.documentSelected);
                this.userDocumentService.patchDocument(this.documentSelected);
              }
              this.isUpload = false;
            }
          });
      } else {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Arquivo obrigatório',
            alertDescription: 'É obrigatório informar um arquivo.',
            isOnlyConfirm: true,
          },
        });
      }
    } else {
      this.dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Data de Emissão',
          alertDescription:
            'Informe uma data de emissão menor ou igual à data atual.O documento não pode ser de data futura.',
          isOnlyConfirm: true,
        },
      });
    }
  }

  onVoltarParaLista(): void {
    this.isUpload = false;
  }

  saveAgent(): void {
    this.isProcessingAgent = true;

    const isRemoving = this.agent && this.agentUID === '';
    const isAdding = !this.agent && this.agentUID;

    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: isRemoving ? 'Remoção de Assessor de Crédito' : 'Atribuição de Assessor de Crédito',
          alertDescription: `Deseja realmente ${ isRemoving ? 'remover' : isAdding ? 'adicionar' : 'alterar'
            } o assessor de crédito deste usuário?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (isRemoving) {
            this.ums
              .removeAgentFromUser(this.customer.uid)
              .then(() => {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Sucesso',
                    alertDescription: `O assessor de crédito foi removido com sucesso`,
                    isOnlyConfirm: true,
                  },
                });
                this.isProcessingAgent = false;
              })
              .catch((err) => {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao remover assessor de crédito',
                    alertDescription: `Ocorreu um erro ao remover o assessor de crédito`,
                    isOnlyConfirm: true,
                  },
                });
                this.isProcessingAgent = false;
              });
          } else if (isAdding) {
            this.ums
              .insertAgentOfUser(this.customer.uid, this.agentUID)
              .then(() => {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Sucesso',
                    alertDescription: `O assessor de crédito foi salvo com sucesso`,
                    isOnlyConfirm: true,
                  },
                });
                this.isProcessingAgent = false;
              })
              .catch((err) => {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao salvar assessor de crédito',
                    alertDescription:
                      err === 'invalid-agent'
                        ? `Assessor não encontrado ou inválido`
                        : `Ocorreu um erro ao salvar o assessor de crédito`,
                    isOnlyConfirm: true,
                  },
                });
                this.isProcessingAgent = false;
              });
          } else {
            this.ums
              .changeAgentOfUser(this.customer.uid, this.agentUID)
              .then((info) => {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: /* (info && !info.message) ? 'Atenção!' : */ 'Sucesso',
                    alertDescription: /* (info && !info.message) ? `O assessor anterior foi removido, mas o novo não foi vinculado ao usuário. Por favor, processe novamente.` : */ `O assessor de crédito foi alterado com sucesso`,
                    isOnlyConfirm: true,
                  },
                });
                this.isProcessingAgent = false;
              })
              .catch((err) => {
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao alterar assessor de crédito',
                    alertDescription:
                      err === 'invalid-agent'
                        ? `Assessor não encontrado ou inválido`
                        : `Ocorreu um erro ao alterar o assessor de crédito`,
                    isOnlyConfirm: true,
                  },
                });
                this.isProcessingAgent = false;
              });
          }
        } else {
          this.isProcessingAgent = false;
        }
      });
  }

  addRecipient(name: string, event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add lead
    if ((value || '').trim()) {
      const valid = !!value.toLowerCase().match(UtilHandler.emailRegex);
      const isCopyTo = !!_.find(this.copyTo, ['email', value.trim().toLowerCase()]);
      const isRecipients = !!_.find(this.recipients, ['email', value.trim().toLowerCase()]);

      if (!isCopyTo && !isRecipients) {
        this[name].push({
          email: value.trim().toLowerCase(),
          valid,
          color: valid ? '' : 'warn',
        });
      }

      if (name === 'isCopyTo') {
        this.hasErrorCopyTo = this.hasInvalidRecipients(name);
        this.hasErrorCopyTo =
          this[name].length === 0 || this[name].length > this.recipientsLimit || this.hasErrorCopyTo;
      } else {
        this.hasErrorRecipients = this.hasInvalidRecipients(name);
        this.hasErrorRecipients =
          this[name].length === 0 || this[name].length > this.recipientsLimit || this.hasErrorRecipients;
      }
    }
    if (input) {
      input.value = null;
    }
  }

  removeRecipient(name: string, recipient: Recipient): void {
    const index = this[name].indexOf(recipient);

    if (index >= 0) {
      this[name].splice(index, 1);
      if (name === 'isCopyTo') {
        this.hasErrorCopyTo = this.hasInvalidRecipients(name);
        this.hasErrorCopyTo =
          this[name].length === 0 || this[name].length > this.recipientsLimit || this.hasErrorCopyTo;
      } else {
        this.hasErrorRecipients = this.hasInvalidRecipients(name);
        this.hasErrorRecipients =
          this[name].length === 0 || this[name].length > this.recipientsLimit || this.hasErrorRecipients;
      }
    }
  }

  hasInvalidRecipients(name = ''): boolean {
    if (name !== '') {
      return _.find(this[name], ['valid', false]) !== undefined;
    } else {
      return (
        _.find(this.recipients, ['valid', false]) !== undefined || _.find(this.copyTo, ['valid', false]) !== undefined
      );
    }
  }

  getApprovedDocuments(): DocumentMongoDB[] {
    return _.filter(this.documents, (d) => d.situation === 'Aprovado');
  }

  isUnableToSend(): boolean {
    return !this.subject || !this.message || this.recipients.length === 0 || this.hasInvalidRecipients();
  }

  sendMessage(): void {
    this.isProcessing = true;
    if (!this.isUnableToSend()) {
      const confirmSubscription = this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Confirmação',
            alertDescription: `Deseja mesmo enviar esta mensagem?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            const approvedDocs = this.getApprovedDocuments();
            const pathsToDocs = _.map(_.filter(this.selectedDocuments, ['selected', true]), (doc) => {
              let path = '';
              if (doc.year) {
                path = _.find(approvedDocs[doc.index]?.fileInfo, (arq) => arq.financialYear === doc.year)?.path;
              } else {
                path = approvedDocs[doc.index]?.fileInfo[0]?.path;
              }

              return {
                name: doc.name,
                path,
              };
            });

            const docsList =
              '<p>Acesso aos documentos:</p><ul>' +
              pathsToDocs.map((p) => `<li><a href="${ p.path }" target="_blank">${ p.name }</a></li>`).join('') +
              '</ul>';

            const formattedMessage =
              '<div><p>' + this.message.replace(/[\n]/g, '<br />') + '<br /><br />' + '</p>' + docsList + '</div>';

            this.http
              .post(
                `${ environment.functionsUrl }/email/send-documents`,
                {
                  subject: this.subject,
                  message: formattedMessage,
                  recipients: [...this.recipients.map((l) => l.email), this.loggedUser.email],
                  copyTo: this.copyTo.map((l) => l.email) ?? null,
                  replyTo: this.loggedUser.email,
                },
                {
                  responseType: 'text',
                  headers: this.authService.getHeader(),
                }
              )
              .toPromise()
              .then((res) => {
                console.log('Mensagem enviada');
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Mensagem enviada',
                    alertDescription: `A mensagem foi enviada com sucesso!`,
                    isOnlyConfirm: true,
                  },
                });

                this.recipients = [];
                this.copyTo = [];
                this.isProcessing = false;
              })
              .catch((err) => {
                console.error('Erro ao enviar documentos por e-mail', err);
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao enviar mensagem',
                    alertDescription: `Ocorreu um erro ao enviar a mensagem. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });

                this.isProcessing = false;
              });
          }

          if (confirmSubscription) {
            confirmSubscription.unsubscribe();
          }
        });
    }
  }

  hasGeneralData(): boolean {
    return !!(
      this.bigdatacorpData &&
      (this.bigdatacorpData.Lawsuits ||
        this.bigdatacorpData.Relationships ||
        this.bigdatacorpData.OnlineCertificates ||
        this.bigdatacorpData.OwnersLawsuits ||
        this.bigdatacorpData.PartnersData)
    );
  }

  hasBoaVistaData(): boolean {
    return !!this.bigdatacorpData?.BoaVista;
  }

  getGeneralDataFromIntegration(): void {
    console.log('General');

    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja mesmo executar esta integração?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isProcessingBDC = true;

          const user = {
            cnpj: this.customer.cnpj,
            uid: this.customer.uid,
          }
          this.ums
            .getGeneralDataFromIntegration(user, this.loggedUser.uid)
            .then(() => {
              console.log('Finish General');
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Integração solicitada',
                  alertDescription: `Os dados foram solicitados com sucesso! Você será notificado por e-mail quando a consulta for concluída.`,
                  isOnlyConfirm: true,
                },
              });
              this.isProcessingBDC = false;
            })
            .catch((err) => {
              console.error('Error General', err);
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao integrar',
                  alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
                  isOnlyConfirm: true,
                },
              });
              this.isProcessingBDC = false;
            });
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  cancelAgentNote(): void {
    this.isAgentNoteCreateMode = false;
    this.clearNewAgentNote();
  }

  clearNewAgentNote(): void {
    this.newAgentNote.message = '';
  }

  createAgentNote(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Salvar registro',
          alertDescription: 'Deseja realmente salvar esse registro de acompanhamento?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const note: AgentNote = {
            ...this.newAgentNote,
            type: 'ADMIN',
            agent: this.agent.uid,
            createdBy: {
              name: this.loggedUser.name,
              email: this.loggedUser.email,
              uid: this.loggedUser.uid,
            },
          };

          this.notesService
            .createAgentNote(this.customerUID, note, {
              companyName: this.customer.companyName,
            })
            .then(() => {
              this.isAgentNoteCreateMode = false;
              this.clearNewAgentNote();
              // this.agentNotes = [{ ...note, createdAt: firestore.Timestamp.now() }, ...this.agentNotes];
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Registro salvo',
                  alertDescription: 'O registro de acompanhamento foi salvo com sucesso.',
                  isOnlyConfirm: true,
                },
              });
            })
            .catch((err) => {
              console.error('Error creating note', err);

              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao salvar',
                  alertDescription: 'Não foi possível salvar o registro. Tente novamente mais tarde.',
                  isOnlyConfirm: true,
                },
              });
            });
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  viewMoreAgentNotes(): void {
    this.dialog.open(AgentNotesComponent, {
      data: {
        agent: this.agent,
        customer: this.customerUID,
        customerData: this.customer,
        admin: this.loggedUser,
        notificationData: {
          companyName: this.customer.companyName,
        },
      },
    });
  }

  runAutomaticIntegration(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja mesmo executar esta integração?`,
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.isProcessingBDC = true;

          try {
            await this.bigdatacorpService.runAutomaticIntegration(this.customer.uid);
            console.log('BDC - Finished Automatic Integration');
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Integração realizada',
                alertDescription: `A integração foi realizada com sucesso`,
                isOnlyConfirm: true,
              },
            });
            this.isProcessingBDC = false;
          } catch (err) {
            console.error('BDC - Error while running automatic integration', err);
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao executar a integração',
                alertDescription: `Ocorreu um erro ao executar a integração. Por favor, tente novamente mais tarde.`,
                isOnlyConfirm: true,
              },
            });
            this.isProcessingBDC = false;
          }
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  hasBasicData(): boolean {
    return !!this.bigdatacorpData?.BasicData;
  }

  hasOnlineQuery(): boolean {
    return !!this.bigdatacorpData?.OnlineQuery;
  }

  parseCompanyAge(): void {
    const age = this.editFormGroup.get('idadeEmpresaCtrl').value;
    if (age || age === 0) {
      let ageStr;

      if (age < 1) {
        ageStr = this.optionsTempoAtividade[0];
      } else if (age === 1) {
        ageStr = this.optionsTempoAtividade[1];
      } else if (age < 5) {
        ageStr = this.optionsTempoAtividade[2];
      } else if (age < 10) {
        ageStr = this.optionsTempoAtividade[3];
      } else {
        ageStr = this.optionsTempoAtividade[4];
      }
      this.editFormGroup.get('tempoAtividadeCtrl').setValue(ageStr);
    }
  }

  getDocumentInfo(doc: DocumentMongoDB): void {
    this.documentSelected = doc;
    this.selectedDocument = [];
    if (doc?.financialYear?.id === 'sim') {
      doc?.financialYears
        .slice()
        .reverse()
        .forEach((year) => {
          let documents = [];
          if (doc?.fileInfo) {
            documents = doc?.fileInfo.filter((item) => item.financialYear === year);
          }
          this.selectedDocument.push({
            year: year,
            status:
              documents.length > 0
                ? (doc?.minimumDocuments || 1) > documents.length
                  ? 'Enviado Parcialmente'
                  : 'Enviado'
                : 'Pendente',
            documents: documents,
          });
        });
    } else {
      this.selectedDocument.push({
        documents: doc?.fileInfo?.map((file) => file) || [],
      });
    }
  }

  onChangeGuarantees(event): void {
    // if has type guarantee
    if (event.value.length && event.value.find((g) => g.id === 'boleto')) {
      this.hasTypeOfAnticipation = true;
    } else {
      this.hasTypeOfAnticipation = false;
      this.hasTypeOfAnticipationCard = false;
      this.hasTypeOfInvoices = false;
      this.editFormGroup.get('typeOfAnticipationCtrl').clearValidators();
      this.editFormGroup.get('typeOfAnticipationCtrl').setValue(null);
      this.editFormGroup.get('creditCardCtrl').clearValidators();
      this.editFormGroup.get('creditCardCtrl').setValue(null);
      this.editFormGroup.get('typeOfInvoicesCtrl').clearValidators();
      this.editFormGroup.get('typeOfInvoicesCtrl').setValue(null);
      this.editFormGroup.updateValueAndValidity();
    }

    // if has only aval
    if (event.value?.length === 1 && event.value[0].id === 'avalista') {
      this.hasOnlyAvalGuarantee = true;
      this.editFormGroup.get('valorTotalStrCtrl').setValue(this.customer?.guaranteesValue ?? null);
    } else {
      this.hasOnlyAvalGuarantee = false;
      this.editFormGroup.get('valorTotalStrCtrl').setValue('');
    }
  }

  getYearDocuments(year: number): void {
    this.yearDocuments = this.selectedDocument.filter((file) => file.year === year);
    this.yearDocuments = this?.yearDocuments[0];
  }

  onSendNewFile(item: DocumentMongoDB, year = '') {
    const confirmUpload = this.dialog
      .open(UploadDialogComponent, {
        maxWidth: '600px',
        data: {
          item: item,
          exerciseYear: year,
          customer: this.customer,
          dataUserDocuments: this.documents,
          hasEmissionDate: false,
        },
      })
      .afterClosed()
      .subscribe((doc) => {
        console.log('Upload Dialog closed.');
        let document = this.documents.find((d) => d.typeId === doc.typeId);
        document = doc;
      });
    this.uploadYearDocument = year === '' ? false : true;
    /* this.isUpload = true; */
    this.fileInfoFormGroup = new FormGroup({
      dataEmissaoCtrl: new FormControl('', []),
      anoExercicioCtrl: new FormControl(year, []),
      caminhoArquivoCtrl: new FormControl('', []),
    });
  }

  verifyUploadStatus(item: DocumentMongoDB, year = '') {
    let yearDocumentsUploaded = [];
    if (year !== '') {
      if ((item?.fileInfo !== undefined || item?.fileInfo !== null) && item.fileInfo.length > 0) {
        yearDocumentsUploaded = item?.fileInfo?.filter((a) => a.financialYear === year);
      }
    }
    if (item.qtyFinancialYear > 0) {
      if (
        item.situation === 'Reprovado' ||
        yearDocumentsUploaded.length < (item?.maximumDocuments || item?.minimumDocuments || 1) ||
        item.qtyExpectedFiles / item.qtyFinancialYear > yearDocumentsUploaded.length
      ) {
        this.canUpload = true;
      } else {
        this.canUpload = false;
      }
    } else {
      if (
        item.situation === 'Reprovado' ||
        item.qtySubmittedFiles < (item?.maximumDocuments || item?.minimumDocuments || 1) ||
        item.qtyExpectedFiles > item.qtySubmittedFiles
      ) {
        this.canUpload = true;
      } else {
        this.canUpload = false;
      }
    }
  }

  onRemoveFileDocument(fileIndex = null, item: DocumentMongoDB, file: DocumentMongoDB['fileInfo'][0]): void {
    const doc = this.documents.find((d) => d.typeId === item.typeId);

    doc.fileInfo = doc.fileInfo.filter((f, i) => f.path !== file.path && fileIndex !== i);
    doc.qtySubmittedFiles = item.fileInfo.length ?? 0;
    if (doc.fileInfo.length === 0) {
      doc.situation = 'Pendente';
      doc.clicksignDocumentKey = null;
      doc.clicksignSignatureRequestKey = null;
    }
    doc.uid = this.customer.uid;
    this.userDocumentService.patchDocument(doc);
  }

  sendEmailVerification() {
    this.isSendingEmailVerification = true;
    this.ums
      .sendEmailVerification(this.customer.email, this.customer.displayName)
      .then((res) => {
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '800px',
            data: {
              alertTitle: 'Verificação enviada',
              alertDescription: 'Verificação de email enviada com sucesso.',
              isOnlyConfirm: true,
            },
          })
          .afterClosed()
          .subscribe(() => (this.isSendingEmailVerification = false));
      })
      .catch((err) => {
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '800px',
            data: {
              alertTitle: 'Verificação não enviada',
              alertDescription: 'Erro ao enviar verificação de email.',
              isOnlyConfirm: true,
            },
          })
          .afterClosed()
          .subscribe(() => (this.isSendingEmailVerification = false));
      });
  }

  compareOppStage(s1: FaseOportunidade, s2: FaseOportunidade): boolean {
    return s1.defaultprobability === s2.defaultprobability;
  }

  onChangeAntecipation(event): void {
    // if has antecipation of card

    if (event.value.length && event.value.find((c) => c.id === 'cartao')) {
      this.hasTypeOfAnticipationCard = true;
    } else {
      this.hasTypeOfAnticipationCard = false;
      this.editFormGroup.get('creditCardCtrl').clearValidators();
      this.editFormGroup.updateValueAndValidity();
      this.editFormGroup.get('creditCardCtrl').setValue(null);
    }

    // if has antecipation of Nota fiscal
    if (event.value.length && event.value.find((model: BasicStructure) => model.id === 'nota_fiscal')) {
      this.hasTypeOfInvoices = true;
    } else {
      this.hasTypeOfInvoices = false;
      this.editFormGroup.get('typeOfInvoicesCtrl').clearValidators();
      this.editFormGroup.updateValueAndValidity();
      this.editFormGroup.get('typeOfInvoicesCtrl').setValue(null);
    }

  }

  onChangetaxRegime(event): void {
    if (event.value.id === 'mei') {
      this.hasTaxRegimeMei = true;
    } else {
      this.hasTaxRegimeMei = false;
      this.editFormGroup.get('personalCellCtrl').clearValidators();
      this.editFormGroup.get('birthDateCtrl').clearValidators();
      this.editFormGroup.get('maritalStatusCtrl').clearValidators();
      this.editFormGroup.updateValueAndValidity();
      this.editFormGroup.get('personalCellCtrl').setValue(null);
      this.editFormGroup.get('birthDateCtrl').setValue(null);
      this.editFormGroup.get('maritalStatusCtrl').setValue(null);
    }
  }

  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }

  deleteCustomerFromAdmin(): void {
    const mainRole = 'customer' as UserRole;
    const uid = this.customer.uid;
    console.log('Deleted Customer From Admin', uid);
    const deletedBy = 'admin';
    // delete the user
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Excluir Conta',
          alertDescription: `A conta do usuário ${ this.customer.name } será excluida PERMANENTEMENTE e essa ação não poderá ser desfeita!`,
        },
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.dialog
            .open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Excluir Conta',
                alertDescription: 'Escreva o motivo da exclusão do usuário.',
                hasTextDescription: true,
                dataTextDescription: {
                  placeholder: 'Motivo',
                  errorMessage: 'Informe uma descrição',
                  dataTextCtrl: '',
                },
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.isProcessingExclused = true;
                const motivation = result;
                this.ums
                  .deleteUser(uid, mainRole, motivation, deletedBy)
                  .then(() => {
                    this.showMessage('Usuário Deletado', `O usuário foi deletado com sucesso!`).afterClosed();
                  })
                  .catch((err) => {
                    this.showMessage('Erro ao Deletar', 'Erro tentar deletar o usuário. Tente mais tarde.');
                    console.error('Erro ao Deletar o usuário: ', err);
                  })
                  .finally(() => {
                    // redirect to the query filter page
                    // admin/clientes/buscar
                    this.isProcessingExclused = false;
                    this.router.navigate(['admin/clientes/buscar']);
                  });
              }
            });
        }
      });
  }

  getDocumentStatus(item: DocumentMongoDB, year = ''): string {
    const files = year !== '' ? item?.fileInfo?.filter((f) => f.financialYear === year) : item.fileInfo;
    const minimunDocuments = (item?.minimumDocuments || 1) * (year === '' ? item.qtyFinancialYear : 1);
    return files.length > 0 ? (minimunDocuments > files.length ? 'Enviado Parcialmente' : 'Enviado') : 'Pendente';
  }

  startApprovalCommittee(): void {
    if (!this.editFormGroup.controls.approvedByCommitteeCtrl.value === true) {
      this.dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Deseja iniciar a aprovação do comite?',
          alertDescription: 'Primeiro será salvo e processado o cliente!',
        },
      })
        .afterClosed()
        .subscribe(async (result) => {
          if (result) {
            await this.onSaveForm()
            try {
              this.tabGroup.selectedIndex = 1;
              this.startCommittee = true
            } catch (error) {
              console.log('erro ao salvar o cliente')
            }
          }
        })
    }
  }

  onPrintTerm() {
    const term = document.getElementById('term').innerHTML;
    const win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><body>');
    win.document.write(term);
    win.document.write('</body></html>');
    win.print();
    win.close();
  }

  async goToChat(contact: Customer) {
    if (!!contact.attributedTo && this.loggedUser.accessLevel !== 'master' && contact.attributedTo !== this.loggedUser.uid) {
      this.ums.getUserByUid(contact.attributedTo).subscribe((data: User) => {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Não permitido',
            alertDescription: `Este cliente já está atribuído ao(à) colaborador(a) ${ data.displayName || data.name }.`,
            isOnlyConfirm: true,
          },
        });
      });

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    } else {
      this.router.navigate(['/admin/chat-sce/sem-atribuicao/cliente/' + contact.cnpj.replace(/\D/g, '')]);
    }
  }

  loadCustomerWithContacts(
    contact?: any
  ): Promise<ContactsCustomer | null> {

    return new Promise<ContactsCustomer | null>((resolve, reject) => {

      this.chatService.getCustomersByLastContact(
        100,
        ['cnpj'],
        [contact.cnpj],
        'contatos',
        this.loggedUser,
        this.selectedMenuitem
      ).subscribe((customers: ContactsCustomer[]) => {
        console.log('load customer -', customers?.length ?? null);

        if (contact && customers) {
          const matchingCustomer = customers.find(c => c.uid === contact.uid);

          if (matchingCustomer) {
            matchingCustomer.lastContact = contact.lastContact || null;
          }

          resolve(matchingCustomer);
        } else {
          resolve(null);
        }

        this.customers = customers;
        this.customers.sort((a, b) => (a?.lastContact < b?.lastContact ? 1 : -1));
      });
    });
  }

  determineStatus(doc: DocumentMongoDB): string {
    if (doc.hasNoAlterationRec === true) {
      return 'Não houve atualização';
    }
    if (doc.fileInfo.length > 0 && doc.fileInfOld.length > 0) {
      const lastOldDateUpload = doc.fileInfOld.map((f) => f.dateUpload).sort()[0];
      for (const file of doc.fileInfo) {
        if (doc.minimumDocuments === doc.fileInfo.length && file.dateUpload && lastOldDateUpload && new Date(file.dateUpload) > new Date(lastOldDateUpload)) {
          return 'Houve atualização';
        }
      }

    }
    return 'Aguardando';
  }


  getNegativeDataFromSerasa(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja mesmo fazer uma consulta no serasa?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isProcessingSerasa = true;
          this.ums
            .getNegativeDataFromSerasa(this.customer.uid, this.loggedUser.uid)
            .then(() => {
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Consulta no Serasa solicitada',
                  alertDescription: `Os dados foram solicitados com sucesso!`,
                  isOnlyConfirm: true,
                },
              });
            })
            .catch((err) => {
              console.error('Error Serasa', err);
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao integrar',
                  alertDescription: `Ocorreu um erro ao executar a consulta. Por favor, tente novamente mais tarde.`,
                  isOnlyConfirm: true,
                },
              });
            }).finally(() => {
              this.isProcessingSerasa = false;
            });
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  getIntegrationSCR(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja realizar a integração registrato SCR?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isProcessingScrBmp = true;
          this.scrBmpService
            .getIntegrationFromScrBmp(this.customer.uid)
            .then(() => {
              this.scrBmpService.getDataFromScrBmp(this.customer.uid).subscribe(data => {
                this.bmpMoneyPlusData = data;

                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Consulta registrato SCR',
                    alertDescription: `Integração realizada com sucesso. Acesse a aba 'Dados adicionais' para consultar os dados integrados!`,
                    isOnlyConfirm: true,
                  },
                });
              });
            })
            .catch((err) => {
              console.error('Error integração registrato SCR', err);

              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao integrar',
                  alertDescription: `Ocorreu um erro ao executar a consulta. Por favor, tente novamente mais tarde. Erro: ${ err.message ?? "" }`,
                  isOnlyConfirm: true,
                },
              });
            }).finally(() => {
              this.isProcessingScrBmp = false;
            });
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  onSerasaDataChange(newData: any) {
    this.serasaData = newData;
  }

  toggleDocumentApproval() {

    this.customer.documentApproval = !this.customer.documentApproval;

    // Obtém o momento atual
    const currentTime = firestore.Timestamp.now();

    this.customer.documentApprovalDate = currentTime;

    this.ums.updateCustomer(this.customer)
      .then(() => {
        console.log('Cliente atualizado com sucesso.');
      })
      .catch(error => {
        console.error('Erro ao atualizar cliente:', error);
      });
  }

  hubspotLink() {

    if (this.customer.hubspot?.companyId) {

      const url = `https://app.hubspot.com/contacts/8256969/record/0-2/${ this.customer.hubspot.companyId }`;
      window.open(url, '_blank');
    } else {
      console.error('CompanyId não está definido.');
    }
  }
}
