<div class="dialog-container">
  <h3 mat-dialog-title>Dados do cliente</h3>
  <div mat-dialog-content>
    <form [formGroup]="customerScreeningForm" >
      <div class="input-container">
        <label>CNPJ</label>
        <input [textMask]="{ mask: MASKS.cnpj.textMask }" formControlName="cnpj" placeholder="CNPJ" />
        <div *ngIf="customerScreeningForm.get('cnpj').touched && customerScreeningForm.get('cnpj').hasError('required')" >
          <p>Campo obrigatório.</p>
        </div>
        <div *ngIf="customerScreeningForm.get('cnpj').touched && customerScreeningForm.get('cnpj').invalid" >
          <div *ngIf="customerScreeningForm.get('cnpj').hasError('cnpj')">Digite um cnpj válido.</div>
        </div>
      </div>
      <div class="input-container">
        <label>Razão social</label>
        <input formControlName="companyName" placeholder="Razão social" />
        <div *ngIf="customerScreeningForm.get('companyName').touched && customerScreeningForm.get('companyName').hasError('required')" >
          <p>Campo obrigatório.</p>
        </div>
      </div>
      <div class="input-container">
        <label>Email</label>
        <input formControlName="email" placeholder="Email" />
        <div *ngIf="customerScreeningForm.get('email').touched && customerScreeningForm.get('email').hasError('required')" >
          <p>Campo obrigatório.</p>
        </div>
        <div *ngIf="customerScreeningForm.get('email').touched && customerScreeningForm.get('email').invalid" >
          <div *ngIf="customerScreeningForm.get('email').hasError('email')">Digite um email válido.</div>
        </div>
      </div>
      <div class="input-container">
        <label>Quanto busca de capital</label>
        <input formControlName="creditValue" placeholder="Quanto busca" [textMask]="{ mask: numberMask }" />
        <div *ngIf="customerScreeningForm.get('creditValue').touched && customerScreeningForm.get('creditValue').hasError('required')" >
          <p>Campo obrigatório.</p>
        </div>
      </div>
      <div class="input-container">
        <label>Dívida atual</label>
        <input formControlName="debt" placeholder="Dívida atual" [textMask]="{ mask: numberMask }" />
        <!-- <div *ngIf="customerScreeningForm.get('debt').touched && customerScreeningForm.get('debt').hasError('required')" >
          <p>Campo obrigatório.</p>
        </div> -->
      </div>
      <div class="input-container">
        <label>Valor total das garantias</label>
        <input formControlName="guaranteesValue" placeholder="Valor total das garantias" [textMask]="{ mask: numberMask }" />
        <!-- <div *ngIf="customerScreeningForm.get('guaranteesValue').touched && customerScreeningForm.get('guaranteesValue').hasError('required')" >
          <p>Campo obrigatório.</p>
        </div> -->
      </div>
      <div class="input-container">
        <label>Finalidade</label>
        <input formControlName="purpose" placeholder="Finalidade" />
        <div *ngIf="customerScreeningForm.get('purpose').touched && customerScreeningForm.get('purpose').hasError('required')" >
          <p>Campo obrigatório.</p>
        </div>
      </div>
      <div class="input-container">
        <label>Faturamento mensal</label>
        <input formControlName="monthlyRevenue" placeholder="Faturamento mensal" [textMask]="{ mask: numberMask }" />
        <div *ngIf="customerScreeningForm.get('monthlyRevenue').touched && customerScreeningForm.get('monthlyRevenue').hasError('required')" >
          <p>Campo obrigatório.</p>
        </div>
      </div>
      <div class="input-container">
        <label>CEP</label>
        <input formControlName="zipcode" placeholder="CEP" [textMask]="{ mask: MASKS.cep.textMask, guide: false }" />
        <div *ngIf="customerScreeningForm.get('zipcode').touched && customerScreeningForm.get('zipcode').hasError('required')" >
          <p>Campo obrigatório.</p>
        </div>
        <div *ngIf="customerScreeningForm.get('zipcode').touched && customerScreeningForm.get('zipcode').invalid" >
          <div *ngIf="customerScreeningForm.get('zipcode').hasError('zipcode')">Digite um cep válido.</div>
        </div>
      </div>
      <div class="btn-container">
        <button class="green-button" (click)="openInNewTab(customer.uid)">Abrir aba de documentação</button>
      </div>
    </form>
  </div>
</div>
