<div class="content">
  <section *ngIf="step === 'company'">
    <div class="section-description">
      <h2>Informações básicas</h2>
      <p>A Capital Empreendedor é a ponte entre você e instituições financeiras no Brasil inteiro! Com nosso cadastro
        único, compare ofertas de crédito em mais de 360 instituições financeiras em um só lugar!</p>
    </div>
    <div>
      <span class="step-label">ETAPA 1 de 3: Sobre a empresa</span>
      <h1>Sobre sua empresa</h1>
      <p>Para começar sua análise, precisamos das seguintes informações sobre sua empresa:</p>
    </div>
    <form name="companyForm" [formGroup]="companyDataFormGroup">
      <div class="form row">
        <div class="field col-12 col-lg-6">
          <mat-icon fontSet="fas" fontIcon="fa-building" class="colored-icon"></mat-icon>

          <div class="" style="width: 100%">
            <mat-form-field color="primary">
              <mat-label>CNPJ</mat-label>
              <input matInput type="text" name="CNPJ" placeholder="CNPJ" formControlName="cnpjCtrl"
                [textMask]="{ mask: MASKS.cnpj.textMask }" required [disabled]="runReceitaWs" />
              <mat-error *ngIf="companyDataFormGroup.get('cnpjCtrl')?.hasError('required')">
                O CNPJ é obrigatório
              </mat-error>
              <mat-error *ngIf="companyDataFormGroup.get('cnpjCtrl')?.hasError('cnpj')"> CNPJ inválido </mat-error>
            </mat-form-field>
            <mat-spinner style="align-items: center;" *ngIf="runReceitaWs" color="primary" diameter="20"></mat-spinner>
          </div>

        </div>

        <div class="field col-12 col-lg-6">
          <mat-icon fontSet="fas" fontIcon="fa-building" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Razão Social</mat-label>
            <input matInput type="text" matTooltip="Nome da sua empresa." name="companyName" placeholder="Razão Social"
              formControlName="companyNameCtrl" required />
            <mat-error *ngIf="companyDataFormGroup.get('companyNameCtrl')?.hasError('required')">
              A Razão Social é obrigatória
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field col-12 col-lg-6">
          <mat-icon fontSet="fas" fontIcon="fa-wallet" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Faturamento Anual</mat-label>
            <input matInput placeholder="Faturamento Anual" name="revenue" matTooltip="Soma do faturamento dos últimos 12 meses “não colocar os centavos”
              Exemplo - 120.000,00 = 120.000." required formControlName="revenueCtrl" [textMask]="{ mask: numberMask }"
              (change)="revenueValidation()" />
            <mat-hint>Desconsidere os centavos</mat-hint>
            <mat-error *ngIf="companyDataFormGroup.get('revenueCtrl')?.hasError('required')">O faturamento é
              obrigatório</mat-error>
            <mat-error *ngIf="companyDataFormGroup.get('revenueCtrl')?.hasError('min')">
              Infelizmente no momento só conseguimos atender empresas com faturamento maior que
              {{ config?.minRevenueStr }}.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field d-flex align-items-center col-12 col-lg-6">
          <mat-icon fontSet="fas" fontIcon="fa-envelope" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>E-mail</mat-label>
            <input matInput placeholder="E-mail" name="email" required formControlName="emailCtrl" oncopy="return false"
              oncut="return false" />
            <mat-error *ngIf="companyDataFormGroup.get('emailCtrl')?.hasError('required')">O e-mail é
              obrigatório</mat-error>
            <mat-error *ngIf="companyDataFormGroup.get('emailCtrl')?.hasError('email')">E-mail inválido</mat-error>
          </mat-form-field>
        </div>

        <div class="field d-flex align-items-center col-12 col-lg-6">
          <mat-icon fontSet="fas" fontIcon="fa-envelope" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Confirme seu e-mail</mat-label>
            <input matInput placeholder="Confirme seu e-mail" name="email-match" required
              formControlName="emailMatchCtrl" onpaste="return false" />
            <mat-error *ngIf="companyDataFormGroup.get('emailMatchCtrl')?.hasError('required')">Confirmação
              obrigatória</mat-error>
            <mat-error *ngIf="companyDataFormGroup.get('emailMatchCtrl')?.hasError('email')">E-mail inválido</mat-error>
            <mat-error *ngIf="companyDataFormGroup.get('emailMatchCtrl')?.hasError('mismatch')">Os e-mails devem ser
              iguais</mat-error>
          </mat-form-field>
        </div>

        <div class="field col-12 col-lg-6">
          <mat-icon fontSet="fab" fontIcon="fa-whatsapp" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>WhatsApp</mat-label>
            <input matInput placeholder="WhatsApp" name="whatsapp" required formControlName="whatsappCtrl"
              [textMask]="{ mask: formatPhone, guide: false }" />
            <mat-error *ngIf="companyDataFormGroup.get('whatsappCtrl')?.hasError('required')">O WhatsApp é
              obrigatório</mat-error>
            <mat-error *ngIf="
                companyDataFormGroup.get('whatsappCtrl')?.hasError('telefone') ||
                companyDataFormGroup.get('whatsappCtrl')?.hasError('minlength')
              ">WhatsApp inválido</mat-error>
          </mat-form-field>
        </div>

        <div lass="field col-12 col-lg-6">
          <div class="flex-fill row">
            <div class="field col-12 col-lg-7">
              <mat-icon fontSet="fas" fontIcon="fa-hand-holding-usd" class="colored-icon"></mat-icon>
              <div class="flex-fill">
                <app-form-field-select fieldName="Garantias disponíveis" controlName="guaranteesCtrl"
                  (selectionChange)="onChangeGuarantees($event)" [formGroup]="companyDataFormGroup" matTooltip="
                    -Recebíveis: É uma linha de crédito para receber antecipadamente suas vendas realizadas a prazo através
                    de cartão de crédito, boleto e/ou nota fiscal.

                    -Imóvel operacional: Sede da empresa, galpão, fábrica, salas comerciais e etc.

                    -Imóvel Não-Operacional: Residencial.

                    -Aval: Sem garantia real, nessa linha o sócio é o garantidor." [options]="config?.guarantees"
                  [compareWith]="true" [multiple]="true" [required]="true"></app-form-field-select>
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <app-form-field-select *ngIf="hasTypeOfAnticipation" fieldName="Tipo de Recebíveis" matTooltip="
                Cartão: Linha de crédito para vendas realizadas a prazo (cartão de crédito) através de maquininhas de cartão.
                Boleto: Linha de crédito para vendas realizadas a prazo por boleto.
                Notas fiscais: Linha de crédito para vendas realizadas a prazo por nota fiscal de venda."
                controlName="typeOfAnticipationCtrl" (selectionChange)="onChangeAntecipation($event)"
                [formGroup]="companyDataFormGroup" [options]="selectTypeOfAnticipationOperator" [multiple]="true"
                [required]="hasTypeOfAnticipation"></app-form-field-select>
            </div>
          </div>
        </div>

        <div class="field col-12 col-lg-12" *ngIf="hasTypeOfAnticipationCard || hasTypeOfInvoices">
          <div class="flex-fill row">
            <div class="col-12 col-lg-6" *ngIf="hasTypeOfAnticipationCard">
              <app-form-field-select fieldName="Maquininha de cartão" controlName="creditCardCtrl"
                [formGroup]="companyDataFormGroup" [options]="config?.cardMachines" [multiple]="true"
                [required]="hasTypeOfAnticipationCard"></app-form-field-select>
            </div>
            <div class="col-12 col-lg-6" *ngIf="hasTypeOfInvoices">
              <app-form-field-select fieldName="Tipo de Nota Fiscal" controlName="typeOfInvoicesCtrl"
                [formGroup]="companyDataFormGroup" [options]="selectTypeOfInvoices" [multiple]="true"
                [required]="hasTypeOfInvoices"></app-form-field-select>
            </div>
          </div>
        </div>




        <div *ngIf="privacyPolicyUrl" class="col-12 col-lg-9 mt-3 mb-4 d-flex">
          <mat-checkbox color="primary" class="mr-3 my-1 checkbox" formControlName="agreedPrivacyPolicyCtrl"
            required></mat-checkbox>
          <span style="flex: 1">Eu li e concordo com a
            <a class="link" [href]="privacyPolicyUrl" target="_blank">política de privacidade</a>.</span>
        </div>

        <div class="field col-12 order-12">
          <button mat-raised-button color="primary" class="action-button" type="button"
            [class.disabled]="companyDataFormGroup.invalid" [disabled]="companyDataFormGroup.invalid"
            (click)="checkCompanyFields()" *ngIf="!isProcessing">
            <img height="1" width="1" style="display: none" alt=""
              src="https://px.ads.linkedin.com/collect/?pid=695505&conversionId=4039849&fmt=gif" />
            Próximo
          </button>
          <div class="text-center">
            <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
          </div>
        </div>
      </div>
    </form>
  </section>

  <section *ngIf="step === 'personal'">
    <div class="section-description">
      <h2>Crédito para sua empresa</h2>
      <p>A Capital Empreendedor é a ponte entre você e instituições financeiras no Brasil inteiro! Com nosso cadastro
        único, compare ofertas de crédito em mais de 360 instituições financeiras em um só lugar!</p>
    </div>
    <div>
      <span class="step-label">ETAPA 2 de 3: Sobre você</span>
      <h1>Sobre você</h1>
      <p>Para começar sua análise, precisamos das seguintes informações sobre você:</p>
    </div>

    <form name="personalForm" [formGroup]="personalDataFormGroup">
      <div class="form row">
        <div class="field col-12 col-lg-10">
          <mat-icon fontSet="fas" fontIcon="fa-user" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Nome</mat-label>
            <input matInput type="text" placeholder="Nome" name="name" required formControlName="nameCtrl" />
            <mat-error *ngIf="personalDataFormGroup.get('nameCtrl')?.hasError('required')">O nome e sobrenome é
              obrigatório</mat-error>
            <mat-error *ngIf="
                personalDataFormGroup.get('nameCtrl')?.value !== '' &&
                personalDataFormGroup.get('nameCtrl')?.hasError('invalidlastname')
              ">Esse campo deve conter nome e sobrenome</mat-error>
          </mat-form-field>
        </div>

        <div class="field col-12 col-lg-5">
          <mat-icon fontSet="fas" fontIcon="fa-id-card" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>CPF</mat-label>
            <input matInput placeholder="CPF" name="cpf" required formControlName="cpfCtrl"
              [textMask]="{ mask: MASKS.cpf.textMask }" />
            <mat-error *ngIf="personalDataFormGroup.get('cpfCtrl')?.hasError('required')">O CPF é
              obrigatório</mat-error>
            <mat-error *ngIf="personalDataFormGroup.get('cpfCtrl')?.hasError('cpf')">CPF inválido</mat-error>
          </mat-form-field>
        </div>



        <div class="field col-12 col-lg-5">
          <mat-icon fontSet="fas" fontIcon="fa-key" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Senha</mat-label>
            <input matInput type="password" placeholder="Senha" formControlName="passwordCtrl" required />
            <mat-error *ngIf="personalDataFormGroup.controls['passwordCtrl'].hasError('required')">
              Informe uma senha de acesso.
            </mat-error>
            <mat-error *ngIf="personalDataFormGroup.controls['passwordCtrl'].hasError('minlength')">
              A senha deve ter 6 caracteres ou mais.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field col-12 col-lg-5">
          <mat-icon fontSet="fas" fontIcon="fa-key" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Confirmar senha</mat-label>
            <input matInput type="password" placeholder="Confirmar senha" formControlName="confirmCtrl" required />
            <mat-error *ngIf="personalDataFormGroup.controls['confirmCtrl'].hasError('required')">
              Confirme a senha criada.
            </mat-error>
            <mat-error *ngIf="personalDataFormGroup.controls['confirmCtrl'].hasError('mismatch')">
              Confirmação de senha não confere.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-9 mt-3 mb-4">
          <mat-checkbox color="primary" class="ml-2 checkbox" formControlName="permissionCtrl" required>
            <span class="pre-wrap">Confirmo que tenho poderes de assinar pela empresa tomadora de crédito.</span>
          </mat-checkbox>

          <mat-checkbox color="primary" class="ml-2 mt-3 checkbox" formControlName="whatsAppContactCtrl"
            name="whatsAppContact">
            <span class="pre-wrap"> Aceito receber contato sobre as propostas por whatsapp </span>
          </mat-checkbox>
        </div>


        <div class="field col-12">
          <button mat-stroked-button type="button" class="action-button text-black autosized"
            (click)="previousStep(step)" *ngIf="!isProcessing">
            Voltar
          </button>
          <button mat-raised-button color="primary" class="action-button" type="button"
            [class.disabled]="!areAllFormsValid()" [disabled]="!areAllFormsValid()" (click)="createAccount()"
            *ngIf="!isProcessing">
            Próximo
          </button>
          <div class="text-center">
            <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>