<div class="container-fluid" style="flex: 1;">
  <mat-card>
    <mat-card-content>
      <mat-card-title>Consulta - BigDataCorp</mat-card-title>
      <div>
        <p class="mt-4 mb-2">Especifique o CNPJ para busca:</p>
        <div class="d-flex">
          <div style="flex: 1;">
            <mat-form-field class="w-100" color="accent">
              <mat-label>CNPJ</mat-label>
              <input matInput [formControl]="cnpj" type="text" placeholder="CNPJ" name="CNPJ"
                [textMask]="{mask: MASKS.cnpj.textMask}" required>
            </mat-form-field>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Datasets
                </mat-panel-title>
                <mat-panel-description>
                  Selecione os datasets a serem consultados
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="row my-3">
                <div class="col-12 col-sm-6 col-xl-3 offset-xl-3 text-center">
                  <button mat-raised-button type="button" class="w-100 mt-1" color="accent" (click)="checkAll()">
                    <mat-icon>done</mat-icon> Marcar todos
                  </button>
                </div>
                <div class="col-12 col-sm-6 col-xl-3 text-center">
                  <button mat-raised-button type="button" class="w-100 mt-1" color="accent" (click)="uncheckAll()">
                    <mat-icon>clear</mat-icon> Limpar todos
                  </button>
                </div>
              </div>

              <div class="row">
                <div *ngFor="let dataset of datasets" class="col-12 col-md-6 col-xl-3">
                  <mat-checkbox class="text-break" [checked]="dataset.checked" (click)="checkOne(dataset)">
                    <span class="checkbox-wrap">{{ dataset.name }}</span>
                  </mat-checkbox>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
          <div class="ml-4">
            <button mat-raised-button type="button" class="w-100" color="primary" (click)="search()"
              [disabled]="cnpj.invalid || !hasSelectedDatasets() || isLoading">
              <span *ngIf="!isLoading">Buscar dados</span>
              <mat-spinner class="mx-auto my-2" color="accent" *ngIf="isLoading" diameter="20"></mat-spinner>
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="mt-3" [class.d-none]="!result || !Object.keys(result).length">
    <mat-card-content>
      <div class="text-center">
        <button mat-raised-button color="accent" (click)="downloadResult()" class="mt-3">
          <mat-icon>save_alt</mat-icon>
          <span class="ml-2">Baixar dados completos (JSON)</span>
        </button>
      </div>
      <div>
        <ul style="padding: 0">
          <app-tree-parent *ngFor="let nk of nodeKeys" [label]="nk" [node]="result[nk]"></app-tree-parent>

          <div style="margin-top: 0.3em" *ngIf="nodeKeys.length && leafKeys.length"></div>

          <app-tree-child *ngFor="let lk of leafKeys" [label]="lk" [data]="result[lk]"></app-tree-child>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>
</div>
