import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/legacy-auth.service';
import { LogService } from 'src/app/components/logger/log.service';

@Injectable({
  providedIn: 'root',
})
export class RDStationService {
  constructor(private authService: AuthService, private http: HttpClient, private logger: LogService) {}

  async convertLead(event: string, lead: any): Promise<object> {
    if (environment.envName === 'PROD') {
      try {
        console.log('Converting lead');

        return await this.http
          .post(
            `${environment.functionsUrl}/rdstation/convert-lead`,
            { lead, event },
            { headers: this.authService.getHeader() }
          )
          .toPromise();
      } catch (error) {
        this.logger.fatal(`RD Station Service - Error converting lead as opportunity - ${lead.email}`, error);
        throw error;
      }
    } else {
      console.warn('Conversion not reported due the environment in use');
    }
  }

  async markOpportunity(leadEmail: string): Promise<object> {
    if (environment.envName === 'PROD') {
      try {
        console.log('Marking lead as opportunity');

        const res = await this.http
          .post(
            `${environment.functionsUrl}/rdstation/mark-opportunity`,
            { email: leadEmail },
            { headers: this.authService.getHeader() }
          )
          .toPromise();
        return res;
      } catch (error) {
        console.error(`RD Station Service - Error marking lead as opportunity`, error);
        this.logger.fatal(`RD Station Service - Error marking lead as opportunity - ${leadEmail}`, error);
        throw error;
      }
    } else {
      console.warn('Opportunity not marked due the environment in use');
    }
  }
}
