import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Customer } from 'functions/src/models/Customer';
import _moment from 'moment';
import { MASKS } from 'ng-brazil';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { UserManagementService } from '../services/user-management.service';
_moment.locale('pt-BR');
export const MY_FORMATS = {
  parse: {
    dateInput: 'LLL',
  },
  display: {
    dateInput: 'LLL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LLL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserListComponent implements OnInit /*, OnDestroy , AfterViewInit */ {
  readonly MASKS = MASKS;
  customers: Customer[] = [];

  displayedColumns: string[] = [
    'cnpj',
    'companyName',
    // 'name',
    // 'cpf',
    'email',
    'lastInteraction',
    /* 'situation', */ 'terms',
    'actions',
  ];
  dataSource: MatTableDataSource<Customer>;
  dataSourceTable: any;


  // Control
  isLoadingResults = false;

  // Filters
  labels = {
    email: 'E-mail',
    name: 'Nome',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    companyName: 'Razão Social',
  };
  selectedFilter = 'companyName';
  currentFilterValue = '';
  filterValue = '';

  // paginator
  length = 0;
  pageSize = 0;
  pageSizeOptions: number[] = [25, 50, 100];
  currentPage = 0;

  // Sorting
  sortField = 'lastInteraction';
  sortDirection = 'desc';

  private matSortUserList: MatSort;
  private paginatorUserList: MatPaginator;

  @ViewChild('matSortUserList', { static: false }) set matSort(ms1: MatSort) {
    this.matSortUserList = ms1;
    this.dataSource.sort = this.matSortUserList;
  }
  @ViewChild('paginatorUserList', { static: false }) set matPaginator(mp1: MatPaginator) {
    this.paginatorUserList = mp1;
    this.dataSource.paginator = this.paginatorUserList;
  }


  constructor(private ums: UserManagementService, private dialog: MatDialog) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit(): void {
    // this.applyFilter();
  }

  applyFilter(resetPage = false): void {
    if (resetPage) {
      this.currentPage = 0;
    }

    this.currentFilterValue = this.filterValue;

    this.isLoadingResults = true;
    this.ums
      .searchCustomers({
        filterField: this.selectedFilter,
        filterValue: this.currentFilterValue,
        orderField: this.sortField,
        orderDirection: this.sortDirection,
        pageSize: this.pageSize,
        page: this.currentPage,
      })
      .then((response) => {
        // this.customers = response.results;
        // mudei o tipo do retorno da Promise em user-management.service.ts em searchCustomers (de CustomerResult para any)

        this.customers = response;
        this.length = response.length;
        // this.length = 0;

        this.dataSource = new MatTableDataSource(this.customers);
        this.dataSource.sort = this.matSortUserList;
        this.isLoadingResults = false;
      })
      .catch((err) => {
        console.error('Error getting customers.', err);
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '700px',
            data: {
              alertTitle: 'Erro ao buscar clientes',
              alertDescription: `Ocorreu um erro ao buscar os clientes. Por favor, tente novamente mais tarde.`,
              isOnlyConfirm: true,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.isLoadingResults = false;
          });
      });
  }

  setPageSizeOptions(setPageSizeOptionsInput: string): void {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
    }
  }

  paginatorEvent(event: PageEvent): void {
    if (event.pageSize !== this.pageSize) {
      this.pageSize = event.pageSize;
      this.pageSize = 0;
    } else {
      this.currentPage = event.pageIndex;
    }
    this.applyFilter();
  }

  sortData(event: Sort): void {
    this.sortDirection = event.direction || 'asc';
    this.sortField = event.active;
    this.currentPage = 0;
    this.applyFilter(true);
  }

  clearFilter(): void {
    this.currentFilterValue = '';
    this.filterValue = '';
    this.pageSize = 0;
    this.applyFilter(true);
  }

  clearFilterValue(): void {
    this.filterValue = '';
  }
}
