<div class="dialog">
  <h4 *ngIf="institutionName === 'money_money_invest'" mat-dialog-title>
    {{ 'Status do lead na Money Money Invest' }}
  </h4>

  <h4 *ngIf="institutionName === 'q_flash'" mat-dialog-title>{{ 'Status do lead na QFlash' }}</h4>

  <div mat-dialog-content>
    <p><strong>Id:</strong>{{ infoStatusInstitution?.id }}</p>
    <p><strong>Status:</strong> {{ infoStatusInstitution?.status }}</p>

    <div *ngIf="institutionName === 'flip' && infoStatusInstitution?.suggestedLimit && infoStatusInstitution?.minimumRate">
      <p><strong>Limite Sugerido:</strong> {{ infoStatusInstitution?.suggestedLimit }}</p>
      <p><strong>Taxas A Partir De:</strong> {{ infoStatusInstitution?.minimumRate }}</p>
    </div>

    <div *ngIf="institutionName === 'money_money_invest'">
      <p><strong>Crédito Disponível:</strong> {{ infoStatusInstitution?.availableCredit }}</p>
      <p><strong>Detalhes:</strong> {{ infoStatusInstitution?.details }}</p>
      <p><strong>Taxa:</strong> {{ infoStatusInstitution?.loanTax }}</p>
      <p><strong>Prazo:</strong> {{ infoStatusInstitution?.loanTerm }}</p>
      <p><strong>URL Proposta:</strong> {{ infoStatusInstitution?.offerUrl }}</p>
      <p><strong>Rating:</strong> {{ infoStatusInstitution?.rating }}</p>
    </div>

    <div *ngIf="institutionName === 'q_flash'">
      <p><strong>CNPJ:</strong> {{ infoStatusInstitution?.cnpj }}</p>
      <p><strong>Limite Aprovado:</strong> {{ infoStatusInstitution?.approvedValue }}</p>
      <p><strong>Limite Consumido:</strong> {{ infoStatusInstitution?.consumedLimit }}</p>
    </div>

    <div *ngIf="institutionName === 'nexoos'">
      <p><strong>Valor Empréstimo:</strong> {{ infoStatusInstitution?.loanValue }}</p>
      <p><strong>Prazo:</strong> {{ infoStatusInstitution?.loanTerm }}</p>
      <p><strong>Risco:</strong> {{ infoStatusInstitution?.loanRisk }}</p>
    </div>

    <div *ngIf="institutionName === 'peak'">
      <p><strong>Valor Aprovado:</strong> {{ infoStatusInstitution?.approvedValue }}</p>
      <p><strong>Valor Solicitado:</strong> {{ infoStatusInstitution?.loanValue }}</p>
      <p><strong>Taxa Ano:</strong> {{ infoStatusInstitution?.loanTax }}</p>
      <p><strong>Parcelas :</strong> {{ infoStatusInstitution?.loanTerm }}</p>
    </div>
    
    <div *ngIf="institutionName === 'u_lend'">
      <p><strong>CNPJ:</strong> {{ infoStatusInstitution?.cnpj }}</p>
      <p><strong>Valor Aprovado:</strong> {{ infoStatusInstitution?.approvedValue }}</p>
      <p><strong>Valor Solicitado:</strong> {{ infoStatusInstitution?.loanValue }}</p>
      <p><strong>URL Proposta:</strong> {{ infoStatusInstitution?.offerUrl }}</p>
    </div>

    <div *ngIf="institutionName === '123_qred_solucoes_financeiras_ltda'">
      <p><strong>Detalhes:</strong> {{ infoStatusInstitution?.details }}</p>
    </div>
  </div>
  <div mat-dialog-actions style="padding-top: 20px" class="d-flex justify-content-end">
    <button
      mat-stroked-button
      class="action-button text-black autosized"
      type="button"
      (click)="onOk()"
      *ngIf="isOnlyConfirm"
    >
      Fechar
    </button>
  </div>
</div>
