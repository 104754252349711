<mat-dialog-content>

  <h1>Autorização do Banco Central</h1>

  <p>Para atender a às exigências do Banco Central, nós e nossos perceiros financeiros solicitamos que você autorize
    o compartilhamento de alguns dados sobre sua empresa.</p>

  <div class="col-12 col-lg-9 mt-3 mb-4 d-flex">
    <mat-checkbox color="primary" class="mr-3 my-1 checkbox" [(ngModel)]="isChecked" required></mat-checkbox>
    <span style="flex: 1">Eu li e concordo com os
      <a class="link" (click)="scrModal()" style="color: #0091ff; cursor: pointer;">Termos de Consulta dos Sócios
        (SCR).</a> </span>
  </div>

  <div style="align-items: center; display: flex; justify-content: center;">
    <button mat-raised-button color="primary" class="action-button" (click)="scrSave()" type="button"
      [disabled]="!isChecked">
      Continuar
    </button>
  </div>


</mat-dialog-content>