<div class="customer-settings-content">
  <h1>Minha conta</h1>
  <mat-tab-group #tabGroup>
    <mat-tab label="Meus dados">
      <app-user-account-data [customer]="customer"></app-user-account-data>
    </mat-tab>
    <mat-tab label="Dados da empresa">
      <app-user-company-data [customer]="customer"></app-user-company-data>
    </mat-tab>
    <mat-tab label="Segurança">
      <router-outlet></router-outlet>
    </mat-tab>
    <mat-tab label="Dados Serasa" *ngIf="!isCustomerNoTouch">
      <div class="serasa-data-wrapper">
        <h5 class="serasa-title">Dados do Serasa</h5>
        <app-serasa-card [expanded]="true" [uid]="customer.uid"></app-serasa-card>
      </div>
    </mat-tab>
    <mat-tab label="Dados Extras">
      <mat-card class="extra-data-card-wrapper">
        <mat-card-title class="title">Meus dados disponíveis</mat-card-title>
        <mat-expansion-panel class="inside-card">
          <mat-expansion-panel-header class="inside-card-header">
            <mat-panel-title class="inside-card-title">Dados Banco Central</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-expansion-panel class="bmp-card-wrapper">
            <mat-expansion-panel-header class="bmp-card-wrapper-header">
              <mat-panel-title class="bmp-card-wrapper-title">Dados do Banco Central (Registrato/SCR)</mat-panel-title>
            </mat-expansion-panel-header>
            <app-card-bmp-moneyplus *ngIf="customer?.uid" [customer]="customer?.uid"></app-card-bmp-moneyplus>
          </mat-expansion-panel>
        </mat-expansion-panel>


        <mat-expansion-panel class="inside-card" style="margin-top: 10px;">
          <mat-expansion-panel-header class="inside-card-header">
            <mat-panel-title class="inside-card-title">Gestão Financeira</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="financial-management-scroll">
            <app-customer-erps-result *ngIf="customer?.uid" [customer]="customer?.uid"></app-customer-erps-result>
          </div>
        </mat-expansion-panel>
      </mat-card>
    </mat-tab>
    <mat-tab label="Termo" *ngIf="!customer.noTermsCustomer">
      <app-user-contract></app-user-contract>
    </mat-tab>
  </mat-tab-group>
</div>
