<mat-dialog-content *ngIf="institutionData?.opportunityStream?.id === 'touch' && proposalData?.stageOpportunity.defaultprobability !== 70 && !isExpired(proposalData) || institutionData?.opportunityStream?.id === 'no-touch' && proposalData?.stageOpportunity.defaultprobability != 40 && proposalData?.stageOpportunity.defaultprobability != 60 && !isExpired(proposalData)">
  <div class="close-icon">
    <i class="material-icons" [mat-dialog-close]="undefined">close</i>
  </div>

  <div *ngIf="!isExpired(proposalData)" class="content d-flex flex-column flex-md-row align-items-md-center">

    <div class="text-div" *ngIf="!isRejectReasonFormOpen && !requestRefused">
      <h1>
        <strong>Detalhes da oferta</strong>
      </h1>
<!-- 
      <div *ngIf="proposalData?.stageOpportunity.defaultprobability > 40 && institutionData?.remuneracaoVariavel === true" style="color: #0F653E;">
        <p><mat-icon>check_circle_outline</mat-icon> Você está com sorte! A taxa de serviço da Capital será gratuita nesta operação! </p>
      </div> -->

      <div class="opp-info">
        <p class="mb-1"><strong>Instituição:</strong> {{ nameIf }}</p>
        <p>
          <a href="{{ institutionData?.publicDescription }}" target="_blank" *ngIf="institutionData?.publicDescription && proposalData?.institutionName === nameIf">
            {{ institutionData?.publicDescription }}
          </a>
        </p>

        <p class="mt-3">
          <strong>Limite de crédito pré-aprovado:</strong>
          {{ proposalData?.stageOpportunity?.defaultprobability < 40 ? '-' : (proposalData?.lineActive && proposalData?.limitOperation | currency: 'BRL':true:'1.2-2' || '-') }}
        </p>
        <p><strong>Taxa:</strong> {{ proposalData?.lineActive && proposalData.rate ? proposalData.rate + '% ao mês' : '-' }}</p>
        <p>
          <strong>Prazo:</strong>
          {{ proposalData?.stageOpportunity?.defaultprobability < 40 ? '-' :
          (proposalData?.deadlineForMonth
          ? proposalData?.deadlineForMonth > 1
          ? proposalData?.deadlineForMonth + ' meses'
          : proposalData?.deadlineForMonth + ' mes'
          : '-')
          }}
        </p>
        <p *ngIf="institutionData?.proposalValidity" >
          <strong >Validade da proposta: </strong>
              {{ validityMessage}}
        </p>

        <p *ngIf="proposalData?.description">
          <strong>Descrição: </strong>
          <span [innerHTML]="convertLinks(proposalData.description)"></span>
        </p>

      </div>

      <div *ngIf="proposalData.stageOpportunity?.defaultprobability === 40">

        <div class="mt-3 text-center">
          <button mat-stroked-button (click)="isRejectReasonFormOpen = true" class="border-button">Recusar</button>

          <button mat-raised-button [mat-dialog-close]="true" class="green-button">
            Tenho interesse
          </button>
        </div>

        <p style="font-size: 12px; margin-top: 10px;">
          <strong>Observação:</strong> Ao clicar em 'tenho interesse' a essa proposta, você irá passar para a próxima
          fase de análise de crédito, mais completa. <strong>Este não é um aceite final!</strong>
        </p>


      </div>

      <div *ngIf="proposalData.stageOpportunity?.defaultprobability >= 60">
        <p *ngIf="
            proposalData.stageOpportunity?.defaultprobability == 60 ||
            proposalData.stageOpportunity?.defaultprobability == 70
          "
          style="font-size: 14px;"
        >
        <span class="text-green">Retorno da operação:</span>
          Para dar andamento ao seu pedido, é necessário o envio dos documentos.
        </p>
        <p *ngIf="proposalData.stageOpportunity?.defaultprobability == 80" class="text-green">
          Aguarde! Seu pedido está em análise final de crédito.
        </p>
        <p *ngIf="proposalData.stageOpportunity?.defaultprobability == 90" class="text-green">
          Parabéns, você conseguiu seu crédito e está em etapa final de contratação.
        </p>
        <p *ngIf="proposalData.stageOpportunity?.defaultprobability == 100" class="text-green">
          Sua operação foi concluída com sucesso! Obrigada por confiar na Capital!
        </p>
        <div class="mt-4 text-center">
          <button class="border-button" [mat-dialog-close]="undefined">Voltar</button>
        </div>
      </div>
    </div>
    <div class="text-div" *ngIf="isRejectReasonFormOpen && !requestRefused">
      <div class="text-center">
        <h1>
          <strong>Detalhes da Proposta</strong>
        </h1>
        <p>Por que não gostaria de dar andamento a esta oportunidade?</p>
        <div class="text-center">
          <mat-form-field appearance="outline" style="max-width: 400px;">
            <mat-label>Motivo</mat-label>
            <mat-select [(value)]="selectedReason">
              <mat-option *ngFor="let rejectReason of rejectReasons" [value]="rejectReason"
                [matTooltip]="rejectReason.nome">
                {{ rejectReason.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="text-center">
            <button mat-raised-button color="primary" style="color: white" [mat-dialog-close]="selectedReason"
              [disabled]="!selectedReason" [ngClass]="{'green-button-select': selectedReason}">
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="text-div" *ngIf="requestRefused && !isRejectReasonFormOpen" >
      <h1>
        <strong style="color: #F93232;">Pedido recusado</strong>
      </h1>

      <p><strong>Instituição:</strong> {{ proposalData.institutionName }}</p>
      <p><strong>Descrição da instituição:</strong> {{ institutionData?.publicDescription }}</p>

      <p>
        Lamentamos informar que, após uma análise cuidadosa, o seu pedido foi, infelizmente, reprovado. Esta decisão pode ter sido influenciada por diversos fatores, tais como histórico de crédito, inadimplências, renda, histórico de pagamento, dados desatualizados, entre outros.</p>
        <p>
          <b> No entanto, não desanime! Após 90 dias, você poderá submeter um novo pedido.</b> Enquanto aguarda, sugerimos que <b>acesse nosso artigo no blog</b>, onde compartilhamos dicas sobre como melhorar sua situação de crédito! Essas informações podem ser úteis para <b>aumentar suas chances de aprovação em futuras solicitações!</b>
      </p>

      <a class="botao" mat-raised-button href="https://www.capitalempreendedor.com.br/blog" target="_blank">Acesse nosso blog</a>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-content class="notouch-modal-container" *ngIf="institutionData?.opportunityStream?.id === 'no-touch' && proposalData?.stageOpportunity.defaultprobability === 40 || institutionData?.opportunityStream?.id === 'no-touch' && proposalData?.stageOpportunity.defaultprobability === 60 && !isExpired(proposalData)">
  <div class="close-icon">
    <i class="material-icons" [mat-dialog-close]="undefined">close</i>
  </div>
  <div class="content d-flex flex-column flex-md-row align-items-md-center">

    <div class="text-div" *ngIf="!isRejectReasonFormOpen">
      <h1>Detalhes da oferta</h1>

      <div class="opp-info">
        <p class="mb-1"><strong>Instituição:</strong> {{ nameIf }}</p>
        <p>
          <strong>Descrição da instituição:</strong>
          <a href="{{ institutionData?.publicDescription }}" target="_blank" *ngIf="institutionData?.publicDescription && proposalData?.institutionName === nameIf">
            {{ institutionData?.publicDescription }}
          </a>
        </p>
      </div>
      <div class="green-line">
        ━━━━━━━━━━━━
      </div>

      <p *ngIf="institutionData?.proposalValidity">
        <strong>Validade da proposta: </strong>
        {{ validityMessage }}
      </p>

      <div class="notouch-texts-wrapper">
        <p><strong>Temos uma oportunidade de crédito para você e sua empresa!</strong></p>
        <p>Neste momento <strong>não será necessário enviar documentos!</strong> Basta seguir o passo a passo simplificado mostrado após clicar no botão de "Prosseguir".</p>
      </div>

      <div class="mt-3 text-center notouch-btn-wrapper">
        <button mat-stroked-button [mat-dialog-close]="undefined" class="border-button">Voltar</button>

        <button mat-raised-button [mat-dialog-close]="true" class="green-button" (click)="openDescriptionDialog(proposalData)">
          Prosseguir
        </button>
      </div>

    </div>
  </div>
</mat-dialog-content>

<mat-dialog-content class="notouch-modal-container" *ngIf="(proposalData?.stageOpportunity.defaultprobability === 40 || proposalData?.stageOpportunity.defaultprobability === 60) && !isExpired(proposalData) && (proposalData.institutionName === 'Cartão de crédito Capital Empreendedor' || proposalData.institutionName === 'Cartão de crédito Cora')">
  <div class="close-icon">
    <i class="material-icons" [mat-dialog-close]="undefined">close</i>
  </div>
  <div class="content d-flex flex-column flex-md-row align-items-md-center">

    <div class="text-div" *ngIf="!isRejectReasonFormOpen">

      <div class="mt-3 text-center" *ngIf="proposalData.institutionName === 'Cartão de crédito Capital Empreendedor'"> 
        <img src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fcart%C3%A3o%20de%20credito%20capital.png?alt=media&token=320c8ca3-4595-461a-a7ce-a404a9f7a707" alt="" width="520">
      </div>

      <div class="mt-3 text-center" *ngIf="proposalData.institutionName === 'Cartão de crédito Cora'"> 
        <img src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fcart%C3%A3o%20de%20credito%20capital%20(3).png?alt=media&token=62524a5f-01a2-4f45-ab45-dc240911b825" alt="" width="520">
      </div>
     
      <h1>Detalhes da oferta</h1>

      <div class="opp-info">
        <p class="mb-1"><strong>Instituição:</strong> {{ nameIf }}</p>
        <p class="mb-1"><strong>Cash Back:</strong> R$30 na movimentação da conta.</p>
        <p class="mb-1"><strong>Limite de crédito pré-aprovado:</strong> Até R$10.000*</p>
        <p class="mb-1"><strong>Taxa:</strong> Zero. Livre de Anuidade ou Mensalidade.</p>
        <p class="mb-1"><strong>Cartão Virtual:</strong> Para compras na Internet.</p>
        <p class="mb-1"><strong>Cartão Múltiplo:</strong> Crédito e Débito em um só cartão.</p>
        <p class="mb-1"><strong>Cartão Internacional.</strong></p>
        
      </div>
     
      <div class="mt-3 text-center notouch-btn-wrapper">
        <button mat-stroked-button (click)="isRejectReasonFormOpen = true" class="border-button">Recusar</button>

        <button mat-raised-button [mat-dialog-close]="true" (click)="requestCard(proposalData)" class="green-button">
          Solicitar Cartão
        </button>
      </div>

      <p style="font-size: 12px; margin-top: 10px;">
        <strong>Observação:</strong> *Limite sujeito à Operação de Crédito. <br> Ao clicar em 'tenho interesse' a essa proposta, você irá passar para a próxima
        fase de análise de crédito, mais completa. <strong>Este não é um aceite final!</strong>
      </p>

    </div>
  </div>
</mat-dialog-content>

<mat-dialog-content class="modal-container"  *ngIf="isExpired(proposalData)" >
  <div class="content d-flex flex-column flex-md-row align-items-md-center">

    <div class="text-div" *ngIf="!isRejectReasonFormOpen">
      <h1 style="color: #F93232;">Proposta expirada</h1>

      <div class="opp-info">
        <p class="mb-1"><strong>Instituição:</strong> {{ nameIf }}</p>
        <p>
          <strong>Descrição da instituição:</strong>
          <a href="{{ institutionData?.publicDescription }}" target="_blank" *ngIf="institutionData?.publicDescription && proposalData?.institutionName === nameIf">
            {{ institutionData?.publicDescription }}
          </a>
        </p>
      </div>


      <div class="notouch-texts-wrapper">
        <p><b>Poxa! Infelizmente sua proposta alcançou o prazo máximo de validade e expirou.</b></p>
          <p>Sabemos que a correria do dia a dia pode fazer a gente perder algumas datas, então estamos aqui para ajudar.</p>
          <p><b style="color: #FF8641;"> Fique atento a seus emails e cheque a aba de “Ofertas de crédito” para verificar se há novas propostas!</b> Se precisar discutir novas opções de crédito ou tiver alguma outra necessidade financeira, é só nos chamar. Estamos sempre prontos para encontrar a melhor solução para você.</p>
          <p>Obrigado por confiar na gente!</p>

      </div>

      <div class="mt-3 text-center notouch-btn-wrapper">
        <button  class="border-button" [mat-dialog-close]="undefined">fechar</button>

      </div>

    </div>
  </div>
</mat-dialog-content>

<mat-dialog-content class="pendencies-modal-container" *ngIf="isNoTouch(institutionData, proposalData) && proposalData?.stageOpportunity.defaultprobability === 70">
  <div class="close-icon">
    <i class="material-icons" [mat-dialog-close]="undefined">close</i>
  </div>
  <div class="content d-flex flex-column flex-md-row align-items-md-center">
    <div class="text-div" *ngIf="!isRejectReasonFormOpen">
      <h1>Detalhes da oferta</h1>
      <div class="opp-info">
        <p><strong>Instituição:</strong> {{ nameIf }}</p>
        <p>
          <strong>Descrição da instituição:</strong>
          <a href="{{ institutionData?.publicDescription }}" target="_blank" *ngIf="institutionData?.publicDescription && proposalData?.institutionName === nameIf">
            {{ institutionData?.publicDescription }}
          </a>
        </p>
        <div class="inner-card">
          <h2>Você tem pendências para seguir com a oferta</h2>
          <h4>Retorno da Capital Empreendedor:</h4>
          <p>{{ pendencyToDisplay.pendency }}</p>
          <button (click)="pendencyMessageChat()" [mat-dialog-close]="undefined">Fale sobre a oferta</button>
        </div>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Condições da oferta</mat-panel-title>
          </mat-expansion-panel-header>
          <p class="mt-3">
            <strong>Limite de crédito pré-aprovado:</strong>
            {{ proposalData?.stageOpportunity?.defaultprobability < 40 ? '-' : (proposalData?.lineActive && proposalData?.limitOperation | currency: 'BRL':true:'1.2-2' || '-') }}
          </p>
          <p><strong>Taxa:</strong> {{ proposalData?.lineActive && proposalData.rate ? proposalData.rate + '% ao mês' : '-' }}</p>
          <p>
            <strong>Prazo:</strong>
            {{ proposalData?.stageOpportunity?.defaultprobability < 40 ? '-' :
            (proposalData?.deadlineForMonth
            ? proposalData?.deadlineForMonth > 1
            ? proposalData?.deadlineForMonth + ' meses'
            : proposalData?.deadlineForMonth + ' mes'
            : '-')
            }}
          </p>
          <p *ngIf="institutionData?.proposalValidity" >
            <strong >Validade da proposta: </strong>
                {{ validityMessage}}
          </p>

          <p *ngIf="proposalData?.description">
            <strong>Descrição: </strong>
            <span [innerHTML]="convertLinks(proposalData.description)"></span>
          </p>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
</mat-dialog-content>