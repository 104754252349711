import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { createNumberMask } from 'text-mask-addons';
import { RevenueRangeService } from '../../bankers/services/revenue-range.service';

@Component({
  selector: 'app-slider-range-revenue',
  templateUrl: `./slider-range-revenue.component.html`,
  styleUrls: ['./slider-range-revenue.component.css'],
})
export class SliderRangeRevenueComponent {
  @Output() updateRevenue = new EventEmitter<{ minRevenue: number; maxRevenue: number }>();
  @ViewChild('faixaValorMenu') faixaValorMenu: MatMenuTrigger;
  readonly numberMask = createNumberMask({
    // decimalLimit: 2,
    thousandsSeparatorSymbol: '.',
    // decimalSymbol: ',',
    // allowDecimal: true,
    integerLimit: 15,
    // prefix: 'R$ ',
    suffix: '',
  });
  minRevenue = 1;
  maxRevenue = 10000000000;

  constructor(private revenueService: RevenueRangeService, public dialogRef: MatDialogRef<SliderRangeRevenueComponent>, @Inject(MAT_DIALOG_DATA) data) {
    if (data.minRevenue) {this.minRevenue = data.minRevenue; }
    if (data.maxRevenue) {this.maxRevenue = data.maxRevenue; }
  }

  updateValues(): void {
    this.revenueService.setMinRevenue(this.minRevenue);
    this.revenueService.setMaxRevenue(this.maxRevenue);
  }

  onMinSliderChange(newValue: number): void {
    if (!newValue) {
      this.minRevenue = null;
    } else if (newValue > this.maxRevenue) {
      this.minRevenue = this.maxRevenue;
    } else {
      this.minRevenue = newValue;
    }

    this.updateValues();
  }

  onMaxSliderChange(newValue: number): void {
    if (!newValue) {
      this.maxRevenue = null;
    } else if (newValue < this.minRevenue) {
      this.maxRevenue = this.minRevenue;
    } else {
      this.maxRevenue = newValue;
    }
    this.updateValues();
  }

  formatValue(value: number): string {
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + 'B';
     } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'mil';
    }

    return value.toString();
  }
}
