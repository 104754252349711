import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-account-property',
  templateUrl: './create-account-property.component.html',
  styleUrls: ['./create-account-property.component.scss']
})
export class CreateAccountPropertyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
