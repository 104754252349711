import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private angularFirestore: AngularFirestore) {}

  getConfiguration(doc: string): Observable<any> {
    return this.angularFirestore.collection('configuracao').doc(doc).valueChanges();
  }
}
