import { animate, state, style, transition, trigger } from '@angular/animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BasicStructure } from 'functions/src/models/common/BasicStructure';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import { CustomerService } from 'src/app/customer/services/customer.service';
// add image resize module
import { Subscription } from 'rxjs';
import { LogService } from 'src/app/components/logger/log.service';
import { User } from '../../../../functions/src/models/User';
import { Document, DocumentAction, DocumentActionLabel } from '../../../../functions/src/models/UserDocument';
import { AuthService } from '../../core/auth/auth.service';
import { TipoDocumentoService } from './tipo-documento.service';

// Add fonts to whitelist
const Font = Quill.import('formats/font');
// We do not add Aref Ruqaa since it is the default
Font.whitelist = ['mirza', 'aref', 'sans-serif', 'monospace', 'serif'];
Quill.register(Font, true);

// Quill.register('modules/imageResize', ImageResize);

@Component({
  selector: 'app-tipo-documento',
  templateUrl: './tipo-documento.component.html',
  styleUrls: ['./tipo-documento.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TipoDocumentoComponent implements OnInit, OnDestroy {
  createEditFormGroup: FormGroup;
  config: any;
  isCreateEdit: boolean;
  tituloTipoDocumento: string;
  isEdit = false;
  instrucoesCtrl: string;
  modules = {};
  hasValidade: boolean;
  hasAnoExercicio: boolean;
  hasAnoExercicioCorrente: boolean;
  multipleDocuments: boolean;
  minimumDocuments: number;
  maximumDocuments: number;
  anosExercicios = [];
  expandedElement: Document;
  expandedElementId: string;

  // Tipe Antecipation
  hasTypeOfAnticipation = false;

  displayedColumns: string[] = ['nome', 'mnemonico', 'ativo', 'acao'];
  dataTiposDocumentos: Document[];
  dataSourceTipoDocumentos: MatTableDataSource<Document>;
  private matSortTipoDocumentos: MatSort;
  loggedAdminSubscription: Subscription;
  loggedAdmin: User;
  @ViewChild('matSortTipoDocumentos', { static: false }) set matSortCredtPre(ms: MatSort) {
    this.matSortTipoDocumentos = ms;
    this.dataSourceTipoDocumentos.sort = this.matSortTipoDocumentos;
  }
  documentActionList: BasicStructure[];

  readonly opcaoSimNao: BasicStructure[] = [
    { id: 'nao', name: 'Não' },
    { id: 'sim', name: 'Sim' },
  ];
  readonly selectTypeOfAnticipationOperator = [
    { id: 'cartao', value: 'Cartão' },
    { id: 'boleto', value: 'Boleto' },
    { id: 'nota_fiscal', value: 'Notas Fiscais' },
  ];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private toolbar: any = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],

    ['link', 'image', 'video'],
  ];

  @ViewChild('editor', { static: false }) editor: QuillEditorComponent;
  configSubscription: Subscription;
  getTiposDocumentosSubscription: Subscription;

  constructor(
    public _data: TipoDocumentoService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    private logger: LogService,
    private authService: AuthService
  ) {
    this.expandedElementId = null;
    this.getTiposDocumentosSubscription = this._data.getTiposDocumentos().subscribe((result) => {
      this.dataTiposDocumentos = result;
      this.dataSourceTipoDocumentos = new MatTableDataSource(this.dataTiposDocumentos);
      this.dataSourceTipoDocumentos.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'ativo':
            return item.ativo.name;
          default:
            return item[property];
        }
      };
      if (this.expandedElement && this.expandedElement.ativo) {
        // Caso já esteja expandido, obtem nova referencia do objeto atualizado pelo stream.
        this.expandedElement = this.dataSourceTipoDocumentos.filteredData.find((a) => a.id === this.expandedElementId);
      }
    });
    this.documentActionList = Object.keys(DocumentAction).map((key) => ({
      id: DocumentAction[key],
      name: DocumentActionLabel[DocumentAction[key]],
    }));
    this.configSubscription = this.customerService.getSignUpConfiguration().subscribe((config) => {
      this.config = config;
    });
    this.logger.controllerName = this.constructor.name;
    this.tituloTipoDocumento = 'Lista';
    this.dataSourceTipoDocumentos = new MatTableDataSource([]);
    this.isCreateEdit = false;
    this.modules = {
      toolbar: this.toolbar,
    };
  }

  ngOnInit(): void {
    this.loggedAdminSubscription = this.authService.user.subscribe(user => {
      this.loggedAdmin = user;
    });
  }

  ngOnDestroy(): void {
    if (this.getTiposDocumentosSubscription) {
      this.getTiposDocumentosSubscription.unsubscribe();
    }
    if (this.loggedAdminSubscription) {
      this.loggedAdminSubscription.unsubscribe();
    }
  }

  onClickExpanded() {
    if (this.expandedElement) {
      this.expandedElementId = this.expandedElement.id;
    } else {
      this.expandedElementId = null;
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceTipoDocumentos.filter = filterValue;
  }

  onLoadForm() {
    this.createEditFormGroup = new FormGroup({
      idCtrl: new FormControl('', []),
      nomeCtrl: new FormControl('', Validators.required),
      mnemonicoCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      descricaoCtrl: new FormControl('', Validators.required),
      guaranteesCtrl: new FormControl(''),
      typeOfAnticipationCtrl: new FormControl(''),
      ativoCtrl: new FormControl('', Validators.required),
      automaticoCtrl: new FormControl('', Validators.required),
      visibleToBankerCtrl: new FormControl('', Validators.required),
      validadeCtrl: new FormControl('', Validators.required),
      qtdDiasCtrl: new FormControl({ value: '', disabled: false }, []),
      anoExercicioCtrl: new FormControl(''),
      anoExercicioCorrenteCtrl: new FormControl(''),
      instrucoesCtrl: new FormControl('', Validators.required),
      anosExerciciosCtrl: this.formBuilder.array([]),
      anoExercicioQtdCtrl: new FormControl({ value: '', disabled: false }, []),
      multipleDocumentsCtrl: new FormControl('', Validators.required),
      minimumDocumentsCtrl: new FormControl('', Validators.required),
      maximumDocumentsCtrl: new FormControl('', Validators.required),
      documentActionCtrl: new FormControl('', Validators.required),
    });
  }

  onAdicionarNovo() {
    this.hasValidade = false;
    this.hasAnoExercicio = false;
    this.multipleDocuments = false;
    this.minimumDocuments = 1;
    this.maximumDocuments = 1;
    this.isEdit = false;
    this.isCreateEdit = true;
    this.onLoadForm();
    this.tituloTipoDocumento = 'Novo';
    this.instrucoesCtrl = '';
  }

  onEditar(tipoDocumento: Document) {
    this.isEdit = true;
    this.isCreateEdit = true;
    this.createEditFormGroup = new FormGroup({
      idCtrl: new FormControl(tipoDocumento.id, []),
      nomeCtrl: new FormControl(tipoDocumento.nome, Validators.required),
      mnemonicoCtrl: new FormControl({ value: tipoDocumento.mnemonico, disabled: true }, Validators.required),
      descricaoCtrl: new FormControl(tipoDocumento.descricao, Validators.required),
      guaranteesCtrl: new FormControl(tipoDocumento?.guarantees),
      typeOfAnticipationCtrl: new FormControl(tipoDocumento?.typeOfAnticipation),
      ativoCtrl: new FormControl(tipoDocumento.ativo, Validators.required),
      automaticoCtrl: new FormControl(tipoDocumento.automatico, Validators.required),
      visibleToBankerCtrl: new FormControl(tipoDocumento.visibleToBanker, Validators.required),
      validadeCtrl: new FormControl(tipoDocumento.validade, Validators.required),
      qtdDiasCtrl: new FormControl(tipoDocumento.qtdDias, []),
      anoExercicioCtrl: new FormControl(tipoDocumento.anoExercicio, Validators.required),
      anoExercicioCorrenteCtrl: new FormControl(tipoDocumento.anoExercicioCorrente),
      multipleDocumentsCtrl: new FormControl(tipoDocumento.multipleDocuments, Validators.required),
      minimumDocumentsCtrl: new FormControl(tipoDocumento.minimumDocuments, Validators.required),
      maximumDocumentsCtrl: new FormControl(tipoDocumento.maximumDocuments, Validators.required),
      instrucoesCtrl: new FormControl(tipoDocumento.instrucoes, Validators.required),
      anosExerciciosCtrl: new FormControl(tipoDocumento.anoExercicio),
      anoExercicioQtdCtrl: new FormControl(tipoDocumento.anoExercicioQtd, []),
      documentActionCtrl: new FormControl(
        {
          id: tipoDocumento.documentAction ?? DocumentAction.UPLOAD,
          name: DocumentActionLabel[tipoDocumento.documentAction ?? DocumentAction.UPLOAD],
        },
        Validators.required
      ),
    });

    if (tipoDocumento.anoExercicio.id === 'sim') {
      this.hasAnoExercicio = true;
    } else {
      this.hasAnoExercicio = false;
    }
    if (tipoDocumento.validade.id === 'sim') {
      this.hasValidade = true;
    } else {
      this.hasValidade = false;
    }
    this.tituloTipoDocumento = tipoDocumento.nome;
    this.instrucoesCtrl = '';
  }

  onSaveForm() {
    const tipoDocumento: Partial<Document> = {
      nome: this.createEditFormGroup.value.nomeCtrl,
      mnemonico: this.createEditFormGroup.controls.mnemonicoCtrl.value,
      descricao: this.createEditFormGroup.controls.descricaoCtrl.value,
      guarantees: this.createEditFormGroup.controls.guaranteesCtrl.value,
      typeOfAnticipation: this.createEditFormGroup.controls.typeOfAnticipationCtrl.value,
      instrucoes: this.createEditFormGroup.value.instrucoesCtrl,
      ativo: this.createEditFormGroup.value.ativoCtrl,
      automatico: this.createEditFormGroup.value.automaticoCtrl,
      visibleToBanker: this.createEditFormGroup.value.visibleToBankerCtrl,
      validade: this.createEditFormGroup.value.validadeCtrl,
      anoExercicio: this.createEditFormGroup.value.anoExercicioCtrl,
      anoExercicioCorrente: this.createEditFormGroup.value.anoExercicioCorrenteCtrl,
      multipleDocuments: this.createEditFormGroup.value.multipleDocumentsCtrl,
      minimumDocuments: this.createEditFormGroup.value.minimumDocumentsCtrl,
      maximumDocuments: this.createEditFormGroup.value.maximumDocumentsCtrl,
      qtdDias: +this.createEditFormGroup.controls.qtdDiasCtrl.value,
      anoExercicioQtd: +this.createEditFormGroup.controls.anoExercicioQtdCtrl.value,

      documentAction: this.createEditFormGroup.value.documentActionCtrl?.id,
    };
    if (this.createEditFormGroup.value.idCtrl === '') {
      this._data.addTipoDocumento(tipoDocumento as Document);
    } else {
      tipoDocumento.id = this.createEditFormGroup.value.idCtrl;
      this._data.updateTipoDocumento(tipoDocumento as Document);
    }
    this.isCreateEdit = false;
    this.tituloTipoDocumento = 'Lista';
  }

  onVoltar() {
    this.isCreateEdit = false;
    this.tituloTipoDocumento = 'Lista';
  }

  slugifName(name) {
    if (!this.isEdit) {
      const slugify = require('@sindresorhus/slugify');
      const result = slugify(name, { separator: '_' });

      this.createEditFormGroup.get('mnemonicoCtrl').setValue(result);
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1.id === o2.id;
  }

  onChangeTemValidade(event) {
    if (event.value.id === 'sim') {
      this.hasValidade = true;
      this.createEditFormGroup.get('qtdDiasCtrl').setValue(0);
    } else {
      this.hasValidade = false;
      this.createEditFormGroup.get('qtdDiasCtrl').setValue(0);
    }
    this.createEditFormGroup.get('qtdDiasCtrl').markAsTouched();
  }

  onChangeTemAnoExercicio(event) {
    if (event.value.id === 'sim') {
      this.hasAnoExercicio = true;
      this.createEditFormGroup.get('anoExercicioQtdCtrl').setValue(0);
      this.hasAnoExercicioCorrente = true;
      this.createEditFormGroup.get('anoExercicioCorrenteCtrl').setValue(0);
    } else {
      this.hasAnoExercicio = false;
      this.createEditFormGroup.get('anoExercicioQtdCtrl').setValue(0);
      this.hasAnoExercicioCorrente = false;
      this.createEditFormGroup.get('anoExercicioCorrenteCtrl').setValue(0);
    }
    this.createEditFormGroup.get('anoExercicioQtdCtrl').markAsTouched();
    this.createEditFormGroup.get('anoExercicioCorrenteCtrl').markAsTouched();

   

  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const item = this.createEditFormGroup.get('anosExerciciosCtrl') as FormArray;
      item.push(this.formBuilder.control(value.trim()));
    }

    if ((value || '').trim()) {
      const item = this.createEditFormGroup.get('anoExercicioCorrenteCtrl') as FormArray;
      item.push(this.formBuilder.control(value.trim()));
    }

    if (input) {
      input.value = '';
    }
  }

  remove(index): void {
    const itens = this.createEditFormGroup.get('anosExerciciosCtrl') as FormArray;

    if (index >= 0) {
      itens.removeAt(index);
    }
  }

  onChangeGuarantees(event): void {
    // if has type guarantee
    if (event.value.length && event.value.find((g) => g.id === 'boleto')) {
      this.hasTypeOfAnticipation = true;
    } else {
      this.hasTypeOfAnticipation = false;
    }
  }
}
