<div class="container-fluid">
  <mat-card>
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <span style="flex: 1">Relatórios - Assessores</span>
      </mat-card-title>

      <div class="d-flex align-items-center">
        <mat-form-field color="primary" style="flex: 1" class="mr-3">
          <mat-label>Escolha o relatório</mat-label>
          <mat-select [formControl]="selectedReport">
            <mat-option *ngFor="let report of reportList; let i = index" [value]="report.name">{{
              report.label
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- filter by agent status -->
      <div *ngIf="selectedReport?.value">
        <h4 class="m-3">Filtros:</h4>
        <mat-form-field>
          <mat-label>Status do assessor</mat-label>
          <mat-select [formControl]="selectedAgentStatus" multiple>
            <mat-option *ngFor="let status of agentStatusList; let i = index" [value]="status.name">
              {{ status.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field color="primary" style="flex: 1" matTooltip="Digite a origem">
          <mat-label>Origem</mat-label>
          <input matInput type="text" name="origem" [formControl]="sourceCtrl" />
        </mat-form-field>
        <div class="col-12 text-center">
          <button
            mat-raised-button
            color="primary"
            (click)="getData()"
            [disabled]="this.selectedReport.value && this.isLoadingAgents"
          >
            Obter dados
          </button>
        </div>
      </div>

      <div class="w-100 p-5" *ngIf="isLoadingAgents">
        <mat-spinner *ngIf="isLoadingAgents" [diameter]="60" class="mx-auto" color="primary"></mat-spinner>
      </div>

      <div class="table-container" *ngIf="!isLoadingAgents && agents?.length === 0">
        <p class="text-center">Nenhum assessores encontrado.</p>
      </div>

      <div class="table-container mt-4" *ngIf="!isLoadingAgents && agents === null">
        <p class="text-center">Falha ao obter a lista de assessores.</p>
        <p class="text-center">
          <button mat-raised-button color="accent" (click)="getData()">Tentar novamente</button>
        </p>
      </div>

      <div [class.d-none]="isLoadingAgents || !agents?.length">
        <div class="col-12 d-flex align-items-center">
          <mat-form-field color="primary" style="flex: 1" class="mr-3">
            <mat-label>Campos a exibir/exportar</mat-label>
            <mat-select [formControl]="selectedFields" multiple>
              <mat-option *ngFor="let field of fieldsList" [value]="field.name">{{ field.label }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="typeFile" color="primary" style="flex: 1; max-width: 150px" class="mr-3">
            <mat-label>Formato</mat-label>
            <mat-select [formControl]="selectedType" [compareWith]="compareSelectedType">
              <mat-option *ngFor="let type of typeFile" [value]="type">{{ type.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <button
              mat-raised-button
              color="accent"
              (click)="exportList()"
              [disabled]="!this.selectedFields?.value?.length || !selectedType?.value"
            >
              <mat-icon class="mr-2">get_app</mat-icon> Exportar
            </button>
          </div>
        </div>

        <div class="table-container mat-elevation-z3">
          <table
            mat-table
            [dataSource]="dataSource"
            matSort
            [matSortActive]="sortField"
            [matSortDirection]="sortDirection"
            matSortDisableClear
          >
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Nome</th>
              <td mat-cell *matCellDef="let row">
                {{ row.name || '-' }}
              </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>E-mail</th>
              <td mat-cell *matCellDef="let row">{{ row.email }}</td>
            </ng-container>

            <!-- Phone Column -->
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Telefone</th>
              <td mat-cell *matCellDef="let row; let i = index">
                {{ row.phone || '-' }}
              </td>
            </ng-container>

            <!-- CNPJ Column -->
            <ng-container matColumnDef="cnpj">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>CNPJ</th>
              <td mat-cell *matCellDef="let row">{{ row.cnpj || '-' }}</td>
            </ng-container>

            <!-- Agent Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Status</th>
              <td mat-cell *matCellDef="let row">{{ row.status || '-' }}</td>
            </ng-container>

            <!-- Lead Quantity Column -->
            <ng-container matColumnDef="leadQuantity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Quantidade de Leads</th>
              <td mat-cell *matCellDef="let row; let i = index" class="text-center">
                {{ row.leadQuantity || 0 }}
              </td>
            </ng-container>

            <!-- Created Lead Quantity Column -->
            <ng-container matColumnDef="createdLeadQuantity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Quantidade de Leads Criados</th>
              <td mat-cell *matCellDef="let row; let i = index" class="text-center">
                {{ row.createdLeadQuantity || 0 }}
              </td>
            </ng-container>

            <!-- Lead Name Column -->
            <ng-container matColumnDef="leadName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Nome do Lead</th>
              <td mat-cell *matCellDef="let row">
                {{ row.leadName || '-' }}
              </td>
            </ng-container>

            <!-- Lead Email Column -->
            <ng-container matColumnDef="leadEmail">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>E-mail do Lead</th>
              <td mat-cell *matCellDef="let row">{{ row.leadEmail }}</td>
            </ng-container>

            <!-- Lead Signed Term Column -->
            <ng-container matColumnDef="signedTerm">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Assinou termo em</th>
              <td mat-cell *matCellDef="let row; let i = index">
                {{ row.signedTerm || '-' }}
              </td>
            </ng-container>

            <!-- Created At Column -->
            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Data Criação</th>
              <td mat-cell *matCellDef="let row">
                {{ (row.createdAt | date: 'yyyy-MM-dd') || '-' }}
              </td>
            </ng-container>

            <!-- Created At Column -->
            <ng-container matColumnDef="lastStatusUpdate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Última atualização de status</th>
              <td mat-cell *matCellDef="let row">
                {{ (row.lastStatusUpdate | date: 'yyyy-MM-dd HH:mm') || '-' }}
              </td>
            </ng-container>

            <!-- Created At Column -->
            <ng-container matColumnDef="lastStatusEvent">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Última ação de status</th>
              <td mat-cell *matCellDef="let row">
                {{ row.lastStatusEvent || '-' }}
              </td>
            </ng-container>

            <!-- UID Column -->
            <ng-container matColumnDef="uid">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>UID</th>
              <td mat-cell *matCellDef="let row">
                {{ row.uid || '-' }}
              </td>
            </ng-container>

            <!-- Source Column -->
            <ng-container matColumnDef="source">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Origem</th>
              <td mat-cell *matCellDef="let row">
                {{ row.source || '-' }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="selectedFields?.value || []"></tr>
            <tr mat-row *matRowDef="let row; columns: selectedFields?.value || []"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
