<div class="dialog">
  <h4 mat-dialog-title>{{ alertTitle }}</h4>
  <div mat-dialog-content>
    <div [innerHtml]="alertDescription" class="alert-description"></div>
    <mat-form-field *ngIf="hasTextDescription" style="width: 100%">
      <!--- IF not Input---->
      <textarea
        *ngIf="!isInput"
        matInput
        placeholder="{{ dataTextDescription.placeholder }}"
        [(ngModel)]="dataTextDescription.dataTextCtrl"
        required
        name="description"
        matTextareaAutosize
        matAutosizeMinRows="3"
        matAutosizeMaxRows="8"
      ></textarea>

      <!--- IF Input---->
      <mat-label *ngIf="isInput">{{ dataTextDescription.placeholder }}</mat-label>
      <input
        *ngIf="isInput"
        matInput
        required
        [placeholder]="dataTextDescription.placeholder"
        [(ngModel)]="dataTextDescription.dataTextCtrl"
        type="text"
      />

      <mat-error *ngIf="!dataTextDescription.dataTextCtrl.trim()">
        {{ dataTextDescription.errorMessage }}
      </mat-error>
    </mat-form-field>
    <div *ngIf="hasSelectList">
      <mat-form-field style="width: 100%" color="accent">
        <mat-select
          placeholder="{{ dataSelectList.placeholder }}"
          [(ngModel)]="optionSelected"
          name="faseOportunidade"
          required
        >
          <mat-option *ngFor="let optionSelected of dataSelectList.dataList" [value]="optionSelected">
            {{ optionSelected.value }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!optionSelected">
          {{ dataSelectList.errorMessage }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions style="padding-top: 20px" class="d-flex justify-content-center">
    <button
      mat-stroked-button
      class="action-button text-black autosized"
      type="button"
      tabindex="-1"
      (click)="onCancel()"
      *ngIf="!isOnlyConfirm"
    >
      Cancelar
    </button>
    <button
      mat-stroked-button
      class="close-modal-button"
      type="button"
      (click)="onOk()"
      *ngIf="isOnlyConfirm"
    >
      Fechar
    </button>
    <button
      mat-raised-button
      color="primary"
      class="action-button text-white autosized"
      type="submit"
      (click)="onOk()"
      tabindex="2"
      *ngIf="!isOnlyConfirm"
      [disabled]="hasSelectList && !optionSelected"
    >
      Confirmar
    </button>
  </div>
</div>
