import { SendChatDocumentMessageRequestEntity, SendChatImageMessageRequestEntity, SendChatTextMessageRequestEntity } from '../../../src/app/chat/models/contact';
import { environment } from '../../../src/environments/environment';
import { Customer } from './Customer';
import { UserNote, UserNotification } from './Notes';

export class UserNotificationEntity implements UserNotification {


  type: UserNote['type'];
  name: string;
  companyName?: string;
  link: {
    toUser?: string;
    toAdmin?: string;
    toAgent?: string;
  };
  content: string;
  recipients: Array<{
    Name: string;
    Email: string;
    type?: UserNote['type'];
  }>;

  constructor() {
    this.type = null;
    this.name = '';
    this.companyName = '';
    this.link = {
      toUser: '',
      toAdmin: '',
      toAgent: '',
    };
    this.content = '';
    this.recipients = [];
  }
  static generateNotification(customerData: Customer, message: string, nameSender: string): UserNotificationEntity {
    const note: UserNotificationEntity = {
      companyName: customerData.companyName,
      name: nameSender,
      type: 'ADMIN',
      content: message,
      recipients: [{
        Name: customerData.name,
        Email: customerData.email,
        type: 'USER'
      }],
      link: {
        toUser: `${ environment.baseURL }/mensagens`
      },
    }
    return note;
  }
  static needNotificationCustomer(data: SendChatTextMessageRequestEntity | SendChatImageMessageRequestEntity | SendChatDocumentMessageRequestEntity): boolean {
    return data.messageType.toUpperCase() === 'CHAT-SCE' && data.sender?.type.toUpperCase() === 'ADMIN'
  }
}
