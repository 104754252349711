<div class="container" style="margin-top: 25px">
  <mat-card>
    <mat-card-content>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            class="d-flex align-items-center py-3"
            [collapsedHeight]="'auto'"
            [expandedHeight]="'auto'"
          >
            <mat-panel-title class="panel-title color-primary-900">
              Como funciona a Capital Empreendedor?
            </mat-panel-title>
          </mat-expansion-panel-header>

          <p>
            Nós somos um Marketplace de crédito, ou seja: ao cadastrar sua empresa em nossa plataforma nós buscamos as
            oportunidades de crédito condizentes com sua demanda em mais de 300 parceiros.
          </p>
          <p>
            Apresentamos o comparativo de todas as oportunidades liberadas e assim é possível comparar e escolher a
            oferta com melhor taxa e prazo para a sua empresa.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header
            class="d-flex align-items-center py-3"
            [collapsedHeight]="'auto'"
            [expandedHeight]="'auto'"
          >
            <mat-panel-title class="panel-title color-primary-900"> Como consigo o crédito? </mat-panel-title>
          </mat-expansion-panel-header>

          <p>
            O crédito é concedido através de uma análise de crédito feita através dos seus documentos. Nosso diferencial
            é a <strong>documentação única</strong>.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header
            class="d-flex align-items-center py-3"
            [collapsedHeight]="'auto'"
            [expandedHeight]="'auto'"
          >
            <mat-panel-title class="panel-title color-primary-900"> Como assim documentação única? </mat-panel-title>
          </mat-expansion-panel-header>

          <p>
            Cada instituição financeira solicita um tipo de documentação para ser analisada a fim de comprovar o seu
            faturamento.
          </p>
          <p>
            Nós criamos uma listagem única de documentos, assim você só precisará nos encaminhar uma única vez e terá
            sua análise realizada por todos os parceiros.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header
            class="d-flex align-items-center py-3"
            [collapsedHeight]="'auto'"
            [expandedHeight]="'auto'"
          >
            <mat-panel-title class="panel-title color-primary-900">
              Por que é importante fornecer meu faturamento real?
            </mat-panel-title>
          </mat-expansion-panel-header>

          <p>
            Só assim é possível fazer uma análise mais precisa e ágil no processo de busca dos parceiros que mais se
            encaixam no seu perfil.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header
            class="d-flex align-items-center py-3"
            [collapsedHeight]="'auto'"
            [expandedHeight]="'auto'"
          >
            <mat-panel-title class="panel-title color-primary-900"> Quanto é a taxa de juros? </mat-panel-title>
          </mat-expansion-panel-header>

          <p>
            As taxas de juros são oferecidas diretamente pelas instituições e partem de 0,79% a.m. Variam de acordo com
            a disponibilidade de linhas de crédito, rating e histórico da empresa.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header
            class="d-flex align-items-center py-3"
            [collapsedHeight]="'auto'"
            [expandedHeight]="'auto'"
          >
            <mat-panel-title class="panel-title color-primary-900">
              Quem são as instituições parceiras?
            </mat-panel-title>
          </mat-expansion-panel-header>

          <p>
            Temos mais de 300 parceiros, entre eles: BNDES, Bancos (Itaú, Santander, Banco do Brasil, Bradesco e Caixa
            Econômica), FIDCs e Fintechs.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header
            class="d-flex align-items-center py-3"
            [collapsedHeight]="'auto'"
            [expandedHeight]="'auto'"
          >
            <mat-panel-title class="panel-title color-primary-900">
              Como é cobrada a remuneração da Capital Empreendedor?
            </mat-panel-title>
          </mat-expansion-panel-header>

          <p>
            Nossa taxa de abertura de crédito é cobrada <strong>somente se</strong>, após o crédito ser desembolsado. Essa taxa
            varia de acordo com o tipo de operação tomada, confira:
          </p>
          <ul>
            <li><strong>Cartão de Crédito e Desconto de Faturas:</strong> 4,5% sobre o valor liberado</li>
            <li>
              <strong>Operações com prazo < 24 meses:</strong> 4,5% sobre o valor liberado (ex: 0,225% a.m. em 20
              meses)
            </li>
            <li>
              <strong>Operações com prazo >= 24 meses:</strong> 4,5% sobre o valor liberado (ex: 0,125% a.m. em 36
              meses)
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header
            class="d-flex align-items-center py-3"
            [collapsedHeight]="'auto'"
            [expandedHeight]="'auto'"
          >
            <mat-panel-title class="panel-title color-primary-900">
              Vale a pena oferecer garantias? Quais devo oferecer?
            </mat-panel-title>
          </mat-expansion-panel-header>

          <p>
            As instituições financeiras entendem que, quando uma empresa tem garantias, menores são os riscos da
            operação e por este fator as taxas e prazos melhoram. As garantias que podem ser oferecidas são:
          </p>
          <ul>
            <li>Imóveis</li>
            <li>Veículos</li>
            <li>Extrato Cartão de Crédito</li>
            <li>Boleto</li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header
            class="d-flex align-items-center py-3"
            [collapsedHeight]="'auto'"
            [expandedHeight]="'auto'"
          >
            <mat-panel-title class="panel-title color-primary-900"> Quais documentos são necessários? </mat-panel-title>
          </mat-expansion-panel-header>

          <p>Documentação <strong>básica</strong> e para empréstimo com garantia de <strong>Aval</strong>:</p>

          <ul>
            <li>Empresa:</li>
            <ul>
              <li>Contrato Social da empresa (deve constar a última alteração contratual);</li>
              <li>Comprovante de Endereço PJ (atualizado dos últimos 60 dias - água, luz ou telefone)</li>
              <li>Extratos bancários dos últimos 4 meses de todas as contas PJ da empresa;</li>
              <li>Declaração de faturamento dos últimos 36 meses (assinada pelo contador).</li>
            </ul>
            <li>Sócios:</li>
            <ul>
              <li>RG e CPF</li>
              <li>Comprovante de Endereço PF (atualizado dos últimos 60 dias - água, luz ou telefone)</li>
              <li>IRPF de todos os sócios + recibo de envio.</li>
            </ul>
          </ul>

          <p>Documentação para empréstimo com garantia de <strong>Imóvel</strong>:</p>

          <ul>
            <li>CPF e RG do proprietário do Imóvel</li>
            <li>Matrícula do Imóvel</li>
          </ul>

          <p>Documentação necessária para empréstimo com garantia de <strong>Veículo</strong>:</p>

          <ul>
            <li>Contrato Social (última alteração)</li>
            <li>3 últimos holerites</li>
            <li>CPF e RG dos sócios</li>
            <li>Documento do veículo</li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header
            class="d-flex align-items-center py-3"
            [collapsedHeight]="'auto'"
            [expandedHeight]="'auto'"
          >
            <mat-panel-title class="panel-title color-primary-900"> Como submeto a documentação? </mat-panel-title>
          </mat-expansion-panel-header>

          <p>
            Carregue toda a documentação solicitada através do nosso sistema, dentro da sua
            <a routerLink="/user-login"></a>área restrita.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header
            class="d-flex align-items-center py-3"
            [collapsedHeight]="'auto'"
            [expandedHeight]="'auto'"
          >
            <mat-panel-title class="panel-title color-primary-900">
              Quando fico sabendo se meu crédito foi aprovado?
            </mat-panel-title>
          </mat-expansion-panel-header>

          <p>
            O tempo varia de acordo com os parceiros. Sugerimos que logo após assinar nosso termo de serviço a empresa
            encaminhe os documentos solicitados por e-mail para que sigam para a análise de crédito.
          </p>
          <p>
            O resultado desta análise é a aprovação ou não do crédito e nós entraremos em contato para informar sobre a
            situação.
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </mat-card>
</div>
