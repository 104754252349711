<div class="navbar">
  <div>
    <img alt="logo-capital-empreendedor"
      src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fnewlogo.svg?alt=media&token=670d09c9-9b73-453f-9598-cce88c9486e6" />
    <img alt="logo-{{partnerShips.partner}}" *ngIf="partnerShips?.logo" src="{{partnerShips.logo}}" />
  </div>


  <!-- <div class="router-buttons">
    <button [matMenuTriggerFor]="buttons" mat-icon-button color="primary"><mat-icon>view_headline</mat-icon></button>

    <mat-menu #buttons>
      <button *ngIf="!router?.url?.includes('/entrar')" mat-menu-item>
        <a [routerLink]="['/entrar']" class="btn">Sou cliente</a>
      </button>
      <button *ngIf="router?.url?.includes('/entrar')" mat-menu-item>
        <a [routerLink]="['/cadastrar']" class="btn">Compare grátis</a>
      </button>
    </mat-menu>
  </div> -->

  <div class="buttons">
    <a *ngIf="router?.url?.includes('/entrar')" class="ml-2" mat-raised-button type="button" style="color:
      white; font-size: 20px; border: 2px solid #0f653e; background-color: #0f653e; "
      (click)="redirectToSignUp()">Compare Grátis</a>
    <a *ngIf="!router?.url?.includes('/entrar')" class="ml-2" mat-raised-button type="button"
    style="color: white; font-size: 20px; border: 2px solid #0f653e; background-color: #0f653e; "
      (click)="redirectToLogin()">
      Sou Cliente</a>
  </div>


</div>
<div *ngIf="backgroundImage" class="content" [style.background-image]="backgroundImage">

  <ng-container *ngIf="order === 'content-area-first'">
    <div class="content-area">
      <div class="content-util-area">
        <router-outlet #o="outlet"></router-outlet>
      </div>
    </div>

    <!-- <div class="detail">
      <h1 [innerHTML]="formatText(partnerShips?.onboardingTexts?.title || getTitleByStep(step))"
        [style.fontSize]="getTitleFontSize()"></h1>
      <p [innerHTML]="formatText(partnerShips?.onboardingTexts?.subtitle || getSubTitleByStep(step))"
        [style.fontSize]="getSubTitleFontSize()"></p>
    </div> -->
  </ng-container>




  <ng-container *ngIf="order === 'detail-first'">
    <div class="detail">
      <h1 [innerHTML]="formatText(partnerShips?.onboardingTexts?.title || getTitleByStep(step))"
        [style.fontSize]="getTitleFontSize()"></h1>
      <p [innerHTML]="formatText(partnerShips?.onboardingTexts?.subtitle || getSubTitleByStep(step))"
        [style.fontSize]="getSubTitleFontSize()"></p>
    </div>

    <div class="content-area">
      <div class="content-util-area">
        <router-outlet #o="outlet"></router-outlet>
      </div>
    </div>


  </ng-container>


</div>
