import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../components/shared.module';
import { BndesRoutes } from './bndes-routing.module';
import { CreateAccountComponent } from './containers/create-account/create-account.component';

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule.forChild(BndesRoutes)],
  declarations: [CreateAccountComponent],
})
export class BndesModule {}
