export enum FirebaseCollections {
  AGENTS = 'agents',
  BANKERS = 'bankers',
  CONFIGURACAO = 'configuracao',
  CONTRACT = 'contract',
  CREDIT_PROCESSING = 'credit-processing',
  CUSTOMERS = 'customers',
  INSTITUICOES_FINANCEIRAS = 'instituicoes-financeiras',
  INST_FINANC_DOCUMENTOS = 'inst-financ-documentos',
  TIPO_DOCUMENTO = 'tipo-documento',
  USER_DOCUMENTS = 'user-documents',
  USERS = 'users',
}

export enum BackupStatus {
  STARTED = 'started',
  SUCCESS = 'success',
  FAILED = 'failed',
  INDETERMINATE = 'indeterminate',
}

export const BackupStatusLabel = {
  [BackupStatus.STARTED]: 'Iniciado',
  [BackupStatus.INDETERMINATE]: 'Indefinido',
  [BackupStatus.SUCCESS]: 'Finalizado',
  [BackupStatus.FAILED]: 'Erro',
};

export enum BackupTriggerType {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
}

export const BackupTriggerTypeLabel = {
  [BackupTriggerType.MANUAL]: 'Manual',
  [BackupTriggerType.AUTOMATIC]: 'Automático',
};

export interface CollectionStatus {
  name: FirebaseCollections;
  status: BackupStatus;
}

export interface Backup {
  triggeredAt: firebase.firestore.Timestamp;
  triggerType: BackupTriggerType;
  status: BackupStatus;
  collections: CollectionStatus[];
}
