import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { Banker } from 'functions/src/models/Banker';
import { Contract } from 'functions/src/models/Contract';
import { Customer } from 'functions/src/models/Customer';
import {
  ArquivoInfo as ArquivoInfoMongoDB,
  Document as DocumentMongoDB,
} from 'functions/src/models/documents/UserDocument';
import JSZip from 'jszip';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { UserDocumentService } from 'src/app/customer/services/user-document.service';
import { BankerContract } from '../../../../../functions/src/models/contract/BankerContract';
import { GroupOppsEntity } from '../../../../../functions/src/models/opportunity/GroupOppsEntity';
import { Opportunity } from '../../../../../functions/src/models/opportunity/Opportunity';
import { CadastrosGeraisService } from '../../../admin/cadastros-gerais/cadastros-gerais.service';
import { NotesService } from '../../../admin/services/notes.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { OpportunityHistoryService } from '../../../customer/services/opportunity-history.service';
import { BankerFileUploadComponent } from '../../components/banker-file-upload/banker-file-upload.component';
import { ConfirmCompleteOppComponent } from '../../components/confirm-complete-opp/confirm-complete-opp.component';
import { OpportunityNotesComponent } from '../../components/opportunity-notes/opportunity-notes.component';
import { BankerService } from '../../services/banker.service';
import { OpportunityService } from '../../services/opportunity.service';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss'],
})
export class BankerOpportunityComponent implements OnInit, OnDestroy {
  // Customer Data
  customerSubscription: Subscription;
  customer: Customer;
  opportunity: Opportunity;
  status: string;
  guarantees: string[];
  oppClosingDate: Date;
  oppLastUpdate: Date;
  isCustomerDataExpanded = false;
  panelOpenState = false;
  reasonsForRefusal: any;
  setValue: any;

  utilHandler = UtilHandler;

  proposal = {
    amount: null,
    interest: null,
    term: null,
    description: '',
  };

  // Contract Data
  contractSubscription: Subscription;
  contract: Contract;

  // Banker Data
  banker: Banker; // Only UID
  institution = '';
  institutionName: string;
  bankerSubscription: Subscription;

  // Banker Contract
  //@ViewChild(FileUploadComponent, { static: false }) fileUploader: FileUploadComponent;
  @ViewChild(BankerFileUploadComponent, { static: false }) fileUploader: BankerFileUploadComponent;
  bankerContract: BankerContract;

  // Control Attributes
  uid: string;
  isLoadingCustomer = true;
  isLoadingCustomerOpp = true;
  hasCustomerError = false;
  isLoadingContract = true;
  hasContractError = false;
  hasInstitutionError = false;
  isRejected = true;
  isConcluded = false;
  isClosed = false;
  isRegionalized = false;
  isSendingContractMode = true;
  completedUpload = false;
  hasText = false;

  // BDC
  bigdatacorpData: any;
  bigdatacorpSubscription: Subscription;
  readonly Object = Object;

  getCadastrosGeraisSubscription: Subscription;

  // docs
  documents: DocumentMongoDB[];
  documentsSubscription: Subscription;
  yearDocuments: any[];
  canUpload: boolean;
  selectedDocument: { documents: ArquivoInfoMongoDB[]; status?: string; year?: string | number }[];
  selectedDocumentType: DocumentMongoDB;

  basicData: any;
  basicDataActivities: any;
  lawsuitsData: any;
  lawsuits: any;
  protests: any;
  relationships: any;
  debts: any;
  previousProposal: GroupOppsEntity[];
  oppHistorySubscription: Subscription;

  canEnableSubmitButton: boolean;

  @ViewChild(MatTabGroup) tab: MatTabGroup;
  readonly tabOptions = ['dados', 'credito', 'documentacao', 'kyc', 'dadosExtras'];
  isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private notesService: NotesService,
    private bankerService: BankerService,
    private opportunityService: OpportunityService,
    private customerService: CustomerService,
    private userDocumentService: UserDocumentService,
    private cadastrosGeraisService: CadastrosGeraisService,
    private oppHistoryService: OpportunityHistoryService,
  ) { }

  ngOnInit(): void {
    const prevItem = -1;
    const nextItem = 1;
    const nullItem = 0;

    this.getCadastrosGeraisSubscription = this.cadastrosGeraisService
      .getCadastrosGerais('motivos-de-recusa-IF')
      .subscribe((result) => {
        this.reasonsForRefusal = result;
        this.reasonsForRefusal.sort(function (itemOne, itemTwo) {
          return itemTwo.position > itemOne.position
            ? prevItem
            : itemTwo.position < itemOne.position
              ? nextItem
              : nullItem;
        });

        this.setValue = this.reasonsForRefusal.forEach((item) => {
          item.value = item.nome;
        });
      });

    this.uid = this.route.snapshot.paramMap.get('id');

    // new rebuilding
    this.bankerSubscription = this.bankerService.banker.subscribe((banker) => {
      if (banker) {
        this.banker = banker;
        this.institution = banker.institution?.name;
        if (this.institution) {
          this.bankerService
            .getCreditInstitutionName(this.institution)
            .then((name) => {
              this.institutionName = name;
            })
            .catch((err) => {
              console.error('Error getting institution name', err);
              this.institutionName = undefined;
            });

          this.opportunityService.getOpportunityOfBanker(this.uid, this.institution).then((res) => {
            this.opportunity = res;

            if (this.opportunity) {
              this.bankerContract = this.opportunity?.contract || null;

              this.isRejected = this.opportunity.stageOpportunity.defaultprobability === 0;
              this.isConcluded = this.opportunity.stageOpportunity.defaultprobability === 100;
              this.isClosed = this.isRejected || this.isConcluded;
              this.oppLastUpdate = this.opportunity.lastUpdate ? this.opportunity.lastUpdate : undefined;
              this.oppClosingDate = this.opportunity.closingDate ? this.opportunity.closingDate : undefined;

              this.isSendingContractMode = !this.bankerContract;

              this.proposal = {
                amount: this.opportunity?.limitOperation,
                interest: this.opportunity?.rate,
                term: isNaN(this.opportunity?.deadlineForMonth) ? null : this.opportunity?.deadlineForMonth,
                description: `${ this.opportunity?.description ?? '' }`,
              };
            } else {
              this.isRejected = true;
            }

            this.hasCustomerError = false;
            this.isLoadingCustomerOpp = false;
          });

          this.customerService.getCustomerByUID(this.uid).then((customer) => {
            if (customer) {


              this.customer = customer;
              this.getOpportunityHistoryByCustomer(this.customer.uid);

              this.guarantees = customer.guarantees?.map((g) => g.name);
            } else {
              this.hasCustomerError = true;
            }
            this.isLoadingCustomer = false;
          });

          this.contractSubscription = this.opportunityService.getContractData(this.uid).subscribe((contract) => {
            if (contract) {
              this.contract = contract;
              this.hasContractError = false;
            } else {
              this.hasContractError = true;
            }
            this.isLoadingContract = false;
          });

          this.opportunityService
            .getCustomerDocuments(this.uid, this.banker?.institution?.name)
            .subscribe((docList) => {
              if (docList.length > 0) {
                const documents = _.sortBy(docList?.filter((d) => d.situation === 'Aprovado') || [], ['name']);
                this.documents = documents;
              }
            });
        }

        this.isRegionalized = banker.isRegionalized ?? false;
      } else {
        this.hasCustomerError = true;
        this.isLoadingCustomer = false;
      }
    });

    this.bigdatacorpSubscription = this.opportunityService.getBigDataCorpSavedData(this.uid).subscribe((docs) => {
      this.bigdatacorpData = {};
      this.basicData = docs[0];
      this.basicDataActivities = docs[0].Activities;
      this.lawsuitsData = docs[1].Lawsuits;
      this.lawsuits = this.lawsuitsData.Lawsuits;
      this.debts = docs[2].OnlineCertificates[0];
      // this.relationships = docs[5].Relationships[0];
      this.relationships = this.findDataByRelationships(docs);


      console.log('docs --------->', docs);
      console.log('dividas ------->', this.debts);

      docs.forEach((d) => {
        _.forEach(d, (v, k) => (this.bigdatacorpData[k] = v));
      });
    });


  }

  findDataByRelationships(docs: any[]): any {
    const foundData = docs.find(doc => doc['Relationships'] !== undefined);
    return foundData ? foundData['Relationships'] : null;
  }

  ngOnDestroy(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
    if (this.contractSubscription) {
      this.contractSubscription.unsubscribe();
    }
    if (this.bankerSubscription) {
      this.bankerSubscription.unsubscribe();
    }
    if (this.documentsSubscription) {
      this.documentsSubscription.unsubscribe();
    }
    if (this.bigdatacorpSubscription) {
      this.bigdatacorpSubscription.unsubscribe();
    }
    if (this.oppHistorySubscription) {
      this.oppHistorySubscription.unsubscribe();
    }
  }

  isSubmitButtonEnabled(): void {
    this.canEnableSubmitButton = this.proposal.interest !== null && this.proposal.term !== null && this.proposal.amount !== null;
  }

  selectTab(tab: string): void {
    this.tab.selectedIndex = this.tabOptions.indexOf(tab);
  }

  canSubmitProposal(): boolean {
    const { amount, term, interest } = this.proposal;

    return (
      amount !== null &&
      term !== null &&
      interest !== null &&
      amount !== undefined &&
      term !== undefined &&
      interest !== undefined &&
      amount >= 0 &&
      term > 0 &&
      interest >= 0
    );
  }

  rejectOpportunity(): void {
    const reasonSelect = {
      placeholder: 'Motivo',
      errorMessage: 'O motivo é obrigatório.',
      dataList: this.reasonsForRefusal,
    };

    const selectDialogSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Rejeitar oportunidade',
          alertDescription: 'Para rejeitar uma oportunidade é necessário informar um motivo:',
          hasSelectList: true,
          dataSelectList: reasonSelect,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.value) {
          this.isLoadingCustomer = true;

          const opportunity: Opportunity = {
            ...this.opportunity,
            motivationOfCancellation: result.value,
            rejectedFor: {
              uid: this.banker.uid,
              // type: 'BANKER'
            },
          };

          console.log('Opportunity rejected');
          const createdAt = {
            uid: this.banker.uid,
            name: this.banker.name,
          };

          this.opportunityService
            .rejectOpportunity(this.customer.uid, opportunity, createdAt)
            .then(() => {
              this.isLoadingCustomer = false;

              const resultDialogSubscription = this.dialog
                .open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Oportunidade rejeitada',
                    alertDescription: `A oportunidade foi rejeitada com sucesso. Você será redirecionado à lista de oportunidades.`,
                    isOnlyConfirm: true,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  if (resultDialogSubscription) {
                    resultDialogSubscription.unsubscribe();
                  }
                  this.router.navigate(['/bankers/opportunities']);
                });
            })
            .catch((err) => {
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro',
                  alertDescription: `Ocorreu um erro ao rejeitar a oportunidade.`,
                  isOnlyConfirm: true,
                },
              });

              this.isLoadingCustomer = false;
            });
        } else {
          console.log('Cancelled rejection');
        }

        if (selectDialogSubscription) {
          selectDialogSubscription.unsubscribe();
        }
      });
  }

  reportOpportunityOutOfRegion(): void {
    const selectDialogSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '650px',
        data: {
          alertTitle: 'Reportar oportunidade',
          alertDescription:
            'Deseja reportar que oportunidade está fora da sua área de atuação? Ao concluir esta ação a oportunidade será removida da sua lista.',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isLoadingCustomer = true;

          console.log('Opportunity out of region reported');

          this.opportunityService
            .reportOpportunityOutOfRegion(this.customer.uid, this.banker.uid)
            .then(() => {
              this.isLoadingCustomer = false;

              const resultDialogSubscription = this.dialog
                .open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Oportunidade reportada',
                    alertDescription: `A oportunidade foi reportada com sucesso. Você será redirecionado à lista de oportunidades.`,
                    isOnlyConfirm: true,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  if (resultDialogSubscription) {
                    resultDialogSubscription.unsubscribe();
                  }
                  this.router.navigate(['/bankers/opportunities']);
                });
            })
            .catch((err) => {
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro',
                  alertDescription: `Ocorreu um erro ao reportar a oportunidade.`,
                  isOnlyConfirm: true,
                },
              });

              this.isLoadingCustomer = false;
            });
        } else {
          console.log('Cancelled rejection');
        }

        if (selectDialogSubscription) {
          selectDialogSubscription.unsubscribe();
        }
      });
  }

  // saveSimulation(): void {
  //   if (this.completedUpload && this.fileUploader.uploadMetadata?.length) {
  //     const selectDialogSubscription = this.dialog
  //       .open(AlertDialogComponent, {
  //         maxWidth: '600px',
  //         data: {
  //           alertTitle: 'Submeter Simulação',
  //           alertDescription: 'Deseja realmente submeter uma simulação para esta oportunidade?',
  //         },
  //       })
  //       .afterClosed()
  //       .subscribe((result) => {
  //         if (result) {
  //           console.log('Saving Contract');
  //           this.isLoadingCustomer = true;

  //           const contract: BankerContract = {
  //             path: this.fileUploader.uploadMetadata[0].downloadURL,
  //           };

  //           this.opportunityService
  //             .submitContract(this.customer.uid, this.customer.companyName, this.opportunity, contract, this.banker.uid)
  //             .then(() => {
  //               const resultDialogSubscription = this.dialog
  //                 .open(AlertDialogComponent, {
  //                   maxWidth: '600px',
  //                   data: {
  //                     alertTitle: 'Simulação salva',
  //                     alertDescription: `A simulação para esta oportunidade foi enviada com sucesso.`,
  //                     isOnlyConfirm: true,
  //                   },
  //                 })
  //                 .afterClosed()
  //                 .subscribe(() => {
  //                   if (resultDialogSubscription) {
  //                     resultDialogSubscription.unsubscribe();
  //                   }
  //                 });

  //               this.isLoadingCustomer = false;
  //             })
  //             .catch((err) => {
  //               this.dialog.open(AlertDialogComponent, {
  //                 maxWidth: '600px',
  //                 data: {
  //                   alertTitle: 'Erro',
  //                   alertDescription: `Ocorreu um erro ao enviar a simulação para esta oportunidade.`,
  //                   isOnlyConfirm: true,
  //                 },
  //               });

  //               this.isLoadingCustomer = false;
  //             });
  //         } else {
  //           console.log('Cancelled Simulation Submission');
  //         }

  //         if (selectDialogSubscription) {
  //           selectDialogSubscription.unsubscribe();
  //         }
  //       });
  //   } else {
  //     console.log('No document uploaded');
  //     this.dialog.open(AlertDialogComponent, {
  //       maxWidth: '600px',
  //       data: {
  //         alertTitle: 'Nenhum documento',
  //         alertDescription: 'Nenhum documento foi carregado para envio. Carregue um documento antes de enviar!',
  //         isOnlyConfirm: true,
  //       },
  //     });
  //   }
  // }

  // updateProposal(): void {
  //   if (this.canSubmitProposal()) {
  //     const selectDialogSubscription = this.dialog
  //       .open(AlertDialogComponent, {
  //         maxWidth: '600px',
  //         data: {
  //           alertTitle: 'Submeter proposta',
  //           alertDescription: 'Deseja realmente submeter uma proposta para esta oportunidade?',
  //         },
  //       })
  //       .afterClosed()
  //       .subscribe((result) => {
  //         if (result) {
  //           console.log('Submit proposal');
  //           this.isLoadingCustomer = true;
  //           this.opportunity.limitOperation = this.proposal.amount;
  //           this.opportunity.deadlineForMonth = this.proposal.term;
  //           this.opportunity.rate = this.proposal.interest;
  //           this.opportunity.description = this.proposal.description;
  //           this.opportunityService
  //             .updateProposal(this.customer.uid, this.opportunity, this.banker.uid, this.customer.companyName)
  //             .then(() => {
  //               const resultDialogSubscription = this.dialog
  //                 .open(AlertDialogComponent, {
  //                   maxWidth: '600px',
  //                   data: {
  //                     alertTitle: 'Proposta enviada',
  //                     alertDescription: `A proposta para esta oportunidade foi enviada com sucesso.`,
  //                     isOnlyConfirm: true,
  //                   },
  //                 })
  //                 .afterClosed()
  //                 .subscribe(() => {
  //                   if (resultDialogSubscription) {
  //                     resultDialogSubscription.unsubscribe();
  //                   }
  //                 });

  //               this.isLoadingCustomer = false;
  //             })
  //             .catch((err) => {
  //               this.dialog.open(AlertDialogComponent, {
  //                 maxWidth: '600px',
  //                 data: {
  //                   alertTitle: 'Erro',
  //                   alertDescription: `Ocorreu um erro ao salvar a proposta para esta oportunidade.`,
  //                   isOnlyConfirm: true,
  //                 },
  //               });

  //               this.isLoadingCustomer = false;
  //             });
  //         } else {
  //           console.log('Cancelled submission');
  //         }

  //         if (selectDialogSubscription) {
  //           selectDialogSubscription.unsubscribe();
  //         }
  //       });
  //   }
  // }
  onSubmitButton() {
    this.isSubmitButtonEnabled();
    const selectDialogSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: 'Deseja realmente submeter uma simulação para esta oportunidade?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          console.log('Confirmed action');

          if (this.canSubmitProposal()) {
            this.isLoadingCustomer = true;
            this.opportunity.limitOperation = this.proposal.amount;
            this.opportunity.deadlineForMonth = this.proposal.term;
            this.opportunity.rate = this.proposal.interest;
            this.opportunity.description = this.proposal.description;

            this.opportunityService
              .updateProposal(
                this.customer.uid,
                this.opportunity,
                this.banker.uid,
                this.customer.companyName
              )
              .then(() => {
                console.log('Update proposal successful');
              })
              .catch((error) => {
                console.error('Error updating proposal:', error);
                this.isLoadingCustomer = false;
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro',
                    alertDescription: 'Ocorreu um erro ao processar a ação Update proposal.',
                    isOnlyConfirm: true,
                  },
                });
              });
          }

          if (this.completedUpload && this.fileUploader.uploadMetadata?.length) {

            console.log('uploadMetadata:', this.fileUploader.uploadMetadata);

            this.isLoadingCustomer = true;
            const contract: BankerContract = {
              path: this.fileUploader.uploadMetadata[0].downloadURL,
            };

            this.opportunityService
              .submitContract(
                this.customer.uid,
                this.customer.companyName,
                this.opportunity,
                contract,
                this.banker.uid
              )
              .then(() => {
                console.log('Submit contract successful');
              })
              .catch((error) => {
                console.error('Error submitting contract:', error);
                this.isLoadingCustomer = false;
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro',
                    alertDescription: 'Ocorreu um erro ao processar a ação Submit contract.',
                    isOnlyConfirm: true,
                  },
                });
              });
          }

          this.isLoadingCustomer = false;

          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Ações concluídas',
              alertDescription: 'A simulação para esta oportunidade foi enviada com sucesso.',
              isOnlyConfirm: true,
            },
          });

          if (selectDialogSubscription) {
            selectDialogSubscription.unsubscribe();
          }
        } else {
          console.log('Cancelled action');
          if (selectDialogSubscription) {
            selectDialogSubscription.unsubscribe();
          }
        }
      });
  }

  viewMoreNotes(): void {
    this.dialog.open(OpportunityNotesComponent, {
      data: {
        banker: this.banker,
        customer: this.uid,
        notificationData: {
          companyName: this.customer.companyName,
          institution: this.institutionName,
        },
      },
    });
  }

  completeOpportunity(): void {
    const resultDialogSubscription = this.dialog
      .open(ConfirmCompleteOppComponent, {
        data: {
          oppInfo: this.proposal,
          customer: this.customer.uid,
          opp: this.opportunity,
          contract: this.contract,
          banker: this.banker.uid,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result === 'success') {
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Oportunidade concluída',
              alertDescription: `Esta oportunidade foi concluída com sucesso.`,
              isOnlyConfirm: true,
            },
          });
        } else if (result === 'error') {
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro',
              alertDescription: `Um erro ocorreu e não foi possível concluir a oportunidade.`,
              isOnlyConfirm: true,
            },
          });
        }

        if (resultDialogSubscription) {
          resultDialogSubscription.unsubscribe();
        }
      });
  }

  async downloadDocsOneByOne(customerDocs: DocumentMongoDB[]): Promise<void> {
    this.isLoading = true;
    const docExtensionRegex = /(\.[\w]+)(?=\?)/;

    for (const doc of customerDocs) {
      for (let idx = 0; idx < doc?.fileInfo?.length; idx++) {
        const info = doc?.fileInfo[idx];
        const extension = docExtensionRegex.exec(info.path);
        try {
          const doczipData = (await this.userDocumentService.downloadFile(info.path)) ?? null;
          if (!!doczipData) {
            const fileName =
              (info?.financialYear
                ? doc.name + ' - ' + info.financialYear + ' - ' + (idx + 1)
                : doc.name + ' - ' + (idx + 1)
              ).replace(/\s+/g, '-') + (extension && extension[0]);

            this.downloadFile(doczipData, fileName);
            this.isLoading = false;
          }
        } catch (error) {
          console.error('Erro ao baixar o arquivo - ', doc.name, ' : ', error);
        }
      }
    }
  }

  async downloadAllDocsZip(customerDocs: DocumentMongoDB[]): Promise<void> {
    this.isLoading = true;
    const docsZip = new JSZip();

    const docExtensionRegex = /(\.[\w]+)(?=\?)/;
    for (const doc of customerDocs) {
      for (let idx = 0; idx < doc?.fileInfo?.length; idx++) {
        const info = doc?.fileInfo[idx];
        const extension = docExtensionRegex.exec(info.path);
        try {
          const doczipData = (await this.userDocumentService.downloadFile(info.path)) ?? null;
          
          if (!!doczipData) {
            docsZip.file(
              (info?.financialYear
                ? doc.name + ' - ' + info.financialYear + ' - ' + (idx + 1)
                : doc.name + ' - ' + (idx + 1)
              ).replace(/\s+/g, '-') + (extension && extension[0]),
              doczipData
            );
          }
        } catch (error) {
          this.dialog
            .open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao Baixar Documentos',
                alertDescription:
                  'Erro na geração do zip do arquivo - ' +
                  doc.name +
                  '. Se ouver mais documentos será gerado um arquivo zip sem o documento especifico.',
                isOnlyConfirm: true,
              },
            })
            .afterClosed();
          console.error('Erro na geração do zip do arquivo - ', doc.name, ' : ', error);
        }
      }
    }

    const zipName =
      this.customer.companyName?.toLowerCase().replace(/\s+/g, '-') + '-' + new Date(Date.now()).toISOString() + '.zip';
    docsZip.generateAsync({ type: 'blob' }).then(function (docs) {
      saveAs(docs, zipName);
    });
    this.isLoading = false;
  }

  private downloadFile(data: any, fileName: string): void {
    const blob = new Blob([data]);
    saveAs(blob, fileName);
  }

  parseScore(value: string): string {
    return !!value ? Number(value).toFixed(2) : '-';
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  updateCompletedUpload(newStatus: boolean) {
    this.isSubmitButtonEnabled();
    this.completedUpload = newStatus;
  }

  verifyUploadStatus(item: DocumentMongoDB, year = '') {
    let yearDocumentsUploaded = [];
    if (year !== '') {
      if (item.fileInfo !== undefined && item.fileInfo.length > 0) {
        yearDocumentsUploaded = item.fileInfo.filter((a) => a.financialYear === year);
      }
    }
    if (item?.qtyFinancialYear > 0) {
      if (
        yearDocumentsUploaded.length < (item?.maximumDocuments || item?.minimumDocuments || 1) ||
        item.qtyExpectedFiles / item.qtyFinancialYear > yearDocumentsUploaded.length
      ) {
        this.canUpload = true;
      } else {
        this.canUpload = false;
      }
    } else {
      if (
        item?.qtySubmittedFiles < (item?.maximumDocuments || item?.minimumDocuments || 1) ||
        item.qtyExpectedFiles > item.qtySubmittedFiles
      ) {
        this.canUpload = true;
      } else {
        this.canUpload = false;
      }
    }
  }

  getYearDocuments(year: number): void {
    this.yearDocuments = this.selectedDocument.filter((file) => file.year === year);
    this.yearDocuments = this?.yearDocuments[0];
  }

  getDocumentInfo(doc: DocumentMongoDB): void {
    this.selectedDocumentType = doc;
    this.selectedDocument = [];
    if (doc.financialYear.id === 'sim') {
      doc.financialYears
        .slice()
        .reverse()
        .forEach((year) => {
          let documents = [];
          if (doc?.fileInfo) {
            documents = doc.fileInfo.filter((item) => item.financialYear === year);
          }
          this.selectedDocument.push({
            year: year,
            status:
              documents.length > 0
                ? (doc?.minimumDocuments || 1) > documents.length
                  ? 'Pendente'
                  : 'Enviado'
                : 'Pendente',
            documents: documents,
          });
        });
    } else {
      this.selectedDocument.push({
        documents: doc?.fileInfo?.map((file) => file),
      });
    }
  }

  getOpportunityHistoryByCustomer(uid: string): void {
    this.oppHistorySubscription = this.oppHistoryService.getOpportunityHistoryByCustomer(uid).subscribe(res => {
      this.previousProposal = res;
    });
  }
}
