import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Banker } from 'functions/src/models/Banker';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from '../../../core/auth/auth.service';
import { BankersAdminService } from '../../services/bankers-admin.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { User } from '../../../../../functions/src/models/User';

@Component({
  selector: 'app-bankers-list',
  templateUrl: './bankers-list.component.html',
  styleUrls: ['./bankers-list.component.scss'],
})
export class BankersListComponent implements OnInit, OnDestroy {
  // Data
  bankers: Banker[] = [];

  displayedColumns: string[] = ['institution', 'name', 'email', 'createdAt', 'lastInteraction', 'actions'];
  dataSource: MatTableDataSource<Banker>;

  // Control
  isLoadingResults = true;

  // Filters
  labels = {
    institution: 'Instituição',
    name: 'Nome',
    email: 'E-mail',
  };
  selectedFilter = 'institution';
  currentFilterValue = '';
  filterValue = '';

  // Pagination
  dataLength = 0;
  pageSize = 100;
  pageSizeOptions: number[] = [25, 50, 100];
  currentPage = 0;

  // Sorting
  sortField = 'lastInteraction';
  sortDirection = 'desc';

  // Admin control
  loggedAdmin: User;
  loggedAdminSubscription: Subscription;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private bankersAdminService: BankersAdminService, private dialog: MatDialog, private authService: AuthService) { }

  ngOnInit(): void {
    this.loggedAdminSubscription = this.authService.user.subscribe(user => {
      this.loggedAdmin = user;
    });

    this.applyFilter(true);
  }

  ngOnDestroy(): void {
    if (this.loggedAdminSubscription) {
      this.loggedAdminSubscription.unsubscribe();
    }
  }

  applyFilter(resetPage = false): void {
    this.currentFilterValue = this.filterValue;

    this.isLoadingResults = true;

    if (resetPage) {
      this.currentPage = 0;
    }

    this.bankersAdminService
      .searchBankers({
        filterField: this.selectedFilter,
        filterValue: this.currentFilterValue,
        orderField: this.sortField,
        orderDirection: this.sortDirection,
        pageSize: this.pageSize,
        page: this.currentPage,
      })
      .then((response) => {
        this.bankers = response.results;

        this.dataLength = response.total;

        this.dataSource = new MatTableDataSource(this.bankers);
        this.dataSource.sort = this.sort;
        this.isLoadingResults = false;
      })
      .catch((err) => {
        console.error('Error getting bankers.', err);
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '700px',
            data: {
              alertTitle: 'Erro ao buscar operadores',
              alertDescription: `Ocorreu um erro ao buscar os operadores de crédito. Por favor, tente novamente mais tarde.`,
              isOnlyConfirm: true,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.isLoadingResults = false;
          });
      });
  }

  setPageSizeOptions(setPageSizeOptionsInput: string): void {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
    }
  }

  paginatorEvent(event: PageEvent): void {
    if (event.pageSize !== this.pageSize) {
      this.pageSize = event.pageSize;
      this.currentPage = 0;
    } else {
      this.currentPage = event.pageIndex;
    }
    this.applyFilter();
  }

  sortData(event: Sort): void {
    this.sortDirection = event.direction || 'asc';
    this.sortField = event.active;
    this.currentPage = 0;
    this.applyFilter(true);
  }

  clearFilter(): void {
    this.currentFilterValue = '';
    this.filterValue = '';
    this.currentPage = 0;
    this.applyFilter(true);
  }
}
