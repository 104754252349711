<div *ngIf="hasCupom && !isEditingCupom">
  <div class="accountant-card">
    <p class="mb-2"><span style="font-weight: 500">Código do cupom:</span> {{ customer.cupomCode }}</p>
    <p class="mb-2"><span style="font-weight: 500">Data de validade do Cupom:</span> {{ customer.dataFim }} </p>
  </div>

  <div class="my-3">
    <button mat-raised-button class="button"
      (click)="onChangeCupomAuthorization({ checked: true }); isEditingCupom = true">
      Alterar dados
    </button>
  </div>
</div>

<div *ngIf="!hasCupom || isEditingCupom">
  <div class="checkBoxDesktop">
    <mat-checkbox color="primary" formControlName="allowCupom" [checked]="checked"
      (click)="onChangeCupomAuthorization()">
      Eu tenho um cupom
    </mat-checkbox>
  </div>
  <form [formGroup]="cupomFormGroup" (ngSubmit)="saveCupom()">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="checkBoxMobile">
        <mat-checkbox color="primary" formControlName="allowCupom" [checked]="checked"
            (click)="onChangeCupomAuthorization()">
            Eu tenho um cupom
        </mat-checkbox>
        </div>

      </div>
      <div class="col-12 col-md-6 mb-1">
        <div class="accountant-field">
          <input  
          placeholder="Código do cupom" 
          formControlName="cupomCode" 
          maxlength="10"
          required/>
          <div class="cupom-error" *ngIf="cupomFormGroup.get('cupomCode').hasError('required') && cupomFormGroup.get('cupomCode').touched">
            Código do cupom invalido.
          </div>
        </div>
      </div>
    </div>

    <div>
      <button class="my-3 mr-1" mat-stroked-button (click)="cancelEditing()"
        *ngIf="!isSaving && hasCupom">
        <mat-icon class="mr-2">cancel</mat-icon> Cancelar
      </button>
      <button class="cupom-btn" color="primary" [disabled]="!cupomFormGroup.get('allowCupom').value || cupomFormGroup.invalid" (click)="saveCupom()" *ngIf="!isSaving">
          Salvar
      </button>
      <mat-spinner *ngIf="isSaving" [diameter]="30" color="primary" style="margin: 0 auto"> </mat-spinner>
    </div>
  </form>
</div>
