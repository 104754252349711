import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { PartnershipsEntity } from '../../../core/entities/partnerships.entity';
import { PartnershipsService } from '../../../core/services/partnerships.service';
import { PartnerBannerFileUploadComponent } from '../partner-banner-file-upload/partner-banner-file-upload.component';
import { PartnerLogoFileUploadComponent } from '../partner-logo-file-upload/partner-logo-file-upload.component';




@Component({
  selector: 'app-partnerships-register',
  templateUrl: './partnerships-register.component.html',
  styleUrls: ['./partnerships-register.component.scss'],

})
export class PartnershipsRegisterComponent implements OnInit {

  @Input() partnership: PartnershipsEntity;

  createFormGroup: FormGroup;
  fileInfoFormGroup: FormGroup;

  partnershipId: string
  corDeFundo: string;
  corDaFonte: string;
  isLoading = false;
  isEdit = false;
  isSalesforceError = false;
  fileName: string



  constructor(private partnershipServe: PartnershipsService, private formBuilder: FormBuilder, private router: Router, private dialog: MatDialog, private cdr: ChangeDetectorRef) {}

  @ViewChild(PartnerLogoFileUploadComponent, { static: false }) partnerLogoFileUpload: PartnerLogoFileUploadComponent;
  @ViewChild(PartnerBannerFileUploadComponent, { static: false }) partnerBannerFileUploadComponent: PartnerBannerFileUploadComponent;


  ngOnInit(): void {

    this.isEdit = this.partnership ? true : false;
    this.onLoadForm();

  }

  atualizarCorPreview(tipo: string, evento: any): void {
    if (evento && typeof evento === 'string') {
      const cor = evento.startsWith('#') ? evento : '#' + evento;

      this.createFormGroup.value[tipo] = cor
    }
  }

  onLoadForm(): void {
    this.createFormGroup = new FormGroup({
      nameCtrl: new FormControl(this.partnership?.partner || '', Validators.required),
      tenantIdCtrl: new FormControl(this.partnership?.tenantId || ''),
      tituloCtrl: new FormControl(this.partnership?.onboardingTexts.title || ''),
      subtituloCtrl: new FormControl(this.partnership?.onboardingTexts.subtitle || ''),
      isTermCtrl: new FormControl(this.partnership?.isTerm || '', this.isEdit ? null : Validators.required),
      isPasswordCtrl: new FormControl(this.partnership?.isPassword || '', this.isEdit ? null : Validators.required),
      isConfirmationEmailCtrl: new FormControl(this.partnership?.isConfirmationEmail || '', this.isEdit ? null : Validators.required),
      logoCtrl: new FormControl(this.partnership?.logo || null),
      bannerCtrl: new FormControl(this.partnership?.banner || null),
      corDeFundoCtrl: new FormControl(this.partnership?.corDeFundo || ''),
      corDaFonteCtrl: new FormControl(this.partnership?.corDaFonte || ''),
    });
  }


  onSavePartnershipData(): Promise<void> {
    this.isLoading = true;
    return Promise.all([])
      .then(() => {
        this.setImagemLogo();
        this.setImagemBanner();

        const partnerData = PartnershipsEntity.generatePartnerByForm(this.createFormGroup)
        return this.partnershipServe.createNewPartnerOnFirestore(partnerData);
      })
      .then((res) => {
        console.log('Novo parceiro criado com sucesso:', res);
        const dialog = {
          title: 'Parceiro Criado',
          message: 'Parceiro criado com sucesso.'
        }

        if (this.partnership) {
          dialog.title = 'Parceiro Atualizado';
          dialog.message = 'Parceiro atualizado com sucesso.';
        }

        const dialogRef = this.showMessage(dialog.title, dialog.message);

        return dialogRef.afterClosed().toPromise();
      })
      .catch((error) => {
        console.error('Erro ao criar novo parceiro:', error);
        throw error;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
  
  showMessage(title: string, message: string): MatDialogRef<AlertDialogComponent> {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });

    return dialogRef;
  }

  resetFormData(): void {
    this.createFormGroup.reset();
  }


  onCancel(): void {
    this.createFormGroup.reset();
  }

  isFormValid(): boolean {
    return this.createFormGroup.valid;
  }

  setImagemLogo(): void {
    const logoUrl = this.partnerLogoFileUpload.getImageUrl() || this.createFormGroup.controls.logoCtrl.value;
    this.createFormGroup.controls.logoCtrl.setValue(logoUrl)
  }

  setImagemBanner(): void {
    const bannerUrl = this.partnerBannerFileUploadComponent.getImageUrl() || this.createFormGroup.controls.bannerCtrl.value;
    this.createFormGroup.controls.bannerCtrl.setValue(bannerUrl)
  }

  deleteLogo(): void {
    this.createFormGroup.controls.logoCtrl.setValue(null)

    this.cdr.detectChanges();

  }

  deleteBanner(): void {
    this.createFormGroup.controls.bannerCtrl.setValue(null)
    this.cdr.detectChanges();
  }


}
