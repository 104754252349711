<div class="table-container">
  <h1>{{alertTitle}}</h1>
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="stageChangeDate">
      <mat-header-cell *matHeaderCellDef> Data de alteração </mat-header-cell>
      <mat-cell *matCellDef="let opportunity"> {{timestampForDate(opportunity.stageChangeDate) | date: 'dd/MM/yyyy HH:mm'}} </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="stageNumber">
      <mat-header-cell *matHeaderCellDef> % da Oportunidade </mat-header-cell>
      <mat-cell *matCellDef="let opportunity"> {{opportunity.stageNumber}} </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="stageName">
      <mat-header-cell *matHeaderCellDef> Nome da oportunidade </mat-header-cell>
      <mat-cell *matCellDef="let opportunity"> {{opportunity.stageName}} </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="createdBy">
      <mat-header-cell *matHeaderCellDef> Alterado por </mat-header-cell>
      <mat-cell *matCellDef="let opportunity"> {{opportunity?.createdBy?.name || '-'}} </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="rejectReason">
      <mat-header-cell *matHeaderCellDef> Motivo de recusa </mat-header-cell>
      <mat-cell *matCellDef="let opportunity"> {{opportunity?.rejectReason || '-'}} </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <button (click)="onGoBack()">voltar</button>
</div>