import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from '../../../../functions/src/models/Customer';
import { ChatService } from '../../chat/services/chat.service';
import { SerasaScoreTable } from './serasa-score-table';

const SERASA_SCORE_TABLE_DATA: SerasaScoreTable[] = [
  {scoreAverage: '801-1000', classification: 'A', scoreDescription: 'Excelente'},
  {scoreAverage: '601-800', classification: 'B', scoreDescription: 'Muito bom'},
  {scoreAverage: '401-600', classification: 'C', scoreDescription: 'Bom'},
  {scoreAverage: '201-400', classification: 'D', scoreDescription: 'Regular'},
  {scoreAverage: '101-200', classification: 'E', scoreDescription: 'Regular'},
  {scoreAverage: '51-100', classification: 'F', scoreDescription: 'Ruim'},
  {scoreAverage: '26-50', classification: 'G', scoreDescription: 'Muito ruim'},
  {scoreAverage: '0-25', classification: 'H', scoreDescription: 'Extremamente ruim'}
]

@Component({
  selector: 'app-score-gauge',
  templateUrl: './score-gauge.component.html',
  styleUrls: ['./score-gauge.component.scss']
})

export class ScoreGaugeComponent {
  @Input() customer: Customer;
  serasaClassification: SerasaScoreTable = { scoreAverage: '', classification: '-', scoreDescription: 'Indisponível' };
  closedCard: boolean;
  phoneValue: string;

  displayedColumns: string[] = ['scoreAverage', 'classification', 'scoreDescription'];
  dataSource = SERASA_SCORE_TABLE_DATA;

  constructor(private chatService: ChatService, private router: Router) {
    this.closedCard = true;

    this.chatService.getPlugConfig().subscribe((data: any) => {
      this.phoneValue = data?.phone;
    });
   }

  get circlePosition(): { x: number; y: number } {
    const angle = (this.customer?.scoreSerasa / 1000) * Math.PI;
    const radius = 85;
    const centerX = 100, centerY = 100;
    const x = centerX + radius * Math.cos(angle - Math.PI) - 5;
    const y = centerY + radius * Math.sin(angle - Math.PI) + 5;

    return { x, y };
  }

  onExpandCard(): void{
    this.closedCard = !this.closedCard;
  }

  getClassficationScoreSerasa(): SerasaScoreTable {
    const score = this.customer?.scoreSerasa;
    if (score === undefined || score === null) {
      return { scoreAverage: '', classification: '-', scoreDescription: 'Indisponível' };
    }

    return (
      SERASA_SCORE_TABLE_DATA.find(({ scoreAverage }) => {
        const [min, max] = scoreAverage.split('-').map(Number);
        return score >= min && score <= max;
      }) ?? { scoreAverage: '', classification: '-', scoreDescription: 'Não classificado' }
    );
  }

  formatWhatsapp(phoneStr: string) {
    return phoneStr.replace(/[^0-9]/g, '');
  }

  async goToChat(){
    const message = {
      customerId: this.customer.uid,
      contactId: this.formatWhatsapp(this.customer.whatsapp),
      sender: {
        uid: this.customer.uid,
        name: this.customer.name,
        phone: this.formatWhatsapp(this.customer.whatsapp),
        type: 'customer'
      },
      recipient: {
        uid: this.customer.attributedTo || '',
        name: 'Capital Empreendedor',
        phone: this.phoneValue,
        type: 'admin'
      },
      sendingType: 'API Client',
      messageType: 'CHAT-SCE',
      text: `Como funciona o Score de Crédito?`,
    };
    try {
      await this.chatService.sendTextMessage(message, this.customer)
      console.log(message)
      this.router.navigate(['/mensagens']);
    }
    catch (error) {
      console.log(error)
    }
  }

}
