import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BndesService } from '../../bndes/services/bndes.service';

@Component({
  selector: 'app-bndes-score',
  templateUrl: `./bndes-score.component.html`,
  styleUrls: ['./bndes-score.component.css'],
})
export class BndesScoreComponent implements OnInit, OnDestroy {
  @Input() proposalId?: string;
  creditScore: number;
  proposalSubscription: Subscription;
  constructor(private bndesService: BndesService) {}

  ngOnInit(): void {
    if (this.proposalId) {
      this.getBndesProposal();
    }
  }

  ngOnDestroy(): void {
    if (this.proposalSubscription) {
      this.proposalSubscription.unsubscribe();
    }

  }

  private getBndesProposal(): void {
    this.proposalSubscription = this.bndesService.getBndesProposal(this.proposalId).subscribe(res => {
      if (res?.creditScore) {
        this.creditScore = res?.creditScore;
      }
    });
  }
}
